import VendorAvatar from "components/common/VendorAvatar";
import Button from "components/common/Button";
import React, { useEffect, useRef, useState } from "react";
import { displayFileIcon } from "helpers";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import { isFileSizeLessThanOrEqualTo } from "helpers/compareFileSizeInMb";
import { firebaseService } from "services/firebaseService";
import useGetUserProfile from "hooks/useGetUserProfile";
import moment from "moment";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import Loader from "components/common/Loader";

const Details = () => {
    const [profile] = useGetUserProfile();
    const [inventoryDetails, setInventoryDetails] = useState<any>([]);
    const [uploading, setUploading] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const [isPerishable, setIsPerishable] = useState(false);

    const { productId } = useParams();
    const {
        fetchingSingleInventory,
        fetchedSingleInventorySuccess,
        updatingSingleProduct,
        updatedSingleProductSuccess,
    } = useAppSelector((state) => state.inventory);

    const handleUpload = (fileName: string, file: File) => {
        if (fileName && file) {
            setUploading(true);
            firebaseService.uploadFile(
                `synthally/${process.env.REACT_APP_STAGE ?? "staging"}/inventory/${
                    profile?.profile?.businessName
                }/${file?.name}`,
                file,
                (url) => {
                    const body = {
                        attachments: [
                            ...inventoryDetails?.attachments,
                            {
                                filename: fileName,
                                path: url,
                                documentType: "Bill of Lajding",
                            },
                        ],
                    };
                    dispatch(inventoryActions.updateSingleProduct(body, productId));

                    setUploading(false);
                }
            );
        }
    };

    useEffect(() => {
        if (Boolean(fetchedSingleInventorySuccess)) {
            setInventoryDetails(fetchedSingleInventorySuccess?.inventory);
        }
    }, [fetchedSingleInventorySuccess]);

    useEffect(() => {
        if (updatedSingleProductSuccess) {
            toast.custom((t) => (
                <CustomToast t={t} message={"Update successful"} type={"success"} />
            ));

            setInventoryDetails(updatedSingleProductSuccess?.inventory);
            dispatch(inventoryActions.resetUpdateSingleProductSuccess());
        }
    }, [dispatch, updatedSingleProductSuccess]);

    useEffect(() => {
        setIsPerishable(inventoryDetails?.isProductPerishable);
    }, [inventoryDetails]);

    const onPerishable = (isPerishable) => {
        setIsPerishable(isPerishable);

        dispatch(
            inventoryActions.updateSingleProduct({ isProductPerishable: isPerishable }, productId)
        );
    };

    return (
        <div className="">
            {inventoryDetails?.description && (
                <div className="w-full">
                    <p className="text-xs font-rocGroteskMedium text-slate-500 mb-1">Description</p>
                    <p className="text-sm font-rocGroteskMedium text-gm-50">
                        {inventoryDetails?.description || ""}
                    </p>
                </div>
            )}

            <div className="flex items-center space-x-[56px] mt-6 pb-8 border-b border-slate-100">
                <div className="flex items-center space-x-4 ">
                    <VendorAvatar
                        size={40}
                        name={inventoryDetails?.creator?.fullName}
                        imageSrc={inventoryDetails?.creator?.profile?.avatar}
                    />
                    <div>
                        <p className="text-slate-700 font-rocGroteskMedium text-sm">
                            {inventoryDetails?.creator?.fullName}
                        </p>
                        <p className="text-g-50 font-rocGroteskMedium text-sm capitalize">
                            {inventoryDetails?.creator?.role}
                        </p>
                    </div>
                </div>

                <div className="">
                    <p className="text-sm font-rocGroteskMedium text-black mb-1">Created on</p>
                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                        {moment(inventoryDetails?.createdAt).format("DD MMM, YYYY")}
                    </p>
                </div>

                <div className="">
                    <p className="text-sm font-rocGroteskMedium text-black ">
                        Is this a perishable goods?
                    </p>
                    <div className="flex items-center space-x-[52px]">
                        <div
                            className="mr-5 cursor-pointer flex items-center"
                            onClick={() => onPerishable(true)}
                        >
                            {isPerishable ? (
                                <i
                                    className={`ri-radio-button-fill text-lg ${
                                        isPerishable && "text-y-10"
                                    }`}
                                ></i>
                            ) : (
                                <span className={`material-icons text-lg text-n-50`}>
                                    radio_button_unchecked
                                </span>
                            )}
                            <span className="text-sm font-rocGroteskMedium ml-[5px]">Yes</span>
                        </div>

                        <div
                            className="cursor-pointer flex items-center"
                            onClick={() => onPerishable(false)}
                        >
                            {!isPerishable ? (
                                <i
                                    className={`ri-radio-button-fill text-lg ${
                                        !isPerishable && "text-y-10"
                                    }`}
                                ></i>
                            ) : (
                                <span className={`material-icons text-lg text-n-50`}>
                                    radio_button_unchecked
                                </span>
                            )}
                            <span className="text-sm font-rocGroteskMedium ml-[5px] translate-y-[1px]">
                                No
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-10">
                <p className="text-base mb-6 font-rocGroteskMedium text-black">Attachments</p>
                <div className="mt-4 grid grid-cols-3 w-full space-x-4">
                    {inventoryDetails?.attachments?.map((doc, idx) => (
                        <div
                            key={idx}
                            className="flex items-center justify-between p-3 rounded-md border border-slate-200 mb-4"
                        >
                            <div className="flex items-center gap-2">
                                {displayFileIcon(getFileTypeFromUrl(doc?.path), false)}
                                <div className="font-rocGroteskMedium">
                                    <p className="text-sm">{doc?.filename}</p>
                                    {/* <p className="text-xs text-slate-500">4 MB</p> */}
                                </div>
                            </div>
                            <div className="w-fit">
                                <Button
                                    btnClassname="!bg-n-20 !py-[6px]"
                                    btnText="View file"
                                    onClick={() => {
                                        window.open(doc?.path, "_blank");
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="py-3 px-6 rounded border border-dashed border-slate-200 w-1/3">
                <div
                    className="flex items-center  w-fit gap-4 cursor-pointer"
                    onClick={() => document.getElementById("fileInput")?.click()}
                >
                    <i className="ri-file-add-line text-2xl text-carribean-green"></i>
                    <p className="text-sm underline text-carribean-green font-rocGroteskMedium">
                        Click to upload document
                    </p>
                    {uploading && <Loader size={4} />}
                    <input
                        type="file"
                        id="fileInput"
                        className={`absolute z-[-1]  opacity-0`}
                        accept="image/png, image/jpeg"
                        multiple
                        onChange={(evt) => {
                            handleUpload(evt.target.files[0].name, evt.target.files[0]);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
export default Details;
