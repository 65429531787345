import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const inventoryService = {
    addSingleProduct,
    getSingleInventory,
    getStockList,
    addProductToStockCount,
    fetchWarehouseList,
    createWarehouse,
    updateWarehouse,
    fetchSearchInventoryListing,
    fetchSearchProductListing,
    deleteWarehouse,
    fetchSingleWarehouseList,
    fetchListStockWarehouse,
    addMultipleProducts,
    getWarehouses,
    getInventoryList,
    getTransferStocks,
    transferStocks,
    transferStockFromWarehouse,
    fetchSingleInventorySuppliers,
    fetchInventoryHistory,
    fetchSingleInventoryPO,
    fetchInventoryWarehouse,
    fetchInventoryOrders,
    updateSingleProduct,
    fetchSingleStockWarehouse,
    fetchSingleStock,
    editStock,
    fetchSingleTransferStock,
    fetchSingleStockAdjustment,
    fetchStockAdjustment,
    addNewStockAdjustment,
    addInventorySettings,
    updateInventorySettings,
    fetchInventorySettings,
    addProductVendor,
    addMultipleProductToStockCount,
    addSuppliedStock,
    updateSuppliedStock,
    fetchSingleSuppliedStock,
    fetchSuppliedStockList,
    fetchSuppliedStockListAllData,
    // ===========================
    fetchTopPerformingProducts,
    fetchLeastPerformingProducts,
    fetchLocationStockLevels,
    fetchProductSuppliers,
    fetchInventoryGrowthRate,
    fetchGMWValues,
    fetchSingleProductGMWValues,
    fetchSingleProductStockLevels,
    fetchSingleProductBom,
    fetchSalesOrders,
    fetchProductsAnalytics,
    fetchEvents,
    fetchInventoryIncidents,
    fetchSingleInventoryIncidents,
    addIncidentComment,
    fetchIncidentComment,
    fetchSalesBreakdown,
    fetchExpirationList,
    fetchReplishmentStockList,
    closeInventoryIncident,
    recentInventoryIncident,
    validateBatchId,
    addForecastEvent,
    updateForecastEvent,
    deleteForecastEvent,
};

// start synth 2.0 services

async function fetchTopPerformingProducts(dateRange = "all") {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/top/performing-products?dateRange=${dateRange}`,
        requestOptions
    );
    return res;
}

async function fetchLeastPerformingProducts(dateRange = "all") {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/least/performing-products?dateRange=${dateRange}`,
        requestOptions
    );
    return res;
}

async function fetchLocationStockLevels(
    dateRange = "all",
    inventoryId?: string,
    warehouseId?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/location/stock-levels?dateRange=${dateRange}${
            inventoryId ? "&inventoryId=" + inventoryId : ""
        }${warehouseId ? "&warehouseId=" + warehouseId : ""}`,
        requestOptions
    );
    return res;
}

async function fetchProductSuppliers(
    limit: number,
    page: number,
    productId: string,
    risk?: string,
    search?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/product/${productId}/suppliers?limit=${limit}${
            page ? `&page=${page}` : ""
        }${risk ? "&risk=" + risk : ""}${search ? "&search=" + search : ""}`,
        requestOptions
    );
    return res;
}

async function fetchInventoryGrowthRate(
    dateRange: string,
    inventoryId?: string,
    warehouseId?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/growth-rate?dateRange=${dateRange}${
            inventoryId ? "&inventoryId=" + inventoryId : ""
        }${warehouseId ? "&warehouseId=" + warehouseId : ""}`,
        requestOptions
    );
    return res;
}

async function fetchGMWValues(dateRange: string, inventoryId?: string, warehouseId?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/gmw?dateRange=${dateRange}${
            inventoryId ? "&inventoryId=" + inventoryId : ""
        }${warehouseId ? "&warehouseId=" + warehouseId : ""}`,
        requestOptions
    );
    return res;
}

async function fetchSingleProductGMWValues(
    productId: string,
    dateRange?: string,
    warehouseId?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${
            config.API_URL
        }/api/v1/ldb/inventory/single/growth-rate/${productId}?dateRange=${dateRange}${
            warehouseId ? `&warehouseId=${warehouseId}` : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchSingleProductStockLevels(
    productId: string,
    dateRange?: string,
    inventoryId?: string,
    warehouseId?: string,
    limit?: number,
    page?: number
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${
            config.API_URL
        }/api/v1/ldb/inventory/single/inventory/stock-levels/${productId}?limit=${limit}${
            page ? `&page=${page}` : ""
        }${warehouseId ? `&warehouseId=${warehouseId}` : ""}`,
        requestOptions
    );
    return res;
}

async function fetchSingleProductBom(
    productId: string,
    dateRange?: string,
    inventoryId?: string,
    warehouseId?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/product/${productId}/bom/list`,
        requestOptions
    );
    return res;
}

async function fetchSalesOrders(
    dateRange = "all",
    limit?: number,
    page?: number,
    warehouseId?: string,
    inventoryId?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/all/sales?dateRange=${dateRange}${
            limit ? "&limit=" + limit : ""
        }${page ? "&page=" + page : ""}${warehouseId ? "&warehouseId=" + warehouseId : ""}${
            inventoryId ? "&inventoryId=" + inventoryId : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchProductsAnalytics() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/Stock-Level-Details`,
        requestOptions
    );
    return res;
}

async function fetchEvents(
    search?: string,
    limit?: number,
    page?: number,
    eventCategory?: string,
    eventImpact?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/service/aggregator/events/list${
            search ? "?searchTerm=" + search : ""
        }${limit ? "&limit=" + limit : ""}${page ? "&page=" + page : ""}${
            eventCategory ? "&category=" + eventCategory : ""
        }${eventImpact ? "&impact=" + eventImpact : ""}`,
        requestOptions
    );
    return res;
}

async function fetchInventoryIncidents(
    warehouseId?: string,
    limit?: number,
    page?: number,
    incidentType?: string,
    inventoryId?: string,
   
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/incidents${
            warehouseId ? "?warehouseId=" + warehouseId : "?warehouseId="
        }${incidentType ? "&incidentType=" + incidentType : ""}${
            inventoryId ? "&inventoryId=" + inventoryId : ""
        }${limit ? "&limit=" + limit : ""}${page ? "&page=" + page : ""}`,
        requestOptions
    );
    return res;
}

async function fetchSingleInventoryIncidents(
    incidentId?: string,
    limit?: number,
    page?: number | string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/single/incident/${incidentId}?limit=${limit}${
            page ? `&page=${page}` : ""
        }`,
        requestOptions
    );
    return res;
}
async function fetchReplishmentStockList(incidentId?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/stock-replenishment/${incidentId}`,
        requestOptions
    );
    return res;
}
async function closeInventoryIncident(incidentId?: string) {
    const requestOptions = {
        method: "PATCH",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/incident/${incidentId}/close`,
        requestOptions
    );
    return res;
}

async function recentInventoryIncident(incidentId?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/incident/most-recent/${incidentId}`,
        requestOptions
    );
    return res;
}

async function addIncidentComment(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/incident/inventory-incident`,
        requestOptions
    );
    return res;
}

async function fetchIncidentComment(incidentId: string, limit: number, page?: number | string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${
            config.API_URL
        }/api/v1/ldb/incident/list/all/inventory-incident/comments/${incidentId}?limit=${limit}${
            page ? `&page=${page}` : ""
        }`,
        requestOptions
    );
    return res;
}

async function addForecastEvent(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/product/forecast-event`,
        requestOptions
    );
    return res;
}

async function updateForecastEvent(
    data: Record<string, any> | Array<Record<string, any>>,
    eventId: string
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/forecast-event/${eventId}/update`,
        requestOptions
    );
    return res;
}

async function deleteForecastEvent(eventId: string) {
    const requestOptions = {
        method: "DELETE",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/forecast-event/${eventId}/delete`,
        requestOptions
    );
    return res;
}

async function fetchSalesBreakdown(warehouseId: string, inventoryId: string, dateRange: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/warehouses/stock-breakdown?dateRange=${dateRange}${
            warehouseId ? "&warehouseId=" + warehouseId : ""
        }${inventoryId ? "&inventoryId=" + inventoryId : ""}`,
        requestOptions
    );
    return res;
}

async function fetchExpirationList(
    search?: string,
    warehouseId?: string,
    limit?: number | string,
    page?: number | string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/track-expiry?limit=${limit}&search=${search}${
            warehouseId ? "&warehouseId=" + warehouseId : ""
        }${page ? `&page=${page}` : ""}`,
        requestOptions
    );

    return res;
}
// end synth 2.0 services

async function getStockList(
    limit?: number,
    search?: string,
    warehouseId?: string,
    category?: string,
    page?: number
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit,
        search,
        warehouseId,
        category,
        page,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/list/stock?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}
async function fetchSingleStock(id: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/single/stock/${id}`,
        requestOptions
    );
    return res;
}
async function fetchSingleTransferStock(id: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/single/stock/movement/${id}`,
        requestOptions
    );
    return res;
}
async function editStock(id: string, data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/product/stock/${id}`,
        requestOptions
    );
    return res;
}
async function getSingleInventory(id: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/inventory/${id}/details`,
        requestOptions
    );
    return res;
}

async function addProductToStockCount(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/product/stock-count`,
        requestOptions
    );
    return res;
}

async function addMultipleProductToStockCount(
    data: Record<string, any> | Array<Record<string, any>>
) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/product/stock-count/multiple`,
        requestOptions
    );
    return res;
}

async function transferStocks(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/stock/transfer`,
        requestOptions
    );
    return res;
}

async function fetchWarehouseList(limit: number, page?: number | string, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit: limit as number,
        search: search as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/warehouses?${page ? `page=${page}` : ""}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function transferStockFromWarehouse(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/stock/transfer`,
        requestOptions
    );
    return res;
}

async function createWarehouse(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/warehouse`,
        requestOptions
    );
    return res;
}

async function addSingleProduct(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/product`,
        requestOptions
    );
    return res;
}

async function addProductVendor(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/vendor`,
        requestOptions
    );
    return res;
}
async function updateWarehouse(id: string, data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/warehouse/${id}`,
        requestOptions
    );
    return res;
}
async function addMultipleProducts(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/product/multiple`,
        requestOptions
    );
    return res;
}
async function deleteWarehouse(id: string) {
    const requestOptions = {
        method: "DELETE",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/warehouse/${id}`,
        requestOptions
    );
    return res;
}

async function getWarehouses(limit?: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        limit,
        search,
    });
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/warehouses?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getInventoryList(limit?: number, page?: number, search?: string, category?: string) {
    // this is being used in synth 2.0
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        category,
        search,
    });
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/listing?limit=${limit}&page=${page}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchSingleWarehouseList(id: string, limit: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit: limit as number,
        search: search as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/warehouse/${id}?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchSearchInventoryListing(
    limit: number,
    search?: string,
    location?: string,
    category?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/listing?limit=${limit}${
            search ? "&search=" + search : ""
        }${location ? "&location=" + location : ""}${category ? "&category=" + category : ""}`,
        requestOptions
    );
    return res;
}

async function getTransferStocks(
    limit: number,
    page: number,
    search?: string,
    warehouseId?: string,
    category?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit,
        page,
        search,
        warehouseId,
        category,
    });
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/list/transfer-stock?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}
async function fetchSearchProductListing(
    limit: number,
    search?: string,
    data?: Record<string, any> | Array<Record<string, any>>
) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const urlParams = createUrlSearchParams({
        limit: limit as number,
        search: search as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/listing-by-products?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchListStockWarehouse(
    id: string,
    inventoryId?: string,
    limit?: number,
    search?: string,
    category?: string,
    isExpired?: boolean
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        inventoryId,
        limit: limit as number,
        search: search as string,
        category: category as string,
        isExpired: isExpired as boolean,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/stock/warehouse/${id}?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchSingleStockWarehouse(id: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/single/stock/${id}`,
        requestOptions
    );
    return res;
}

async function fetchSingleInventorySuppliers(id: string, limit: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit: limit as number,
        search: search as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/inventory/${id}/suppliers?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchInventoryHistory(id: string, limit: number, filter?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit: limit as number,
        filter: filter as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/inventory/${id}/history?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchSingleInventoryPO(id: string, limit: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit: limit as number,
        search: search as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/inventory/${id}/purchaseOrders?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchInventoryWarehouse(id: string, limit: number, search?: string, page?: number) {
    // this is being used in synth 2.0
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit: limit as number,
        search: search as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/inventory/${id}/warehouse?page=${
            search ? 1 : page
        }${Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""}`,
        requestOptions
    );
    return res;
}
async function fetchInventoryOrders(id: string, limit: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        limit: limit as number,
        search: search as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/inventory/${id}/orders?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchStockAdjustment(
    limit: number,
    page: number,
    search?: string,
    warehouseId?: string,
    category?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        page,
        limit: limit,
        search: search,
        warehouseId: warehouseId,
        category: category,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/list/adjustment-stock?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchSingleStockAdjustment(id: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/single/stock-adjustment/movement/${id}`,
        requestOptions
    );
    return res;
}

async function addNewStockAdjustment(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/product/stock/multiple`,
        requestOptions
    );
    return res;
}

async function updateSingleProduct(
    data: Record<string, any> | Array<Record<string, any>>,
    productId: string
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/update/inventory/${productId}`,
        requestOptions
    );
    return res;
}

async function addInventorySettings(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/stock/settings`,
        requestOptions
    );
    return res;
}

async function updateInventorySettings(
    data: Record<string, any> | Array<Record<string, any>>,
    settingsId: string
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/stockSettings/${settingsId}`,
        requestOptions
    );
    return res;
}

async function fetchInventorySettings(settingsId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/single/stockSetting/${settingsId}`,
        requestOptions
    );
    return res;
}

async function addSuppliedStock(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/multiple/stock-record`,
        requestOptions
    );
    return res;
}

async function updateSuppliedStock(
    id: string,
    data: Record<string, any> | Array<Record<string, any>>
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/update/stock-record/${id}`,
        requestOptions
    );
    return res;
}

async function fetchSuppliedStockList(
    limit,
    search?: string,
    warehouseId?: string,
    category?: string
) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        limit: limit,
        search: search,
        warehouseId: warehouseId,
        category: category,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/list/supply-stock?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchSuppliedStockListAllData(
    limit,
    search?: string,
    warehouseId?: string,
    category?: string,
    page?: number
) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        limit: limit,
        search: search,
        warehouseId: warehouseId,
        category: category,
        page,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/supply-stock/full-list?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchSingleSuppliedStock(id: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/single/supply-stock/${id}`,
        requestOptions
    );
    return res;
}

async function validateBatchId(id: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/validate/batch/${id}`,
        requestOptions
    );
    return res;
}
