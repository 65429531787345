import React, { useEffect } from "react";
import ReactEcharts from "echarts-for-react";

type mapProp = {
    mapData?: any;
};

const MultipleLineChart = ({ mapData }: mapProp) => {
    const getOptions = () => {
        const options = {
            grid: { top: 8, right: 0, bottom: 24, left: 0, containLabel: true },
            xAxis: {
                type: "category",
                data: mapData[0],
                nameTextStyle: {
                    borderType: "dotted",
                    fontSize: "9",
                    fontFamily: "rocGrotesk-regular",
                },
                axisLabel: {
                    color: "#89939b",
                    align: "center",
                },
                axisLine: {
                    lineStyle: {
                        color: "#E2E8F0",
                    },
                },
                axisTick: {
                    show: false,
                },
            },
            axisLine: {
                lineStyle: {
                    color: "#E2E8F0",
                },
            },
            axisTick: {
                show: false,
            },

            yAxis: {
                type: "value",
                axisLabel: {
                    color: "#89939b",
                    formatter: "{value}",
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: "dotted",
                        color: "#E2E8F0",
                    },
                },
            },

            series: [
                {
                    name: "Sales",
                    data: mapData[1],
                    type: "line",
                    symbolSize: 5,
                    symbol: "circle",
                    smooth: true,
                    lineStyle: {
                        width: 1,
                        color: "#002A61",
                        type: "line",
                    },
                },
                {
                    name: "Demand forecast",
                    data: mapData[2],
                    type: "line",
                    symbolSize: 5,
                    symbol: "circle",
                    smooth: true,
                    lineStyle: {
                        width: 1,
                        color: "#00A5D0",
                        type: "dashed",
                    },
                },
            ],
            color: ["#002A61", "#00A5D0"],
            tooltip: {
                trigger: "axis",
                backgroundColor: "#fff",
                textStyle: {
                    color: "#000",
                },

                axisPointer: {
                    type: "line",
                    lineStyle: {
                        color: "#CBD5E1",
                        width: 1,
                        type: "line",
                    },
                },

                formatter: function (params) {
                    if (
                        (params[0] && params[0]?.data?.value) ||
                        (params.length === 2 && params[1]?.data?.value)
                    ) {
                        const firstParam = params[0];
                        const secondParam =
                            params.length === 2 && params[1]?.data?.value ? params[1] : null;
                        return `
         <div class="chart" style="width: 200px">
            <div style="display:flex ; align-items: center; gap: 5px;  margin-bottom: 10px;">
               ${
                   firstParam.seriesName === "Sales"
                       ? `
                <p style="height: 10px; width: 10px; background-color: #002A61 ; border-radius: 100%;">
                </p>
            `
                       : `
                <p style="height: 10px; width: 10px; background-color:#00A5D0 ; border-radius: 100%;">
                </p>
            `
               }

              <p style="font-weight: 500; color: #64748B; font-size: 12px;" >  ${firstParam.name} ${
                            firstParam.seriesName
                        } </p>
            </div>

              <p style="font-weight: 500; color: #475569; font-size: 16px; margin-bottom: 10px;" > $${firstParam?.data?.value?.toLocaleString()} </p>
               ${
                   firstParam?.data?.quantity
                       ? `
                  <p style="font-weight: 500; color: #475569; font-size: 16px; margin-bottom: 10px;" > ${firstParam?.data?.quantity.toLocaleString()} units </p>
            `
                       : ""
               }

               ${
                   secondParam
                       ? `<div style="display:flex ; align-items: center; gap: 5px;  margin-bottom: 10px;">

                <p style="height: 10px; width: 10px; background-color:#00A5D0 ; border-radius: 100%;">
                </p>
              <p style="font-weight: 500; color: #64748B; font-size: 12px;" >  ${
                  secondParam.name
              } ${secondParam.seriesName} </p>
            </div>

              <p style="font-weight: 500; color: #475569; font-size: 16px; margin-bottom: 10px;" > $${secondParam?.data?.value?.toLocaleString()} </p>
               ${
                   secondParam?.data?.quantity
                       ? `
                  <p style="font-weight: 500; color: #475569; font-size: 16px; margin-bottom: 10px;" > ${secondParam?.data?.quantity.toLocaleString()} units </p>
            `
                       : ""
               }
            `
                       : ""
               }

</div>

        `;
                    } else {
                        return "";
                    }
                },
            },
        };

        return options;
    };

    return (
        <div id="chart" className="w-full">
            <ReactEcharts option={getOptions()} />
        </div>
    );
};

export default MultipleLineChart;
