import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { onScroll } from "helpers";
import { debounce, truncate } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { taskActions } from "redux/Ldb/actions";
import TaskDetailsModal from "./TaskDetails";
import { calculateRemainingTime } from "helpers/calculateCommentDate";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";
import CustomMobileTable from "components/common/CustomMobileTable";

const TaskLists = () => {
    const limit = 11;
    const navigate = useNavigate();
    const [taskLimit, setTaskLimit] = useState(limit);
    const [taskList, setTaskList] = useState([]);
    const [loadMoreTask, setLoadMoreTask] = useState(false);
    const [filter, setFilter] = useState({
        search: "",
    });
    const [showTaskDetails, setShowTaskDetails] = useState(false);
    const [debouncedFilter, setDebouncedFilter] = useState({
        search: "",
    });
    const [taskPagination, setTaskPagination] = useState<{
        current: number | string;
        number_of_pages: number | string;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [param] = useSearchParams();
    const tab = param.get("show");
    const [taskId, setTaskId] = useState("");
    const dispatch = useAppDispatch();

    const handleCardClick = (e, id) => {
        const excludeElement = document.getElementById("excludeElement");
        if (excludeElement && !excludeElement.contains(e.target)) {
            setTaskId(id);
        }
    };

    useEffect(() => {
        if (Boolean(taskId)) {
            setShowTaskDetails(true);
        }
    }, [taskId]);

    const {
        fetchingTaskList,
        fetchTaskListSuccess,
        updateTaskSuccess,
        fetchActiveTaskTwoSuccess,
        fetchPendingTaskTwoSuccess,
        fetchCompletedTaskTwoSuccess,
        fetchOverdueTaskTwoSuccess,
        fetchMyTaskTwoSuccess,
        fetchAllTaskTwoSuccess,
    } = useAppSelector((state) => state.task);

    const dashboardContent = document.getElementById("scrollableTable");

    const taskTitle = [
        fetchAllTaskTwoSuccess?.totalCount !== 0 && {
            id: 1,
            icon: "ri-list-unordered",
            title: "All tasks",
            name: "all",
            total: fetchAllTaskTwoSuccess?.totalCount,
        },
        fetchActiveTaskTwoSuccess?.totalCount !== 0 && {
            id: 1,
            icon: "ri-flashlight-line",
            title: "Active tasks",
            name: "on-track",
            total: fetchActiveTaskTwoSuccess?.totalCount,
        },
        fetchPendingTaskTwoSuccess?.totalCount !== 0 && {
            id: 1,
            icon: "ri-hourglass-line",
            title: "Pending tasks ",
            name: "pending",
            total: fetchPendingTaskTwoSuccess?.totalCount,
        },
        fetchCompletedTaskTwoSuccess?.totalCount !== 0 && {
            id: 1,
            icon: "ri-checkbox-multiple-line",
            title: "Completed tasks",
            name: "completed",
            total: fetchCompletedTaskTwoSuccess?.totalCount,
        },
        fetchOverdueTaskTwoSuccess?.totalCount !== 0 && {
            id: 1,
            icon: "ri-alarm-warning-line",
            title: "Overdue tasks",
            name: "delayed",
            total: fetchOverdueTaskTwoSuccess?.totalCount,
        },
        fetchMyTaskTwoSuccess?.totalCount !== 0 && {
            id: 1,
            icon: "ri-user-add-line",
            title: "Assigned to me",
            name: "assignee",
            total: fetchMyTaskTwoSuccess?.totalCount,
        },
    ].filter(Boolean);

    const tableHeader = [
        { title: `Tasks (${fetchAllTaskTwoSuccess?.totalCount})`, widthClass: "w-[25%]" },
        { title: `Assignee`, widthClass: "w-[14%]" },
        { title: "Status", widthClass: "w-[10%]" },
        { title: "Date created", widthClass: "w-[10%]" },
        { title: "Due date", widthClass: "w-[10%]" },
        { title: "", widthClass: "w-[4%]" },
    ];

    const handleSearch = (e) => {
        const { name, value } = e.target;

        setFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleDebouncedChange = debounce((e) => {
        const { name, value } = e.target;

        setDebouncedFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, 1500);
    useEffect(() => {
        if (debouncedFilter.search !== "") {
            dispatch(taskActions.fetchTaskList(taskLimit, "", "", debouncedFilter.search));
        }
    }, [dispatch, taskLimit, debouncedFilter.search]);

    useEffect(() => {
        dispatch(taskActions.fetchPendingTaskList(taskLimit, "pending"));
        dispatch(taskActions.fetchOverdueTaskList(taskLimit, "delayed"));
        dispatch(taskActions.fetchCompletedTaskList(taskLimit, "completed"));
        dispatch(taskActions.fetchActiveTaskList(taskLimit, "on-track"));
        dispatch(taskActions.fetchMyTaskList(taskLimit, "", "assignee"));
        dispatch(taskActions.fetchAllTaskList(taskLimit));
    }, [dispatch, taskLimit]);

    useEffect(() => {
        dispatch(taskActions.fetchPendingTaskTwoList(taskLimit, "pending"));
        dispatch(taskActions.fetchOverdueTaskTwoList(taskLimit, "delayed"));
        dispatch(taskActions.fetchCompletedTaskTwoList(taskLimit, "completed"));
        dispatch(taskActions.fetchActiveTaskTwoList(taskLimit, "on-track"));
        dispatch(taskActions.fetchMyTaskTwoList(taskLimit, "", "assignee"));
        dispatch(taskActions.fetchAllTaskTwoList(taskLimit));
    }, [dispatch]);

    useEffect(() => {
        if (tab?.toLowerCase() === "assignee") {
            dispatch(taskActions.fetchTaskList(taskLimit, "", tab, debouncedFilter.search));
        } else if (tab?.toLowerCase() === "all") {
            dispatch(taskActions.fetchTaskList(taskLimit, "", "", debouncedFilter.search));
        } else {
            dispatch(taskActions.fetchTaskList(taskLimit, tab, "", debouncedFilter.search));
        }
    }, [dispatch, taskLimit, tab, debouncedFilter.search]);

    useEffect(() => {
        if (Boolean(fetchTaskListSuccess)) {
            setTaskList(fetchTaskListSuccess?.tasks);
            setTaskPagination({
                current: fetchTaskListSuccess?.pagination?.current,
                number_of_pages: fetchTaskListSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchTaskListSuccess]);

    const onTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                taskPagination?.current as number,
                taskPagination?.number_of_pages as number,
                () => {
                    setTaskLimit(() => taskLimit + limit);
                    setLoadMoreTask(true);
                }
            ),
        [dashboardContent, taskPagination]
    );

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onTableScroll);
        };
    }, [dashboardContent, onTableScroll]);

    const handleStatusChange = (id, status) => {
        const updatedTaskList = taskList.map((task) => {
            if (task._id === id) {
                return {
                    ...task,
                    status,
                };
            }
            return task;
        });
        setTaskList(updatedTaskList);

        const body = {
            status,
        };
        if (id) {
            dispatch(taskActions.updateTask(body, id));
        }
    };

    useEffect(() => {
        if (Boolean(updateTaskSuccess)) {
            if (tab?.toLowerCase() === "assignee") {
                dispatch(taskActions.fetchTaskList(taskLimit, "", tab));
            } else if (tab?.toLowerCase() === "all") {
                dispatch(taskActions.fetchTaskList(taskLimit, "", ""));
            } else {
                dispatch(taskActions.fetchTaskList(taskLimit, tab));
                dispatch(taskActions.fetchPendingTaskList(taskLimit, "pending"));
                dispatch(taskActions.fetchOverdueTaskList(taskLimit, "delayed"));
                dispatch(taskActions.fetchCompletedTaskList(taskLimit, "completed"));
                dispatch(taskActions.fetchActiveTaskList(taskLimit, "on-track"));
                dispatch(taskActions.fetchPendingTaskTwoList(taskLimit, "pending"));
                dispatch(taskActions.fetchOverdueTaskTwoList(taskLimit, "delayed"));
                dispatch(taskActions.fetchCompletedTaskTwoList(taskLimit, "completed"));
                dispatch(taskActions.fetchActiveTaskTwoList(taskLimit, "on-track"));
                dispatch(taskActions.fetchMyTaskTwoList(taskLimit, "", "assignee"));
                dispatch(taskActions.fetchAllTaskTwoList(taskLimit));
            }
            dispatch(taskActions.resetUpdateTask());
        }
    }, [dispatch, updateTaskSuccess, taskLimit, tab]);

    const tableBody = taskList?.map((task) => [
        {
            content: (
                <div className="flex pr-3 items-center py-[18px] space-x-1.5" key={task?._id}>
                    <div className="h-5 w-5 flex justify-center items-center rounded-[4px] bg-n-20">
                        <i className="ri-flashlight-fill text-[13px] text-p-50"></i>
                    </div>
                    <p
                        className={`text-[14px] text-slate-900 font-rocGroteskMedium whitespace-nowrap`}
                    >
                        {truncate(camelCaseToRegularCase(task?.name), {
                            length: 40,
                        })}
                    </p>
                </div>
            ),
            cellClickAction: (e) => {
                handleCardClick(e, task?._id);
            },
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 font-rocGroteskMedium text-sm text-slate-900 `}
                >
                    {task?.action?.assignee ? (
                        <div
                            className="flex items-center space-x-2"
                            key={task?.action?.assignee[0]?._id}
                        >
                            <VendorAvatar
                                name={task?.action?.assignee[0]?.email}
                                size={30}
                                imageSrc={task?.action?.assignee[0]?.avatar}
                            />
                            <span>{task?.action?.assignee[0]?.fullName}</span>
                        </div>
                    ) : (
                        <div className="w-5 h-5 p-2 rounded bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100% flex items-center justify-center">
                            <i className="ri-robot-line text-white"></i>
                        </div>
                    )}
                </div>
            ),
        },
        {
            content: (
                <div
                    id="excludeElement"
                    className={`py-3 pr-3  border-slate-100 text-sm text-slate-900 font-rocGroteskMedium `}
                    onClick={(e) => e.stopPropagation()}
                >
                    {task?.action?.assignee ? (
                        <>
                            {task?.status?.toLowerCase() === "completed" ? (
                                <div className="bg-g-25  px-[15px] text-slate-900 font-rocGroteskMedium py-[5px] text-xs rounded-[20px] text-center flex items-center">
                                    Completed
                                </div>
                            ) : (
                                <Dropdown
                                    dropdownTriggerClassName={" "}
                                    disabled={task?.action?.assignee ? false : true}
                                    dropdown={
                                        <div className="flex space-x-4">
                                            <div
                                                className={`${
                                                    task?.status?.toLowerCase() === "delayed" &&
                                                    "bg-r-25"
                                                } ${
                                                    task?.status?.toLowerCase() === "completed" &&
                                                    "bg-g-25"
                                                } 
                               ${task?.status?.toLowerCase() === "pending" && "bg-light-yellow"} 
                               ${
                                   task?.status?.toLowerCase() === "on-track" && "bg-p-25"
                               }  px-[15px] py-[4px] text-slate-900 font-rocGroteskMedium text-xs rounded-[20px] text-center flex items-center`}
                                            >
                                                {task?.status?.toLowerCase() === "delayed"
                                                    ? "Overdue"
                                                    : task?.status?.toLowerCase() === "on-track"
                                                    ? "Active"
                                                    : task?.status?.charAt(0).toUpperCase() +
                                                      task?.status?.slice(1)}
                                            </div>
                                            <i className="ri-arrow-down-s-line text-lg text-gm-40"></i>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 -left-[10px] !mt-3  border border-slate-100 w-[159px] rounded !max-h-fit
                       `}
                                    name={"faq-dropdown"}
                                    dropdownOptions={[
                                        {
                                            label: (
                                                <div className="bg-g-25 px-[15px] text-slate-900 font-rocGroteskMedium py-[4px] text-xs rounded-[20px] text-center flex items-center">
                                                    Completed
                                                </div>
                                            ),

                                            value: "completed",
                                            action: () =>
                                                handleStatusChange(task?._id, "completed"),
                                        },
                                        {
                                            label: (
                                                <div className="bg-r-25 px-[15px] text-slate-900 font-rocGroteskMedium py-[4px] text-xs rounded-[20px] text-center flex items-center">
                                                    Overdue
                                                </div>
                                            ),
                                            value: "delayed",
                                            action: () => handleStatusChange(task?._id, "delayed"),
                                        },
                                        {
                                            label: (
                                                <div className="bg-p-25 px-[15px] text-slate-900 font-rocGroteskMedium py-[4px] text-xs rounded-[20px] text-center flex items-center">
                                                    Active
                                                </div>
                                            ),
                                            value: "on-track",
                                            action: () => handleStatusChange(task?._id, "on-track"),
                                        },
                                        {
                                            label: (
                                                <div className="bg-light-yellow px-[15px] text-slate-900 font-rocGroteskMedium py-[4px] text-xs rounded-[20px] text-center flex items-center">
                                                    Pending
                                                </div>
                                            ),
                                            value: "pending",
                                            action: () => handleStatusChange(task?._id, "pending"),
                                        },
                                    ]}
                                />
                            )}
                        </>
                    ) : (
                        <div className="bg-g-25  px-[15px] text-slate-900 font-rocGroteskMedium py-[5px] text-xs rounded-[20px] text-center flex items-center">
                            {task?.status?.toLowerCase() === "delayed"
                                ? "Overdue"
                                : task?.status?.toLowerCase() === "on-track"
                                ? "Active"
                                : task?.status?.charAt(0).toUpperCase() + task?.status?.slice(1)}
                        </div>
                    )}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm text-slate-900 font-rocGroteskMedium `}
                >
                    <div className="flex items-center gap-3">
                        <span>{moment(task?.createdAt).format("MMM. Do, YYYY")}</span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm text-slate-900 font-rocGroteskMedium `}
                >
                    <div className="flex flex-col items-center">
                        <span>{moment(task?.endDate).format("MMM. Do, YYYY")}</span>
                        {task?.status?.toLowerCase() === "delayed" && (
                            <span className="text-r-50 text-[10px] font-rocGroteskMedium">
                                {calculateRemainingTime(task?.endDate)}
                            </span>
                        )}
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-[18px] flex justify-center  items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    {/* <Dropdown
                        dropdownTriggerClassName={" "}
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-700"></i>}
                        dropdownClassname={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-4 -left-[150px]  border border-slate-100 w-[159px] rounded !max-h-fit
                            `}
                        dropdownItemsClasses={`last:text-r-50 last:hover:bg-r-25`}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "View runs",
                                value: "view runs",
                                action: () => {
                                    navigate(`/dashboard/workflow/run/`);
                                },
                            },
                            {
                                label: "View activity log",
                                value: "view activity log",
                                action: () => {},
                            },
                            {
                                label: "Edit workflow name",
                                value: "edit workflow name",
                                action: () => {},
                            },
                        ]}
                    /> */}
                </div>
            ),
        },
    ]);

    const mobileTableBody = taskList?.map((task, idx) => {
        return {
            cellClickAction: (e) => {
                handleCardClick(e, task?._id);
            },
            topString: camelCaseToRegularCase(task?.name) + task?._id,
            topContent: (
                <div className="flex items-center gap-3">
                    <div className="h-5 w-5 flex justify-center items-center rounded-[4px] bg-n-20">
                        <i className="ri-flashlight-fill text-[13px] text-p-50"></i>
                    </div>

                    <div className="">
                        <p className="text-sm font-rocGroteskMedium mb-1.5">
                            {truncate(camelCaseToRegularCase(task?.name), { length: 30 })}
                        </p>
                        <p
                            className={`text-sm font-rocGroteskMedium ${
                                task?.status?.toLowerCase() === "delayed" && "text-r-50"
                            } ${task?.status?.toLowerCase() === "completed" && "text-g-50"} 
                                    ${
                                        task?.status?.toLowerCase() === "pending" &&
                                        "text-[#FF8A00]"
                                    } 
                                    ${task?.status?.toLowerCase() === "on-track" && "text-p-40"}`}
                        >
                            {task?.status?.toLowerCase() === "delayed"
                                ? "Overdue"
                                : task?.status?.toLowerCase() === "on-track"
                                ? "Active"
                                : task?.status?.charAt(0).toUpperCase() + task?.status?.slice(1)}
                        </p>
                    </div>
                </div>
            ),

            rightIcon: <i className="ri-arrow-right-s-line text-2xl"></i>,
        };
    });
    return (
        <PageFrame
            containerClassName={"!h-[calc(100vh-108px)]"}
            isLoading={fetchingTaskList && taskList?.length === 0}
        >
            <div className="mt-5 ">
                <div className="flex space-x-[14px] max-lg:flex-nowrap max-lg:w-full max-lg:overflow-x-auto">
                    {taskTitle?.map((task, idx) => (
                        <div
                            key={idx}
                            className={`flex cursor-pointer max-lg:flex-shrink-0 font-rocGroteskMedium items-center shadow-boxShadow-2 space-x-2 w-fit border ${
                                tab === task?.name?.toLowerCase()
                                    ? "border-gm-50 bg-gm-50 text-white"
                                    : "border-n-20 bg-neutral-50 text-gm-40"
                            } px-4 py-1.5 rounded-[30px]`}
                            onClick={() => {
                                task?.name === ""
                                    ? navigate(
                                          `/dashboard/task/1?show=${task?.name?.toLowerCase()}`
                                      )
                                    : navigate(
                                          `/dashboard/task/1?show=${task?.name?.toLowerCase()}`
                                      );
                            }}
                        >
                            <i className={`${task?.icon} text-base`}></i>
                            <p className="text-[13px] ">
                                {task?.title} ({task?.total})
                            </p>
                        </div>
                    ))}
                </div>
                <div className="flex mt-9 items-center gap-3 max-sm:w-full">
                    <div className="max-sm:w-full">
                        <TextInput
                            name={"search"}
                            value={filter.search}
                            type={"text"}
                            inputPlaceholder={"Search"}
                            inputClassName={"!h-[40px] pl-[0px] !bg-white text-sm !mb-0"}
                            onChange={handleSearch}
                            onInput={handleDebouncedChange}
                            leftIcon={
                                <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                            }
                            inputContainerClassname={
                                "!rounded-[4px] !w-[300px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                            }
                        />
                    </div>

                    {/* <div>
                        <Dropdown
                            value={""}
                            dropdown={
                                <div className="flex items-center cursor-pointer gap-2 py-1.5 px-3 bg-white rounded shadow-boxShadow-2 border border-n-20">
                                    <i className="ri-filter-3-fill"></i>
                                    <p className="text-[13px] font-rocGroteskMedium">Filter</p>
                                </div>
                            }
                            dropdownContainerClasses={
                                " left-[-234px] !py-0 top-[49px] shadow-dropdownShadow rounded !border-0"
                            }
                            dropdownClassName={"!max-h-fit"}
                            name={"quickAction"}
                        />
                    </div> */}
                </div>
                <div
                    className="mt-8 overflow-y-auto  pb-40 max-h-[calc(100vh-220px)]"
                    id="scrollableTable"
                >
                    {taskList?.length > 0 ? (
                        <div className="">
                            <div className="max-lg:hidden">
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isScrollable={false}
                                    isCellBordered={false}
                                    isCellSelectable={true}
                                    isAllSelectable={true}
                                    bodyItemClass="hover:border-slate-100 hover:bg-slate-50 !border-t"
                                    headerContainerClass="!bg-slate-50 !border-slate-100 "
                                    tableClass="mb-12"
                                    headerItemClass="font-rocGroteskMedium "
                                />
                            </div>
                            <div className="hidden max-lg:block">
                                <CustomMobileTable data={mobileTableBody} />
                            </div>
                            {loadMoreTask && fetchingTaskList && (
                                <div className="flex my-4 justify-center">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {taskPagination?.current === taskPagination?.number_of_pages &&
                                !fetchingTaskList && (
                                    <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                        End of list
                                    </div>
                                )}
                        </div>
                    ) : (
                        <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                            <div className="space-y-2  text-center">
                                <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                                <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                    {filter.search ? "No task found" : "No task yet"}
                                </p>
                                <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                    {filter.search
                                        ? "No task found for this search query"
                                        : "Recent task  will be displayed here"}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {showTaskDetails && (
                <TaskDetailsModal
                    id={taskId}
                    openModal={showTaskDetails}
                    closeModal={() => {
                        setShowTaskDetails(false);
                        setTaskId("");
                    }}
                />
            )}
        </PageFrame>
    );
};

export default TaskLists;
