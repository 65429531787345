import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import CustomToast from "components/common/CustomToast";
import Checkbox from "components/common/InputField/Checkbox";
import ModalContainer from "components/common/ModalContainer";
import PageFrame from "components/layout/PageFrame";
import { ItemDetailsCard } from "components/views/Ldb/Dashboard/Quotes/QuoteItemResponse";
import { displayFileIcon, formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import { truncate } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { orderActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks";

const QuoteVendorDetails = () => {
    const [closeVendorDetails, setCloseVendorDetails] = useState(true);
    const [closeOrderDetails, setCloseOrderDetails] = useState(true);
    const [acceptDeleteModal, setAcceptDeleteModal] = useState("");
    const [responseDetails, setResponseDetails] = useState<{ [key: string]: any }>({});
    const [otherResponses, setOtherResponses] = useState([]);
    const dispatch = useAppDispatch();
    const { id, quoteId, itemId } = useParams();
    const [params] = useSearchParams();
    const item = params?.get("item");
    const {
        fetchingSingleResponse,
        fetchedSingleResponseSuccess,
        acceptQuote,
        acceptQuoteSuccess,
        fetchingSingleQuoteResponses,
        fetchedSingleQuoteResponsesSuccess,
    } = useAppSelector((state) => state.order);
    const tableHeader = [
        { title: "Item/Service", widthClass: "w-[55%] " },
        { title: "Quantity", widthClass: "w-[19%]" },
        {
            title: `Unit Price (${getCurrencyFromCurrencyCode(
                responseDetails?.charges?.[0]?.amount?.currency
            )})`,
            widthClass: "w-[19%]",
        },
        {
            title: `Total Price (${getCurrencyFromCurrencyCode(
                responseDetails?.charges?.[0]?.amount?.currency
            )})`,
            widthClass: "w-[19%]",
        },
    ];

    const tableBody = responseDetails?.charges?.map((charge) => {
        return [
            {
                content: (
                    <div
                        className={`py-[14px] pr-4 border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                    >
                        <p className="text-slate-700 leading-5">
                            {truncate(charge?.description, {
                                length: 40,
                            })}{" "}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[14px] pr-4 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-700 `}
                    >
                        {charge?.quantity}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[14px] pr-4 border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                    >
                        <p className="text-slate-700 ">
                            {getCurrencyFromCurrencyCode(charge?.amount?.currency)}
                            {formatMoney()?.format(charge?.amount?.amount)}{" "}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[14px] pr-4 border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                    >
                        <p className="text-slate-700 ">
                            {getCurrencyFromCurrencyCode(charge?.amount?.currency)}
                            {formatMoney()?.format(charge?.amount?.amount * charge?.quantity)}{" "}
                        </p>
                    </div>
                ),
            },
        ];
    });

    const analysisTableHeader = [
        { title: "Criteria", widthClass: "w-[61%] " },
        {
            title: "Successful",
            widthClass: "w-[12%] bg-[#F0FDF4]",
            itemClass: "flex justify-center",
        },
        { title: "At risk", widthClass: "w-[11%] bg-[#FFFBEB]", itemClass: "flex justify-center" },
        { title: "Failed", widthClass: "w-[11%] bg-[#FEF2F2]", itemClass: "flex justify-center" },
    ];

    const analysisTableBody = [
        [
            {
                content: (
                    <div
                        className={`py-[14px] pr-3 border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                    >
                        <p className="text-slate-700 mb-1">Payment terms</p>
                        <p className="text-slate-500">
                            {responseDetails?.analysis?.paymentTerms?.result || "N/A"}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[14px] pr-3 w-full flex font-rocGroteskMedium justify-center items-center h-full border-slate-100 text-sm text-slate-700 `}
                    >
                        {responseDetails?.analysis?.paymentTerms?.status?.toLowerCase() ===
                        "successful" ? (
                            <i className="ri-check-fill text-carribean-green text-3xl"></i>
                        ) : (
                            <p className="text-slate-700 ">----- </p>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[14px] pr-3 w-full flex justify-center border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                    >
                        {responseDetails?.analysis?.paymentTerms?.status?.toLowerCase() ===
                        "at_risk" ? (
                            <i className="ri-check-fill text-carribean-green text-3xl"></i>
                        ) : (
                            <p className="text-slate-700 ">----- </p>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[14px] pr-3 w-full flex justify-center border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                    >
                        {responseDetails?.analysis?.paymentTerms?.status?.toLowerCase() ===
                        "failed" ? (
                            <i className="ri-check-fill text-carribean-green text-3xl"></i>
                        ) : (
                            <p className="text-slate-700 ">----- </p>
                        )}
                    </div>
                ),
            },
        ],
        [
            {
                content: (
                    <div
                        className={`py-[14px] pr-3 border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                    >
                        <p className="text-slate-700 mb-1">Pricing</p>
                        <p className="text-slate-500">
                            {responseDetails?.analysis?.pricing?.result || "N/A"}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[14px] pr-3 w-full flex font-rocGroteskMedium justify-center items-center h-full border-slate-100 text-sm text-slate-700 `}
                    >
                        {responseDetails?.analysis?.pricing?.status?.toLowerCase() ===
                        "successful" ? (
                            <i className="ri-check-fill text-carribean-green text-3xl"></i>
                        ) : (
                            <p className="text-slate-700 ">----- </p>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[14px] pr-3 w-full flex justify-center border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                    >
                        {responseDetails?.analysis?.pricing?.status?.toLowerCase() === "at_risk" ? (
                            <i className="ri-check-fill text-carribean-green text-3xl"></i>
                        ) : (
                            <p className="text-slate-700 ">----- </p>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[14px] pr-3 w-full flex justify-center border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                    >
                        {responseDetails?.analysis?.pricing?.status?.toLowerCase() === "failed" ? (
                            <i className="ri-check-fill text-carribean-green text-3xl"></i>
                        ) : (
                            <p className="text-slate-700 ">----- </p>
                        )}
                    </div>
                ),
            },
        ],
        [
            {
                content: (
                    <div
                        className={`py-[14px] pr-3 border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                    >
                        <p className="text-slate-700 mb-1">Delivery timeline</p>
                        <p className="text-slate-500">
                            {responseDetails?.analysis?.deliveryDate?.result || "N/A"}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[14px] pr-3 w-full flex font-rocGroteskMedium justify-center items-center h-full border-slate-100 text-sm text-slate-700 `}
                    >
                        {responseDetails?.analysis?.deliveryDate?.status?.toLowerCase() ===
                        "successful" ? (
                            <i className="ri-check-fill text-carribean-green text-3xl"></i>
                        ) : (
                            <p className="text-slate-700 ">----- </p>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[14px] pr-3 w-full flex justify-center border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                    >
                        {responseDetails?.analysis?.deliveryDate?.status?.toLowerCase() ===
                        "at_risk" ? (
                            <i className="ri-check-fill text-carribean-green text-3xl"></i>
                        ) : (
                            <p className="text-slate-700 ">----- </p>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[14px] pr-3 w-full flex justify-center border-slate-100 text-sm font-rocGroteskMedium text-slate-700 `}
                    >
                        {responseDetails?.analysis?.deliveryDate?.status?.toLowerCase() ===
                        "failed" ? (
                            <i className="ri-check-fill text-carribean-green text-3xl"></i>
                        ) : (
                            <p className="text-slate-700 ">----- </p>
                        )}
                    </div>
                ),
            },
        ],
    ];

    const acceptRejectQuote = () => {
        const body = {
            acceptance: acceptDeleteModal === "accept" ? true : false,
            quoteResponseId: id,
        };

        dispatch(orderActions.acceptQuote(body));
    };

    useEffect(() => {
        if (Boolean(acceptQuoteSuccess)) {
            setResponseDetails(acceptQuoteSuccess?.quote);
            toast.custom((t) => (
                <CustomToast t={t} message={`Quote ${acceptDeleteModal}ed`} type={"success"} />
            ));
            setAcceptDeleteModal("");
            dispatch(orderActions?.resetAcceptQuoteSuccess());
        }
    }, [dispatch, acceptQuoteSuccess]);

    useEffect(() => {
        dispatch(orderActions.getSingleQuoteResponse(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (Boolean(fetchedSingleResponseSuccess)) {
            setResponseDetails(fetchedSingleResponseSuccess?.quoteResponse);
        }
    }, [fetchedSingleResponseSuccess]);

    useEffect(() => {
        if (responseDetails?.rfq?._id) {
            dispatch(orderActions.getSingleQuoteResponses(responseDetails?.rfq?._id, 10));
        }
    }, [dispatch, responseDetails?.rfq?._id]);

    useEffect(() => {
        if (Boolean(fetchedSingleQuoteResponsesSuccess)) {
            setOtherResponses(fetchedSingleQuoteResponsesSuccess?.quoteResponses);
        }
    }, [fetchedSingleQuoteResponsesSuccess]);
    return (
        <PageFrame isLoading={fetchingSingleResponse} containerClassName={""}>
            <div className="h-full w-full px-2  relative flex sm:px-[20px]  lg:px-[28px] ">
                <div className="xl:w-[71.8%] lg:w-[70.5%] w-full shadow  bg-white z-10 rounded  lg:rounded-tl-[12px] lg:rounded-bl-[12px] left-0 pt-5 pb-4  h-fit overflow-y-auto">
                    <div className="lg:px-[34px] px-2  border-b border-slate-100 pb-4">
                        <BreadCrumb
                            parentCrumb={"Quotes"}
                            parentUrl={"/dashboard/quotes"}
                            childrenCrumb={[
                                { title: "Quote responses", path: `/dashboard/quotes/${quoteId}` },
                                {
                                    title: "Item quote",
                                    path: `/dashboard/quotes/${itemId}/responses?item=${item}&quoteId=${quoteId}`,
                                },
                                {
                                    title: "quote details",
                                    path: "/dashboard/quotes/1/item/quote-detail",
                                },
                            ]}
                            size="small"
                        />
                        <h6 className="lg:text-xl lg:px-0 px-2 text-base text-slate-900 font-rocGroteskMedium mt-2">
                            {responseDetails?.item?.description}
                        </h6>
                    </div>
                    <div className="w-full">
                        <div className="">
                            <ItemDetailsCard
                                showSuggestionQuote={responseDetails?.isSuggested}
                                title={responseDetails?.vendor?.profile?.businessName}
                                email={responseDetails?.vendor?.email}
                                logo={responseDetails?.vendor?.profile?.avatar}
                                amount={formatMoney().format(responseDetails?.amount?.amount)}
                                currency={getCurrencyFromCurrencyCode(
                                    responseDetails?.amount?.currency
                                )}
                                sentAt={moment(responseDetails?.createdAt).fromNow()}
                                rfqTaId={responseDetails?.rfq?.taId}
                            />
                            <div className="border-b pb-5 border-slate-100">
                                <div className="lg:px-[32px] px-4">
                                    <h4 className="text-slate-900  text-base font-rocGroteskMedium lg:text-xl">
                                        Quote details
                                    </h4>
                                    <div className="w-full mt-5">
                                        <div className="lg:block hidden">
                                            <CustomTable
                                                tableBody={tableBody}
                                                tableHeader={tableHeader}
                                                isAllSelectable={false}
                                                isScrollable={false}
                                                isCellBordered={true}
                                                tableClass="!rounded-[8px]"
                                                headerItemClass="font-rocGroteskMedium !text-slate-700 !text-[12px] "
                                            />
                                        </div>
                                        {responseDetails?.charges?.map((charge) => (
                                            <div className="space-y-1 mb-1 lg:hidden block">
                                                <div className=" w-full flex bg-[#F4F5F7] font-rocGroteskMedium">
                                                    <p className="border px-2  w-[40%] text-[12px] leading-5 text-[#334155] border-[#DFE1E6] text-center py-[10px]">
                                                        Item/service
                                                    </p>
                                                    <p className="border-r border-b text-sm  border-[#DFE1E6] text-slate-900 border-t px-2 w-[60%] text-center py-[10px]">
                                                        {truncate(charge?.description, {
                                                            length: 40,
                                                        })}{" "}
                                                    </p>
                                                </div>
                                                <div className=" w-full flex bg-[#F4F5F7] font-rocGroteskMedium">
                                                    <p className="border px-2  w-[40%] text-[12px] leading-5 text-[#334155] border-[#DFE1E6] text-center py-[10px]">
                                                        Quantity
                                                    </p>
                                                    <p className="border-r border-b text-sm  border-[#DFE1E6] text-slate-900 border-t px-2 w-[60%] text-center py-[10px]">
                                                        {charge?.quantity}
                                                    </p>
                                                </div>
                                                <div className=" w-full flex bg-[#F4F5F7] font-rocGroteskMedium">
                                                    <p className="border px-2  w-[40%] text-[12px] leading-5 text-[#334155] border-[#DFE1E6] text-center py-[10px]">
                                                        Unit Price ($)
                                                    </p>
                                                    <p className="border-r border-b text-sm  border-[#DFE1E6] text-slate-900 border-t px-2 w-[60%] text-center py-[10px]">
                                                        {getCurrencyFromCurrencyCode(
                                                            charge?.amount?.currency
                                                        )}
                                                        {formatMoney()?.format(
                                                            charge?.amount?.amount
                                                        )}{" "}
                                                    </p>
                                                </div>
                                                <div className=" w-full flex bg-[#F4F5F7] font-rocGroteskMedium">
                                                    <p className="border px-2  w-[40%] text-[12px] leading-5 text-[#334155] border-[#DFE1E6] text-center py-[10px]">
                                                        Total Price ($)
                                                    </p>
                                                    <p className="border-r border-b text-sm  border-[#DFE1E6] text-slate-900 border-t px-2 w-[60%] text-center py-[10px]">
                                                        {getCurrencyFromCurrencyCode(
                                                            charge?.amount?.currency
                                                        )}
                                                        {formatMoney()?.format(
                                                            charge?.amount?.amount *
                                                                charge?.quantity
                                                        )}{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                        {responseDetails?.documents?.map((doc) => {
                                            return (
                                                <a
                                                    key={doc?.path}
                                                    href={doc?.path}
                                                    download={doc?.filename}
                                                    className="bg-white mt-10 cursor-pointer border flex items-center py-3 lg:py-4 px-3 justify-between  rounded-[6px]  border-slate-200"
                                                >
                                                    <div className="space-x-2 flex items-center ">
                                                        {displayFileIcon(
                                                            getFileTypeFromUrl(doc?.path),
                                                            true,
                                                            "!w-4 !h-4"
                                                        )}
                                                        <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                                            {truncate(doc?.filename || doc?.name, {
                                                                length: 30,
                                                            })}
                                                        </p>
                                                    </div>
                                                    <div className="cursor-pointer">
                                                        <i className="ri-download-line text-[20px] text-gm-50"></i>
                                                    </div>
                                                </a>
                                            );
                                        })}

                                        <div className="mt-12 mb-3">
                                            <h4 className="text-slate-900 flex mb-8 font-rocGroteskMedium text-base lg:text-xl">
                                                Quote Analysis{" "}
                                                <span className="lg:hidden block pl-1">
                                                    {" "}
                                                    - criteria
                                                </span>
                                            </h4>
                                            <div className="space-y-4 md:block hidden">
                                                <CustomTable
                                                    tableBody={analysisTableBody}
                                                    tableHeader={analysisTableHeader}
                                                    isAllSelectable={false}
                                                    isScrollable={false}
                                                    isCellBordered={true}
                                                    tableClass="!rounded-[8px]"
                                                    headerItemClass="font-rocGroteskMedium !text-slate-700 !text-[12px] "
                                                />
                                            </div>
                                            <div>
                                                <div className="space-y-4 md:hidden block border-b pb-3 mb-5">
                                                    <div className="font-rocGroteskMedium">
                                                        <p className="text-sm text-slate-900 mb-1">
                                                            Payment terms
                                                        </p>
                                                        <p className="text-sm text-slate-500">
                                                            Payment terms of this quote might not
                                                            meet the payment terms your customers
                                                            gave as it is a 15 days payment terms
                                                            while your customer will pay you in 30
                                                            days
                                                        </p>
                                                        <div className="flex xs:flex-row gap-3 flex-col xs:space-y-0 space-y-2 mt-4 md:space-x-[23px] items-center w-full justify-between">
                                                            <div className="flex  rounded w-full whitespace-nowrap justify-between bg-[#F0FDF4] py-2 px-2 items-center space-x-1 border">
                                                                <p className="text-xs text-slate-700">
                                                                    Successful
                                                                </p>
                                                                <Checkbox
                                                                    name="paymentSuccess"
                                                                    isChecked={
                                                                        responseDetails?.analysis?.paymentTerms?.status?.toLowerCase() ===
                                                                        "successful"
                                                                    }
                                                                    className={
                                                                        "h-6 w-6 !border-tradeally-neutral-200"
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="flex bg-[#FFFBEB] whitespace-nowrap rounded w-full justify-between py-2 px-2 items-center space-x-1 border">
                                                                <p className="text-xs text-slate-700">
                                                                    At risk
                                                                </p>
                                                                <Checkbox
                                                                    name="paymentAtRisk"
                                                                    isChecked={
                                                                        responseDetails?.analysis?.paymentTerms?.status?.toLowerCase() ===
                                                                        "at_risk"
                                                                    }
                                                                    className={
                                                                        "h-6 w-6 !border-tradeally-neutral-200"
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="flex rounded w-full whitespace-nowrap justify-between py-2 px-2 bg-[#FEF2F2] items-center space-x-1 border">
                                                                <p className="text-xs text-slate-700">
                                                                    Failed
                                                                </p>
                                                                <Checkbox
                                                                    name="paymentFailed"
                                                                    isChecked={
                                                                        responseDetails?.analysis?.paymentTerms?.status?.toLowerCase() ===
                                                                        "failed"
                                                                    }
                                                                    className={
                                                                        "h-6 w-6 !border-tradeally-neutral-200"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="space-y-4 md:hidden block border-b pb-3 mb-5">
                                                    <div className="font-rocGroteskMedium">
                                                        <p className="text-sm text-slate-900 mb-1">
                                                            Pricing
                                                        </p>
                                                        <p className="text-sm text-slate-500">
                                                            Although the total price of this quote
                                                            contributes to 30% of your customer
                                                            budget, it is the cheapest quote
                                                            received so far
                                                        </p>
                                                        <div className="flex  gap-3 xs:flex-row flex-col xs:space-y-0 space-y-2 mt-4 md:space-x-[23px] items-center w-full justify-between">
                                                            <div className="flex  rounded w-full whitespace-nowrap justify-between bg-[#F0FDF4] py-2 px-2 items-center space-x-1 border">
                                                                <p className="text-xs text-slate-700">
                                                                    Successful
                                                                </p>
                                                                <Checkbox
                                                                    name="pricingSuccess"
                                                                    isChecked={
                                                                        responseDetails?.analysis?.pricing?.status?.toLowerCase() ===
                                                                        "successful"
                                                                    }
                                                                    className={
                                                                        "h-6 w-6 !border-tradeally-neutral-200"
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="flex bg-[#FFFBEB] whitespace-nowrap rounded w-full justify-between py-2 px-2 items-center space-x-1 border">
                                                                <p className="text-xs text-slate-700">
                                                                    At risk
                                                                </p>
                                                                <Checkbox
                                                                    name="pricingAtRisk"
                                                                    isChecked={
                                                                        responseDetails?.analysis?.pricing?.status?.toLowerCase() ===
                                                                        "at_risk"
                                                                    }
                                                                    className={
                                                                        "h-6 w-6 !border-tradeally-neutral-200"
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="flex rounded w-full whitespace-nowrap justify-between py-2 px-2 bg-[#FEF2F2] items-center space-x-1 border">
                                                                <p className="text-xs text-slate-700">
                                                                    Failed
                                                                </p>
                                                                <Checkbox
                                                                    name="pricingFailed"
                                                                    isChecked={
                                                                        responseDetails?.analysis?.pricing?.status?.toLowerCase() ===
                                                                        "failed"
                                                                    }
                                                                    className={
                                                                        "h-6 w-6 !border-tradeally-neutral-200"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="space-y-4 md:hidden block border-b pb-3 mb-5">
                                                    <div className="font-rocGroteskMedium">
                                                        <p className="text-sm text-slate-900 mb-1">
                                                            Quality
                                                        </p>
                                                        <p className="text-sm text-slate-500">
                                                            The vendor provided you with an horrible
                                                            service the last time and has a rating
                                                            of 1
                                                        </p>
                                                        <div className="flex gap-3 xs:flex-row flex-col xs:space-y-0 space-y-2 mt-4 md:space-x-[23px] items-center w-full justify-between">
                                                            <div className="flex  rounded w-full whitespace-nowrap justify-between bg-[#F0FDF4] py-2 px-2 items-center space-x-1 border">
                                                                <p className="text-xs text-slate-700">
                                                                    Successful
                                                                </p>
                                                                <Checkbox
                                                                    name="qualitySuccess"
                                                                    isChecked={
                                                                        responseDetails?.analysis?.quality?.status?.toLowerCase() ===
                                                                        "successful"
                                                                    }
                                                                    className={
                                                                        "h-6 w-6 !border-tradeally-neutral-200"
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="flex bg-[#FFFBEB] whitespace-nowrap rounded w-full justify-between py-2 px-2 items-center space-x-1 border">
                                                                <p className="text-xs text-slate-700">
                                                                    At risk
                                                                </p>
                                                                <Checkbox
                                                                    name="qualityAtRisk"
                                                                    isChecked={
                                                                        responseDetails?.analysis?.quality?.status?.toLowerCase() ===
                                                                        "at_risk"
                                                                    }
                                                                    className={
                                                                        "h-6 w-6 !border-tradeally-neutral-200"
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="flex rounded w-full whitespace-nowrap justify-between py-2 px-2 bg-[#FEF2F2] items-center space-x-1 border">
                                                                <p className="text-xs text-slate-700">
                                                                    Failed
                                                                </p>
                                                                <Checkbox
                                                                    name="qualityFailed"
                                                                    isChecked={
                                                                        responseDetails?.analysis?.quality?.status?.toLowerCase() ===
                                                                        "failed"
                                                                    }
                                                                    className={
                                                                        "h-6 w-6 !border-tradeally-neutral-200"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="space-y-4 md:hidden block border-b pb-3 mb-5">
                                                    <div className="font-rocGroteskMedium">
                                                        <p className="text-sm text-slate-900 mb-1">
                                                            Delivery timeline
                                                        </p>
                                                        <p className="text-sm text-slate-500">
                                                            The delivery timeline from this vendor
                                                            is too close to the delivery date
                                                            requested by your customer
                                                        </p>
                                                        <div className="flex gap-3 xs:flex-row flex-col xs:space-y-0 space-y-2 mt-4 md:space-x-[23px] items-center w-full justify-between">
                                                            <div className="flex  rounded w-full whitespace-nowrap justify-between bg-[#F0FDF4] py-2 px-2 items-center space-x-1 border">
                                                                <p className="text-xs text-slate-700">
                                                                    Successful
                                                                </p>
                                                                <Checkbox
                                                                    name="deliverySuccess"
                                                                    isChecked={
                                                                        responseDetails?.analysis?.deliveryDate?.status?.toLowerCase() ===
                                                                        "successful"
                                                                    }
                                                                    className={
                                                                        "h-6 w-6 !border-tradeally-neutral-200"
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="flex bg-[#FFFBEB] whitespace-nowrap rounded w-full justify-between py-2 px-2 items-center space-x-1 border">
                                                                <p className="text-xs text-slate-700">
                                                                    At risk
                                                                </p>
                                                                <Checkbox
                                                                    name="deliveryAtRisk"
                                                                    isChecked={
                                                                        responseDetails?.analysis?.deliveryDate?.status?.toLowerCase() ===
                                                                        "at_risk"
                                                                    }
                                                                    className={
                                                                        "h-6 w-6 !border-tradeally-neutral-200"
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="flex rounded w-full whitespace-nowrap justify-between py-2 px-2 bg-[#FEF2F2] items-center space-x-1 border">
                                                                <p className="text-xs text-slate-700">
                                                                    Failed
                                                                </p>
                                                                <Checkbox
                                                                    name="deliveryFailed"
                                                                    isChecked={
                                                                        responseDetails?.analysis?.deliveryDate?.status?.toLowerCase() ===
                                                                        "failed"
                                                                    }
                                                                    className={
                                                                        "h-6 w-6 !border-tradeally-neutral-200"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {otherResponses?.length > 0 && (
                                            <h4 className="text-slate-700 mt-5 font-rocGroteskMedium text-[20px]">
                                                Similar responses ({otherResponses?.length})
                                            </h4>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {otherResponses?.map((response) => {
                                return (
                                    // <ItemDetailsCard showQuotesDownload />
                                    <ItemDetailsCard
                                        key={response?._id}
                                        showQuotesDownload
                                        documents={response?.documents}
                                        showSuggestionQuote={response?.isSuggested}
                                        title={response?.vendor?.profile?.businessName}
                                        email={response?.vendor?.email}
                                        logo={response?.vendor?.profile?.avatar}
                                        amount={formatMoney().format(response?.amount?.amount)}
                                        currency={getCurrencyFromCurrencyCode(
                                            response?.amount?.currency
                                        )}
                                        sentAt={moment(response?.createdAt).fromNow()}
                                        rfqTaId={response?.rfq?.taId}
                                    />
                                );
                            })}
                            <div className="hidden max-lg:block w-full h-full overflow-y-scroll pb-8">
                                <div>
                                    <div className="flex items-center mt-4 px-6 border-slate-100 justify-between py-3 border-t border-b">
                                        <h6 className="text-[16px] text-gm-50 leading-6 font-rocGroteskMedium">
                                            Vendor details
                                        </h6>
                                        <div
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setCloseVendorDetails(!closeVendorDetails)
                                            }
                                        >
                                            {closeVendorDetails ? (
                                                <i className="ri-arrow-down-s-line text-gm-50 "></i>
                                            ) : (
                                                <i className="ri-arrow-up-s-line text-gm-50 "></i>
                                            )}
                                        </div>
                                    </div>
                                    {closeVendorDetails && (
                                        <div className="px-6 mt-6">
                                            <div>
                                                <h5 className="text-[14px] leading-5 text-slate-700 font-rocGroteskMedium ">
                                                    {responseDetails?.vendor?.profile?.businessName}
                                                </h5>
                                            </div>
                                            <h6 className="text-[14px] font-rocGroteskRegular text-slate-500">
                                                {responseDetails?.vendor?.email}
                                            </h6>
                                            {/* <div className="flex items-center gap-1.5">
                                        <i className="ri-star-fill text-star-gold"></i>
                                        <i className="ri-star-fill text-star-gold"></i>
                                        <i className="ri-star-fill text-star-gold"></i>
                                        <i className="ri-star-line text-slate-300"></i>
                                        <i className="ri-star-line text-slate-300"></i>
                                    </div> */}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <div className="flex items-center mt-6 px-6 justify-between py-3 border-slate-100  border-b">
                                        <h6 className="text-[16px] leading-6 text-gm-50 font-rocGroteskMedium">
                                            Order details
                                        </h6>
                                        <div
                                            className="cursor-pointer"
                                            onClick={() => setCloseOrderDetails(!closeOrderDetails)}
                                        >
                                            {closeOrderDetails ? (
                                                <i className="ri-arrow-down-s-line text-gm-50 "></i>
                                            ) : (
                                                <i className="ri-arrow-up-s-line text-gm-50 "></i>
                                            )}
                                        </div>
                                    </div>
                                    {closeOrderDetails && (
                                        <div className="px-6 mt-5">
                                            <div className="mb-5">
                                                <p className="text-[14px] mb-1 text-gm-50 font-rocGroteskMedium leading-5">
                                                    Company
                                                </p>
                                                <p className="text-[14px] text-slate-500 font-rocGroteskRegular leading-5">
                                                    {
                                                        responseDetails?.order?.customerDetails
                                                            ?.companyName
                                                    }
                                                </p>
                                            </div>
                                            <div className="mb-5">
                                                <p className="text-[14px] mb-1 text-gm-50 font-rocGroteskMedium leading-5">
                                                    First name
                                                </p>
                                                <p className="text-[14px] text-slate-500 font-rocGroteskRegular leading-5">
                                                    {responseDetails?.order?.customerDetails
                                                        ?.firstNane || "N/A"}
                                                </p>
                                            </div>
                                            <div className="mb-5">
                                                <p className="text-[14px] mb-1 text-gm-50 font-rocGroteskMedium leading-5">
                                                    Last name
                                                </p>
                                                <p className="text-[14px] text-slate-500 font-rocGroteskRegular leading-5">
                                                    {responseDetails?.order?.customerDetails
                                                        ?.lastName || "N/A"}
                                                </p>
                                            </div>
                                            <div className="mb-5">
                                                <p className="text-[14px] mb-1 text-gm-50 font-rocGroteskMedium leading-5">
                                                    Email address
                                                </p>
                                                <p className="text-[14px] text-slate-500 font-rocGroteskRegular leading-5">
                                                    {responseDetails?.order?.customerDetails
                                                        ?.email || "N/A"}
                                                </p>
                                            </div>
                                            <div className="mb-5">
                                                <p className="text-[14px] mb-1 text-gm-50 font-rocGroteskMedium leading-5">
                                                    Phone
                                                </p>
                                                <p className="text-[14px] text-slate-500 font-rocGroteskRegular leading-5">
                                                    {
                                                        responseDetails?.order?.customerDetails
                                                            ?.phone?.mobile
                                                    }
                                                </p>
                                            </div>
                                            <div className="mb-5">
                                                <p className="text-[14px] mb-1 text-gm-50 font-rocGroteskMedium leading-5">
                                                    Country
                                                </p>
                                                <p className="text-[14px] text-slate-500 font-rocGroteskRegular leading-5">
                                                    {responseDetails?.order?.customerDetails
                                                        ?.country || "N/A"}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {responseDetails?.currentStatus !== "accepted" &&
                                    responseDetails?.currentStatus !== "rejected" && (
                                        <div className="flex w-full flex-col px-2 mt-3 items-center  whitespace-nowrap space-y-4">
                                            <Button
                                                btnClassname={"!bg-n-20 !w-full !text-gm-50"}
                                                btnText={"Reject quote"}
                                                type={"button"}
                                                onClick={() => setAcceptDeleteModal("reject")}
                                            />
                                            <Button
                                                btnClassname={"!w-full"}
                                                btnText={"Accept quote"}
                                                type={"button"}
                                                isLoading={false}
                                                onClick={() => setAcceptDeleteModal("accept")}
                                            />
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" xl:w-[calc(100%-79%)] 2xl:w-[calc(100%-78.4%)] lg:block hidden bg-white h-full lg:rounded-tr-[12px] lg:rounded-br-[12px] lg:border-l border-slate-100 z-[100] right-[46px] p-4 fixed">
                    <div className=" w-full h-full overflow-y-scroll pb-8">
                        {responseDetails?.currentStatus !== "accepted" &&
                            responseDetails?.currentStatus !== "rejected" && (
                                <div className="flex w-full flex-col px-8 items-center  whitespace-nowrap space-y-4">
                                    <Button
                                        btnClassname={"!bg-n-20 !text-gm-50"}
                                        btnText={"Reject quote"}
                                        type={"button"}
                                        onClick={() => setAcceptDeleteModal("reject")}
                                    />
                                    <Button
                                        btnText={"Accept quote"}
                                        type={"button"}
                                        isLoading={false}
                                        onClick={() => setAcceptDeleteModal("accept")}
                                    />
                                </div>
                            )}
                        <div>
                            <div className="flex items-center mt-4 px-6 border-slate-100 justify-between py-3 border-t border-b">
                                <h6 className="text-[16px] text-gm-50 leading-6 font-rocGroteskMedium">
                                    Vendor details
                                </h6>
                                <div
                                    className="cursor-pointer"
                                    onClick={() => setCloseVendorDetails(!closeVendorDetails)}
                                >
                                    {closeVendorDetails ? (
                                        <i className="ri-arrow-down-s-line text-gm-50 "></i>
                                    ) : (
                                        <i className="ri-arrow-up-s-line text-gm-50 "></i>
                                    )}
                                </div>
                            </div>
                            {closeVendorDetails && (
                                <div className="px-6 mt-6">
                                    <div>
                                        <h5 className="text-[14px] leading-5 text-slate-700 font-rocGroteskMedium ">
                                            {responseDetails?.vendor?.profile?.businessName}
                                        </h5>
                                    </div>
                                    <h6 className="text-[14px] font-rocGroteskRegular text-slate-500">
                                        {responseDetails?.vendor?.email}
                                    </h6>
                                    {/* <div className="flex items-center gap-1.5">
                                        <i className="ri-star-fill text-star-gold"></i>
                                        <i className="ri-star-fill text-star-gold"></i>
                                        <i className="ri-star-fill text-star-gold"></i>
                                        <i className="ri-star-line text-slate-300"></i>
                                        <i className="ri-star-line text-slate-300"></i>
                                    </div> */}
                                </div>
                            )}
                        </div>
                        <div>
                            <div className="flex items-center mt-6 px-6 justify-between py-3 border-slate-100  border-b">
                                <h6 className="text-[16px] leading-6 text-gm-50 font-rocGroteskMedium">
                                    Order details
                                </h6>
                                <div
                                    className="cursor-pointer"
                                    onClick={() => setCloseOrderDetails(!closeOrderDetails)}
                                >
                                    {closeOrderDetails ? (
                                        <i className="ri-arrow-down-s-line text-gm-50 "></i>
                                    ) : (
                                        <i className="ri-arrow-up-s-line text-gm-50 "></i>
                                    )}
                                </div>
                            </div>
                            {closeOrderDetails && (
                                <div className="px-6 mt-5">
                                    <div className="mb-5">
                                        <p className="text-[14px] mb-1 text-gm-50 font-rocGroteskMedium leading-5">
                                            Company
                                        </p>
                                        <p className="text-[14px] text-slate-500 font-rocGroteskRegular leading-5">
                                            {responseDetails?.order?.customerDetails?.companyName}
                                        </p>
                                    </div>
                                    <div className="mb-5">
                                        <p className="text-[14px] mb-1 text-gm-50 font-rocGroteskMedium leading-5">
                                            First name
                                        </p>
                                        <p className="text-[14px] text-slate-500 font-rocGroteskRegular leading-5">
                                            {responseDetails?.order?.customerDetails?.firstNane ||
                                                "N/A"}
                                        </p>
                                    </div>
                                    <div className="mb-5">
                                        <p className="text-[14px] mb-1 text-gm-50 font-rocGroteskMedium leading-5">
                                            Last name
                                        </p>
                                        <p className="text-[14px] text-slate-500 font-rocGroteskRegular leading-5">
                                            {responseDetails?.order?.customerDetails?.lastName ||
                                                "N/A"}
                                        </p>
                                    </div>
                                    <div className="mb-5">
                                        <p className="text-[14px] mb-1 text-gm-50 font-rocGroteskMedium leading-5">
                                            Email address
                                        </p>
                                        <p className="text-[14px] text-slate-500 font-rocGroteskRegular leading-5">
                                            {responseDetails?.order?.customerDetails?.email ||
                                                "N/A"}
                                        </p>
                                    </div>
                                    <div className="mb-5">
                                        <p className="text-[14px] mb-1 text-gm-50 font-rocGroteskMedium leading-5">
                                            Phone
                                        </p>
                                        <p className="text-[14px] text-slate-500 font-rocGroteskRegular leading-5">
                                            {responseDetails?.order?.customerDetails?.phone?.mobile}
                                        </p>
                                    </div>
                                    <div className="mb-5">
                                        <p className="text-[14px] mb-1 text-gm-50 font-rocGroteskMedium leading-5">
                                            Country
                                        </p>
                                        <p className="text-[14px] text-slate-500 font-rocGroteskRegular leading-5">
                                            {responseDetails?.order?.customerDetails?.country ||
                                                "N/A"}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <ModalContainer
                open={Boolean(acceptDeleteModal)}
                showCloseIcon={false}
                closeModal={() => setAcceptDeleteModal("")}
                tailwindClassName="w-[36%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="bg-white flex flex-col rounded-[20px]  w-[572px] ">
                    <div
                        onClick={() => setAcceptDeleteModal("")}
                        className="cursor-pointer mt-5 px-6 flex justify-end "
                    >
                        <i className="ri-close-fill text-gm-45 text-[30px]"></i>
                    </div>
                    <div className="px-14 my-7 ">
                        <div>
                            <h6 className="text-center font-rocGroteskMedium text-[16px]">
                                Are you sure you want to {acceptDeleteModal} quote?
                            </h6>
                        </div>
                        <div className="mt-5 space-y-4">
                            <Button
                                btnText={`Yes, ${acceptDeleteModal} quote`}
                                type={"button"}
                                btnClassname={`!py-3 ${
                                    acceptDeleteModal === "accept"
                                        ? "!bg-tradeally-light-green border border-carribean-green"
                                        : "!bg-[#FFEFEC] border border-r-50"
                                } !text-dark-gray-2 !px-4`}
                                onClick={() => {
                                    acceptRejectQuote();
                                }}
                                isLoading={acceptQuote}
                                disabled={acceptQuote}
                            />
                            <Button
                                btnText={"No, close"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => {
                                    setAcceptDeleteModal("");
                                }}
                                disabled={acceptQuote}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};
export default QuoteVendorDetails;
