import React from "react";

export const displayProductChannelIcon = (channel: string, className?: string) => {
    switch (channel?.toLowerCase()) {
        case "quickbooks":
            return (
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1716480828/quickbooksIcon_poqahg.svg"
                    alt="quickbooks"
                    className={`w-6 h-6 ${className}`}
                />
            );
        case "shopify":
            return (
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1716480854/shopifyIcon_qelbea.svg"
                    alt="shopify"
                    className={`w-6 h-6 ${className}`}
                />
            );
        default:
            return (
                <img
                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1716480916/synthIconOnly_rcj7zd.svg"
                    alt="synth"
                    className={`w-6 h-6 ${className}`}
                />
            );
    }
};
