import Button from "components/common/Button";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import {restrictPastDate} from "helpers";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import {useNavigate} from "react-router-dom";
import countries from "variables/countries";

interface CustomerDetailsProps {
    customerDetails: {
        companyName: string;
        email: string;
        firstName: string;
        lastName: string;
        ext: string;
        phone: string;
        country: string;
        budget: string;
        currency: string;
        deliveryDate: string;
    };
    setCustomerDetails: React.Dispatch<
        React.SetStateAction<{
            companyName: string;
            email: string;
            firstName: string;
            lastName: string;
            ext: string;
            phone: string;
            country: string;
            budget: string;
            currency: string;
            deliveryDate: string;
        }>
    >;
    setActiveScreen: React.Dispatch<React.SetStateAction<number>>;
    setStep: React.Dispatch<React.SetStateAction<number>>;
}

const CustomerDetails = ({
    customerDetails,
    setCustomerDetails,
    setActiveScreen,
    setStep,
}: CustomerDetailsProps) => {
    const navigate = useNavigate();
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | {target: {name: string; value: string}}
    ) => {
        const {name, value} = e.target;

        if (name?.toLowerCase() === "firstname" || name?.toLowerCase() === "lastname") {
            const sanitizedValue = value.replace(/[^A-Za-z ]/g, "");

            setCustomerDetails((prev) => ({
                ...prev,
                [name]: sanitizedValue,
            }));
        } else {
            setCustomerDetails((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    return (
        <form
            onSubmit={() => {
                setActiveScreen((prev) => prev + 1);
                setStep((prev) => prev + 1);
            }}
        >
            <div className="mb-8 px-8 max-sm:px-3">
                <p className="text-[22px] font-rocGroteskMedium text-gm-50">
                    Enter your customer details
                </p>
            </div>
            <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-6 pb-[66px] px-8 max-sm:px-3">
                <div className="flex flex-col gap-6">
                    <TextInput
                        name={"companyName"}
                        type={"text"}
                        value={customerDetails.companyName}
                        onChange={handleChange}
                        placeholder={"Company name"}
                        required={true}
                        containerClassname=""
                    />
                    <TextInput
                        name={"firstName"}
                        type={"text"}
                        value={customerDetails.firstName}
                        onChange={handleChange}
                        placeholder={"First name"}
                        required={true}
                        containerClassname=""
                    />
                    <div className="w-full grid grid-cols-[115px_calc(100%-119px)] gap-1 ">
                        <SelectInput
                            value={customerDetails.ext}
                            name="ext"
                            placeholder={"Ext"}
                            handleChange={(name, value) => handleChange({target: {name, value}})}
                            isRequired={true}
                            dropdownOptions={countries
                                .filter((data) => Boolean(data?.codes[0]))
                                .sort((p1, p2) =>
                                    p1.codes[0] > p2.codes[0]
                                        ? 1
                                        : p1.codes[0] < p2.codes[0]
                                        ? -1
                                        : 0
                                )
                                .map((item) => ({
                                    label: item.codes[0]?.replace(" ", ""),
                                    value: item.codes[0]?.replace(" ", ""),
                                    icon: (
                                        <ReactCountryFlag
                                            countryCode={item?.abbreviation}
                                            svg
                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                            cdnSuffix="svg"
                                            style={{
                                                width: "16px",
                                                height: "16px",
                                                borderRadius: "50%",
                                                marginTop: "-4px",
                                            }}
                                        />
                                    ),
                                }))}
                        />
                        <TextInput
                            name={"phone"}
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            value={customerDetails.phone}
                            onChange={handleChange}
                            placeholder={"Phone number"}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            minLength={10}
                            required={true}
                            pattern={"^[0-9]+$"}
                            title={"must be digits"}
                        />
                    </div>
                    <div className="w-full grid grid-cols-[115px_calc(100%-119px)] gap-1 ">
                        <SelectInput
                            value={customerDetails.currency}
                            name="currency"
                            placeholder={"Currency"}
                            handleChange={(name, value) => handleChange({target: {name, value}})}
                            isRequired={Boolean(customerDetails.budget)}
                            dropdownOptions={[
                                {
                                    label: "GBP (£)",
                                    value: "GBP",
                                },
                                {
                                    label: "USD ($)",
                                    value: "USD",
                                },
                                {
                                    label: "EUR (€)",
                                    value: "EUR",
                                },
                                {
                                    label: "NGN (₦)",
                                    value: "NGN",
                                },
                            ]}
                        />
                        <TextInput
                            name={"budget"}
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            value={customerDetails.budget}
                            onChange={handleChange}
                            placeholder={"Customer budget (Optional)"}
                            required={Boolean(customerDetails.currency)}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            pattern={"^[0-9]+$"}
                            title={"must be digits"}
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-6">
                    <TextInput
                        name={"email"}
                        type={"email"}
                        value={customerDetails.email}
                        onChange={handleChange}
                        placeholder={"Email address"}
                        required={true}
                        containerClassname=""
                    />
                    <TextInput
                        name={"lastName"}
                        type={"text"}
                        value={customerDetails.lastName}
                        onChange={handleChange}
                        placeholder={"Last name"}
                        required={true}
                        containerClassname=""
                    />
                    <SelectInput
                        value={customerDetails.country}
                        name="country"
                        placeholder={"Country"}
                        handleChange={(name, value) => handleChange({target: {name, value}})}
                        isRequired={true}
                        dropdownOptions={countries.map((item) => ({
                            label: item.name,
                            value: item.name,
                        }))}
                    />
                    <TextInput
                        name={"deliveryDate"}
                        type={"date"}
                        value={customerDetails.deliveryDate}
                        onChange={handleChange}
                        placeholder={"Delivery date"}
                        required={true}
                        min={restrictPastDate()}
                        containerClassname=""
                    />
                </div>
            </div>
            <div className="px-6 max-sm:px-3 py-4 border-t border-slate-100 flex justify-end">
                <div className="flex items-center gap-3">
                    <Button
                        btnClassname="!bg-n-20 !text-gm-50 !w-[150px]"
                        btnText={"Back"}
                        onClick={() => {
                            setStep((prev) => prev - 1);
                            navigate("/dashboard/rfqs/create", {replace: true});
                        }}
                        type={"button"}
                    />
                    <Button
                        btnText={"Next"}
                        type={"submit"}
                        btnClassname={"!w-[150px]"}
                        // onClick={() => setActiveScreen((prev) => prev + 1)}
                        // disabled={!Boolean(selectedVendor)}
                    />
                </div>
            </div>
        </form>
    );
};

export default CustomerDetails;
