/* eslint-disable */
import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import Rating from "components/common/Rating";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { displayFileIcon, getCurrencyFromCurrencyCode, onScroll } from "helpers";
import { downloadUrl } from "helpers/fileDownload";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { orderActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks";

const QuoteItemDetails = () => {
    const limit = 20;
    const { quoteId } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const item = new URLSearchParams(location.search).get("item");
    const quotationId = new URLSearchParams(location.search).get("quoteId");

    const [quoteResponses, setQuoteResponses] = useState<{ [key: string]: any }>({});
    const [showAcceptedQuoteModal, setShowAcceptedQuoteModal] = useState(false);
    const [responseLimit, setResponseLimit] = useState(limit);
    const [responseTotal, setResponseTotal] = useState(0);
    const [loadingButton, setLoadingButton] = useState(null);
    const [responsePagination, setResponsePagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [loadMoreResponses, setLoadMoreResponses] = useState(false);
    const {
        fetchingSingleQuoteResponses,
        acceptQuoteSuccess,
        acceptQuote,
        fetchedSingleQuoteResponsesSuccess,
    } = useAppSelector((state) => state.order);

    useEffect(() => {
        dispatch(orderActions.getSingleQuoteResponses(quoteId));
    }, [dispatch, quoteId]);

    useEffect(() => {
        if (Boolean(fetchedSingleQuoteResponsesSuccess)) {
            setQuoteResponses(fetchedSingleQuoteResponsesSuccess);
            setResponseTotal(fetchedSingleQuoteResponsesSuccess?.total);
            setResponsePagination({
                current: fetchedSingleQuoteResponsesSuccess?.pagination?.current,
                number_of_pages: fetchedSingleQuoteResponsesSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedSingleQuoteResponsesSuccess]);

    const onAcceptQuote = async (res) => {
        setLoadingButton(res);
        const body = {
            acceptance: true,
            quoteResponseId: res,
        };
        try {
            dispatch(orderActions.acceptQuote(body));
            setTimeout(() => {
                setLoadingButton(null);
            }, 1000);
        } catch (error) {
            setLoadingButton(null);
        }
    };

    useEffect(() => {
        if (Boolean(acceptQuoteSuccess)) {
            setShowAcceptedQuoteModal(true);
            dispatch(orderActions.getSingleQuoteResponses(quoteId));
        }
    }, [quoteId, dispatch, acceptQuoteSuccess]);

    const onResponseScroll = useCallback(
        (orderContainer) =>
            onScroll(
                orderContainer,
                responsePagination?.current as number,
                responsePagination?.number_of_pages as number,
                () => {
                    setResponseLimit(() => responseLimit + limit);
                    setLoadMoreResponses(true);
                }
            ),
        [responsePagination]
    );

    useEffect(() => {
        dispatch(orderActions.getSingleQuoteResponses(quoteId, responseLimit));
        setTimeout(() => {
            setLoadMoreResponses(false);
        }, 800);
    }, [dispatch, responseLimit]);

    return (
        <PageFrame
            containerClassName={"!h-[calc(100vh-108px)]"}
            isLoading={
                fetchingSingleQuoteResponses && responseLimit === limit && !acceptQuoteSuccess
            }
        >
            <div className="px-2 mx-1.5 sm:mx-0 xs:px-[20px] lg:px-0 py-4 lg:mx-[26px] lg:rounded-none rounded lg:shadow-sm">
                <div className="lg:border-b pb-4 lg:px-[24px] ">
                    <BreadCrumb
                        parentCrumb={"Quotes"}
                        parentUrl={"/dashboard/quotes"}
                        childrenCrumb={[
                            {
                                title: "Quote responses",
                                path: `/dashboard/quotes/${quotationId}`,
                            },
                            {
                                title: "Item quote",
                                path: `/dashboard/quotes/${quoteId}/responses?item=${item}&quoteId=${quotationId}`,
                            },
                        ]}
                        size="small"
                    />
                    <h6 className="lg:text-xl px-1 lg:px-0 text-base text-slate-700 font-rocGroteskMedium mt-2">
                        {responseTotal === 1
                            ? `${responseTotal} Quote response for Item:
                       ${item}`
                            : `${responseTotal} Quote responses for Item:
                      ${item}`}
                    </h6>
                </div>
                <div
                    onScroll={(e) => {
                        const container = e.target;
                        onResponseScroll(container);
                    }}
                    className=""
                >
                    {quoteResponses?.acceptedQuote && (
                        <div
                            onClick={() =>
                                navigate(
                                    `/dashboard/quotes/${quotationId}/item/${quoteId}/quote-detail/${quoteResponses?.acceptedQuote?._id}?item=${item}`
                                )
                            }
                            className={`${
                                quoteResponses?.quoteResponses &&
                                quoteResponses?.quoteResponses?.length !== 0 &&
                                "border-b pb-3"
                            }  bg-[#FBFCFE]  border-slate-200 text-base cursor-pointer`}
                        >
                            <div className=" px-3 xs:px-[20px] py-6 lg:px-[28px] lg:mb-0 mb-2 lg:border-none border border-slate-200 lg:rounded-none rounded ">
                                <div>
                                    <div className="bg-g-25 mb-2 lg:block hidden  w-fit py-1 rounded px-2">
                                        <p className="text-g-65 text-sm font-rocGroteskMedium ">
                                            Quote accepted
                                        </p>
                                    </div>
                                </div>
                                <div className="">
                                    <p className=" text-slate-900 text-sm lg:text-xs font-rocGroteskMedium">
                                        RFQ-{quoteResponses?.acceptedQuote?.rfq?.taId}
                                    </p>
                                    <div className="flex justify-between mt-1.5 items-center">
                                        <div className="flex items-center gap-3">
                                            <VendorAvatar
                                                imageSrc={
                                                    quoteResponses?.acceptedQuote?.vendor?.profile
                                                        ?.avatar
                                                }
                                                size={35}
                                                name={
                                                    quoteResponses?.acceptedQuote?.vendor?.profile
                                                        ?.businessName
                                                }
                                            />

                                            <div>
                                                <div className="flex items-center space-x-2">
                                                    <div>
                                                        <h5 className="text-base font-rocGroteskMedium">
                                                            {
                                                                quoteResponses?.acceptedQuote
                                                                    ?.vendor?.profile?.businessName
                                                            }
                                                        </h5>
                                                    </div>
                                                    <div className="lg:block hidden">
                                                        {/* <Rating
                                                        ratingNo={
                                                            quoteResponses?.acceptedQuote?.vendor
                                                                ?.rating
                                                        }
                                                        totalRating={5}
                                                    /> */}
                                                    </div>
                                                </div>
                                                <h6 className="text-sm font-rocGroteskRegular text-slate-500">
                                                    {quoteResponses?.acceptedQuote?.vendor?.email}
                                                </h6>
                                                <div className="lg:hidden block">
                                                    {/* <Rating
                                                        ratingNo={
                                                            quoteResponses?.acceptedQuote?.vendor
                                                                ?.rating
                                                        }
                                                        size="xs"
                                                        totalRating={5}
                                                    /> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="cursor-pointer justify-center bg-white border w-[34px] h-[34px] border-slate-100 p-2 rounded-full flex items-center">
                                            <i className="ri-thumb-down-line"></i>
                                        </div> */}
                                    </div>
                                    <p className="text-gm-50 lg:block hidden font-rocGroteskMedium text-[14px] leading-5 mt-3">
                                        {quoteResponses?.acceptedQuote?.comment}
                                    </p>
                                </div>
                                <div className="flex mt-4 gap-1">
                                    {quoteResponses?.acceptedQuote?.documents?.map((doc) => (
                                        <a
                                            href={doc?.path}
                                            download={doc?.filename}
                                            className="bg-white cursor-pointer border flex items-center py-2 px-3 space-x-2 rounded-[6px]  border-slate-200 shadow-box-shadow-5 drop-shadow-sm"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                // downloadUrl(doc?.path, doc?.name);
                                            }}
                                            key={doc?.path}
                                        >
                                            {displayFileIcon(getFileTypeFromUrl(doc?.path), true)}
                                            <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                                {doc?.filename || doc?.name}
                                            </p>
                                            <div className="cursor-pointer">
                                                <i className="ri-download-line text-gm-50"></i>
                                            </div>
                                        </a>
                                    ))}
                                </div>
                                <div className="flex lg:flex-row flex-col w-full lg:space-y-0 space-y-3 lg:justify-between mt-2 lg:items-center">
                                    <div className="flex flex-col w-fit sm:flex-row sm:space-y-0 space-y-2 whitespace-nowrap sm:space-x-2 mt-2">
                                        <div className="bg-slate-100 rounded-[4px] px-2 py-1">
                                            <p className="text-slate-700  text-[14px] font-rocGroteskMedium">
                                                Amount:{" "}
                                                {getCurrencyFromCurrencyCode(
                                                    quoteResponses?.acceptedQuote?.amount?.currency
                                                )}
                                                {quoteResponses?.acceptedQuote?.amount?.amount}
                                            </p>
                                        </div>
                                        <div className="bg-slate-100 rounded-[4px] px-2 py-1">
                                            <p className="text-slate-700  text-[14px] font-rocGroteskMedium">
                                                Sent:{" "}
                                                {moment(
                                                    quoteResponses?.acceptedQuote?.createdAt
                                                ).fromNow()}{" "}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="lg:flex hidden  md:flex-row lg:justify-end md:space-y-0 space-y-3 items-center w-full  lg:w-[250px] whitespace-nowrap space-x-3">
                                        <div className="flex items-center w-full justify-center  space-x-1.5 border px-2 py-2 rounded-[4px] border-n-20">
                                            <i className="ri-check-double-fill text-g-50"></i>
                                            <p className="text-g-75 text-[13px] font-rocGroteskMedium">
                                                Quote accepted
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex lg:hidden md:flex-row lg:justify-end md:space-y-0 space-y-3 items-center w-full  lg:w-[250px] whitespace-nowrap space-x-3">
                                <div className="flex items-center w-full justify-center  space-x-1.5 border px-2 py-2 rounded-[4px] border-n-20">
                                    <i className="ri-check-double-fill text-g-50"></i>
                                    <p className="text-g-75 text-[13px] font-rocGroteskMedium">
                                        Quote accepted
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="lg:px-[24px]">
                        {quoteResponses?.quoteResponses?.length !== 0 && (
                            <div className="bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1692194429/Tradeally_2.0/purple_banner_b60tth.svg')] space-x-1 items-center flex w-[156px] h-[24px] mt-4">
                                <div className=" flex items-center justify-center border-r border-white w-[28px]">
                                    <i className="ri-copper-diamond-line text-[17px] text-white"></i>
                                </div>
                                <p className="text-[12px] text-white font-rocGroteskMedium ">
                                    suggested quote
                                </p>
                            </div>
                        )}
                    </div>
                    {quoteResponses?.quoteResponses?.map((res, idx) => (
                        <div
                            onClick={() =>
                                navigate(
                                    `/dashboard/quotes/${quotationId}/item/${quoteId}/quote-detail/${res?._id}?item=${item}`
                                )
                            }
                            className="border-b pb-8 lg:pb-6 pt-4  cursor-pointer border-slate-200"
                            key={res?._id}
                        >
                            <div className="">
                                <div className="border lg:py-0 py-4  border-[#E2E8F0] lg:border-none lg:rounded-none rounded lg:mb-0 mb-2 px-2 xs:px-[20px] lg:px-[26px]">
                                    <div className="">
                                        <p className="text-slate-900 text-[12px] font-rocGroteskMedium">
                                            RFQ-{res?.rfq?.taId}
                                        </p>
                                        <div className="flex justify-between mt-1.5 items-center">
                                            <div className="flex items-center space-x-3 ">
                                                <VendorAvatar
                                                    imageSrc={res?.vendor?.profile?.avatar}
                                                    size={35}
                                                    name={res?.vendor?.profile?.businessName}
                                                />

                                                <div>
                                                    <div className="flex items-center space-x-2">
                                                        <div>
                                                            <h5 className="text-base font-rocGroteskMedium">
                                                                {res?.vendor?.profile?.businessName}
                                                            </h5>
                                                        </div>
                                                        <div className="lg:block hidden">
                                                            {/* <Rating
                                                        ratingNo={res?.vendor?.rating}
                                                        totalRating={5}
                                                    /> */}
                                                        </div>
                                                    </div>
                                                    <h6 className="text-[14px] font-rocGroteskRegular text-slate-500">
                                                        {res?.vendor?.email}
                                                    </h6>
                                                    <div className="lg:hidden block">
                                                        {/* <Rating
                                                        ratingNo={res?.vendor?.rating}
                                                        size="xs"
                                                        totalRating={5}
                                                    /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="cursor-pointer justify-center bg-white border w-[34px] h-[34px] border-slate-100 p-2 rounded-full flex items-center">
                                            <i className="ri-thumb-down-line"></i>
                                        </div> */}
                                        </div>
                                        <p className="text-gm-50 lg:block hidden font-rocGroteskMedium text-sm mt-3">
                                            {res?.comment}
                                        </p>
                                    </div>
                                    <div className="flex mt-4 gap-1">
                                        {res?.documents?.map((doc) => (
                                            <a
                                                href={doc?.path}
                                                download={doc?.filename}
                                                className="bg-white cursor-pointer border flex items-center py-2 px-3 space-x-2 rounded-[6px]  border-slate-200 shadow-box-shadow-5 drop-shadow-sm"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    // downloadUrl(doc?.path, doc?.name);
                                                }}
                                                key={doc?.path}
                                            >
                                                {displayFileIcon(
                                                    getFileTypeFromUrl(doc?.path),
                                                    true
                                                )}
                                                <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                                    {doc?.filename || doc?.name}
                                                </p>
                                                <div className="cursor-pointer">
                                                    <i className="ri-download-line text-gm-50"></i>
                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                    <div className="flex justify-between w-full mt-2 items-center">
                                        <div className="flex sm:flex-row w-fit  lg:mb-0 mb-2 flex-col sm:space-y-0 space-y-2 sm:space-x-2 mt-2">
                                            <div className="bg-slate-100 rounded-[4px] px-2 py-1">
                                                <p className="text-slate-700  text-[14px] font-rocGroteskMedium">
                                                    Amount:{" "}
                                                    {getCurrencyFromCurrencyCode(
                                                        res?.amount?.currency
                                                    )}
                                                    {res?.amount?.amount}
                                                </p>
                                            </div>
                                            <div className="bg-slate-100 rounded-[4px] px-2 py-1">
                                                <p className="text-slate-700  text-[14px] font-rocGroteskMedium">
                                                    Sent: {moment(res?.createdAt).fromNow()}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="lg:flex hidden md:flex-row justify-end md:space-y-0 space-y-3 items-center  sm:w-[250px] whitespace-nowrap space-x-3">
                                            <Button
                                                btnText={"Accept quote"}
                                                type={"button"}
                                                disabled={acceptQuote}
                                                isLoading={acceptQuote && loadingButton === res._id}
                                                btnClassname={"!w-fit !py-2"}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onAcceptQuote(res?._id);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:hidden flex md:flex-row  lg:justify-end  items-center  w-full  lg:w-[250px] whitespace-nowrap">
                                <Button
                                    btnText={"Accept quote"}
                                    type={"button"}
                                    disabled={acceptQuote}
                                    isLoading={acceptQuote && loadingButton === res._id}
                                    btnClassname={"!w-full"}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onAcceptQuote(res?._id);
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                    {loadMoreResponses && fetchingSingleQuoteResponses && (
                        <div className="flex justify-center my-4">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}
                    {/* {responsePagination?.current === responsePagination?.number_of_pages && (
                        <div className="flex my-4 justify-center text-sm font-rocGroteskMedium">
                            End of list
                        </div>
                    )} */}
                    {!quoteResponses?.acceptedQuote &&
                        quoteResponses?.quoteResponses?.length == 0 && (
                            <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                                <div className="space-y-2  text-center">
                                    <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                                    <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                        No quote yet
                                    </p>
                                    <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                        Recent quotes from vendor will be displayed here
                                    </p>
                                </div>
                            </div>
                        )}
                </div>
                {showAcceptedQuoteModal && (
                    <ModalContainer
                        open={showAcceptedQuoteModal}
                        modalClassName={"py-10 px-10"}
                        tailwindClassName="w-[400px]"
                        showCloseIcon={false}
                        closeModal={() => {
                            if (!fetchingSingleQuoteResponses) {
                                dispatch(orderActions.resetCreatePurchaseOrderSuccess());
                                dispatch(orderActions.resetAcceptQuoteSuccess());
                                setShowAcceptedQuoteModal(false);
                                navigate("/dashboard/purchase-orders");
                            }
                        }}
                    >
                        <div className="bg-white !px-2 pt-6 pb-7  rounded-[12px]">
                            <div className="flex px-6 items-center justify-end">
                                <span
                                    className="material-icons text-gm-45 text-[24px] cursor-pointer font-medium"
                                    onClick={() => {
                                        if (!fetchingSingleQuoteResponses) {
                                            dispatch(
                                                orderActions.resetCreatePurchaseOrderSuccess()
                                            );
                                            dispatch(orderActions.resetAcceptQuoteSuccess());
                                            // setShowSuccessPO(false);
                                            setShowAcceptedQuoteModal(false);
                                            navigate("/dashboard/purchase-orders");
                                        }
                                    }}
                                >
                                    close
                                </span>
                            </div>
                            {fetchingSingleQuoteResponses ? (
                                <div className="flex justify-center my-4">
                                    <Loader color="gm-25" size={8} />
                                </div>
                            ) : (
                                <div className="mt-4 px-6">
                                    <div className="text-center flex justify-center flex-col items-center space-y-5">
                                        <div className="w-14 h-14 flex justify-center items-center bg-[#00DB8F] rounded-full">
                                            <i className="ri-checkbox-circle-fill text-[29px] text-white "></i>
                                        </div>
                                        <div>
                                            <h3 className="text-xl text-[#333333] font-rocGroteskMedium">
                                                Quote accepted successfully
                                            </h3>
                                            <p className="text-slate-500 mt-1 text-sm font-rocGroteskMedium">
                                                You've accepted a quote from{" "}
                                                {
                                                    quoteResponses?.acceptedQuote?.vendor?.profile
                                                        ?.businessName
                                                }
                                                . Would you like to create a Purchase Order for this
                                                accepted quote? You can review and confirm the
                                                details before proceeding.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-5 flex whitespace-nowrap space-x-3">
                                        <Button
                                            btnText={"Not now"}
                                            btnClassname="!py-3 cursor-pointer !bg-[#F4F5F7] !text-[14px] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4"
                                            onClick={() => {
                                                dispatch(orderActions.resetAcceptQuoteSuccess());
                                                setShowAcceptedQuoteModal(false);
                                            }}
                                        />
                                        <Button
                                            btnText={"Create Purchase Order"}
                                            btnClassname="!py-3"
                                            onClick={() => {
                                                dispatch(orderActions.resetAcceptQuoteSuccess());
                                                setShowAcceptedQuoteModal(false);
                                                navigate(
                                                    `/dashboard/purchase-orders/create?id=${acceptQuoteSuccess?.quote?._id}`
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </ModalContainer>
                )}
            </div>
        </PageFrame>
    );
};
export default QuoteItemDetails;
