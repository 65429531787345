import Dropdown from "components/common/Dropdown";
import VendorAvatar from "components/common/VendorAvatar";
import React, {useEffect, useState} from "react";
import {Draggable} from "react-beautiful-dnd";
import TaskDetailsModal from "./TaskDetails";
import {camelCaseToRegularCase} from "helpers/camelCaseToRegularCase";
import Loader from "components/common/Loader";

export const BoardCard = ({cardDetails}) => {
    const [showTaskDetails, setShowTaskDetails] = useState(false);
    const [taskId, setTaskId] = useState("");
    const handleCardClick = (id) => {
        setTaskId(id);
    };

    useEffect(() => {
        if (Boolean(taskId)) {
            setShowTaskDetails(true);
        }
    }, [taskId]);

    return (
        <div>
            {showTaskDetails && (
                <TaskDetailsModal
                    id={taskId}
                    openModal={showTaskDetails}
                    closeModal={() => {
                        setShowTaskDetails(false);
                        setTaskId("");
                    }}
                />
            )}

            {cardDetails &&
                cardDetails?.map((d, index) => (
                    <div className="w-full" key={index}>
                        <Draggable key={index} draggableId={d?._id} index={index}>
                            {(provided, snapshot) => (
                                <div
                                    key={index}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                        ...provided.draggableProps.style,
                                        cursor: snapshot.isDragging ? "grabbing" : "grab",
                                    }}
                                >
                                    <div
                                        className={` px-3 mt-4 cursor-pointer  py-[14px] bg-white border-[0.5px]
                                         border-slate-200 rounded-[8px] shadow-faintShadow`}
                                        onClick={(e) => handleCardClick(d?._id)}
                                    >
                                        <div className="font-rocGroteskMedium text-xs mb-[12px]">
                                            <p className="text-slate-500">
                                                {camelCaseToRegularCase(d?.name)}
                                            </p>
                                            <span className="text-gm-45">#{d?.action?.taId}</span>
                                        </div>
                                        <div className="flex justify-between">
                                            {d?.action?.assignee ? (
                                                <>
                                                    <div
                                                        className="flex space-x-1 items-center "
                                                        key={d?.assignee?._id}
                                                    >
                                                        <div>
                                                            <VendorAvatar
                                                                name={
                                                                    d?.assignee?.fullName
                                                                        ? d?.assignee?.fullName
                                                                        : d?.assignee?.email
                                                                }
                                                                size={30}
                                                                imageSrc={d?.assignee?.avatar}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="w-5 h-5 p-2 rounded bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100% flex items-center justify-center">
                                                    <i className="ri-robot-line text-white"></i>
                                                </div>
                                            )}

                                            {/* <Dropdown
                                                dropdownTriggerClassName={" "}
                                                dropdown={
                                                    <i className="ri-more-2-fill text-lg text-slate-700"></i>
                                                }
                                                dropdownClassname={"!w-full !mb-10 !-mt-7"}
                                                dropdownContainerClasses={`shadow-cardShadow-4 -left-[70px]  border border-slate-100 w-[159px] rounded !max-h-fit
                            `}
                                                dropdownItemsClasses={`last:text-r-50 last:hover:bg-r-25`}
                                                name={"faq-dropdown"}
                                                dropdownOptions={[
                                                    {
                                                        label: "View runs",
                                                        value: "view runs",
                                                    },
                                                ]}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Draggable>
                    </div>
                ))}
        </div>
    );
};
