import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import {calculateCommentDate} from "helpers/calculateCommentDate";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/storeHooks";
import {workFlowActions} from "redux/Ldb/actions";

type CommentsProps = {
    actionId: string;
    profile: any;
};

const Comments = ({actionId, profile}: CommentsProps) => {
    const dispatch = useAppDispatch();
    const [reply, setReply] = useState("");
    const [comments, setComments] = useState<{[key: string]: any}[]>([]);
    const [replies, setReplies] = useState<{[key: string]: any}>({});
    const [isHovered, setIsHovered] = useState<string | number | boolean>("");
    const [showThreadReply, setShowThreadReply] = useState(false);
    const [commentIdx, setCommentIdx] = useState<number | string>("");
    const [thread, setThread] = useState<{[key: string]: any}>({});

    const handleMouseEnter = (idx: number) => {
        setIsHovered(idx);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const toggleReplies = (commentId: string) => {
        setReplies((prev) => ({
            ...prev,
            [commentId]: !prev[commentId],
        }));
    };
    const {
        fetchedNodeCommentsSuccess,
        createCommentSuccess,
        creatingComment,
        fetchingNodeComments,
        deletedCommentSuccess,
    } = useAppSelector((state) => state.workFlow);

    useEffect(() => {
        if (showThreadReply) {
            setThread(comments[commentIdx]);
        }
    }, [comments]);
    useEffect(() => {
        if (Boolean(fetchedNodeCommentsSuccess)) {
            setComments(fetchedNodeCommentsSuccess?.workFlowComments);
        }
        if (Boolean(createCommentSuccess)) {
            setReply("");
            dispatch(workFlowActions.resetCreateCommentSuccess());
            dispatch(workFlowActions.getNodeComments(actionId));
        }
    }, [dispatch, fetchedNodeCommentsSuccess, createCommentSuccess]);

    useEffect(() => {
        if (Boolean(deletedCommentSuccess)) {
            dispatch(workFlowActions.getNodeComments(actionId));
        }
    }, [dispatch, deletedCommentSuccess]);
    useEffect(() => {
        if (actionId) {
            dispatch(workFlowActions.getNodeComments(actionId));
        }
    }, [actionId]);

    const submitReply = () => {
        let body;
        if (Boolean(showThreadReply)) {
            body = {
                comment: reply,
                commentSourceId: actionId,
                commentType: "WorkFlowAction",
                parentId: thread?._id,
            };
        } else {
            body = {
                comment: reply,
                commentSourceId: actionId,
                commentType: "WorkFlowAction",
            };
        }
        dispatch(workFlowActions.createComment(body));
    };

    const handleDeleteComment = (idx: number) => {
        if (showThreadReply) {
            const commentId = thread?.replies[idx]?._id;
            dispatch(workFlowActions.deleteComment(commentId));
        } else {
            const commentId = comments[idx]._id;
            dispatch(workFlowActions.deleteComment(commentId));
        }
    };

    const showThread = (idx: number) => {
        setCommentIdx(idx);
        const thread = comments[idx];
        setThread(thread);
        setShowThreadReply(true);
    };
    const handleCloseThread = () => {
        setShowThreadReply(false);
        setCommentIdx("");
    };

    return (
        <>
            {fetchingNodeComments ? (
                <div className="my-6 ">
                    <Loader />
                </div>
            ) : !fetchingNodeComments && comments?.length > 0 && !showThreadReply ? (
                <div className="mt-6 border-b-[#E2E8F0] border-b-[1px]">
                    {comments?.map((item, idx) => {
                        return (
                            <div
                                key={item?._id}
                                className="flex flex-col gap-y-2 cursor-pointer"
                                onMouseEnter={() => handleMouseEnter(idx)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div
                                    className={`
                                            hover:bg-[#F8FAFC] 
                                         px-[10px] py-5 cursor:pointer`}
                                >
                                    <div className="flex justify-between">
                                        <div className="flex gap-2 items-start font-rocGroteskMedium">
                                            <VendorAvatar
                                                size="24"
                                                containerClassname="rounded-full"
                                                name={item?.creator?.fullName}
                                                imageSrc={item?.creator?.avatar}
                                            />

                                            <div className="items-start flex gap-4">
                                                <div className="items-start flex flex-col gap-1">
                                                    <div className="flex gap-2 items-center">
                                                        <p className="text-[#042821] text-sm">
                                                            {profile?._id === item?.creator?._id
                                                                ? "You"
                                                                : item?.creator?.fullName &&
                                                                  item?.creator?.fullName
                                                                ? item?.creator?.email
                                                                : ""}
                                                        </p>
                                                        <p className="text-[#64748B] text-[10px] uppercase">
                                                            {calculateCommentDate(item?.createdAt)}
                                                        </p>
                                                    </div>
                                                    <p className="text-[#94A3B8] text-[10px] uppercase">
                                                        {item?.creator?.role}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {isHovered === idx && (
                                            <div className="flex gap-1 relative">
                                                <i
                                                    onClick={() => showThread(idx)}
                                                    className="ri-reply-line text-[#89939B] "
                                                ></i>
                                                <Dropdown
                                                    dropdown={
                                                        <i className="ri-more-2-fill text-lg text-gm-35 -pt-1"></i>
                                                    }
                                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                                    dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-120px] `}
                                                    dropdownItemsClasses={``}
                                                    name={"faq-dropdown"}
                                                    dropdownOptions={[
                                                        {
                                                            label: "Delete message",
                                                            value: "delete",
                                                            action: () => {
                                                                handleDeleteComment(idx);
                                                            },
                                                        },
                                                        {
                                                            label: "Reply message",
                                                            value: "edit",
                                                            action: () => {
                                                                showThread(idx);
                                                            },
                                                        },
                                                    ]}
                                                />

                                                <i className="ri-check-double-line text-gm-35"></i>
                                            </div>
                                        )}
                                    </div>
                                    <p className="mt-4 text-[#475569] text-xs font-rocGroteskMedium ">
                                        {item?.comment}
                                    </p>
                                    {item?.replies.length > 0 && (
                                        <button
                                            onClick={() => toggleReplies(item?._id)}
                                            className="text-[#5452BF] font-rocGroteskBold text-xs mt-4"
                                        >
                                            {!replies[item?._id] ? (
                                                <> Show {item?.replies.length} more replies </>
                                            ) : (
                                                "Close replies"
                                            )}
                                        </button>
                                    )}

                                    {replies[item?._id] &&
                                        item?.replies?.map((reply) => {
                                            return (
                                                <div
                                                    key={reply?._id}
                                                    className="px-[10px] pb-5 mt-4"
                                                >
                                                    <div className="flex gap-2 items-start font-rocGroteskMedium">
                                                        <VendorAvatar
                                                            size="24"
                                                            containerClassname="rounded-full"
                                                            name={
                                                                item?.creator?.fullName ||
                                                                item?.creator?.email
                                                            }
                                                            imageSrc={item?.creator?.avatar}
                                                        />
                                                        <div className="items-start flex gap-4">
                                                            <div className="items-start flex flex-col gap-1">
                                                                <div className="flex gap-2 items-center">
                                                                    <p className="text-[#042821] text-sm">
                                                                        {item?.creator?.fullName
                                                                            ? item?.creator
                                                                                  ?.fullName
                                                                            : item?.creator?.email}
                                                                    </p>

                                                                    <p className="text-[#64748B] text-[10px] uppercase">
                                                                        {calculateCommentDate(
                                                                            item?.createdAt
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                <p className="text-[#94A3B8] text-[10px] uppercase">
                                                                    {item?.creator?.role}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="mt-[11px] text-[#475569] text-xs font-rocGroteskMedium ">
                                                        {reply?.comment}
                                                    </p>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : comments?.length < 1 && !showThreadReply ? (
                <div className="w-full ">
                    <div className="mx-auto  flex justify-center text-center my-6 items-center flex-col max-w-[273px] font-rocGroteskMedium pt-8 pb-[88px]">
                        <i className="ri-chat-4-line text-2xl text-gm-50 mb-[15px]"></i>
                        <h3 className="text-black font-rocGroteskMedium text-[14px] mb-2 text-base">
                            No Comments Yet
                        </h3>
                        <p className="text-slate-500 text-sm font-rocGroteskMedium">
                            It seems like there are no comments yet. Be the first to share your
                            thoughts
                        </p>
                    </div>
                </div>
            ) : (
                showThreadReply &&
                (thread?.replies?.length > 0 ? (
                    <div>
                        <div className="bg-slate-50 border-b border-b-100 px-2 py-[14px] flex items-center gap-2">
                            <i
                                className="ri-arrow-left-line text-gm-50 cursor-pointer"
                                onClick={handleCloseThread}
                            ></i>
                            <p className="text-sm font-rocGroteskMedium text-g-75 ">
                                {profile?._id === thread?.creator?._id
                                    ? "Reply your comment"
                                    : `Reply ${thread?.creator?.fullName} comment`}
                            </p>
                        </div>
                        <div className="mt-6 mb-4">
                            {thread?.replies?.map((item, idx) => {
                                return (
                                    <div
                                        key={item?._id}
                                        className="flex flex-col gap-y-2 cursor-pointer"
                                        onMouseEnter={() => handleMouseEnter(idx)}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <div
                                            className={`
                                            hover:bg-[#F8FAFC] 
                                         px-[10px] py-5 cursor:pointer`}
                                        >
                                            <div className="flex justify-between">
                                                <div className="flex gap-2 items-start font-rocGroteskMedium">
                                                    <VendorAvatar
                                                        size="24"
                                                        containerClassname="rounded-full"
                                                        name={
                                                            item?.creator?.fullName ||
                                                            item?.creator?.email
                                                        }
                                                        imageSrc={item?.creator?.avatar}
                                                    />
                                                    <div className="items-start flex gap-4">
                                                        <div className="items-start flex flex-col gap-1">
                                                            <div className="flex gap-2 items-center">
                                                                <p className="text-[#042821] text-sm">
                                                                    {profile?._id ===
                                                                    item?.creator?._id
                                                                        ? "You"
                                                                        : item?.creator?.fullName &&
                                                                          item?.creator?.fullName
                                                                        ? item?.creator?.email
                                                                        : ""}
                                                                </p>
                                                                <p className="text-[#64748B] text-[10px] uppercase">
                                                                    {calculateCommentDate(
                                                                        item?.createdAt
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <p className="text-[#94A3B8] text-[10px] uppercase">
                                                                {item?.creator?.role}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                {isHovered === idx && (
                                                    <div className="flex gap-1 relative">
                                                        <Dropdown
                                                            dropdown={
                                                                <i className="ri-more-2-fill text-lg text-gm-35"></i>
                                                            }
                                                            dropdownClassName={
                                                                "!w-full !mb-10 !-mt-7"
                                                            }
                                                            dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-120px] `}
                                                            dropdownItemsClasses={``}
                                                            name={"faq-dropdown"}
                                                            dropdownOptions={[
                                                                {
                                                                    label: "Delete reply",
                                                                    value: "delete",
                                                                    action: () => {
                                                                        handleDeleteComment(idx);
                                                                    },
                                                                },
                                                            ]}
                                                        />

                                                        <i className="ri-check-double-line text-gm-35 pt-1"></i>
                                                    </div>
                                                )}
                                            </div>
                                            <p className="mt-4 text-[#475569] text-xs font-rocGroteskMedium ">
                                                {item?.comment}
                                            </p>
                                            {item?.replies.length > 0 && (
                                                <button
                                                    onClick={() => toggleReplies(item?._id)}
                                                    className="text-[#5452BF] font-rocGroteskBold text-xs mt-4"
                                                >
                                                    {!replies[item?._id] ? (
                                                        <>
                                                            {" "}
                                                            Show {item?.replies.length} more replies{" "}
                                                        </>
                                                    ) : (
                                                        "Close replies"
                                                    )}
                                                </button>
                                            )}

                                            {replies[item?._id] &&
                                                item?.replies?.map(
                                                    (reply: {[key: string]: any}) => {
                                                        return (
                                                            <div
                                                                key={reply?._id}
                                                                className="px-[10px] pb-5 mt-4"
                                                            >
                                                                <div className="flex gap-2 items-start font-rocGroteskMedium">
                                                                    <VendorAvatar
                                                                        size="24"
                                                                        containerClassname="rounded-full"
                                                                        name={
                                                                            item?.creator
                                                                                ?.fullName ||
                                                                            item?.creator?.email
                                                                        }
                                                                        imageSrc={
                                                                            item?.creator?.avatar
                                                                        }
                                                                    />
                                                                    <div className="items-start flex gap-4">
                                                                        <div className="items-start flex flex-col gap-1">
                                                                            <div className="flex gap-2 items-center">
                                                                                <p className="text-[#042821] text-sm">
                                                                                    {item?.creator
                                                                                        ?.fullName
                                                                                        ? item
                                                                                              ?.creator
                                                                                              ?.fullName
                                                                                        : item
                                                                                              ?.creator
                                                                                              ?.email}
                                                                                </p>

                                                                                <p className="text-[#64748B] text-[10px] uppercase">
                                                                                    {calculateCommentDate(
                                                                                        item?.createdAt
                                                                                    )}
                                                                                </p>
                                                                            </div>
                                                                            <p className="text-[#94A3B8] text-[10px] uppercase">
                                                                                {
                                                                                    item?.creator
                                                                                        ?.role
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p className="mt-[11px] text-[#475569] text-xs font-rocGroteskMedium ">
                                                                    {reply?.comment}
                                                                </p>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <div className="w-full ">
                        <div className="bg-slate-50 border-b border-b-100 px-2 py-[14px] flex items-center gap-2">
                            <i
                                className="ri-arrow-left-line text-gm-50 cursor-pointer"
                                onClick={handleCloseThread}
                            ></i>
                            <p className="text-sm font-rocGroteskMedium text-g-75 ">
                                {profile?._id === thread?.creator?._id
                                    ? "Reply your comment"
                                    : `Reply ${thread?.creator?.fullName} comment`}
                            </p>
                        </div>
                        <div className="mx-auto  flex justify-center text-center my-6 items-center flex-col max-w-[273px] font-rocGroteskMedium pt-8 pb-[88px]">
                            <i className="ri-chat-4-line text-2xl text-gm-50 mb-[15px]"></i>
                            <h3 className="text-black font-rocGroteskMedium text-[14px] mb-2 text-base">
                                No Comments Yet
                            </h3>
                            <p className="text-slate-500 text-sm font-rocGroteskMedium">
                                It seems like there are no comments yet. Be the first to share your
                                thoughts
                            </p>
                        </div>
                    </div>
                ))
            )}

            {
                <div className="pt-5 ">
                    <TextInput
                        type="text"
                        value={reply}
                        placeholder="Click to reply"
                        inputContainerClassname="!bg-[#F1F5F9] !border-none"
                        onChange={(e) => setReply(e.target.value)}
                    />
                    <div className="flex justify-start gap-4 items-center pb-4 mt-[11px]">
                        <Button
                            isLoading={creatingComment}
                            btnText={"Comment"}
                            type={"button"}
                            btnClassname="!py-2 !px-3 !text-[10px] !w-[80px] !h-[35px]"
                            onClick={submitReply}
                            disabled={creatingComment || !reply}
                        />

                        <Button
                            btnText="Cancel"
                            btnClassname="!py-2 !px-3  !w-auto !bg-[#F4F5F7] !text-[#142837] !text-[10px]"
                            btnTextClassName="!text-[10px]"
                            onClick={() => setReply("")}
                        />
                    </div>
                </div>
            }
        </>
    );
};

export default Comments;
