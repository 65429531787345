import React, { useEffect, useState } from "react";
import CustomerDetails from "./Steps/CustomerDetails";
import FillServices from "./Steps/FillServices";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { orderActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../../../../../../hooks/storeHooks";

type ManualProps = {
    setStep: React.Dispatch<React.SetStateAction<number>>;
};

const Manual = ({ setStep }: ManualProps) => {
    const [activeScreen, setActiveScreen] = useState(0);
    const [customerDetails, setCustomerDetails] = useState({
        companyName: "",
        email: "",
        firstName: "",
        lastName: "",
        ext: "",
        phone: "",
        country: "",
        budget: "",
        currency: "",
        deliveryDate: "",
    });
    const [serviceData, setServiceData] = useState([
        {
            description: "",
            qty: "",
            vendor: [],
        },
    ]);
    const [orderDetails, setOrderDetails] = useState({
        orderType: "",
        vendorCategory: "",
        orderDescription: "",
    });

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { sendingRfqs, sendRfqsSuccess } = useAppSelector((state) => state.order);

    const handleSendRfqs = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body: { [key: string]: any } = {
            itemsAndVendors: serviceData
                ?.map((service) => ({
                    item: service?.description?.split("-")[1],
                    quantity: service?.qty,
                    vendors: JSON.parse(service?.description?.split("-")[2]),
                    inventoryId: service?.description?.split("-")[0],
                }))
                ?.filter(
                    (value) =>
                        value.item && value.quantity && value.vendors && value.vendors?.length > 0
                ),
            deliveryDate: new Date(customerDetails?.deliveryDate).toISOString(),
            orderType: orderDetails?.orderType,
            customerDetails: {
                email: customerDetails?.email,
                phone: {
                    mobile: customerDetails?.ext + customerDetails?.phone,
                    rawMobile: customerDetails?.phone,
                    mobileExtension: customerDetails?.ext,
                },
                country: customerDetails?.country,
                firstNane: customerDetails?.firstName,
                lastName: customerDetails?.lastName,
                companyName: customerDetails?.companyName,
            },
        };

        if (Boolean(orderDetails?.orderDescription)) {
            body.specifications = orderDetails?.orderDescription;
        }

        if (Boolean(customerDetails?.currency) && Boolean(customerDetails?.budget)) {
            body.budget = {
                currency: customerDetails?.currency,
                amount: customerDetails?.budget,
            };
        }

        dispatch(orderActions.sendRfqs(body));
    };

    const displayActiveScreen = () => {
        switch (activeScreen) {
            case 0:
                return (
                    <CustomerDetails
                        customerDetails={customerDetails}
                        setCustomerDetails={setCustomerDetails}
                        setActiveScreen={setActiveScreen}
                        setStep={setStep}
                    />
                );

            case 1:
                return (
                    <FillServices
                        setActiveScreen={setActiveScreen}
                        setStep={setStep}
                        serviceData={serviceData}
                        setServiceData={setServiceData}
                        orderDetails={orderDetails}
                        setOrderDetails={setOrderDetails}
                        handleSendRfqs={handleSendRfqs}
                        sendingRfqs={sendingRfqs}
                    />
                );

            default:
                return (
                    <CustomerDetails
                        customerDetails={customerDetails}
                        setCustomerDetails={setCustomerDetails}
                        setActiveScreen={setActiveScreen}
                        setStep={setStep}
                    />
                );
        }
    };

    useEffect(() => {
        if (Boolean(sendRfqsSuccess)) {
            dispatch(orderActions.resetSendRfqsSuccess());
            navigate("/dashboard/rfqs");
        }
    }, [dispatch, navigate, sendRfqsSuccess]);

    return (
        <div className="">
            <div className="pt-6 border-b border-slate-100">{displayActiveScreen()}</div>
        </div>
    );
};

export default Manual;
