import React from "react";

type CreateRfqMethodsProps = {
    optionsData: Array<Record<string, any>>,
    selectedOption: string,
};

const CreateRfqMethods = ({optionsData, selectedOption}: CreateRfqMethodsProps) => {
    return (
        <div className={`grid grid-cols-${optionsData?.length} max-sm:grid-cols-1 gap-4`}>
            {optionsData?.map((opt, idx) => {
                return (
                    <div
                        key={opt?.title + idx}
                        onClick={() => opt?.action?.()}
                        className={`md:px-6 py-6 cursor-pointer w-full border hover:bg-slate-50 hover:border-p-50 ${
                            selectedOption === opt?.isSelectedValue
                                ? "border-p-50 !bg-slate-50"
                                : "border-slate-200"
                        } rounded bg-white flex flex-col items-center`}
                    >
                        {opt?.icon}
                        <div className="text-center">
                            <p className="mb-1 text-base font-rocGroteskMedium text-slate-700">
                                {opt?.title}
                            </p>
                            <p className="text-sm font-rocGroteskRegular text-slate-500 mb-2">
                                {opt?.subText}
                            </p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default CreateRfqMethods;
