/* eslint-disable */
import { pdfjs } from "react-pdf";
import worker from "pdfjs-dist/webpack";

pdfjs.GlobalWorkerOptions.workerSrc = worker;

const convertDataURIToBinary = (dataURI) => {
    const base64Index = dataURI.indexOf("base64,") + "base64,".length;
    const base64 = dataURI.substring(base64Index);
    const binary = atob(base64);
    const array = new Uint8Array(binary.length);

    for (let i = 0; i < binary.length; i++) {
        array[i] = binary.charCodeAt(i);
    }
    return array;
};

export const convertPDFtoImages = async (blob) => {
    const readBlobAsDataURL = (blob) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => resolve(event.target.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });

    try {
        const imageDataUrl = await readBlobAsDataURL(blob);
        const pdfAsArray = convertDataURIToBinary(imageDataUrl);
        const loadingTask = pdfjsLib.getDocument(pdfAsArray);
        const pdf = await loadingTask.promise;
        const imageUrls = [];

        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            const scale = 2.0;
            const viewport = page.getViewport({ scale });

            // Create a canvas element
            const canvas = document.createElement("canvas");
            canvas.width = viewport.width;
            canvas.height = viewport.height;

            // Use page.render to render the page onto the canvas
            await page.render({ canvasContext: canvas.getContext("2d"), viewport }).promise;

            // Use canvas.toDataURL to get the data URL after rendering completes
            const imageUrl = canvas.toDataURL("image/png");
            imageUrls.push(imageUrl);
        }
        return imageUrls;
    } catch (error) {
        console.error("Error converting PDF to images:", error);
        return [];
    }
};
