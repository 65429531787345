import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import React, { useEffect, useState } from "react";
import Source from "../../../Alerts/Tabs/Source";
import ReactECharts from "echarts-for-react";
import VendorAvatar from "components/common/VendorAvatar";
import { debounce, truncate } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import useGetSingleProductGMWValues from "hooks/useGetSingleProductGMWValues";
import useGetSalesOrders from "hooks/useGetSalesOrders";
import moment from "moment";
import { formatMoney, getCurrencyFromCurrencyCode, getSalesMultiBarChartData } from "helpers";
import useGetAllWarehouses from "hooks/useGetAllWarehouses";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";

const Sales = () => {
    const [gmwValuesFilter, setGmwValuesFilter] = useState({
        location: "",
        period: "daily",
        inventoryId: "",
    });
    const { productId } = useParams();
    const {
        warehouseValue: warehouseDataValue,
        searchTwo,
        handleDebouncedChangeTwo,
        handleSearchTwo,
        searchResult,
        handleFilterChange: filterChange,
        handleClearFilter: clearFilter,
    } = useGetAllWarehouses();

    const { data } = useGetSingleProductGMWValues(
        gmwValuesFilter?.period,
        warehouseDataValue?.value
    );

    const {
        data: allWarehouses,
        warehouseValue,
        search,
        handleDebouncedChange,
        handleSearch,
        handleFilterChange,
        handleClearFilter,
    } = useGetAllWarehouses();

    const {
        data: salesOrderData,
        isFetching,
        isLoadingMore,
        pagination,
        setUpdatedPage,
    } = useGetSalesOrders(productId, warehouseValue?.value);

    const multiBarChartOption = {
        legend: { show: false },
        tooltip: {},
        color: ["#6562E5", "#56B648"],
        dataset: {
            source: [
                ["product", "Sales demand", "Sales forcasted"],
                ...getSalesMultiBarChartData(data, gmwValuesFilter?.period),
            ],
        },
        xAxis: { type: "category" },
        yAxis: {
            axisLabel: {
                formatter: "${value}",
            },
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
            { type: "bar", barWidth: "13%" },
            { type: "bar", barWidth: "13%" },
        ],
    };

    const tableHeader = [
        // { title: "Product name", widthClass: "w-[15.6%]" },
        { title: "Customer Name", widthClass: "w-[11.4%]" },
        { title: "Date", widthClass: "w-[9.1%]" },
        // { title: "Customer Name", widthClass: "w-[11.4%]" },
        { title: "Quantity", widthClass: "w-[7.6%]" },
        { title: "Unit Price", widthClass: "w-[7.6%]" },
        { title: "Total Price", widthClass: "w-[7.6%]" },
        { title: "Connected source", widthClass: "w-[14.6%]" },
        { title: "Order Status", widthClass: "w-[10.7%]" },
        { title: "Outlet", widthClass: "w-[15.6%]" },
    ];

    const tableBody = salesOrderData?.map((salesData, idx) => [
        // {
        //     content: (
        //         <div
        //             className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
        //         >
        //             <div className="flex items-center space-x-3">
        //                 <VendorAvatar imageSrc={""} size={32} name={"Intercom"} />
        //                 <div>
        //                     <span className="block text-sm font-rocGroteskMedium">
        //                         SO-001 dummy
        //                     </span>
        //                     <span className="block text-sm font-rocGroteskMedium text-slate-500">
        //                         {truncate(salesData?.products?.inventory?.productName, {
        //                             length: 18,
        //                         })}
        //                     </span>
        //                 </div>
        //             </div>
        //         </div>
        //     ),
        //     // cellClickAction: () => {
        //     //     navigate(`/dashboard/inventory/products/${salesData?.products?.inventory?._id}`);
        //     // },
        // },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm ${/^\*+$/.test(salesData?.customerDetails?.first_name)
                    ? ` text-[#94A3B8]`
                    : ` text-slate-800`} `}
                >
                    <span>
                        {/^\*+$/.test(salesData?.customerDetails?.first_name)
                            ? `Name not found on Shopify`
                            : `${salesData?.customerDetails?.first_name} ${salesData?.customerDetails?.last_name}`}
                    </span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>{moment(salesData?.saleDate)?.format("DD MMM, YYYY")}</span>
                </div>
            ),
        },
        // {
        //     content: (
        //         <div
        //             className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
        //         >
        //             <span>
        //                 {salesData?.customerDetails?.first_name}{" "}
        //                 {salesData?.customerDetails?.last_name}
        //             </span>
        //         </div>
        //     ),
        // },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>{salesData?.products?.quantity}</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>
                        {getCurrencyFromCurrencyCode(salesData?.products?.price?.currency)}{" "}
                        {salesData?.products?.price?.amount}
                    </span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>
                        {getCurrencyFromCurrencyCode(salesData?.products?.subtotal?.currency)}{" "}
                        {salesData?.subtotal?.amount}
                    </span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center space-x-2">
                        {displayProductChannelIcon(salesData?.channel)}
                        <span>{truncate(salesData?.channel, { length: 10 })} </span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="text-sm font-rocGroteskMedium flex items-center space-x-2">
                        <span className="w-2 h-2 rounded-full bg-[#F59E0B]"></span>
                        <span className="capitalize">{salesData?.status}</span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>{salesData?.warehouse?.warehouseName}</span>
                </div>
            ),
        },
    ]);

    return (
        <div id="salesContainer">
            {/* <Source /> */}

            <div className="py-5  bg-white rounded-[6px] shadow-faintShadow border border-[#E2E8F080] mb-8">
                <div className="flex items-center justify-between pb-5 px-6 border-b border-slate-200 mb-6">
                    <div className="flex items-center space-x-3">
                        <div>
                            <span className="block font-rocGroteskMedium text-sm text-g-75">
                                Total sales
                            </span>
                            <span className="block font-rocGroteskMedium text-xl text-p-50">
                                ${formatMoney()?.format((data?.totalSale || 0)?.toFixed(2)) || 0}
                            </span>
                        </div>
                        <div>
                            <span className="block font-rocGroteskMedium text-sm text-g-75">
                                Total forecasted sales
                            </span>
                            <span className="block font-rocGroteskMedium text-xl text-[#56B648]">
                                $
                                {formatMoney()?.format(
                                    (data?.forecastTotalSale || 0)?.toFixed(2)
                                ) || 0}
                            </span>
                        </div>
                    </div>

                    <div className="flex items-center space-x-4">
                        <div className="flex items-center whitespace-nowrap h-[40px] py-2 px-4 space-x-2">
                            <span className="w-[14px] h-[14px] rounded-[2px] bg-[#56B648]"></span>
                            <span className={`text-g-75 font-rocGroteskMedium text-sm`}>
                                Sales forecasted
                            </span>
                        </div>
                        <div className="flex items-center whitespace-nowrap h-[40px] py-2 px-4 space-x-2">
                            <span className="w-[14px] h-[14px] rounded-[2px] bg-p-50"></span>
                            <span className={`text-g-75 font-rocGroteskMedium text-sm`}>
                                Sales demand
                            </span>
                        </div>
                        <div className="flex items-center rounded-[5px] border border-slate-200 ">
                            <div className="border-r border-slate-200">
                                <Dropdown
                                    handleChange={(name, value) => {
                                        console.log("first", name, value);
                                    }}
                                    dropdown={
                                        <div className="flex items-center  whitespace-nowrap h-[40px] py-2 px-4 space-x-1 ">
                                            <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                            <span
                                                className={`text-g-75 font-rocGroteskMedium text-[13px]`}
                                            >
                                                {warehouseDataValue?.name || "Warehouses"}
                                            </span>
                                            {warehouseDataValue?.name && (
                                                <i
                                                    onClick={clearFilter}
                                                    className="ri-close-circle-line text-slate-500"
                                                ></i>
                                            )}
                                            <i className="ri-arrow-down-s-line text-g-75"></i>
                                        </div>
                                    }
                                    search={
                                        <div>
                                            <TextInput
                                                value={searchTwo}
                                                name={"search"}
                                                type={"text"}
                                                required={false}
                                                onChange={handleSearchTwo}
                                                onInput={handleDebouncedChangeTwo}
                                                inputPlaceholder="Search warehouse"
                                                containerClassname=""
                                                // inputContainerClassname={"!border-none !rounded-none "}
                                                // inputClassName="w-[50%]"
                                            />
                                        </div>
                                    }
                                    dropdownContainerClasses={`shadow-cardShadow-4  border border-slate-100  rounded `}
                                    dropdownOptions={[
                                        {
                                            customChild: (
                                                <div>
                                                    <div className="max-h-[280px]">
                                                        {searchResult?.map((warehouse) => {
                                                            return (
                                                                <div
                                                                    key={warehouse?._id}
                                                                    onClick={() =>
                                                                        filterChange(
                                                                            warehouse?.warehouseName,
                                                                            warehouse?._id
                                                                        )
                                                                    }
                                                                >
                                                                    <div
                                                                        className={`flex items-center space-x-3 max-w-[300px] px-3 py-2 hover:bg-slate-100 cursor-pointer ${
                                                                            warehouseDataValue?.value ===
                                                                                warehouse?._id &&
                                                                            "bg-slate-100"
                                                                        }`}
                                                                    >
                                                                        <i className="ri-map-pin-line text-base text-g-75 "></i>
                                                                        <div>
                                                                            <span
                                                                                className={`block text-g-75 text-sm font-rocGroteskMedium ${
                                                                                    warehouse
                                                                                        ?.address
                                                                                        ?.address &&
                                                                                    "mb-[2px]"
                                                                                }`}
                                                                            >
                                                                                {truncate(
                                                                                    warehouse?.warehouseName,
                                                                                    { length: 40 }
                                                                                )}
                                                                            </span>
                                                                            <span className="block text-g-75 text-sm font-rocGroteskMedium ">
                                                                                {truncate(
                                                                                    warehouse
                                                                                        ?.address
                                                                                        ?.address,
                                                                                    { length: 40 }
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            ),
                                        },
                                    ]}
                                />
                            </div>
                            <div>
                                <Dropdown
                                    handleChange={(name, value) => {
                                        console.log("first", name, value);
                                        setGmwValuesFilter((prev) => {
                                            return {
                                                ...prev,
                                                period: value,
                                            };
                                        });
                                    }}
                                    dropdown={
                                        <div className="flex items-center whitespace-nowrap h-[40px] py-2 px-4 space-x-1 bg-slate-50">
                                            <span
                                                className={`text-g-75 font-rocGroteskMedium text-[13px] capitalize`}
                                            >
                                                {gmwValuesFilter?.period === "daily"
                                                    ? "Current month"
                                                    : gmwValuesFilter?.period}
                                            </span>
                                            <i className="ri-arrow-down-s-line text-g-75"></i>
                                        </div>
                                    }
                                    // dropdownItemsClasses={`!px-0 !py-0`}
                                    // dropdownClassname={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100  rounded !max-h-fit`}
                                    dropdownOptions={[
                                        {
                                            label: "Current month",
                                            value: "daily",
                                        },
                                        // {
                                        //     label: "Weekly",
                                        //     value: "weekly",
                                        // },
                                        {
                                            label: "Monthly",
                                            value: "monthly",
                                        },
                                        {
                                            label: "Yearly",
                                            value: "yearly",
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="px-6">
                    <ReactECharts option={multiBarChartOption} />
                </div>
            </div>

            {/* <div className="p-6 mb-6 bg-white rounded-[6px] border border-[#E2E8F080] shadow-faintShadow ">
                <div className="flex items-center space-x-3 mb-4">
                    <div className="rounded-[2px] bg-[#FF0000] w-[43px] h-[22px] flex items-center justify-center">
                        <span className="text-xs font-rocGroteskMedium uppercase text-white">
                            Live
                        </span>
                    </div>

                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                        Updated 13 minutes ago
                    </span>
                </div>

                <div className="flex items-center justify-between mb-8 ">
                    <div className="w-full">
                        <span className="text-lg font-rocGroteskMedium text-[#E4281D] ">
                            Our system has identified a potential overstocking event for Kit Kat
                            Milk Chocolate Wafer Snack Size Candy. This situation can significantly
                            impact the business in terms of financial losses or missed revenue
                            opportunities.
                        </span>
                    </div>

                </div>

                <div className="grid grid-cols-3 space-x-4 pb-4 mb-8">
                    <div className="rounded-[12px] bg-[#FFF1F2] py-6 px-8">
                        <div className="w-8 h-8 rounded-full bg-[#E11D48] flex items-center justify-center mb-5">
                            <i className="ri-arrow-down-line text-xl text-white"></i>
                        </div>

                        <p className=" font-rocGroteskMedium text-g-75 mb-3">
                            <span className="block text-lg">Potential Loss & Impact:</span>
                        </p>
                        <ul className="list-disc pl-3">
                            <li className="text-sm font-rocGroteskMedium text-g-75 mb-3">
                                Your business stands to lose money through increased holding costs
                                and potential write-offs if overstocked items expire or spoil.
                            </li>
                            <li className="text-sm font-rocGroteskMedium text-g-75">
                                An overstock of 500 units of Vine Ripened Tomatoes at $0.64 per
                                unit, with holding costs of $0.50 per unit per month, the monthly
                                holding cost would be $250. Over a year, this amounts to $3,000 in
                                unnecessary expenses.
                            </li>
                        </ul>
                    </div>
                    <div className="rounded-[12px] bg-[#FEFCE8] py-6 px-8">
                        <div className="w-8 h-8 rounded-full bg-[#EAB308] flex items-center justify-center mb-5">
                            <i className="ri-question-mark text-xl text-white"></i>
                        </div>
                        <p className=" font-rocGroteskMedium text-g-75 mb-3">
                            <span className="block text-lg">Why overstocking happened</span>
                        </p>
                        <ul className="list-disc pl-3">
                            <li className="text-sm font-rocGroteskMedium text-g-75 mb-3">
                                Your business stands to lose money through increased holding costs
                                and potential write-offs if overstocked items expire or spoil.
                            </li>
                            <li className="text-sm font-rocGroteskMedium text-g-75">
                                An overstock of 500 units of Vine Ripened Tomatoes at $0.64 per
                                unit, with holding costs of $0.50 per unit per month, the monthly
                                holding cost would be $250. Over a year, this amounts to $3,000 in
                                unnecessary expenses.
                            </li>
                        </ul>
                    </div>
                    <div className="rounded-[12px] bg-[#F0FDF4] py-6 px-8 flex items-center">
                        <div>
                            <div className="w-8 h-8 rounded-full bg-[#22C55E] flex items-center justify-center mb-5">
                                <i className="ri-sparkling-2-fill text-xl text-white"></i>
                            </div>
                            <p className=" font-rocGroteskMedium text-g-75 mb-4">
                                <span className="block text-lg">Recommendation</span>
                            </p>
                            <ul className="list-disc pl-3">
                                <li className="text-sm font-rocGroteskMedium text-g-75 mb-3">
                                    Your business stands to lose money through increased holding
                                    costs and potential write-offs if overstocked items expire or
                                    spoil.
                                </li>
                                <li className="text-sm font-rocGroteskMedium text-g-75">
                                    An overstock of 500 units of Vine Ripened Tomatoes at $0.64 per
                                    unit, with holding costs of $0.50 per unit per month, the
                                    monthly holding cost would be $250. Over a year, this amounts to
                                    $3,000 in unnecessary expenses.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div>
                    <span className="block text-sm font-rocGroteskMedium mb-3">Sources</span>
                    <div className="grid grid-cols-4 space-x-3 mb-4">
                        {[1, 2, 3, 4]?.map((_, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className={`p-4 rounded-[6px] bg-[#F8FAFC] ${
                                        idx >= 3 && "flex flex-col justify-between"
                                    }`}
                                >
                                    <div className="mb-1 flex items-center space-x-1">
                                        <VendorAvatar
                                            size={16}
                                            name="amazon"
                                            textClassname="!text-[8px]"
                                        />
                                        {idx < 3 ? (
                                            <span className="text-xs font-rocGroteskMedium text-slate-500">
                                                amazon.com
                                            </span>
                                        ) : (
                                            <VendorAvatar
                                                size={16}
                                                name="amazon"
                                                textClassname="!text-[8px]"
                                            />
                                        )}
                                    </div>
                                    <span className="block text-base font-rocGroteskMedium text-g-75 leading-[24px]">
                                        {idx < 3
                                            ? "Retail Universe for U.S. Premium Natural Organic Food, Supple..."
                                            : "View 2 more links"}
                                    </span>
                                    {idx < 3 ? (
                                        <div className="flex items-center justify-between mt-1">
                                            <div className="flex items-center space-x-1.5">
                                                <span className="w-5 h-5 flex items-center justify-center rounded-full border border-slate-100">
                                                    <i className="ri-cloud-fill text-xs text-[#56B648]"></i>
                                                </span>
                                                <span className="text-xs font-rocGroteskMedium text-slate-500">
                                                    Weather
                                                </span>
                                            </div>
                                            <span className="text-xs font-rocGroteskMedium text-slate-500">
                                                1
                                            </span>
                                        </div>
                                    ) : (
                                        <i className="ri-arrow-right-line text-xl"></i>
                                    )}
                                </div>
                            );
                        })}
                    </div>

                    <span className="block text-sm font-rocGroteskMedium text-slate-500">
                        Forcasted on 26th Tue 2024
                    </span>
                </div>
            </div> */}

            <div className="pt-2">
                <div className="flex justify-between items-center mb-4">
                    <h4 className="text-xl font-rocGroteskMedium">Sales orders</h4>

                    <Dropdown
                        handleChange={(name, value) => {
                            console.log("first", name, value);
                        }}
                        dropdown={
                            <div className="flex items-center whitespace-nowrap h-[40px] py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                <span className={`text-g-75 font-rocGroteskMedium text-[13px]`}>
                                    {warehouseValue?.name || "Warehouses"}
                                </span>
                                {warehouseValue?.name && (
                                    <i
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setUpdatedPage(1);
                                            handleClearFilter();
                                        }}
                                        className="ri-close-circle-line text-slate-500"
                                    ></i>
                                )}
                                <i className="ri-arrow-down-s-line text-g-75"></i>
                            </div>
                        }
                        search={
                            <div>
                                <TextInput
                                    value={search}
                                    name={"search"}
                                    type={"text"}
                                    required={false}
                                    onChange={handleSearch}
                                    onInput={handleDebouncedChange}
                                    inputPlaceholder="Search warehouse"
                                    containerClassname=""
                                    // inputContainerClassname={"!border-none !rounded-none "}
                                    inputClassName="w-full"
                                />
                            </div>
                        }
                        dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 left-[-172px] rounded !max-h-fit`}
                        dropdownOptions={[
                            {
                                customChild: (
                                    <div>
                                        <div className="max-h-[280px]">
                                            {allWarehouses?.map((warehouse) => {
                                                return (
                                                    <div
                                                        key={warehouse?._id}
                                                        onClick={() => {
                                                            setUpdatedPage(1);
                                                            handleFilterChange(
                                                                warehouse?.warehouseName,
                                                                warehouse?._id
                                                            );
                                                        }}
                                                    >
                                                        <div
                                                            className={`flex items-center space-x-3 px-3 py-2 hover:bg-slate-100 cursor-pointer ${
                                                                warehouseValue?.value ===
                                                                    warehouse?._id && "bg-slate-100"
                                                            }`}
                                                        >
                                                            <i className="ri-map-pin-line text-base text-g-75 "></i>
                                                            <div>
                                                                <span
                                                                    className={`block text-g-75 text-sm font-rocGroteskMedium ${
                                                                        warehouse?.address
                                                                            ?.address && "mb-[2px]"
                                                                    }`}
                                                                >
                                                                    {truncate(
                                                                        warehouse?.warehouseName,
                                                                        { length: 40 }
                                                                    )}
                                                                </span>
                                                                <span className="block text-g-75 text-sm font-rocGroteskMedium ">
                                                                    {truncate(
                                                                        warehouse?.address?.address,
                                                                        { length: 40 }
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>

                <div>
                    {salesOrderData.length > 0 ? (
                        <div>
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isScrollable={false}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-6"
                            />

                            {isFetching && isLoadingMore && (
                                <div className="flex justify-center my-4">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {pagination?.currentPage === pagination?.noOfPages && (
                                <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                    End of list
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <div className="flex justify-center items-center h-[300px]">
                                <span className="text-g-75 font-rocGroteskMedium text-lg">
                                    No sales data available
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Sales;
