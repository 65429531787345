import moment from 'moment';

export const convertDaysToWeeks = (days) => {
    const weeks = Math.floor(days / 7);
    const remainingDays = days % 7;

    let result = '';
    if (weeks > 0) {
        result += `${weeks} week${weeks > 1 ? 's' : ''}`;
    }
    if (remainingDays > 0) {
        if (weeks > 0) result += ' and ';
        result += `${remainingDays} day${remainingDays > 1 ? 's' : ''}`;
    }

    return result || '-----';
};