import { inventoryTypes } from "redux/Ldb/types";

const initialState = {
    addingSingleProduct: false,
    addSingleProductSuccess: null,
    addSingleProductFailure: null,
    updatingSingleProduct: false,
    updatedSingleProductSuccess: null,
    updatedSingleProductFailure: null,
    addingMultipleProducts: false,
    addMultipleProductsSuccess: null,
    addMultipleProductsFailure: null,
    fetchingSingleInventory: false,
    fetchedSingleInventorySuccess: null,
    fetchedSingleInventoryFailure: null,
    fetchingStockList: false,
    fetchedStockListSuccess: null,
    fetchedStockListFailure: null,
    addingProductToStockCount: false,
    addedProductToStockCountSuccess: null,
    addedProductToStockCountFailure: null,
    addingMultipleProductToStockCount: false,
    addedMultipleProductToStockCountSuccess: null,
    addedMultipleProductToStockCountFailure: null,
    fetchingWarehouseList: false,
    fetchedWarehouseListSuccess: null,
    fetchedWarehouseListFailure: null,
    fetchingWarehouseSearchList: false,
    fetchedWarehouseSearchListSuccess: null,
    fetchedWarehouseSearchListFailure: null,
    createWarehouse: false,
    createWarehouseSuccess: null,
    createWarehouseFailure: null,
    updateWarehouse: false,
    updateWarehouseSuccess: null,
    updateWarehouseFailure: null,
    fetchingSingleWarehouseList: false,
    fetchedSingleWarehouseListSuccess: null,
    fetchedSingleWarehouseListFailure: null,
    deleteWarehouse: false,
    deleteWarehouseSuccess: null,
    deleteWarehouseFailure: null,
    fetchingSearchInventoryListing: false,
    fetchedSearchInventoryListingSuccess: null,
    fetchedSearchInventoryListingFailure: null,
    fetchingWarehouses: false,
    fetchedWarehousesSuccess: null,
    fetchedWarehousesFailure: null,
    fetchingInventoryList: false,
    fetchedInventoryListSuccess: null,
    fetchedInventoryListFailure: null,
    fetchingTransferStocks: false,
    fetchedTransferStocksSuccess: null,
    fetchedTransferStocksFailure: null,
    transferringStocks: false,
    transferStocksSuccess: null,
    transferStocksFailure: null,
    transferringStockFromWarehouse: false,
    transferStockFromWarehouseSuccess: null,
    transferStockFromWarehouseFailure: null,
    fetchingSearchProductListing: false,
    fetchedSearchProductListingSuccess: null,
    fetchedSearchProductListingFailure: null,
    fetchingListStockWarehouse: false,
    fetchedListStockWarehouseSuccess: null,
    fetchedListStockWarehouseFailure: null,
    fetchingSingleInventorySuppliers: false,
    fetchedSingleInventorySuppliersSuccess: null,
    fetchedSingleInventorySuppliersFailure: null,
    fetchingInventoryHistory: false,
    fetchedInventoryHistorySuccess: null,
    fetchedInventoryHistoryFailure: null,
    fetchingSingleInventoryPurchaseOrder: false,
    fetchedSingleInventoryPurchaseOrderSuccess: null,
    fetchedSingleInventoryPurchaseOrderFailure: null,
    fetchingInventoryWarehouse: false,
    fetchedInventoryWarehouseSuccess: null,
    fetchedInventoryWarehouseFailure: null,
    fetchingInventoryOrders: false,
    fetchedInventoryOrdersSuccess: null,
    fetchedInventoryOrdersFailure: null,
    fetchingSingleStockWarehouse: false,
    fetchedSingleStockWareSuccess: null,
    fetchedSingleStockWarehouseFailure: null,
    fetchingSingleStock: false,
    fetchedSingleStockSuccess: null,
    fetchedSingleStockFailure: null,
    updatingStock: false,
    updatedStockSuccess: null,
    updatedStockFailure: null,
    fetchingSingleTransferStock: false,
    fetchedSingleTransferStockSuccess: null,
    fetchedSingleTransferStockFailure: null,
    fetchingStockAdjustmentList: false,
    fetchedStockAdjustmentSuccess: null,
    fetchedStockAdjustmentFailure: null,
    fetchingSingleStockAdjustment: false,
    fetchedSingleStockAdjustmentSuccess: null,
    fetchedSingleStockAdjustmentFailure: null,
    addingNewStockAdjustment: false,
    addedNewStockAdjustmentSuccess: null,
    addedNewStockAdjustmentFailure: null,
    addingInventorySettings: false,
    addedInventorySettingsSuccess: null,
    addedInventorySettingsFailure: null,
    updatingInventorySettings: false,
    updatedInventorySettingsSuccess: null,
    updatedInventorySettingsFailure: null,
    fetchingInventorySettings: false,
    fetchedInventorySettingsSuccess: null,
    fetchedInventorySettingsFailure: null,
    addingProductVendor: false,
    addedProductVendorSuccess: null,
    addedProductVendorFailure: null,
    fetchingSuppliedStockList: false,
    fetchedSuppliedStockListSuccess: null,
    fetchedSuppliedStockListFailure: null,
    fetchingSingleSuppliedStock: false,
    fetchedSingleSuppliedStockSuccess: null,
    fetchedSingleSuppliedStockFailure: null,
    addingSuppliedStock: false,
    addedSuppliedStockSuccess: null,
    addedSuppliedStockFailure: null,
    updatingSuppliedStock: false,
    updatedSuppliedStockSuccess: null,
    updatedSuppliedStockFailure: null,
    fetchingSuppliedStockListAllData: false,
    fetchedSuppliedStockListAllDataSuccess: null,
    fetchedSuppliedStockListAllDataFailure: null,
    fetchingTopPerformingProducts: false,
    fetchedTopPerformingProductsSuccess: null,
    fetchedTopPerformingProductsFailure: null,
    fetchingLeastPerformingProducts: false,
    fetchedLeastPerformingProductsSuccess: null,
    fetchedLeastPerformingProductsFailure: null,
    fetchingLocationStockLevels: false,
    fetchedLocationStockLevelsSuccess: null,
    fetchedLocationStockLevelsFailure: null,
    fetchingProductSuppliers: false,
    fetchedProductSuppliersSuccess: null,
    fetchedProductSuppliersFailure: null,
    fetchingInventoryGrowthRate: false,
    fetchedInventoryGrowthRateSuccess: null,
    fetchedInventoryGrowthRateFailure: null,
    fetchingGMWValues: false,
    fetchedGMWValuesSuccess: null,
    fetchedGMWValuesFailure: null,
    fetchingSingleProductGMW: false,
    fetchedSingleProductGMWSuccess: null,
    fetchedSingleProductGMWFailure: null,
    fetchingSingleProductStockLevels: false,
    fetchedSingleProductStockLevelsSuccess: null,
    fetchedSingleProductStockLevelsFailure: null,
    fetchingSingleProductBom: false,
    fetchedSingleProductBomSuccess: null,
    fetchedSingleProductBomFailure: null,
    fetchingSalesOrders: false,
    fetchedSalesOrdersSuccess: null,
    fetchedSalesOrdersFailure: null,
    fetchingProductsAnalytics: false,
    fetchedProductsAnalyticsSuccess: null,
    fetchedProductsAnalyticsFailure: null,
    fetchingEvents: false,
    fetchedEventsSuccess: null,
    fetchedEventsFailure: null,
    fetchingInventoryIncidents: false,
    fetchedInventoryIncidentsSuccess: null,
    fetchedInventoryIncidentsFailure: null,
    fetchingInventoryIncidentStocking: false,
    fetchedInventoryIncidentStockingSuccess: null,
    fetchedInventoryIncidentStockingFailure: null,

    fetchingInventoryIncidentOverstock: false,
    fetchedInventoryIncidentOverstockSuccess: null,
    fetchedInventoryIncidentOverstockFailure: null,

    fetchingSingleInventoryIncident: false,
    fetchedSingleInventoryIncidentSuccess: null,
    fetchedSingleInventoryIncidentFailure: null,
    addInventoryIncidentComment: false,
    addInventoryIncidentCommentSuccess: null,
    addInventoryIncidentCommentFailure: null,
    fetchingInventoryIncidentComment: false,
    fetchedInventoryIncidentCommentSuccess: null,
    fetchedInventoryIncidentCommentFailure: null,
    fetchingSalesBreakdown: false,
    fetchedSalesBreakdownSuccess: null,
    fetchedSalesBreakdownFailure: null,
    fetchingInventorySearchList: false,
    fetchedInventorySearchListSuccess: null,
    fetchedInventorySearchListFailure: null,
    fetchingExpirationList: false,
    fetchedExpirationListSuccess: null,
    fetchedExpirationListFailure: null,
    fetchingReplishmentStockList: false,
    fetchedReplishmentStockListSuccess: null,
    fetchedReplishmentStockListFailure: null,
    closeInventoryIncident: false,
    closeInventoryIncidentSuccess: null,
    closeInventoryIncidentFailure: null,
    recentInventoryIncident: false,
    recentInventoryIncidentSuccess: null,
    recentInventoryIncidentFailure: null,
    validateBatchId: false,
    validateBatchIdSuccess: null,
    validateBatchIdFailure: null,
    addingForecastEvent: false,
    addForecastEventSuccess: null,
    addForecastEventFailure: null,
    updatingForecastEvent: false,
    updateForecastEventSuccess: null,
    updateForecastEventFailure: null,
    deletingForecastEvent: false,
    deleteForecastEventSuccess: null,
    deleteForecastEventFailure: null,
};

export const inventory = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case inventoryTypes.FETCH_EXPIRATION_LIST_REQUEST:
            return {
                ...state,
                fetchingExpirationList: true,
                fetchedExpirationListSuccess: null,
                fetchedExpirationListFailure: null,
            };
        case inventoryTypes.FETCH_EXPIRATION_LIST_SUCCESS:
            return {
                ...state,
                fetchingExpirationList: false,
                fetchedExpirationListSuccess: action.payload,
                fetchedExpirationListFailure: null,
            };
        case inventoryTypes.FETCH_EXPIRATION_LIST_FAILURE:
            return {
                ...state,
                fetchingExpirationList: false,
                fetchedExpirationListSuccess: null,
                fetchedExpirationListFailure: action.payload,
            };

        case inventoryTypes.ADD_FORECAST_EVENT_REQUEST:
            return {
                ...state,
                addingForecastEvent: true,
                addForecastEventSuccess: null,
                addForecastEventFailure: null,
            };
        case inventoryTypes.ADD_FORECAST_EVENT_SUCCESS:
            return {
                ...state,
                addingForecastEvent: false,
                addForecastEventSuccess: action.payload,
                addForecastEventFailure: null,
            };
        case inventoryTypes.ADD_FORECAST_EVENT_FAILURE:
            return {
                ...state,
                addingForecastEvent: false,
                addForecastEventSuccess: null,
                addForecastEventFailure: action.payload,
            };

        case inventoryTypes.UPDATE_FORECAST_EVENT_REQUEST:
            return {
                ...state,
                updatingForecastEvent: true,
                updateForecastEventSuccess: null,
                updateForecastEventFailure: null,
            };
        case inventoryTypes.UPDATE_FORECAST_EVENT_SUCCESS:
            return {
                ...state,
                updatingForecastEvent: false,
                updateForecastEventSuccess: action.payload,
                updateForecastEventFailure: null,
            };
        case inventoryTypes.UPDATE_FORECAST_EVENT_FAILURE:
            return {
                ...state,
                updatingForecastEvent: false,
                updateForecastEventSuccess: null,
                updateForecastEventFailure: action.payload,
            };

        case inventoryTypes.DELETE_FORECAST_EVENT_REQUEST:
            return {
                ...state,
                deletingForecastEvent: true,
                deleteForecastEventSuccess: null,
                deleteForecastEventFailure: null,
            };
        case inventoryTypes.DELETE_FORECAST_EVENT_SUCCESS:
            return {
                ...state,
                deletingForecastEvent: false,
                deleteForecastEventSuccess: action.payload,
                deleteForecastEventFailure: null,
            };
        case inventoryTypes.DELETE_FORECAST_EVENT_FAILURE:
            return {
                ...state,
                deletingForecastEvent: false,
                deleteForecastEventSuccess: null,
                deleteForecastEventFailure: action.payload,
            };

        case inventoryTypes.FETCH_SALES_BREAKDOWN_REQUEST:
            return {
                ...state,
                fetchingSalesBreakdown: true,
                fetchedSalesBreakdownSuccess: null,
                fetchedSalesBreakdownFailure: null,
            };
        case inventoryTypes.FETCH_SALES_BREAKDOWN_SUCCESS:
            return {
                ...state,
                fetchingSalesBreakdown: false,
                fetchedSalesBreakdownSuccess: action.payload,
                fetchedSalesBreakdownFailure: null,
            };
        case inventoryTypes.FETCH_SALES_BREAKDOWN_FAILURE:
            return {
                ...state,
                fetchingSalesBreakdown: false,
                fetchedSalesBreakdownSuccess: null,
                fetchedSalesBreakdownFailure: action.payload,
            };

        case inventoryTypes.FETCH_INVENTORY_SEARCH_LIST_REQUEST:
            return {
                ...state,
                fetchingInventorySearchList: true,
                fetchedInventorySearchListSuccess: null,
                fetchedInventorySearchListFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_SEARCH_LIST_SUCCESS:
            return {
                ...state,
                fetchingInventorySearchList: false,
                fetchedInventorySearchListSuccess: action.payload,
                fetchedInventorySearchListFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_SEARCH_LIST_FAILURE:
            return {
                ...state,
                fetchingInventorySearchList: false,
                fetchedInventorySearchListSuccess: null,
                fetchedInventorySearchListFailure: action.payload,
            };

        case inventoryTypes.FETCH_EVENTS_REQUEST:
            return {
                ...state,
                fetchingEvents: true,
                fetchedEventsSuccess: null,
                fetchedEventsFailure: null,
            };
        case inventoryTypes.FETCH_EVENTS_SUCCESS:
            return {
                ...state,
                fetchingEvents: false,
                fetchedEventsSuccess: action.payload,
                fetchedEventsFailure: null,
            };
        case inventoryTypes.FETCH_EVENTS_FAILURE:
            return {
                ...state,
                fetchingEvents: false,
                fetchedEventsSuccess: null,
                fetchedEventsFailure: action.payload,
            };

        case inventoryTypes.FETCH_PRODUCTS_ANALYTICS_REQUEST:
            return {
                ...state,
                fetchingProductsAnalytics: true,
                fetchedProductsAnalyticsSuccess: null,
                fetchedProductsAnalyticsFailure: null,
            };
        case inventoryTypes.FETCH_PRODUCTS_ANALYTICS_SUCCESS:
            return {
                ...state,
                fetchingProductsAnalytics: false,
                fetchedProductsAnalyticsSuccess: action.payload,
                fetchedProductsAnalyticsFailure: null,
            };
        case inventoryTypes.FETCH_PRODUCTS_ANALYTICS_FAILURE:
            return {
                ...state,
                fetchingProductsAnalytics: false,
                fetchedProductsAnalyticsSuccess: null,
                fetchedProductsAnalyticsFailure: action.payload,
            };

        case inventoryTypes.FETCH_SALES_ORDERS_REQUEST:
            return {
                ...state,
                fetchingSalesOrders: true,
                fetchedSalesOrdersSuccess: null,
                fetchedSalesOrdersFailure: null,
            };
        case inventoryTypes.FETCH_SALES_ORDERS_SUCCESS:
            return {
                ...state,
                fetchingSalesOrders: false,
                fetchedSalesOrdersSuccess: action.payload,
                fetchedSalesOrdersFailure: null,
            };
        case inventoryTypes.FETCH_SALES_ORDERS_FAILURE:
            return {
                ...state,
                fetchingSalesOrders: false,
                fetchedSalesOrdersSuccess: null,
                fetchedSalesOrdersFailure: action.payload,
            };

        case inventoryTypes.FETCH_SINGLE_PRODUCT_BOM_REQUEST:
            return {
                ...state,
                fetchingSingleProductBom: true,
                fetchedSingleProductBomSuccess: null,
                fetchedSingleProductBomFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_PRODUCT_BOM_SUCCESS:
            return {
                ...state,
                fetchingSingleProductBom: false,
                fetchedSingleProductBomSuccess: action.payload,
                fetchedSingleProductBomFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_PRODUCT_BOM_FAILURE:
            return {
                ...state,
                fetchingSingleProductBom: false,
                fetchedSingleProductBomSuccess: null,
                fetchedSingleProductBomFailure: action.payload,
            };

        case inventoryTypes.FETCH_SINGLE_PRODUCT_STOCK_LEVELS_REQUEST:
            return {
                ...state,
                fetchingSingleProductStockLevels: true,
                fetchedSingleProductStockLevelsSuccess: null,
                fetchedSingleProductStockLevelsFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_PRODUCT_STOCK_LEVELS_SUCCESS:
            return {
                ...state,
                fetchingSingleProductStockLevels: false,
                fetchedSingleProductStockLevelsSuccess: action.payload,
                fetchedSingleProductStockLevelsFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_PRODUCT_STOCK_LEVELS_FAILURE:
            return {
                ...state,
                fetchingSingleProductStockLevels: false,
                fetchedSingleProductStockLevelsSuccess: null,
                fetchedSingleProductStockLevelsFailure: action.payload,
            };

        case inventoryTypes.FETCH_SINGLE_PRODUCT_GMW_REQUEST:
            return {
                ...state,
                fetchingSingleProductGMW: true,
                fetchedSingleProductGMWSuccess: null,
                fetchedSingleProductGMWFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_PRODUCT_GMW_SUCCESS:
            return {
                ...state,
                fetchingSingleProductGMW: false,
                fetchedSingleProductGMWSuccess: action.payload,
                fetchedSingleProductGMWFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_PRODUCT_GMW_FAILURE:
            return {
                ...state,
                fetchingSingleProductGMW: false,
                fetchedSingleProductGMWSuccess: null,
                fetchedSingleProductGMWFailure: action.payload,
            };

        case inventoryTypes.FETCH_GMW_VALUES_REQUEST:
            return {
                ...state,
                fetchingGMWValues: true,
                fetchedGMWValuesSuccess: null,
                fetchedGMWValuesFailure: null,
            };
        case inventoryTypes.FETCH_GMW_VALUES_SUCCESS:
            return {
                ...state,
                fetchingGMWValues: false,
                fetchedGMWValuesSuccess: action.payload,
                fetchedGMWValuesFailure: null,
            };
        case inventoryTypes.FETCH_GMW_VALUES_FAILURE:
            return {
                ...state,
                fetchingGMWValues: false,
                fetchedGMWValuesSuccess: null,
                fetchedGMWValuesFailure: action.payload,
            };

        case inventoryTypes.FETCH_INVENTORY_GROWTH_RATE_REQUEST:
            return {
                ...state,
                fetchingInventoryGrowthRate: true,
                fetchedInventoryGrowthRateSuccess: null,
                fetchedInventoryGrowthRateFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_GROWTH_RATE_SUCCESS:
            return {
                ...state,
                fetchingInventoryGrowthRate: false,
                fetchedInventoryGrowthRateSuccess: action.payload,
                fetchedInventoryGrowthRateFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_GROWTH_RATE_FAILURE:
            return {
                ...state,
                fetchingInventoryGrowthRate: false,
                fetchedInventoryGrowthRateSuccess: null,
                fetchedInventoryGrowthRateFailure: action.payload,
            };

        case inventoryTypes.FETCH_PRODUCT_SUPPLIERS_REQUEST:
            return {
                ...state,
                fetchingProductSuppliers: true,
                fetchedProductSuppliersSuccess: null,
                fetchedProductSuppliersFailure: null,
            };
        case inventoryTypes.FETCH_PRODUCT_SUPPLIERS_SUCCESS:
            return {
                ...state,
                fetchingProductSuppliers: false,
                fetchedProductSuppliersSuccess: action.payload,
                fetchedProductSuppliersFailure: null,
            };
        case inventoryTypes.FETCH_PRODUCT_SUPPLIERS_FAILURE:
            return {
                ...state,
                fetchingProductSuppliers: false,
                fetchedProductSuppliersSuccess: null,
                fetchedProductSuppliersFailure: action.payload,
            };

        case inventoryTypes.FETCH_LOCATION_STOCK_LEVELS_REQUEST:
            return {
                ...state,
                fetchingLocationStockLevels: true,
                fetchedLocationStockLevelsSuccess: null,
                fetchedLocationStockLevelsFailure: null,
            };
        case inventoryTypes.FETCH_LOCATION_STOCK_LEVELS_SUCCESS:
            return {
                ...state,
                fetchingLocationStockLevels: false,
                fetchedLocationStockLevelsSuccess: action.payload,
                fetchedLocationStockLevelsFailure: null,
            };
        case inventoryTypes.FETCH_LOCATION_STOCK_LEVELS_FAILURE:
            return {
                ...state,
                fetchingLocationStockLevels: false,
                fetchedLocationStockLevelsSuccess: null,
                fetchedLocationStockLevelsFailure: action.payload,
            };

        case inventoryTypes.FETCH_TOP_PERFORMING_PRODUCTS_REQUEST:
            return {
                ...state,
                fetchingTopPerformingProducts: true,
                fetchedTopPerformingProductsSuccess: null,
                fetchedTopPerformingProductsFailure: null,
            };
        case inventoryTypes.FETCH_TOP_PERFORMING_PRODUCTS_SUCCESS:
            return {
                ...state,
                fetchingTopPerformingProducts: false,
                fetchedTopPerformingProductsSuccess: action.payload,
                fetchedTopPerformingProductsFailure: null,
            };
        case inventoryTypes.FETCH_TOP_PERFORMING_PRODUCTS_FAILURE:
            return {
                ...state,
                fetchingTopPerformingProducts: false,
                fetchedTopPerformingProductsSuccess: null,
                fetchedTopPerformingProductsFailure: action.payload,
            };

        case inventoryTypes.FETCH_LEAST_PERFORMING_PRODUCTS_REQUEST:
            return {
                ...state,
                fetchingLeastPerformingProducts: true,
                fetchedLeastPerformingProductsSuccess: null,
                fetchedLeastPerformingProductsFailure: null,
            };
        case inventoryTypes.FETCH_LEAST_PERFORMING_PRODUCTS_SUCCESS:
            return {
                ...state,
                fetchingLeastPerformingProducts: false,
                fetchedLeastPerformingProductsSuccess: action.payload,
                fetchedLeastPerformingProductsFailure: null,
            };
        case inventoryTypes.FETCH_LEAST_PERFORMING_PRODUCTS_FAILURE:
            return {
                ...state,
                fetchingLeastPerformingProducts: false,
                fetchedLeastPerformingProductsSuccess: null,
                fetchedLeastPerformingProductsFailure: action.payload,
            };

        case inventoryTypes.ADD_SINGLE_PRODUCT_REQUEST:
            return {
                ...state,
                addingSingleProduct: true,
                addSingleProductSuccess: null,
                addSingleProductFailure: null,
            };
        case inventoryTypes.ADD_SINGLE_PRODUCT_SUCCESS:
            return {
                ...state,
                addingSingleProduct: false,
                addSingleProductSuccess: action.payload,
                addSingleProductFailure: null,
            };
        case inventoryTypes.ADD_SINGLE_PRODUCT_FAILURE:
            return {
                ...state,
                addingSingleProduct: false,
                addSingleProductSuccess: null,
                addSingleProductFailure: action.payload,
            };

        case inventoryTypes.UPDATE_SINGLE_PRODUCT_REQUEST:
            return {
                ...state,
                updatingSingleProduct: true,
                updatedSingleProductSuccess: null,
                updatedSingleProductFailure: null,
            };
        case inventoryTypes.UPDATE_SINGLE_PRODUCT_SUCCESS:
            return {
                ...state,
                updatingSingleProduct: false,
                updatedSingleProductSuccess: action.payload,
                updatedSingleProductFailure: null,
            };
        case inventoryTypes.UPDATE_SINGLE_PRODUCT_FAILURE:
            return {
                ...state,
                updatingSingleProduct: false,
                updatedSingleProductSuccess: null,
                updatedSingleProductFailure: action.payload,
            };

        case inventoryTypes.ADD_MULTIPLE_PRODUCTS_REQUEST:
            return {
                ...state,
                addingMultipleProducts: true,
                addMultipleProductsSuccess: null,
                addMultipleProductsFailure: null,
            };
        case inventoryTypes.ADD_MULTIPLE_PRODUCTS_SUCCESS:
            return {
                ...state,
                addingMultipleProducts: false,
                addMultipleProductsSuccess: action.payload,
                addMultipleProductsFailure: null,
            };
        case inventoryTypes.ADD_MULTIPLE_PRODUCTS_FAILURE:
            return {
                ...state,
                addingMultipleProducts: false,
                addMultipleProductsSuccess: null,
                addMultipleProductsFailure: action.payload,
            };

        case inventoryTypes.TRANSFER_STOCK_FROM_WAREHOUSE_REQUEST:
            return {
                ...state,
                transferringStockFromWarehouse: true,
                transferStockFromWarehouseSuccess: null,
                transferStockFromWarehouseFailure: null,
            };
        case inventoryTypes.TRANSFER_STOCK_FROM_WAREHOUSE_SUCCESS:
            return {
                ...state,
                transferringStockFromWarehouse: false,
                transferStockFromWarehouseSuccess: action.payload,
                transferStockFromWarehouseFailure: null,
            };
        case inventoryTypes.TRANSFER_STOCK_FROM_WAREHOUSE_FAILURE:
            return {
                ...state,
                transferringStockFromWarehouse: false,
                transferStockFromWarehouseSuccess: null,
                transferStockFromWarehouseFailure: action.payload,
            };

        case inventoryTypes.FETCH_SINGLE_INVENTORY_REQUEST:
            return {
                ...state,
                fetchingSingleInventory: true,
                fetchedSingleInventorySuccess: null,
                fetchedSingleInventoryFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_INVENTORY_SUCCESS:
            return {
                ...state,
                fetchingSingleInventory: false,
                fetchedSingleInventorySuccess: action.payload,
                fetchedSingleInventoryFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_INVENTORY_FAILURE:
            return {
                ...state,
                fetchingSingleInventory: false,
                fetchedSingleInventorySuccess: null,
                fetchedSingleInventoryFailure: action.payload,
            };
        case inventoryTypes.FETCH_STOCK_LIST_REQUEST:
            return {
                ...state,
                fetchingStockList: true,
                fetchedStockListSuccess: null,
                fetchedStockListFailure: null,
            };
        case inventoryTypes.FETCH_STOCK_LIST_SUCCESS:
            return {
                ...state,
                fetchingStockList: false,
                fetchedStockListSuccess: action.payload,
                fetchedStockListFailure: null,
            };
        case inventoryTypes.FETCH_STOCK_LIST_FAILURE:
            return {
                ...state,
                fetchingStockList: false,
                fetchedStockListSuccess: null,
                fetchedStockListFailure: action.payload,
            };
        case inventoryTypes.ADD_PRODUCT_TO_STOCK_COUNT_REQUEST:
            return {
                ...state,
                addingProductToStockCount: true,
                addedProductToStockCountSuccess: null,
                addedProductToStockCountFailure: null,
            };
        case inventoryTypes.ADD_PRODUCT_TO_STOCK_COUNT_SUCCESS:
            return {
                ...state,
                addingProductToStockCount: false,
                addedProductToStockCountSuccess: action.payload,
                addedProductToStockCountFailure: null,
            };
        case inventoryTypes.ADD_PRODUCT_TO_STOCK_COUNT_FAILURE:
            return {
                ...state,
                addingProductToStockCount: false,
                addedProductToStockCountSuccess: null,
                addedProductToStockCountFailure: action.payload,
            };
        case inventoryTypes.ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_REQUEST:
            return {
                ...state,
                addingMultipleProductToStockCount: true,
                addedMultipleProductToStockCountSuccess: null,
                addedMultipleProductToStockCountFailure: null,
            };
        case inventoryTypes.ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_SUCCESS:
            return {
                ...state,
                addingMultipleProductToStockCount: false,
                addedMultipleProductToStockCountSuccess: action.payload,
                addedMultipleProductToStockCountFailure: null,
            };
        case inventoryTypes.ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_FAILURE:
            return {
                ...state,
                addingMultipleProductToStockCount: false,
                addedMultipleProductToStockCountSuccess: null,
                addedMultipleProductToStockCountFailure: action.payload,
            };

        case inventoryTypes.FETCH_WAREHOUSE_LIST_REQUEST:
            return {
                ...state,
                fetchingWarehouseList: true,
                fetchedWarehouseListSuccess: null,
                fetchedWarehouseListFailure: null,
            };
        case inventoryTypes.FETCH_WAREHOUSE_LIST_SUCCESS:
            return {
                ...state,
                fetchingWarehouseList: false,
                fetchedWarehouseListSuccess: action.payload,
                fetchedWarehouseListFailure: null,
            };
        case inventoryTypes.FETCH_WAREHOUSE_LIST_FAILURE:
            return {
                ...state,
                fetchingWarehouseList: false,
                fetchedWarehouseListSuccess: null,
                fetchedWarehouseListFailure: action.payload,
            };

        case inventoryTypes.FETCH_WAREHOUSE_SEARCH_LIST_REQUEST:
            return {
                ...state,
                fetchingWarehouseSearchList: true,
                fetchedWarehouseSearchListSuccess: null,
                fetchedWarehouseSearchListFailure: null,
            };
        case inventoryTypes.FETCH_WAREHOUSE_SEARCH_LIST_SUCCESS:
            return {
                ...state,
                fetchingWarehouseSearchList: false,
                fetchedWarehouseSearchListSuccess: action.payload,
                fetchedWarehouseSearchListFailure: null,
            };
        case inventoryTypes.FETCH_WAREHOUSE_SEARCH_LIST_FAILURE:
            return {
                ...state,
                fetchingWarehouseSearchList: false,
                fetchedWarehouseSearchListSuccess: null,
                fetchedWarehouseSearchListFailure: action.payload,
            };
        case inventoryTypes.CREATE_WAREHOUSE_REQUEST:
            return {
                ...state,
                createWarehouse: true,
                createWarehouseSuccess: null,
                createWarehouseFailure: null,
            };
        case inventoryTypes.CREATE_WAREHOUSE_SUCCESS:
            return {
                ...state,
                createWarehouse: false,
                createWarehouseSuccess: action.payload,
                createWarehouseFailure: null,
            };
        case inventoryTypes.CREATE_WAREHOUSE_FAILURE:
            return {
                ...state,
                createWarehouse: false,
                createWarehouseSuccess: null,
                createWarehouseFailure: action.payload,
            };

        case inventoryTypes.FETCH_SEARCH_INVENTORY_LISTING_REQUEST:
            return {
                ...state,
                fetchingSearchInventoryListing: true,
                fetchedSearchInventoryListingSuccess: null,
                fetchedSearchInventoryListingFailure: null,
            };
        case inventoryTypes.FETCH_SEARCH_INVENTORY_LISTING_SUCCESS:
            return {
                ...state,
                fetchingSearchInventoryListing: false,
                fetchedSearchInventoryListingSuccess: action.payload,
                fetchedSearchInventoryListingFailure: null,
            };
        case inventoryTypes.FETCH_SEARCH_INVENTORY_LISTING_FAILURE:
            return {
                ...state,
                fetchingSearchInventoryListing: false,
                fetchedSearchInventoryListingSuccess: null,
                fetchedSearchInventoryListingFailure: action.payload,
            };
        case inventoryTypes.FETCH_WAREHOUSES_REQUEST:
            return {
                ...state,
                fetchingWarehouses: true,
                fetchedWarehousesSuccess: null,
                fetchedWarehousesFailure: null,
            };
        case inventoryTypes.FETCH_WAREHOUSES_SUCCESS:
            return {
                ...state,
                fetchingWarehouses: false,
                fetchedWarehousesSuccess: action.payload,
                fetchedWarehousesFailure: null,
            };
        case inventoryTypes.FETCH_WAREHOUSES_FAILURE:
            return {
                ...state,
                fetchingWarehouses: false,
                fetchedWarehousesSuccess: null,
                fetchedWarehousesFailure: action.payload,
            };
        case inventoryTypes.FETCH_INVENTORY_LIST_REQUEST:
            return {
                ...state,
                fetchingInventoryList: true,
                fetchedInventoryListSuccess: null,
                fetchedInventoryListFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_LIST_SUCCESS:
            return {
                ...state,
                fetchingInventoryList: false,
                fetchedInventoryListSuccess: action.payload,
                fetchedInventoryListFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_LIST_FAILURE:
            return {
                ...state,
                fetchingInventoryList: false,
                fetchedInventoryListSuccess: null,
                fetchedInventoryListFailure: action.payload,
            };
        case inventoryTypes.FETCH_TRANSFER_STOCKS_REQUEST:
            return {
                ...state,
                fetchingTransferStocks: true,
                fetchedTransferStocksSuccess: null,
                fetchedTransferStocksFailure: null,
            };
        case inventoryTypes.FETCH_TRANSFER_STOCKS_SUCCESS:
            return {
                ...state,
                fetchingTransferStocks: false,
                fetchedTransferStocksSuccess: action.payload,
                fetchedTransferStocksFailure: null,
            };
        case inventoryTypes.FETCH_TRANSFER_STOCKS_FAILURE:
            return {
                ...state,
                fetchingTransferStocks: false,
                fetchedTransferStocksSuccess: null,
                fetchedTransferStocksFailure: action.payload,
            };
        case inventoryTypes.TRANSFER_STOCKS_REQUEST:
            return {
                ...state,
                transferringStocks: true,
                transferStocksSuccess: null,
                transferStocksFailure: null,
            };
        case inventoryTypes.TRANSFER_STOCKS_SUCCESS:
            return {
                ...state,
                transferringStocks: false,
                transferStocksSuccess: action.payload,
                transferStocksFailure: null,
            };
        case inventoryTypes.TRANSFER_STOCKS_FAILURE:
            return {
                ...state,
                transferringStocks: false,
                transferStocksSuccess: null,
                transferStocksFailure: action.payload,
            };

        case inventoryTypes.FETCH_SEARCH_PRODUCT_LISTING_REQUEST:
            return {
                ...state,
                fetchingSearchProductListing: true,
                fetchedSearchProductListingSuccess: null,
                fetchedSearchProductListingFailure: null,
            };
        case inventoryTypes.FETCH_SEARCH_PRODUCT_LISTING_SUCCESS:
            return {
                ...state,
                fetchingSearchProductListing: false,
                fetchedSearchProductListingSuccess: action.payload,
                fetchedSearchProductListingFailure: null,
            };
        case inventoryTypes.FETCH_SEARCH_PRODUCT_LISTING_FAILURE:
            return {
                ...state,
                fetchingSearchProductListing: false,
                fetchedSearchProductListingSuccess: null,
                fetchedSearchProductListingFailure: action.payload,
            };
        case inventoryTypes.DELETE_WAREHOUSE_REQUEST:
            return {
                ...state,
                deleteWarehouse: true,
                deleteWarehouseSuccess: null,
                deleteWarehouseFailure: null,
            };
        case inventoryTypes.DELETE_WAREHOUSE_SUCCESS:
            return {
                ...state,
                deleteWarehouse: false,
                deleteWarehouseSuccess: action.payload,
                deleteWarehouseFailure: null,
            };
        case inventoryTypes.DELETE_WAREHOUSE_FAILURE:
            return {
                ...state,
                deleteWarehouse: false,
                deleteWarehouseSuccess: null,
                deleteWarehouseFailure: action.payload,
            };
        case inventoryTypes.FETCH_SINGLE_WAREHOUSE_LIST_REQUEST:
            return {
                ...state,
                fetchingSingleWarehouseList: true,
                fetchedSingleWarehouseListSuccess: null,
                fetchedSingleWarehouseListFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_WAREHOUSE_LIST_SUCCESS:
            return {
                ...state,
                fetchingSingleWarehouseList: false,
                fetchedSingleWarehouseListSuccess: action.payload,
                fetchedSingleWarehouseListFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_WAREHOUSE_LIST_FAILURE:
            return {
                ...state,
                fetchingSingleWarehouseList: false,
                fetchedSingleWarehouseListSuccess: null,
                fetchedSingleWarehouseListFailure: action.payload,
            };

        case inventoryTypes.FETCH_LIST_STOCK_WAREHOUSE_REQUEST:
            return {
                ...state,
                fetchingListStockWarehouse: true,
                fetchedListStockWarehouseSuccess: null,
                fetchedListStockWarehouseFailure: null,
            };
        case inventoryTypes.FETCH_LIST_STOCK_WAREHOUSE_SUCCESS:
            return {
                ...state,
                fetchingListStockWarehouse: false,
                fetchedListStockWarehouseSuccess: action.payload,
                fetchedListStockWarehouseFailure: null,
            };
        case inventoryTypes.FETCH_LIST_STOCK_WAREHOUSE_FAILURE:
            return {
                ...state,
                fetchingListStockWarehouse: false,
                fetchedListStockWarehouseSuccess: null,
                fetchedListStockWarehouseFailure: action.payload,
            };

        case inventoryTypes.UPDATE_WAREHOUSE_REQUEST:
            return {
                ...state,
                updateWarehouse: true,
                updateWarehouseSuccess: null,
                updateWarehouseFailure: null,
            };
        case inventoryTypes.UPDATE_WAREHOUSE_SUCCESS:
            return {
                ...state,
                updateWarehouse: false,
                updateWarehouseSuccess: action.payload,
                updateWarehouseFailure: null,
            };
        case inventoryTypes.UPDATE_WAREHOUSE_FAILURE:
            return {
                ...state,
                updateWarehouse: false,
                updateWarehouseSuccess: null,
                updateWarehouseFailure: action.payload,
            };

        case inventoryTypes.FETCH_SINGLE_INVENTORY_SUPPLIERS_REQUEST:
            return {
                ...state,
                fetchingSingleInventorySuppliers: true,
                fetchedSingleInventorySuppliersSuccess: null,
                fetchedSingleInventorySuppliersFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_INVENTORY_SUPPLIERS_SUCCESS:
            return {
                ...state,
                fetchingSingleInventorySuppliers: false,
                fetchedSingleInventorySuppliersSuccess: action.payload,
                fetchedSingleInventorySuppliersFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_INVENTORY_SUPPLIERS_FAILURE:
            return {
                ...state,
                fetchingSingleInventorySuppliers: false,
                fetchedSingleInventorySuppliersSuccess: null,
                fetchedSingleInventorySuppliersFailure: action.payload,
            };
        case inventoryTypes.FETCH_INVENTORY_HISTORY_REQUEST:
            return {
                ...state,
                fetchingInventoryHistory: true,
                fetchedInventoryHistorySuccess: null,
                fetchedInventoryHistoryFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_HISTORY_SUCCESS:
            return {
                ...state,
                fetchingInventoryHistory: false,
                fetchedInventoryHistorySuccess: action.payload,
                fetchedInventoryHistoryFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_HISTORY_FAILURE:
            return {
                ...state,
                fetchingInventoryHistory: false,
                fetchedInventoryHistorySuccess: null,
                fetchedInventoryHistoryFailure: action.payload,
            };

        case inventoryTypes.FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                fetchingSingleInventoryPurchaseOrder: true,
                fetchedSingleInventoryPurchaseOrderSuccess: null,
                fetchedSingleInventoryPurchaseOrderFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                fetchingSingleInventoryPurchaseOrder: false,
                fetchedSingleInventoryPurchaseOrderSuccess: action.payload,
                fetchedSingleInventoryPurchaseOrderFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_FAILURE:
            return {
                ...state,
                fetchingSingleInventoryPurchaseOrder: false,
                fetchedSingleInventoryPurchaseOrderSuccess: null,
                fetchedSingleInventoryPurchaseOrderFailure: action.payload,
            };

        case inventoryTypes.FETCH_INVENTORY_WAREHOUSE_REQUEST:
            return {
                ...state,
                fetchingInventoryWarehouse: true,
                fetchedInventoryWarehouseSuccess: null,
                fetchedInventoryWarehouseFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_WAREHOUSE_SUCCESS:
            return {
                ...state,
                fetchingInventoryWarehouse: false,
                fetchedInventoryWarehouseSuccess: action.payload,
                fetchedInventoryWarehouseFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_WAREHOUSE_FAILURE:
            return {
                ...state,
                fetchingInventoryWarehouse: false,
                fetchedInventoryWarehouseSuccess: null,
                fetchedInventoryWarehouseFailure: action.payload,
            };

        case inventoryTypes.FETCH_INVENTORY_ORDERS_REQUEST:
            return {
                ...state,
                fetchingInventoryOrders: true,
                fetchedInventoryOrdersSuccess: null,
                fetchedInventoryOrdersFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_ORDERS_SUCCESS:
            return {
                ...state,
                fetchingInventoryOrders: false,
                fetchedInventoryOrdersSuccess: action.payload,
                fetchedInventoryOrdersFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_ORDERS_FAILURE:
            return {
                ...state,
                fetchingInventoryOrders: false,
                fetchedInventoryOrdersSuccess: null,
                fetchedInventoryOrdersFailure: action.payload,
            };

        case inventoryTypes.FETCH_SINGLE_STOCK_WAREHOUSE_REQUEST:
            return {
                ...state,
                fetchingSingleStockWarehouse: true,
                fetchedSingleStockWareSuccess: null,
                fetchedSingleStockWarehouseFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_STOCK_WAREHOUSE_SUCCESS:
            return {
                ...state,
                fetchingSingleStockWarehouse: false,
                fetchedSingleStockWareSuccess: action.payload,
                fetchedSingleStockWarehouseFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_STOCK_WAREHOUSE_FAILURE:
            return {
                ...state,
                fetchingSingleStockWarehouse: false,
                fetchedSingleStockWareSuccess: null,
                fetchedSingleStockWarehouseFailure: action.payload,
            };

        case inventoryTypes.FETCH_SINGLE_STOCK_REQUEST:
            return {
                ...state,
                fetchingSingleStock: true,
                fetchedSingleStockSuccess: null,
                fetchedSingleStockFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_STOCK_SUCCESS:
            return {
                ...state,
                fetchingSingleStock: false,
                fetchedSingleStockSuccess: action.payload,
                fetchedSingleStockFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_STOCK_FAILURE:
            return {
                ...state,
                fetchingSingleStock: false,
                fetchedSingleStockSuccess: null,
                fetchedSingleStockFailure: action.payload,
            };
        case inventoryTypes.UPDATE_STOCK_REQUEST:
            return {
                ...state,
                updatingStock: true,
                updatedStockSuccess: null,
                updatedStockFailure: null,
            };
        case inventoryTypes.UPDATE_STOCK_SUCCESS:
            return {
                ...state,
                updatingStock: false,
                updatedStockSuccess: action.payload,
                updatedStockFailure: null,
            };
        case inventoryTypes.UPDATE_STOCK_FAILURE:
            return {
                ...state,
                updatingStock: false,
                updatedStockSuccess: null,
                updatedStockFailure: action.payload,
            };
        case inventoryTypes.FETCH_SINGLE_TRANSFER_STOCK_REQUEST:
            return {
                ...state,
                fetchingSingleTransferStock: true,
                fetchedSingleTransferStockSuccess: null,
                fetchedSingleTransferStockFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_TRANSFER_STOCK_SUCCESS:
            return {
                ...state,
                fetchingSingleTransferStock: false,
                fetchedSingleTransferStockSuccess: action.payload,
                fetchedSingleTransferStockFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_TRANSFER_STOCK_FAILURE:
            return {
                ...state,
                fetchingSingleTransferStock: false,
                fetchedSingleTransferStockSuccess: null,
                fetchedSingleTransferStockFailure: action.payload,
            };
        case inventoryTypes.FETCH_STOCK_ADJUSTMENT_LIST_REQUEST:
            return {
                ...state,
                fetchingStockAdjustmentList: true,
                fetchedStockAdjustmentSuccess: null,
                fetchedStockAdjustmentFailure: null,
            };
        case inventoryTypes.FETCH_STOCK_ADJUSTMENT_LIST_SUCCESS:
            return {
                ...state,
                fetchingStockAdjustmentList: false,
                fetchedStockAdjustmentSuccess: action.payload,
                fetchedStockAdjustmentFailure: null,
            };
        case inventoryTypes.FETCH_STOCK_ADJUSTMENT_LIST_FAILURE:
            return {
                ...state,
                fetchingStockAdjustmentList: false,
                fetchedStockAdjustmentSuccess: null,
                fetchedStockAdjustmentFailure: action.payload,
            };
        case inventoryTypes.FETCH_SINGLE_STOCK_ADJUSTMENT_REQUEST:
            return {
                ...state,
                fetchingSingleStockAdjustment: true,
                fetchedSingleStockAdjustmentSuccess: null,
                fetchedSingleStockAdjustmentFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_STOCK_ADJUSTMENT_SUCCESS:
            return {
                ...state,
                fetchingSingleStockAdjustment: false,
                fetchedSingleStockAdjustmentSuccess: action.payload,
                fetchedSingleStockAdjustmentFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_STOCK_ADJUSTMENT_FAILURE:
            return {
                ...state,
                fetchingSingleStockAdjustment: false,
                fetchedSingleStockAdjustmentSuccess: null,
                fetchedSingleStockAdjustmentFailure: action.payload,
            };
        case inventoryTypes.ADD_NEW_STOCK_ADJUSTMENT_REQUEST:
            return {
                ...state,
                addingNewStockAdjustment: true,
                addedNewStockAdjustmentSuccess: null,
                addedNewStockAdjustmentFailure: null,
            };
        case inventoryTypes.ADD_NEW_STOCK_ADJUSTMENT_SUCCESS:
            return {
                ...state,
                addingNewStockAdjustment: false,
                addedNewStockAdjustmentSuccess: action.payload,
                addedNewStockAdjustmentFailure: null,
            };
        case inventoryTypes.ADD_NEW_STOCK_ADJUSTMENT_FAILURE:
            return {
                ...state,
                addingNewStockAdjustment: false,
                addedNewStockAdjustmentSuccess: null,
                addedNewStockAdjustmentFailure: action.payload,
            };

        case inventoryTypes.ADD_INVENTORY_SETTINGS_REQUEST:
            return {
                ...state,
                addingInventorySettings: true,
                addedInventorySettingsSuccess: null,
                addedInventorySettingsFailure: null,
            };
        case inventoryTypes.ADD_INVENTORY_SETTINGS_SUCCESS:
            return {
                ...state,
                addingInventorySettings: false,
                addedInventorySettingsSuccess: action.payload,
                addedInventorySettingsFailure: null,
            };
        case inventoryTypes.ADD_INVENTORY_SETTINGS_FAILURE:
            return {
                ...state,
                addingInventorySettings: false,
                addedInventorySettingsSuccess: null,
                addedInventorySettingsFailure: action.payload,
            };

        case inventoryTypes.UPDATE_INVENTORY_SETTINGS_REQUEST:
            return {
                ...state,
                updatingInventorySettings: true,
                updatedInventorySettingsSuccess: null,
                updatedInventorySettingsFailure: null,
            };
        case inventoryTypes.UPDATE_INVENTORY_SETTINGS_SUCCESS:
            return {
                ...state,
                updatingInventorySettings: false,
                updatedInventorySettingsSuccess: action.payload,
                updatedInventorySettingsFailure: null,
            };
        case inventoryTypes.UPDATE_INVENTORY_SETTINGS_FAILURE:
            return {
                ...state,
                updatingInventorySettings: false,
                updatedInventorySettingsSuccess: null,
                updatedInventorySettingsFailure: action.payload,
            };

        case inventoryTypes.FETCH_INVENTORY_SETTINGS_REQUEST:
            return {
                ...state,
                fetchingInventorySettings: true,
                fetchedInventorySettingsSuccess: null,
                fetchedInventorySettingsFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_SETTINGS_SUCCESS:
            return {
                ...state,
                fetchingInventorySettings: false,
                fetchedInventorySettingsSuccess: action.payload,
                fetchedInventorySettingsFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_SETTINGS_FAILURE:
            return {
                ...state,
                fetchingInventorySettings: false,
                fetchedInventorySettingsSuccess: null,
                fetchedInventorySettingsFailure: action.payload,
            };

        case inventoryTypes.ADD_PRODUCT_VENDOR_REQUEST:
            return {
                ...state,
                addingProductVendor: true,
                addedProductVendorSuccess: null,
                addedProductVendorFailure: null,
            };
        case inventoryTypes.ADD_PRODUCT_VENDOR_SUCCESS:
            return {
                ...state,
                addingProductVendor: false,
                addedProductVendorSuccess: action.payload,
                addedProductVendorFailure: null,
            };
        case inventoryTypes.ADD_PRODUCT_VENDOR_FAILURE:
            return {
                ...state,
                addingProductVendor: false,
                addedProductVendorSuccess: null,
                addedProductVendorFailure: action.payload,
            };
        case inventoryTypes.FETCH_SINGLE_SUPPLIED_STOCK_REQUEST:
            return {
                ...state,
                fetchingSingleSuppliedStock: true,
                fetchedSingleSuppliedStockSuccess: null,
                fetchedSingleSuppliedStockFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_SUPPLIED_STOCK_SUCCESS:
            return {
                ...state,
                fetchingSingleSuppliedStock: false,
                fetchedSingleSuppliedStockSuccess: action.payload,
                fetchedSingleSuppliedStockFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_SUPPLIED_STOCK_FAILURE:
            return {
                ...state,
                fetchingSingleSuppliedStock: false,
                fetchedSingleSuppliedStockSuccess: null,
                fetchedSingleSuppliedStockFailure: action.payload,
            };
        case inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_REQUEST:
            return {
                ...state,
                fetchingSuppliedStockList: true,
                fetchedSuppliedStockListSuccess: null,
                fetchedSuppliedStockListFailure: null,
            };
        case inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_SUCCESS:
            return {
                ...state,
                fetchingSuppliedStockList: false,
                fetchedSuppliedStockListSuccess: action.payload,
                fetchedSuppliedStockListFailure: null,
            };
        case inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_FAILURE:
            return {
                ...state,
                fetchingSuppliedStockList: false,
                fetchedSuppliedStockListSuccess: null,
                fetchedSuppliedStockListFailure: action.payload,
            };
        case inventoryTypes.ADD_SUPPLIED_STOCK_REQUEST:
            return {
                ...state,
                addingSuppliedStock: true,
                addedSuppliedStockSuccess: null,
                addedSuppliedStockFailure: null,
            };
        case inventoryTypes.ADD_SUPPLIED_STOCK_SUCCESS:
            return {
                ...state,
                addingSuppliedStock: false,
                addedSuppliedStockSuccess: action.payload,
                addedSuppliedStockFailure: null,
            };
        case inventoryTypes.ADD_SUPPLIED_STOCK_FAILURE:
            return {
                ...state,
                addingSuppliedStock: false,
                addedSuppliedStockSuccess: null,
                addedSuppliedStockFailure: action.payload,
            };
        case inventoryTypes.UPDATE_SUPPLIED_STOCK_REQUEST:
            return {
                ...state,
                updatingSuppliedStock: true,
                updatedSuppliedStockSuccess: null,
                updatedSuppliedStockFailure: null,
            };
        case inventoryTypes.UPDATE_SUPPLIED_STOCK_SUCCESS:
            return {
                ...state,
                updatingSuppliedStock: false,
                updatedSuppliedStockSuccess: action.payload,
                updatedSuppliedStockFailure: null,
            };
        case inventoryTypes.UPDATE_SUPPLIED_STOCK_FAILURE:
            return {
                ...state,
                updatingSuppliedStock: false,
                updatedSuppliedStockSuccess: null,
                updatedSuppliedStockFailure: action.payload,
            };
        case inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_REQUEST:
            return {
                ...state,
                fetchingSuppliedStockListAllData: true,
                fetchedSuppliedStockListAllDataSuccess: null,
                fetchedSuppliedStockListAllDataFailure: null,
            };
        case inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_SUCCESS:
            return {
                ...state,
                fetchingSuppliedStockListAllData: false,
                fetchedSuppliedStockListAllDataSuccess: action.payload,
                fetchedSuppliedStockListAllDataFailure: null,
            };
        case inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_FAILURE:
            return {
                ...state,
                fetchingSuppliedStockListAllData: false,
                fetchedSuppliedStockListAllDataSuccess: null,
                fetchedSuppliedStockListAllDataFailure: action.payload,
            };

        case inventoryTypes.FETCH_INVENTORY_INCIDENTS_REQUEST:
            return {
                ...state,
                fetchingInventoryIncidents: true,
                fetchedInventoryIncidentsSuccess: null,
                fetchedInventoryIncidentsFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_INCIDENTS_SUCCESS:
            return {
                ...state,
                fetchingInventoryIncidents: false,
                fetchedInventoryIncidentsSuccess: action.payload,
                fetchedInventoryIncidentsFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_INCIDENTS_FAILURE:
            return {
                ...state,
                fetchingInventoryIncidents: false,
                fetchedInventoryIncidentsSuccess: null,
                fetchedInventoryIncidentsFailure: action.payload,
            };
        case inventoryTypes.FETCH_INVENTORY_INCIDENTS_STOCKING_REQUEST:
            return {
                ...state,
                fetchingInventoryIncidentStocking: true,
                fetchedInventoryIncidentStockingSuccess: null,
                fetchedInventoryIncidentStockingFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_INCIDENTS_STOCKING_SUCCESS:
            return {
                ...state,
                fetchingInventoryIncidentStocking: false,
                fetchedInventoryIncidentStockingSuccess: action.payload,
                fetchedInventoryIncidentStockingFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_INCIDENTS_STOCKING_FAILURE:
            return {
                ...state,
                fetchingInventoryIncidentStocking: false,
                fetchedInventoryIncidentStockingSuccess: null,
                fetchedInventoryIncidentStockingFailure: action.payload,
            };

        case inventoryTypes.FETCH_INVENTORY_INCIDENTS_OVER_STOCK_REQUEST:
            return {
                ...state,
                fetchingInventoryIncidentOverstock: true,
                fetchedInventoryIncidentOverstockSuccess: null,
                fetchedInventoryIncidentOverstockFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_INCIDENTS_OVER_STOCK_SUCCESS:
            return {
                ...state,
                fetchingInventoryIncidentOverstock: false,
                fetchedInventoryIncidentOverstockSuccess: action.payload,
                fetchedInventoryIncidentOverstockFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_INCIDENTS_OVER_STOCK_FAILURE:
            return {
                ...state,
                fetchingInventoryIncidentOverstock: false,
                fetchedInventoryIncidentOverstockSuccess: null,
                fetchedInventoryIncidentOverstockFailure: action.payload,
            };

        case inventoryTypes.FETCH_SINGLE_INVENTORY_INCIDENT_REQUEST:
            return {
                ...state,
                fetchingSingleInventoryIncident: true,
                fetchedSingleInventoryIncidentSuccess: null,
                fetchedSingleInventoryIncidentFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_INVENTORY_INCIDENT_SUCCESS:
            return {
                ...state,
                fetchingSingleInventoryIncident: false,
                fetchedSingleInventoryIncidentSuccess: action.payload,
                fetchedSingleInventoryIncidentFailure: null,
            };
        case inventoryTypes.FETCH_SINGLE_INVENTORY_INCIDENT_FAILURE:
            return {
                ...state,
                fetchingSingleInventoryIncident: false,
                fetchedSingleInventoryIncidentSuccess: null,
                fetchedSingleInventoryIncidentFailure: action.payload,
            };
        case inventoryTypes.ADD_INVENTORY_INCIDENT_COMMENT_REQUEST:
            return {
                ...state,
                addInventoryIncidentComment: true,
                addInventoryIncidentCommentSuccess: null,
                addInventoryIncidentCommentFailure: null,
            };
        case inventoryTypes.ADD_INVENTORY_INCIDENT_COMMENT_SUCCESS:
            return {
                ...state,
                addInventoryIncidentComment: false,
                addInventoryIncidentCommentSuccess: action.payload,
                addInventoryIncidentCommentFailure: null,
            };
        case inventoryTypes.ADD_INVENTORY_INCIDENT_COMMENT_FAILURE:
            return {
                ...state,
                addInventoryIncidentComment: false,
                addInventoryIncidentCommentSuccess: null,
                addInventoryIncidentCommentFailure: action.payload,
            };
        case inventoryTypes.FETCH_INVENTORY_INCIDENT_COMMENT_REQUEST:
            return {
                ...state,
                fetchingInventoryIncidentComment: true,
                fetchedInventoryIncidentCommentSuccess: null,
                fetchedInventoryIncidentCommentFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_INCIDENT_COMMENT_SUCCESS:
            return {
                ...state,
                fetchingInventoryIncidentComment: false,
                fetchedInventoryIncidentCommentSuccess: action.payload,
                fetchedInventoryIncidentCommentFailure: null,
            };
        case inventoryTypes.FETCH_INVENTORY_INCIDENT_COMMENT_FAILURE:
            return {
                ...state,
                fetchingInventoryIncidentComment: false,
                fetchedInventoryIncidentCommentSuccess: null,
                fetchedInventoryIncidentCommentFailure: action.payload,
            };
        case inventoryTypes.FETCH_STOCK_REPLISHMENT_LIST_REQUEST:
            return {
                ...state,
                fetchingReplishmentStockList: true,
                fetchedReplishmentStockListSuccess: null,
                fetchedReplishmentStockListFailure: null,
            };
        case inventoryTypes.FETCH_STOCK_REPLISHMENT_LIST_SUCCESS:
            return {
                ...state,
                fetchingReplishmentStockList: false,
                fetchedReplishmentStockListSuccess: action.payload,
                fetchedReplishmentStockListFailure: null,
            };
        case inventoryTypes.FETCH_STOCK_REPLISHMENT_LIST_FAILURE:
            return {
                ...state,
                fetchingReplishmentStockList: false,
                fetchedReplishmentStockListSuccess: null,
                fetchedReplishmentStockListFailure: action.payload,
            };
        case inventoryTypes.CLOSE_INVENTORY_INCIDENT_REQUEST:
            return {
                ...state,
                closeInventoryIncident: true,
                closeInventoryIncidentSuccess: null,
                closeInventoryIncidentFailure: null,
            };
        case inventoryTypes.CLOSE_INVENTORY_INCIDENT_SUCCESS:
            return {
                ...state,
                closeInventoryIncident: false,
                closeInventoryIncidentSuccess: action.payload,
                closeInventoryIncidentFailure: null,
            };
        case inventoryTypes.CLOSE_INVENTORY_INCIDENT_FAILURE:
            return {
                ...state,
                closeInventoryIncident: false,
                closeInventoryIncidentSuccess: null,
                closeInventoryIncidentFailure: action.payload,
            };
        case inventoryTypes.RECENT_INVENTORY_INCIDENT_REQUEST:
            return {
                ...state,
                recentInventoryIncident: true,
                recentInventoryIncidentSuccess: null,
                recentInventoryIncidentFailure: null,
            };
        case inventoryTypes.RECENT_INVENTORY_INCIDENT_SUCCESS:
            return {
                ...state,
                recentInventoryIncident: false,
                recentInventoryIncidentSuccess: action.payload,
                recentInventoryIncidentFailure: null,
            };
        case inventoryTypes.RECENT_INVENTORY_INCIDENT_FAILURE:
            return {
                ...state,
                recentInventoryIncident: false,
                recentInventoryIncidentSuccess: null,
                recentInventoryIncidentFailure: action.payload,
            };
        case inventoryTypes.VALIDATE_BATCHID_REQUEST:
            return {
                ...state,
                validateBatchId: true,
                validateBatchIdSuccess: null,
                validateBatchIdFailure: null,
            };
        case inventoryTypes.VALIDATE_BATCHID_SUCCESS:
            return {
                ...state,
                validateBatchId: false,
                validateBatchIdSuccess: action.payload,
                validateBatchIdFailure: null,
            };
        case inventoryTypes.VALIDATE_BATCHID_FAILURE:
            return {
                ...state,
                validateBatchId: false,
                validateBatchIdSuccess: null,
                validateBatchIdFailure: action.payload,
            };
        default:
            return state;
    }
};
