import CustomToast from "components/common/CustomToast";
import { businessAnalyticsType } from "redux//Ldb/types";
import { toast } from "react-hot-toast";
import { businessAnalyticsService } from "services/Ldb";

export const businessAnalyticsActions = {
    purchaseSummary,
    purchaseOrder,
    spendAnalytics,
    procurementOrderGraph,
    procurementOrder,
    procurementInsight,
    spendAnalyticsCatAndSup,
};

function purchaseSummary(startDate, endDate, period) {
    return (dispatch) => {
        dispatch(request(businessAnalyticsType.GET_PURCHASE_ORDER_SUMMARY_REQUEST));

        businessAnalyticsService.getPurchaseOrderSum(startDate, endDate, period).then(
            (res) => {
                dispatch(
                    success(businessAnalyticsType.GET_PURCHASE_ORDER_SUMMARY_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            businessAnalyticsType.GET_PURCHASE_ORDER_SUMMARY_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function purchaseOrder(startDate,endDate, period) {
    return (dispatch) => {
        dispatch(request(businessAnalyticsType.GET_PURCHASE_REQUEST));

        businessAnalyticsService.getPurchaseOrder(startDate, endDate, period).then(
            (res) => {
                dispatch(success(businessAnalyticsType.GET_PURCHASE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(businessAnalyticsType.GET_PURCHASE_FAILURE, error?.message));
                }
            }
        );
    };
}

function procurementOrder(period, startDate, endDate) {
    return (dispatch) => {
        dispatch(request(businessAnalyticsType.FETCH_ANALYTICS_PROCUREMENT_REQUEST));

        businessAnalyticsService.getAnalyticsProcurement(period, startDate, endDate).then(
            (res) => {
                dispatch(success(businessAnalyticsType.FETCH_ANALYTICS_PROCUREMENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(businessAnalyticsType.FETCH_ANALYTICS_PROCUREMENT_FAILURE, error?.message));
                }
            }
        );
    };
}
function procurementInsight(period,  startDate, endDate) {
    return (dispatch) => {
        dispatch(request(businessAnalyticsType.FETCH_ANALYTICS_PROCUREMENT_INSIGHT_REQUEST));

        businessAnalyticsService.getAnalyticsProcurementInsight(period,  startDate, endDate).then(
            (res) => {
                dispatch(success(businessAnalyticsType.FETCH_ANALYTICS_PROCUREMENT_INSIGHT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(businessAnalyticsType.FETCH_ANALYTICS_PROCUREMENT_INSIGHT_FAILURE, error?.message));
                }
            }
        );
    };
}


function procurementOrderGraph(period, startDate, endDate) {
    return (dispatch) => {
        dispatch(request(businessAnalyticsType.FETCH_ANALYTICS_PROCUREMENT_GRAPH_REQUEST));

        businessAnalyticsService.getAnalyticsProcurementGraph(period, startDate, endDate).then(
            (res) => {
                dispatch(success(businessAnalyticsType.FETCH_ANALYTICS_PROCUREMENT_GRAPH_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(businessAnalyticsType.FETCH_ANALYTICS_PROCUREMENT_GRAPH_FAILURE, error?.message));
                }
            }
        );
    };
}

function spendAnalytics(startDate, endDate, vendorId, category) {
    return (dispatch) => {
        dispatch(request(businessAnalyticsType.GET_SPEND_ANALYTICS_REQUEST));

        businessAnalyticsService.getSpendAnalytics(startDate, endDate, vendorId, category).then(
            (res) => {
                dispatch(success(businessAnalyticsType.GET_SPEND_ANALYTICS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(businessAnalyticsType.GET_SPEND_ANALYTICS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function spendAnalyticsCatAndSup(startDate, endDate) {
    return (dispatch) => {
        dispatch(request(businessAnalyticsType.FETCH_SPEND_ANALYSIS_CATEGORY_AND_SUPPLIER_REQUEST));

        businessAnalyticsService
            .getSpendAnalysisCategoryAndSupplier(startDate, endDate)
            .then(
                (res) => {
                    dispatch(
                        success(
                            businessAnalyticsType.FETCH_SPEND_ANALYSIS_CATEGORY_AND_SUPPLIER_SUCCESS,
                            res?.data
                        )
                    );
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                businessAnalyticsType.FETCH_SPEND_ANALYSIS_CATEGORY_AND_SUPPLIER_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function request(type) {
    return { type: type };
}
function success(type, data) {
    return { type: type, payload: data };
}
function failure(type, error) {
    return { type: type, payload: error ?? "" };
}
