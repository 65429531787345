export const restrictPastDate = () => {
    const dtToday = new Date();

    let month = String(dtToday.getMonth() + 1);
    let day = String(dtToday.getDate());
    let year = dtToday.getFullYear();

    if (Number(month) < 10) {
        month = "0" + month.toString();
    }

    if (Number(day) < 10) {
        day = "0" + day.toString();
    }

    const maxDate = year + "-" + month + "-" + day;

    return maxDate;
};
