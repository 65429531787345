export const currencies = [
    {
        label: "GBP (£)",
        value: "GBP",
    },
    {
        label: "USD ($)",
        value: "USD",
    },
    {
        label: "EUR (€)",
        value: "EUR",
    },
    {
        label: "NGN (₦)",
        value: "NGN",
    },
];
