import { onScroll } from "helpers";
import { useCallback, useEffect, useState } from "react";

const useInfiniteScroll = (
    pagination: { current: number | string; number_of_pages: number | string },
    increaseBy?: number,
    scrollContent?: HTMLElement
) => {
    const limit = 20;
    const dashboardContent =
        scrollContent ||
        document.getElementById("modalContent") ||
        document.getElementById("dashboardContent");
    const dropdownContent = document.getElementById("dropdownContent");
    const [updatedLimit, setUpdatedLimit] = useState<number>(increaseBy || limit);
    const [updatedPage, setUpdatedPage] = useState<number>(1);
    const [loadMore, setLoadMore] = useState<boolean>(false);

    const onPageScroll = useCallback(
        () =>
            onScroll(
                dropdownContent || dashboardContent,
                Number(pagination?.current),
                Number(pagination?.number_of_pages),
                () => {
                    setUpdatedLimit(() => updatedLimit + (increaseBy ? increaseBy : limit));
                    setUpdatedPage(() => updatedPage + 1);
                    setLoadMore(true);
                },
                loadMore
            ),
        [dashboardContent, pagination, updatedLimit, updatedPage, dropdownContent]
    );

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onPageScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onPageScroll);
        };
    }, [dashboardContent, dropdownContent, onPageScroll]);

    return {
        updatedLimit,
        updatedPage,
        setUpdatedPage,
        isLoadingMore: loadMore,
        setLoadMore,
    };
};

export default useInfiniteScroll;
