export const events = [
    {
        event: "Order received from customer",
        value: "receiveCustomerOrder",
        eventType: ["rfq", "order"],
    },
    {
        event: "RFQ passes evaluation criteria",
        value: "orderEvaluationPassed",
        eventType: ["rfq"],
    },
    {
        event: "Structured RFQ is created and sent to vendors",
        value: "rfqSent",
        eventType: ["rfq"],
    },
    {
        event: "Vendors/suppliers send back quotes",
        value: "quoteRecieved",
        eventType: ["quotes"],
    },
    {
        event: "Quote responses from vendors/suppliers are reviewed",
        value: "quoteReviewed",
        eventType: ["quotes"],
    },
    {
        event: "Preferred vendors/suppliers are selected",
        value: "vendorSelected",
        eventType: ["rfq"],
    },
    {
        event: "Purchase order for quote is created",
        value: "purchaseOrderCreated",
        eventType: ["po"],
    },
    {
        event: "Purchase request created",
        value: "purchaseRequestCreated",
        eventType: ["po"],
    },
    {
        event: "Purchase order is sent for approval",
        value: "purchaseOrderApproval",
        eventType: ["po"],
    },
    {
        event: "Purchase order is shared with vendors",
        value: "purchaseOrderShared",
        eventType: ["po"],
    },
    {
        event: "Pro forma invoice is created",
        value: "proFormaInvoiceCreated",
        eventType: ["po"],
    },
    {
        event: "Pro forma invoice is shared with customer",
        value: "proFormaInvoiceShared",
        eventType: ["po"],
    },
    {
        event: "Customer accepts shared quotation",
        value: "customerAcceptsQuotes",
        eventType: ["quotes"],
    },
    {
        event: "Customer makes payment",
        value: "customerMakesPayment",
        eventType: ["payment"],
    },
    {
        event: "Customer receives payment confirmation mail",
        value: "customerRecievesPaymentConfirmation",
        eventType: ["payment"],
    },
    {
        event: "Vendor's payment is made",
        value: "paymentToVendor",
        eventType: ["payment"],
    },
    {
        event: "Goods are assigned to all vendors",
        value: "transactionAssignedToVendors",
        eventType: ["shipment"],
    },
    {
        event: "Customer is notified of shipment status",
        value: "shipmentStatusUpdate",
        eventType: ["shipment"],
    },
    {
        event: "Shipment is delivered at the port",
        value: "shipmentStatusUpdate",
        eventType: ["shipment"],
    },
    {
        event: "Shipment is cleared at the port",
        value: "shipmentStatusUpdate",
        eventType: ["shipment"],
    },
    {
        event: "Shipment is out for last mile delivery",
        value: "shipmentStatusUpdate",
        eventType: ["shipment"],
    },
    {
        event: "Last mile delivery is complete",
        value: "shipmentStatusUpdate",
        eventType: ["shipment"],
    },
    {
        event: "Shipment is warehoused",
        value: "shipmentStatusUpdate",
        eventType: ["shipment"],
    },
];

export const actions = [
    {
        action: "Send email",
        value: "sendEmail",
        type: "all",
        category: "task",
        iconClass: "ri-mail-send-line",
    },
    {
        action: "Generate email",
        value: "generateEmail",
        type: "all",
        category: "task",
        iconClass: "ri-mail-settings-line",
    },
    {
        action: "Evaluate Orders",
        value: "evaluateOrder",
        type: "rfq",
        category: "task",
        iconClass: "ri-list-check-3",
    },
    {
        action: "Compare and evaluate received quote from vendors",
        value: "evaluateQuotes",
        type: "quotes",
        category: "task",
        iconClass: "ri-checkbox-multiple-line",
    },
    {
        action: "Create a structured RFQ and send to vendors",
        value: "createRFQ",
        category: "task",
        type: "rfq",
        iconClass: "ri-arrow-right-up-line",
    },
    {
        action: "Recommend best quotes/vendor for approval",
        value: "choosePreferredVendors",
        type: "quotes",
        category: "task",
        iconClass: "ri-send-plane-line",
    },
    {
        action: "Generate PO based on approved supplier/vendor quote",
        value: "createPO",
        type: "po",
        category: "task",
        iconClass: "ri-file-text-line",
    },
    {
        action: "Send PO request for approval",
        value: "sendPOForApproval",
        type: "po",
        category: "task",
        iconClass: "ri-arrow-right-up-line",
    },
    {
        action: "Approve PO request",
        value: "approvePO",
        type: "po",
        category: "approval",
        iconClass: "ri-check-double-line",
    },
    {
        action: "Check available budget for RFQ",
        value: "checkBudgetForRFQ",
        type: "rfq",
        category: "task",
        iconClass: "ri-money-dollar-circle-line",
    },
    {
        action: "Send approved PO to supplier/vendor",
        value: "sharePO",
        type: "po",
        category: "task",
        iconClass: "ri-arrow-right-up-line",
    },
    {
        action: "Check available budget for projects",
        value: "checkBudget",
        type: "quotes",
        category: "task",
        iconClass: "ri-money-dollar-circle-line",
    },
    {
        action: "Inspect delivered goods to match PO details",
        value: "inspectGoodsWithPO",
        type: "po",
        category: "task",
        iconClass: "ri-list-check-3",
    },
    {
        action: "Check inventory for item availability",
        value: "checkInventory",
        type: "inventory",
        category: "task",
        iconClass: "ri-survey-line",
    },
    {
        action: "Prepare quote for customer",
        value: "prepareCustomerQuote",
        type: "quotes",
        category: "task",
        iconClass: "ri-file-list-3-line",
    },
    {
        action: "Send quote to customer",
        value: "sendQuoteToCustomer",
        type: "quotes",
        category: "task",
        iconClass: "ri-arrow-right-up-line",
    },
    {
        action: "Approve Suggested quote",
        value: "approveSuggestedQuote",
        type: "quotes",
        category: "approval",
        iconClass: "ri-check-double-line",
    },
    {
        action: "Track items from suppliers/vendors",
        value: "trackShipment",
        type: "shipment",
        category: "task",
        iconClass: "ri-find-replace-line",
    },
    {
        action: "Pick items from stock and pack for delivery",
        value: "pickItemsForDelivery",
        type: "shipment",
        category: "task",
        iconClass: "ri-archive-2-line",
    },
    {
        action: "Check item quality",
        value: "checkItemQuality",
        type: "shipment",
        category: "task",
        iconClass: "ri-user-search-line",
    },
    {
        action: "Schedule item delivery to customer",
        value: "scheduleDeliveryToCustomer",
        type: "shipment",
        category: "task",
        iconClass: "ri-calendar-2-line",
    },
    {
        action: "Ship items to customer",
        value: "shipItemToCustomer",
        type: "shipment",
        category: "task",
        iconClass: "ri-truck-line",
    },
];

export const copilotActions = [
    {
        action: "Send email",
        value: "sendEmail",
        category: ["rfq", "po", "order", "shipment", "quotes"],
        type: "all",
        iconClass: "ri-arrow-right-up-line",
    },
    {
        action: "Generate email",
        value: "generateEmail",
        category: ["rfq", "po", "order", "shipment", "quotes"],
        type: "all",
        iconClass: "ri-mail-settings-line",
    },
    {
        action: "Evaluate Orders",
        value: "evaluateOrder",
        category: ["rfq", "order"],
        type: "rfq",
        iconClass: "ri-list-check-3",
    },
    {
        action: "Create a structured RFQ and send to vendors",
        value: "createRFQ",
        category: ["rfq", "order"],
        type: "rfq",
        iconClass: "ri-arrow-right-up-line",
    },
    {
        action: "Evaluate quotes from vendors based on the following rules",
        value: "evaluateQuotes",
        category: ["quotes"],
        type: "quotes",
        iconClass: "ri-list-check-3",
    },
    // {
    //     action: "Recommend and select preferred vendors/suppliers' quote",
    //     value: "choosePreferredVendors",
    //     category: ["quotes"]
    // },
    // { action: "Create Purchase order for quote", value: "createPO" },
    // { action: "Send purchase order for approval", value: "sendPOForApproval" },
    // { action: "Approve purchase order if the following criteria is met", value: "approvePO" },
    // { action: "Share approved purchase order with vendors", value: "sharePO" },
    // { action: "Create pro forma invoice for vendors", value: "createProFormaInvoice" },
    // { action: "Share pro forma invoice with customer", value: "shareProFormaInvoice" },
    // { action: "Send payment confirmation email", value: "sendPaymentConfirmation" },
    // { action: "Process payment to vendors", value: "processVendorPayment" },
    // { action: "Assign shipment to vendors", value: "assignShipmentToVendor" },
    // { action: "Track shipment", value: "trackShipment" },
    // { action: "Provide customer with shipment updates", value: "provideShipmentUpdates" },
    // { action: "Process shipment clearance document", value: "processShipmentClearanceDocument" },
    // {
    //     action: "Inspect shipment for final delivery or warehousing",
    //     value: "inspectShipmentForDelivery",
    // },
    // { action: "Confirm last mile shipment delivery", value: "confirmDelivery" },
    // { action: "End workflow", value: "endWorkflow" },
];
