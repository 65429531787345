import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../../../../../common/CustomTable";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import Button from "components/common/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { onScroll } from "helpers";
import Loader from "components/common/Loader";
import { truncate, uniqBy } from "lodash";
import CustomMobileTable from "components/common/CustomMobileTable";
// import { getEditPermissionList } from "helpers/getEditPermissionList";

type StockProps = {
    debouncedSearch?: string;
    warehouse?: string;
    stockCategory?: string;
};

const StockTransferList = ({ debouncedSearch, warehouse, stockCategory }: StockProps) => {
    const limit = 10;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [param] = useSearchParams();
    const currTab = param.get("currTab");
    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const [transferStockList, setTransferStockList] = useState<any>([]);
    const [page, setPage] = useState<number>(1);
    const [loadMore, setLoadMore] = useState<boolean>(false);
    const [paginate, setPaginate] = useState<{
        current: number | string;
        number_of_pages: number | string;
    }>({
        current: "",
        number_of_pages: "",
    });

    const { fetchingTransferStocks, fetchedTransferStocksSuccess } = useAppSelector(
        (state) => state.inventory
    );

    useEffect(() => {
        if (Boolean(fetchedTransferStocksSuccess)) {
            setTransferStockList((prev) =>
                page > 1
                    ? uniqBy([...prev, ...fetchedTransferStocksSuccess?.stock], "_id")
                    : [...fetchedTransferStocksSuccess?.stock]
            );
            setPaginate({
                current: fetchedTransferStocksSuccess?.pagination?.current,
                number_of_pages: fetchedTransferStocksSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedTransferStocksSuccess]);

    const scrollTable = document.getElementById("dashboardContent");

    const onTableScroll = useCallback(
        () =>
            onScroll(
                scrollTable,
                paginate?.current as number,
                paginate?.number_of_pages as number,
                () => {
                    setPage(() => page + 1);
                    setLoadMore(true);
                }
            ),
        [scrollTable, paginate]
    );
    useEffect(() => {
        scrollTable?.addEventListener("scroll", onTableScroll);
        return () => {
            scrollTable?.removeEventListener("scroll", onTableScroll);
        };
    }, [scrollTable, onTableScroll]);

    useEffect(() => {
        dispatch(
            inventoryActions.fetchTransferStocks(
                limit,
                page,
                debouncedSearch,
                warehouse,
                stockCategory
            )
        );
    }, [dispatch, page, debouncedSearch, warehouse, stockCategory]);

    useEffect(() => {
        if (debouncedSearch || warehouse || stockCategory) {
            setPage(1);
        }
    }, [debouncedSearch, warehouse, stockCategory]);

    const handleScroll = (e) => {
        if (e.target.scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const tableHeader = [
        {
            title: "Reference",
            widthClass: "w-[12%] !border-b !border-slate-200",
            itemClass: "",
        },
        {
            title: "From",
            widthClass: "w-[22%] !border-b !border-slate-200",
            itemClass: "",
        },
        {
            title: "To",
            widthClass: "w-[22%] !border-b !border-slate-200",
            itemClass: "",
        },
        {
            title: "Schedule Date",
            widthClass: "w-[22%] !border-b !border-slate-200",
            itemClass: "",
        },
    ];

    const tableBody =
        transferStockList?.length > 0
            ? transferStockList?.map((item, idx) => {
                  const transferFrom = item?.warehouseData?.find(
                      (data) => data?._id?.toLowerCase() === "warehousefrom"
                  );
                  const transferTo = item?.warehouseData?.find(
                      (data) => data?._id?.toLowerCase() === "warehouseto"
                  );

                  return [
                      {
                          content: (
                              <div className="py-4" key={idx}>
                                  <p className=""> {item?.reference}</p>
                              </div>
                          ),
                          widthClass: "!border-b !border-slate-200",
                          cellClickAction: () =>
                              navigate(
                                  `/dashboard/inventory/stock-control/transfer-stock/${item?._id}?currTab=${currTab}`
                              ),
                      },
                      {
                          content: (
                              <div className="py-4 flex items-center gap-3">
                                  <p className="">{transferFrom?.warehouseName}</p>
                              </div>
                          ),
                          widthClass: "!border-b !border-slate-200",
                      },
                      {
                          content: (
                              <div className="py-4">
                                  <p className="">{transferTo?.warehouseName} </p>
                              </div>
                          ),
                          widthClass: "!border-b !border-slate-200",
                      },
                      {
                          content: (
                              <div className="py-4 flex items-center gap-2">
                                  <p className="">
                                      {item?.movementDate
                                          ? moment(item?.movementDate).format(
                                                "MMM D, YYYY • h:mm A"
                                            )
                                          : ""}
                                  </p>
                              </div>
                          ),
                          widthClass: "!border-b !border-slate-200",
                      },
                  ];
              })
            : [];

    const mobileTableBody = transferStockList?.map((item) => {
        const transferFrom = item?.warehouseData?.find(
            (data) => data?._id?.toLowerCase() === "warehousefrom"
        );
        const transferTo = item?.warehouseData?.find(
            (data) => data?._id?.toLowerCase() === "warehouseto"
        );

        return {
            cellClickAction: () =>
                navigate(
                    `/dashboard/inventory/stock-control/transfer-stock/${item?._id}?currTab=${currTab}`
                ),
            topString: item?._id,
            // avatar: item?.inventory?.productImageDetails?.productAvatar ? (
            //     <div className="w-8 h-8">
            //         <img
            //             src={item?.inventory?.productImageDetails?.productAvatar}
            //             alt=""
            //             className="object-contain h-full w-full"
            //         />
            //     </div>
            // ) : (
            //     <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
            //         <i className="ri-box-2-fill text-lg text-slate-500"></i>
            //     </div>
            // ),
            topContent: (
                <div>
                    <div className="flex items-center space-x-1.5">
                        <p className="text-sm font-rocGroteskMedium">
                            {truncate(transferFrom?.warehouseName, { length: 18 })}
                        </p>
                        <i className="ri-arrow-right-line"></i>
                        <p className="text-sm font-rocGroteskMedium">
                            {truncate(transferTo?.warehouseName, { length: 18 })}{" "}
                        </p>
                    </div>
                </div>
            ),
            bottomContent: (
                <div className="flex items-center text-sm font-rocGroteskMedium space-x-2 mt-1">
                    <p className="text-slate-500 font-rocGroteskMedium text-xs">
                        {" "}
                        {item?.reference}
                    </p>
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-200 h-[21px] border rounded px-2 text-xs text-slate-500 flex items-center justify-center`}
                    >
                        <span>
                            {item?.movementDate
                                ? moment(item?.movementDate).format("MMM D, YYYY")
                                : ""}
                        </span>
                    </div>
                </div>
            ),
        };
    });

    return (
        <div>
            {fetchingTransferStocks && transferStockList?.length === 0 ? (
                <div className={`w-full min-h-[30vh]  flex items-center  justify-center`}>
                    <Loader color={"gm-50"} size={8} />
                </div>
            ) : (
                <>
                    {transferStockList?.length > 0 ? (
                        <div>
                            <div
                                className="overflow-x-scroll max-lg:hidden"
                                onScroll={handleScroll}
                            >
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isScrollable={true}
                                    isScrolled={isScrolled}
                                    isCellBordered={false}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs !bg-slate-50"
                                    headerContainerClass="!bg-slate-50"
                                    tableClass=" w-full !font-rocGroteskMedium cursor-pointer"
                                    tbodyClass="!text-sm !text-gm-50"
                                    bodyItemClass="hover:!bg-slate-50 "
                                />
                            </div>

                            <div className="hidden max-lg:block">
                                <CustomMobileTable data={mobileTableBody} />
                            </div>

                            {loadMore && fetchingTransferStocks && (
                                <div className="flex my-4 justify-center">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {paginate?.current === paginate?.number_of_pages && (
                                <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                    End of list
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            {debouncedSearch || stockCategory || warehouse ? (
                                <div className=" min-h-[30vh] my text-slate-500  flex items-center  justify-center text-sm font-rocGroteskMedium">
                                    <p className=""> "No data matches your search query" </p>
                                </div>
                            ) : (
                                <div className="w-full flex justify-center">
                                    <div
                                        className="flex mt-[85px] p-10 rounded-lg border border-slate-100 text-slate-500 gap-6
                    font-rocGroteskMedium max-w-[635px] shadow-tableNoDataShadow"
                                    >
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1705573225/arrow-left-right-fill_r7n5yi.svg"
                                            alt="no data "
                                            className=""
                                        />

                                        <div className="">
                                            <p className="text-black text-base  pb-[9px]">
                                                No stock transfers recorded yet.
                                            </p>
                                            <p className="text-sm text-slate-500 pb-6">
                                                Currently, there are no recorded stock details for
                                                your products. Ensure accurate tracking by adding
                                                stock records, each tagged with a unique batch
                                                number for easy identification and management.
                                            </p>
                                            <Button
                                                btnText="Transfer Stock"
                                                btnClassname="!py-[10px]  !text-black !text-[13px] !w-auto"
                                                onClick={() =>
                                                    navigate(
                                                        `/dashboard/inventory/stock-control/stock-transfer?currTab=${currTab}`
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default StockTransferList;
