import React from "react";

type StatusBadgeProps = {
    status?: string;
    hideDot?: boolean;
    className?: string;
};

const StatusBadge = ({status, hideDot, className}: StatusBadgeProps) => {
    return (
        <div
            className={`flex ${className} h-[29px] w-fit px-[15px] justify-center  ${
                status?.toLowerCase() === "completed" && "bg-g-25"
            } ${status?.toLowerCase() === "delayed" && "bg-light-yellow"} ${
                status?.toLowerCase() === "pending" && "bg-[#ffdd003b]"
            } ${status?.toLowerCase() === "active" && "bg-g-55"} ${
                status?.toLowerCase() === "failed" && "bg-r-25"
            } ${
                status?.toLowerCase() === "paused" && "bg-slate-100"
            } rounded-full space-x-1.5 items-center`}
        >
            {!hideDot && (
                <div
                    className={`h-2 w-2 rounded-full bg-[#FF8A00] ${
                        status?.toLowerCase() === "completed" && "bg-g-55"
                    } ${status?.toLowerCase() === "delayed" && "bg-[#FF8A00]"} ${
                        status?.toLowerCase() === "pending" && "bg-[#ffdd00]"
                    } ${status?.toLowerCase() === "active" && "bg-g-25"} ${
                        status?.toLowerCase() === "failed" && "bg-r-50"
                    } ${status?.toLowerCase() === "paused" && "bg-slate-100"}`}
                ></div>
            )}
            <p
                className={`text-slate-800 font-rocGroteskRegular text-[12px] capitalize ${
                    status?.toLowerCase() === "active" && "!text-white"
                }`}
            >
                {status}
            </p>
        </div>
    );
};

export default StatusBadge;
