import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { dashboardActions } from "redux/Ldb/actions";
import moment from "moment";

const useGetDashboardGWM = () => {
    const dispatch = useAppDispatch();
    const [gwmData, setGwmData] = useState<{ [key: string]: any }>([]);
    const [productId, setProductId] = useState<string>("");
    const [channel, setChannel] = useState<string>("");
    const [category, setCategory] = useState<string>("");
    const [dateRange, setDateRange] = useState<any>("yearly");
    const [endDate, setEndDate] = useState<string>("");
    const [startDate, setStartDate] = useState<any>("");

    const handleCategoryChange = (value) => {
        setCategory(value);
    };

    const handleChannelChange = (value) => {
        setChannel(value);
    };
    const handleProductChange = (value) => {
        setProductId(value);
    };
    const handleDateRangeChange = (period) => {
        setDateRange(period);
        setStartDate("");
        setEndDate("");
    };
    const handleStartAndEndDateChange = (customDate) => {
        setStartDate(customDate[0].toISOString());
        setEndDate(customDate[1].toISOString());
        setDateRange("");
    };

    const { fetchingDashboardGWM, fetchedDashboardGWMSuccess, fetchedDashboardGWMFailure } =
        useAppSelector((state) => state.dashboard);

    useEffect(() => {
        if (Boolean(fetchedDashboardGWMSuccess)) {
            return setGwmData(fetchedDashboardGWMSuccess);
        }
    }, [fetchedDashboardGWMSuccess]);

    useEffect(() => {
        dispatch(
            dashboardActions?.getDashboardGWM(
                category,
                productId,
                channel,
                startDate,
                endDate,
                dateRange
            )
        );
    }, [dispatch, category, productId, channel, startDate, endDate, dateRange]);

    return {
        data: gwmData,
        isFetching: fetchingDashboardGWM,
        error: fetchedDashboardGWMFailure,
        handleCategoryChange,
        handleProductChange,
        handleDateRangeChange,
        handleChannelChange,
        handleStartAndEndDateChange,
    };
};

export default useGetDashboardGWM;
