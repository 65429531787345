import React from "react";
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import VendorAvatar from "components/common/VendorAvatar";
import { useNavigate } from "react-router-dom";

interface OrderDetailsProps {
    onClickOne?: any;
    onClickTwo?: any;
    onApprove?: any;
    onReject?: any;
    title: string;
    channelImg: string;
    channel: string;
    orderDate: string;
    customerPO: string;
    deliveryDate: string;
    currency: string;
    subTotal: string;
    discount: string;
    total: string;
    customerName: string;
    haulage: string;
    thirdParty: string;
    email: string;
    phone: string;
    billAddress: string;
    orderStatus?: string;
}

const SalesOrderDetail = ({
    onClickOne,
    onClickTwo,
    title,
    channelImg,
    channel,
    orderDate,
    customerPO,
    deliveryDate,
    currency,
    subTotal,
    discount,
    total,
    customerName,
    haulage,
    email,
    thirdParty,
    phone,
    billAddress,
    orderStatus,
    onApprove,
    onReject,
}: OrderDetailsProps) => {
    const navigate = useNavigate();

    const tableHeader = [
        { title: "Product SKU/Name", widthClass: "w-[35%]" },
        { title: "Qty", widthClass: "w-[16.7%]" },
        { title: "Unit price", widthClass: "w-[16.6%]" },
        { title: "Total", widthClass: "w-[16.6%]" },
        { title: "Multipack", widthClass: "w-[16.6%]" },
    ];

    const tableBody = [1, 2, 3]?.map((order, idx) => [
        {
            content: (
                <div
                    className={`py-4 pr-3 flex space-x-2 items-center h-full border-slate-100 text-sm font-rocGroteskMedium`}
                >
                    <p className="text-[#142837] font-rocGroteskMedium pr-3 border-r border-[#E2E8F0] ">
                        K12890
                    </p>
                    <img
                        className="h-8 w-8"
                        src="https://s3-alpha-sig.figma.com/img/3c48/2bde/dec85fd33cc4c4390d7c1cd692ab0979?Expires=1716768000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DNHgK3~E2sgohXZ3TQJrvpkcL-D4FXD6i9ckfsgjVH-Ji~oq4kOHNnCrNKVrUcMu6llstZOw3GQKRXusJw8PXX7k9g3Kq30q6AUR51pfSLJXtIpTtn2LuLZrOlcON3v69neWGi9BbANv15ytEu-KcZBj4f5gHwe6UA6rPcg2fHeTgds2h2USxG1XfwIFOEtwDpp8f033OWxghyLoRkZCdlCQmmcXRXcvFHlHZFfw-ZfFHuR5G~fej7oQvsPH-T0uGWHPCCV92d4lG8dOcjddUhuy6nP-~0GojzQKuBYa0gB0qklyBYGKHjcnVCs0vTZO~McQA66od393HsGYXSEwNQ__"
                        alt="product-icon"
                    />
                    <p className="text-sm text-[#142837] font-rocGroteskMedium">Turkey</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-4 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    100 pcs
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-4 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    $25
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-4 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    $2500
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-5 pr-3  items-center h-full flex space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    6-Pack
                </div>
            ),
        },
    ]);

    return (
        <div className="p-4 border border-slate-100 rounded-[8px] bg-white">
            <div
                onClick={() => navigate(-1)}
                className="cursor-pointer text-[#3B4C58] space-x-3 w-fit flex items-center py-4"
            >
                <i className="ri-arrow-left-line text-sm "></i>
                <p className="text-sm underline  font-rocGroteskMedium">Back</p>
            </div>
            <div className="mb-6 flex justify-between items-center py-4 border-b border-slate-200">
                <p className="text-[22px] leading-[33px] text-gm-50 font-rocGroteskMedium">
                    {title}
                </p>
                {title?.toLowerCase() === "preview" && (
                    <div className="flex items-center space-x-3">
                        <Button
                            onClick={onClickOne}
                            btnClassname="!py-2.5 !border !bg-[#F4F5F7] !border-[#F4F5F7] !px-4 !w-fit !h-10"
                            btnText="Adjust sales order"
                        />
                        <Button
                            onClick={onClickTwo}
                            btnClassname="!py-2.5 !px-8 !w-fit !h-10"
                            btnText="Create sales order"
                        />
                    </div>
                )}
            </div>

            <div className=" flex space-x-3 ">
                <div className="w-[74%]  pb-4">
                    <div className="flex items-center justify-between mb-3">
                        <div>
                            <span className="block text-base font-rocGroteskMedium mb-1">
                                Order details
                            </span>
                        </div>
                        <div className="flex  space-x-3 items-center">
                            <p className="border-r pr-2 border-[#E2E8F0] text-sm font-rocGroteskMedium text-[#64748B]">
                                Status
                            </p>
                            {title?.toLowerCase() === "preview" ? (
                                <div className=" space-x-1  flex items-center px-[10px] py-[5px]  bg-[#F1F5F9] w-fit">
                                    <div className="h-2 w-2 bg-[#64748B] rounded-full"></div>
                                    <p>---</p>
                                </div>
                            ) : (
                                <div
                                    className={`flex items-center space-x-2 px-4 py-2.5 rounded-md ${
                                        orderStatus?.toLowerCase() === "fulfilled" &&
                                        "bg-light-purple-bg"
                                    }  ${
                                        orderStatus?.toLowerCase() === "pending" &&
                                        "bg-light-yellow-bg"
                                    } ${
                                        orderStatus?.toLowerCase() === "approved" &&
                                        "bg-[#16C6A426]"
                                    }  w-fit h-10`}
                                >
                                    <span
                                        className={`w-2 h-2 rounded-full ${
                                            orderStatus?.toLowerCase() === "fulfilled" &&
                                            "bg-[#6562E5]"
                                        }  ${
                                            orderStatus?.toLowerCase() === "pending" &&
                                            "bg-[#F59E0B]"
                                        } ${
                                            orderStatus?.toLowerCase() === "approved" &&
                                            "bg-carribean-green"
                                        }`}
                                    />
                                    <span className={`text-sm font-rocGroteskMedium text-gm-50`}>
                                        {orderStatus?.charAt(0).toUpperCase() +
                                            orderStatus?.slice(1)}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="grid grid-cols-5 border px-4 rounded items-center justify-between py-6">
                        <div className="">
                            <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                Channel
                            </span>
                            <div>
                                <img
                                    src={channelImg}
                                    className="max-h-[20px] max-w-[20px]"
                                    alt=""
                                />
                                <span className="block text-base font-rocGroteskMedium">
                                    {channel}
                                </span>
                            </div>
                        </div>

                        <div className="">
                            <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                Customer PO
                            </span>
                            <span className="block text-base font-rocGroteskMedium">
                                PO-{customerPO}
                            </span>
                        </div>

                        <div className="">
                            <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                Order date
                            </span>
                            <span className="block text-base font-rocGroteskMedium">
                                {orderDate}
                            </span>
                        </div>

                        <div className="">
                            <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                Delivery date
                            </span>
                            <span className="block text-base font-rocGroteskMedium">
                                {deliveryDate}
                            </span>
                        </div>
                        <div className="">
                            <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                Currency
                            </span>
                            <span className="block text-base font-rocGroteskMedium">
                                {currency}
                            </span>
                        </div>
                    </div>

                    <div className="border-b border-dashed border-[#E0E0E0] my-8" />
                    <div className="">
                        <div className="flex items-center justify-between mb-4">
                            <span className="block text-base font-rocGroteskMedium ">
                                Product details
                            </span>
                        </div>

                        <div className="mb-4">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isAllSelectable={false}
                                isCellBordered={false}
                                headerContainerClass="!bg-slate-50  !border-slate-100 "
                                bodyItemClass="hover:border-slate-100 !border-b hover:bg-slate-50 "
                                headerItemClass="font-rocGroteskMedium !text-slate-700"
                                tableClass="!w-fill-available"
                            />
                        </div>
                        <div>
                            <p className="text-sm mb-4 font-rocGroteskMedium text-[#64748B]">
                                Breakdown
                            </p>
                            <div className="grid grid-cols-3 bg-[#F8FAFC] p-4 rounded items-center justify-between">
                                <div className="">
                                    <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                        Sub total
                                    </span>
                                    <div>
                                        <img src="" className="max-h-[20px] max-w-[20px]" alt="" />
                                        <span className="block text-base font-rocGroteskMedium">
                                            {subTotal}
                                        </span>
                                    </div>
                                </div>

                                <div className="">
                                    <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                        Discount
                                    </span>
                                    <span className="block text-base font-rocGroteskMedium">
                                        {discount}
                                    </span>
                                </div>

                                <div className="">
                                    <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                        Total
                                    </span>
                                    <span className="block text-base font-rocGroteskMedium">
                                        {total}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-b border-dashed border-[#E0E0E0] my-8" />
                    <div>
                        <span className="block text-base font-rocGroteskMedium mb-2">
                            Tracking information
                        </span>
                    </div>
                    <div className="grid grid-cols-3 border px-4 rounded items-center justify-between py-6">
                        <div className="">
                            <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                Shipment ID
                            </span>
                            <div>
                                <span className="block text-base font-rocGroteskMedium">---</span>
                            </div>
                        </div>

                        <div className="">
                            <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                Tracking number
                            </span>
                            <span className="block text-base font-rocGroteskMedium">---</span>
                        </div>

                        <div className="">
                            <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                BoL
                            </span>
                            <span className="block text-base font-rocGroteskMedium">---</span>
                        </div>
                    </div>
                    <div className="border-b border-dashed border-[#E0E0E0] my-8" />
                    <div>
                        <h6 className="text-base font-rocGroteskMedium mb-6">
                            Approval & Processing
                        </h6>
                        <div className="grid grid-cols-2 gap-3">
                            <div className="flex justify-between items-center border border-[#E2E8F0] rounded py-6 px-4">
                                <div>
                                    <p className="text-[16px] leading-[23px] font-rocGroteskMedium text-gm-50">
                                        FIFO Assignment
                                    </p>
                                    <p className="text-sm text-[#64748B] font-rocGroteskMedium mt-0.5">
                                        Assigns lots on a FIFO (First In, First Out) basis.
                                    </p>
                                </div>
                                <i className="ri-check-double-line text-2xl text-[#16C6A4]"></i>
                            </div>
                            <div className="flex justify-between items-center border border-[#E2E8F0] rounded py-6 px-4">
                                <div>
                                    <p className="text-[16px] leading-[23px] font-rocGroteskMedium text-gm-50">
                                        Inventory sync
                                    </p>
                                    <p className="text-sm text-[#64748B] font-rocGroteskMedium mt-0.5">
                                        Updates inventory levels based on the order.
                                    </p>
                                </div>
                                <i className="ri-check-double-line text-2xl text-[#16C6A4]"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-[26%]  border border-[#E2E8F0] p-4">
                    {title.toLowerCase() !== "preview" && orderStatus === "pending" && (
                        <div className="space-y-3">
                            <Button
                                onClick={onApprove}
                                btnClassname="!py-2.5 !px-8 !h-10"
                                btnText="Approve quote"
                            />
                            <Button
                                onClick={onReject}
                                btnClassname="!py-2.5 !border !bg-[#F4F5F7] !border-[#F4F5F7] !px-4 !h-10"
                                btnText="Reject quote"
                            />
                            <div className="border-b border-dashed border-[#E0E0E0] my-5" />
                        </div>
                    )}
                    {title.toLowerCase() !== "preview" && (
                        <>
                            <div className="flex items-center mt-5 space-x-5">
                                <div className="border-r pr-8">
                                    <p className="text-sm text-[#64748B] font-rocGroteskMedium leading-[20.6px]">
                                        Profit margin
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-gm-50">25%</p>
                                </div>
                                <div>
                                    <p className="text-sm text-[#64748B] font-rocGroteskMedium leading-[20.6px]">
                                        Currency
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-gm-50">USD</p>
                                </div>
                            </div>
                            <div className="border-b border-dashed border-[#E0E0E0] my-5" />
                        </>
                    )}
                    {title.toLowerCase() === "preview" && (
                        <>
                            <div className="">
                                <span className="text-sm block font-rocGroteskMedium text-slate-500 mb-1.5">
                                    Currency
                                </span>
                                <span className="text-[16px] leading-[23px]  text-gm-50 font-rocGroteskMedium">
                                    USD
                                </span>
                            </div>
                            <div className="border-b border-dashed border-[#E0E0E0] my-5" />
                        </>
                    )}

                    <div className="">
                        <span className="text-base text-gm-50 block font-rocGroteskMedium mb-3">
                            Customer details
                        </span>
                        <div className="space-y-2">
                            <div className="flex items-center space-x-3">
                                <VendorAvatar name="avatar" size={24} />
                                <span className="text-base font-rocGroteskMedium">
                                    {customerName}
                                </span>
                            </div>

                            <div className="flex items-center space-x-3">
                                <i className="ri-mail-line text-slate-400 text-lg"></i>
                                <span className="text-base  text-gm-50 font-rocGroteskMedium">
                                    {email}
                                </span>
                            </div>

                            <div className="flex items-center space-x-3">
                                <i className="ri-phone-line text-slate-400 text-lg"></i>
                                <span className="text-base text-gm-50 font-rocGroteskMedium">
                                    {phone}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="border-b border-dashed border-[#E0E0E0] my-6" />

                    <div className="">
                        <span className="text-sm block font-rocGroteskMedium text-slate-500 mb-1.5">
                            Billing address
                        </span>
                        <span className="text-base  text-gm-50 font-rocGroteskMedium">
                            {billAddress}
                        </span>
                    </div>

                    <div className="border-b border-dashed border-[#E0E0E0] my-6" />
                    <div>
                        <span className="text-base text-gm-50 block font-rocGroteskMedium mb-3">
                            Fulfilment & Shipping
                        </span>
                        <div className="mb-2">
                            <span className="text-sm block font-rocGroteskMedium text-slate-500 mb-1">
                                3PL
                            </span>
                            <span className="text-[16px] leading-[23px]  text-gm-50 font-rocGroteskMedium">
                                {thirdParty}
                            </span>
                        </div>
                        <div className="">
                            <span className="text-sm block font-rocGroteskMedium text-slate-500 mb-1">
                                In-Country Haulauge
                            </span>
                            <span className="text-[16px] leading-[23px]  text-gm-50 font-rocGroteskMedium">
                                {haulage}
                            </span>
                        </div>
                    </div>
                    <div className="border-b border-dashed border-[#E0E0E0] my-6" />
                </div>
            </div>
        </div>
    );
};

export default SalesOrderDetail;
