import DashboardSidebar from "components/common/DashboardSidebar";
import DashboardNav from "components/common/Navbar/DashboardNav";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

type DashboardFrameProps = {
    children: React.ReactNode;
};

const DashboardFrame = ({ children }: DashboardFrameProps) => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

    const handleResize = () => {
        const windowWidth = window.innerWidth;

        setSidebarCollapsed(windowWidth <= 1024);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="bg-body-1 w-full min-h-screen overflow-x-hidden">
            <DashboardNav
                setSidebarCollapsed={setSidebarCollapsed}
            />
            <div className={`flex w-full h-[calc(100vh-84px)] max-lg:h-[calc(100vh-129px)]`}>
                {/* <div className={`${!sidebarCollapsed && "w-[22%]"}`}>
                    <DashboardSidebar
                        setSidebarCollapsed={setSidebarCollapsed}
                        sidebarCollapsed={sidebarCollapsed}
                    />
                </div> */}

                <div
                    id="dashboardContent"
                    className={`lg:px-[47px] px-5 pt-[17px] pb-6 w-full overflow-y-scroll bg-white`}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default DashboardFrame;
