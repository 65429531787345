import React, { useEffect, useRef, useState } from "react";

type CustomTooltipProps = {
    title: string,
    text: string,
    children: React.ReactNode,
};

const CustomTooltip = ({ title, text, children }: CustomTooltipProps) => {
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current) {
                if (!ref.current.contains(event.target as Node)) {
                    setOpen(false);
                }
            }

            if (!event.target) setOpen(false);
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, open]);

    return (
        <div className="relative ">
            {open && (
                <div
                    ref={ref}
                    className="z-20 pointer-events-none absolute bottom-[30px] w-[450px] text-[10px] left-[20%] transform -translate-x-1/2 bg-gm-50 p-4 text-white transition before:absolute before:left-1/2 before:top-full before:translate-x-1/2 before:rotate-180 before:border-4 before:border-transparent before:border-b-gm-50 rounded before:content-['']"
                >
                    <div className="flex items-center justify-between mb-4">
                        <div className="flex items-center gap-3">
                            <i className="ri-question-line text-xl"></i>
                            <span className="text-white text-base font-rocGroteskMedium">
                                {title}
                            </span>
                        </div>
                        <i
                            onClick={() => {
                                setOpen(false);
                            }}
                            className="ri-close-fill text-white text-lg"
                        ></i>
                    </div>
                    <p className="text-sm text-white font-rocGroteskRegular">{text}</p>
                </div>
            )}
            <div
                onClick={() => {
                    setOpen(true);
                }}
                className="cursor-pointer"
            >
                {children}
            </div>
        </div>
    );
};

export default CustomTooltip;
