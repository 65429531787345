import Button from "components/common/Button";
import PageFrame from "components/layout/PageFrame";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { truncate } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";

const StockTransferDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [transferStockDetails, setTransferStockDetails] = useState<{ [key: string]: any }>({});

    const { fetchingSingleTransferStock, fetchedSingleTransferStockSuccess } = useAppSelector(
        (state) => state.inventory
    );

    useEffect(() => {
        if (Boolean(fetchedSingleTransferStockSuccess)) {
            setTransferStockDetails(fetchedSingleTransferStockSuccess?.stockMovement);
        }
    }, [fetchedSingleTransferStockSuccess]);
    useEffect(() => {
        if (id) {
            dispatch(inventoryActions?.fetchSingleTransferStock(id));
        }
    }, [id]);

    return (
        <PageFrame
            isLoading={fetchingSingleTransferStock}
            containerClassName={"h-[calc(100vh-14px)]"}
        >
            <div className="">
                <div className="bg-white rounded-lg border border-slate-100">
                    <div className="">
                        <div className="flex items-center justify-between px-8 py-4 border-b border-n-20 top-[-10px] md:sticky  z-[50] bg-white">
                            <p className="text-base font-rocGroteskMedium text-gun-metal">
                                Stock transfer
                            </p>
                            <div className="">
                                <Button
                                    btnText="Close"
                                    btnClassname="!px-4 !py-2 !w-fit"
                                    onClick={() => navigate(-1)}
                                />
                            </div>
                        </div>

                        <div className="w-full pt-6 pb-8 px-8">
                            <div className="w-[70%]">
                                <div className="w-full mb-8">
                                    <p className="text-sm font-rocGroteskMedium mb-2">
                                        Stock transfer number <span className="text-r-50">*</span>
                                    </p>
                                    <p className="text-2xl font-rocGroteskMedium text-slate-700">
                                        {transferStockDetails?.reference}
                                    </p>
                                </div>
                                <div className="w-full pb-6 border-b border-slate-200">
                                    <p className="text-sm font-rocGroteskMedium text-gun-metal">
                                        Contact Person From
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        {transferStockDetails?.warehouse?.contactPersonFrom?.email}
                                    </p>
                                </div>
                                <div className="w-full py-6 border-b border-slate-200">
                                    <p className="text-sm font-rocGroteskMedium text-gun-metal">
                                        Contact Person To
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        {transferStockDetails?.warehouse?.contactPersonTo?.email}
                                    </p>
                                </div>
                                <div className="w-full py-6 border-b border-slate-200">
                                    <p className="text-sm font-rocGroteskMedium text-gun-metal">
                                        Source warehouse
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        {
                                            transferStockDetails?.warehouse?.warehouseFrom
                                                ?.warehouseName
                                        }
                                    </p>
                                </div>
                                <div className="w-full py-6 border-b border-slate-200">
                                    <p className="text-sm font-rocGroteskMedium text-gun-metal">
                                        Destination warehouse
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        {
                                            transferStockDetails?.warehouse?.warehouseTo
                                                ?.warehouseName
                                        }
                                    </p>
                                </div>

                                <div className="mt-8">
                                    <p className="text-base font-rocGroteskMedium text-gun-metal">
                                        Product
                                    </p>
                                    <div className="max-w-[393px] flex gap-4 items-center mt-6">
                                        {transferStockDetails?.inventory?.productImageDetails
                                            ?.productAvatar && (
                                            <div className="w-[64px] h-[64px] ">
                                                <img
                                                    src={
                                                        transferStockDetails?.inventory
                                                            ?.productImageDetails?.productAvatar
                                                    }
                                                    alt=""
                                                    className="rounded"
                                                />
                                            </div>
                                        )}
                                        <div className="font-rocGroteskMedium">
                                            <p className="text-base  text-slate-700">
                                                {transferStockDetails?.inventory?.productName}
                                            </p>
                                            <p className="text-sm text-slate-500">
                                                {truncate(
                                                    transferStockDetails?.inventory
                                                        ?.productDescription,
                                                    { length: 50 }
                                                )}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="w-full py-4 border-b border-slate-200 flex justify-between">
                                        <p className="text-sm font-rocGroteskMedium text-slate-500">
                                            Date schedule
                                        </p>
                                        <p className="text-sm font-rocGroteskMedium text-gm-50">
                                            {moment(transferStockDetails?.movementDate).format(
                                                "MMM DD, YYYY"
                                            )}
                                        </p>
                                    </div>
                                    <div className="w-full py-4 border-b border-slate-200 flex justify-between">
                                        <p className="text-sm font-rocGroteskMedium text-slate-500">
                                            Qty
                                        </p>
                                        <p className="text-sm font-rocGroteskMedium text-gm-50">
                                            {transferStockDetails?.quantity}
                                        </p>
                                    </div>
                                    <div className="w-full py-4 border-b border-slate-200 flex justify-between">
                                        <p className="text-sm font-rocGroteskMedium text-slate-500">
                                            Cost price
                                        </p>
                                        <p className="text-sm font-rocGroteskMedium text-gm-50">
                                            {getCurrencyFromCurrencyCode(
                                                transferStockDetails?.movementCost?.currency
                                            )}
                                            {formatMoney()?.format(
                                                transferStockDetails?.movementCost?.amount
                                            )}
                                        </p>
                                    </div>
                                    <div className="w-full py-4 border-b border-slate-200 flex justify-between">
                                        <p className="text-sm font-rocGroteskMedium text-slate-500">
                                            Total cost
                                        </p>
                                        <p className="text-sm font-rocGroteskMedium text-gm-50">
                                            {`${getCurrencyFromCurrencyCode(
                                                transferStockDetails?.movementCost?.currency
                                            )} ${
                                                transferStockDetails?.quantity *
                                                transferStockDetails?.movementCost?.amount
                                            }`}
                                        </p>
                                    </div>
                                </div>

                                {/* <div className="w-full pb-6 border-b border-slate-200">
                                <p className="text-sm font-rocGroteskMedium text-gun-metal">
                                    Assigned to
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-slate-500">
                                    {transferStockDetails?.contact?.fullName ||
                                        transferStockDetails?.contact?.email}
                                </p>
                            </div> */}

                                {transferStockDetails?.notes && (
                                    <div className="w-full  font-rocGroteskMedium mt-6">
                                        <p className="text-sm">Additional Note</p>
                                        <p className="text-sm text-slate-500">
                                            {transferStockDetails?.notes}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default StockTransferDetails;
