import React, { useEffect, useState } from "react";
import { integrationActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";

const useGetAssetAccounts = () => {
    const dispatch = useAppDispatch();
    const [accounts, setAccounts] = useState<{ [key: string]: any }[]>([]);

    const {
        fetchingQuickbooksAssetAccounts,
        fetchedQuickbooksAssetAccountsSuccess,
        fetchedQuickbooksAssetAccountsFailure,
    } = useAppSelector((state) => state.integration);

    useEffect(() => {
        dispatch(integrationActions?.getQuickbooksAssetAccounts());
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedQuickbooksAssetAccountsSuccess)) {
           
            setAccounts(fetchedQuickbooksAssetAccountsSuccess?.accounts);
        }
    }, [fetchedQuickbooksAssetAccountsSuccess]);

    return {
        data: accounts,
        isFetching: fetchingQuickbooksAssetAccounts,
        error: fetchedQuickbooksAssetAccountsFailure,
    };
};

export default useGetAssetAccounts;
