import React from "react";
import VendorAvatar from "./VendorAvatar";
import { truncate } from "lodash";
import moment from "moment";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import { convertDaysToWeeks } from "helpers/convertDaysToWeeks";

interface Props {
    index: number;
    productName: string;
    stockOutDescription: string;
    risk: string;
    createdAt: string;
    status: string;
    stockOutQty: number;
    potentialRevenue: number;
    forcastedStockDuration: number;
    stockOutDate: string;
    currency: string;
    uom: string;
    isStockout?: boolean;
    incidentType?: string;
}
const AlertCard = ({
    index,
    productName,
    stockOutDescription,
    currency,
    stockOutDate,
    risk,
    createdAt,
    status,
    stockOutQty,
    potentialRevenue,
    uom,
    isStockout,
    forcastedStockDuration,
    incidentType,
}: Props) => {
    return (
        <div
            className={`border mb-6 border-[#E2E8F080] rounded-md shadow-[0px_8px_8px_-4px_#10182808]`}
        >
            <div className="">
                <div className="px-6 pt-4  mb-4">
                    <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center space-x-3">
                            <div
                                className={`w-6 h-6 rounded-[2px]  ${
                                    isStockout ? "bg-[#FEF2F2]" : "bg-[#DCFCE7]"
                                } flex items-center justify-center`}
                            >
                                <i
                                    className={`ri-alarm-warning-fill  ${
                                        isStockout ? "text-[#FF0000]" : "text-[#16A34A]"
                                    } text-[13px]`}
                                ></i>
                            </div>
                            <div>
                                <span className="block text-g-75 text-sm font-rocGroteskMedium mb-[2px]">
                                    #{index} Alert -{" "}
                                    {incidentType?.toLowerCase() === "overstocking"
                                        ? "Overstocking"
                                        : "Stockout"}{" "}
                                    for{" "}
                                    {productName?.charAt(0).toUpperCase() + productName?.slice(1)}
                                </span>
                            </div>
                        </div>

                        <i className="ri-arrow-right-s-line  text-2xl"></i>
                    </div>
                    <p className="block text-slate-500 text-sm font-rocGroteskMedium mb-2">
                        {truncate(stockOutDescription, { length: 120 })}
                    </p>

                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-1.5 bg-slate-50 border border-slate-200 rounded py-1 px-3 h-[28px] w-fit">
                            {risk === "high" && (
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1716189612/bar-chart-2-fill_2_h0x1km.svg"
                                    alt="risk-icon"
                                />
                            )}
                            {risk === "medium" && (
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1716189612/bar-chart-2-fill_mlbycq.svg"
                                    alt="risk-icon"
                                />
                            )}
                            {risk === "low" && (
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1716189612/bar-chart-2-fill_1_kxmng6.svg"
                                    alt="risk-icon"
                                />
                            )}
                            <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                {risk?.charAt(0).toUpperCase() + risk?.slice(1)} risk
                            </span>
                        </div>
                        <div className="flex items-center  w-fit">
                            <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                Stockout date {moment(stockOutDate).format("DD MMMM YYYY")}
                            </span>
                        </div>
                        <div className="flex items-center space-x-1.5 w-fit">
                            <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                Reported {moment(createdAt).fromNow(true)} ago
                            </span>
                        </div>

                        <div className="flex items-center space-x-1.5 w-fit">
                            <i className="ri-check-fill text-base text-slate-500 "></i>
                            <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                {status}
                            </span>
                        </div>

                        {/* <div className="flex items-center space-x-1.5 w-fit">
                            <VendorAvatar
                                name="Augustine Asiuwhu"
                                imageSrc=""
                                size={20}
                                textClassname="!text-[8px]"
                            />
                            <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                Augustine Asiuwhu
                            </span>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="border-t border-b py-3 px-5 border-slate-200">
                <div className="flex items-center space-x-1.5 w-fit">
                    <i className="ri-time-line text-base text-slate-500 "></i>
                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                        Forecasted stock duration: {convertDaysToWeeks(forcastedStockDuration)}
                    </span>
                </div>
            </div>
            <div className=" flex items-center justify-between bg-slate-50 ">
                <div className="text-xs text-[#042821] font-rocGroteskMedium w-1/2 pl-6 py-3">
                    <span className="block">Forecasted stock needed</span>
                    <span className="block text-base">
                        {Math.round(stockOutQty)} {uom}
                    </span>
                </div>
                <div className="text-xs text-[#042821] font-rocGroteskMedium border-l w-1/2 pl-6 py-3 border-slate-200">
                    <span className="block">Potential revenue loss</span>
                    <span className="block text-base">
                        {getCurrencyFromCurrencyCode(currency)}
                        {formatMoney().format(potentialRevenue)}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default AlertCard;
