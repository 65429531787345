import Button from "components/common/Button";
import EmptyStateComp from "components/common/EmptyStateComp";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { displayFileIcon, formatMoney, onModalScroll } from "helpers";
import { calculateRemainingTime } from "helpers/calculateCommentDate";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import useGetProductList from "hooks/inventoryHooks/useGetProductList";
import useGetProductsAnalytics from "hooks/inventoryHooks/useGetProductsAnalytics";
import { debounce, truncate } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { COLORS } from "variables/chartColors";
import ReactECharts from "echarts-for-react";
import useGetAllWarehouses from "hooks/useGetAllWarehouses";
import millify from "millify";
import ModalContainer from "components/common/ModalContainer";
import AlertTabDetails from "../Alerts/AlertTabDetails";
import SelectInput from "components/common/InputField/SelectInput";
import AddressInput from "components/common/InputField/AddressInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { oceanCarriers } from "variables/oceanCarriers";
import useRecentInventoryIncident from "hooks/inventoryHooks/useRecentInventoryInceident";
import Incidents from "./ProductDetail/Tabs/Incidents";
import useGetSingleInventoryIncidents from "hooks/inventoryHooks/useGetSingleInventoryIncidents";
import useGetReplishmentStockList from "hooks/inventoryHooks/useGetReplishmentStock";
import ScrollableModal from "components/views/Synth2.0/Inventory/ModalInv";
import CustomToast from "components/common/CustomToast";
import toast from "react-hot-toast";
import { shipmentActions } from "redux/Ldb/actions/shipmentActions";
import { firebaseService } from "services/firebaseService";
import CustomMobileTable from "components/common/CustomMobileTable";

const ProductList = () => {
    const limit = 15;
    const navigate = useNavigate();
    const [dateRange, setDateRange] = useState("all");
    const [openReStockModal, setOpenReStockModal] = useState<boolean>(false);
    const [openDocument, setOpenDocument] = useState<boolean>(false);
    const [openShipment, setOpenShipment] = useState<boolean>(false);
    const tabs = ["Timeline", "Comments", "Sources", "Previous alerts"];
    const dispatch = useAppDispatch();
    const [productList, setProductList] = useState<any>([]);
    const [productData, setProductData] = useState<any>([]);
    const [mode, setMode] = useState<string>("");
    const [getProductId, setGetProductId] = useState<string>("");
    const [showAddShipmentReminder, setShowAddShipmentReminder] = useState<boolean>(false);
    const [uploadingAttachment, setUploadingAttachment] = useState<boolean>(false);
    const [deletingImage, setDeletingImage] = useState<boolean>(false);
    const [replacingAttachment, setReplacingAttachment] = useState<boolean>(false);
    const [poShipment, setPoShipment] = useState([]);
    const [shipmentId, setShipmentId] = useState<string>("");
    const [showSuccessShipmentAdded, setshowSuccessShipmentAdded] = useState<boolean>(false);
    const [attachments, setAttachments] = useState<any>([]);
    const [response, setResponse] = useState<any>([]);
    const [address, setAddress] = useState<{ [key: string]: any }>({});
    const [page, setPage] = useState<number>(1);
    const [poShipmentPagination, setPoShipmentPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const {
        data: recentIncident,
        isFetching: isFetchingRecentIncident,
        handleIncidentId,
    } = useRecentInventoryIncident();

    const { data: incidentData, isFetching: isFetchingIncidentData } =
        useGetSingleInventoryIncidents(recentIncident?.incident?._id);
    const {
        data: replishmentStock,
        isFetching: isFetchingReplishment,
        handleIncidentId: handleRecentIncidentId,
        reFetchData,
    } = useGetReplishmentStockList();
    const [country, setCountry] = useState<string>("");
    const {
        createShipment,
        createdShipmentSuccess,
        fetchPoShipmentSuccess,
        updateShipment,
        updatedShipmentSuccess,
        fetchingPoShipment,
    } = useAppSelector((state) => state.shipment);
    const [params] = useSearchParams();
    const currTab = params.get("currTab");
    // const {
    //     warehouseValue: warehouseDataValue,
    //     searchTwo,
    //     handleDebouncedChangeTwo,
    //     handleSearchTwo,
    //     searchResult,
    //     handleFilterChange: filterChange,
    //     handleClearFilter: clearFilter,
    // } = useGetAllWarehouses();

    const [pieChartModal, setPieChartModal] = useState<boolean>(false);

    useEffect(() => {
        if (recentIncident?.incident?._id) {
            handleRecentIncidentId(recentIncident?.incident?._id);
        }
    }, [recentIncident]);

    const {
        data: allWarehouses,
        warehouseValue,
        search: warehouseSearch,
        handleDebouncedChange: debounceChange,
        handleSearch: handleWarehouseSearch,
        handleFilterChange: handleWarehouseFilterChange,
        handleClearFilter: clearWarehouseFilter,
    } = useGetAllWarehouses();

    const {
        data,
        handleDebouncedChange,
        handleSearch,
        isFetching: productListLoading,
        search,
        searchTwo,
        handleSearchTwo,
        handleDebouncedChangeTwo,
        handleFilterChangeTwo,
        handleClearFilterTwo,
        selectedProduct,
        productSearchList,
        handleFilterChange,
        handleClearFilter,
        productCategory,
        pagination,
        isLoadingMore,
    } = useGetProductList();

    const [atBottom, setAtBottom] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);

    const checkScrollPosition = () => {
        if (modalRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = modalRef.current;
            setAtBottom(scrollTop + clientHeight >= scrollHeight);
        }
    };

    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.addEventListener("scroll", checkScrollPosition);
            return () => modalRef.current?.removeEventListener("scroll", checkScrollPosition);
        }
    }, []);

    const {
        isFetching: analyticsLoading,
        data: productAnalytics,
        salesBreakdown,
    } = useGetProductsAnalytics(warehouseValue?.value, selectedProduct?.value, dateRange);

    const [shipmentDetails, setShipmentDetails] = useState<{ [key: string]: any }>({
        billOfLading: "",
        trackingLink: "",
        carrier: "",
        billNumber: "",
        destination: "",
        additionalInfo: "",
    });

    const [debouncedFilter, setDebouncedFilter] = useState({
        search: "",
        shipmentSearch: "",
    });

    const [warehouseList, setWarehouseList] = useState([]);
    const { fetchedWarehouseListSuccess } = useAppSelector((state) => state.inventory);
    const productDetailsTableHeader = [
        { title: "Product name", widthClass: "w-[30.6%]" },
        { title: "SKU", widthClass: "w-[15.2%]" },
        { title: "Qty", widthClass: "w-[15.2%]" },
        { title: "", widthClass: "w-[6%]" },
    ];

    const handleDeleteRow = (index) => {
        const updatedData = productData.filter((_, idx) => idx !== index);
        setProductData(updatedData);
    };

    const handleAddRow = () => {
        const newRow = {
            name: "",
            inventoryCategory: "",
            qty: 0,
            unitOfMeasurement: "",
            amount: 0,
        };

        setProductData([...productData, newRow]);
    };

    useEffect(() => {
        const filteredData = recentIncident?.purchaseOrder?.itemsOrServices?.filter(
            (item) => item?.inventoryId
        );
        setProductData(filteredData || []);
    }, [recentIncident?.purchaseOrder?.itemsOrServices]);

    const updatedData = [...productData];

    useEffect(() => {
        if (address?.origin) {
            const countryObject = address?.origin?.details?.[0]?.address_components?.find((obj) =>
                obj.types.includes("country")
            );
            if (countryObject) {
                setCountry(countryObject.long_name);
            }
        }
    }, [address?.origin]);

    useEffect(() => {
        if (Boolean(createdShipmentSuccess)) {
            setResponse(createdShipmentSuccess);
            setShowAddShipmentReminder(false);
            dispatch(shipmentActions.getPoShipment(replishmentStock?.purchaseOrder?._id));
            setshowSuccessShipmentAdded(true);
            reFetchData();
        }
    }, [createdShipmentSuccess, dispatch]);

    const handleSubmit = () => {
        const body: { [key: string]: any } = {
            channel: "web",
            origin: {
                address: address?.origin?.address,
                lng: address?.origin?.lng,
                lat: address?.origin?.lat,
                details: [address?.origin?.address],
                country: country,
            },

            destinationId: shipmentDetails?.destination,
            purchaseOrderId: replishmentStock?.purchaseOrder?._id,
            shipmentMode: mode,
            productDetails: productData?.map((product) => ({
                category: product?.inventory?.category ?? product?.name,
                inventoryId: product?.inventoryId,
                quantity: Number(product?.qty),
            })),
        };
        if (shipmentDetails?.additionalInfo) {
            body.additionalNotes = shipmentDetails?.additionalInfo;
        }
        if (mode.toLowerCase() === "land") {
            body.trackingLink = shipmentDetails?.trackingLink;
        }
        if (mode.toLowerCase() === "air") {
            body.airWayBillNumber = shipmentDetails?.billNumber;
            body.carrier = shipmentDetails?.carrier;
        }
        if (mode.toLowerCase() === "ocean") {
            if (shipmentDetails?.trackingLink) {
                body.trackingLink = shipmentDetails?.trackingLink;
            }
            body.billOfLading = shipmentDetails?.billOfLading;
        }
        dispatch(shipmentActions.createShipment(body));
    };

    useEffect(() => {
        if (recentIncident?.purchaseOrder?._id) {
            dispatch(
                shipmentActions.getPoShipment(
                    recentIncident?.purchaseOrder?._id,
                    limit,
                    page,
                    debouncedFilter.shipmentSearch
                )
            );
        }
    }, [dispatch, limit, page, recentIncident?.purchaseOrder?._id, debouncedFilter.shipmentSearch]);

    useEffect(() => {
        if (Boolean(fetchPoShipmentSuccess)) {
            setPoShipment(fetchPoShipmentSuccess?.data);
            setPoShipmentPagination({
                current: fetchPoShipmentSuccess?.pagination.current,
                number_of_pages: fetchPoShipmentSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchPoShipmentSuccess]);

    const handleReplaceUpload = (indexToReplace: number, newFileName: string, newFile: File) => {
        setReplacingAttachment(true);
        firebaseService.uploadFile(
            `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/shipment-doc/${newFileName}`,
            newFile,
            (url) => {
                setAttachments((prevAttachments) => {
                    const updatedAttachments = [...prevAttachments];
                    updatedAttachments[indexToReplace] = {
                        filename: newFileName,
                        path: url,
                        documentType: "pdf",
                    };
                    return updatedAttachments;
                });

                setReplacingAttachment(false);
            }
        );
    };

    const handleUpload = (fileName: string, file: File) => {
        if (fileName && file) {
            setUploadingAttachment(true);
            firebaseService.uploadFile(
                `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/shipment-doc/${fileName}`,
                file,
                (url) => {
                    setAttachments((prev) => [
                        ...prev,
                        { path: url, filename: fileName, documentType: "pdf" },
                    ]);

                    setUploadingAttachment(false);
                }
            );
        }
    };

    const handleRemoveUpload = (idx) => {
        const newArr = [...attachments];

        const imagePath = decodeURIComponent(
            attachments[idx]?.path?.split("/o/")?.[1]?.split("?alt")?.[0]
        );

        setDeletingImage(true);
        firebaseService.removeFile(imagePath, () => {
            newArr?.splice(idx, 1);
            setAttachments(newArr);
            setDeletingImage(false);
        });
    };

    const handleUpdateShipment = () => {
        const body = {
            attachments,
        };
        dispatch(shipmentActions.updateShipment(body, shipmentId));
    };

    useEffect(() => {
        if (Boolean(updatedShipmentSuccess)) {
            dispatch(shipmentActions.resetUpdateShipmentSuccess());
            dispatch(shipmentActions.getPoShipment(replishmentStock?.purchaseOrder?._id, 20));
            reFetchData();
            setOpenDocument(false);
            setOpenReStockModal(true);
        }
    }, [updatedShipmentSuccess, dispatch]);

    const handleTableChange = (name, value, idx) => {
        const selectedProduct = productData?.find((product) => product.name === value);

        if (selectedProduct && !updatedData.some((product) => product.name === value)) {
            updatedData[idx] = {
                ...updatedData[idx],
                ...selectedProduct,
                qty: selectedProduct?.qty || 0,
            };

            setProductData(updatedData);
        } else {
            toast.custom((t) => (
                <CustomToast t={t} message={"Product selected already exists"} type="error" />
            ));
        }
    };

    const handleQuantityChange = (e, idx) => {
        const { value } = e.target;
        const updatedData = [...productData];

        updatedData[idx] = {
            ...updatedData[idx],
            qty: value,
        };

        setProductData(updatedData);
    };

    const productDetailsTableBody = productData?.map((data: any, idx) => [
        {
            content: (
                <div
                    key={idx}
                    className={`-ml-3 pr-3  flex font-rocGroteskMedium items-center text-sm text-slate-500 `}
                >
                    <SelectInput
                        name="category"
                        value={data.name}
                        placeholder={"Enter Product"}
                        handleChange={(name, value) => handleTableChange(name, value, idx)}
                        className={"w-full !py-0 border-none"}
                        isRequired={true}
                        clearValue
                        dropdownOptions={replishmentStock?.purchaseOrder?.itemsOrServices?.map(
                            (res) => ({
                                label: (
                                    <div className={`font-rocGroteskMedium -mt-2 flex space-x-1`}>
                                        <VendorAvatar
                                            imageSrc={
                                                res?.inventory?.productImageDetails?.productAvatar
                                            }
                                            size={24}
                                            name={res?.name}
                                        />

                                        <p className="text-slate-500 ">{res?.name}</p>
                                    </div>
                                ),
                                value: res?.name,
                            })
                        )}
                    />
                </div>
            ),
        },
        {
            content: (
                <div
                    className={` pr-4 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-900 `}
                >
                    <p className="text-slate-700">{data?.inventory?.sku || "N/A"}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`w-full -ml-3  border-r pr-1  font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <TextInput
                        value={data?.qty}
                        name={"qty"}
                        type={"number"}
                        inputContainerClassname="!border-none"
                        inputPlaceholder="Enter qty"
                        onChange={(e) => handleQuantityChange(e, idx)}
                        required={true}
                    />
                </div>
            ),
        },
        {
            content: (
                <i
                    className={`ri-delete-bin-line text-sm text-[#64748B] ${
                        productData[0] && "opacity-50"
                    } `}
                    onClick={() => productData?.length > 1 && handleDeleteRow(idx)}
                ></i>
            ),
        },
    ]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setShipmentDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouseList(20, 1, debouncedFilter.search));
    }, [dispatch, debouncedFilter.search]);

    useEffect(() => {
        if (Boolean(fetchedWarehouseListSuccess)) {
            setWarehouseList(fetchedWarehouseListSuccess.warehouses?.data);
        }
    }, [fetchedWarehouseListSuccess]);

    const tableHeader = [
        { title: "Product name", widthClass: "w-[23.8%]" },
        { title: "Sales channels", widthClass: "w-[12.4%]" },
        { title: "Categories", widthClass: "w-[10.8%]" },
        { title: "Current stock", widthClass: "w-[11.6%]" },
        { title: "On order", widthClass: "w-[7.6%]" },
        { title: "Ongoing sales", widthClass: "w-[10.8%]" },
        { title: "Forecasted needs", widthClass: "w-[12.8%]" },
        { title: "Sells out in", widthClass: "w-[10.1%]" },
        { title: "Action", widthClass: "w-[10.1%]" },
    ];

    const tableBody = data?.map((product, idx) => {
        const givenDate = moment(product?.stockoutDate);
        const today = moment().startOf("day");
        let differenceInDays = 0;

        if (givenDate.isValid()) {
            differenceInDays = givenDate.diff(today, "days");
        }

        return [
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        product?.productImageDetails?.productAvatar ||
                                        product?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <div>
                                <span className="block text-sm">
                                    {truncate(product?.productName, {
                                        length: 55,
                                    })}
                                </span>
                                <span className="text-sm font-rocGroteskMedium text-slate-500">
                                    SKU: {product?.sku}
                                </span>
                            </div>
                        </div>
                    </div>
                ),
                cellClickAction: () => {
                    navigate(`/dashboard/inventory/products/${product?._id}`);
                },
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-xs text-slate-800 `}
                    >
                        {product?.channel ? (
                            <div className="flex items-center space-x-2">
                                {displayProductChannelIcon(product?.channel)}
                                <span>{truncate(product?.channel, { length: 10 })} </span>
                            </div>
                        ) : (
                            <div className="flex items-center space-x-2">
                                {displayProductChannelIcon(product?.channel)}
                                <span>Synth</span>
                            </div>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>{product?.inventoryCategory || "N/A"}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {product?.productStockDetails?.stockLevel?.toLocaleString() || "N/A"}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>{Math.round(product?.onOrder)?.toLocaleString()}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>{product?.unfulfilledSale?.toLocaleString()}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {product?.replenishment
                                ? Math.round(product?.replenishment)?.toLocaleString()
                                : "N/A"}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <div>
                            <span className="text-sm font-rocGroteskMedium">
                                {differenceInDays <= 0 ? (
                                    product?.productStockDetails?.stockLevel >
                                    product?.unfulfilledSale ? (
                                        "N/A"
                                    ) : (
                                        "Sold out"
                                    )
                                ) : (
                                    <>
                                        <span
                                            className={`block ${
                                                differenceInDays > 10
                                                    ? "text-carribean-green"
                                                    : "text-[#E4281D]"
                                            }`}
                                        >
                                            {differenceInDays} days
                                        </span>
                                        <span className={`block text-slate-500`}>
                                            {moment(product?.stockoutDate).format("MMM DD, YYYY")}
                                        </span>
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Button
                            isLoading={isFetchingRecentIncident && getProductId === product?._id}
                            btnText="Restock"
                            disabled={isFetchingRecentIncident}
                            btnClassname="!px-4 !py-2 !w-fit !h-[35px] !text-[13px] !whitespace-nowrap"
                            onClick={() => {
                                setGetProductId(product?._id);
                                handleIncidentId(product?._id);
                            }}
                        />
                    </div>
                ),
            },
        ];
    });

    const mobileTableBody = data?.map((product, idx) => {
        return {
            cellClickAction: () => {
                navigate(`/dashboard/inventory/products/${product?._id}`);
            },
            topString: product?._id,
            avatar: product?.productImageDetails?.productImages?.[0] ? (
                <VendorAvatar
                    imageSrc={
                        product?.productImageDetails?.productAvatar ||
                        product?.productImageDetails?.productImages?.[0]
                    }
                    size={32}
                    name={product?.productName}
                    containerClassname="!rounded-md"
                />
            ) : (
                <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                </div>
            ),
            topContent: (
                <div className="text-sm font-rocGroteskMedium">
                    <p className="font-rocGroteskMedium text-sm">
                        {truncate(product?.productName, { length: 25 })}
                    </p>
                    <p className="text-slate-500 font-rocGroteskMedium text-sm">
                        SKU: {product?.sku} | {product?.inventoryCategory}
                    </p>
                </div>
            ),
            bottomContent: (
                <div className="flex items-center space-x-2">
                    <div className="border border-slate-200 rounded w-fit px-2 py-[2px] flex items-center ">
                        {product?.channel ? (
                            <div className="flex items-center space-x-2 ">
                                {displayProductChannelIcon(product?.channel, "!w-4 !h-4")}
                                <span className="text-slate-500 text-sm font-rocGroteskMedium">
                                    {truncate(product?.channel, { length: 15 })}
                                </span>
                            </div>
                        ) : (
                            <div className="flex items-center space-x-2">
                                {displayProductChannelIcon(product?.channel, "!w-4 !h-4")}
                                <span className="text-slate-500 text-sm font-rocGroteskMedium">
                                    Synth
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="border border-slate-200 rounded w-fit px-2 py-[2px] flex items-center ">
                        <div className="flex items-center">
                            <span className="text-slate-500 text-sm font-rocGroteskMedium">
                                Curr_stock:{" "}
                                {product?.productStockDetails?.stockLevel?.toLocaleString() ||
                                    "N/A"}
                            </span>
                        </div>
                    </div>
                </div>
            ),
            // rightIcon: (
            //     <div
            //         className={` flex justify-end space-x-2.5  items-center h-full border-slate-100 text-sm text-slate-800 `}
            //         onClick={(e) => e.stopPropagation()}
            //     >
            //         <p
            //             className={` ${
            //                 product?.status === "fulfilled"
            //                     ? "text-g-60 bg-g-25"
            //                     : product?.status === "rejected"
            //                     ? "text-r-55 bg-r-25 "
            //                     : product?.status === "defectDetected"
            //                     ? "text-[#E2A400] bg-[rgba(255,185,3,0.15)]"
            //                     : "text-slate-900 bg-slate-200"
            //             } capitalize !font-rocGroteskMedium text-sm rounded-2xl py-0.5 px-2`}
            //         >
            //             {product?.status === "defectDetected"
            //                 ? "Defect detected"
            //                 : product?.status === "inProgress"
            //                 ? "In progress"
            //                 : product?.status}
            //         </p>

            //         <Dropdown
            //             dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
            //             dropdownClassName={"!w-full  !mb-10 !-mt-7"}
            //             dropdownContainerClasses={`shadow-cardShadow-2 !left-[-80px]
            //                  border-0 rounded !max-h-fit`}
            //             dropdownItemsClasses={``}
            //             name={"faq-dropdown"}
            //             dropdownOptions={[
            //                 {
            //                     label: `View details`,
            //                     value: "1",
            //                     action: () => {
            //                         navigate(
            //                             `/dashboard/track-trace/${product?.batchId}?productType=finished-good`
            //                         );
            //                     },
            //                 },
            //                 // {
            //                 //     label: "Initiate recall",
            //                 //     value: "2",
            //                 //     action: () => {
            //                 //         console.log("2");
            //                 //     },
            //                 // },
            //             ]}
            //         />
            //     </div>
            // ),
        };
    });

    useEffect(() => {
        if (recentIncident?.incident === undefined) {
            return;
        }
        if (recentIncident.incident?._id) {
            setOpenReStockModal(true);
        } else if (!recentIncident.incident?._id) {
            navigate(`/dashboard/purchase/create-purchase-request?inventory=${getProductId}`);
            handleIncidentId("");
        }
    }, [recentIncident?.incident]);

    const pieChartTwoOptions = {
        tooltip: {
            trigger: "item",
        },
        color: COLORS,
        series: [
            {
                name: "Inventory per location",
                type: "pie",
                radius: ["55%", "70%"],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: "center",
                },
                itemStyle: {
                    borderRadius: 0,
                    borderWidth: 0,
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 40,
                        fontWeight: "bold",
                    },
                },
                labelLine: {
                    show: false,
                },
                data: salesBreakdown?.channelData?.map((channel) => {
                    return { value: channel?.totalStock, name: channel?.warehouseName };
                }),
            },
        ],
    };

    return (
        <PageFrame
            isLoading={
                // (productListLoading && Number(pagination.currentPage) === 1 && data.length === 0) ||
                analyticsLoading
            }
            containerClassName={"!min-h-[60vh]"}
        >
            <div className="grid grid-cols-[1fr_1.2fr] max-lg:grid-cols-1 max-lg:space-x-0 max-lg:space-y-6 space-x-6 mb-6">
                <div className="grid grid-cols-2 gap-4 max-sm:grid-cols-1">
                    <div className="p-6 bg-[#FFFBEB] rounded-md flex flex-col justify-center">
                        <i className="ri-money-dollar-circle-fill text-2xl text-y-10 mb-2.5"></i>
                        <span className="block text-sm font-rocGroteskMedium">
                            Total Stock value
                        </span>
                        <span className="block text-[28px] max-sm:text-xl max-sm:leading-[28.8px] leading-[40.32px] font-rocGroteskMedium">
                            ${formatMoney().format(productAnalytics?.totalStockValue) || 0}
                        </span>
                    </div>

                    <div className="p-6 bg-[#F0FDF4] rounded-md flex flex-col justify-center">
                        <i className="ri-box-2-fill text-2xl text-[#16A34A] mb-2.5"></i>
                        <span className="block text-sm font-rocGroteskMedium">In stock</span>
                        <span className="block text-[28px] max-sm:text-xl max-sm:leading-[28.8px] leading-[40.32px] font-rocGroteskMedium">
                            {productAnalytics?.totalPositiveStock || 0}
                        </span>
                    </div>
                    <div className="p-6 bg-[#FAF5FF] rounded-md flex flex-col justify-center">
                        <i className="ri-arrow-down-line text-2xl text-[#7C3AED] mb-2.5"></i>
                        <span className="block text-sm font-rocGroteskMedium">Below minimum</span>
                        <span className="block text-[28px] max-sm:text-xl max-sm:leading-[28.8px] leading-[40.32px] font-rocGroteskMedium">
                            {productAnalytics?.totalBelowMinimumStock || 0}
                        </span>
                    </div>
                    <div className="p-6 bg-[#FEF2F2] rounded-md flex flex-col justify-center">
                        <i className="ri-box-3-line text-2xl text-[#DC2626] mb-2.5"></i>
                        <span className="block text-sm font-rocGroteskMedium">Out of stock</span>
                        <span className="block text-[28px] max-sm:text-xl max-sm:leading-[28.8px] leading-[40.32px] font-rocGroteskMedium">
                            {productAnalytics?.totalNegetiveStock || 0}
                        </span>
                    </div>
                </div>

                <div className="border border-[#E2E8F080] rounded-lg p-6 max-sm:px-3 shadow-[-1px_6px_15px_0px_#00000005] h-[350px] max-sm:h-auto">
                    <div className="flex items-center space-x-2 max-sm:grid max-sm:grid-cols-1 max-sm:space-x-0 max-sm:space-y-2">
                        <Dropdown
                            handleChange={(name, value) => {
                                console.log("");
                            }}
                            dropdown={
                                <div className="flex items-center justify-between whitespace-nowrap h-[40px]  py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                    <span className={`text-g-75 font-rocGroteskMedium text-[13px]`}>
                                        {truncate(selectedProduct?.name, { length: 20 }) ||
                                            "All products"}
                                    </span>
                                    <div className="flex items-center space-x-1">
                                        {selectedProduct?.name && (
                                            <i
                                                onClick={handleClearFilterTwo}
                                                className="ri-close-circle-line text-slate-500"
                                            ></i>
                                        )}
                                        <i className="ri-arrow-down-s-line text-g-75"></i>
                                    </div>
                                </div>
                            }
                            search={
                                <div>
                                    <TextInput
                                        value={searchTwo}
                                        name={"search"}
                                        type={"text"}
                                        required={false}
                                        onChange={handleSearchTwo}
                                        onInput={handleDebouncedChangeTwo}
                                        inputPlaceholder="Search products"
                                        containerClassname=""
                                        // inputContainerClassname={"!border-none !rounded-none "}
                                        inputClassName="w-full"
                                    />
                                </div>
                            }
                            wholeContainerClass="!w-[33.33%] max-sm:!w-full"
                            dropdownContainerClasses={`shadow-cardShadow-4 !w-full !max-w-full min-w-full border border-slate-100 rounded !max-h-fit !pt-0`}
                            dropdownOptions={[
                                {
                                    customChild: (
                                        <div>
                                            <div className="max-h-[280px]">
                                                {productSearchList?.map((product) => {
                                                    return (
                                                        <div
                                                            key={product?._id}
                                                            onClick={() =>
                                                                handleFilterChangeTwo(
                                                                    product?.productName,
                                                                    product?._id
                                                                )
                                                            }
                                                        >
                                                            <div
                                                                className={`flex items-center space-x-3 px-3 py-2 hover:bg-slate-100 cursor-pointer `}
                                                            >
                                                                <div>
                                                                    <div className="flex items-center space-x-3">
                                                                        {product
                                                                            ?.productImageDetails
                                                                            ?.productImages?.[0] ? (
                                                                            <VendorAvatar
                                                                                imageSrc={
                                                                                    product
                                                                                        ?.productImageDetails
                                                                                        ?.productImages?.[0]
                                                                                }
                                                                                size={32}
                                                                                name={
                                                                                    product?.productName
                                                                                }
                                                                                containerClassname="!rounded-md"
                                                                            />
                                                                        ) : (
                                                                            <div className="w-8 h-8 rounded flex shrink-0 items-center justify-center bg-slate-200 ">
                                                                                <i className="ri-box-2-fill text-sm text-slate-500"></i>
                                                                            </div>
                                                                        )}
                                                                        <div>
                                                                            <span className="block text-xs font-rocGroteskMedium">
                                                                                {truncate(
                                                                                    product?.sku,
                                                                                    {
                                                                                        length: 30,
                                                                                    }
                                                                                )}
                                                                            </span>
                                                                            <span className="block text-[10px] text-slate-500 font-rocGroteskMedium">
                                                                                {truncate(
                                                                                    product?.productName,
                                                                                    {
                                                                                        length: 50,
                                                                                    }
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        />

                        <Dropdown
                            handleChange={(name, value) => {
                                console.log("first", name, value);
                            }}
                            dropdown={
                                <div className="flex items-center justify-between whitespace-nowrap h-[40px]  py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                    <div className="flex items-center space-x-1">
                                        {/* <i className="ri-map-pin-line text-sm text-g-75 "></i> */}
                                        <span
                                            className={`text-g-75 font-rocGroteskMedium text-[13px]`}
                                        >
                                            {truncate(warehouseValue?.name, { length: 16 }) ||
                                                "All locations"}
                                        </span>
                                    </div>
                                    <div className="flex items-center space-x-1">
                                        {warehouseValue?.name && (
                                            <i
                                                onClick={clearWarehouseFilter}
                                                className="ri-close-circle-line text-slate-500"
                                            ></i>
                                        )}
                                        <i className="ri-arrow-down-s-line text-g-75"></i>
                                    </div>
                                </div>
                            }
                            search={
                                <div>
                                    <TextInput
                                        value={warehouseSearch}
                                        name={"search"}
                                        type={"text"}
                                        required={false}
                                        onChange={handleWarehouseSearch}
                                        onInput={debounceChange}
                                        inputPlaceholder="Search warehouse"
                                        containerClassname=""
                                        // inputContainerClassname={"!border-none !rounded-none "}
                                        inputClassName="w-full"
                                    />
                                </div>
                            }
                            // dropdownClassName=""
                            wholeContainerClass="!w-[33.33%] max-sm:!w-full"
                            id="modalContent"
                            dropdownContainerClasses={`shadow-cardShadow-4 !w-full border border-slate-100  rounded !max-h-fit !pt-0`}
                            dropdownOptions={[
                                {
                                    customChild: (
                                        <div>
                                            <div className="max-h-[280px]">
                                                {allWarehouses?.map((warehouse) => {
                                                    return (
                                                        <div
                                                            key={warehouse?._id}
                                                            onClick={() =>
                                                                handleWarehouseFilterChange(
                                                                    warehouse?.warehouseName,
                                                                    warehouse?._id
                                                                )
                                                            }
                                                        >
                                                            <div
                                                                className={`flex items-center space-x-3 px-3 py-2 hover:bg-slate-100 cursor-pointer ${
                                                                    warehouseValue?.value ===
                                                                        warehouse?._id &&
                                                                    "bg-slate-100"
                                                                }`}
                                                            >
                                                                <i className="ri-map-pin-line text-base text-g-75 "></i>
                                                                <div>
                                                                    <span
                                                                        className={`block text-g-75 text-sm font-rocGroteskMedium ${
                                                                            warehouse?.address
                                                                                ?.address &&
                                                                            "mb-[2px]"
                                                                        }`}
                                                                    >
                                                                        {truncate(
                                                                            warehouse?.warehouseName,
                                                                            { length: 40 }
                                                                        )}
                                                                    </span>
                                                                    <span className="block text-g-75 text-sm font-rocGroteskMedium ">
                                                                        {truncate(
                                                                            warehouse?.address
                                                                                ?.address,
                                                                            { length: 40 }
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        />

                        <Dropdown
                            handleChange={(name, value) => {
                                console.log("first", name, value);
                                setDateRange(value);
                            }}
                            dropdown={
                                <div className="flex items-center justify-between whitespace-nowrap h-[40px] py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                    <span
                                        className={`text-g-75 font-rocGroteskMedium text-[13px] capitalize`}
                                    >
                                        {dateRange === "all" ? "All time" : dateRange}
                                    </span>
                                    <i className="ri-arrow-down-s-line text-g-75"></i>
                                </div>
                            }
                            // dropdownItemsClasses={`!px-0 !py-0`}
                            wholeContainerClass="!w-[33.33%] max-sm:!w-full"
                            dropdownContainerClasses={`shadow-cardShadow-4 !w-full border border-slate-100  rounded !max-h-fit`}
                            dropdownOptions={[
                                {
                                    label: "All time",
                                    value: "all",
                                },
                                {
                                    label: "Daily",
                                    value: "daily",
                                },
                                {
                                    label: "Weekly",
                                    value: "weekly",
                                },
                                {
                                    label: "Monthly",
                                    value: "monthly",
                                },
                                {
                                    label: "Yearly",
                                    value: "yearly",
                                },
                            ]}
                        />
                    </div>

                    <div className="grid grid-cols-2 max-sm:grid-cols-1 items-center">
                        <div className="relative ">
                            <ReactECharts option={pieChartTwoOptions} />

                            <div className="absolute top-[28%] left-0 right-0 m-auto w-[130px] h-[130px] rounded-full font-rocGroteskMedium flex flex-col items-center justify-center bg-white shadow-[0px_4px_15.6px_-4px_#1018281A]">
                                <span className="block text-[12px] text-slate-500">
                                    {truncate(warehouseValue?.name, { length: 15 }) ||
                                        "All warehouses"}
                                </span>
                                <span className="block text-[20px] text-g-75 leading-[40px]">
                                    {millify(salesBreakdown.totalStock, {
                                        precision: 2,
                                    })}
                                </span>
                                <span className="block text-[12px] text-slate-500">Units</span>
                            </div>
                        </div>

                        <div className="">
                            {salesBreakdown?.channelData?.slice(0, 5)?.map((channel, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className="flex justify-between items-center mb-3"
                                    >
                                        <div className="flex items-center space-x-1.5">
                                            <span
                                                className={`w-[14px] h-[14px] rounded-[2px]`}
                                                style={{ backgroundColor: COLORS?.[idx] }}
                                            ></span>
                                            <span className="text-slate-500 font-rocGroteskMedium text-sm">
                                                {truncate(channel?.warehouseName, { length: 20 })}
                                            </span>
                                        </div>
                                        <span className="block text-center text-g-75 font-rocGroteskMedium text-base">
                                            {formatMoney(true).format(channel?.totalStock)} units
                                        </span>
                                    </div>
                                );
                            })}
                            {salesBreakdown?.channelData?.slice(5)?.length > 0 && (
                                <div className="flex justify-end">
                                    <div
                                        onClick={() => setPieChartModal(true)}
                                        className="flex items-center space-x-1 w-fit cursor-pointer"
                                    >
                                        <span className="text-sm font-rocGroteskMedium underline text-p-50">
                                            View all
                                        </span>
                                        <i className="ri-arrow-right-line text-base text-p-50"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex items-center space-x-2 mb-6 max-sm:grid max-sm:space-x-0 max-sm:space-y-2 max-sm:grid-cols-1">
                <TextInput
                    name={"search"}
                    value={search}
                    type={"text"}
                    inputPlaceholder={"Search by product ID, name, date"}
                    inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                    onChange={handleSearch}
                    onInput={handleDebouncedChange}
                    leftIcon={
                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                    }
                    inputContainerClassname={
                        "!rounded-[4px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white shadow-[0_1px_2px_0_rgba(91,104,113,0.15)] !border !border-n-20"
                    }
                />

                <Dropdown
                    name="filter"
                    value={""}
                    dropdownTriggerClassName={" "}
                    handleChange={(name, value) => handleFilterChange(value)}
                    dropdown={
                        <div className="bg-white flex items-center justify-between h-10 space-x-2 py-2.5 px-3 border border-tradeally-neutral-20 !rounded shadow-[0_1px_2px_0_rgba(91,104,113,0.15)]">
                            <div className="flex items-center space-x-2">
                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                    {productCategory || "Category"}
                                </span>
                                {Boolean(productCategory) && (
                                    <i
                                        onClick={(e) => {
                                            e?.stopPropagation();
                                            handleClearFilter();
                                        }}
                                        className="ri-close-circle-fill text-sm text-slate-500"
                                    ></i>
                                )}
                            </div>
                            <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                        </div>
                    }
                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                    dropdownContainerClasses={`shadow-cardShadow-4 left-[0px] !mt-0  !w-[159px] max-sm:!w-full rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                    dropdownOptions={[
                        {
                            label: "Raw Materials",
                            value: "Raw Material",
                        },
                        {
                            label: "Finished Goods",
                            value: "Finished Goods",
                        },
                    ]}
                />

                {/* <Dropdown
                    handleChange={(name, value) => {
                        console.log("first", name, value);
                    }}
                    dropdown={
                        <div className="flex items-center  whitespace-nowrap h-[40px] py-2 px-4 space-x-1 ">
                            <i className="ri-map-pin-line text-sm text-g-75 "></i>
                            <span className={`text-g-75 font-rocGroteskMedium text-[13px]`}>
                                {warehouseDataValue?.name || "Warehouses"}
                            </span>
                            {warehouseDataValue?.name && (
                                <i
                                    onClick={clearFilter}
                                    className="ri-close-circle-line text-slate-500"
                                ></i>
                            )}
                            <i className="ri-arrow-down-s-line text-g-75"></i>
                        </div>
                    }
                    search={
                        <div>
                            <TextInput
                                value={searchTwo}
                                name={"search"}
                                type={"text"}
                                required={false}
                                onChange={handleSearchTwo}
                                onInput={handleDebouncedChangeTwo}
                                inputPlaceholder="Search warehouse"
                                containerClassname=""
                                // inputContainerClassname={"!border-none !rounded-none "}
                                // inputClassName="w-[50%]"
                            />
                        </div>
                    }
                    dropdownContainerClasses={`shadow-cardShadow-4  border border-slate-100  rounded `}
                    dropdownOptions={[
                        {
                            customChild: (
                                <div>
                                    <div className="max-h-[280px]">
                                        {searchResult?.map((warehouse) => {
                                            return (
                                                <div
                                                    key={warehouse?._id}
                                                    onClick={() =>
                                                        filterChange(
                                                            warehouse?.warehouseName,
                                                            warehouse?._id
                                                        )
                                                    }
                                                >
                                                    <div
                                                        className={`flex items-center space-x-3 max-w-[300px] px-3 py-2 hover:bg-slate-100 cursor-pointer ${
                                                            warehouseDataValue?.value ===
                                                                warehouse?._id && "bg-slate-100"
                                                        }`}
                                                    >
                                                        <i className="ri-map-pin-line text-base text-g-75 "></i>
                                                        <div>
                                                            <span
                                                                className={`block text-g-75 text-sm font-rocGroteskMedium ${
                                                                    warehouse?.address?.address &&
                                                                    "mb-[2px]"
                                                                }`}
                                                            >
                                                                {truncate(
                                                                    warehouse?.warehouseName,
                                                                    { length: 40 }
                                                                )}
                                                            </span>
                                                            <span className="block text-g-75 text-sm font-rocGroteskMedium ">
                                                                {truncate(
                                                                    warehouse?.address?.address,
                                                                    { length: 40 }
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ),
                        },
                    ]}
                /> */}

                {/* <Button
                    btnText="Settings"
                    btnClassname="!bg-white shadow-boxShadow-2 !border !border-tradeally-neutral-20 !w-fit !h-10 !text-[13px]"
                    icon={<i className="ri-settings-5-line text-base"></i>}
                /> */}
                <Button
                    btnText="Add product"
                    btnClassname="!px-4 !py-2 !w-fit max-sm:!w-full !h-10 !text-[13px] !whitespace-nowrap"
                    onClick={() => navigate("/dashboard/inventory/add-product")}
                />
            </div>

            {data?.length > 0 ? (
                <div className="overflow-x-scroll mb-6">
                    <div className="max-lg:hidden">
                        <CustomTable
                            tableBody={tableBody}
                            tableHeader={tableHeader}
                            isScrollable={false}
                            // isScrolled={isScrolled}
                            isCellBordered={false}
                            isCellSelectable={false}
                            isAllSelectable={false}
                            headerContainerClass="!bg-slate-50"
                            headerItemClass="font-rocGroteskMedium "
                            tableClass="mb-12"
                        />
                    </div>

                    <div className="hidden max-lg:block">
                        <CustomMobileTable data={mobileTableBody} />
                    </div>

                    {productListLoading && isLoadingMore && (
                        <div className="flex justify-center my-4">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}

                    {pagination?.currentPage === pagination?.noOfPages && (
                        <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                            End of list
                        </div>
                    )}
                </div>
            ) : (
                <EmptyStateComp
                    title="No products available"
                    description="There are no products in your inventory. Add new products to start managing your inventory."
                    hasButton
                    btnText="Add new product"
                    btnAction={() => navigate("/dashboard/inventory/add-product")}
                    icon={<i className="ri-box-3-line text-slate-500 text-[40px]"></i>}
                />
            )}

            {openReStockModal && (
                <ScrollableModal
                    closeModal={() => {
                        handleIncidentId("");
                        setOpenReStockModal(false);
                    }}
                    setAtBottom={setAtBottom}
                    modalHeader={
                        <div className="flex py-4  px-8 border-b border-slate-200 justify-between items-center">
                            <p className="text-lg font-rocGroteskMedium text-g-75">
                                Replenishment Tasks
                            </p>
                            <i
                                className="ri-close-fill cursor-pointer text-xl text-black"
                                onClick={() => {
                                    handleIncidentId("");
                                    setOpenReStockModal(false);
                                }}
                            ></i>
                        </div>
                    }
                    children={
                        <div className="mt-2 ">
                            <AlertTabDetails
                                data={incidentData}
                                isFetching={isFetchingIncidentData}
                                pagination={pagination}
                                isLoadingMore={isLoadingMore}
                                modalShow={false}
                                onClickDocument={() => {
                                    setOpenDocument(true);
                                    setOpenReStockModal(false);
                                }}
                                incidentId={recentIncident?.incident?._id}
                                replishmentStock={replishmentStock}
                                isFetchingReplishment={isFetchingReplishment}
                                onClickShipment={() => {
                                    setOpenShipment(true);
                                    setOpenReStockModal(false);
                                }}
                            />
                            {!atBottom && (
                                <div className="justify-center flex  absolute left-0 right-0 bottom-2">
                                    <div className="bg-y-10 px-[16px]  shadow-scrollShadow  py-[6px] !w-fit rounded-[200px] flex space-x-2 items-center">
                                        <i className="ri-arrow-down-line text-g-75"></i>
                                        <p className="text-base font-rocGroteskMedium text-g-75">
                                            Scroll down
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                />
            )}

            <ModalContainer
                open={openShipment}
                showCloseIcon={false}
                tailwindClassName="w-[700px]"
                closeModal={() => {
                    setOpenShipment(false);
                }}
            >
                <div className="bg-white max-h-[600px] h-fit pb-4  rounded-lg shadow-cardShadow relative">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            setShowAddShipmentReminder(true);
                            setOpenReStockModal(false);
                            setOpenShipment(false);
                        }}
                    >
                        <div className="">
                            <div className="flex py-4 border-b border-slate-200 px-8 justify-between items-center">
                                <p className="text-lg font-rocGroteskMedium text-g-75">
                                    Add shipment(s) from your supplier
                                </p>
                                <i
                                    className="ri-close-fill text-xl text-black cursor-pointer"
                                    onClick={() => setOpenShipment(false)}
                                ></i>
                            </div>
                            <div className=" mt-6 max-h-[400px] overflow-y-auto">
                                <div className="px-8">
                                    <div className=" pb-4">
                                        <div className="flex space-x-5 w-full">
                                            <div className="w-full">
                                                <TextInput
                                                    value={""}
                                                    disabled={true}
                                                    name="shipmentId"
                                                    placeholder={"Shipment ID"}
                                                    onChange={(e) => {}}
                                                />
                                                <span className="mt-1 flex items-center">
                                                    <img
                                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1713459494/Frame_g6qjiy.svg"
                                                        className="h-2 w-2"
                                                        alt=""
                                                    />
                                                    <p className="text-[10px] leading-[14px] text-[#64748B]">
                                                        This is auto generated by the system
                                                    </p>
                                                </span>
                                            </div>
                                            <div className="w-full">
                                                <SelectInput
                                                    value={mode}
                                                    name="mode"
                                                    placeholder={"Select mode"}
                                                    handleChange={(name, value) => setMode(value)}
                                                    isRequired={true}
                                                    optionItemContainerClassName={
                                                        "hover:bg-slate-100"
                                                    }
                                                    dropdownOptions={[
                                                        {
                                                            label: (
                                                                <div className="font-rocGroteskMedium items-center -mt-2 flex space-x-1">
                                                                    <i className="ri-ship-line"></i>

                                                                    <p className="text-gm-50 text-xs  font-rocGroteskMedium">
                                                                        Ocean
                                                                    </p>
                                                                </div>
                                                            ),
                                                            value: "ocean",
                                                        },
                                                        {
                                                            label: (
                                                                <div className="font-rocGroteskMedium items-center -mt-2 flex space-x-1">
                                                                    <i className="ri-plane-fill"></i>

                                                                    <p className="text-gm-50 text-xs  font-rocGroteskMedium">
                                                                        Air
                                                                    </p>
                                                                </div>
                                                            ),
                                                            value: "air",
                                                        },
                                                        {
                                                            label: (
                                                                <div className="font-rocGroteskMedium items-center -mt-2 flex space-x-1">
                                                                    <i className="ri-truck-line"></i>

                                                                    <p className="text-gm-50 text-xs  font-rocGroteskMedium">
                                                                        Land
                                                                    </p>
                                                                </div>
                                                            ),
                                                            value: "land",
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className=" pb-4">
                                            {mode.toLowerCase() === "ocean" && (
                                                <div className="flex space-x-5 mt-3 items-center">
                                                    <TextInput
                                                        value={shipmentDetails?.trackingLink}
                                                        type={"text"}
                                                        name="trackingLink"
                                                        placeholder={"Tracking link"}
                                                        onChange={handleChange}
                                                    />
                                                    <TextInput
                                                        value={shipmentDetails?.billOfLading}
                                                        type={"text"}
                                                        name="billOfLading"
                                                        placeholder={"Bill of lading"}
                                                        onChange={handleChange}
                                                        required={true}
                                                    />
                                                </div>
                                            )}
                                            {mode.toLowerCase() === "air" && (
                                                <div className="flex space-x-5 mt-3 items-center">
                                                    <TextInput
                                                        value={shipmentDetails?.billNumber}
                                                        name="billNumber"
                                                        placeholder={"Airway bill number"}
                                                        onChange={handleChange}
                                                        required={true}
                                                    />
                                                    <SelectInput
                                                        value={shipmentDetails?.carrier}
                                                        name="carrier"
                                                        placeholder={"Carrier"}
                                                        handleChange={(name, value) =>
                                                            handleChange({
                                                                target: {
                                                                    name,
                                                                    value,
                                                                },
                                                            })
                                                        }
                                                        isRequired={true}
                                                        optionItemContainerClassName={
                                                            "hover:bg-slate-100"
                                                        }
                                                        dropdownOptions={oceanCarriers?.map(
                                                            (carrierData) => {
                                                                return {
                                                                    label: carrierData?.[
                                                                        "Full Name"
                                                                    ],
                                                                    value: carrierData?.SCAC,
                                                                };
                                                            }
                                                        )}
                                                    />
                                                </div>
                                            )}
                                            {mode.toLowerCase() === "land" && (
                                                <div className="mt-3 ">
                                                    <TextInput
                                                        value={shipmentDetails?.trackingLink}
                                                        type={"text"}
                                                        name="trackingLink"
                                                        placeholder={"Tracking link"}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex space-x-5 mt-1">
                                            <AddressInput
                                                setData={setAddress}
                                                savedAddress={
                                                    address?.streetAddress
                                                        ? address?.origin?.address
                                                        : ""
                                                }
                                                placeholder="Origin"
                                                required={true}
                                                name={"origin"}
                                            />

                                            <SelectInput
                                                value={shipmentDetails?.destination}
                                                name="destination"
                                                handleInputChange={debounce((evt) => {
                                                    setDebouncedFilter({
                                                        search: evt.target.value,
                                                        shipmentSearch: "",
                                                    });
                                                }, 800)}
                                                placeholder={"Destination"}
                                                handleChange={(name, value) =>
                                                    handleChange({
                                                        target: { name, value },
                                                    })
                                                }
                                                isRequired={true}
                                                optionItemContainerClassName={"hover:bg-slate-100"}
                                                dropdownOptions={warehouseList?.map((list) => {
                                                    return {
                                                        label: list?.warehouseName,
                                                        value: list?._id,
                                                    };
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="px-8 mt-3  ">
                                    <div className="font-rocGroteskMedium">
                                        <p className="text-base  text-gm-50 mb-2">
                                            Product details
                                        </p>
                                        <p className="text-sm  text-[#64748B]  mb-2">
                                            Kindly include the products you wish to ship in your
                                            shipment
                                        </p>
                                        <div>
                                            <CustomTable
                                                tableBody={productDetailsTableBody}
                                                tableHeader={productDetailsTableHeader}
                                                isAllSelectable={false}
                                                isCellSelectable={false}
                                                headerContainerClass="!bg-[#F1F5F9] !border-[#E2E8F0] !border"
                                                isScrollable={false}
                                                isCellBordered={false}
                                                bodyItemClass={"hover:!bg-transparent"}
                                            />
                                        </div>
                                        {productData?.length > 0 &&
                                            productData?.length <
                                                productList?.itemsOrServices?.length && (
                                                <Button
                                                    btnText="Add products"
                                                    onClick={handleAddRow}
                                                    btnClassname="!px-4 !py-2.5 !w-fit "
                                                />
                                            )}
                                    </div>
                                    <div className=" mt-4 pb-4">
                                        <p className="text-[#62707A] text-[16px]  mb-2 leading-[23.04px] font-rocGroteskMedium">
                                            Additional notes
                                        </p>
                                        <TextareaInput
                                            name={"additionalInfo"}
                                            value={shipmentDetails?.additionalInfo}
                                            onChange={handleChange}
                                            rows={2}
                                            placeholder={"Additional notes"}
                                            containerClassname=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="pt-3 border-t border-slate-200 px-8 flex space-x-4 items-center ">
                                <Button
                                    btnText="Close"
                                    btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 "
                                    onClick={() => {
                                        setShipmentDetails({});
                                        setMode("");
                                        setOpenShipment(false);
                                    }}
                                />
                                <Button
                                    btnText="Add shipment"
                                    btnClassname="!px-4 !py-2.5 "
                                    type={"submit"}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </ModalContainer>
            <ModalContainer
                open={showAddShipmentReminder}
                showCloseIcon={false}
                tailwindClassName="md:w-[600px] "
            >
                <div className="bg-white p-6 flex flex-col rounded-[8px]  ">
                    <p className="text-[18px] mb-2 leading-[24px] font-rocGroteskMedium text-gm-50">
                        Review shipment for accuracy
                    </p>

                    <p className="text-slate-500 font-rocGroteskMedium text-base">
                        Please review and ensure that the items listed in the Purchase Order (PO)
                        match what has been delivered. Before adding these products to inventory,
                        carefully review the product details for accuracy in both quantity and
                        quality to ensure everything is correct.
                    </p>

                    <div className=" justify-end mt-6 flex space-x-4">
                        <Button
                            btnText={"No, go back"}
                            btnClassname={"!bg-[#F4F5F7] !w-fit !py-3"}
                            disabled={createShipment}
                            onClick={() => {
                                setShowAddShipmentReminder(false);
                                setOpenShipment(true);
                            }}
                        />
                        <Button
                            btnText={`Proceed to add shipment to inventory`}
                            onClick={handleSubmit}
                            btnClassname={"!w-fit !py-3"}
                            disabled={createShipment}
                            isLoading={createShipment}
                        />
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={showSuccessShipmentAdded}
                showCloseIcon={false}
                closeModal={() => {
                    dispatch(shipmentActions.resetCreateShipmentSuccess());
                    setshowSuccessShipmentAdded(false);
                    setShipmentDetails({});
                    setMode("");
                }}
                tailwindClassName="w-[500px]"
            >
                {response?.failed?.length > 0 ? (
                    <div className="bg-white rounded-lg shadow-cardShadow relative">
                        <div className="px-10 pt-8 max-h-[70vh] ">
                            <div className="flex justify-end ">
                                <i
                                    className="ri-close-fill text-lg text-gm-45 cursor-pointer"
                                    onClick={() => {
                                        setShipmentDetails({});
                                        setMode("");
                                        dispatch(shipmentActions.resetCreateShipmentSuccess());
                                        setshowSuccessShipmentAdded(false);
                                        setOpenReStockModal(true);
                                    }}
                                ></i>
                            </div>
                            <div className="flex flex-col items-center">
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1707140954/Software_xcvcl6.svg"
                                    alt="warn-sign"
                                />

                                <p className="text-xl mb-3 text-center mt-4 font-rocGroteskMedium text-g-75">
                                    Shipment creation failed!
                                </p>
                                <p className="text-sm text-center font-rocGroteskMedium text-slate-500 max-w-[411px]">
                                    There was an error while creating your shipment as the quantity
                                    exceeded the purchase order
                                </p>
                            </div>
                            {response?.failed?.map((item, idx) => (
                                <div className="max-h-[50vh] overflow-y-scroll ">
                                    <div className="mt-2">
                                        <p className="text-base text-slate-500 font-rocGroteskMedium">
                                            Package {idx + 1}
                                        </p>
                                    </div>
                                    <div className="bg-white space-y-3 mt-2 text-sm mb-2 shadow-cardDropdownShadow p-4">
                                        <div className="flex justify-between items-center font-rocGroteskMedium ">
                                            <p className="text-black">Product name</p>
                                            <p className="text-end text-slate-500">
                                                {item?.inventory?.productName}
                                            </p>
                                        </div>
                                        <div className="flex justify-between items-center font-rocGroteskMedium ">
                                            <p className="text-black">SKU</p>
                                            <p className="text-end text-slate-500">
                                                {item?.inventory?.sku}
                                            </p>
                                        </div>
                                        <div className="flex justify-between items-center font-rocGroteskMedium ">
                                            <p className="text-black">Inventory category</p>
                                            <p className="text-end text-slate-500">
                                                {item?.inventory?.inventoryCategory}
                                            </p>
                                        </div>

                                        <div className="flex justify-between text-[#FF3D1D] items-center font-rocGroteskMedium">
                                            <div className="flex items-center space-x-1 ">
                                                <i className="ri-information-line"></i>
                                                <p>Quantity</p>
                                            </div>
                                            <p className="text-end">
                                                {item?.qtyRemaining < 0 ? 0 : item?.qtyRemaining}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="w-full px-8 py-4  border-slate-200 ">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                onClick={() => {
                                    setShipmentDetails({});
                                    setMode("");
                                    dispatch(shipmentActions.resetCreateShipmentSuccess());
                                    setshowSuccessShipmentAdded(false);
                                    setOpenReStockModal(true);
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="bg-white rounded-lg shadow-cardShadow relative">
                        <div className="px-10 pt-5 max-h-[70vh] overflow-y-scroll ">
                            <div className="flex justify-end ">
                                <i
                                    className="ri-close-fill text-lg text-gm-45 cursor-pointer"
                                    onClick={() => {
                                        setShipmentDetails({});
                                        setMode("");
                                        dispatch(shipmentActions.resetCreateShipmentSuccess());
                                        setshowSuccessShipmentAdded(false);
                                        setOpenReStockModal(true);
                                    }}
                                ></i>
                            </div>
                            <div className="flex mb-4 flex-col items-center">
                                <div className="w-12 h-12 rounded-full bg-[#00DB8F] flex items-center justify-center">
                                    <i className="ri-checkbox-circle-fill text-xl text-white"></i>
                                </div>

                                <p className="text-2xl  text-center mt-4 font-rocGroteskMedium text-g-75">
                                    Shipment added successful
                                </p>
                                <p className="text-base text-center font-rocGroteskMedium text-slate-500 max-w-[411px]">
                                    The shipment has been successfully created and associated with
                                    Purchase Order: PO-{replishmentStock?.purchaseOrder?.taId}
                                </p>
                            </div>
                        </div>
                        <div className="w-full px-8 pb-6 pt-2  border-slate-200 ">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                onClick={() => {
                                    setShipmentDetails({});
                                    setMode("");
                                    dispatch(shipmentActions.resetCreateShipmentSuccess());
                                    setshowSuccessShipmentAdded(false);
                                    setOpenReStockModal(true);
                                }}
                            />
                        </div>
                    </div>
                )}
            </ModalContainer>
            <ModalContainer
                open={openDocument}
                showCloseIcon={false}
                tailwindClassName="w-[700px]"
                closeModal={() => {
                    setOpenDocument(false);
                    setOpenReStockModal(true);
                }}
            >
                <div className="bg-white max-h-[600px] h-fit pb-4  rounded-lg shadow-cardShadow relative">
                    <div className="">
                        <div className="flex py-4  px-8 border-b border-slate-200 justify-between items-center">
                            <p className="text-lg font-rocGroteskMedium text-g-75">
                                Add documents related to shipment(s)
                            </p>
                            <i
                                className="ri-close-fill text-xl text-black cursor-pointer"
                                onClick={() => {
                                    setOpenDocument(false);
                                    setOpenReStockModal(true);
                                }}
                            ></i>
                        </div>
                        <div className=" px-8 mt-6 border-b border-slate-200 ">
                            <div>
                                <SelectInput
                                    value={shipmentId}
                                    name="shipment"
                                    placeholder={"Select Shipment"}
                                    handleChange={(name, value) => {
                                        setShipmentId(value);
                                    }}
                                    handleInputChange={debounce((evt) => {
                                        setPage(1);
                                        setDebouncedFilter({
                                            search: "",
                                            shipmentSearch: evt.target.value,
                                        });
                                    }, 800)}
                                    isRequired={true}
                                    searchLoading={fetchingPoShipment}
                                    dropdownOptions={poShipment?.map((data: any) => ({
                                        label: (
                                            <div className="flex space-x-1 items-center">
                                                <p>{data?.origin?.country}</p> <span> - </span>
                                                <p>{data?.destination?.address?.country}</p>
                                            </div>
                                        ),
                                        value: data?._id,
                                    }))}
                                />
                            </div>
                            {attachments?.length === 0 && (
                                <div className="w-full relative rounded border-[#BFCDE0] border-dashed border my-6">
                                    <div className="py-8 font-rocGroteskMedium flex justify-center flex-col items-center">
                                        <i className="ri-upload-cloud-2-line mb-4 text-4xl  text-slate-500 "></i>
                                        <p className="text-[#142837] mb-1 text-sm ">
                                            Drag your file here to upload
                                        </p>
                                        <p className="text-[#6B778C] text-sm mb-4">
                                            File size limit: 5MB
                                        </p>
                                        <Button
                                            isLoading={uploadingAttachment}
                                            disabled={
                                                uploadingAttachment ||
                                                deletingImage ||
                                                replacingAttachment
                                            }
                                            btnText="Browse files"
                                            btnClassname="!bg-white !py-2 !px-4 border border-[#DFE1E6] !text-[#142837] !text-[13px] !w-auto"
                                        />
                                    </div>
                                    <input
                                        id="uploadFile"
                                        type="file"
                                        className="opacity-0 absolute w-full h-full top-0 cursor-pointer"
                                        accept="png"
                                        onChange={(e) =>
                                            handleUpload(
                                                e.target?.files[0]?.name,
                                                e.target.files[0] as File
                                            )
                                        }
                                    />
                                </div>
                            )}
                        </div>

                        {attachments?.length > 0 && (
                            <div className="px-8 max-h-[400px] overflow-y-auto mt-6 border-b border-slate-200">
                                {attachments?.map((doc, idx) => (
                                    <div
                                        key={idx}
                                        className="h-[68px] justify-between  items-center   border-t border-b border-r rounded border-slate-200 flex"
                                    >
                                        <div className="flex items-center space-x-3">
                                            <div className="bg-slate-50 border w-[68px] rounded-tl rounded-bl h-[68px] flex justify-center items-center border-slate-200">
                                                <i className="ri-file-fill text-4xl text-slate-500"></i>
                                            </div>
                                            <div>
                                                <p className="text-sm font-rocGroteskMedium text-gm-50">
                                                    {doc?.filename}
                                                </p>
                                                <p className="text-slate-500  font-rocGroteskMedium text-sm">
                                                    PDF
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-center pr-6 space-x-2 ">
                                            <div className="relative w-fit cursor-pointer">
                                                <Button
                                                    isLoading={replacingAttachment}
                                                    disabled={
                                                        uploadingAttachment ||
                                                        deletingImage ||
                                                        replacingAttachment
                                                    }
                                                    onClick={() => {
                                                        !replacingAttachment &&
                                                            document
                                                                .getElementById("fileInput")
                                                                ?.click();
                                                    }}
                                                    btnText="Replace File"
                                                    btnClassname="!px-4 !py-2.5 !bg-transparent !whitespace-nowrap  !border !border-[#DFE1E6] "
                                                />

                                                <input
                                                    type="file"
                                                    id="fileInput"
                                                    className={`absolute z-[-1]  opacity-0`}
                                                    accept="png"
                                                    onChange={(e) =>
                                                        handleReplaceUpload(
                                                            idx,
                                                            e.target?.files[0]?.name,
                                                            e.target.files[0] as File
                                                        )
                                                    }
                                                />
                                            </div>

                                            <Button
                                                btnText="Remove"
                                                isLoading={deletingImage}
                                                disabled={
                                                    uploadingAttachment ||
                                                    deletingImage ||
                                                    replacingAttachment
                                                }
                                                btnClassname="!px-4 !py-2.5 !bg-transparent !text-[#FF0000] !border !border-[#DFE1E6] "
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoveUpload(idx);
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}

                                <div className="w-full relative rounded border-[#BFCDE0] border-dashed border my-6">
                                    <div className="py-6 px-5 font-rocGroteskMedium flex  justify-between items-center">
                                        <div className="flex items-center space-x-2.5">
                                            <i className="ri-upload-cloud-2-line mb-4 text-4xl  text-slate-500 "></i>
                                            <div>
                                                <p className="text-[#142837] mb-1 text-sm ">
                                                    Drag your file here to upload
                                                </p>
                                                <p className="text-[#6B778C] text-sm mb-4">
                                                    File size limit: 5MB
                                                </p>
                                            </div>
                                        </div>
                                        <Button
                                            isLoading={uploadingAttachment}
                                            disabled={
                                                uploadingAttachment ||
                                                deletingImage ||
                                                replacingAttachment
                                            }
                                            btnText="Browse files"
                                            btnClassname="!bg-white !py-2 !px-4 border border-[#DFE1E6] !text-[#142837] !text-[13px] !w-auto"
                                        />
                                    </div>
                                    <input
                                        id="uploadFile"
                                        type="file"
                                        className="opacity-0 absolute w-full h-full top-0 cursor-pointer"
                                        accept="png"
                                        onChange={(e) =>
                                            handleUpload(
                                                e.target?.files[0]?.name,
                                                e.target.files[0] as File
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        <div className="mt-3 px-8 flex space-x-4 items-center ">
                            <Button
                                btnText="Close"
                                disabled={updateShipment}
                                btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 "
                                onClick={() => {
                                    setOpenDocument(false);
                                    setOpenReStockModal(true);
                                }}
                            />
                            <Button
                                isLoading={updateShipment}
                                btnText="Add uploaded file to shipment"
                                onClick={handleUpdateShipment}
                                btnClassname="!px-4 !py-2.5 "
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={pieChartModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10 max-sm:p-5"}
                tailwindClassName="w-[54%] max-lg:w-[95%]"
                closeModal={() => {
                    clearWarehouseFilter();
                    handleClearFilterTwo();
                    setDateRange("all");
                    setPieChartModal(false);
                }}
            >
                <div className="bg-white shadow-cardShadow relative rounded-[8px]">
                    <div className="flex items-center justify-between px-6 py-4 border-b border-slate-200">
                        <h4 className="text-lg font-rocGroteskMedium">Inventory per channel</h4>
                        <i
                            onClick={() => {
                                clearWarehouseFilter();
                                handleClearFilterTwo();
                                setDateRange("all");
                                setPieChartModal(false);
                            }}
                            className="ri-close-fill text-[24px] cursor-pointer "
                        ></i>
                    </div>

                    <div className="py-4 px-6">
                        <div className="flex items-center space-x-2">
                            <Dropdown
                                handleChange={(name, value) => {
                                    console.log("first", name, value);
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between whitespace-nowrap h-[40px]  py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                        <div className="flex items-center space-x-1">
                                            <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                            <span
                                                className={`text-g-75 font-rocGroteskMedium text-[13px]`}
                                            >
                                                {truncate(warehouseValue?.name, { length: 16 }) ||
                                                    "Warehouses"}
                                            </span>
                                        </div>
                                        <div className="flex items-center space-x-1">
                                            {warehouseValue?.name && (
                                                <i
                                                    onClick={clearWarehouseFilter}
                                                    className="ri-close-circle-line text-slate-500"
                                                ></i>
                                            )}
                                            <i className="ri-arrow-down-s-line text-g-75"></i>
                                        </div>
                                    </div>
                                }
                                search={
                                    <div>
                                        <TextInput
                                            value={warehouseSearch}
                                            name={"search"}
                                            type={"text"}
                                            required={false}
                                            onChange={handleWarehouseSearch}
                                            onInput={debounceChange}
                                            inputPlaceholder="Search warehouse"
                                            containerClassname=""
                                            // inputContainerClassname={"!border-none !rounded-none "}
                                            inputClassName="w-full"
                                        />
                                    </div>
                                }
                                // dropdownClassName=""
                                wholeContainerClass="!w-[33.33%]"
                                dropdownContainerClasses={`shadow-cardShadow-4 !w-full border border-slate-100 left-[-115px] rounded !max-h-fit`}
                                dropdownOptions={[
                                    {
                                        customChild: (
                                            <div>
                                                <div className="max-h-[280px]">
                                                    {allWarehouses?.map((warehouse) => {
                                                        return (
                                                            <div
                                                                key={warehouse?._id}
                                                                onClick={() =>
                                                                    handleWarehouseFilterChange(
                                                                        warehouse?.warehouseName,
                                                                        warehouse?._id
                                                                    )
                                                                }
                                                            >
                                                                <div
                                                                    className={`flex items-center space-x-3 px-3 py-2 hover:bg-slate-100 cursor-pointer ${
                                                                        warehouseValue?.value ===
                                                                            warehouse?._id &&
                                                                        "bg-slate-100"
                                                                    }`}
                                                                >
                                                                    <i className="ri-map-pin-line text-base text-g-75 "></i>
                                                                    <div>
                                                                        <span
                                                                            className={`block text-g-75 text-sm font-rocGroteskMedium ${
                                                                                warehouse?.address
                                                                                    ?.address &&
                                                                                "mb-[2px]"
                                                                            }`}
                                                                        >
                                                                            {truncate(
                                                                                warehouse?.warehouseName,
                                                                                { length: 40 }
                                                                            )}
                                                                        </span>
                                                                        <span className="block text-g-75 text-sm font-rocGroteskMedium ">
                                                                            {truncate(
                                                                                warehouse?.address
                                                                                    ?.address,
                                                                                { length: 40 }
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        ),
                                    },
                                ]}
                            />

                            <Dropdown
                                handleChange={(name, value) => {
                                    console.log("first", name, value);
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between whitespace-nowrap h-[40px]  py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                        <span
                                            className={`text-g-75 font-rocGroteskMedium text-[13px]`}
                                        >
                                            {truncate(selectedProduct?.name, { length: 20 }) ||
                                                "All products"}
                                        </span>
                                        <div className="flex items-center space-x-1">
                                            {selectedProduct?.name && (
                                                <i
                                                    onClick={handleClearFilterTwo}
                                                    className="ri-close-circle-line text-slate-500"
                                                ></i>
                                            )}
                                            <i className="ri-arrow-down-s-line text-g-75"></i>
                                        </div>
                                    </div>
                                }
                                search={
                                    <div>
                                        <TextInput
                                            value={searchTwo}
                                            name={"search"}
                                            type={"text"}
                                            required={false}
                                            onChange={handleSearchTwo}
                                            onInput={handleDebouncedChangeTwo}
                                            inputPlaceholder="Search warehouse"
                                            containerClassname=""
                                            // inputContainerClassname={"!border-none !rounded-none "}
                                            inputClassName="w-full"
                                        />
                                    </div>
                                }
                                wholeContainerClass="!w-[33.33%]"
                                dropdownContainerClasses={`shadow-cardShadow-4 !w-full !max-w-full min-w-full border border-slate-100  rounded !max-h-fit`}
                                dropdownOptions={[
                                    {
                                        customChild: (
                                            <div>
                                                <div className="max-h-[280px]">
                                                    {productSearchList?.map((product) => {
                                                        return (
                                                            <div
                                                                key={product?._id}
                                                                onClick={() =>
                                                                    handleFilterChangeTwo(
                                                                        product?.productName,
                                                                        product?._id
                                                                    )
                                                                }
                                                            >
                                                                <div
                                                                    className={`flex items-center space-x-3 px-3 py-2 hover:bg-slate-100 cursor-pointer `}
                                                                >
                                                                    <div>
                                                                        <div className="flex items-center space-x-3">
                                                                            {product
                                                                                ?.productImageDetails
                                                                                ?.productImages?.[0] ? (
                                                                                <VendorAvatar
                                                                                    imageSrc={
                                                                                        product
                                                                                            ?.productImageDetails
                                                                                            ?.productImages?.[0]
                                                                                    }
                                                                                    size={24}
                                                                                    name={
                                                                                        product?.productName
                                                                                    }
                                                                                    containerClassname="!rounded-md"
                                                                                />
                                                                            ) : (
                                                                                <div className="w-6 h-6 rounded flex items-center justify-center bg-slate-200 ">
                                                                                    <i className="ri-box-2-fill text-sm text-slate-500"></i>
                                                                                </div>
                                                                            )}
                                                                            <div>
                                                                                <span className="block text-xs font-rocGroteskMedium">
                                                                                    {truncate(
                                                                                        product?.sku,
                                                                                        {
                                                                                            length: 30,
                                                                                        }
                                                                                    )}
                                                                                </span>
                                                                                <span className="block text-[10px] text-slate-500 font-rocGroteskMedium">
                                                                                    {truncate(
                                                                                        product?.productName,
                                                                                        {
                                                                                            length: 50,
                                                                                        }
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        ),
                                    },
                                ]}
                            />

                            <Dropdown
                                handleChange={(name, value) => {
                                    console.log("first", name, value);
                                    setDateRange(value);
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between whitespace-nowrap h-[40px] py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                        <span
                                            className={`text-g-75 font-rocGroteskMedium text-[13px] capitalize`}
                                        >
                                            {dateRange === "all" ? "All time" : dateRange}
                                        </span>
                                        <i className="ri-arrow-down-s-line text-g-75"></i>
                                    </div>
                                }
                                // dropdownItemsClasses={`!px-0 !py-0`}
                                wholeContainerClass="!w-[33.33%]"
                                dropdownContainerClasses={`shadow-cardShadow-4 !w-full border border-slate-100  rounded !max-h-fit`}
                                dropdownOptions={[
                                    {
                                        label: "All time",
                                        value: "all",
                                    },
                                    {
                                        label: "Daily",
                                        value: "daily",
                                    },
                                    {
                                        label: "Weekly",
                                        value: "weekly",
                                    },
                                    {
                                        label: "Monthly",
                                        value: "monthly",
                                    },
                                    {
                                        label: "Yearly",
                                        value: "yearly",
                                    },
                                ]}
                            />
                        </div>

                        <div className="">
                            <div className="relative ">
                                <ReactECharts option={pieChartTwoOptions} />

                                <div className="absolute top-[28%] left-0 right-0 m-auto w-[130px] h-[130px] rounded-full font-rocGroteskMedium flex flex-col items-center justify-center bg-white shadow-[0px_4px_15.6px_-4px_#1018281A]">
                                    <span className="block text-[12px] text-slate-500">
                                        {truncate(warehouseValue?.name, { length: 15 }) ||
                                            "All warehouses"}
                                    </span>
                                    <span className="block text-[20px] text-g-75 leading-[40px]">
                                        {millify(salesBreakdown.totalStock, {
                                            precision: 2,
                                        })}
                                    </span>
                                    <span className="block text-[12px] text-slate-500">Units</span>
                                </div>
                            </div>

                            <div className="max-h-[220px] overflow-y-auto">
                                {salesBreakdown?.channelData?.map((channel, idx) => {
                                    return (
                                        <div
                                            key={channel?._id + String(idx)}
                                            className="flex justify-between items-center mb-3"
                                        >
                                            <div className="flex items-center space-x-1.5">
                                                <span
                                                    className={`w-[14px] h-[14px] rounded-[2px]`}
                                                    style={{ backgroundColor: COLORS[idx] }}
                                                ></span>
                                                <span className="text-slate-500 font-rocGroteskMedium text-sm">
                                                    {channel?.warehouseName}
                                                </span>
                                            </div>
                                            <span className="block text-center text-g-75 font-rocGroteskMedium text-base">
                                                {formatMoney(true).format(channel?.totalStock)}{" "}
                                                units
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default ProductList;
