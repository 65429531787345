import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const vendorService = {
    getLdbVendors,
    getSingleVendor,
    authenticateGmail,
    connectGoogleWorkspace,
    getAllChannels,
    getVendorsByOrderType,
    getOnboardedVendors,
    getSuggestedVendors,
    authenticateMicrosoftAcct,
    connectMicrosoftWorkspace,
    getVendorPo,
    manageConnection,
    getVendorActivities,
    authenticateGmailSettings,
    authenticateMicrosoftAcctSettings,
    connectMicrosoftWorkspaceSettings,
    connectGoogleWorkspaceSettings,
    getVendorByCategory,
    addVendor,
    updateVendor,
    listVendors,
    supplierAnalytics,
    supplierProduct,
    supplierOngoingPo,
    supplierQuotes,
    supplierDetails,
    deleteSupplier,
    supplierReturnedGoods,
    supplierProductHistory,
};

async function getLdbVendors(vendorLimit?: string | number, search?: string, status?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v2/ldb/vendor?limit=${vendorLimit}${
            Boolean(search) ? "&search=" + search : ""
        }${Boolean(status) ? "&status=" + status : ""}`,
        requestOptions
    );
    return res;
}

async function getOnboardedVendors() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/onboarded`,
        requestOptions
    );
    return res;
}

async function getSuggestedVendors() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/suggestions`,
        requestOptions
    );
    return res;
}

async function getSingleVendor(vendorId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/single?vendorId=${vendorId}`,
        requestOptions
    );
    return res;
}

async function authenticateGmail() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/google/authenticate`,
        requestOptions
    );
    return res;
}
async function authenticateGmailSettings() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/google/authenticate/settings`,
        requestOptions
    );
    return res;
}

async function connectGoogleWorkspace(reqObj: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/google/connect`,
        requestOptions
    );

    return res;
}
async function connectGoogleWorkspaceSettings(
    reqObj: Record<string, any> | Array<Record<string, any>>
) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/google/connect/settings`,
        requestOptions
    );

    return res;
}
async function authenticateMicrosoftAcct() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/microsoft/authenticate`,
        requestOptions
    );
    return res;
}

async function authenticateMicrosoftAcctSettings() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/microsoft/authenticate/settings`,
        requestOptions
    );
    return res;
}
async function manageConnection(reqObj: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/manage/connection`,
        requestOptions
    );
    return res;
}
async function connectMicrosoftWorkspace(reqObj: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/microsoft/connect`,
        requestOptions
    );

    return res;
}
async function connectMicrosoftWorkspaceSettings(
    reqObj: Record<string, any> | Array<Record<string, any>>
) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/microsoft/connect/settings`,
        requestOptions
    );

    return res;
}

async function getAllChannels() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/channels`,
        requestOptions
    );

    return res;
}

async function getVendorsByOrderType(orderType?: string, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/suggested?orderType=${orderType}${
            search ? "&search=" + search : ""
        }`,
        requestOptions
    );

    return res;
}

async function getVendorPo(vendorId: string, limit?: string | number) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/po/list/${vendorId}?limit=${limit}`,
        requestOptions
    );

    return res;
}

async function getVendorActivities(vendorId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendorActivity/?id=${vendorId}`,
        requestOptions
    );

    return res;
}
async function getVendorByCategory(category: string, startDate: string, endDate: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        category,
        startDate,
        endDate,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/category?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );

    return res;
}
async function addVendor(reqObj: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v2/ldb/vendor/add`,
        requestOptions
    );

    return res;
}

async function updateVendor(reqObj: Record<string, any> | Array<Record<string, any>>, id: string) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v2/ldb/vendor/update/${id}`,
        requestOptions
    );

    return res;
}

async function listVendors(search?: string, risk?: string, limit?: number, page?: number) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        page,
        search,
        risk,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v2/ldb/vendor?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}
async function supplierAnalytics(startDate?: string, endDate?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        startDate,
        endDate,
    });
    const res = await authService.apiGate(
        `${config.API_URL}/api/v2/ldb/vendor/analytics?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function supplierProduct(supplierId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/vendor/${supplierId}/products`,
        requestOptions
    );
    return res;
}

async function supplierOngoingPo(supplierId: string, limit) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/po/vendor/${supplierId}/ongoing-po?limit=${limit}`,
        requestOptions
    );
    return res;
}

async function supplierQuotes(supplierId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/vendor/${supplierId}/quotes`,
        requestOptions
    );
    return res;
}

async function supplierDetails(supplierId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v2/ldb/vendor/${supplierId}/info`,
        requestOptions
    );
    return res;
}
async function deleteSupplier(supplierId: string) {
    const requestOptions = {
        method: "DELETE",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v2/ldb/vendor/${supplierId}/delete`,
        requestOptions
    );
    return res;
}
async function supplierProductHistory(supplierId: string, inventoryId) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/vendor/${supplierId}/product/${inventoryId}`,
        requestOptions
    );
    return res;
}

async function supplierReturnedGoods(supplierId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v2/ldb/vendor/${supplierId}`,
        requestOptions
    );
    return res;
}
