import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer";
import rocGroteskLight from "./../../../../../assets/fonts/rocGrotesk/RocGrotesk-Light.otf";
import rocGroteskRegular from "./../../../../../assets/fonts/rocGrotesk/RocGrotesk-Regular.otf";
import rocGroteskMedium from "./../../../../../assets/fonts/rocGrotesk/RocGrotesk-Medium.otf";
import rocGroteskBold from "./../../../../../assets/fonts/rocGrotesk/RocGrotesk-Bold.otf";
import moment from "moment";
import { getCurrencyFromCurrencyCode } from "helpers";

Font.register({
    family: "roc-grotesk",
    fonts: [
        {
            src: rocGroteskLight,
            fontWeight: "light",
        },
        {
            src: rocGroteskRegular,
            fontWeight: "normal",
        },
        {
            src: rocGroteskMedium,
            fontWeight: "medium",
        },
        {
            src: rocGroteskBold,
            fontWeight: "bold",
        },
    ],
});

Font.register({
    family: "Roboto",
    fonts: [
        {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
            fontWeight: 400,
        },
    ],
});

const signFix = StyleSheet.create({
    page: {
        fontFamily: "Roboto",
        fontWeight: 400,
    },
});


const styles = StyleSheet.create({
    page: {
        flexDirection: "row",
        backgroundColor: "#fff",
        color: "#000",
        fontWeight: "400",
        fontFamily: "roc-grotesk",
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    coverPage: {
        width: "100%",
        backgroundColor: "#0B213A",
        color: "#fff",
        flexDirection: "row",
    },
    image: {
        width: 200,
        height: 200,
        margin: 10,
    },
    boldText: {
        fontWeight: "bold",
    },
    footer: {
        paddingTop: 150,
        paddingLeft: 30,
        backgroundColor: "#0B213A",
        color: "#fff",
        width: "100%",
    },
    footerLogo: {
        paddingBottom: 80,
        width: "7%",
    },
    footerText: {
        paddingBottom: 136,
    },
    divider1: {
        backgroundColor: "#12ad68",
        height: 15,
        width: "95%",
    },
    divider2: {
        backgroundColor: "#ceaa0c",
        height: 15,
        width: "95%",
        marginTop: 100,
    },
    flex1: {
        width: "60%",
        paddingTop: 140,
    },
    flex2: {
        flexDirection: "column",
        width: "40%",
        paddingTop: 112,
        alignItems: "center",
        gap: 8,
    },
    logo1: {
        marginLeft: 10,
        paddingTop: 50,
        marginBottom: 15,
    },
    chartName: {
        marginLeft: 40,
    },
    businessLogo: {
        height: 80,
        marginBottom: 26,
    },
    times: {
        width: 18,
    },
    logo2: {
        marginTop: 26,
        width: 100,
    },
    imgWidth: {
        width: "80%",
        marginBottom: 40,
    },
    flex: {
        display: "flex",
        gap: "2",
    },
    rowDirection: {
        flexDirection: "row",
    },
    colDirection: {
        flexDirection: "column",
    },
    flexWrap: {
        flexWrap: "wrap",
    },
    alignItems: {
        alignItems: "center",
    },
    chartDiv: {
        height: "70%",
        width: "100%",
        marginTop: 20,
        marginBottom: 20,
    },
    chartValueContainer: {
        fontSize: "18",
    },
    chartValueItem: {
        marginRight: "20",
    },
    graphValues: {
        color: "#334155",
        fontSize: 16,
    },
    pageLayout: {
        padding: "40",
    },
    container: { flexDirection: "row", alignItems: "center", gap: "22px" },
    text1: {
        color: "#0F172A",
        fontSize: 18,
        fontWeight: "medium",
    },
    infoContainer: {
        borderRadius: 4,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 5,
        borderWidth: "1px",
        borderColor: "#e2e8f0",
        borderStyle: "solid",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: 4,
    },
    icon: {
        fontSize: "18pt",
        color: "#fff",
        marginRight: "3px",
        backgroundColor: "#CBD5E1",
        width: "16",
        height: "16",
        borderRadius: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    text2: {
        color: "#142837",
        fontSize: 14,
        fontWeight: "medium",
    },
    text3: {
        color: "#142837",
        fontSize: 16,
        fontWeight: "medium",
    },
    summarySection: {
        flexDirection: "row",
        backgroundColor: "#fff",
        color: "#000",
        fontWeight: "400",
    },
    card: {
        border: "0.8px solid #e0e0e0",
        borderRadius: "8px",
        padding: "15px",
        width: "192px",
        backgroundColor: "#fff",
        margin: "10px",
        color: "#000",
        height: "100pt",
    },
    cardTitle: {
        fontSize: "12pt",
        marginBottom: "10px",
        color: "#374151",
    },
    cardValue: {
        // fontSize: "20pt",
        fontWeight: "medium",
        marginBottom: "7px",
        fontSize: 40,
        color: "#22214c",
        marginTop: "7pt",
    },
    cardPercentage: {
        fontSize: "12pt",
        color: "#757575",
    },
    icon2: {
        width: "20px",
        height: "20px",
        marginRight: "5px",
    },
});

const cardStyles = StyleSheet.create({
    container: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "start",
        gap: 10,
    },
    titleFlex: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        gap: 8,
    },
    card: {
        width: "auto",
        borderRadius: "4px",
        border: "1 solid #CBD5E1",
        paddingLeft: "7px",
        paddingRight: "25px",
        paddingTop: "7px",
        marginBottom: "10px",
    },
    cardHeader: {
        flexDirection: "row",
        marginBottom: "14px",
        alignItems: "center",
    },
    image: {
        marginRight: "5px",
    },
    cardTitle: {
        fontWeight: "medium",
        fontSize: 10,
        color: "#475569",
    },
    cardValue: {
        fontSize: "20px",
        fontWeight: "normal",
        color: "#22214C",
    },
    cardPercentage: {
        flexDirection: "row",
        alignItems: "center",
        gap: 1,
        marginBottom: 4,
    },
    percentageImage: {
        marginRight: "5",
    },
    percentageText: {
        fontSize: 8,
        color: "#89939B",
        fontWeight: "medium",
    },
    icon: {
        width: 12,
    },
    pIcon: {
        height: 6,
    },
});
const titleStyles = StyleSheet.create({
    heading: {
        fontSize: "18px",
        fontWeight: "medium",
    },
    date: {
        fontSize: "12px",
        fontWeight: "medium",
    },
    section: {
        marginBottom: "32px",
    },
    divider: {
        backgroundColor: "#F1F5F9",
        height: "1px",
        width: "100%",
    },
});

const tableStyles = StyleSheet.create({
    section: {
        marginTop: 20,
        marginBottom: 20,
    },
    headingSection: {
        marginBottom: 2,
        color: "#334155",
        fontWeight: "medium",
    },
    heading: {
        fontSize: 16,
        marginBottom: "16px",
        color: "#1E293B",
        borderBottom: "1px solid #F1F5F9",
    },
    container: {
        width: "100%",
        backgroundColor: "white",
    },
    tableBody: {
        borderLeft: "1 solid #E2E8F0",
        borderRight: "1 solid #E2E8F0",
        borderBottom: "1 solid #E2E8F0",
    },
    header: {
        backgroundColor: "#F8FAFC",
        flexDirection: "row",
        justifyContent: "space-between",
        borderLeft: "1 solid #E2E8F0",
        borderTop: "1 solid #E2E8F0",
        borderRight: "1 solid #E2E8F0",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        paddingTop: 18,
        paddingLeft: 16,
        textAlign: "left",
        fontSize: 10,
        color: "#334155",
        fontWeight: "medium",
    },
    row: {
        flexDirection: "row",
        borderTop: "1 solid #E2E8F0",
        justifyContent: "space-between",
        fontSize: 10,
        paddingTop: 18,
        paddingLeft: 16,
        color: "#3B4C58",
        fontWeight: "medium",
    },
    cellWdith: {
        width: "23%",
    },
    cellWdith2: {
        width: "10%",
    },
    cellWdith3: {
        width: "12%",
    },
    rounded: {
        borderRadius: 9999,
        width: 7,
        height: 7,
    },
    pending: {
        backgroundColor: "#F59E0B",
    },
    approved: {
        backgroundColor: "#16C6A4",
    },
    rejected: {
        backgroundColor: "#ff3347",
    },
    statusFlex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        gap: 6,
    },
    imgMargin: {
        marginTop: -4,
    },
    cellFlex: {
        flexDirection: "row",
        gap: 4,
        alignItems: "center",
    },
    profileImg: {
        width: 16,
        marginTop: -4,
    },
});


const AnalyticsPdf = ({
    year,
    chartName,
    title,
    businessLogo,
    chart,
    showPo,
    chartValue1,
    chartValue2,
    rfqDetail,
    summary,
    summaryDuration,
    pendingPO,
    approvedPO,
    rejected,
    dateRange,
    showDateRange,
}) => {
    return (
        <Document>
            <Page size="A4" style={styles.page} orientation="landscape">
                <View style={styles.coverPage}>
                    <View style={styles.flex1}>
                        <View style={styles.divider1} />
                        <View style={styles.divider2} />
                        <Image
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702357166/synthAlly_dcohmp.png"
                            style={styles.logo1}
                        />
                        <Text style={styles.chartName}>
                            {chartName} report - {year}
                        </Text>
                    </View>
                    <View style={styles.flex2}>
                        <Image src={businessLogo} style={styles.businessLogo} />
                        <Image
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702359704/X_mfthox.png"
                            style={styles.times}
                        />
                        <Image
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702357366/synthAllylogo_fhimzk.png"
                            style={styles.logo2}
                        />
                    </View>
                </View>
            </Page>
            {showPo && (
                <Page size="A4" style={[styles.page, styles?.pageLayout]} orientation="landscape">
                    <View>
                        <View style={[titleStyles.section]}>
                            <Text style={titleStyles.heading}>{`${chartName} statistics`}</Text>
                            <Text style={titleStyles.date}>{dateRange}</Text>
                        </View>
                        <View style={cardStyles.container}>
                            {summary?.map((item, index) => (
                                <View key={index} style={cardStyles.card}>
                                    <View style={cardStyles.cardHeader}>
                                        {item?._id === "in-progress" ? (
                                            <View style={cardStyles.titleFlex}>
                                                <Image
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702604202/fulfilled_f8jeqa.png"
                                                    style={[cardStyles.icon, tableStyles.imgMargin]}
                                                />
                                                <Text style={cardStyles.cardTitle}>
                                                    Fulfilled purchase orders
                                                </Text>
                                            </View>
                                        ) : item?._id === "completed" ? (
                                            <View style={cardStyles.titleFlex}>
                                                <Image
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702603660/active_bmjctx.png"
                                                    style={[cardStyles.icon, tableStyles.imgMargin]}
                                                />
                                                <Text style={cardStyles.cardTitle}>
                                                    Active purchase orders
                                                </Text>
                                            </View>
                                        ) : item?._id === "pending" ? (
                                            <View style={cardStyles.titleFlex}>
                                                <Image
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702604351/pending_gtlpnd.png"
                                                    style={[cardStyles.icon, tableStyles.imgMargin]}
                                                />
                                                <Text style={cardStyles.cardTitle}>
                                                    Pending purchase orders
                                                </Text>
                                            </View>
                                        ) : (
                                            item?._id === "rejected" && (
                                                <View style={cardStyles.titleFlex}>
                                                    <Image
                                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702604154/cancelled_ubagit.png"
                                                        style={[
                                                            cardStyles.icon,
                                                            tableStyles.imgMargin,
                                                        ]}
                                                    />
                                                    <Text style={cardStyles.cardTitle}>
                                                        Cancelled purchase orders
                                                    </Text>
                                                </View>
                                            )
                                        )}
                                    </View>
                                    <Text style={cardStyles.cardValue}>{item.count}</Text>
                                    <View
                                        style={[cardStyles.cardPercentage, tableStyles.statusFlex]}
                                    >
                                        {item.percentage > 0 ? (
                                            <Image
                                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702621597/arrowUp_wfxvaj.png"
                                                style={[cardStyles.pIcon, tableStyles.imgMargin]}
                                            />
                                        ) : (
                                            <Image
                                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702621381/arrowDown_l4wmfy.png"
                                                style={[cardStyles.pIcon, tableStyles.imgMargin]}
                                            />
                                        )}

                                        <Text style={cardStyles.percentageText}>
                                            {`${Math.abs(item.percentage)}% ${
                                                item.percentage > 0 ? "up" : "down"
                                            }  ${
                                                showDateRange
                                                    ? dateRange
                                                    : `this ${summaryDuration}`
                                            } `}
                                        </Text>
                                    </View>
                                </View>
                            ))}
                        </View>

                        <View>
                            {pendingPO !== null && (
                                <View style={tableStyles.section}>
                                    <View style={tableStyles.headingSection}>
                                        <Text style={tableStyles.heading}>Pending PO </Text>
                                    </View>
                                    <View style={tableStyles.container}>
                                        <View style={tableStyles.header}>
                                            <Text style={tableStyles.cellWdith2}>PO Number</Text>
                                            <Text style={tableStyles.cellWdith}>
                                                Vendor (Seller)
                                            </Text>
                                            <Text style={tableStyles.cellWdith}>Buyer</Text>
                                            <Text style={tableStyles.cellWdith3}>Issue Date</Text>
                                            <Text style={tableStyles.cellWdith3}>Due Date</Text>
                                            <Text style={tableStyles.cellWdith2}>Total Amount</Text>
                                            <Text style={tableStyles.cellWdith2}>Status</Text>
                                        </View>
                                        <View style={tableStyles.tableBody}>
                                            {pendingPO.map((item) => (
                                                <View style={tableStyles.row}>
                                                    <Text style={tableStyles.cellWdith2}>
                                                        {`PR ${item.taId}`}
                                                    </Text>
                                                    <View
                                                        style={[
                                                            tableStyles.cellFlex,
                                                            tableStyles.cellWdith,
                                                        ]}
                                                    >
                                                        {item.vendor?.profile?.avatar ? (
                                                            <Image
                                                                src={item.vendor?.profile?.avatar}
                                                                style={tableStyles?.profileImg}
                                                            />
                                                        ) : (
                                                            <Image
                                                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1694624630/Tradeally_2.0/undefined_-_Imgur_1_prpxel.jpg"
                                                                style={tableStyles?.profileImg}
                                                            />
                                                        )}
                                                        <Text>
                                                            {` ${item?.vendor?.firstName} ${item?.vendor?.lastName}`}
                                                        </Text>
                                                    </View>

                                                    <Text style={tableStyles.cellWdith}>
                                                        {item?.order?.customerDetails?.companyName}
                                                    </Text>
                                                    <Text style={tableStyles.cellWdith3}>
                                                        {moment(item.issuedDate).format(
                                                            "MMM DD, YYYY"
                                                        )}
                                                    </Text>
                                                    <Text style={tableStyles.cellWdith3}>
                                                        {moment(item.dueDate).format(
                                                            "MMM DD, YYYY"
                                                        )}
                                                    </Text>
                                                    <Text style={[tableStyles.cellWdith2, signFix.page]}>
                                                        {item?.totalAmount?.currency &&
                                                            getCurrencyFromCurrencyCode(
                                                                item?.totalAmount?.currency
                                                            )}

                                                        {` ${
                                                            item?.totalAmount?.amount
                                                                ? Math.round(
                                                                      item?.totalAmount?.amount
                                                                  ).toLocaleString()
                                                                : ""
                                                        }`}
                                                    </Text>
                                                    <View
                                                        style={[
                                                            tableStyles.statusFlex,
                                                            tableStyles.cellWdith2,
                                                        ]}
                                                    >
                                                        <Text
                                                            style={[
                                                                tableStyles.rounded,
                                                                tableStyles.pending,
                                                                tableStyles.imgMargin,
                                                            ]}
                                                        ></Text>
                                                        <Text>Pending</Text>
                                                    </View>
                                                </View>
                                            ))}
                                        </View>
                                    </View>
                                </View>
                            )}
                        </View>
                        <View>
                            {approvedPO !== null && (
                                <View style={tableStyles.section}>
                                    <View style={tableStyles.headingSection}>
                                        <Text style={tableStyles.heading}>Approved PO </Text>
                                    </View>
                                    <View style={tableStyles.container}>
                                        <View style={tableStyles.header}>
                                            <Text style={tableStyles.cellWdith2}>PO Number</Text>
                                            <Text style={tableStyles.cellWdith}>
                                                Vendor (Seller)
                                            </Text>
                                            <Text style={tableStyles.cellWdith}>Buyer</Text>
                                            <Text style={tableStyles.cellWdith3}>Issue Date</Text>
                                            <Text style={tableStyles.cellWdith3}>Due Date</Text>
                                            <Text style={tableStyles.cellWdith2}>Total Amount</Text>
                                            <Text style={tableStyles.cellWdith2}>Status</Text>
                                        </View>
                                        <View style={tableStyles.tableBody}>
                                            {approvedPO?.map((item) => (
                                                <View style={tableStyles.row}>
                                                    <Text style={tableStyles.cellWdith2}>
                                                        {`PR ${item.taId}`}
                                                    </Text>
                                                    <View
                                                        style={[
                                                            tableStyles.cellFlex,
                                                            tableStyles.cellWdith,
                                                        ]}
                                                    >
                                                        {item.vendor?.profile?.avatar ? (
                                                            <Image
                                                                src={item.vendor?.profile?.avatar}
                                                                style={tableStyles?.profileImg}
                                                            />
                                                        ) : (
                                                            <Image
                                                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1694624630/Tradeally_2.0/undefined_-_Imgur_1_prpxel.jpg"
                                                                style={tableStyles?.profileImg}
                                                            />
                                                        )}
                                                        <Text>
                                                            {` ${item?.vendor?.firstName} ${item?.vendor?.lastName}`}
                                                        </Text>
                                                    </View>

                                                    <Text style={tableStyles.cellWdith}>
                                                        {item?.order?.customerDetails?.companyName}
                                                    </Text>
                                                    <Text style={tableStyles.cellWdith3}>
                                                        {moment(item.issuedDate).format(
                                                            "MMM DD, YYYY"
                                                        )}
                                                    </Text>
                                                    <Text style={tableStyles.cellWdith3}>
                                                        {moment(item.dueDate).format(
                                                            "MMM DD, YYYY"
                                                        )}
                                                    </Text>
                                                    <Text style={tableStyles.cellWdith2}>
                                                        {item?.totalAmount?.amount &&
                                                            ` ${Math.round(
                                                                item?.totalAmount?.amount
                                                            ).toLocaleString()}`}
                                                    </Text>
                                                    <View
                                                        style={[
                                                            tableStyles.statusFlex,
                                                            tableStyles.cellWdith2,
                                                        ]}
                                                    >
                                                        <Text
                                                            style={[
                                                                tableStyles.rounded,
                                                                tableStyles.approved,
                                                                tableStyles.imgMargin,
                                                            ]}
                                                        ></Text>
                                                        <Text> Approved</Text>
                                                    </View>
                                                </View>
                                            ))}
                                        </View>
                                    </View>
                                </View>
                            )}
                        </View>
                        <View>
                            {rejected !== null && (
                                <View style={tableStyles.section}>
                                    <View style={tableStyles.headingSection}>
                                        <Text style={tableStyles.heading}>Cancelled PO </Text>
                                    </View>
                                    <View style={tableStyles.container}>
                                        <View style={tableStyles.header}>
                                            <Text style={tableStyles.cellWdith2}>PO Number</Text>
                                            <Text style={tableStyles.cellWdith}>
                                                Vendor (Seller)
                                            </Text>
                                            <Text style={tableStyles.cellWdith}>Buyer</Text>
                                            <Text style={tableStyles.cellWdith3}>Issue Date</Text>
                                            <Text style={tableStyles.cellWdith3}>Due Date</Text>
                                            <Text style={tableStyles.cellWdith2}>Total Amount</Text>
                                            <Text style={tableStyles.cellWdith2}>Status</Text>
                                        </View>
                                        <View style={tableStyles.tableBody}>
                                            {rejected?.map((item) => (
                                                <View style={tableStyles.row}>
                                                    {}
                                                    <Text style={tableStyles.cellWdith2}>
                                                        {`PR ${item?.taId}`}
                                                    </Text>
                                                    <View
                                                        style={[
                                                            tableStyles.cellFlex,
                                                            tableStyles.cellWdith,
                                                        ]}
                                                    >
                                                        {item.vendor?.profile?.avatar ? (
                                                            <Image
                                                                src={item?.vendor?.profile?.avatar}
                                                                style={tableStyles?.profileImg}
                                                            />
                                                        ) : (
                                                            <Image
                                                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1694624630/Tradeally_2.0/undefined_-_Imgur_1_prpxel.jpg"
                                                                style={tableStyles?.profileImg}
                                                            />
                                                        )}
                                                        <Text>
                                                            {` ${item?.vendor?.firstName} ${item?.vendor?.lastName}`}
                                                        </Text>
                                                    </View>
                                                    <Text style={tableStyles.cellWdith}>
                                                        {item?.order?.customerDetails?.companyName}
                                                    </Text>
                                                    <Text style={tableStyles.cellWdith3}>
                                                        {moment(item?.issuedDate).format(
                                                            "MMM DD, YYYY"
                                                        )}
                                                    </Text>
                                                    <Text style={tableStyles.cellWdith3}>
                                                        {moment(item?.dueDate).format(
                                                            "MMM DD, YYYY"
                                                        )}
                                                    </Text>
                                                    <Text style={tableStyles.cellWdith2}>
                                                        {item?.totalAmount?.amount &&
                                                            ` ${Math.round(
                                                                item?.totalAmount?.amount
                                                            ).toLocaleString()}`}
                                                    </Text>

                                                    <View
                                                        style={[
                                                            tableStyles.statusFlex,
                                                            tableStyles.cellWdith2,
                                                        ]}
                                                    >
                                                        <Text
                                                            style={[
                                                                tableStyles.rounded,
                                                                tableStyles.rejected,
                                                                tableStyles.imgMargin,
                                                            ]}
                                                        ></Text>
                                                        <Text>Rejected</Text>
                                                    </View>
                                                </View>
                                            ))}
                                        </View>
                                    </View>
                                </View>
                            )}
                        </View>
                    </View>
                </Page>
            )}

            {chartValue1 && chartValue2 && chart && (
                <Page
                    size="A4"
                    style={[styles.page, styles?.pageLayout, styles?.flex, styles?.colDirection]}
                    orientation="landscape"
                >
                    <View>
                        <View style={styles.container}>
                            <Text style={styles.text1}>{title}</Text>
                            {rfqDetail && (
                                <View style={styles.infoContainer}>
                                    <Image
                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702603335/info_o0ozgx.png"
                                        style={[cardStyles.icon, tableStyles.imgMargin]}
                                    />

                                    <Text style={styles.text2}>
                                        Requisition to Fulfilled Order ratio:
                                    </Text>
                                    <Text style={styles.text3}>{rfqDetail}</Text>
                                </View>
                            )}
                        </View>
                        <View style={[styles.chartDiv, styles.flex, styles.alignItems]}>
                            <Image src={chart} />
                        </View>
                        <View style={[styles.flex, styles?.rowDirection]}>
                            <View
                                style={[
                                    styles.chartValueContainer,
                                    styles?.flex,
                                    styles?.rowDirection,
                                    styles?.flexWrap,
                                ]}
                            >
                                {chartValue1 &&
                                    chartValue1?.map(({ month, value }) => (
                                        <View
                                            key={month}
                                            style={[
                                                styles.chartValueItem,
                                                styles.flex,
                                                styles?.rowDirection,
                                                styles?.graphValues,
                                            ]}
                                        >
                                            <Text style={styles.boldText}>{month}:</Text>
                                            <Text>{value}</Text>
                                        </View>
                                    ))}
                            </View>
                            <View
                                style={[
                                    styles.chartValueContainer,
                                    styles?.flex,
                                    styles?.rowDirection,
                                    styles?.flexWrap,
                                ]}
                            >
                                {chartValue2 &&
                                    chartValue2?.map(({ month, value }) => (
                                        <View
                                            key={month}
                                            style={[
                                                styles.chartValueItem,
                                                styles?.flex,
                                                styles?.rowDirection,
                                                styles?.graphValues,
                                            ]}
                                        >
                                            <Text style={styles?.boldText}>{month}:</Text>
                                            <Text>{value}</Text>
                                        </View>
                                    ))}
                            </View>
                        </View>
                    </View>
                </Page>
            )}

            <Page size="A4" style={styles.page} orientation="landscape">
                <View style={styles.footer}>
                    <View style={styles.imgWidth}>
                        <Image src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702358337/thanks_yjmk0q.png" />
                    </View>
                    <View style={[styles.flex, styles.rowDirection]}>
                        <Text style={styles.boldText}>Address:</Text>
                        <Text> 7000 North Mopac Expressway, Austin TX 78759</Text>
                    </View>
                    <View style={[styles.flex, styles.rowDirection]}>
                        <Text style={styles.boldText}>Phone Number:</Text>
                        <Text> +18329972582</Text>
                    </View>
                    <View style={[styles.flex, styles.rowDirection]}>
                        <Text style={styles.boldText}>E-Mail:</Text>
                        <Text style={styles.footerText}> hello@tradeally.io</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};
export default AnalyticsPdf;
