import React from "react";
import Loader from "./Loader";

type ButtonProps = {
    btnText?: string;
    type?: "button" | "submit";
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    btnClassname?: string;
    btnCustomContent?: React.ReactNode;
    isLoading?: boolean;
    disabled?: boolean;
    icon?: React.ReactNode;
    btnTextClassName?: string;
    btnType?: string;
};

const Button = ({
    btnText,
    type,
    onClick,
    btnClassname,
    btnCustomContent,
    isLoading,
    disabled,
    icon,
    btnTextClassName,
    btnType,
}: ButtonProps) => {
    return (
        <button
            onClick={(e) => onClick?.(e)}
            type={type ? type : "button"}
            disabled={disabled || isLoading}
            className={`${btnClassname}  w-full flex justify-center py-3 items-center px-4
             bg-y-10 rounded text-sm font-rocGroteskMedium text-black  ${
                 disabled && "opacity-50"
             } `}
        >
            {isLoading ? (
                <Loader color={"inherit"} size={4} />
            ) : // <p>loading</p>
            btnCustomContent ? (
                btnCustomContent
            ) : (
                <>
                    {btnType === "textFirst" ? (
                        <div className="flex space-x-2 items-center">
                            <p className={btnTextClassName}> {btnText}</p>
                            <div> {icon}</div>
                        </div>
                    ) : icon ? (
                        <div className="flex space-x-2 items-center">
                            <div> {icon}</div>
                            {btnText && <p className={btnTextClassName}> {btnText}</p>}
                        </div>
                    ) : (
                        btnText
                    )}
                </>
            )}
        </button>
    );
};

export default Button;
