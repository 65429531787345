/* eslint-disable react-hooks/exhaustive-deps */
import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import CustomMobileTable from "components/common/CustomMobileTable";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import OrderSummaryCardOne from "components/views/Ldb/Dashboard/Orders/OrderSummaryCardOne";
import OrderSummaryCardTwo from "components/views/Ldb/Dashboard/Orders/OrderSummaryCardTwo";
import {getCurrencyFromCurrencyCode, onScroll} from "helpers";
import {debounce, truncate} from "lodash";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {orderActions} from "redux/Ldb/actions";
import {useAppDispatch, useAppSelector} from "../../../../hooks/storeHooks";

const Rfqs = () => {
    const limit = 20;
    const [seeAllModal, setSeeAllModal] = useState(false);
    const [orders, setOrders] = useState([]);
    const [orderTotal, setOrderTotal] = useState(0);
    const [rfqLimit, setRfqLimit] = useState(limit);
    const [orderLimit, setOrderLimit] = useState(limit);
    const [rfqPagination, setRfqPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [orderPagination, setOrderPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [loadMoreRfqs, setLoadMoreRfqs] = useState(false);
    const [loadMoreOrders, setLoadMoreOrders] = useState(false);
    const [rfqs, setRfqs] = useState([]);
    const [filter, setFilter] = useState({
        search: "",
        amount: "",
        vendor: "",
        orderId: "",
    });
    const [debouncedFilter, setDebouncedFilter] = useState({
        search: "",
        amount: "",
        vendor: "",
        orderId: "",
    });

    const dashboardContent = document.getElementById("dashboardContent");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {
        fetchingOrders,
        fetchedOrdersSuccess,
        fetchingRfqs,
        fetchedRfqsSuccess,
        updateOrderSuccess,
    } = useAppSelector((state) => state.order);

    const tableHeader = [
        {title: "RFQ ID/Name", widthClass: "w-[33%]"},
        {title: "Requested by", widthClass: "w-[17%]"},
        {title: "Amount", widthClass: "w-[19%]"},
        {title: "Vendor", widthClass: "w-[25%]"},
        // { title: "", widthClass: "w-[6%]" },
    ];

    const tableBody = rfqs?.map((rfq) => [
        {
            content: (
                <div
                    className={`py-[18px] pr-4 border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    <p className="text-slate-900 ">RFQ {rfq?.taId}</p>
                    <p className="text-slate-500">{rfq?.item?.description}</p>
                </div>
            ),
            cellClickAction: () => {
                navigate(`/dashboard/rfq/${rfq?._id}`);
            },
        },
        {
            content: (
                <div
                    className={`py-[18px]  pr-4 border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    <div className="flex items-center gap-3">
                        <img
                            src={
                                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1694624630/Tradeally_2.0/undefined_-_Imgur_1_prpxel.jpg"
                            }
                            width={24}
                            height={24}
                            alt="avatar logo"
                        />
                        <span>
                            {truncate(rfq?.order?.customerDetails?.companyName, {length: 15})}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-[18px] pr-4 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-900 `}
                >
                    {rfq?.amount
                        ? getCurrencyFromCurrencyCode(rfq?.amount?.from?.currency) +
                          rfq?.amount?.from?.amount +
                          " - " +
                          getCurrencyFromCurrencyCode(rfq?.amount?.from?.currency) +
                          rfq?.amount?.to?.amount
                        : "----"}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-[18px] pr-4 flex items-center h-full border-slate-100 text-sm text-slate-900 `}
                >
                    {rfq?.quoteResponses && rfq?.quoteResponses?.length > 0 ? (
                        <div
                            className={` flex space-x-1 items-center border-slate-100 text-sm text-slate-900 `}
                        >
                            <div className="flex items-center gap-1">
                                {rfq?.quoteResponses?.slice(0, 8)?.map((vendor, idx) => (
                                    <VendorAvatar
                                        key={idx + vendor?.responder?.profile?.avatar}
                                        size={24}
                                        imageSrc={vendor?.responder?.profile?.avatar}
                                        name={vendor?.responder?.profile?.businessName}
                                    />
                                ))}
                            </div>
                            {rfq?.quoteResponses?.slice(8)?.length > 0 && (
                                <div className="h-[32px] flex items-center justify-center w-[32px] border border-white bg-n-20">
                                    <p className="font-rocGroteskMedium text-gm-50 text-[10px] rounded-[4px]">
                                        +{rfq?.quoteResponses?.slice(8)?.length}
                                    </p>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="flex items-center gap-3">
                            <div className="rounded bg-n-20 w-8 h-8 flex items-center justify-center">
                                <i className="ri-time-fill text-slate-500 text-lg"></i>
                            </div>
                            <span>Awaiting vendor responses</span>
                        </div>
                    )}
                </div>
            ),
        },
        // {
        //     content: (
        //         <div
        //             onClick={(e) => e.stopPropagation()}
        //             className={`py-[18px] flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-900 `}
        //         >
        //             <Dropdown
        //                 dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
        //                 dropdownClassname={"!w-full !mb-10 !-mt-7"}
        //                 dropdownContainerClasses={"shadow-cardShadow-2 border-0 rounded !max-h-fit"}
        //                 dropdownItemsClasses={``}
        //                 name={"faq-dropdown"}
        //                 dropdownOptions={[
        //                     {
        //                         label: "Show more",
        //                         value: "empty",
        //                         action: () => {
        //                             console.log("action");
        //                         },
        //                     },
        //                 ]}
        //             />
        //         </div>
        //     ),
        // },
    ]);

    const mobileTableBody = rfqs?.map((rfq, idx) => {
        return {
            cellClickAction: () => {
                navigate(`/dashboard/rfq/${rfq?._id}`);
            },
            topString: "RFQ" + rfq?.taId,
            topContent: <p className="text-sm font-rocGroteskMedium mb-1.5">RFQ {rfq?.taId}:</p>,
            bottomContent: (
                <div className="flex items-center gap-3">
                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                        {truncate(rfq?.item?.description, {length: 15}) || "----"}
                    </p>
                    <p className="flex items-center gap-2 text-sm font-rocGroteskMedium">
                        <VendorAvatar
                            name={rfq?.order?.customerDetails?.companyName}
                            imageSrc={rfq?.order?.customerDetails?.avatar}
                            size={24}
                        />
                        <span>
                            {truncate(rfq?.order?.customerDetails?.companyName, {length: 15})}
                        </span>
                    </p>
                </div>
            ),
            rightIcon: <i className="ri-arrow-right-s-line text-2xl"></i>,
        };
    });

    const handleDebouncedChange = debounce((e) => {
        const {name, value} = e.target;

        setDebouncedFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, 1500);

    const handleSearch = (e) => {
        const {name, value} = e.target;

        setFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const onRfqTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                rfqPagination?.current as number,
                rfqPagination?.number_of_pages as number,
                () => {
                    setRfqLimit(() => rfqLimit + limit);
                    setLoadMoreRfqs(true);
                }
            ),
        [dashboardContent, rfqPagination]
    );

    const onOrderModalScroll = useCallback(
        (orderContainer) =>
            onScroll(
                orderContainer,
                orderPagination?.current as number,
                orderPagination?.number_of_pages as number,
                () => {
                    setOrderLimit(() => orderLimit + limit);
                    setLoadMoreOrders(true);
                }
            ),
        [orderPagination]
    );

    useEffect(() => {
        dispatch(orderActions.getOrders(orderLimit));
        setTimeout(() => {
            setLoadMoreOrders(false);
        }, 800);
    }, [dispatch, updateOrderSuccess, orderLimit]);

    useEffect(() => {
        dispatch(
            orderActions.getRfqs(
                rfqLimit,
                debouncedFilter.search,
                debouncedFilter.vendor,
                debouncedFilter.orderId,
                debouncedFilter.amount
            )
        );
        setTimeout(() => {
            setLoadMoreRfqs(false);
        }, 800);
    }, [dispatch, debouncedFilter, rfqLimit]);

    useEffect(() => {
        if (Boolean(fetchedOrdersSuccess)) {
            setOrders(fetchedOrdersSuccess?.orders);
            setOrderTotal(fetchedOrdersSuccess?.total);
            setOrderPagination({
                current: fetchedOrdersSuccess?.pagination?.current,
                number_of_pages: fetchedOrdersSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedOrdersSuccess]);

    useEffect(() => {
        if (Boolean(fetchedRfqsSuccess)) {
            setRfqs(fetchedRfqsSuccess?.rfqs);
            setRfqPagination({
                current: fetchedRfqsSuccess?.pagination?.current,
                number_of_pages: fetchedRfqsSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedRfqsSuccess]);

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onRfqTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onRfqTableScroll);
        };
    }, [dashboardContent, onRfqTableScroll]);

    return (
        <PageFrame
            containerClassName={"!h-[calc(100vh-108px)]"}
            isLoading={
                (fetchingOrders && orderLimit === limit && !debouncedFilter.search) ||
                (fetchingRfqs && rfqs?.length === 0 && !debouncedFilter.search)
            }
        >
            <div>
                <div className="px-[14px] overflow-auto max-lg:px-0">
                    <div className="mb-4">
                        <BreadCrumb
                            parentCrumb={"Orders"}
                            parentUrl={"/dashboard/rfqs"}
                            childrenCrumb={[{title: "RFQs", path: "/dashboard/rfqs"}]}
                            size="large"
                        />
                    </div>
                    <div>
                        {orders?.length > 0 && (
                            <>
                                <div className="flex items-center justify-between mb-3 max-lg:items-start max-lg:flex-col max-lg:gap-2">
                                    <p className="text-base font-rocGroteskMedium ">
                                        Co-Pilot detected new orders that need RFQs: Here are the
                                        details we've gathered:
                                    </p>
                                    <p
                                        onClick={() => setSeeAllModal(true)}
                                        className="text-sm font-rocGroteskMedium underline cursor-pointer text-g-50 max-lg:flex max-lg:w-full max-lg:justify-end"
                                    >
                                        See all
                                    </p>
                                </div>

                                <div className="grid grid-cols-4 gap-3 mb-8 max-lg:grid-cols-1 max-lg:gap-0 max-lg:bg-white max-lg:rounded max-lg:px-4 max-lg:py-2.5 max-lg:border max-lg:border-slate-100">
                                    {orders?.slice(0, 4)?.map((order, idx) => (
                                        <OrderSummaryCardOne key={idx} index={idx} order={order} />
                                    ))}
                                </div>
                            </>
                        )}

                        <div>
                            <div className="flex items-center justify-between mb-[27px] max-lg:flex-col max-lg:gap-2.5">
                                <div className="flex items-center gap-3 max-lg:w-full">
                                    <TextInput
                                        name={"search"}
                                        value={filter.search}
                                        type={"text"}
                                        inputPlaceholder={"Search by RFQ id (e.g 00014)"}
                                        inputClassName={"!h-[40px] pl-[0px] !mb-0 text-sm "}
                                        onChange={handleSearch}
                                        onInput={handleDebouncedChange}
                                        leftIcon={
                                            <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                        }
                                        inputContainerClassname={
                                            "!rounded-[4px] !w-[428px] max-lg:!w-full !h-[40px] !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                                        }
                                    />

                                    {/* <Dropdown
                                        value={""}
                                        dropdown={
                                            <div className="flex items-center cursor-pointer gap-2 py-1.5 px-3 bg-white rounded shadow-boxShadow-2 border border-n-20">
                                                <i className="ri-filter-3-fill"></i>
                                                <p className="text-[13px] font-rocGroteskMedium">
                                                    Filter
                                                </p>
                                            </div>
                                        }
                                        dropdownContainerClasses={
                                            " left-[-234px] !py-0 top-[49px] shadow-dropdownShadow rounded !border-0"
                                        }
                                        dropdownClassName={"!max-h-fit"}
                                        name={"quickAction"}
                                        dropdownOptions={[
                                            {
                                                customChild: (
                                                    <div className="w-[318px] relative  bg-white p-4 shadow-boxShadow-2 rounded border border-n-20">
                                                        <div className="flex relative flex-col h-fit gap-2">
                                                            <TextInput
                                                                name={"orderId"}
                                                                type={"text"}
                                                                value={filter.orderId}
                                                                placeholder={"Order ID"}
                                                                onChange={handleSearch}
                                                                onInput={handleDebouncedChange}
                                                                required={false}
                                                            />

                                                            <TextInput
                                                                name={"amount"}
                                                                type={"number"}
                                                                onWheel={(e) => e.target.blur()}
                                                                value={filter.amount}
                                                                placeholder={"Amount"}
                                                                onKeyDown={(evt) =>
                                                                    [
                                                                        "e",
                                                                        "E",
                                                                        "+",
                                                                        "-",
                                                                        "ArrowUp",
                                                                        "ArrowDown",
                                                                    ].includes(evt.key) &&
                                                                    evt.preventDefault()
                                                                }
                                                                onChange={handleSearch}
                                                                onInput={handleDebouncedChange}
                                                                required={false}
                                                            />
                                                            <TextInput
                                                                name={"vendor"}
                                                                type={"text"}
                                                                value={filter.vendor}
                                                                placeholder={"Vendor"}
                                                                onChange={handleSearch}
                                                                onInput={handleDebouncedChange}
                                                                required={false}
                                                            />
                                                        </div>
                                                    </div>
                                                ),
                                            },
                                        ]}
                                    /> */}
                                </div>
                                <div className="max-lg:w-full ">
                                    <Button
                                        btnText={"Create RFQs"}
                                        type={"button"}
                                        onClick={() => navigate("/dashboard/rfqs/create")}
                                        btnClassname="!py-2 !px-4"
                                    />
                                </div>
                            </div>
                        </div>

                        {rfqs?.length > 0 ? (
                            <div>
                                <div className="max-lg:hidden">
                                    <CustomTable
                                        tableBody={tableBody}
                                        tableHeader={tableHeader}
                                        isAllSelectable={false}
                                        isScrollable={false}
                                        isCellBordered={false}
                                        headerContainerClass={"!bg-slate-50"}
                                    />
                                </div>
                                <div className="hidden max-lg:block">
                                    <CustomMobileTable data={mobileTableBody} />
                                </div>
                                {loadMoreRfqs && fetchingRfqs && (
                                    <div className="flex my-4 justify-center">
                                        <Loader color="gm-25" size={4} />
                                    </div>
                                )}
                                {rfqPagination?.current === rfqPagination?.number_of_pages && (
                                    <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                        End of list
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                                <div className="space-y-2  text-center">
                                    <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                                    <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                        No RFQs yet
                                    </p>
                                    <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                        Your list of RFQs will be displayed here
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <ModalContainer
                    open={seeAllModal}
                    showCloseIcon={false}
                    tailwindClassName="w-[83%] max-lg:w-[95%]"
                    closeModal={() => {
                        setSeeAllModal(false);
                    }}
                >
                    <div className="bg-white shadow-cardShadow relative rounded-[6px]">
                        <div className="px-6 py-4 flex items-start justify-between border-b border-slate-100">
                            <div className=" w-full">
                                <p className=" text-base font-rocGroteskMedium text-slate-700">
                                    Co-Pilot detected {orderTotal} new potential orders from your
                                    conversation
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-slate-500">
                                    Feel free to modify order details and suggested vendor to match
                                    your customer's needs accurately.
                                </p>
                            </div>
                            <i
                                className="ri-close-fill text-xl cursor-pointer"
                                onClick={() => {
                                    setSeeAllModal(false);
                                }}
                            ></i>
                        </div>
                        <div
                            onScroll={(e) => {
                                const container = e.target;
                                onOrderModalScroll(container);
                            }}
                            className="max-h-[550px] overflow-auto scrollbar-hide"
                        >
                            {orders?.map((order, idx) => (
                                <OrderSummaryCardTwo key={order?._id} id={idx} order={order} />
                            ))}
                            {loadMoreOrders && fetchingOrders && (
                                <div className="flex justify-center my-4">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {orderPagination?.current === orderPagination?.number_of_pages && (
                                <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                    End of list
                                </div>
                            )}
                        </div>
                        <div className="py-4 px-6 border-t border-slate-100">
                            <p className="text-xs text-center text-slate-500">
                                Note: Your data privacy is important to us. The TradeAlly Co-Pilot
                                only analyses your conversations to provide you with RFQ suggestions
                                and vendor recommendations.
                            </p>
                        </div>
                    </div>
                </ModalContainer>
            </div>
        </PageFrame>
    );
};

export default Rfqs;
