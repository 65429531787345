import React, { useEffect, useState } from "react";

type AccordionProps = {
    title: string,
    open?: boolean,
    children: React.ReactNode,
    containerClass?: string,
    titleContainerClass?: string,
    titleClass?: string,
    dropdownIconClass?: string,
};

const Accordion = ({
    title,
    open,
    children,
    containerClass,
    titleContainerClass,
    titleClass,
    dropdownIconClass,
}: AccordionProps) => {
    const [accordionOpen, setAccordionOpen] = useState(open || false);

    useEffect(() => {
        if (open) setAccordionOpen(open);
    }, [open]);

    return (
        <div className={`mb-8 ${containerClass}`}>
            <div
                onClick={() => setAccordionOpen((prev) => !prev)}
                className={`flex items-center justify-between pb-2.5 border-b ${
                    accordionOpen ? "mb-[14px]" : "mb-0"
                } border-slate-100 cursor-pointer  ${titleContainerClass}`}
            >
                <div className={`text-sm font-rocGroteskMedium ${titleClass}`}>{title}</div>
                <i
                    className={`ri-arrow-down-s-line ${
                        accordionOpen && "rotate-180"
                    } transition-transform delay-100 duration-100 ${dropdownIconClass}`}
                ></i>
            </div>
            <div
                className={`${
                    !accordionOpen ? "h-0 opacity-0 hidden" : "h-full opacity-100 block"
                } transition-all delay-100 duration-100`}
            >
                {children}
            </div>
        </div>
    );
};

export default Accordion;
