import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const businessAnalyticsService = {
    getPurchaseOrderSum,
    getPurchaseOrder,
    getSpendAnalytics,
    getAnalyticsProcurement,
    getAnalyticsProcurementGraph,
    getAnalyticsProcurementInsight,
    getSpendAnalysisCategoryAndSupplier,
};

async function getPurchaseOrderSum(period, startDate, endDate) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        period,
        startDate,
        endDate,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/analytics/summary/purchase-order?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}
async function getPurchaseOrder(period, startDate, endDate) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        period,
        startDate,
        endDate,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/analytics/rfq?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSpendAnalytics(startDate, endDate, vendorId, category) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        startDate,
        endDate,
        vendorId,
        category,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/analytics/vendor-spend?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getAnalyticsProcurement(period, startDate, endDate) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        period,
        startDate,
        endDate,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/analytics/procurement?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getAnalyticsProcurementInsight(period, startDate, endDate) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        period,
        startDate,
        endDate,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/analytics/procurement/insights?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getAnalyticsProcurementGraph(period, startDate, endDate) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        period,
        startDate,
        endDate,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/analytics/procurement/graph?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSpendAnalysisCategoryAndSupplier(startDate, endDate) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        startDate,
        endDate,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/po/list/active-vendors?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}
