import Button from "components/common/Button";
import CreateRfqMethods from "components/common/CreateRfqMethods";
import CustomToast from "components/common/CustomToast";
import ModalContainer from "components/common/ModalContainer";
import { displayFileIcon } from "helpers";
import { isFileSizeLessThanOrEqualTo } from "helpers/compareFileSizeInMb";
import { convertBytes } from "helpers/displayFileSize";
import useGetUserProfile from "hooks/useGetUserProfile";
import { truncate } from "lodash";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { chatActions } from "redux/Ldb/actions";
import { firebaseService } from "services/firebaseService";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/storeHooks";

type InitialScreenProps = {
    selectedOption: string;
    setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
    setStep: React.Dispatch<React.SetStateAction<number>>;
};

const InitialScreen = ({ selectedOption, setSelectedOption, setStep }: InitialScreenProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [profile] = useGetUserProfile();
    const { creatingConversation, createConversationSuccess } = useAppSelector(
        (state) => state.chat
    );
    const [docData, setDocData] = useState<File | null>(null);
    const [attachDocModal, setAttachDocModal] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [docUrl, setDocUrl] = useState({
        name: "",
        url: "",
    });

    const optionsData = [
        {
            title: "Generate with AI",
            subText: "Use the SynthAlly AI Co-Pilot to create and send RFQs to vendors.",
            icon: <i className="ri-sparkling-line text-3xl text-p-50 mb-6"></i>,
            isSelectedValue: "AI",
            action: () => setSelectedOption("AI"),
        },
        {
            title: "Import Document",
            subText: "Use Copilot to create and send RFQs to vendors on your document.",
            icon: <i className="ri-file-upload-line text-3xl text-p-50 mb-6"></i>,
            isSelectedValue: "document",
            action: () => setSelectedOption("document"),
        },
        {
            title: "Create a blank RFQ",
            subText: "Start fresh with a new RFQ from the ground up.",
            icon: <i className="ri-file-2-line text-3xl text-p-50 mb-6"></i>,
            isSelectedValue: "manual",
            action: () => setSelectedOption("manual"),
        },
    ];

    const handleCreateConversation = () => {
        dispatch(
            chatActions.createConversation({
                title: "RFQ Creation",
                receiverType: "co-pilot",
                conversationType: "CoPilotRFQ",
            })
        );
    };

    const handleUpload = (fileName: string, file: File) => {
        if (fileName && file) {
            setUploading(true);
            // setDocData(file);
            firebaseService.uploadFile(
                `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/ai-conversation/${
                    profile?.profile?.businessName
                }/rfqs/${fileName}`,
                file,
                (url) => {
                    setDocUrl({
                        name: fileName,
                        url: url,
                    });
                    handleCreateConversation();
                    setUploading(false);
                }
            );
        }
    };

    useEffect(() => {
        if (Boolean(createConversationSuccess)) {
            navigate(
                `/dashboard/rfqs/create?by=${selectedOption}&conversationId=${createConversationSuccess?.conversation?._id}`,
                {
                    replace: true,
                    state: { doc: docUrl?.url, docName: docUrl?.name },
                }
            );
            dispatch(chatActions.resetCreateConversationSuccess());
        }
    }, [dispatch, navigate, createConversationSuccess, docUrl]);

    return (
        <div className="">
            <div className="py-6 px-8 h-[400px] max-sm:h-auto">
                <p className="font-rocGroteskMedium text-gm-50 text-[22px] mb-6">Create new RFQ</p>
                <CreateRfqMethods optionsData={optionsData} selectedOption={selectedOption} />
            </div>
            <div className="px-6 py-4 border-t border-slate-100 flex justify-end">
                <div className="flex items-center gap-3">
                    <Button
                        btnClassname="!bg-n-20 !text-gm-50 !w-[150px]"
                        btnText={"Back"}
                        onClick={() => {
                            navigate("/dashboard/rfqs");
                        }}
                        type={"button"}
                    />
                    <Button
                        btnText={"Next"}
                        type={"button"}
                        btnClassname={"!w-[150px]"}
                        disabled={!Boolean(selectedOption)}
                        isLoading={creatingConversation}
                        onClick={() => {
                            if (selectedOption === "AI") {
                                handleCreateConversation();
                            } else if (selectedOption === "document") {
                                setAttachDocModal(true);
                            } else {
                                setStep((prev) => prev + 1);
                                navigate(`/dashboard/rfqs/create?by=${selectedOption}`, {
                                    replace: true,
                                });
                            }
                        }}
                    />
                </div>
            </div>
            <ModalContainer
                open={attachDocModal}
                showCloseIcon={false}
                closeModal={() => {
                    setDocData(null);
                    setAttachDocModal(false);
                }}
                tailwindClassName="w-[35%] max-lg:w-[95%]"
            >
                <div className="bg-white flex flex-col rounded-[20px]">
                    <form className="bg-white shadow-cardShadow relative rounded-[20px] py-8 ">
                        <div
                            onClick={() => {
                                setDocData(null);
                                setAttachDocModal(false);
                            }}
                            className="cursor-pointer  px-6 flex justify-end "
                        >
                            <i
                                onClick={() => {
                                    setDocData(null);
                                    setAttachDocModal(false);
                                }}
                                className="ri-close-fill text-gm-45 text-[30px]"
                            ></i>
                        </div>
                        <p className="text-xl font-rocGroteskMedium text-center mb-6">
                            Upload File
                        </p>
                        <div className="relative px-10 ">
                            <div className="relative flex flex-col items-center cursor-pointer justify-center mb-4 py-12 px-6 text-center text-sm rounded border border-dashed border-slate-200">
                                <i className="ri-upload-cloud-2-line text-slate-500 text-4xl mb-4"></i>
                                <p className="mb-1 font-rocGroteskMedium">
                                    <span>Click to upload</span>{" "}
                                    <span className="text-tradeally-neutral-200">
                                        or drag & drop
                                    </span>
                                </p>
                                <p className="text-tradeally-neutral-200  font-rocGroteskMedium">
                                    Supported formats: PDF, CSV, DOCX & TXT. File size limit: 25MB
                                </p>
                                <input
                                    type="file"
                                    accept=".pdf,.doc,.docx,.csv"
                                    className="absolute top-0 w-full outline-none h-full opacity-0 cursor-pointer"
                                    id="uploadDoc"
                                    onChange={(evt) => {
                                        if (
                                            !isFileSizeLessThanOrEqualTo(
                                                evt.target.files?.[0] as File,
                                                25
                                            )
                                        ) {
                                            toast.custom((t) => (
                                                <CustomToast
                                                    t={t}
                                                    message={"File to large"}
                                                    type={"error"}
                                                />
                                            ));
                                            return;
                                        }

                                        if (evt.target.files?.[0]) {
                                            setDocData(evt.target.files?.[0] as File);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {docData && (
                            <div className="mt-4 p-4 rounded border border-slate-200 mx-10 flex items-center gap-4">
                                {displayFileIcon(
                                    docData?.type?.split("/")?.slice(-1)?.[0],
                                    undefined
                                )}
                                <div className="w-full flex justify-between items-start">
                                    <div>
                                        <p className="text-sm font-rocGroteskMedium ">
                                            {truncate(docData?.name, { length: 34 })}
                                        </p>
                                        <p className="text-xs font-rocGroteskMedium text-tradeally-neutral-200">
                                            {convertBytes(docData?.size)}
                                        </p>
                                    </div>
                                    <i
                                        onClick={() => {
                                            const loading = uploading || creatingConversation;
                                            if (!loading) {
                                                setDocData(null);
                                            }
                                        }}
                                        className="ri-delete-bin-line text-slate-500 cursor-pointer"
                                    ></i>
                                </div>
                            </div>
                        )}
                        <div className="px-10 mt-6">
                            <Button
                                btnText={`Attach document`}
                                type={"button"}
                                btnClassname={" mb-3"}
                                onClick={() =>
                                    handleUpload(docData?.name as string, docData as File)
                                }
                                disabled={!Boolean(docData) || uploading || creatingConversation}
                                isLoading={uploading || creatingConversation}
                            />
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                onClick={() => {
                                    setDocData(null);
                                    setAttachDocModal(false);
                                }}
                                disabled={uploading || creatingConversation}
                            />
                        </div>
                    </form>
                </div>
            </ModalContainer>
        </div>
    );
};

export default InitialScreen;
