import { reduce, sortBy, sortedUniq } from "lodash";
import moment from "moment";

export const getBarchartDataPerFilter = (
    data: { [key: string]: string | number }[],
    filter: string
) => {
    let chartData = {
        "x-axis": [],
        values: [],
    };
    let valuesArr = [];
    switch (filter) {
        case "weekly":
            const sortedData = data?.sort((a, b) => Number(a?.dayOfWeek) - Number(b?.dayOfWeek));

            sortedData?.forEach((item, idx) => {
                valuesArr[Number(item?.dayOfWeek) - 1] = Number(item?.count);
            });

            chartData["x-axis"] = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
            chartData.values = valuesArr;

            return chartData;

        case "monthly":
            const sortedValues = data?.sort((a, b) => Number(a?.day) - Number(b?.day));
            let count = 0;

            valuesArr = [0, 1, 2, 3, 4]?.map((item, idx) => {
                return sortedValues?.splice(idx * 7, (idx + 1) * 7);
            });

            chartData["x-axis"] = ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"];
            chartData.values = data;

            return chartData;

        case "yearly":
            return Object.keys(data)
                .sort((a, b) => data[b] - data[a])
                .slice(0, 5)
                .map((key) => {
                    return {
                        name: key,
                        value: data[key],
                    };
                });

        default:
            return Object.keys(data).map((key) => {
                return {
                    name: key,
                    value: data[key],
                };
            });
    }
};

export const getSalesMultiBarChartData = (
    data: { [key: string]: string | number },
    filter: string
) => {
    switch (filter) {
        case "daily":
            const year = moment().format("YYYY");
            const month = moment().format("MMM");

            const allCurrentSalesDays = (data?.currentSales || {})?.[year]?.[month]?.days;
            const allForecastSalesDays = (data?.forecastSales || {})?.[year]?.[month]?.days;

            const sortedAllCurrentSalesDays = sortBy(allCurrentSalesDays, ["day"]);
            const sortedAllForecastSalesDays = sortBy(allForecastSalesDays, ["day"]);

            const currentSalesDaysValues = (sortedAllCurrentSalesDays || [])?.map((item, idx) => {
                return {
                    value1: item?.totalSale,
                    value2: 0,
                    value1Day: item?.day,
                    value2Day: null,
                    type: "value1",
                };
            });
            const forecastSalesDaysValues = (sortedAllForecastSalesDays || [])?.map((item, idx) => {
                return {
                    value1: 0,
                    value2: item?.totalSale,
                    value1Day: null,
                    value2Day: item?.day,
                    type: "value2",
                };
            });

            const combinedData = [...currentSalesDaysValues, ...forecastSalesDaysValues]?.map(
                (item) => {
                    return {
                        value1Day: item?.value1Day || null,
                        value2Day: item?.value2Day || null,
                        type: item?.type,
                        value1: item?.value1 || 0,
                        value2: item?.value2 || 0,
                    };
                }
            );

            const daysValues = combinedData?.map((item) => {
                return [
                    `Day ${item?.value1Day || item?.value2Day}`,
                    Math.round(item?.value1) || 0,
                    Math.round(item?.value2) || 0,
                ];
            });

            return daysValues;

        case "weekly":
            return [[]];

        case "monthly":
            const currentSalesMonthsValue = Object.entries(data?.currentSales || {})?.map(
                ([key, value]) => value
            );
            const forecastSalesMonthsValue = Object.entries(data?.forecastSales || {})?.map(
                ([key, value]) => value
            );

            const currentSalesValuesArray = Object.entries(currentSalesMonthsValue?.[0] || {})?.map(
                ([key, value]) => ({
                    xAxisLabel: key,
                    value1: value,
                    value2: {},
                })
            );
            const forecastSalesValuesArray = Object.entries(
                forecastSalesMonthsValue?.[0] || {}
            )?.map(([key, value]) => ({
                xAxisLabel: key,
                value1: {},
                value2: value,
            }));

            const valuesFormat = [...currentSalesValuesArray, ...forecastSalesValuesArray]?.map(
                (item, idx) => {
                    return [
                        item.xAxisLabel || "",
                        Math.round((item?.value1 as any)?.totalSale) || 0,
                        Math.round((item?.value2 as any)?.totalSale) || 0,
                    ];
                }
            );
            const sortedValues = valuesFormat.sort((a, b) => {
                const months = {
                    jan: 0,
                    feb: 1,
                    mar: 2,
                    apr: 3,
                    may: 4,
                    jun: 5,
                    jul: 6,
                    aug: 7,
                    sep: 8,
                    oct: 9,
                    nov: 10,
                    dec: 11,
                };
                return (
                    months[a[0]?.toString()?.toLowerCase()] - months[b[0]?.toString().toLowerCase()]
                );
            });

            return sortedValues;

        case "yearly":
            const currentSalesYearsKey = Object.keys(data?.currentSales || {})?.map((item) => item);

            const forecastSalesYearsKey = Object.keys(data?.forecastSales || {})?.map(
                (item) => item
            );

            const sortedUniqData = sortedUniq([...currentSalesYearsKey, ...forecastSalesYearsKey]);

            const bothValues = sortedUniqData?.map((item, id) => {
                const current = data?.currentSales[item];
                const forecast = data?.forecastSales[item];

                const currentValues = Object.values(current || {})?.map((item) =>
                    Math.round((item as any)?.totalSale)
                );
                const forecastValues = Object.values(forecast || {})?.map((item) =>
                    Math.round((item as any)?.totalSale)
                );

                return [currentValues, forecastValues];
            });

            const formattedAns = bothValues?.map((item, idx) => {
                return [
                    sortedUniqData[idx],
                    reduce(item[0], (acc, curr) => acc + curr, 0),
                    reduce(item[1], (acc, curr) => acc + curr, 0),
                ];
            });

            return formattedAns;

        default:
            return [];
    }
};

export const getDashboardChartData = (
    data: { [key: string]: string | number },
    filter: string,
    date?: string[]
) => {
    const currentDate = moment();
    const daysInMonth = currentDate?.clone()?.daysInMonth();
    switch (filter) {
        case "weekly":
            let currentSalesWeek = [];
            let forecastSalesWeek = [];
            for (let i = 3; i >= 0; i--) {
                currentSalesWeek?.push(moment().subtract(i, "days").format("DD/MMM/YYYY"));
            }
            for (let i = 0; i <= 3; i++) {
                forecastSalesWeek?.push(moment().add(i, "days").format("DD/MMM/YYYY"));
            }

            const currentSalesWeekData = currentSalesWeek.map((month) => {
                const [d, m, y] = month.split("/");
                const foundItem = (data?.currentSales || {})?.[y]?.[m]?.days?.find((item) => {
                    return item?.day === +d ? item?.totalSale : 0;
                });
                return {
                    value: foundItem?.totalSale ? Math.round(foundItem?.totalSale) : 0,
                    quantity: foundItem?.totalQty || 0,
                };
            });

            const forecastSalesWeekData = forecastSalesWeek.map((month) => {
                const [d, m, y] = month.split("/");
                const foundItem = (data?.forecastSales || {})?.[y]?.[m]?.days?.find((item) => {
                    return item?.day === +d ? item?.totalSale : 0;
                });
                return {
                    value: foundItem?.totalSale ? Math.round(foundItem?.totalSale) : 0,
                    quantity: foundItem?.totalQty || 0,
                };
            });

            let newArrayWeek = [];
            for (let i = 0; i < currentSalesWeekData?.length - 1; i++) {
                newArrayWeek.push(null);
            }

            const arrayForecastSalesWeekData = newArrayWeek.concat(forecastSalesWeekData);

            currentSalesWeek.pop();
            let week = currentSalesWeek.concat(forecastSalesWeek);
            const getDayOfWeek = (dateString) => {
                const date = moment(dateString, "DD/MMM/YYYY");
                return date.format("ddd");
            };

            const daysOfWeek = week.map(getDayOfWeek);
            return [daysOfWeek, currentSalesWeekData, arrayForecastSalesWeekData];

        case "daily":
            let currentSalesDays = [];
            let forecastSalesDays = [];
            for (let i = 14; i >= 0; i--) {
                currentSalesDays?.push(moment().subtract(i, "days").format("DD/MMM/YYYY"));
            }
            for (let i = 0; i <= daysInMonth - 16; i++) {
                forecastSalesDays?.push(moment().add(i, "days").format("DD/MMM/YYYY"));
            }

            const currentSalesDaysData = currentSalesDays.map((month) => {
                const [d, m, y] = month.split("/");
                const foundItem = (data?.currentSales || {})?.[y]?.[m]?.days?.find((item) => {
                    return item?.day === +d ? item?.totalSale : 0;
                });
                return {
                    value: foundItem?.totalSale ? Math.round(foundItem?.totalSale) : 0,
                    quantity: foundItem?.totalQty || 0,
                };
            });

            const forecastSalesDaysData = forecastSalesDays.map((month) => {
                const [d, m, y] = month.split("/");
                const foundItem = (data?.forecastSales || {})?.[y]?.[m]?.days?.find((item) => {
                    return item?.day === +d ? item?.totalSale : 0;
                });

                return {
                    value: foundItem?.totalSale ? Math.round(foundItem?.totalSale) : 0,
                    quantity: foundItem?.totalQty || 0,
                };
            });

            let newArrayDays = [];
            for (let i = 0; i < currentSalesDaysData?.length - 1; i++) {
                newArrayDays.push(null);
            }

            const arrayForecastSalesDaysData = newArrayDays.concat(forecastSalesDaysData);

            currentSalesDays.pop();
            let days = currentSalesDays.concat(forecastSalesDays);

            const getDayOfTheMonth = (dateString) => {
                const date = moment(dateString, "DD/MMM/YYYY");
                return date.format("MMM Do");
            };

            const daysOfTheMonth = days.map(getDayOfTheMonth);
            return [daysOfTheMonth, currentSalesDaysData, arrayForecastSalesDaysData];

        case "yearly":
            let currentSalesMonths = [];
            let forecastSalesMonths = [];
            for (let i = 6; i >= 0; i--) {
                currentSalesMonths?.push(moment().subtract(i, "months").format("MMM YYYY"));
            }
            for (let i = 0; i < 6; i++) {
                forecastSalesMonths?.push(moment().add(i, "months").format("MMM YYYY"));
            }

            const currentSalesMonthsData = currentSalesMonths.map((month) => {
                const [m, y] = month.split(" ");
                return {
                    value: Math.round((data?.currentSales || {})[y]?.[m]?.days[0]?.totalSale || 0),
                    quantity:
                        Math.round((data?.currentSales || {})[y]?.[m]?.days[0]?.totalQty) || 0,
                };
            });

            const forecastSalesMonthsData = forecastSalesMonths.map((month) => {
                const [m, y] = month.split(" ");
                return {
                    value: Math.round((data?.forecastSales || {})[y]?.[m]?.days[0]?.totalSale || 0),
                    quantity:
                        Math.round((data?.forecastSales || {})[y]?.[m]?.days[0]?.totalQty) || 0,
                };
            });

            let newArray = [];
            for (let i = 0; i < currentSalesMonthsData?.length - 1; i++) {
                newArray.push(null);
            }

            const arrayForecastSalesMonthsData = newArray.concat(forecastSalesMonthsData);

            currentSalesMonths.pop();
            let months = currentSalesMonths?.concat(forecastSalesMonths);
            return [months, currentSalesMonthsData, arrayForecastSalesMonthsData];

        case "custom":
            let currentSalesCustomDays = [];
            let forecastSalesCustomDays = [];

            const customStartDate = moment(date[0], "MMM DD YYYY");
            const customEndDate = moment(date[1], "MMM DD YYYY");
            const checkCurrentDatePast = customEndDate.diff(currentDate, "days");

            const currentDateCount =
                checkCurrentDatePast >= 0
                    ? currentDate.diff(customStartDate, "days")
                    : customEndDate.diff(customStartDate, "days");

            const forecastDateCount =
                currentDateCount >= 0
                    ? customEndDate.diff(currentDate, "days")
                    : customEndDate.diff(customStartDate, "days");

            if (currentDateCount >= 0) {
                if (checkCurrentDatePast >= 0) {
                    for (let i = currentDateCount; i >= 0; i--) {
                        currentSalesCustomDays?.push(
                            moment().subtract(i, "days").format("MMM/DD/YYYY")
                        );
                    }
                } else {
                    for (let i = currentDateCount; i >= 0; i--) {
                        currentSalesCustomDays?.push(
                            moment(customEndDate).subtract(i, "days").format("MMM/DD/YYYY")
                        );
                    }
                }
            }

            if (forecastDateCount >= 0) {
                if (currentDateCount >= 0) {
                    let count =
                        moment().format("MMM DD YYYY") === moment(date[1]).format("MMM DD YYYY")
                            ? forecastDateCount
                            : forecastDateCount + 1;

                    for (let i = 0; i <= count; i++) {
                        forecastSalesCustomDays?.push(
                            moment().add(i, "days").format("MMM/DD/YYYY")
                        );
                    }
                } else {
                    for (let i = 0; i <= forecastDateCount + 1; i++) {
                        forecastSalesCustomDays?.push(
                            moment(customStartDate).add(i, "days").format("MMM/DD/YYYY")
                        );
                    }
                }
            }
            const currentSalesCustomData = currentSalesCustomDays.map((month) => {
                const [m, d, y] = month.split("/");
                const foundItem = (data?.currentSales || {})?.[y]?.[m]?.days?.find((item) => {
                    return item?.day === +d ? item?.totalSale : 0;
                });
                return {
                    value: foundItem?.totalSale ? Math.round(foundItem?.totalSale) : 0,
                    quantity: foundItem?.totalQty || 0,
                };
            });
            const forecastSalesCustomData = forecastSalesCustomDays.map((month) => {
                const [m, d, y] = month.split("/");
                const foundItem = (data?.forecastSales || {})?.[y]?.[m]?.days?.find((item) => {
                    return item?.day === +d ? item?.totalSale : 0;
                });
                return {
                    value: foundItem?.totalSale ? Math.round(foundItem?.totalSale) : 0,
                    quantity: foundItem?.totalQty || 0,
                };
            });
            let newArrayCustomDays = [];
            for (let i = 0; i < currentSalesCustomData?.length - 1; i++) {
                newArrayCustomDays.push(null);
            }

            const arrayForecastSalesCustomData = newArrayCustomDays.concat(forecastSalesCustomData);

            forecastSalesCustomData?.length > 0 && currentSalesCustomDays.pop();
            let customDays = currentSalesCustomDays.concat(forecastSalesCustomDays);
            return [customDays, currentSalesCustomData, arrayForecastSalesCustomData];

        default:
            return [];
    }
};
