import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const workFlowService = {
    getWorkFlowList,
    createWorkFlow,
    deleteWorkFlow,
    pauseOrPlayWorkFlow,
    createEventAndAction,
    getSingleWorkflow,
    updateWorkflowConnections,
    updateWorkflowDisconnections,
    updateWorkflowNodePosition,
    createComment,
    getNodeComments,
    updateWorkflowEvent,
    deleteEvent,
    deleteAction,
    swapNodes,
    getEventAndActionsOptionList,
    getWorkflowActivities,
    duplicateWorkflow,
    updateWorkflowName,
    getWorkflowRuns,
    getSingleWorkflowRun,
    retryAction,
    markActionAsDone,
    playPauseWorkflowRun,
    getWorkflowAnalytics,
    getDashboardWorkflowRuns,
    getSingleComment,
    deleteComment,
    getWorkflowTemplates,
    createAction,
    updateEvent,
    updateAction,
    duplicateAction,
    createMultipleActions,
};

async function getWorkFlowList(workflowLimit?: string | number, WorkflowName?: string, createdBy?: string, numOfRuns?: string | number, dateCreated?: string) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        WorkflowName: WorkflowName as string,
        createdBy: createdBy as string,
        numOfRuns: numOfRuns as string,
        dateCreated: dateCreated as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/list?limit=${workflowLimit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getDashboardWorkflowRuns() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/dashboard/list/runs`,
        requestOptions
    );
    return res;
}

async function createWorkFlow(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(`${config.API_URL}/api/v1/ldb/workFlow`, requestOptions);

    return res;
}

async function createEventAndAction(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/event`,
        requestOptions
    );

    return res;
}

async function createAction(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/workflow-action`,
        requestOptions
    );

    return res;
}

async function createMultipleActions(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/workflow-action/multiple`,
        requestOptions
    );

    return res;
}

async function updateEvent(data: Record<string, any> | Array<Record<string, any>>, eventId: string) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/workFlowEvent/${eventId}`,
        requestOptions
    );

    return res;
}

async function updateAction(data: Record<string, any> | Array<Record<string, any>>, actionId: string) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/update/action/${actionId}`,
        requestOptions
    );

    return res;
}

async function duplicateAction(actionId: string) {
    const requestOptions = {
        method: "POST",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/duplicate/workflowAction/${actionId}`,
        requestOptions
    );

    return res;
}

async function getSingleWorkflow(workflowId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/single-workFlow/${workflowId}`,
        requestOptions
    );

    return res;
}

async function deleteWorkFlow(workFlowId: string) {
    const requestOptions = {
        method: "DELETE",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/delete/workFlow/${workFlowId}`,
        requestOptions
    );
    return res;
}

async function pauseOrPlayWorkFlow(workflowId: string, data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/play-or-pause/workFlow/${workflowId}`,
        requestOptions
    );
    return res;
}

async function updateWorkflowEvent(eventId: string, data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/update/action/${eventId}`,
        requestOptions
    );
    return res;
}

async function updateWorkflowConnections(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/update/connection`,
        requestOptions
    );
    return res;
}

async function updateWorkflowDisconnections(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/update/disconnect`,
        requestOptions
    );
    return res;
}

async function updateWorkflowNodePosition(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/update/position`,
        requestOptions
    );
    return res;
}

async function createComment(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/comment`,
        requestOptions
    );
    return res;
}
async function getSingleComment(commentId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/comment/single/${commentId}`,
        requestOptions
    );
    return res;
}
async function getNodeComments(nodeId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/comment/node/${nodeId}`,
        requestOptions
    );
    return res;
}

async function deleteEvent(eventId: string) {
    const requestOptions = {
        method: "DELETE",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/event/${eventId}`,
        requestOptions
    );
    return res;
}

async function deleteAction(actionId: string) {
    const requestOptions = {
        method: "DELETE",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/action/${actionId}`,
        requestOptions
    );
    return res;
}

async function swapNodes(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/update/swap`,
        requestOptions
    );
    return res;
}

async function getEventAndActionsOptionList(type: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/event-actions?type=${type}`,
        requestOptions
    );
    return res;
}

async function getWorkflowActivities(workflowId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/list/activities/${workflowId}?limit=20`,
        requestOptions
    );
    return res;
}

async function duplicateWorkflow(workflowId: string) {
    const requestOptions = {
        method: "POST",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/duplicate-workflow/${workflowId}`,
        requestOptions
    );
    return res;
}

async function updateWorkflowName(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/update/workflow/name`,
        requestOptions
    );
    return res;
}

async function getWorkflowRuns(workFlowId: string, limit?: string | number) {
    const requestOptions = {
        method: "GET",
    };
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/list/runs?workFlowId=${workFlowId}&limit=${limit}`,
        requestOptions
    );
    return res;
}

async function getSingleWorkflowRun(workFlowRunId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/single-workFlow-run/${workFlowRunId}`,
        requestOptions
    );
    return res;
}

async function retryAction(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/retry-action`,
        requestOptions
    );
    return res;
}

async function markActionAsDone(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/run/action-done`,
        requestOptions
    );
    return res;
}

async function playPauseWorkflowRun(data: Record<string, any> | Array<Record<string, any>>, runId: string) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/play-or-pause/run/${runId}`,
        requestOptions
    );
    return res;
}

async function getWorkflowTemplates() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/templates`,
        requestOptions
    );
    return res;
}
async function getWorkflowAnalytics() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/analytics/workflow`,
        requestOptions
    );
    return res;
}

async function deleteComment(nodeId: string) {
    const requestOptions = {
        method: "DELETE",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/comment/single/${nodeId}`,
        requestOptions
    );
    return res;
}
