import React from "react";

type DetailsTabProps = {
    singleTask: {[key: string]: any} | undefined;
};

const DetailsTab = ({singleTask}: DetailsTabProps) => {
    return (
        <div className="flex gap-[18px] flex-col mt-[18px] text-[14px] leading-[24px]">
            {/* <p className="text-[#3B4C58] font-rocGroteskMedium ">
                            Please create a PO for the order by Sterling Limited and send to finance
                        </p> */}
            {/* {!singleTask?.action?.data?.rfqIds && singleTask?.action?.approvals?.length === 0 && (
                <div className="flex justify-center text-center my-6">
                    <p className="text-slate-700 font-rocGroteskMedium text-[14px]">
                        No data available
                    </p>
                </div>
            )} */}
            {/* {singleTask?.action?.data?.rfqIds &&
                singleTask?.action?.data?.rfqIds?.map((item: string, idx: number) => {
                    return (
                        <div className="flex items-center ">
                            <p className="text-[#64748B] font-rocGroteskMedium w-[166px]">
                                RFQ ID {idx + 1}
                            </p>
                            <p className="text-[#12A589] font-rocGroteskMedium">{item?.taId}</p>
                        </div>
                    );
                })} */}
            {singleTask?.action?.approvals?.length > 0 ? (
                singleTask?.approvals?.map((item: string, idx: number) => {
                    return (
                        <div key={idx} className="flex items-center">
                            <p className="text-[#64748B] font-rocGroteskMedium w-[166px]">
                                {`Approval level ${idx + 1}`}
                            </p>
                            <div className="flex items-center gap-2">
                                <div className="w-[24px] h-[24px] rounded-full">
                                    <img
                                        src="/logo_192x192.png"
                                        className="rounded-full"
                                        alt="profile"
                                    />
                                </div>
                                <p className="text-[#3B4C58] font-rocGroteskMedium">{item}</p>
                            </div>
                        </div>
                    );
                })
            ) : (
                <div className="flex justify-center text-center my-6">
                    <p className="text-slate-700 font-rocGroteskMedium text-[14px]">
                        No data available
                    </p>
                </div>
            )}
        </div>
    );
};

export default DetailsTab;
