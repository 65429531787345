import {getCurrencyFromCurrencyCode} from "helpers";
import {truncate} from "lodash";
import React from "react";
import {useNavigate} from "react-router-dom";

interface OrderSummaryCardOneProps {
    index?: number
    order: {[key: string]: any};
}

const OrderSummaryCardOne = ({order, index}: OrderSummaryCardOneProps) => {
    const navigate = useNavigate();

    return (
        <div className="">
            <div
                onClick={() => navigate(`/dashboard/order/${order?._id}`)}
                className="max-lg:hidden p-3 cursor-pointer bg-white text-xs font-rocGroteskMedium rounded border border-slate-100 shadow-boxShadow-3"
            >
                <p className="flex items-center justify-between mb-2 ">
                    <span className="text-slate-700 text-xs ">#Order ID: {order?.taId}</span>
                    {/* <i className="ri-close-fill text-slate-500"></i> */}
                </p>
                <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-1.5">
                        <i className="ri-list-radio"></i>
                        <p>
                            <span className="text-gm-50">Items/Services:</span>{" "}
                            <span className="text-slate-500">
                                {truncate(order?.items?.[0]?.description, { length: 23 })}
                            </span>
                        </p>
                    </div>
                    <div className="flex items-center gap-1.5">
                        <i className="ri-file-list-3-line"></i>
                        <p>
                            <span className="text-gm-50">Specifications:</span>{" "}
                            <span className="text-slate-500">
                                {truncate(order?.specifications, { length: 23 })}
                            </span>
                        </p>
                    </div>
                    <div className="flex items-center gap-1.5">
                        <i className="ri-bank-card-2-line"></i>
                        <p>
                            <span className="text-gm-50">Estimated Budget:</span>{" "}
                            <span className="text-slate-500">
                                {order?.budget?.amount
                                    ? getCurrencyFromCurrencyCode(order?.budget?.currency) +
                                      order?.budget?.amount
                                    : "N/A"}
                            </span>
                        </p>
                    </div>
                </div>
            </div>

            <div
                onClick={() => navigate(`/dashboard/order/${order?._id}`)}
                className={`hidden ${
                    index !== 0 && "border-t"
                } border-slate-100 max-lg:flex gap-2 bg-white items-center justify-between py-2.5 rounded`}
            >
                <div className="flex gap-3 items-center">
                    <div>
                        <p className="text-xs font-rocGroteskMedium mb-1">
                            #Order ID: {order?.taId}
                        </p>
                        <p className="text-sm font-rocGroteskMedium text-slate-500">
                            {truncate(order?.items?.[0]?.description, { length: 23 })}
                        </p>
                    </div>
                </div>
                <i className="ri-arrow-right-s-line text-2xl"></i>
            </div>
        </div>
    );
};

export default OrderSummaryCardOne;
