/* eslint-disable react-hooks/exhaustive-deps */
import Button from "./Button";
import React, {useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Loader from "./Loader";
import moment from "moment";
import CustomTable from "./CustomTable";
import VendorSelectInput from "./InputField/VendorSelectInput";
import TextInput from "./InputField/TextInput";
import {chatActions} from "../../redux/Ldb/actions";
import {toast} from "react-hot-toast";
import CustomToast from "./CustomToast";
import {ThreeDots} from "react-loader-spinner";
import TypingEffect from "./TypingEffect";
import {getFileTypeFromUrl} from "../../helpers/getFileTypeFromUrl";
import {displayFileIcon} from "../../helpers";
import {truncate} from "lodash";
import {convertBytes} from "helpers/displayFileSize";
import {isFileSizeLessThanOrEqualTo} from "helpers/compareFileSizeInMb";
import {firebaseService} from "services/firebaseService";
import useGetUserProfile from "hooks/useGetUserProfile";
import ModalContainer from "./ModalContainer";
import {useAppDispatch, useAppSelector} from "../../hooks/storeHooks";
import {adjustHeight} from "variables/adjustInputHeight";

type CopilotChatProps = {
    chats: any[];
    send: (message: string) => void;
    handleChatChange: (message: string) => void;
    message: string;
    doc: string;
    docName: string;
    setChats: (chats: any[]) => void;
    conversationId: string;
};

const CopilotChat = ({
    chats,
    send,
    handleChatChange,
    message,
    doc,
    docName,
    setChats,
    conversationId,
}: CopilotChatProps) => {
    const [rfqItems, setRfqItems] = useState([
        {
            description: "",
            qty: "",
            vendor: [],
        },
    ]);
    const [vendorEmails, setVendorEmails] = useState([]);
    const [showBtn, setShowBtn] = useState(false);
    const [docData, setDocData] = useState(null);
    const [attachDocModal, setAttachDocModal] = useState(false);
    const [uploading, setUploading] = useState(false);

    const [profile] = useGetUserProfile();
    const chatContainerRef = useRef(null);
    const lastMessageRef = useRef(null);
    const typingSpeed = 25; // think of this as delay i.e how long it would take to type, so lower is faster vice versa

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {sendingAiMessage, sendAiMessageSuccess, updatingChatData, updateChatDataSuccess} =
        useAppSelector((state) => state.chat);
    const {fetchUserProfileSuccess} = useAppSelector((state) => state.auth);

    const nameArr: string[] = fetchUserProfileSuccess?.profile?.businessName
        ? fetchUserProfileSuccess?.profile?.businessName?.split(" ")
        : [];

    const initials = nameArr
        ?.map((name: string) => name[0]?.toUpperCase())
        ?.join("")
        ?.slice(0, 3);

    const handleItemChange = (idx, name, value) => {
        const itemDataCopyArr = [...rfqItems];
        const itemDataCopy = itemDataCopyArr?.[idx];
        itemDataCopy[name] = value;

        setRfqItems(itemDataCopyArr);
    };

    const handleVendorEmailChange = (index, value) => {
        const vendorEmailsCopy = [...vendorEmails];
        vendorEmailsCopy[index] = value;
        setVendorEmails(vendorEmailsCopy);
    };

    const handleUpdateChat = (chat) => {
        const body = {
            data: {
                ...chat?.data,
                items: rfqItems?.map((item, id) => ({
                    item: item?.description,
                    qty: Number(item?.qty),
                    vendors:
                        chat?.data?.vendorType?.toLowerCase() === "email"
                            ? vendorEmails?.[id]
                                  ?.replace(" ", "")
                                  ?.split(",")
                                  ?.map((email) => email?.replace(" ", ""))
                                  ?.filter((value) => Boolean(value))
                            : item?.vendor,
                })),
            },
            chatId: chat?._id,
        };

        dispatch(chatActions.updateChatData(body));
    };

    const displayMessage = (chat) => {
        if (chat?.loading) {
            return (
                <div>
                    <ThreeDots
                        height="40"
                        width="60"
                        radius="9"
                        color="#64748B33"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        visible={true}
                    />
                </div>
            );
        }

        if (
            chat?.conversationType?.toLowerCase() === "copilotrfq" &&
            chat?.conversationStage?.stage === 4 &&
            chat?.data?.order?.items?.length > 0
        ) {
            const tableHeader = [
                {title: "Item/Service", widthClass: "w-[89%] max-sm:w-[75%]"},
                {title: "Quantity", widthClass: "w-[11%] max-sm:w-[25%]"},
            ];

            const tableBody = chat?.data?.order?.items?.map((item) => [
                {
                    content: (
                        <div className={`w-full border-slate-100 text-sm text-slate-700 py-[14px]`}>
                            <p>{item?.item}</p>
                        </div>
                    ),
                },
                {
                    content: (
                        <div
                            className={`w-full h-full border-slate-100 text-sm text-slate-700 py-[14px]`}
                        >
                            <p>{item?.qty}</p>
                        </div>
                    ),
                },
            ]);

            return (
                <div>
                    {chat?._id === chats?.[chats?.length - 1]?._id ? (
                        <p
                            className={`text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap`}
                        >
                            <TypingEffect
                                typingSpeed={typingSpeed}
                                text={chat?.data?.description?.items_description}
                            />
                        </p>
                    ) : (
                        <p
                            className={`text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap`}
                        >
                            {chat?.data?.description?.items_description}
                        </p>
                    )}
                    <div className="my-4">
                        <CustomTable
                            tableBody={tableBody}
                            tableHeader={tableHeader}
                            isAllSelectable={false}
                            headerContainerClass="!bg-slate-50"
                            tableClass=" w-full"
                            isScrollable={false}
                            isCellBordered={true}
                        />
                    </div>
                    {chat?._id === chats?.[chats?.length - 1]?._id ? (
                        <p
                            className={`text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap`}
                        >
                            <TypingEffect
                                typingSpeed={typingSpeed}
                                text={
                                    Boolean(chat?.data?.description?.customer_details)
                                        ? chat?.data?.description?.customer_details
                                        : `Customer name is ${chat?.data?.customer?.name}, mobile number is ${chat?.data?.customer?.mobile}, email is ${chat?.data?.customer?.email} and address is ${chat?.data?.customer?.address}`
                                }
                            />
                        </p>
                    ) : (
                        <p
                            className={`text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap`}
                        >
                            {!Boolean(chat?.data?.description?.customer_details)
                                ? chat?.data?.description?.customer_details
                                : `Customer name is ${chat?.data?.customer?.name}, mobile number is ${chat?.data?.customer?.mobile}, email is ${chat?.data?.customer?.email} and address is ${chat?.data?.customer?.address}`}
                        </p>
                    )}
                </div>
            );
        }

        if (
            chat?.conversationType?.toLowerCase() === "copilotrfq" &&
            chat?.conversationStage?.stage === 5 &&
            chat?.data?.order?.order
        ) {
            const tableHeader = [
                {title: "Item/Service", widthClass: "w-[47%] max-sm:w-[30%] !px-2.5"},
                {title: "Quantity", widthClass: "w-[12%] max-sm:w-[15%] !px-2.5"},
                {
                    title:
                        chat?.data?.vendorType?.toLowerCase() === "email"
                            ? "Add your vendors"
                            : chat?.data?.vendorType?.toLowerCase() !== "email" &&
                              !Boolean(chat?._id === chats?.[chats?.length - 1]?._id)
                            ? "Assigned Vendor(s)"
                            : "Assign vendor",
                    widthClass: "w-[41%] max-sm:w-[35%] !px-2.5",
                },
            ];

            const tableBody = chat?.data?.order?.order?.items?.map((data, idx) => {
                return [
                    {
                        content: (
                            <div
                                className={`w-full border-slate-100 text-sm text-slate-700 `}
                                onClick={() => setShowBtn(true)}
                            >
                                <TextInput
                                    value={rfqItems?.[idx]?.description || data?.item}
                                    name={"description"}
                                    type={"text"}
                                    required={true}
                                    disabled={
                                        !Boolean(chat?._id === chats?.[chats?.length - 1]?._id)
                                    }
                                    onChange={(e) =>
                                        handleItemChange(idx, e.target.name, e.target.value)
                                    }
                                    placeholder={"Item description"}
                                    containerClassname=""
                                    inputContainerClassname={"!border-0 !rounded-none "}
                                    inputClassName="w-full "
                                />
                            </div>
                        ),
                        tableDataDivClass: "!pl-0",
                    },
                    {
                        content: (
                            <div
                                className={`w-full h-full border-slate-100 text-sm text-slate-700 `}
                                onClick={() => setShowBtn(true)}
                            >
                                <TextInput
                                    defaultValue={rfqItems?.[idx]?.qty || data?.qty}
                                    name={"qty"}
                                    type={"number"}
                                    onWheel={(e) => (e.target as any).blur()}
                                    required={true}
                                    onChange={(e) =>
                                        handleItemChange(idx, e.target.name, e.target.value)
                                    }
                                    placeholder={"Qty"}
                                    disabled={
                                        !Boolean(chat?._id === chats?.[chats?.length - 1]?._id)
                                    }
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    pattern={"^[0-9]+$"}
                                    title={"must be digits"}
                                    containerClassname=""
                                    inputContainerClassname={"!border-0 !rounded-none"}
                                    inputClassName="w-full "
                                />
                            </div>
                        ),
                        tableDataDivClass: "!pl-0",
                    },
                    {
                        content: (
                            <div
                                className={`w-full flex items-center h-full border-slate-100 text-sm text-slate-700`}
                                onClick={() => setShowBtn(true)}
                            >
                                {chat?.data?.vendorType?.toLowerCase() !== "email" ? (
                                    <VendorSelectInput
                                        value={rfqItems?.[idx]?.vendor?.join(", ")}
                                        name="vendor"
                                        placeholder={"Select vendor"}
                                        isRequired={true}
                                        disabled={
                                            !Boolean(chat?._id === chats?.[chats?.length - 1]?._id)
                                        }
                                        orderType={chat?.data?.order?.order?.orderType}
                                        className="!border-0"
                                        handleChange={(name, value, valuesArray) =>
                                            handleItemChange(idx, name, valuesArray)
                                        }
                                        dropdownOptionsProp={chat?.data?.vendors?.map((vendor) => {
                                            return {
                                                label: vendor?.profile?.businessName,
                                                value: vendor?._id,
                                                icon: (
                                                    <img
                                                        src={vendor?.profile?.avatar}
                                                        width={24}
                                                        height={24}
                                                        alt="logo"
                                                    />
                                                ),
                                            };
                                        })}
                                    />
                                ) : (
                                    <TextInput
                                        value={
                                            vendorEmails?.[idx] ||
                                            chat?.data?.items?.[idx]?.vendors?.join(", ")
                                        }
                                        name="vendor"
                                        type={"text"}
                                        required={true}
                                        onChange={(e) =>
                                            handleVendorEmailChange(idx, e.target.value)
                                        }
                                        placeholder={"Vendor email"}
                                        disabled={
                                            !Boolean(chat?._id === chats?.[chats?.length - 1]?._id)
                                        }
                                        containerClassname=""
                                        inputContainerClassname={"!border-0 !rounded-none"}
                                        inputClassName="w-full "
                                    />
                                )}
                            </div>
                        ),
                        widthClass: `w-[36.3%] absolute`,
                        tableDataDivClass: "!pl-0",
                    },
                ];
            });

            return (
                <div>
                    {chat?._id === chats?.[chats?.length - 1]?._id ? (
                        <p
                            className={`text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap`}
                        >
                            <TypingEffect typingSpeed={typingSpeed} text={chat?.message} />
                        </p>
                    ) : (
                        <p
                            className={`text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap`}
                        >
                            {chat?.message}
                        </p>
                    )}
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();

                            handleUpdateChat(chat);
                        }}
                    >
                        <div className="my-4">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                tableClass=" w-full"
                                isScrollable={false}
                                isCellBordered={true}
                            />
                        </div>
                        {chat?._id === chats?.[chats?.length - 1]?._id && showBtn && (
                            <div className="flex justify-start my-4">
                                <div className="flex items-center gap-2">
                                    <Button
                                        btnText={
                                            chat?.data?.vendorType?.toLowerCase() === "email"
                                                ? "Send email"
                                                : "Save & Proceed"
                                        }
                                        isLoading={updatingChatData}
                                        type={"submit"}
                                        btnClassname={"!w-[150px] "}
                                    />
                                    <Button
                                        btnText={"Cancel"}
                                        type={"button"}
                                        onClick={() => {
                                            const items = chat?.data?.order?.order?.items?.map(
                                                (rfqItem) => ({
                                                    description: rfqItem?.item,
                                                    qty: rfqItem?.qty,
                                                    vendor: chat?.data?.vendors?.map(
                                                        (vendor) => vendor?._id
                                                    ),
                                                })
                                            );
                                            setVendorEmails([]);
                                            setRfqItems(items);
                                        }}
                                        btnClassname={
                                            "!bg-slate-50 !w-[100px] border border-slate-200 !text-gm-50"
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </form>
                </div>
            );
        }

        if (
            chat?.conversationType?.toLowerCase() === "copilotrfq" &&
            chat?.conversationStage?.stage === 6 &&
            chat?.data?.rfqs
        ) {
            return (
                <div className="w-full">
                    {chat?._id === chats?.[chats?.length - 1]?._id ? (
                        <p
                            className={`text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap`}
                        >
                            <TypingEffect typingSpeed={typingSpeed} text={chat?.message} />
                        </p>
                    ) : (
                        <p
                            className={`text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap`}
                        >
                            {chat?.message}
                        </p>
                    )}
                    <div className="mt-6 grid grid-cols-3 gap-5">
                        {chat?.data?.rfqs?.map((rfq, idx) => (
                            <Link
                                to={`/dashboard/rfq/${rfq?._id}`}
                                key={rfq?._id}
                                className="p-4 flex items-center gap-4 cursor-pointer bg-[#F0FDF4] border-[2px] border-[#4ADE80] rounded"
                            >
                                <div className="w-11 h-11 bg-[#4ADE80] rounded flex items-center justify-center ">
                                    <i className="ri-file-list-3-fill text-xl text-white"></i>
                                </div>
                                <div>
                                    <p className="text-sm font-rocGroteskMedium">RFQ {idx + 1}</p>
                                    <p className="text-xs text-slate-700 font-rocGroteskMedium">
                                        Click to view
                                    </p>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            );
        }

        return chat?._id === chats?.[chats?.length - 1]?._id ? (
            <p className={`text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap`}>
                <TypingEffect typingSpeed={typingSpeed} text={chat?.message} />
            </p>
        ) : (
            <p className={`text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap`}>
                {chat?.message}
            </p>
        );
    };

    const handleUpload = (fileName, file) => {
        if (fileName && file) {
            setUploading(true);
            firebaseService.uploadFile(
                `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/ai-conversation/${
                    profile?.profile?.businessName
                }/rfqs/${fileName}`,
                file,
                (url) => {
                    navigate(window.location?.pathname + window.location?.search, {
                        replace: true,
                        state: {doc: url, docName: fileName},
                    });
                    dispatch(
                        chatActions.sendAiMessage(
                            {
                                conversationId,
                                message: "Process document",
                                media: [
                                    {
                                        file_type: "document",
                                        file: url,
                                    },
                                ],
                            },
                            setChats
                        )
                    );

                    // setUploading(false);
                }
            );
        }
    };

    useEffect(() => {
        if (
            chats?.[chats?.length - 1]?.conversationType?.toLowerCase() === "copilotrfq" &&
            chats?.[chats?.length - 1]?.conversationStage?.stage === 5 &&
            chats?.[chats?.length - 1]?.data?.order?.order &&
            rfqItems?.length === 1 &&
            rfqItems?.[0]?.description === "" &&
            rfqItems?.[0]?.qty === ""
        ) {
            const items = chats?.[chats?.length - 1]?.data?.order?.order?.items?.map((data) => ({
                description: data?.item,
                qty: data?.qty,
                vendor: chats?.[chats?.length - 1]?.data?.vendors?.map((vendor) => vendor?._id),
            }));

            setRfqItems(items);
        }
    }, [chats, rfqItems]);

    useEffect(() => {
        if (Boolean(updateChatDataSuccess)) {
            toast.custom((t) => (
                <CustomToast t={t} message={"Updated successfully"} type="success" />
            ));
            send?.("yes, proceed");
            setShowBtn(false);
            dispatch(chatActions.resetUpdateChatDataSuccess());
        }
    }, [updateChatDataSuccess, dispatch]);

    useEffect(() => {
        if (Boolean(sendAiMessageSuccess)) {
            const elem = document.getElementById("chatInput");
            elem.style.height = "24px";
            setDocData(null);
            setAttachDocModal(false);
            setUploading(false);
            dispatch(chatActions.resetSendAiMessageSuccess());
        }
    }, [sendAiMessageSuccess, dispatch]);

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({behavior: "smooth"});
        }
    }, [chats]);

    return (
        <>
            <div className=" bg-white">
                <div className="py-4 px-8 border-y border-slate-100">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                            <span className="text-base font-rocGroteskMedium bg-clip-text text-transparent bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                                Co-Pilot
                            </span>
                            <div className="rounded-[5px] flex justify-center items-center w-[50px] p-[1px] bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                                <div className="px-[11px] bg-white rounded">
                                    <span className="text-xs font-rocGroteskMedium leading-4 bg-clip-text text-transparent bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                                        Beta
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="flex items-center gap-3">
                        <div className="flex items-center gap-2">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1690905410/Web%20App/google_logo_u0iqig.svg"
                                alt="avatar"
                                width={26}
                                height={26}
                                className="border-[2px] border-p-50 rounded-full p-[2px]"
                            />
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1690905410/Web%20App/google_logo_u0iqig.svg"
                                alt="avatar"
                                width={26}
                                height={26}
                                className="border-[2px] border-r-50 rounded-full p-[2px]"
                            />
                        </div>
                        <Button
                            btnText={"Share"}
                            type={"button"}
                            btnClassname={"!px-4 !py-1.5 !w-fit"}
                        />
                    </div> */}
                    </div>
                </div>
                <div className="min-h-[60vh] max-h-[60vh]  overflow-auto relative w-full">
                    <div
                        ref={chatContainerRef}
                        className="min-h-[inherit] max-h-[inherit] overflow-auto relative w-full"
                    >
                        <div className={`min-h-[inherit] ${doc ? "pb-[16vh]" : "pb-[9vh]"}`}>
                            {chats && chats?.length > 0 ? (
                                <div>
                                    {chats?.map((chat, idx) => {
                                        return chat?.senderType?.toLowerCase() === "ldb" ? (
                                            <div
                                                key={chat?._id + idx}
                                                className="py-6 px-8 grid grid-cols-[32px_auto] items-start gap-6 bg-white border-b border-slate-100"
                                            >
                                                {Boolean(
                                                    fetchUserProfileSuccess?.profile?.avatar
                                                ) ? (
                                                    <img
                                                        src={
                                                            fetchUserProfileSuccess?.profile?.avatar
                                                        }
                                                        alt="user avatar"
                                                        className=" w-8 h-8 rounded"
                                                    />
                                                ) : (
                                                    <div className="w-8 h-8 p-2 rounded bg-slate-100 flex items-center justify-center">
                                                        <p className="text-xs font-rocGroteskMedium text-gm-50">
                                                            {initials}
                                                        </p>
                                                    </div>
                                                )}

                                                <div>
                                                    <p className="flex text-sm font-rocGroteskMedium items-center mb-1">
                                                        <span className="capitalize">
                                                            {
                                                                fetchUserProfileSuccess?.profile
                                                                    ?.businessName
                                                            }
                                                        </span>
                                                        <span className="mx-1">|</span>
                                                        <span className="text-slate-500">
                                                            {moment(chat?.createdAt).fromNow(true)}{" "}
                                                            ago
                                                        </span>{" "}
                                                    </p>
                                                    <p className="text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap">
                                                        {chat?.message}
                                                    </p>
                                                </div>
                                                {idx === chats.length - 1 && (
                                                    <div ref={lastMessageRef}></div>
                                                )}
                                            </div>
                                        ) : (
                                            <div
                                                key={chat?._id + idx}
                                                className="py-6 px-8 flex items-start gap-6 bg-slate-50 border-b border-slate-100"
                                            >
                                                <div className="w-8 h-8 p-2 rounded bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100% flex items-center justify-center">
                                                    <i className="ri-robot-line text-white"></i>
                                                </div>

                                                <div className="w-full">
                                                    <p className="flex text-sm font-rocGroteskMedium items-center mb-1">
                                                        <span>Co-Pilot</span>
                                                        <span className="mx-1">|</span>
                                                        <span className="text-slate-500">
                                                            {moment(chat?.createdAt).fromNow(true)}{" "}
                                                            ago
                                                        </span>{" "}
                                                    </p>
                                                    {displayMessage(chat)}
                                                </div>
                                                {idx === chats.length - 1 && (
                                                    <div ref={lastMessageRef}></div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className="w-full min-h-[inherit] flex flex-col items-center justify-center">
                                    <img
                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1693839754/Tradeally_2.0/icons/emptyChatIcon_n5mj2j.svg"
                                        alt="empty chat"
                                        className="mb-3"
                                        width={120}
                                        height={90}
                                    />
                                    <p className="text-gm-40 text-sm font-rocGroteskMedium max-sm:w-[90%] max-sm:text-center">
                                        No conversations to show yet, you can start by saying hi
                                    </p>
                                </div>
                            )}
                        </div>

                        <div className="flex items-center justify-center">
                            <div className=" fixed bottom-[10.5vh] w-[63%] max-lg:w-[90%] bg-white rounded-lg shadow-boxShadow-3 border border-slate-100 mx-auto ">
                                {doc && (
                                    <p className="w-full flex items-center gap-1.5 border-b border-slate-100 py-2 px-4 text-sm font-rocGroteskMedium bg-slate-50">
                                        <span>Talking to: </span>
                                        <span className="bg-white flex w-fit items-center gap-1.5 px-2 py-[2px] rounded-md border border-slate-200">
                                            {displayFileIcon(getFileTypeFromUrl(doc), true)}
                                            {truncate(docName, {length: 35})}
                                        </span>
                                    </p>
                                )}
                                <div className="flex justify-between items-center w-full p-4">
                                    <div className="flex items-center gap-3 w-full">
                                        {(chats?.[chats?.length - 1]?.conversationStage?.stage ===
                                            1 ||
                                            !chats?.[chats?.length - 1]?.conversationStage
                                                ?.stage) && (
                                            <div className="relative">
                                                <i
                                                    onClick={() => {
                                                        setAttachDocModal(true);
                                                    }}
                                                    className="ri-attachment-2 cursor-pointer text-slate-500 text-xl"
                                                ></i>
                                            </div>
                                        )}
                                        <textarea
                                            onKeyUp={(e) => {
                                                adjustHeight(e.target);
                                            }}
                                            onKeyDown={(e) => {
                                                if (
                                                    e.key?.toLowerCase() === "enter" &&
                                                    !e.shiftKey
                                                ) {
                                                    e.preventDefault();
                                                    if (Boolean(message)) {
                                                        send?.(message);
                                                    }
                                                }
                                            }}
                                            id="chatInput"
                                            placeholder="How can your Co-Pilot help?"
                                            name="chatInput"
                                            value={message}
                                            disabled={chats?.slice(-1)?.[0]?.loading}
                                            onChange={(evt) => {
                                                handleChatChange(evt.target.value);
                                            }}
                                            rows={1}
                                            className="border-0 w-full overflow-y-auto resize-none min-h-[24px] max-h-[150px] text-sm placeholder:text-base bg-transparent p-0 pr-10 outline-none focus:border-0 focus:ring-0 focus-visible:ring-0 dark:bg-transparent"
                                        />
                                    </div>
                                    <div
                                        onClick={() => {
                                            if (Boolean(message)) {
                                                send?.(message);
                                            } else {
                                                toast.custom((t) => (
                                                    <CustomToast
                                                        t={t}
                                                        message={"Please type a message"}
                                                        type={"error"}
                                                    />
                                                ));
                                            }
                                        }}
                                    >
                                        {sendingAiMessage ? (
                                            <Loader size={4} />
                                        ) : (
                                            <i className="ri-send-plane-2-fill cursor-pointer bg-clip-text text-transparent text-xl bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%"></i>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="px-6 py-4 border-t">
                        <p className="text-xs font-rocGroteskMedium text-center text-slate-500">
                            As an AI model still in training, SynthAlly Co-Pilot may produce
                            inaccurate information about people, places, or facts.
                        </p>
                    </div>
                </div>
            </div>

            <ModalContainer
                open={attachDocModal}
                showCloseIcon={false}
                closeModal={() => {
                    setDocData(null);
                    setAttachDocModal(false);
                }}
                tailwindClassName="w-[40%] max-sm:w-[100%]"
            >
                <div className="bg-white flex flex-col rounded-[20px] w-[530px] ">
                    <form className="bg-white shadow-cardShadow relative rounded-[20px] py-8 ">
                        <div
                            onClick={() => {
                                setDocData(null);
                                setAttachDocModal(false);
                            }}
                            className="cursor-pointer  px-6 flex justify-end "
                        >
                            <i
                                onClick={() => {
                                    setDocData(null);
                                    setAttachDocModal(false);
                                }}
                                className="ri-close-fill text-gm-45 text-[30px]"
                            ></i>
                        </div>
                        <p className="text-xl font-rocGroteskMedium text-center mb-6">
                            Upload File
                        </p>
                        <div className="relative px-10 ">
                            <div className="flex flex-col items-center cursor-pointer justify-center mb-4 py-12 px-6 text-center text-sm rounded border border-dashed border-slate-200">
                                <i className="ri-upload-cloud-2-line text-slate-500 text-4xl mb-4"></i>
                                <p className="mb-1 font-rocGroteskMedium">
                                    <span>Click to upload</span>{" "}
                                    <span className="text-tradeally-neutral-200">
                                        or drag & drop
                                    </span>
                                </p>
                                <p className="text-tradeally-neutral-200  font-rocGroteskMedium">
                                    Supported formats: PDF, CSV, DOCX & TXT. File size limit: 25MB
                                </p>
                            </div>
                            <input
                                type="file"
                                accept=".pdf,.doc,.docx,.csv"
                                className="absolute top-0 w-full h-full opacity-0 outline-none cursor-pointer"
                                id="uploadDoc"
                                onChange={(evt) => {
                                    if (
                                        !isFileSizeLessThanOrEqualTo(
                                            evt.target.files?.[0] as File,
                                            25
                                        )
                                    ) {
                                        toast.custom((t) => (
                                            <CustomToast
                                                t={t}
                                                message={"File to large"}
                                                type={"error"}
                                            />
                                        ));
                                        return;
                                    }

                                    if (evt.target.files?.[0]) {
                                        setDocData(evt.target.files?.[0]);
                                    }
                                }}
                            />
                        </div>
                        {docData && (
                            <div className="mt-4 p-4 rounded border border-slate-200 mx-10 flex items-center gap-4">
                                {displayFileIcon(docData?.type?.split("/")?.slice(-1)?.[0], null)}
                                <div className="w-full flex justify-between items-start">
                                    <div>
                                        <p className="text-sm font-rocGroteskMedium ">
                                            {truncate(docData?.name, {length: 34})}
                                        </p>
                                        <p className="text-xs font-rocGroteskMedium text-tradeally-neutral-200">
                                            {convertBytes(docData?.size)}
                                        </p>
                                    </div>
                                    <i
                                        onClick={() => {
                                            const loading = uploading || sendingAiMessage;
                                            if (!loading) {
                                                setDocData(null);
                                            }
                                        }}
                                        className="ri-delete-bin-line text-slate-500 cursor-pointer"
                                    ></i>
                                </div>
                            </div>
                        )}
                        <div className="px-10 mt-6">
                            <Button
                                btnText={`Attach document`}
                                type={"button"}
                                btnClassname={" mb-3"}
                                onClick={() => handleUpload(docData?.name, docData)}
                                disabled={!Boolean(docData) || uploading || sendingAiMessage}
                                isLoading={uploading || sendingAiMessage}
                            />
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                onClick={() => {
                                    setDocData(null);
                                    setAttachDocModal(false);
                                }}
                                disabled={uploading || sendingAiMessage}
                            />
                        </div>
                    </form>
                </div>
            </ModalContainer>
        </>
    );
};

export default CopilotChat;
