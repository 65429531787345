/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

type AddressInputProps = {
    name: string;
    setData: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
    savedAddress?: string;
    placeholder?: string;
    classNames?: string;
    dropdownClassName?: string;
    required?: boolean;
    resetLocation?: boolean;
    disabled?: boolean;
    setResetLocation?: (value: boolean) => void;
};

const AddressInput = ({
    name,
    setData,
    savedAddress,
    placeholder,
    classNames,
    dropdownClassName,
    required,
    resetLocation,
    disabled,
    setResetLocation,
}: AddressInputProps) => {
    const [address, setAddress] = useState("");
    const [isFocused, setIsFocused] = useState(false);

    const handleChange = (address: string) => {
        setAddress(address);
        if (address === "") {
            setData((prev) => ({
                ...prev,
                [name]: {},
            }));
        }
        resetLocation && setResetLocation(false);
    };

    const handleSelect = (address: string) => {
        setAddress(address);
        resetLocation && setResetLocation(false);
        geocodeByAddress(address)
            .then((results) => {
                setData((data) => ({
                    ...data,
                    [name]: { address, details: results },
                }));

                return getLatLng(results[0]);
            })
            .then((latLng) => {
                setData((data) => ({
                    ...data,
                    [name]: { ...data[name], ...latLng },
                }));
            })
            .catch((error) => console.error("Error", error));
    };

    useEffect(() => {
        if (savedAddress) {
            setAddress(savedAddress);
            handleSelect(savedAddress);
        }
    }, [savedAddress]);

    useEffect(() => {
        if (resetLocation) {
            setAddress("");
            setData((prev) => ({
                ...prev,
                [name]: {},
            }));
        }
    }, [resetLocation]);

    return (
        <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
            googleCallbackName="initMap"
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className="w-full relative">
                    <div
                        className={`w-full border-[0.5px] rounded ${
                            isFocused ? "!border-g-60 !border-b-[1px]" : "border-gm-25"
                        }`}
                    >
                        <input
                            {...getInputProps({
                                // placeholder: placeholder,
                                className: "location-search-input ",
                            })}
                            required={required}
                            className={`inputText bg-transparent mt-[6px] mb-[-6px] py-3 px-3 rounded outline-0 outline-none w-full h-[50px] text-sm font-rocGroteskRegular placeholder:font-rocGroteskRegular placeholder:text-gm-35 ${classNames}`}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            disabled={disabled}
                        />
                        {placeholder && (
                            <span className="floating-label text-sm text-gm-35 font-rocGroteskRegular">
                                {placeholder}
                                {required && !isFocused && (
                                    <span className="required text-r-50 text-sm mt-[-2px] font-rocGroteskMedium">
                                        *
                                    </span>
                                )}
                            </span>
                        )}
                    </div>

                    {(loading || suggestions?.length > 0) && (
                        <div
                            className={`absolute top-full z-[200] border bg-white w-full min-h-[170px] text-[13px] ${dropdownClassName}`}
                        >
                            {loading && (
                                <div className="text-center h-[150px] flex items-center justify-center">
                                    Loading...
                                </div>
                            )}
                            {!loading &&
                                suggestions?.map((suggestion, index) => {
                                    const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                    const style = suggestion.active
                                        ? {
                                              backgroundColor: "#fafafa",
                                              cursor: "pointer",
                                          }
                                        : {
                                              backgroundColor: "#ffffff",
                                              cursor: "pointer",
                                          };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className: `${className} font-rocGroteskRegular p-2`,
                                                style,
                                            })}
                                            key={suggestion.description + index}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                        </div>
                    )}
                </div>
            )}
        </PlacesAutocomplete>
    );
};

export default AddressInput;
