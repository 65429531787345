import Loader from "components/common/Loader";
import {fbDownload} from "helpers/fileDownload";
import {truncate} from "lodash";
import React, {useState, useRef, useEffect} from "react";
import {workFlowActions} from "redux/Ldb/actions";
import {firebaseService} from "services/firebaseService";
import {useAppDispatch} from "../../../../../hooks/storeHooks";

type DocumentsProps = {
    documents: {[key: string]: any}[];
    profile: {[key: string]: any};
    taskId: string;
};

const Documents = ({documents, profile, taskId}: DocumentsProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();

    const [uploadingPdf, setUploadingPdf] = useState<boolean | undefined>();
    const [attachment, setAttachment] = useState<{[key: string]: any}[]>(documents);
    const [sendAttachment, setSendAttachment] = useState<{[key: string]: any}[]>([]);

    const handleUpload = (fileName: string, file: File, idx?: number) => {
        if (fileName && file) {
            setUploadingPdf(true);
            firebaseService.uploadFile(
                `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/task/${
                    profile?.profile?.businessName
                }/createdByDocument/${fileName}`,
                file,
                (url) => {
                    if (idx !== undefined) {
                        const updatedDocument = [...attachment];
                        updatedDocument[idx] = {
                            path: url,
                            name: fileName,
                        };
                        setAttachment(updatedDocument);
                        setSendAttachment(updatedDocument);
                    } else {
                        setAttachment((prev) => [...prev, {path: url, name: fileName}]);
                        setSendAttachment((prev) => [...prev, {path: url, name: fileName}]);
                    }

                    setUploadingPdf(false);
                }
            );
        }
    };

    useEffect(() => {
        if (sendAttachment.length > 0) {
            const body = {
                documents: attachment,
            };
            dispatch(workFlowActions.updateEventAndAction(taskId, body));
        }
    }, [sendAttachment]);

    const downloadFile = (idx: number) => {
        const fileUrl = attachment[idx]?.path;
        const fileName = attachment[idx]?.name;
        fbDownload(fileUrl, fileName);
    };

    return (
        <div>
            {attachment?.length > 0 ? (
                <div>
                    <div className="mt-7 mb-10 flex gap-2 flex-col">
                        {attachment?.map((item, index) => {
                            return (
                                <div
                                    key={item?._id}
                                    className="flex gap-2 flex-col font-rocGroteskMedium text-sm"
                                >
                                    <div className="border-[#F1F5F9] border-[1px] rounded-[4px] w-[60%] sm:w-[400px] p-4 flex justify-between items-center">
                                        <div className="flex gap-x-[11px]">
                                            <img
                                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700580775/pdf-document_kqjkit.svg"
                                                alt=""
                                            />

                                            <span className="text-[#04080B] font-rocGroteskMedium text-sm ">
                                                {truncate(item?.name, {length: 35})}
                                            </span>
                                        </div>
                                        <div className="flex gap-2 items-center">
                                            <div>
                                                <input
                                                    type="file"
                                                    id={`changeFile-${index}`}
                                                    className="hidden"
                                                    onChange={(e) => {
                                                        if (e.target.files?.[0]) {
                                                            handleUpload(
                                                                e.target.files?.[0]?.name,
                                                                e.target.files?.[0],
                                                                index
                                                            );
                                                        }
                                                    }}
                                                    disabled={uploadingPdf}
                                                />
                                                <label
                                                    htmlFor={`changeFile-${index}`}
                                                    className=" underline text-p-55 right-4 top-4 !text-xs cursor-pointer"
                                                >
                                                    Change File
                                                </label>
                                            </div>

                                            <div className="">
                                                <input
                                                    id="file"
                                                    type="file"
                                                    className="hidden"
                                                    accept=".pdf"
                                                ></input>

                                                <button
                                                    className="cursor-pointer"
                                                    disabled={uploadingPdf}
                                                    onClick={() => downloadFile(index)}
                                                >
                                                    <i className="ri-download-cloud-2-line text-p-55"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>{" "}
                    <div className="mt-4">
                        <input
                            type="file"
                            id="newFile"
                            ref={fileInputRef}
                            style={{display: "none"}}
                            onChange={(e) => {
                                if (e.target.files?.[0]) {
                                    handleUpload(e.target.files?.[0]?.name, e.target.files?.[0]);
                                }
                            }}
                            accept=".pdf"
                        />
                        <label htmlFor="newFile" className="">
                            <i className="ri-attachment-2 text-[#5452BF] cursor-pointer"></i>
                        </label>

                        <span className="pl-2 font-rocGroteskMedium text-base text-slate-900">
                            Add an attachment
                        </span>
                    </div>
                </div>
            ) : (
                <div className="mx-auto  flex justify-center text-center items-center flex-col max-w-[273px] font-rocGroteskBold mt-8 mb-[90px]">
                    <i className="ri-file-add-line text-2xl text-gm-50 mb-[15px]"></i>
                    <h3 className="text-black font-rocGroteskMedium text-[14px] mb-2 text-base">
                        No Documents found
                    </h3>
                    <p className="text-slate-500 text-sm font-rocGroteskMedium mb-6">
                        It seems like there are no documents attached to this task yet.
                    </p>
                    <div className="">
                        <input
                            type="file"
                            id="newFile"
                            ref={fileInputRef}
                            style={{display: "none"}}
                            onChange={(e) => {
                                if (e.target.files?.[0]) {
                                    handleUpload(e.target.files?.[0]?.name, e.target.files?.[0]);
                                }
                            }}
                            accept=".pdf"
                        />

                        {uploadingPdf ? (
                            <Loader size={4} />
                        ) : (
                            <label
                                htmlFor="newFile"
                                className="pl-2 font-rocGroteskMedium text-[13px] leading-[24px] text-[#142837] py-2 px-4
                            border border-n-20 rounded cursor-pointer"
                            >
                                Add an attachment
                            </label>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Documents;
