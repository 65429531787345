import React, { useState, useRef, useEffect } from "react";

import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import TabsContainer from "components/common/TabsContainer";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import VendorAvatar from "components/common/VendorAvatar";
import Loader from "components/common/Loader";

const ShareModal = ({ openModal, closeModal }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showCollaborators, setShowCollaborators] = useState(false);

    const [open, setOpen] = useState(false);

    return (
        <ModalContainer
            open={openModal}
            showCloseIcon={false}
            closeModal={closeModal}
            tailwindClassName="w-[90%] md:w-[609px]"
        >
            <div className="bg-white flex h-full  flex-col rounded-[20px] max-h-[90vh] overflow-y-scroll px-8">
                <div className="mt-[15px] mb-5 flex justify-end">
                    <i
                        onClick={closeModal}
                        className="ri-close-fill cursor-pointer text-gm-45 text-2xl"
                    ></i>
                </div>
                <div className="mx-auto text-center mb-5">
                    <h1 className="text-g-75 text-2xl font-rocGroteskMedium">
                        {" "}
                        Share analytics with team members
                    </h1>
                    <p className="mt-[10px] text-gm-40 font-rocGroteskMedium text-sm">
                        Collaborate with a team member on your business analytics
                    </p>
                </div>
                <div className="">
                    <TextInput
                        type="text"
                        placeholder="Add email (separate multiple emails using a comma)"
                        className="!text-xs placeholder:text-[#89939B] w-full border border-[#89939B] py-[13px] pl-3 pr-4
                            mb-5"
                        name="email"
                        inputContainerClassname="!h-auto !rounded-none"
                        // value={createTasks?.name || ""}
                        // onChange={handleChange}
                    />
                    <div className=" mb-5">
                        <div className="flex justify-between items-center pt-5 pb-3 border-b border-b-slate-200">
                            <p className="text-gm-40 text-sm font-rocGroteskMedium">
                                {" "}
                                Collaborators already on this analytics
                            </p>
                            <button
                                onClick={() => setShowCollaborators(!showCollaborators)}
                                className={`${!showCollaborators && "scale-y-[-1]"}`}
                            >
                                <i className="ri-arrow-up-s-line text-2xl"></i>
                            </button>
                        </div>
                    </div>
                    {showCollaborators && (
                        <div className="border-t border-t-slate-100 transition-all ease-in-out ">
                            <div className="border-b border-b-slate-100 flex gap-3 items-center p-4">
                                <VendorAvatar
                                    size="24"
                                    containerClassname="rounded-full"
                                    name="Y"
                                    // imageSrc={profile?.profile?.avatar}
                                />
                                <p className="text-[#0F172A] font-rocGroteskMedium text-sm">
                                    Augustine asiuwhu
                                </p>
                            </div>
                            <div className="border-b border-b-slate-100 flex gap-3 items-center p-4">
                                <VendorAvatar
                                    size="24"
                                    containerClassname="rounded-full"
                                    name="Y"
                                    // imageSrc={profile?.profile?.avatar}
                                />
                                <p className="text-[#0F172A] font-rocGroteskMedium text-sm">
                                    Augustine asiuwhu
                                </p>
                            </div>
                            <div className="border-b border-b-slate-100 flex gap-3 items-center p-4">
                                <VendorAvatar
                                    size="24"
                                    containerClassname="rounded-full"
                                    name="Y"
                                    // imageSrc={profile?.profile?.avatar}
                                />
                                <p className="text-[#0F172A] font-rocGroteskMedium text-sm">
                                    Augustine asiuwhu
                                </p>
                            </div>
                        </div>
                    )}

                    {/* <div className="mt-[30px] mb-7 flex gap-[14px] flex-col">
                        <div className="flex items-center  text-sm">
                            <p className="text-[#64748B] font-rocGroteskMedium w-[166px]">
                                Reporter
                            </p>
                            {Boolean(profile) && (
                                <div className="flex items-center gap-2">
                                    
                                </div>
                            )}
                        </div>
                      </div> */}

                    <div className="flex justify-end gap-4 items-center border-t-[#E2E8F0] border-t-[1px] pt-5 pb-[22px] mt-8">
                        <Button
                            onClick={closeModal}
                            btnText="Close"
                            btnClassname="!w-auto !py-[10px] !px-4 !bg-[#fff] !text-[#252C32] border border-[#DDE2E4] rounded"
                            classname=""
                        />
                        <Button
                            // isLoading={createTask}
                            // onClick={handleSubmit}
                            btnText={"Share analytics"}
                            type={"button"}
                            btnClassname="!py-[10px] !px-4 !w-auto"
                        />
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
};

export default ShareModal;
