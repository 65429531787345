type monthFormat = "short" | "long"
export const formatDateRange = (startDate: string, endDate: string, format?: monthFormat) => {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const formattedStartDate = new Date(startDate).toLocaleDateString("en-US", {
        month: format,
        day: "numeric",
        year: startDateObj.getFullYear() !== endDateObj.getFullYear() ? "numeric" : undefined,
    });

    const formattedEndDate = new Date(endDate).toLocaleDateString("en-US", {
        month: format,
        day: "numeric",
        year: "numeric",
    });

    const formattedRange = `${formattedStartDate} - ${formattedEndDate}`;

    return formattedRange;
};
