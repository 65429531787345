import ProgressBar from "components/common/ProgressBar";
import GenerateWithAI from "components/views/Ldb/Dashboard/Orders/CreateRfqs/GenerateWithAI";
import InitialScreen from "components/views/Ldb/Dashboard/Orders/CreateRfqs/InitialScreen";
import Manual from "components/views/Ldb/Dashboard/Orders/CreateRfqs/Manual";
import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

const CreateRfqs = () => {
    const [activeScreen, setActiveScreen] = useState(0);
    const [selectedOption, setSelectedOption] = useState("");
    const [step, setStep] = useState(1);
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const createBy = param.get("by");

    const displayActiveScreen = () => {
        switch (activeScreen) {
            case 0:
                return (
                    <InitialScreen
                        setStep={setStep}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                    />
                );

            case 1:
                return <GenerateWithAI />;

            case 3:
                return <Manual setStep={setStep} />;

            default:
                return (
                    <InitialScreen
                        setStep={setStep}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                    />
                );
        }
    };

    useEffect(() => {
        switch (createBy) {
            case "AI":
                setActiveScreen(1);
                break;
            case "document":
                setActiveScreen(1);
                break;
            case "manual":
                setActiveScreen(3);
                break;
            default:
                setActiveScreen(0);
                break;
        }
    }, [createBy]);

    return (
        <div className="pt-6 px-16 max-lg:px-0">
            <div className="bg-white rounded-lg h-full shadow-cardShadow">
                <div className="  px-8 py-4 flex items-center justify-between">
                    <p className="text-base font-rocGroteskMedium text-slate-700 ">
                        Request a quote
                    </p>
                    <i
                        onClick={() => {
                            if (Boolean(createBy)) {
                                navigate("/dashboard/rfqs/create", {replace: true});
                                setStep(1);
                                setActiveScreen(0);
                            } else {
                                navigate("/dashboard/rfqs");
                            }
                        }}
                        className="ri-close-fill text-xl text-gm-50 cursor-pointer"
                    ></i>
                </div>
                {activeScreen !== 1 && (
                    <ProgressBar progress={Number(((step / 3) * 100).toFixed())} />
                )}
                {displayActiveScreen()}
            </div>
        </div>
    );
};

export default CreateRfqs;
