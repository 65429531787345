import React, { createContext, useContext, useState, Dispatch, SetStateAction } from "react";

interface NavContextType {
    navName: string;
    setNavName: Dispatch<SetStateAction<string>>;
    onClick: () => void;
    setOnClick: Dispatch<SetStateAction<() => void>>;
}

const NavContext = createContext<NavContextType>({
    navName: "",
    setNavName: () => {},
    onClick: () => {},
    setOnClick: () => {},
});

export const useNav = () => useContext(NavContext);

export const NavProvider = ({ children }) => {
    const [navName, setNavName] = useState("");
    const [onClick, setOnClick] = useState(() => () => {});

    return (
        <NavContext.Provider value={{ navName, setNavName, onClick, setOnClick }}>
            {children}
        </NavContext.Provider>
    );
};
