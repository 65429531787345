import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import {getCurrencyFromCurrencyCode} from "helpers";
import {reduce, truncate} from "lodash";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {orderActions} from "redux/Ldb/actions";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/storeHooks";

interface OrderSummaryCardTwoProps {
    order: {[key: string]: any};
    id: string | number;
}

const OrderSummaryCardTwo = ({order, id}: OrderSummaryCardTwoProps) => {
    const [activeOrder, setActiveOrder] = useState<string | number | null>(null);
    const [activeModalId, setActiveModalId] = useState<string | number | null>(null);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const [updateOrderData, setUpdateOrderData] = useState({
        budget: "",
        currency: "",
        orderType: "",
    });
    const [itemData, setItemData] = useState<{[key: string]: any}[]>([
        {
            description: "",
            qty: "",
            _id: "",
        },
    ]);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const title = order?.items?.map((item: {[key: string]: any}) => item?.description)?.join(", ");
    const quantities = order?.items?.map((item: {[key: string]: any}) => Number(item?.quantity));
    const quantitiesTotal = reduce(
        quantities,
        function (sum, n) {
            return sum + n;
        },
        0
    );

    const {sendingRfqs, updatingOrder, updateOrderSuccess} = useAppSelector((state) => state.order);

    const handleItemChange = (idx: number, name: string, value: string | string[]) => {
        const itemDataCopyArr = [...itemData];
        const itemDataCopy = itemDataCopyArr?.[idx];
        itemDataCopy[name] = value;

        setItemData(itemDataCopyArr);
    };

    const handleUpdateOrder = () => {
        const body: {[key: string]: any} = {
            items: itemData
                ?.map((item) => {
                    const obj: {[key: string]: any} = {};

                    if (Boolean(item?.description) && Boolean(item?.qty)) {
                        obj.description = item?.description;
                        obj.quantity = item.qty;
                    }

                    if (Boolean(item?.description) && Boolean(item?.qty) && Boolean(item?._id)) {
                        obj._id = item?._id;
                    }

                    return obj;
                })
                ?.filter((data) => data?.description && data?.quantity),
            budget: {
                currency: updateOrderData?.currency,
                amount: updateOrderData?.budget,
            },
            orderType: updateOrderData?.orderType,
        };

        dispatch(orderActions.updateOrder(order?._id, body));
    };

    const tableHeader = [
        { title: "Item name", widthClass: "w-[90%] max-md:w-[75%]" },
        { title: "QTY", widthClass: "w-[10%] max-md:w-[25%]" },
    ];

    const tableBody = itemData?.map((item, idx) => [
        {
            content: (
                <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                    <TextInput
                        value={item?.description}
                        name={"description"}
                        type={"text"}
                        onChange={(e) => handleItemChange(idx, e.target.name, e.target.value)}
                        placeholder={"Item description"}
                        containerClassname=""
                        inputContainerClassname={"!border-0 !rounded-none"}
                        inputClassName="w-full "
                    />
                </div>
            ),
        },
        {
            content: (
                <div className={`w-full h-full border-slate-100 text-sm text-slate-700 `}>
                    <TextInput
                        value={item?.qty}
                        name={"qty"}
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(e) => handleItemChange(idx, e.target.name, e.target.value)}
                        onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                            evt.preventDefault()
                        }
                        placeholder={"Qty"}
                        containerClassname=""
                        inputContainerClassname={"!border-0 !rounded-none"}
                        inputClassName="w-full "
                    />
                    {idx !== 0 && (
                        <i
                            onClick={() => {
                                const itemDataCopy = [...itemData];
                                itemDataCopy.pop();
                                setItemData(itemDataCopy);
                            }}
                            className="ri-delete-bin-line cursor-pointer text-slate-500 absolute right-[-20px]"
                        ></i>
                    )}
                </div>
            ),
        },
    ]);

    const handleSendRfqs = useCallback(() => {
        const body = {
            itemsAndVendors: order?.items?.map((service: {[key: string]: any}) => ({
                item: service?.description,
                quantity: service?.quantity,
                vendors: order.suggestedVendors?.map((vendor: {[key: string]: any}) => vendor?._id),
            })),
            deliveryDate: order?.deliveryDate,
            specifications: order?.specifications,
            orderType: order?.orderType,
            customerDetails: {
                email: order?.customerDetails?.email,
                phone: {
                    mobile: order?.customerDetails?.phone?.mobile,
                    rawMobile: order?.customerDetails?.phone?.rawMobile,
                    mobileExtension: order?.customerDetails?.phone?.mobileExtension,
                },
                country: order?.customerDetails?.country,
                firstNane: order?.customerDetails?.firstNane,
                lastName: order?.customerDetails?.lastName,
                companyName: order?.customerDetails?.companyName,
            },
            // suggestedVendors: ["64da1b1a2bdbe500e2760f27"],
            budget: {
                currency: order?.budget?.currency,
                amount: order?.budget?.amount,
            },
        };

        dispatch(orderActions.sendRfqs(body));
    }, [order, dispatch]);

    useEffect(() => {
        if (activeOrder === id) {
            handleSendRfqs();
        }
    }, [activeOrder, handleSendRfqs, id]);

    useEffect(() => {
        if (activeModalId === id) {
            setEditModalOpen(true);
        }
    }, [activeModalId, id]);

    useEffect(() => {
        if (Boolean(updateOrderSuccess)) {
            setEditModalOpen(false);
            dispatch(orderActions.resetUpdateOrderSuccess());
        }
    }, [dispatch, updateOrderSuccess]);

    useEffect(() => {
        const data = order?.items?.map((item: {[key: string]: any}) => ({
            description: item?.description,
            qty: item?.quantity,
            _id: item?._id,
        }));
        setItemData(data);
        setUpdateOrderData({
            budget: order?.budget?.amount,
            currency: order?.budget?.currency,
            orderType: order?.orderType,
        });
    }, [order]);

    return (
        <div>
            <div
                className="p-6 border-b border-slate-100 cursor-pointer hover:bg-slate-50"
                onClick={() => navigate(`/dashboard/order/${order?._id}`)}
            >
                <div className="flex items-center justify-between mb-4">
                    <div>
                        <p className="text-xs font-rocGroteskMedium text-slate-700 mb-2">
                            #Order-{order?.taId}
                        </p>
                        <p className="text-base font-rocGroteskMedium text-slate-700 mb-2">
                            {truncate(title, {length: 53})}
                        </p>
                        <div className="flex items-center gap-2 max-md:flex-col max-md:items-start">
                            <div className="py-1 px-2 bg-slate-100 rounded">
                                <p className="text-xs text-gm-50 font-rocGroteskMedium">
                                    Quantity: {quantitiesTotal}
                                </p>
                            </div>
                            <div className="py-1 px-2 bg-slate-100 rounded">
                                <p className="text-xs text-gm-50 font-rocGroteskMedium">
                                    Est. Budget:{" "}
                                    {order?.budget?.amount
                                        ? getCurrencyFromCurrencyCode(order?.budget?.currency) +
                                          order?.budget?.amount
                                        : "N/A"}
                                </p>
                            </div>
                            <div className="py-1 px-2 bg-slate-100 rounded">
                                <p className="text-xs text-gm-50 font-rocGroteskMedium">
                                    Type of Order: {order?.orderType}
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="w-[34px] h-[34px] border rounded-full border-slate-100 flex items-center justify-center">
                        <i className="ri-thumb-down-line"></i>
                    </div> */}
                </div>
                <div className="mb-1.5">
                    <div className="flex flex-col gap-2 text-sm">
                        <div className="flex items-center gap-1.5">
                            <i className="ri-list-radio"></i>
                            <p>
                                <span className="text-gm-50">Items/Services:</span>{" "}
                                <span className="text-slate-500">
                                    {truncate(title, {length: 53})}
                                </span>
                            </p>
                        </div>
                        <div className="flex items-center gap-1.5">
                            <i className="ri-file-list-3-line"></i>
                            <p>
                                <span className="text-gm-50">Specifications:</span>{" "}
                                <span className="text-slate-500">
                                    {truncate(order?.specifications, {length: 53})}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mb-4 max-md:my-4">
                    <div
                        className={` flex space-x-1 items-center border-slate-100 text-sm text-slate-700 `}
                    >
                        <div className="flex items-center gap-1">
                            {order?.suggestedVendors
                                ?.slice(0, 8)
                                ?.map((vendor: {[key: string]: any}, idx: number) => (
                                    <img
                                        key={idx + vendor?.profile?.avatar}
                                        src={vendor?.profile?.avatar}
                                        width={24}
                                        height={24}
                                        alt="logo"
                                    />
                                ))}
                        </div>
                        {order?.suggestedVendors?.slice(8)?.length > 0 && (
                            <div className="h-[32px] flex items-center justify-center w-[32px] border border-white bg-n-20">
                                <p className="font-rocGroteskMedium text-gm-50 text-[10px] rounded-[4px]">
                                    +{order?.suggestedVendors?.slice(8)?.length}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <p className="text-xs font-rocGroteskMedium text-slate-700 mb-[10px]">Ordered by</p>
                <div className="flex items-center justify-between max-md:flex-col max-md:items-start">
                    <div className="flex items-center gap-3">
                        <img
                            src={
                                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1694624630/Tradeally_2.0/undefined_-_Imgur_1_prpxel.jpg"
                            }
                            width={32}
                            height={32}
                            alt=" logo"
                        />
                        <div>
                            <p className="text-sm text-gm-50 font-rocGroteskMedium">
                                {order?.customerDetails?.companyName}
                            </p>
                            <p className="text-xs text-slate-500 font-rocGroteskMedium">
                                {order?.customerDetails?.email}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center gap-2 max-md:mt-5">
                        <Button
                            btnText={"Edit order"}
                            type={"button"}
                            btnClassname="!py-2 !px-6 bg-n-20 !text-gm-50 !text-[13px] "
                            onClick={(e) => {
                                e.stopPropagation();
                                setActiveModalId(id);
                            }}
                        />
                        <Button
                            btnText={"Request for quote"}
                            type={"button"}
                            btnClassname="!py-2 !px-3 !text-[13px] whitespace-nowrap"
                            onClick={(e) => {
                                e.stopPropagation();
                                setActiveOrder(id);
                            }}
                            isLoading={sendingRfqs && activeOrder === id}
                        />
                    </div>
                </div>
            </div>

            <ModalContainer
                open={editModalOpen && activeModalId === id}
                showCloseIcon={false}
                tailwindClassName="w-[68%] max-lg:w-[95%]"
                closeModal={() => {
                    setEditModalOpen(false);
                    setActiveModalId(null);
                }}
            >
                <div className="bg-white shadow-cardShadow relative rounded-[6px]">
                    <div className="px-8 py-6 flex items-center justify-between border-b border-slate-100">
                        <div className=" w-full">
                            <p className="text-base font-rocGroteskMedium text-slate-700">
                                Edit order details
                            </p>
                        </div>
                        <i
                            className="ri-close-fill text-xl cursor-pointer"
                            onClick={() => {
                                setEditModalOpen(false);
                                setActiveModalId(null);
                            }}
                        ></i>
                    </div>
                    <div className="min-h-[370px] max-h-[450px] p-8 overflow-auto scrollbar-hide">
                        <div className="grid grid-cols-3 gap-4 mb-6 max-md:grid-cols-1">
                            <TextInput
                                value={order?.taId}
                                name={"orderId"}
                                type={"text"}
                                placeholder={"Order ID"}
                                disabled={true}
                                containerClassname=""
                            />

                            <div className="w-full grid grid-cols-[115px_calc(100%-119px)] gap-1 ">
                                <SelectInput
                                    value={updateOrderData?.currency}
                                    name="currency"
                                    placeholder={"($) USD"}
                                    handleChange={(name, value) =>
                                        setUpdateOrderData((prev) => ({
                                            ...prev,
                                            [name]: value,
                                        }))
                                    }
                                    isRequired={false}
                                    dropdownOptions={[
                                        {
                                            label: "GBP (£)",
                                            value: "GBP",
                                        },
                                        {
                                            label: "USD ($)",
                                            value: "USD",
                                        },
                                        {
                                            label: "EUR (€)",
                                            value: "EUR",
                                        },
                                        {
                                            label: "NGN (₦)",
                                            value: "NGN",
                                        },
                                    ]}
                                />
                                <TextInput
                                    value={updateOrderData?.budget}
                                    name={"budget"}
                                    type={"number"}
                                    onWheel={(e: any) => e.target.blur()}
                                    onChange={(e) =>
                                        setUpdateOrderData((prev) => ({
                                            ...prev,
                                            [e.target.name]: e.target.value,
                                        }))
                                    }
                                    placeholder={"Est. Budget"}
                                    // required={true}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    pattern={"^[0-9]+$"}
                                    title={"must be digits"}
                                    containerClassname=""
                                />
                            </div>

                            <SelectInput
                                value={updateOrderData?.orderType}
                                name="orderType"
                                placeholder={"Type of Order"}
                                className=""
                                handleChange={(name, value) =>
                                    setUpdateOrderData((prev) => ({...prev, [name]: value}))
                                }
                                // isRequired={true}
                                dropdownOptions={[
                                    {
                                        label: "Supply",
                                        value: "supply",
                                    },
                                    {
                                        label: "Logistics",
                                        value: "logistics",
                                    },
                                ]}
                            />
                        </div>
                        <div>
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                tableClass=" w-full"
                                isScrollable={false}
                                isCellBordered={true}
                            />
                            <div
                                onClick={() =>
                                    setItemData((prev) => [
                                        ...prev,
                                        {description: "", qty: "", _id: ""},
                                    ])
                                }
                                className="mt-4 flex items-center w-fit gap-1 cursor-pointer"
                            >
                                <i className="ri-add-fill"></i>
                                <p className="text-sm font-rocGroteskMedium text-gm-50">
                                    Add more items
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full justify-end gap-3 py-4 px-6 border-t border-slate-100">
                        <Button
                            btnClassname="!bg-n-20 !text-gm-50 !w-[150px]"
                            btnText={"Close"}
                            onClick={() => {
                                setEditModalOpen(false);
                                setActiveModalId(null);
                            }}
                            type={"button"}
                        />
                        <Button
                            btnText={"Save changes"}
                            isLoading={updatingOrder}
                            onClick={handleUpdateOrder}
                            type={"button"}
                            btnClassname="!w-[150px]"
                        />
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default OrderSummaryCardTwo;
