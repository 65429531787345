import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer";
import rocGroteskLight from "./../../../../../assets/fonts/rocGrotesk/RocGrotesk-Light.otf";
import rocGroteskRegular from "./../../../../../assets/fonts/rocGrotesk/RocGrotesk-Regular.otf";
import rocGroteskMedium from "./../../../../../assets/fonts/rocGrotesk/RocGrotesk-Medium.otf";
import rocGroteskBold from "./../../../../../assets/fonts/rocGrotesk/RocGrotesk-Bold.otf";

Font.register({
    family: "roc-grotesk",
    fonts: [
        {
            src: rocGroteskLight,
            fontWeight: "light",
        },
        {
            src: rocGroteskRegular,
            fontWeight: "normal",
        },
        {
            src: rocGroteskMedium,
            fontWeight: "medium",
        },
        {
            src: rocGroteskBold,
            fontWeight: "bold",
        },
    ],
});

Font.register({
    family: "Roboto",
    fonts: [
        {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
            fontWeight: 500,
        },
    ],
});

const signFix = StyleSheet.create({
    page: {
        fontFamily: "Roboto",
        fontWeight: 500,
    },
});

const styles = StyleSheet.create({
    page: {
        flexDirection: "row",
        backgroundColor: "#fff",
        color: "#000000",
        fontWeight: 400,
        fontFamily: "roc-grotesk",
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    innerSection: {
        flexDirection: "row",
        alignItems: "center",
        gap: 3,
    },
    outerSection: {
        marginTop: 50,
    },
    color: {
        width: 6,
        height: 6,
        borderRadius: 2,
        backgroundColor: "#000000",
        marginBottom: 3,
    },
    catText: {
        color: "#64748B",
        fontSize: 10,
        fontWeight: "medium",
    },
    amountSpent: {
        color: "#475569",
        fontSize: 8,
        fontWeight: "bold",
    },
    coverPage: {
        width: "100%",
        backgroundColor: "#0B213A",
        color: "#fff",
        flexDirection: "row",
    },
    image: {
        width: 200,
        height: 200,
        margin: 10,
    },
    boldText: {
        fontWeight: "bold",
    },
    footer: {
        paddingTop: 150,
        paddingLeft: 20,
        backgroundColor: "#0B213A",
        color: "#fff",
        width: "100%",
    },
    footerLogo: {
        paddingBottom: 80,
        width: "7%",
    },
    footerText: {
        paddingBottom: 136,
    },
    divider1: {
        backgroundColor: "#12ad68",
        height: 15,
        width: "95%",
    },
    divider2: {
        backgroundColor: "#ceaa0c",
        height: 15,
        width: "95%",
        marginTop: 100,
    },
    flex1: {
        width: "60%",
        paddingTop: 140,
    },
    flex2: {
        flexDirection: "column",
        width: "40%",
        paddingTop: 112,
        alignItems: "center",
        gap: 8,
    },
    logo1: {
        marginLeft: 10,
        paddingTop: 50,
        marginBottom: 15,
    },
    chartName: {
        marginLeft: 40,
    },
    businessLogo: {
        height: 80,
        marginBottom: 26,
    },
    times: {
        width: 18,
    },
    logo2: {
        marginTop: 26,
        width: 100,
    },
    imgWidth: {
        width: "80%",
        marginBottom: 40,
    },
    flex: {
        display: "flex",
        gap: "2",
    },
    rowDirection: {
        flexDirection: "row",
    },
    colDirection: {
        flexDirection: "column",
    },
    flexWrap: {
        flexWrap: "wrap",
    },
    alignItems: {
        alignItems: "center",
    },
    chartDiv: {
        height: "70%",
        width: "100%",
        marginTop: 20,
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        boxShadow: "1px -6px 15px 0px rgba(0, 0, 0, 0.02), -1px 6px 15px 0px rgba(0, 0, 0, 0.02)",
        marginLeft: -80,
    },
    chartValueContainer: {
        fontSize: "18",
    },
    chartValueItem: {
        marginRight: "20",
    },
    graphValues: {
        color: "#334155",
        fontSize: 16,
    },
    pageLayout: {
        padding: "40",
    },
    container: { flexDirection: "row", alignItems: "center", gap: "22px" },
    text1: {
        color: "#0F172A",
        fontSize: 18,
        fontWeight: "medium",
    },
    infoContainer: {
        borderRadius: 4,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 5,
        borderWidth: "1px",
        borderColor: "#e2e8f0",
        borderStyle: "solid",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: 4,
    },
    icon: {
        fontSize: "18pt",
        color: "#fff",
        marginRight: "3px",
        backgroundColor: "#CBD5E1",
        width: "16",
        height: "16",
        borderRadius: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    text2: {
        color: "#142837",
        fontSize: 14,
        fontWeight: "medium",
    },
    text3: {
        color: "#142837",
        fontSize: 16,
        fontWeight: "medium",
    },
    summarySection: {
        flexDirection: "row",
        backgroundColor: "#fff",
        color: "#000",
        fontWeight: 400,
    },
    card: {
        border: "0.8px solid #e0e0e0",
        borderRadius: "8px",
        padding: "15px",
        width: "192px",
        backgroundColor: "#fff",
        margin: "10px",
        color: "#000",
        height: "100pt",
    },
    cardTitle: {
        fontSize: "12pt",
        marginBottom: "10px",
        color: "#374151",
    },
    cardValue: {
        fontWeight: "medium",
        marginBottom: "7px",
        fontSize: 40,
        color: "#22214c",
        marginTop: "7pt",
    },
    cardPercentage: {
        fontSize: "12pt",
        color: "#757575",
    },
    icon2: {
        width: "20px",
        height: "20px",
        marginRight: "5px",
    },
    left: {
        width: "60%",
    },
    right: {
        width: "40%",
        marginLeft: 100,
    },
    noChart: {
        width: "100%",
        marginLeft: 0,
    },
});

const titleStyles = StyleSheet.create({
    heading: {
        fontSize: "18px",
        fontWeight: "medium",
    },
    date: {
        fontSize: "12px",
        fontWeight: "medium",
    },
    section: {
        marginBottom: 30,
    },
    divider: {
        backgroundColor: "#F1F5F9",
        height: "1px",
        width: "100%",
    },
});

const spendStyles = StyleSheet.create({
    section: {
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    space: {
        marginTop: 20,
    },
});

const insightStyles = StyleSheet.create({
    container: {
        padding: 10,
    },
    headingContainer: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 15,
    },
    centerFlex: {
        flexDirection: "row",
        alignItems: "center",
        gap: 6,
    },
    headingImage: {
        marginRight: 5,
    },
    headingImg: {
        width: 26,
        marginTop: -10,
    },
    headingText: {
        fontWeight: "bold",
        color: "#1E293B",
        fontSize: 13,
    },
    infoSection: {
        marginBottom: 5,
    },
    infoLabel: {
        color: "#94A3B8",
        fontSize: 10,
        fontWeight: "medium",
        marginBottom: 1.5,
    },
    infoValue: {
        color: "#475569",
        fontSize: 12,
        fontWeight: "medium",
    },
    progressBarContainer: {
        flexDirection: "row",
        alignItems: "center",
        marginTop: 1,
        gap: 10,
    },
    progressBar: {
        height: 5,
        width: 200,
        borderRadius: 10,
        backgroundColor: "#16c6a4",
    },
    progressBar2: {
        height: 5,
        width: 100,
        borderRadius: 10,
        backgroundColor: "#16c6a4",
    },
    progressBarText: {
        fontSize: 10,
        color: "#6B7280",
    },
    summarySection: {
        marginTop: 6,
    },
    summaryHeader: {
        flexDirection: "row",
        alignItems: "center",
        paddingBottom: 2,
        borderBottom: "2 solid #E2E8F0",
        width: 130,
    },
    summaryImg: {
        width: 10,
        marginTop: -4,
        marginRight: 4,
    },
    summaryHeaderText: {
        color: "#475569",
        fontSize: 12,
        fontWeight: "medium",
    },
    summaryText: {
        color: "#64748B",
        fontSize: 10,
        fontWeight: "medium",
        textAlign: "left",
        marginTop: 5,
    },
    badge: {
        marginTop: -4,
        width: 7,
    },
    imgDiv: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: 200,
        width: "100%",
    },
    textImg: {
        marginTop: 19,
        fontWeight: "medium",
        fontSize: 10,
        color: "#64748B",
    },
});

const SpendAnalysisPdf = ({
    year,
    chartName,
    dateRange,
    title,
    businessLogo,
    chart,
    analysis,
    chartData,
    category,
    showCategory,
    insights,
}) => {
    return (
        <Document>
            <Page size="A4" style={styles.page} orientation="landscape">
                <View style={styles.coverPage}>
                    <View style={styles.flex1}>
                        <View style={styles.divider1} />
                        <View style={styles.divider2} />
                        <Image
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702357166/synthAlly_dcohmp.png"
                            style={styles.logo1}
                        />
                        <Text style={styles.chartName}>{`${title} report - ${year}`}</Text>
                    </View>
                    <View style={styles.flex2}>
                        <Image src={businessLogo} style={styles.businessLogo} />
                        <Image
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702359704/X_mfthox.png"
                            style={styles.times}
                        />
                        <Image
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702357366/synthAllylogo_fhimzk.png"
                            style={styles.logo2}
                        />
                    </View>
                </View>
            </Page>

            <Page
                size="A4"
                style={[styles.page, styles?.pageLayout, styles?.flex, styles?.colDirection]}
                orientation="landscape"
            >
                <View>
                    <View style={[titleStyles.section]}>
                        <Text style={titleStyles.heading}>
                            {chartName} {chart && "statistics"}
                        </Text>
                        <Text style={titleStyles.date}>{`${dateRange}`}</Text>
                    </View>

                    <View>
                        <Text style={[titleStyles.date, spendStyles.space]}>{category} </Text>
                    </View>

                    <View style={spendStyles.section}>
                        {chart && (
                            <View
                                style={[
                                    styles.chartDiv,
                                    styles.flex,
                                    styles.alignItems,
                                    styles?.left,
                                ]}
                            >
                                <Image src={chart} />
                                <View style={styles.outerSection}>
                                    {chartData &&
                                        chartData?.map((item, idx) => {
                                            return (
                                                <View style={{ marginBottom: 5 }} key={idx}>
                                                    <View style={styles.innerSection}>
                                                        <Text
                                                            style={{
                                                                backgroundColor: item.color,
                                                                width: 8,
                                                                height: 8,
                                                                borderRadius: 2,
                                                                marginBottom: 5,
                                                                marginRight: 3,
                                                            }}
                                                        ></Text>
                                                        <Text style={styles.catText}>
                                                            {item?.name}
                                                        </Text>
                                                    </View>
                                                    <Text style={styles.amountSpent}>
                                                        {` ${Math.round(
                                                            item?.value
                                                        ).toLocaleString()}`}
                                                    </Text>
                                                </View>
                                            );
                                        })}
                                </View>
                            </View>
                        )}

                        <View style={chart ? styles?.right : styles?.noChart}>
                            <View style={insightStyles.container}>
                                <View
                                    style={[
                                        insightStyles.headingContainer,
                                        insightStyles.centerFlex,
                                    ]}
                                >
                                    <Image
                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702651215/insight_rlw5sn.png"
                                        style={insightStyles.headingImg}
                                    />
                                    <Text style={insightStyles.headingText}>
                                        Supplier spend insights
                                    </Text>
                                </View>

                                {analysis?.spend_analysis?.length > 0 ||
                                analysis?.top_vendor?.totalOrders ||
                                insights?.length > 0 ? (
                                    <View>
                                       { analysis?.spend_analysis?.length > 0 && <View style={insightStyles.infoSection}>
                                            <Text style={insightStyles.infoLabel}>
                                                Top supplier category
                                            </Text>
                                            {showCategory ? (
                                                <Text style={insightStyles.infoValue}>
                                                    {category}
                                                </Text>
                                            ) : (
                                                <Text style={insightStyles.infoValue}>
                                                    {analysis?.spend_analysis?.[0].category}
                                                </Text>
                                            )}

                                            <View style={insightStyles.progressBarContainer}>
                                                <View style={insightStyles.progressBar} />
                                                <Text style={insightStyles.progressBarText}>
                                                    {`${
                                                        analysis?.spend_analysis?.[0]
                                                            .numberOfVendors || 0
                                                    } supplier(s)`}
                                                </Text>
                                            </View>
                                        </View>}
                                      { analysis?.top_vendor?.totalOrders && <View style={insightStyles.infoSection}>
                                            <View style={insightStyles.centerFlex}>
                                                <Image
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702652088/badge_wil5rr.png"
                                                    style={insightStyles.badge}
                                                />
                                                <Text style={insightStyles.infoLabel}>
                                                    Top Supplier
                                                </Text>
                                            </View>
                                            <Text style={insightStyles.infoValue}>
                                                {analysis?.top_vendor.vendorName}
                                            </Text>
                                            <View style={insightStyles.progressBarContainer}>
                                                <View style={insightStyles.progressBar2} />
                                                <Text style={insightStyles.progressBarText}>
                                                    {`${
                                                        analysis?.top_vendor.totalOrders || 0
                                                    } purchased`}
                                                </Text>
                                            </View>
                                        </View>}
                                       {insights?.length > 0 && <View style={insightStyles.summarySection}>
                                            <View style={insightStyles.summaryHeader}>
                                                <Image
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702653816/bulb_daiaa9.png"
                                                    style={insightStyles.summaryImg}
                                                />
                                                <Text style={insightStyles.summaryHeaderText}>
                                                    Insight summary
                                                </Text>
                                            </View>

                                            <View style={[signFix.page, insightStyles.summaryText]}>
                                                {insights &&
                                                    insights?.map((item, idx) => {
                                                        return <Text key={idx}> {item}</Text>;
                                                    })}
                                            </View>
                                        </View>}
                                    </View>
                                ) : (
                                    <View style={insightStyles.imgDiv}>
                                        <Image src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702157493/no_report_pf7dd2.svg" />
                                        <Text style={insightStyles.textImg}>
                                            No report generated yet
                                        </Text>
                                    </View>
                                )}
                            </View>
                        </View>
                    </View>
                </View>
            </Page>

            <Page size="A4" style={styles.page} orientation="landscape">
                <View style={styles.footer}>
                    <View style={styles.imgWidth}>
                        <Image src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702358337/thanks_yjmk0q.png" />
                    </View>
                    <View style={[styles.flex, styles.rowDirection]}>
                        <Text style={styles.boldText}>Address:</Text>
                        <Text> 7000 North Mopac Expressway, Austin TX 78759</Text>
                    </View>
                    <View style={[styles.flex, styles.rowDirection]}>
                        <Text style={styles.boldText}>Phone Number:</Text>
                        <Text> +18329972582</Text>
                    </View>
                    <View style={[styles.flex, styles.rowDirection]}>
                        <Text style={styles.boldText}>E-Mail:</Text>
                        <Text style={styles.footerText}> hello@tradeally.io</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};
export default SpendAnalysisPdf;
