import Button from "components/common/Button";
import CustomMobileTable from "components/common/CustomMobileTable";
import CustomTable from "components/common/CustomTable";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import VendorAvatar from "components/common/VendorAvatar";
import { truncate } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { orderActions } from "redux/Ldb/actions";

const QuoteAnalysis = ({ id }) => {
    const [openQuoteAnalysis, setOpenQuoteAnalysis] = useState(false);
    const [responseDetails, setResponseDetails] = useState({});
    const [responses, setResponses] = useState([]);

    const dispatch = useDispatch();

    const {
        fetchingSingleResponse,
        fetchedSingleResponseSuccess,
        fetchingSingleQuoteResponses,
        fetchedSingleQuoteResponsesSuccess,
    } = useSelector((state) => state.order);

    const tableHeader = [
        { title: "Vendor", widthClass: "w-[19.1%]" },
        { title: "Payment Terms", widthClass: "w-[21.3%]" },
        { title: "Pricing", widthClass: "w-[21.3%]" },
        { title: "Delivery Timeline", widthClass: "w-[21.3%]" },
        {
            title: "AI Top Pick",
            widthClass:
                "w-[10.6%] items-center bg-clip-text text-transparent bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%",
        },
    ];

    const tableBody = responses
        ?.sort((a, b) => b?.analysis?.rating - a?.analysis?.rating)
        .map((res, idx) => [
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-slate-700 py-[14px] pr-3 flex items-center gap-3`}
                    >
                        <VendorAvatar
                            size={32}
                            name={res?.vendor?.profile?.businessName}
                            imageSrc={res?.vendor?.profile?.avatar}
                        />
                        <p>{truncate(res?.vendor?.profile?.businessName, { length: 20 })}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full h-full border-slate-100 text-sm text-slate-700 py-[14px] pr-3`}
                    >
                        {/* <p className="mb-1 text-sm font-rocGroteskMedium text-slate-900">Net 30 days</p> */}
                        <p className="text-[13px] font-rocGroteskMedium text-slate-500">
                            {res?.analysis?.paymentTerms?.result || "N/A"}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full h-full border-slate-100 text-sm text-slate-700 py-[14px] pr-3`}
                    >
                        {/* <p className="mb-1 text-sm font-rocGroteskMedium text-slate-900">
                        $150,000 - $250,000
                    </p> */}
                        <p className="text-[13px] font-rocGroteskMedium text-slate-500">
                            {res?.analysis?.pricing?.result || "N/A"}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full h-full border-slate-100 text-sm text-slate-700 py-[14px] pr-3`}
                    >
                        {/* <p className="mb-1 text-sm font-rocGroteskMedium text-slate-900">
                        7-11 business days
                    </p> */}
                        <p className="text-[13px] font-rocGroteskMedium text-slate-500">
                            {res?.analysis?.deliveryDate?.result || "N/A"}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full h-full border-slate-100 text-sm text-slate-700 py-[14px] flex justify-center`}
                    >
                        {idx === 0 && (
                            <i className="ri-check-fill text-carribean-green text-3xl"></i>
                        )}
                    </div>
                ),
            },
        ]);

    const mobileTableBody = responses
        ?.sort((a, b) => b?.analysis?.rating - a?.analysis?.rating)
        ?.map((res, idx) => {
            return {
                topString: res?.vendor?.profile?.businessName + res?._id,
                topContent: (
                    <div className="flex items-center gap-3">
                        <VendorAvatar
                            size={32}
                            name={res?.vendor?.profile?.businessName}
                            imageSrc={res?.vendor?.profile?.avatar}
                        />
                        <p>{truncate(res?.vendor?.profile?.businessName, { length: 20 })}</p>
                        {idx === 0 && (
                            <i className="ri-check-fill text-carribean-green text-2xl"></i>
                        )}
                    </div>
                ),
                bottomContent: (
                    <div className="mt-3 flex flex-col gap-3">
                        <div>
                            <p className="text-sm font-rocGroteskMedium mb-1.5">Payment Terms</p>
                            <p className={`text-xs font-rocGroteskRegular `}>
                                {res?.analysis?.paymentTerms?.result || "N/A"}
                            </p>
                        </div>
                        <div>
                            <p className="text-sm font-rocGroteskMedium mb-1.5">Pricing</p>
                            <p className={`text-xs font-rocGroteskRegular `}>
                                {res?.analysis?.pricing?.result || "N/A"}
                            </p>
                        </div>
                        <div>
                            <p className="text-sm font-rocGroteskMedium mb-1.5">
                                Delivery Timeline
                            </p>
                            <p className={`text-xs font-rocGroteskRegular `}>
                                {res?.analysis?.deliveryDate?.result || "N/A"}
                            </p>
                        </div>
                    </div>
                ),
                // rightIcon: <i className="ri-arrow-right-s-line text-2xl"></i>,
            };
        });

    useEffect(() => {
        dispatch(orderActions.getSingleQuoteResponse(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (Boolean(fetchedSingleResponseSuccess)) {
            setResponseDetails(fetchedSingleResponseSuccess?.quoteResponse);
        }
    }, [fetchedSingleResponseSuccess]);

    useEffect(() => {
        if (responseDetails?.rfq?._id) {
            dispatch(orderActions.getSingleQuoteResponses(responseDetails?.rfq?._id, 5));
        }
    }, [dispatch, responseDetails?.rfq?._id]);

    useEffect(() => {
        if (Boolean(fetchedSingleQuoteResponsesSuccess)) {
            setResponses(fetchedSingleQuoteResponsesSuccess?.quoteResponses);
        }
    }, [fetchedSingleQuoteResponsesSuccess]);

    return (
        <div>
            <div className="mt-4 py-5 px-6 flex items-center justify-between bg-[#f9f4f6]">
                <div>
                    <p className="mb-3 text-base font-rocGroteskMedium bg-clip-text text-transparent bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                        Our AI has carefully reviewed all of the quotes and has <br /> selected the
                        one that offers the best value for your needs.
                    </p>
                    <Button
                        icon={<i className="ri-bard-fill text-white text-base"></i>}
                        btnText={"Click to see the best one"}
                        btnTextClassName={"text-[13px]"}
                        btnClassname={
                            "!w-fit !text-white bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%"
                        }
                        onClick={() => setOpenQuoteAnalysis(true)}
                    />
                </div>
                <i className="ri-bard-fill text-[48px] bg-clip-text text-transparent bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%"></i>
            </div>

            <ModalContainer
                open={openQuoteAnalysis}
                showCloseIcon={false}
                closeModal={() => setOpenQuoteAnalysis(false)}
                tailwindClassName="w-[86%] max-sm:w-[95%]"
            >
                <div className="bg-white rounded-[8px]">
                    <div className="py-6 px-8 border-b border-slate-100">
                        <h3 className="text-[22px] text-center font-rocGroteskMedium bg-clip-text text-transparent bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                            Here is your quote analysis for RFQ {responseDetails?.rfq?.taId} from
                            all vendors
                        </h3>
                    </div>
                    {fetchingSingleResponse || fetchingSingleQuoteResponses ? (
                        <div className="p-8">
                            <Loader size={6} />
                        </div>
                    ) : (
                        <div className="p-8 max-h-[550px] max-sm:px-3 overflow-y-auto">
                            <div className="max-sm:hidden">
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isAllselectable={false}
                                    headerContainerClass="!bg-slate-50"
                                    tableClass=" w-full"
                                    contentContainer="!pl-0"
                                    isScrollable={false}
                                    isCellBordered={true}
                                />
                            </div>
                            <div className="hidden max-sm:block">
                                <CustomMobileTable data={mobileTableBody} />
                            </div>
                        </div>
                    )}
                </div>
            </ModalContainer>
        </div>
    );
};

export default QuoteAnalysis;
