export const dashboardTypes = {
    FETCH_DASHBOARD_GMW_REQUEST: "FETCH_DASHBOARD_GMW_REQUEST",
    FETCH_DASHBOARD_GMW_SUCCESS: "FETCH_DASHBOARD_GMW_SUCCESS",
    FETCH_DASHBOARD_GMW_FAILURE: "FETCH_DASHBOARD_GMW_FAILURE",
    FETCH_SALES_CHANNEL_REQUEST: "FETCH_SALES_CHANNEL_REQUEST",
    FETCH_SALES_CHANNEL_SUCCESS: "FETCH_SALES_CHANNEL_SUCCESS",
    FETCH_SALES_CHANNEL_FAILURE: "FETCH_SALES_CHANNEL_FAILURE",
    FETCH_DASHBOARD_ANALYTICS_REQUEST: "FETCH_DASHBOARD_ANALYTICS_REQUEST",
    FETCH_DASHBOARD_ANALYTICS_SUCCESS: "FETCH_DASHBOARD_ANALYTICS_SUCCESS",
    FETCH_DASHBOARD_ANALYTICS_FAILURE: "FETCH_DASHBOARD_ANALYTICS_FAILURE",
    FETCH_DASHBOARD_BUSINESS_STATS_REQUEST: "FETCH_DASHBOARD_BUSINESS_STATS_REQUEST",
    FETCH_DASHBOARD_BUSINESS_STATS_SUCCESS: "FETCH_DASHBOARD_BUSINESS_STATS_SUCCESS",
    FETCH_DASHBOARD_BUSINESS_STATS_FAILURE: "FETCH_DASHBOARD_BUSINESS_STATS_FAILURE",
    FETCH_DASHBOARD_TOP_PERFORMING_REQUEST: "FETCH_DASHBOARD_TOP_PERFORMING_REQUEST",
    FETCH_DASHBOARD_TOP_PERFORMING_SUCCESS: "FETCH_DASHBOARD_TOP_PERFORMING_SUCCESS",
    FETCH_DASHBOARD_TOP_PERFORMING_FAILURE: "FETCH_DASHBOARD_TOP_PERFORMING_FAILURE",
    FETCH_DASHBOARD_LEAST_PERFORMING_REQUEST: "FETCH_DASHBOARD_LEAST_PERFORMING_REQUEST",
    FETCH_DASHBOARD_LEAST_PERFORMING_SUCCESS: "FETCH_DASHBOARD_LEAST_PERFORMING_SUCCESS",
    FETCH_DASHBOARD_LEAST_PERFORMING_FAILURE: "FETCH_DASHBOARD_LEAST_PERFORMING_FAILURE",

    FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_REQUEST: "FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_REQUEST",
    FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_SUCCESS: "FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_SUCCESS",
    FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_FAILURE: "FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_FAILURE",
    FETCH_DASHBOARD_PRODUCT_PROFIT_MARGIN_REQUEST: "FETCH_DASHBOARD_PRODUCT_PROFIT_MARGIN_REQUEST",
    FETCH_DASHBOARD_PRODUCT_PROFIT_MARGIN_SUCCESS: "FETCH_DASHBOARD_PRODUCT_PROFIT_MARGIN_SUCCESS",
    FETCH_DASHBOARD_PRODUCT_PROFIT_MARGIN_FAILURE: "FETCH_DASHBOARD_PRODUCT_PROFIT_MARGIN_FAILURE",
    FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_LIST_REQUEST: "FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_LIST_REQUEST",
    FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_LIST_SUCCESS: "FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_LIST_SUCCESS",
    FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_LIST_FAILURE: "FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_LIST_FAILURE",
    FETCH_DASHBOARD_PRODUCT_PROFIT_LIST_REQUEST: "FETCH_DASHBOARD_PRODUCT_PROFIT_LIST_REQUEST",
    FETCH_DASHBOARD_PRODUCT_PROFIT_LIST_SUCCESS: "FETCH_DASHBOARD_PRODUCT_PROFIT_LIST_SUCCESS",
    FETCH_DASHBOARD_PRODUCT_PROFIT_LIST_FAILURE: "FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_LIST_FAILURE",
};
