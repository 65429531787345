import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import CustomTableLoader from "components/common/CustomTableLoader";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import useGetDashboardLeastPerforming from "hooks/Dashboard/useGetDashboardLeastPerforming";
import useGetDashboardTopPerforming from "hooks/Dashboard/useGetDashboardTopPerforming";
import useGetSalesChannel from "hooks/Dashboard/useGetSalesChannel";
import useGetProductList from "hooks/inventoryHooks/useGetProductList";
import useGetAllWarehouses from "hooks/useGetAllWarehouses";
import { truncate } from "lodash";
import millify from "millify";
import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";

type performerProp = {
    bestSellingPercentage?: number;
    leastSellingPercentage?: number;
    toptotalProfitContribution?: number;
    toptotalRevenueContribution?: number;
    topproductStockValue?: number;
    topchangeInAnnualSales?: number;
    leasttotalProfitContribution?: number;
    leasttotalRevenueContribution?: number;
    leastproductStockValue?: number;
    leastchangeInAnnualSales?: number;
    showAIAgent?: boolean;
    isLoading?: boolean;
};

const TopLeastPerformer = ({
    bestSellingPercentage,
    leastSellingPercentage,
    toptotalProfitContribution,
    toptotalRevenueContribution,
    topproductStockValue,
    topchangeInAnnualSales,
    leasttotalProfitContribution,
    leasttotalRevenueContribution,
    leastproductStockValue,
    leastchangeInAnnualSales,
    showAIAgent,
    isLoading,
}: performerProp) => {
    const [fetchTopPerforming, setFetchTopPerformingData] = useState<boolean>(false);

    const {
        data: topPerformingData,
        error: topPerformingError,
        handleDebouncedChange: handleTopDebouncedChange,
        isFetching: topPerformingDataLoading,
        pagination,
        setWarehouseId: setTopWarehouseId,
        setInventoryId: setTopInventoryId,
        setChannel: setTopPerformingChannel,
        setSortValues,
        setDateRange: setTopDateRange,
    } = useGetDashboardTopPerforming(fetchTopPerforming);

    const [fetchLeastPerforming, setFetchLeastPerformingData] = useState<boolean>(false);

    const {
        data: leastPerformingData,
        error: leastPerformingError,
        handleDebouncedChange: handleLeastDebouncedChange,
        isFetching: leastPerformingDataLoading,
        pagination: leastPagination,
        setWarehouseId: setLeastWarehouseId,
        setInventoryId: setLeastInventoryId,
        setChannel: setLeastPerformingChannel,
        setSortValues: setLeastSortValues,
        setDateRange: setLeastDateRange,
    } = useGetDashboardLeastPerforming(fetchLeastPerforming);

    const {
        data: productData,
        handleDebouncedChange: producthandleDebouncedChange,
        handleSearch: producthandleSearch,
        isFetching: productListLoading,
        search: productListSearch,
        setSearch: setproductListSearch,
        setDebouncedSearch: setproductDebouncedSearch,
        pagination: productpagination,
        isLoadingMore: productisLoadingMore,
    } = useGetProductList();

    const { data: salesChannel, isFetching: salesChannelLoading } = useGetSalesChannel();

    const {
        data: warehouseData,
        isFetching: warehousesLoading,
        search: warehouseSearch,
        setSearch: setWarehouseSearch,
        setDebouncedSearch: setWarehouseDebouncedSearch,
        handleSearch: handleWarehouseSearch,
        handleDebouncedChange: handleWarehouseDebouncedChange,
    } = useGetAllWarehouses();

    const navigate = useNavigate();
    const clearSearchRef = useRef(null);
    const warehouseRef = useRef(null);
    const [topModal, setTopModal] = useState<boolean>(false);
    const [leastModal, setLeastModal] = useState<boolean>(false);
    const [productSearch, setProductSearch] = useState<string>("");
    const [tableBodyData, setTableBodyData] = useState<{ [key: string]: any }>([]);
    const [tablePagination, setTablePagination] = useState<{ [key: string]: any }>();
    const [tableLoading, setTableLoading] = useState<boolean>(true);
    const [tableIsLoadingMore, setTableIsLoadingMore] = useState<boolean>(false);
    const [productFilter, setProductFilter] = useState<string>("");
    const [channelFilter, setChannelFilter] = useState<string>("");
    const [warehouse, setWarehouse] = useState<string>("");
    const [dateFilter, setDateFilter] = useState<string>("");
    const [showIdx, setShowIdx] = useState<number>();

    const values = [
        {
            label: "This year",
            value: "yearly",
        },
        {
            label: "This month",
            value: "monthly",
        },
        {
            label: "This week",
            value: "weekly",
        },

        {
            label: "Today",
            value: "daily",
        },
        {
            label: "All times",
            value: "",
        },
    ];

    const handleDateRangeChange = (e) => {
        const { value } = e.target;
        const arrayValue = value?.split("-");
        setDateFilter(arrayValue[1]);
        if (topModal) {
            setTopDateRange(arrayValue[0]);
        } else {
            setLeastDateRange(arrayValue[0]);
        }
    };

    const handleWarehouseChange = (e) => {
        const { value } = e.target;
        const arrayValue = value !== "" && value?.split("-");
        setWarehouse(value);
        if (topModal) {
            value === "" ? setTopWarehouseId("") : setTopWarehouseId(arrayValue[1]);
        } else {
            value === "" ? setLeastWarehouseId("") : setLeastWarehouseId(arrayValue[1]);
        }
    };

    const handleSortChange = (idx: number, sortValue: number) => {
        let sortKey;
        if (idx === 0) {
            sortKey = "productName";
        } else if (idx === 3) {
            sortKey = "stockLevel";
        } else if (idx === 4) {
            sortKey = "forCast";
        } else if (idx === 5) {
            sortKey = "unitCost";
        } else if (idx === 6) {
            sortKey = "actualRevenue";
        } else if (idx === 7) {
            sortKey = "inventoryStatus";
        }
        if (topModal) {
            setSortValues({ [sortKey]: sortValue });
        }
        if (leastModal) {
            setLeastSortValues({ [sortKey]: sortValue });
        }
    };

    const handleProductValue = (e) => {
        const { value } = e.target;
        const arrayValue = value !== "" && value?.split("-");
        setProductFilter(value);
        if (topModal) {
            value === "" ? setTopInventoryId("") : setTopInventoryId(arrayValue[0]);
        } else {
            value === "" ? setLeastInventoryId("") : setLeastInventoryId(arrayValue[0]);
        }
    };

    useEffect(() => {
        if (!topModal && !leastModal) {
            setTableLoading(true);
            setWarehouse("");
            setDateFilter("");
            setSortValues("");
            setLeastSortValues("");
            handleTopDebouncedChange("");
            setTopWarehouseId("");
            setTopInventoryId("");
            setTopPerformingChannel("");
            setTopDateRange("");
            handleLeastDebouncedChange("");
            setLeastWarehouseId("");
            setLeastInventoryId("");
            setLeastPerformingChannel("");
            setLeastDateRange("");
            setProductSearch("");
        }
    }, [topModal, leastModal]);

    useEffect(() => {
        if (topModal) {
            setTableBodyData(topPerformingData);
            setTablePagination(pagination);
            setTableLoading(topPerformingDataLoading);
        }
        if (leastModal) {
            setTableBodyData(leastPerformingData);
            setTablePagination(leastPagination);
            setTableLoading(leastPerformingDataLoading);
        }
    }, [
        topModal,
        leastModal,
        leastPerformingData,
        topPerformingData,
        pagination,

        leastPagination,
        topPerformingDataLoading,
        leastPerformingDataLoading,
    ]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (clearSearchRef.current && !clearSearchRef.current.contains(event.target)) {
                setproductListSearch("");
                setproductDebouncedSearch("");
            }
            if (warehouseRef.current && !warehouseRef.current.contains(event.target)) {
                setWarehouseSearch("");
                setWarehouseDebouncedSearch("");
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [clearSearchRef, warehouseRef]);

    const handleChannelChange = (e) => {
        const { value } = e.target;
        setChannelFilter(value);
        if (topModal) {
            value === "All channels" ? setTopPerformingChannel("") : setTopPerformingChannel(value);
        } else {
            value === "All channels"
                ? setLeastPerformingChannel("")
                : setLeastPerformingChannel(value);
        }
    };

    const showLoading = () => {
        return (
            <div className="flex  justify-start font-rocGroteskMedium text-xs mt-8 pb-5 bg-white max-lg:flex-row overflow-x-hidden">
                <div className="lg:border-r lg:border-r-slate-200 py-1 lg:pr-[50px]">
                    <div className="mb-4 flex gap-4 items-center">
                        {[1, 1]?.map((item, idx) => {
                            return (
                                <Skeleton
                                    height={24}
                                    width={idx === 0 ? 252 : 112}
                                    highlightColor="#E2E8F0"
                                    borderRadius={16}
                                    baseColor="#F1F5F9"
                                    className="custom-skeleton"
                                />
                            );
                        })}
                    </div>
                    <div className="mb-4 flex gap-4 items-center">
                        {[1, 1]?.map((item, idx) => {
                            return (
                                <Skeleton
                                    height={24}
                                    width={idx === 0 ? 252 : 112}
                                    highlightColor="#E2E8F0"
                                    borderRadius={16}
                                    baseColor="#F1F5F9"
                                    className="custom-skeleton"
                                />
                            );
                        })}
                    </div>
                </div>

                <div className=" py-1 lg:pl-[50px]">
                    <div className="mb-4 flex gap-4 items-center">
                        {[1, 1]?.map((item, idx) => {
                            return (
                                <Skeleton
                                    height={24}
                                    width={idx === 0 ? 252 : 112}
                                    highlightColor="#E2E8F0"
                                    borderRadius={16}
                                    baseColor="#F1F5F9"
                                    className="custom-skeleton"
                                />
                            );
                        })}
                    </div>
                    <div className="mb-4 flex gap-4 items-center">
                        {[1, 1]?.map((item, idx) => {
                            return (
                                <Skeleton
                                    height={24}
                                    width={idx === 0 ? 252 : 112}
                                    highlightColor="#E2E8F0"
                                    borderRadius={16}
                                    baseColor="#F1F5F9"
                                    className="custom-skeleton"
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };
    const tableHeader = [
        { title: `Product`, widthClass: "w-[170px]", sortIcon: true },
        { title: "Channels", widthClass: "w-[205px]" },
        { title: "Sold units", widthClass: "w-[130px]" },
        { title: "Inventory Level", widthClass: "w-[156px]", sortIcon: true },
        { title: "Sales demand forecast", widthClass: "w-[195px]", sortIcon: true },
        { title: "Unit price", widthClass: "w-[120px]", sortIcon: true },
        { title: "Actual revenue", widthClass: "w-[155px]", sortIcon: true },
        { title: "Inventory Status", widthClass: "w-[320px]", sortIcon: true },
    ];

    const tableBody = tableBodyData?.map((product, idx) => {
        const firstTwoChannels = product?.channels.slice(0, 2);

        const getStockOut = () => {
            const currentDate = moment().startOf("day");
            const stockOutDate = product?.stockoutDate
                ? moment(product?.stockoutDate)
                : moment.invalid();
            if (stockOutDate?.isValid() && stockOutDate?.isAfter(currentDate)) {
                const daysToStockout = stockOutDate?.diff(currentDate, "days");
                return `Stock out in ${daysToStockout}-${daysToStockout}`;
            } else if (stockOutDate?.isValid()) {
                if (product?.stockLevel > product?.ongoingSales) {
                    return "Out of stock";
                } else if (product?.stockLevel < product?.forecast) {
                    return "Reorder now";
                } else {
                    if (product?.stockLevel > product?.ongoingSales) {
                        return "In stock";
                    } else {
                        return "Out of stock";
                    }
                }
            } else {
                return "N/A";
            }
        };
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
                    >
                        <p className="">{truncate(product?.productName, { length: 20 })}</p>
                        <p className="text-slate-500 text-xs ">{`SKU No: ${product?.sku}`}</p>
                    </div>
                ),
            },

            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600   flex gap-4 py-2 `}
                    >
                        <div className="flex gap-1">
                            {firstTwoChannels?.map((item, idx) => {
                                return (
                                    <span className="w-[14px] h-[14px]" key={idx}>
                                        {displayProductChannelIcon(item)}
                                    </span>
                                );
                            })}
                        </div>
                        <p className="">
                            {firstTwoChannels?.map((item, idx) => {
                                return (
                                    <span className="" key={idx}>
                                        {`${item}  ${
                                            idx === 0 && firstTwoChannels?.length === 2 ? ", " : ""
                                        }`}
                                    </span>
                                );
                            })}
                            {product?.channels?.length > 2 && (
                                <span className=""> + {product?.channels?.length - 2} </span>
                            )}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
                    >
                        <p className="">{product?.totalQuantitySold?.toLocaleString()}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div className="py-2">
                        <p className="!font-rocGroteskMedium text-sm">
                            {product?.stockLevel?.toLocaleString()}
                        </p>
                    </div>
                ),
            },

            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium pr-4 border-slate-100 text-sm text-slate-600   flex w-full justify-between items-center py-2`}
                    >
                        <p className=""> {Math.round(product?.forecast)?.toLocaleString()} pcs</p>

                        <i className="ri-line-chart-line text-g-55"></i>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
                    >
                        <p className="">${formatMoney().format(product?.averageSellingPrice)}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600 py-2 `}
                    >
                        <p className="">${formatMoney().format(product?.actualRevenue)}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div className="!font-rocGroteskMedium border-slate-100 text-sm text-slate-600 py-2  ">
                        <p
                            className={` ${
                                getStockOut()?.split(" ")[0] === "In"
                                    ? "bg-g-25 text-g-60"
                                    : getStockOut().split(" ")[0] === "Stock"
                                    ? "text-[#F4B000] bg-[rgba(252,195,78,0.15)] "
                                    : (getStockOut().split(" ")[0] === "Reorder" ||
                                          getStockOut().split(" ")[0] === "Out") &&
                                      "bg-r-25 text-r-55"
                            } capitalize !font-rocGroteskMedium text-xs rounded-2xl py-0.5 px-2 !w-fit`}
                        >
                            {getStockOut()?.split("-")[0]} {""}{" "}
                            {getStockOut().split(" ")[0] === "Stock" && "days"}
                        </p>
                        <p
                            className={` ${
                                getStockOut()?.split(" ")[0] === "In"
                                    ? "text-slate-400"
                                    : getStockOut()?.split(" ")[0] !== "N/A" && "text-r-55"
                            }  text-[11px]`}
                        >
                            {getStockOut()?.split(" ")[0] === "In"
                                ? "You have enough items in stock to meet your demands"
                                : getStockOut()?.split(" ")[0] === "Stock" &&
                                  `Reorder now to avoid stockout in ${
                                      getStockOut()?.split("-")[1]
                                  } days`}
                        </p>
                    </div>
                ),
            },
        ];
    });

    const mobileTableBody = tableBodyData?.map((product, idx) => {
        const firstTwoChannels = product?.channels.slice(0, 2);

        const getStockOut = () => {
            const currentDate = moment().startOf("day");
            const stockOutDate = product?.stockoutDate
                ? moment(product?.stockoutDate)
                : moment.invalid();
            if (stockOutDate?.isValid() && stockOutDate?.isAfter(currentDate)) {
                const daysToStockout = stockOutDate?.diff(currentDate, "days");
                return `Stock out in ${daysToStockout}-${daysToStockout}`;
            } else if (stockOutDate?.isValid()) {
                if (product?.stockLevel > product?.ongoingSales) {
                    return "Out of stock";
                } else if (product?.stockLevel < product?.forecast) {
                    return "Reorder now";
                } else {
                    if (product?.stockLevel > product?.ongoingSales) {
                        return "In stock";
                    } else {
                        return "Out of stock";
                    }
                }
            } else {
                return "N/A";
            }
        };
        return (
            <div
                key={idx}
                className="border-slate-200 border rounded py-4 px-2.5 font-rocGroteskMedium"
            >
                <div className=" flex justify-between items-center">
                    <div className={`!font-rocGroteskMedium  pb-2`}>
                        <p className="text-[13px] text-slate-600">
                            {truncate(product?.productName, { length: 20 })}
                        </p>
                        <p className="text-slate-500 text-[10px] ">{`SKU No: ${product?.sku}`}</p>
                    </div>

                    <div className="flex gap-[30px] items-center">
                        <p
                            className={` ${
                                getStockOut() === "N/A"
                                    ? "hidden"
                                    : getStockOut()?.split(" ")[0] === "In"
                                    ? "bg-g-25 text-g-60"
                                    : getStockOut().split(" ")[0] === "Stock"
                                    ? "text-[#F4B000] bg-[rgba(252,195,78,0.15)] "
                                    : (getStockOut().split(" ")[0] === "Reorder" ||
                                          getStockOut().split(" ")[0] === "Out") &&
                                      "bg-r-25 text-r-55"
                            } capitalize !font-rocGroteskMedium text-xs rounded-2xl py-0.5 px-2 !w-fit`}
                        >
                            {getStockOut()?.split("-")[0]} {""}{" "}
                            {getStockOut().split(" ")[0] === "Stock" && "days"}
                        </p>
                        <i
                            className={`${
                                showIdx === idx
                                    ? "ri-arrow-down-s-line text-[#16C6A4]"
                                    : "ri-arrow-right-s-line text-[#042821]"
                            } text-2xl `}
                            onClick={() => {
                                if (idx === showIdx) {
                                    setShowIdx(-1);
                                } else {
                                    setShowIdx(idx);
                                }
                            }}
                        ></i>
                    </div>
                </div>

                {showIdx === idx && (
                    <div className="space-y-[10px] border-t border-t-slate-200 text-[13px] text-slate-700 !font-rocGroteskMedium">
                        <div className="flex justify-between  pt-2.5">
                            <p className="">Channels </p>

                            <div className={`flex gap-4`}>
                                <p className="">
                                    {firstTwoChannels?.map((item, idx) => {
                                        return (
                                            <span className="" key={idx}>
                                                {`${item}  ${
                                                    idx === 0 && firstTwoChannels?.length === 2
                                                        ? ", "
                                                        : ""
                                                }`}
                                            </span>
                                        );
                                    })}
                                    {product?.channels?.length > 2 && (
                                        <span className="">
                                            {" "}
                                            + {product?.channels?.length - 2}{" "}
                                        </span>
                                    )}
                                </p>
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <p className="">Sold units </p>
                            <p className="">{product?.totalQuantitySold?.toLocaleString()}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="">Inventory level </p>
                            <p className=""> {product?.stockLevel?.toLocaleString()}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="">Sales demand forecast </p>
                            <div className={` gap-2   flex justify-between items-center `}>
                                <p className="">
                                    {" "}
                                    {Math.round(product?.forecast)?.toLocaleString()} pcs
                                </p>

                                <i className="ri-line-chart-line text-g-55"></i>
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <p className="">Unit price </p>
                            <p className="">
                                ${formatMoney().format(product?.averageSellingPrice)}
                            </p>
                        </div>
                        <div className="flex justify-between">
                            <p className="">Actual Revenue </p>
                            <p className="">${formatMoney().format(product?.actualRevenue)}</p>
                        </div>
                        <p
                            className={` ${
                                getStockOut()?.split(" ")[0] === "In"
                                    ? "text-slate-400"
                                    : getStockOut()?.split(" ")[0] !== "N/A" && "text-r-55"
                            }  text-[11px]`}
                        >
                            {getStockOut()?.split(" ")[0] === "In"
                                ? "You have enough items in stock to meet your demands"
                                : getStockOut()?.split(" ")[0] === "Stock" &&
                                  `Reorder now to avoid stockout in ${
                                      getStockOut()?.split("-")[1]
                                  } days`}
                        </p>
                    </div>
                )}
            </div>
        );
    });

    return (
        <div className="">
            <div className="w-full lg:px-4 pt-6 lg:py-6 lg:shadow-tableShadow lg:rounded-[10px] max-lg:border-b-[1px] max-lg:border-slate-200">
                <div className="lg:flex border-b border-b-slate-200 justify-between items-center w-full">
                    <div className=" mb-1">
                        <div className="flex justify-between items-center w-full">
                            <div className="flex gap-2 items-center mb-1">
                                <div className="bg-[#D0F4ED] rounded-full w-7 h-7 text-center pt-0.5">
                                    <i className="ri-flashlight-fill text-g-55 "></i>
                                </div>
                                <p className="text-g-75 text-sm lg:text-base font-rocGroteskMedium">
                                    Top performing products
                                </p>
                            </div>

                            <div className="lg:hidden">
                                {!isLoading && bestSellingPercentage !== undefined && (
                                    <Button
                                        btnText="See all products"
                                        onClick={() => {
                                            setTopModal(true);
                                            setFetchTopPerformingData(true);
                                            setFetchLeastPerformingData(false);
                                        }}
                                        btnClassname="!bg-transparent !border-none !text-sm !text-b-55 !px-4 !py-2.5 !rounded-[30px] !w-auto hover:!bg-b-25 !bg-transparent"
                                    />
                                )}
                            </div>
                        </div>

                        {isLoading || bestSellingPercentage === undefined ? (
                            <p className="font-rocGroteskMedium text-slate-600 text-sm">
                                Your best-selling items data not available at the moment.
                            </p>
                        ) : (
                            <p className="font-rocGroteskMedium text-slate-600 text-sm">
                                Your best-selling items represent
                                <span className="font-GroteskBold text-g-75">{` ${bestSellingPercentage}% `}</span>
                                of all products.
                            </p>
                        )}
                    </div>
                    <div className="max-lg:hidden">
                        {!isLoading && bestSellingPercentage !== undefined && (
                            <Button
                                btnText="See all products"
                                onClick={() => {
                                    setTopModal(true);
                                    setFetchTopPerformingData(true);
                                    setFetchLeastPerformingData(false);
                                }}
                                btnClassname="!bg-transparent !border-none !text-sm !text-b-55 !px-4 !py-2.5 !rounded-[30px] !w-auto hover:!bg-b-25 !bg-transparent"
                            />
                        )}
                    </div>
                </div>

                {isLoading ? (
                    showLoading()
                ) : (
                    <div
                        className={`lg:flex ${
                            showAIAgent && "flex-col"
                        } justify-start font-rocGroteskMedium text-xs mt-4 pb-5 bg-white`}
                    >
                        <div
                            className={`lg:border-r ${
                                showAIAgent && "!border-none mb-4"
                            } border-r-slate-200 py-1 lg:pr-[50px]`}
                        >
                            <div className="mb-4 flex gap-4 items-center">
                                <div
                                    className={`bg-[#2563EB] rounded-r-lg h-[26px] mb-1 flex justify-end items-center font-rocGroteskMedium pr-2 w-[145px] lg:w-[320px]`}
                                >
                                    <div className="text-[10px] rounded-xl bg-white text-slate-700 font-rocGroteskBold px-2">
                                        {toptotalProfitContribution
                                            ? `$${millify(toptotalProfitContribution, {
                                                  precision: 2,
                                              })}`
                                            : "--"}
                                    </div>
                                </div>
                                <p className="text-slate-600 text-sm">Total profits contributed</p>
                            </div>
                            <div className="flex gap-4 items-center">
                                <div
                                    className={`bg-[#56B648] rounded-r-lg h-[26px] mb-1 flex justify-end items-center font-rocGroteskMedium pr-2 w-[145px] lg:w-[320px]`}
                                >
                                    <div className="text-[10px] rounded-xl bg-white text-slate-700 font-rocGroteskBold px-2">
                                        {toptotalRevenueContribution
                                            ? `$${millify(toptotalRevenueContribution, {
                                                  precision: 2,
                                              })}`
                                            : "--"}
                                    </div>
                                </div>
                                <p className="text-slate-600 text-sm">Total revenue contributed</p>
                            </div>
                        </div>

                        <div className={`py-1 lg:pl-[50px] ${showAIAgent && "!pl-0"}`}>
                            <div className="mb-4 flex gap-4 items-center">
                                <div
                                    className={`bg-r-25 rounded-r-lg h-[26px] mb-1 flex justify-end items-center font-rocGroteskMedium pr-2 w-[145px] lg:w-[320px]`}
                                >
                                    <div className="text-[10px] rounded-xl bg-white text-slate-700 font-rocGroteskBold px-2">
                                        {topproductStockValue
                                            ? ` $${millify(topproductStockValue, {
                                                  precision: 2,
                                              })}`
                                            : "--"}
                                    </div>
                                </div>
                                <p className="text-slate-600 text-sm">Products' Stock value</p>
                            </div>
                            <div className="flex gap-4 items-center">
                                <div
                                    className={`bg-g-55 rounded-r-lg h-[26px] mb-1 flex justify-end items-center font-rocGroteskMedium pr-2 w-[145px] lg:w-[320px]`}
                                >
                                    <div className="text-[10px] rounded-xl bg-white text-slate-700 font-rocGroteskBold px-2">
                                        {topchangeInAnnualSales
                                            ? ` $${millify(topchangeInAnnualSales, {
                                                  precision: 2,
                                              })}`
                                            : "--"}
                                    </div>
                                </div>
                                <p className="text-slate-600 text-sm">Change in annual sales</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="w-full lg:px-4 pt-6 lg:py-6 lg:shadow-tableShadow lg:rounded-[10px] lg:mt-5">
                <div className="lg:flex border-b border-b-slate-200 justify-between items-center w-full">
                    <div className=" mb-1">
                        <div className="flex justify-between items-center w-full">
                            <div className="flex gap-2 items-center mb-1">
                                <div className="bg-r-25 rounded-full w-7 h-7 text-center pt-0.5">
                                    <i className="ri-arrow-right-down-line text-r-55 "></i>
                                </div>
                                <p className="text-g-75 text-base font-rocGroteskMedium">
                                    Least performing products
                                </p>
                            </div>
                            <div className="lg:hidden">
                                {!isLoading && leastSellingPercentage !== undefined && (
                                    <Button
                                        btnText="See all products"
                                        onClick={() => {
                                            setLeastModal(true);
                                            setFetchTopPerformingData(false);
                                            setFetchLeastPerformingData(true);
                                        }}
                                        btnClassname="!border-none !text-sm !text-b-55 !px-4 !py-2.5 !rounded-[30px] !w-auto hover:!bg-b-25 !bg-transparent "
                                    />
                                )}
                            </div>
                        </div>

                        {isLoading || leastSellingPercentage === undefined ? (
                            <p className="font-rocGroteskMedium text-slate-600 text-sm">
                                Your least-selling items data not available at the moment.
                            </p>
                        ) : (
                            <p className="font-rocGroteskMedium text-slate-600 text-sm">
                                Your least-selling items represent
                                <span className="font-GroteskBold text-g-75">{` ${leastSellingPercentage}% `}</span>
                                of all products.
                            </p>
                        )}
                    </div>
                    <div className="max-lg:hidden">
                        {!isLoading && leastSellingPercentage !== undefined && (
                            <Button
                                btnText="See all products"
                                onClick={() => {
                                    setLeastModal(true);
                                    setFetchTopPerformingData(false);
                                    setFetchLeastPerformingData(true);
                                }}
                                btnClassname="!border-none !text-sm !text-b-55 !px-4 !py-2.5 !rounded-[30px] !w-auto hover:!bg-b-25 !bg-transparent "
                            />
                        )}
                    </div>
                </div>

                {isLoading ? (
                    showLoading()
                ) : (
                    <div
                        className={`lg:flex ${
                            showAIAgent && "flex-col"
                        } justify-start font-rocGroteskMedium text-xs mt-8 pb-5 bg-white`}
                    >
                        <div
                            className={`lg:border-r ${
                                showAIAgent && "!border-none mb-4"
                            } border-r-slate-200 py-1 lg:pr-[50px]`}
                        >
                            <div className="mb-4 flex gap-4 items-center">
                                <div
                                    className={`bg-[#2563EB] rounded-r-lg h-[26px] mb-1 flex justify-end items-center font-rocGroteskMedium pr-2 w-[145px] lg:w-[320px]`}
                                >
                                    <div className="text-[10px] rounded-xl bg-white text-slate-700 font-rocGroteskBold px-2">
                                        {leasttotalProfitContribution
                                            ? ` $${millify(leasttotalProfitContribution, {
                                                  precision: 2,
                                              })}`
                                            : "--"}
                                    </div>
                                </div>
                                <p className="text-slate-600 text-sm">Total profits contributed</p>
                            </div>
                            <div className="flex gap-4 items-center">
                                <div
                                    className={`bg-[#56B648] rounded-r-lg h-[26px] mb-1 flex justify-end items-center font-rocGroteskMedium pr-2 w-[145px] lg:w-[320px]`}
                                >
                                    <div className="text-[10px] rounded-xl bg-white text-slate-700 font-rocGroteskBold px-2">
                                        {leasttotalRevenueContribution
                                            ? `$${millify(leasttotalRevenueContribution, {
                                                  precision: 2,
                                              })}`
                                            : "--"}
                                    </div>
                                </div>
                                <p className="text-slate-600 text-sm">Total revenue contributed</p>
                            </div>
                        </div>

                        <div className={`py-1 lg:pl-[50px] ${showAIAgent && "!pl-0"}`}>
                            <div className="mb-4 flex gap-4 items-center">
                                <div
                                    className={`bg-r-25 rounded-r-lg h-[26px] mb-1 flex justify-end items-center font-rocGroteskMedium pr-2 w-[145px] lg:w-[320px]`}
                                >
                                    <div className="text-[10px] rounded-xl bg-white text-slate-700 font-rocGroteskBold px-2">
                                        {leastproductStockValue
                                            ? `$${millify(leastproductStockValue, {
                                                  precision: 2,
                                              })}`
                                            : "--"}
                                    </div>
                                </div>
                                <p className="text-slate-600 text-sm">Products' Stock value</p>
                            </div>
                            <div className="flex gap-4 items-center">
                                <div
                                    className={`bg-g-55 rounded-r-lg h-[26px] mb-1 flex justify-end items-center font-rocGroteskMedium pr-2 w-[145px] lg:w-[320px]`}
                                >
                                    <div className="text-[10px] rounded-xl bg-white text-slate-700 font-rocGroteskBold px-2">
                                        {leastchangeInAnnualSales
                                            ? `$${millify(leastchangeInAnnualSales, {
                                                  precision: 2,
                                              })}`
                                            : "--"}
                                    </div>
                                </div>
                                <p className="text-slate-600 text-sm">Change in annual sales</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <ModalContainer
                modalClassName="!bg-[rgba(217,217,217,0.3)"
                open={topModal || leastModal}
                closeModal={() => {
                    setTopModal(false);
                    setLeastModal(false);
                    setFetchTopPerformingData(false);
                    setFetchLeastPerformingData(false);
                }}
                tailwindClassName="w-[95%] lg:w-[85%]"
            >
                <div
                    className={` bg-white rounded-[6px] h-[90vh]  lg:max-h-[60vh] overflow-y-scroll`}
                    id="modalContent"
                >
                    <div className=" mb-4 relative">
                        <div className="sticky top-0 bg-white z-[9999] py-4 ">
                            <div className="pl-6  flex gap-[22px] items-center lg:justify-between pb-3  border-b border-b-slate-200 w-full">
                                {" "}
                                <i
                                    className="ri-arrow-left-line  text-2xl cursor-pointer text-slate-800 lg:hidden "
                                    onClick={() => {
                                        setTopModal(false);
                                        setLeastModal(false);
                                    }}
                                ></i>
                                <div className="flex gap-2 items-center">
                                    {leastModal ? (
                                        <div className="flex gap-2 items-center">
                                            <div className="bg-r-25 rounded-full w-7 h-7 text-center pt-0.5">
                                                <i className="ri-arrow-right-down-line text-r-55 "></i>
                                            </div>
                                            <p className="text-g-75 text-base font-rocGroteskMedium">
                                                Least performing products
                                            </p>
                                        </div>
                                    ) : (
                                        topModal && (
                                            <div className="flex gap-2 items-center">
                                                <div className="bg-[#D0F4ED] rounded-full w-7 h-7 text-center pt-0.5">
                                                    <i className="ri-flashlight-fill text-g-55 "></i>
                                                </div>
                                                <p className="text-g-75 text-base font-rocGroteskMedium">
                                                    Top performing products
                                                </p>
                                            </div>
                                        )
                                    )}
                                </div>
                                <i
                                    className="ri-close-line  text-2xl cursor-pointer text-slate-800 pr-6 max-lg:hidden"
                                    onClick={() => {
                                        setTopModal(false);
                                        setLeastModal(false);
                                    }}
                                ></i>
                            </div>

                            <div className="flex justify-between items-center px-6 lg:px-4 py-[13px]">
                                <div className="flex items-center gap-y-2.5 gap-x-0 lg:gap-2.5 flex-wrap lg:flex-nowrap">
                                    <div className="w-full lg:w-[215px]">
                                        <TextInput
                                            name="porductSearch"
                                            type="text"
                                            value={productSearch}
                                            onChange={(e) => setProductSearch(e?.target.value)}
                                            onInput={(e) => {
                                                topModal
                                                    ? handleTopDebouncedChange(e)
                                                    : handleLeastDebouncedChange(e);
                                            }}
                                            inputPlaceholder="Search product"
                                            leftIcon={
                                                <i className="ri-search-line text-slate-400"></i>
                                            }
                                            leftIconClass="!left-2.5"
                                            inputClassName={
                                                "!h-fit pl-[30px] !py-3 !mb-0 !font-rocGroteskMedium !text-slate-400"
                                            }
                                            floatingPlaceholderClass="!text-slate-400 !font-rocGroteskMedium "
                                            inputContainerClassname={
                                                "!bg-white lg:!bg-slate-50 !border-slate-100 !h-auto !px-0 !py-0"
                                            }
                                        />
                                    </div>

                                    <Dropdown
                                        handleChange={(name, value) =>
                                            handleProductValue({ target: { name, value } })
                                        }
                                        loader={productisLoadingMore}
                                        endOfList={
                                            productpagination?.currentPage ===
                                            productpagination?.noOfPages
                                        }
                                        name="valueRange"
                                        search={
                                            <div ref={clearSearchRef} className="">
                                                <TextInput
                                                    value={productListSearch}
                                                    name={"search"}
                                                    type={"text"}
                                                    required={false}
                                                    onChange={producthandleSearch}
                                                    onInput={producthandleDebouncedChange}
                                                    inputPlaceholder="Search product"
                                                    containerClassname=""
                                                    inputContainerClassname={"!h-[40px] "}
                                                />
                                            </div>
                                        }
                                        dropdown={
                                            <div className="flex items-center lg:rounded-[5px] lg:shadow-buttonShadow2 py-[6px] px-[10px] gap-2 !border !border-slate-100">
                                                <div
                                                    className={`text-slate-800 font-rocGroteskMedium text-[13px] inline-block`}
                                                >
                                                    {productFilter
                                                        ? truncate(productFilter?.split("-")[1], {
                                                              length: 15,
                                                          })
                                                        : "All product"}
                                                </div>
                                                <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>
                                            </div>
                                        }
                                        dropdownItemsClasses="!px-0 !py-0"
                                        dropdownClassName="!w-full !mb-10 !-mt-7"
                                        dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[5px] !w-auto !min-h-[300px] !max-h-[300px] !pt-0`}
                                        dropdownOptions={[
                                            {
                                                label: (
                                                    <div
                                                        className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                border-b border-b-slate-100 !w-[190px] px-4 py-[10px] "
                                                    >
                                                        <i
                                                            className={`${
                                                                productFilter === ""
                                                                    ? "ri-checkbox-line text-carribean-green"
                                                                    : "ri-checkbox-blank-line text-slate-200"
                                                            }    text-xl`}
                                                        ></i>
                                                        All product
                                                    </div>
                                                ),
                                                value: "",
                                            },
                                            ...productData?.map((item, idx) => ({
                                                label: (
                                                    <div
                                                        key={idx}
                                                        className=" text-slate-600 font-rocGroteskMedium text-[13px] gap-2 flex items-center
                                                border-b border-b-slate-100 !w-[190px] px-4 py-[10px]"
                                                    >
                                                        <i
                                                            className={`${
                                                                productFilter?.split("-")[0] ===
                                                                item?._id
                                                                    ? "ri-checkbox-line text-carribean-green"
                                                                    : "ri-checkbox-blank-line text-slate-200"
                                                            }    text-xl`}
                                                        ></i>

                                                        <div className="flex flex-col">
                                                            {truncate(item?.productName, {
                                                                length: 18,
                                                            })}
                                                            <p className="text-[10px]">{` SKU No: ${item?.sku}`}</p>
                                                        </div>
                                                    </div>
                                                ),
                                                value: item?._id + "-" + item?.productName,
                                            })),
                                        ]}
                                    />

                                    <Dropdown
                                        handleChange={(name, value) =>
                                            handleChannelChange({
                                                target: { name, value },
                                            })
                                        }
                                        dropdown={
                                            <div className="flex items-center justify-between lg:rounded-[5px] lg:shadow-buttonShadow2 py-[6px] px-[10px] gap-2 !border !border-slate-100">
                                                <div
                                                    className={`text-slate-800 font-rocGroteskMedium text-[13px] inline-block ${
                                                        channelFilter && "capitalize"
                                                    }`}
                                                >
                                                    <div
                                                        className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block `}
                                                    >
                                                        {truncate(channelFilter, { length: 15 }) ||
                                                            "All channels"}
                                                    </div>
                                                </div>
                                                <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>
                                            </div>
                                        }
                                        dropdownItemsClasses="!px-0 !py-0"
                                        dropdownClassName="!mb-10 !-mt-7"
                                        dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[5px] !max-h-[200px] !w-[190px] !min-w-min
                            `}
                                        name="valueRange"
                                        dropdownOptions={[
                                            {
                                                label: (
                                                    <div
                                                        className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                border-b border-b-slate-100 !w-[190px] px-4 py-[10px]"
                                                    >
                                                        <i
                                                            className={`${
                                                                channelFilter === ""
                                                                    ? "ri-checkbox-line text-carribean-green"
                                                                    : "ri-checkbox-blank-line text-slate-200"
                                                            }    text-xl`}
                                                        ></i>
                                                        All channels
                                                    </div>
                                                ),
                                                value: "",
                                            },
                                            ...salesChannel?.map((item, idx) => ({
                                                label: (
                                                    <div
                                                        key={idx}
                                                        className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                border-b border-b-slate-100 !w-[190px] px-4 py-[10px] capitalize"
                                                    >
                                                        <i
                                                            className={`${
                                                                channelFilter === item
                                                                    ? "ri-checkbox-line text-carribean-green"
                                                                    : "ri-checkbox-blank-line text-slate-200"
                                                            }    text-xl`}
                                                        ></i>
                                                        {item}
                                                    </div>
                                                ),
                                                value: item,
                                            })),
                                        ]}
                                    />
                                    <Dropdown
                                        handleChange={(name, value) =>
                                            handleWarehouseChange({
                                                target: { name, value },
                                            })
                                        }
                                        search={
                                            <div ref={warehouseRef} className="">
                                                <TextInput
                                                    value={warehouseSearch}
                                                    name={"search"}
                                                    type={"text"}
                                                    required={false}
                                                    onChange={handleWarehouseSearch}
                                                    onInput={handleWarehouseDebouncedChange}
                                                    inputPlaceholder="Search warehouse"
                                                    containerClassname=""
                                                    inputContainerClassname={"!h-[40px] "}
                                                />
                                            </div>
                                        }
                                        dropdown={
                                            <div className="flex items-center justify-between lg:rounded-[5px] lg:shadow-buttonShadow2 py-[6px] px-[10px] gap-2 !border !border-slate-100">
                                                <div
                                                    className={`text-slate-800 font-rocGroteskMedium text-[13px] inline-block capitalize`}
                                                >
                                                    {warehouse
                                                        ? truncate(warehouse?.split("-")[0], {
                                                              length: 12,
                                                          })
                                                        : "All warehouses"}
                                                </div>
                                                <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>
                                            </div>
                                        }
                                        dropdownItemsClasses="!px-0 !py-0"
                                        dropdownClassName="!mb-10 !-mt-7"
                                        dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[5px] !max-h-[200px]
                                         !w-[200px] !min-w-min !pt-0
                            `}
                                        name="valueRange"
                                        dropdownOptions={[
                                            {
                                                label: (
                                                    <div
                                                        className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                border-b border-b-slate-100 !w-[200px] px-4 py-[10px] capitalize"
                                                    >
                                                        <i
                                                            className={`${
                                                                warehouse === ""
                                                                    ? "ri-checkbox-line text-carribean-green"
                                                                    : "ri-checkbox-blank-line text-slate-200"
                                                            }    text-xl`}
                                                        ></i>
                                                        All Warehouses
                                                    </div>
                                                ),
                                                value: "",
                                            },
                                            ...warehouseData?.map((item, idx) => ({
                                                label: (
                                                    <div
                                                        key={idx}
                                                        className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                    border-b border-b-slate-100 !w-[200px] px-4 py-[10px] capitalize"
                                                    >
                                                        <i
                                                            className={`${
                                                                warehouse?.split("-")[1] ===
                                                                item?._id
                                                                    ? "ri-checkbox-line text-carribean-green"
                                                                    : "ri-checkbox-blank-line text-slate-200"
                                                            }    text-xl`}
                                                        ></i>
                                                        {item?.warehouseName}
                                                    </div>
                                                ),
                                                value: item?.warehouseName + "-" + item?._id,
                                            })),
                                        ]}
                                    />
                                    <Dropdown
                                        handleChange={(name, value) =>
                                            handleDateRangeChange({ target: { name, value } })
                                        }
                                        dropdown={
                                            <div className="flex items-center justify-between lg:rounded-[5px] lg:shadow-buttonShadow2 py-[6px] px-[10px] gap-2 !border !border-slate-100">
                                                <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>

                                                <div
                                                    className={`text-slate-800 font-rocGroteskMedium text-[13px] inline-block`}
                                                >
                                                    {dateFilter || "All times"}
                                                </div>
                                            </div>
                                        }
                                        dropdownItemsClasses="!px-0 !py-0"
                                        dropdownClassName="!w-full !mb-10 !-mt-7"
                                        dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[5px] !max-h-fit !w-auto`}
                                        name="valueRange"
                                        dropdownOptions={[
                                            ...values?.map((item, idx) => ({
                                                label: (
                                                    <div
                                                        key={idx}
                                                        className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                                                border-b border-b-slate-100 !w-[120px] px-4 py-[10px]"
                                                    >
                                                        {item?.label}
                                                    </div>
                                                ),
                                                value: item?.value + "-" + item?.label,
                                            })),
                                        ]}
                                    />
                                    {/* <Dropdown
                                        handleChange={(name, value) =>
                                            handleChangeValue({
                                                target: { name, value },
                                            })
                                        }
                                        dropdown={
                                            <div className="flex items-center justify-between rounded-[5px] shadow-buttonShadow2 py-1.5 px-2.5 gap-2 !border !border-slate-100">
                                                <i className="ri-equalizer-line text-lg text-slate-800"></i>

                                                <div
                                                    className={`text-slate-800 font-rocGroteskMedium text-[13px] inline-block`}
                                                >
                                                    Show/hide
                                                </div>
                                            </div>
                                        }
                                        dropdownItemsClasses="!px-0 !py-0"
                                        dropdownClassName="!mb-10 !-mt-7"
                                        dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[5px] !max-h-[200px] !w-[200px] !min-w-min
                            `}
                                        name="valueRange"
                                        dropdownOptions={[
                                            // ...channels?.map((item, idx) => ({
                                            //     label: (
                                            //         <div
                                            //             key={idx}
                                            //             className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                                            //         border-b border-b-slate-100 !w-[159px] px-4 py-[10px] capitalize"
                                            //         >
                                            //             {item?.channel}
                                            //         </div>
                                            //     ),
                                            //     value: item?.channel,
                                            // })),
                                            {
                                                label: (
                                                    <div
                                                        className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                                                border-b border-b-slate-100 !w-[140px] px-4 py-[10px] capitalize"
                                                    >
                                                        All channels
                                                    </div>
                                                ),
                                                value: "",
                                            },
                                        ]}
                                    /> */}
                                </div>
                                {/* <Button
                                    btnText="Export"
                                    btnClassname="!bg-transparent !w-fit !shadow-buttonShadow2 !px-2.5 !py-2 !rounded-[5px] !border !border-slate-100"
                                    icon={
                                        <i className="ri-arrow-go-forward-fill text-slate-800"></i>
                                    }
                                /> */}
                            </div>
                        </div>

                        <div className="min-h-[300px]">
                            {tableLoading && !tableIsLoadingMore ? (
                                <>
                                    <div className="lg:hidden">
                                        <div className="200px flex items-center justify-center">
                                            <Loader size={6} />
                                        </div>
                                    </div>
                                    <div className="overflow-x-hidden max-lg:hidden">
                                        <CustomTableLoader tableHeader={tableHeader} />
                                    </div>
                                </>
                            ) : tableBodyData?.length >= 1 ? (
                                <div className="">
                                    <div className="lg:hidden px-6 space-y-2.5">
                                        {mobileTableBody}
                                    </div>

                                    <div className="overflow-x-scroll mb-6 overflow-y-scroll max-lg:hidden">
                                        <CustomTable
                                            tableBody={tableBody}
                                            tableHeader={tableHeader}
                                            isScrollable={false}
                                            isCellBordered={true}
                                            isCellSelectable={false}
                                            isAllSelectable={false}
                                            headerContainerClass="!bg-slate-50"
                                            headerItemClass="font-rocGroteskMedium !text-xs !text-slate-700"
                                            tableClass="!border !border-slate-200"
                                            bodyItemClass="!border-slate-200 !cursor-default !bg-transparent"
                                            sort={handleSortChange}
                                        />
                                    </div>
                                </div>
                            ) : (
                                tableBodyData?.length < 1 &&
                                !tableLoading && (
                                    <div className="flex items-center justify-center flex-col h-[200px]">
                                        <p className="text-gm-40 text-base font-rocGroteskMedium max-w-[330px] text-center">
                                            It looks like there are no products listed here right
                                            now
                                        </p>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default TopLeastPerformer;
