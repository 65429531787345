import moment from "moment";

export type AllowedPeriods = "day" | "week" | "month" | "quarter" | "year";

export const periodStartDateAndEndDate = (period: AllowedPeriods, numberOfMonths?:number) => {
    const now = moment();
    let startDate;
    let endDate;
    if (numberOfMonths) {
        startDate = now.clone().subtract(numberOfMonths, "months").format("YYYY-MM-DD");
        endDate = now.clone().format("YYYY-MM-DD");
    } else {
        startDate =  now.clone().subtract(1, period).format("YYYY-MM-DD");
        endDate = now.clone().format("YYYY-MM-DD");
    }
    return {
        startDate,
        endDate,
    };
};
