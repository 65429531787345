import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import TabsContainer from "components/common/TabsContainer";
import PageFrame from "components/layout/PageFrame";
import Board from "components/views/Ldb/Dashboard/Tasks/Board";
import TaskLists from "components/views/Ldb/Dashboard/Tasks/TaskList";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { taskActions } from "redux/Ldb/actions/taskActions";
import NewTasksModal from "../../../components/views/Ldb/Dashboard/Tasks/NewTasksModal";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";

const Task = () => {
    const { id } = useParams();
    const [addTasks, setAddTasks] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { fetchOverdueTaskTwoSuccess, fetchingOverdueTaskTwoList } = useAppSelector(
        (state) => state.task
    );
    const [currentTab, setCurrentTab] = useState(0);
    const tabs = ["Board", "Task list"];

    const displayActiveTab = () => {
        switch (currentTab) {
            case 0:
                return <Board />;

            case 1:
                return <TaskLists />;

            default:
                return;
        }
    };

    useEffect(() => {
        if (id) {
            setCurrentTab(parseInt(id));
        }
    }, [id]);

    useEffect(() => {
        dispatch(taskActions.fetchOverdueTaskTwoList(1, "delayed"));
    }, [dispatch]);

    return (
        <PageFrame containerClassName={"h-full w-full"}>
            {addTasks && (
                <NewTasksModal openModal={addTasks} closeModal={() => setAddTasks(!addTasks)} />
            )}

            {/* <TaskDetailsModal /> */}

            <div className="mx-7 h-full fixed w-[76.5%] 2xl:w-[77.5%] max-lg:relative max-lg:mx-0 max-lg:w-full">
                <div className="mb-6 space-x-5  flex item-center ">
                    <div>
                        <BreadCrumb
                            parentCrumb={"My tasks"}
                            size="large"
                            parentClassName="!text-slate-700 !text-lg"
                        />
                    </div>
                    {fetchOverdueTaskTwoSuccess?.totalCount !== 0 &&
                        !fetchingOverdueTaskTwoList && (
                            <div
                                className="flex items-center py-[4px] rounded-[20px] cursor-pointer space-x-2 px-[15px] bg-r-25"
                                onClick={() => navigate("/dashboard/task/1?show=delayed")}
                            >
                                <i className="ri-error-warning-fill text-r-70"></i>
                                <p className="text-slate-900 text-xs font-rocGroteskRegular">
                                    {fetchOverdueTaskTwoSuccess?.totalCount} overdue tasks
                                </p>
                            </div>
                        )}
                </div>
                <div className="mt-2">
                    <TabsContainer
                        tabs={tabs}
                        showTabMenu={"task"}
                        activeTab={currentTab}
                        setActiveTab={setCurrentTab}
                        component={displayActiveTab()}
                        className={"!px-1"}
                        showButton={
                            <Button
                                btnText={"Add new task"}
                                btnClassname="!py-2 !px-4 max-lg:mt-3"
                                isLoading={false}
                                onClick={() => {
                                    setAddTasks(true);
                                }}
                            />
                        }
                    />
                </div>
            </div>
        </PageFrame>
    );
};
export default Task;
