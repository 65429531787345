import Button from "components/common/Button";
// import CopilotChat from "components/common/CopilotChat";
import Dropdown from "components/common/Dropdown";
import MultiselectInput from "components/common/InputField/MultiselectInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import ModalContainer from "components/common/ModalContainer";
import React from "react";
import {adjustHeight} from "variables";

const Copilot = () => {
    const chatOptions = [
        {
            label: "Pin",
            value: "pin",
            icon: <i className="ri-pushpin-2-line"></i>,
            action: () => console.log("click"),
        },
        {
            label: "Share",
            value: "share",
            icon: <i className="ri-share-forward-line"></i>,
            action: () => console.log("click"),
        },
        {
            label: "Rename",
            value: "rename",
            icon: <i className="ri-edit-2-line"></i>,
            action: () => console.log("click"),
        },
        {
            label: "Delete",
            value: "delete",
            icon: <i className="ri-delete-bin-6-line text-r-50"></i>,
            action: () => console.log("click"),
        },
    ];

    return (
        <div className="flex pl-2 gap-6 h-full">
            <div className="w-[75%] h-full relative bg-white rounded-t-xl  ">
                {/* <CopilotChat chats={[]} /> */}
                <div className="flex flex-col items-center">
                    <div className="mt-12 flex flex-col items-center mb-10">
                        <div className="px-[10px] w-fit bg-white rounded-xl border border-slate-100 shadow-copilotLogo mb-4">
                            <i className="ri-robot-fill text-[40px] bg-clip-text text-transparent bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%"></i>
                        </div>
                        <div className="flex items-center gap-2 mb-1">
                            <span className="text-[32px] font-rocGroteskMedium bg-clip-text text-transparent bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                                Co-Pilot
                            </span>
                            <div className="rounded-[5px] flex justify-center items-center w-[48px] h-5 p-[1px] bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                                <div className="px-[10px]  bg-white rounded h-full flex items-center">
                                    <span className="text-xs font-rocGroteskMedium leading-4 bg-clip-text text-transparent bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%">
                                        Beta
                                    </span>
                                </div>
                            </div>
                        </div>
                        <p className="text-slate-500 text-base font-rocGroteskMedium">
                            Say Hi to your Business Co-Pilot
                        </p>
                    </div>
                    <div className="grid grid-cols-2 gap-4 w-[60%]">
                        <div className="px-6 py-3 cursor-pointer hover:bg-slate-50 flex items-center whitespace-nowrap justify-center border border-slate-100 rounded">
                            <p className="text-sm font-rocGroteskMedium">Create RFQ</p>
                        </div>
                        <div className="px-6 py-3 cursor-pointer hover:bg-slate-50 flex items-center whitespace-nowrap justify-center border border-slate-100 rounded">
                            <p className="text-sm font-rocGroteskMedium">
                                Create PO for all accepted RFQ
                            </p>
                        </div>
                        <div className="px-6 py-3  cursor-pointer hover:bg-slate-50 flex items-center whitespace-nowrap justify-center border border-slate-100 rounded">
                            <p className="text-sm font-rocGroteskMedium">
                                Generate Invoice for recent PO
                            </p>
                        </div>
                        <div className="px-6 py-3  cursor-pointer hover:bg-slate-50flex items-center whitespace-nowrap justify-center border border-slate-100 rounded">
                            <p className="text-sm font-rocGroteskMedium">
                                Request Report for July 2023
                            </p>
                        </div>
                    </div>

                    <div className="flex items-center justify-center">
                        <div className="p-4 fixed bottom-20 w-[54%] bg-white rounded-lg shadow-boxShadow-3 border border-slate-100 mx-auto flex justify-between items-center">
                            <div className="flex items-center gap-3 w-full">
                                <div className="relative">
                                    <i
                                        onClick={() => {
                                            const uploadElem =
                                                document.getElementById("attachment");
                                            uploadElem?.click();
                                        }}
                                        className="ri-attachment-2 cursor-pointer text-slate-500 text-xl"
                                    ></i>
                                    <input
                                        type="file"
                                        hidden
                                        id="attachment"
                                        className="absolute"
                                    />
                                </div>
                                <textarea
                                    onKeyUp={(e) => {
                                        adjustHeight(e.target);
                                    }}
                                    placeholder="How can your Co-Pilot help?"
                                    rows={1}
                                    className="border-0 w-full overflow-y-auto resize-none min-h-[24px] max-h-[150px] bg-transparent p-0 pr-10 outline-none focus:border-0 focus:ring-0 focus-visible:ring-0 dark:bg-transparent"
                                />
                            </div>
                            <div>
                                <i className="ri-send-plane-2-fill cursor-pointer bg-clip-text text-transparent text-xl bg-gradient-to-tr from-[#5C58FF] from-0% to-[#FF3D1D] to-100%"></i>
                            </div>
                        </div>
                    </div>
                    <div className="absolute bg-white bottom-[-24px] border-t w-full border-slate-100 flex justify-center py-4 px-6">
                        <p className="text-xs text-slate-500 font-rocGroteskMedium">
                            As an AI model still in training, TradeAlly Co-Pilot may produce
                            inaccurate information about people, places, or facts.
                        </p>
                    </div>
                </div>
            </div>
            <div className="bg-white h-[calc(100%-84px)] pt-6 absolute top-[84px] right-0 w-[20%]">
                <div className="px-6">
                    <Button btnText={"New chat"} type={"button"} btnClassname={""} />
                </div>

                <div className="mt-3">
                    <div className="">
                        <p className="py-2 text-xs text-slate-500 font-rocGroteskMedium px-6">
                            Pinned
                        </p>
                        <div className="py-3 px-6 flex items-center justify-between gap-2 cursor-pointer hover:bg-slate-50">
                            <div className="flex items-center gap-2">
                                <i className="ri-pushpin-2-line"></i>
                                <p className="text-sm font-rocGroteskMedium">
                                    Walk me through creat...
                                </p>
                            </div>

                            <Dropdown
                                dropdown={
                                    <i className="ri-more-2-fill before:content-['\ef76'] text-slate-500"></i>
                                }
                                dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                dropdownContainerClasses={`shadow-cardShadow-2 left-[-170px] w-[180px] border-0 rounded !max-h-fit`}
                                dropdownItemsClasses={`last:text-r-50`}
                                name={"chat-dropdown"}
                                dropdownOptions={chatOptions}
                            />
                        </div>
                        <div className="py-3 px-6 flex items-center justify-between gap-2 cursor-pointer hover:bg-slate-50">
                            <div className="flex items-center gap-2">
                                <i className="ri-pushpin-2-line"></i>
                                <p className="text-sm font-rocGroteskMedium">
                                    Analyze the following...
                                </p>
                            </div>

                            <Dropdown
                                dropdown={
                                    <i className="ri-more-2-fill before:content-['\ef76'] text-slate-500"></i>
                                }
                                dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                dropdownContainerClasses={`shadow-cardShadow-2   border-0 rounded !max-h-fit`}
                                dropdownItemsClasses={``}
                                name={"faq-dropdown"}
                                dropdownOptions={chatOptions}
                            />
                        </div>
                    </div>
                    <div className="">
                        <p className="py-2 text-xs text-slate-500 font-rocGroteskMedium px-6">
                            Today
                        </p>
                        <div className="py-3 px-6 flex items-center justify-between gap-2 cursor-pointer hover:bg-slate-50">
                            <div className="flex items-center gap-2">
                                <i className="ri-chat-4-line"></i>
                                <p className="text-sm font-rocGroteskMedium">
                                    Walk me through creat...
                                </p>
                            </div>

                            <Dropdown
                                dropdown={
                                    <i className="ri-more-2-fill before:content-['\ef76'] text-slate-500"></i>
                                }
                                dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                dropdownContainerClasses={`shadow-cardShadow-2 left-[-170px] w-[180px] border-0 rounded !max-h-fit`}
                                dropdownItemsClasses={`last:text-r-50`}
                                name={"chat-dropdown"}
                                dropdownOptions={chatOptions}
                            />
                        </div>
                        <div className="py-3 px-6 flex items-center justify-between gap-2 cursor-pointer hover:bg-slate-50">
                            <div className="flex items-center gap-2">
                                <i className="ri-chat-4-line"></i>
                                <p className="text-sm font-rocGroteskMedium">
                                    Analyze the following...
                                </p>
                            </div>

                            <Dropdown
                                dropdown={
                                    <i className="ri-more-2-fill before:content-['\ef76'] text-slate-500"></i>
                                }
                                dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                dropdownContainerClasses={`shadow-cardShadow-2 left-[-170px] w-[180px] border-0 rounded !max-h-fit`}
                                dropdownItemsClasses={`last:text-r-50`}
                                name={"chat-dropdown"}
                                dropdownOptions={chatOptions}
                            />
                        </div>
                    </div>
                    <div className="">
                        <p className="py-2 text-xs text-slate-500 font-rocGroteskMedium px-6">
                            July
                        </p>
                        <div className="py-3 px-6 flex items-center justify-between gap-2 cursor-pointer hover:bg-slate-50">
                            <div className="flex items-center gap-2">
                                <i className="ri-chat-4-line"></i>
                                <p className="text-sm font-rocGroteskMedium">
                                    Walk me through creat...
                                </p>
                            </div>

                            <Dropdown
                                dropdown={
                                    <i className="ri-more-2-fill before:content-['\ef76'] text-slate-500"></i>
                                }
                                dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                dropdownContainerClasses={`shadow-cardShadow-2 left-[-170px] w-[180px] border-0 rounded !max-h-fit`}
                                dropdownItemsClasses={`last:text-r-50`}
                                name={"chat-dropdown"}
                                dropdownOptions={chatOptions}
                            />
                        </div>
                        <div className="py-3 px-6 flex items-center justify-between gap-2 cursor-pointer hover:bg-slate-50">
                            <div className="flex items-center gap-2">
                                <i className="ri-chat-4-line"></i>
                                <p className="text-sm font-rocGroteskMedium">
                                    Analyze the following...
                                </p>
                            </div>

                            <Dropdown
                                dropdown={
                                    <i className="ri-more-2-fill before:content-['\ef76'] text-slate-500"></i>
                                }
                                dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                dropdownContainerClasses={`shadow-cardShadow-2 left-[-170px] w-[180px] border-0 rounded !max-h-fit`}
                                dropdownItemsClasses={`last:text-r-50`}
                                name={"chat-dropdown"}
                                dropdownOptions={chatOptions}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <ModalContainer
                open={false}
                showCloseIcon={false}
                tailwindClassName="w-[38%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    // setEditModalOpen(false);
                    // setActiveModalId(null);
                }}
            >
                <form className="bg-white shadow-cardShadow relative rounded-[20px] py-8 px-10">
                    <div className="flex justify-end">
                        <i className="ri-close-fill text-xl"></i>
                    </div>
                    <p className="text-xl font-rocGroteskMedium text-center mb-6">
                        Rename this chat
                    </p>

                    <TextareaInput
                        name={"orderDescription"}
                        // value={orderDetails.orderDescription}
                        // onChange={handleChange}
                        rows={4}
                        placeholder={"Chat name"}
                        required={false}
                        containerClassname="mb-6"
                    />
                    <Button btnText={"Save"} type={"submit"} />
                </form>
            </ModalContainer>

            <ModalContainer
                open={false}
                showCloseIcon={false}
                tailwindClassName="w-[38%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    // setEditModalOpen(false);
                    // setActiveModalId(null);
                }}
            >
                <form className="bg-white shadow-cardShadow relative rounded-[20px] py-8 px-10">
                    <div className="flex justify-end">
                        <i className="ri-close-fill text-xl"></i>
                    </div>
                    <p className="text-xl font-rocGroteskMedium text-center mb-6">
                        Share chat with team
                    </p>
                    <div className="mb-10">
                        <MultiselectInput
                            value={""}
                            name="requiredDocuments"
                            placeholder={"Enter email address"}
                            className=""
                            isRequired={true}
                            // handleChange={(name, value, valuesArray) => onChangeDocs(valuesArray)}
                            // switchOptions={vendorCategory}
                            dropdownOptions={[{title: "Team member 1"}]?.map((item) => ({
                                label: item.title,
                                value: item.title,
                            }))}
                        />
                    </div>

                    <Button btnText={"Share chat"} type={"submit"} />
                    <p className="text-sm font-rocGroteskMedium text-center mt-[10px] cursor-pointer">
                        Close
                    </p>
                </form>
            </ModalContainer>

            <ModalContainer
                open={false}
                showCloseIcon={false}
                tailwindClassName="w-[38%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    // setEditModalOpen(false);
                    // setActiveModalId(null);
                }}
            >
                <form className="bg-white shadow-cardShadow relative rounded-[20px] py-8 px-10">
                    <div className="flex justify-end">
                        <i className="ri-close-fill text-xl"></i>
                    </div>
                    <p className="text-xl font-rocGroteskMedium text-center mb-6">Delete chat?</p>
                    <div className="mb-[46px]">
                        <p className="text-center text-base font-rocGroteskMedium">
                            Are you sure you want to delete your prompt history? This will remove
                            this chat from your saved prompts.
                        </p>
                    </div>

                    <Button
                        btnText={"Share chat"}
                        type={"submit"}
                        btnClassname={"!bg-[#FFEFEC] !text-gm-50 border border-r-50 mb-[10px]"}
                    />
                    <Button
                        btnText={"No, cancel"}
                        type={"button"}
                        btnClassname={"!bg-n-20 !text-gm-50"}
                    />
                </form>
            </ModalContainer>

            <ModalContainer
                open={false}
                showCloseIcon={false}
                tailwindClassName="w-[38%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    // setEditModalOpen(false);
                    // setActiveModalId(null);
                }}
            >
                <form className="bg-white shadow-cardShadow relative rounded-[20px] py-8 px-10">
                    <div className="flex justify-end">
                        <i className="ri-close-fill text-xl"></i>
                    </div>
                    <p className="text-xl font-rocGroteskMedium text-center mb-6">Upload File</p>
                    <div className="relative">
                        <div className="flex flex-col items-center cursor-pointer justify-center mb-4 py-12 px-6 text-center text-sm rounded border border-dashed border-slate-200">
                            <i className="ri-upload-cloud-2-line text-slate-500 text-4xl mb-4"></i>
                            <p className="mb-1 font-rocGroteskMedium">
                                <span>Click to upload</span>{" "}
                                <span className="text-tradeally-neutral-200">or drag & drop</span>
                            </p>
                            <p className="text-tradeally-neutral-200 font-rocGroteskMedium">
                                Supported formats: PDF, CSV, DOCX & TXT. File size limit: 25MB
                            </p>
                        </div>
                        <input
                            type="file"
                            accept=".pdf,.doc,.docx,.txt"
                            className="absolute top-0 w-full outline-none h-full opacity-0 cursor-pointer"
                        />
                    </div>

                    <Button btnText={"Submit"} type={"submit"} btnClassname={" mb-3"} />
                    <Button
                        btnText={"Close"}
                        type={"button"}
                        btnClassname={"!bg-n-20 !text-gm-50"}
                    />
                </form>
            </ModalContainer>
        </div>
    );
};

export default Copilot;
