import { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "./storeHooks";
import { useParams } from "react-router-dom";

const useGetSingleProductGMWValues = (dateRange: string, warehouseId?: string) => {
    const dispatch = useAppDispatch();
    const { productId } = useParams();
    const {
        fetchingSingleProductGMW,
        fetchedSingleProductGMWSuccess,
        fetchedSingleProductGMWFailure,
    } = useAppSelector((state) => state.inventory);

    const [gmwValue, setGmwValue] = useState<{ [key: string]: any }>({});

    useEffect(() => {
        dispatch(inventoryActions.fetchSingleProductGMW(productId, dateRange, warehouseId));
    }, [dispatch, productId, dateRange, warehouseId]);

    useEffect(() => {
        if (Boolean(fetchedSingleProductGMWSuccess)) {
            setGmwValue(fetchedSingleProductGMWSuccess);
        }
    }, [fetchedSingleProductGMWSuccess]);

    return {
        isFetching: fetchingSingleProductGMW,
        error: fetchedSingleProductGMWFailure,
        data: gmwValue,
    };
};

export default useGetSingleProductGMWValues;
