import TextInput from "components/common/InputField/TextInput";
import React, { useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { BoardList } from "./BoardList";
import { taskActions } from "redux/Ldb/actions";
import { useEffect } from "react";
import { debounce, truncate } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks";
import VendorAvatar from "components/common/VendorAvatar";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";
import { onScroll } from "helpers";
import Loader from "components/common/Loader";
import TaskDetailsModal from "./TaskDetails";

const Board = () => {
    const limit = 20;
    const [taskCompletedLimit, setTaskCompletedLimit] = useState(limit);
    const [taskLimit, setTaskLimit] = useState(limit);
    const [taskPendingLimit, setTaskPendingLimit] = useState(limit);
    const [taskActiveLimit, setTaskActiveLimit] = useState(limit);
    const [taskOverdueLimit, setTaskOverdueLimit] = useState(limit);
    const [pendingTaskList, setPendingTaskList] = useState([]);
    const [overdueTaskList, setOverdueTaskList] = useState([]);
    const [activeTaskList, setActiveTaskList] = useState([]);
    const [completedTaskList, setCompletedTaskList] = useState([]);
    const [loadMoreCompletedTask, setLoadMoreCompletedTask] = useState(false);
    const [loadMorePendingTask, setLoadMorePendingTask] = useState(false);
    const [loadMoreActiveTask, setLoadMoreActiveTask] = useState(false);
    const [loadMoreOverdueTask, setLoadMoreOverdueTask] = useState(false);
    const [scrollTitle, setScrollTitle] = useState("");
    const [openMobileContainer, setOpenMobileContainer] = useState<null | number>(null);
    const [filter, setFilter] = useState({
        search: "",
    });
    const [debouncedFilter, setDebouncedFilter] = useState({
        search: "",
    });
    const [listData, setListData] = useState([]);
    const [activeTaskPagination, setActivePagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [completedTaskPagination, setCompletedPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [pendingTaskPagination, setPendingPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [overdueTaskPagination, setOverduePagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [showTaskDetails, setShowTaskDetails] = useState(false);
    const [taskId, setTaskId] = useState("");

    const dispatch = useAppDispatch();

    const {
        fetchActiveTaskSuccess,
        fetchPendingTaskSuccess,
        fetchCompletedTaskSuccess,
        fetchOverdueTaskSuccess,
        updateTaskSuccess,
        fetchingCompletedTaskList,
        fetchingOverdueTaskList,
        fetchingActiveTaskList,
        fetchingPendingTaskList,
        fetchActiveTaskTwoSuccess,
        fetchPendingTaskTwoSuccess,
        fetchCompletedTaskTwoSuccess,
        fetchOverdueTaskTwoSuccess,
    } = useAppSelector((state) => state.task);

    const taskPagination =
        scrollTitle.toLowerCase() === "on-track"
            ? activeTaskPagination
            : scrollTitle.toLowerCase() === "pending"
            ? pendingTaskPagination
            : scrollTitle.toLowerCase() === "delayed"
            ? overdueTaskPagination
            : scrollTitle.toLowerCase() === "completed"
            ? completedTaskPagination
            : completedTaskPagination;

    const setLoadMoreTask =
        scrollTitle.toLowerCase() === "on-track"
            ? setLoadMoreActiveTask
            : scrollTitle.toLowerCase() === "pending"
            ? setLoadMorePendingTask
            : scrollTitle.toLowerCase() === "delayed"
            ? setLoadMoreOverdueTask
            : scrollTitle.toLowerCase() === "completed"
            ? setLoadMoreCompletedTask
            : setLoadMoreCompletedTask;

    const loadMoreTask =
        scrollTitle.toLowerCase() === "on-track"
            ? loadMoreActiveTask
            : scrollTitle.toLowerCase() === "pending"
            ? loadMorePendingTask
            : scrollTitle.toLowerCase() === "delayed"
            ? loadMoreOverdueTask
            : scrollTitle.toLowerCase() === "completed"
            ? loadMoreCompletedTask
            : false;

    const setTasksLimit =
        scrollTitle.toLowerCase() === "on-track"
            ? setTaskActiveLimit
            : scrollTitle.toLowerCase() === "pending"
            ? setTaskPendingLimit
            : scrollTitle.toLowerCase() === "delayed"
            ? setTaskOverdueLimit
            : scrollTitle.toLowerCase() === "completed"
            ? setTaskCompletedLimit
            : setTaskCompletedLimit;

    const tasksLimit =
        scrollTitle.toLowerCase() === "on-track"
            ? taskActiveLimit
            : scrollTitle.toLowerCase() === "pending"
            ? taskPendingLimit
            : scrollTitle.toLowerCase() === "delayed"
            ? taskOverdueLimit
            : scrollTitle.toLowerCase() === "completed"
            ? taskCompletedLimit
            : taskCompletedLimit;

    const loading =
        scrollTitle.toLowerCase() === "on-track"
            ? fetchingActiveTaskList
            : scrollTitle.toLowerCase() === "pending"
            ? fetchingPendingTaskList
            : scrollTitle.toLowerCase() === "delayed"
            ? fetchingOverdueTaskList
            : scrollTitle.toLowerCase() === "completed"
            ? fetchingCompletedTaskList
            : false;

    const handleCardClick = (id) => {
        setTaskId(id);
    };

    useEffect(() => {
        dispatch(
            taskActions.fetchCompletedTaskList(
                taskCompletedLimit,
                "completed",
                "",
                debouncedFilter.search
            )
        );
    }, [dispatch, taskCompletedLimit, debouncedFilter.search]);

    useEffect(() => {
        dispatch(
            taskActions.fetchOverdueTaskList(
                taskOverdueLimit,
                "delayed",
                "",
                debouncedFilter.search
            )
        );
    }, [dispatch, taskOverdueLimit, debouncedFilter.search]);

    useEffect(() => {
        dispatch(
            taskActions.fetchActiveTaskList(taskActiveLimit, "on-track", "", debouncedFilter.search)
        );
    }, [dispatch, taskActiveLimit, debouncedFilter.search]);

    useEffect(() => {
        dispatch(
            taskActions.fetchPendingTaskList(
                taskPendingLimit,
                "pending",
                "",
                debouncedFilter.search
            )
        );
    }, [dispatch, taskPendingLimit, debouncedFilter.search]);

    useEffect(() => {
        if (Boolean(fetchActiveTaskSuccess)) {
            setActiveTaskList(fetchActiveTaskSuccess?.tasks);
            setActivePagination({
                current: fetchActiveTaskSuccess?.pagination?.current,
                number_of_pages: fetchActiveTaskSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchActiveTaskSuccess]);

    useEffect(() => {
        if (Boolean(fetchPendingTaskSuccess)) {
            setPendingTaskList(fetchPendingTaskSuccess?.tasks);
            setPendingPagination({
                current: fetchPendingTaskSuccess?.pagination?.current,
                number_of_pages: fetchPendingTaskSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchPendingTaskSuccess]);

    useEffect(() => {
        if (Boolean(fetchCompletedTaskSuccess)) {
            setCompletedTaskList(fetchCompletedTaskSuccess?.tasks);
            setCompletedPagination({
                current: fetchCompletedTaskSuccess?.pagination?.current,
                number_of_pages: fetchCompletedTaskSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchCompletedTaskSuccess]);

    useEffect(() => {
        if (Boolean(fetchOverdueTaskSuccess)) {
            setOverdueTaskList(fetchOverdueTaskSuccess?.tasks);
            setOverduePagination({
                current: fetchOverdueTaskSuccess?.pagination?.current,
                number_of_pages: fetchOverdueTaskSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchOverdueTaskSuccess]);

    const handleSearch = (e) => {
        const { name, value } = e.target;

        setFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        dispatch(taskActions.fetchPendingTaskTwoList(taskLimit, "pending"));
        dispatch(taskActions.fetchOverdueTaskTwoList(taskLimit, "delayed"));
        dispatch(taskActions.fetchCompletedTaskTwoList(taskLimit, "completed"));
        dispatch(taskActions.fetchActiveTaskTwoList(taskLimit, "on-track"));
        dispatch(taskActions.fetchMyTaskTwoList(taskLimit, "", "assignee"));
        dispatch(taskActions.fetchAllTaskTwoList(taskLimit));
    }, [dispatch]);

    const handleDebouncedChange = debounce((e) => {
        const { name, value } = e.target;

        setDebouncedFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, 1500);

    useEffect(() => {
        if (debouncedFilter.search !== "") {
            dispatch(
                taskActions.fetchPendingTaskList(taskLimit, "pending", "", debouncedFilter.search)
            );
            dispatch(
                taskActions.fetchOverdueTaskList(taskLimit, "delayed", "", debouncedFilter.search)
            );
            dispatch(
                taskActions.fetchCompletedTaskList(
                    taskLimit,
                    "completed",
                    "",
                    debouncedFilter.search
                )
            );
            dispatch(
                taskActions.fetchActiveTaskList(taskLimit, "on-track", "", debouncedFilter.search)
            );
        }
    }, [dispatch, taskLimit, debouncedFilter.search]);

    useEffect(() => {
        setListData([
            {
                id: "1",
                taskTitle: activeTaskList[0]?.status ? activeTaskList[0]?.status : "on-track",
                taskTotal: fetchActiveTaskTwoSuccess?.totalCount
                    ? fetchActiveTaskTwoSuccess?.totalCount
                    : "0",
                cardDetails: activeTaskList,
            },
            {
                id: "2",
                taskTitle: pendingTaskList[0]?.status ? pendingTaskList[0]?.status : "pending",
                taskTotal: fetchPendingTaskTwoSuccess?.totalCount
                    ? fetchPendingTaskTwoSuccess?.totalCount
                    : "0",
                cardDetails: pendingTaskList,
            },
            {
                id: "3",
                taskTitle: completedTaskList[0]?.status
                    ? completedTaskList[0]?.status
                    : "completed",
                taskTotal: fetchCompletedTaskTwoSuccess?.totalCount
                    ? fetchCompletedTaskTwoSuccess?.totalCount
                    : "0",
                cardDetails: completedTaskList,
            },
            {
                id: "4",
                taskTitle: overdueTaskList[0]?.status ? overdueTaskList[0]?.status : "delayed",
                taskTotal: fetchOverdueTaskTwoSuccess?.totalCount
                    ? fetchOverdueTaskTwoSuccess?.totalCount
                    : "0",
                cardDetails: overdueTaskList,
            },
        ]);
    }, [
        activeTaskList,
        pendingTaskList,
        completedTaskList,
        overdueTaskList,
        fetchActiveTaskSuccess,
        fetchPendingTaskSuccess,
        fetchCompletedTaskSuccess,
        fetchOverdueTaskSuccess,
        fetchActiveTaskTwoSuccess,
        fetchPendingTaskTwoSuccess,
        fetchCompletedTaskTwoSuccess,
        fetchOverdueTaskTwoSuccess,
    ]);

    useEffect(() => {
        if (Boolean(updateTaskSuccess)) {
            dispatch(taskActions.fetchPendingTaskList(taskLimit, "pending"));
            dispatch(taskActions.fetchOverdueTaskList(taskLimit, "delayed"));
            dispatch(taskActions.fetchCompletedTaskList(taskLimit, "completed"));
            dispatch(taskActions.fetchActiveTaskList(taskLimit, "on-track"));
            dispatch(taskActions.fetchAllTaskList(taskLimit, ""));
            dispatch(taskActions.resetUpdateTask());
        }
    }, [dispatch, updateTaskSuccess, taskLimit]);

    const onDragEnd = (result, listData, setListData) => {
        if (!result.destination) return;
        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = listData.find((column) => column.taskTitle === source.droppableId);
            const destColumn = listData.find(
                (column) => column.taskTitle === destination.droppableId
            );
            const sourceItems = [...sourceColumn.cardDetails];
            const destItems = [...destColumn.cardDetails];
            const [movedItem] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, movedItem);
            if (source.droppableId?.toLowerCase() !== "completed") {
                if (movedItem?.assigneeType?.toLowerCase() !== "copilot") {
                    setListData((prevListData) => {
                        const updatedListData = prevListData.map((column) => {
                            if (column.taskTitle === source.droppableId) {
                                return { ...column, cardDetails: sourceItems };
                            }
                            if (column.taskTitle === destination.droppableId) {
                                return { ...column, cardDetails: destItems };
                            }
                            return column;
                        });

                        return updatedListData;
                    });
                }
            }
            dispatch(taskActions.updateTask({ status: destColumn?.taskTitle }, movedItem?._id));
        } else {
            const column = listData.find((column) => column.taskTitle === source.droppableId);
            const copiedItems = [...column.cardDetails];
            // const [removed] = copiedItems.splice(source.index, 1);
            // copiedItems.splice(destination.index, 0, removed);
            setListData((prevListData) => {
                const updatedListData = prevListData.map((c) => {
                    if (c.taskTitle === source.droppableId) {
                        return { ...c, cardDetails: copiedItems };
                    }
                    return c;
                });

                return updatedListData;
            });
        }
    };

    return (
        <div className="mt-5 ">
            <div className="flex justify-between items-center max-sm:flex-col-reverse max-sm:gap-3 max-sm:items-start max-sm:mb-4">
                <div>
                    <h1 className="text-slate-900 font-rocGroteskMedium text-lg">All tasks</h1>
                </div>
                <div className="flex items-center gap-3 max-sm:w-full">
                    <div className="max-sm:w-full">
                        <TextInput
                            name={"search"}
                            value={filter.search}
                            type={"text"}
                            inputPlaceholder={"Search"}
                            inputClassName={"!h-[40px] pl-[0px] !bg-white text-sm !mb-0"}
                            onChange={handleSearch}
                            onInput={handleDebouncedChange}
                            leftIcon={
                                <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                            }
                            inputContainerClassname={
                                "!rounded-[4px] !w-[300px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                            }
                        />
                    </div>

                    {/* <div>
                        <Dropdown
                            value={""}
                            dropdown={
                                <div className="flex items-center cursor-pointer gap-2 py-1.5 px-3 bg-white rounded shadow-boxShadow-2 border border-n-20">
                                    <i className="ri-filter-3-fill"></i>
                                    <p className="text-[13px] font-rocGroteskMedium">Filter</p>
                                </div>
                            }
                            dropdownContainerClasses={
                                " left-[-234px] !py-0 top-[49px] shadow-dropdownShadow rounded !border-0"
                            }
                            dropdownClassName={"!max-h-fit"}
                            name={"quickAction"}
                        />
                    </div> */}
                </div>
            </div>
            <div className="mt-7 flex space-x-[17px] w-full max-sm:hidden">
                <DragDropContext onDragEnd={(result) => onDragEnd(result, listData, setListData)}>
                    <div className="mt-7  flex space-x-[17px] w-full">
                        <BoardList
                            listData={listData}
                            scrollBar={"scrollBar"}
                            taskPagination={taskPagination}
                            setLoadMoreTask={setLoadMoreTask}
                            loadMoreTask={loadMoreTask}
                            setTaskLimit={setTasksLimit}
                            scrollTitle={scrollTitle}
                            taskLimit={tasksLimit}
                            limit={limit}
                            setScrollTitle={setScrollTitle}
                            loading={loading}
                        />
                    </div>
                </DragDropContext>
            </div>
            <div className="hidden max-sm:block w-full">
                {listData?.map((list, index) => {
                    return (
                        <div
                            key={list?.id}
                            className={`rounded p-4 shadow-faintShadow border-l-[4px] ${
                                list?.taskTitle?.toLowerCase() === "on-track" && "border-p-40"
                            } ${
                                list?.taskTitle?.toLowerCase() === "pending" &&
                                "border-logo-task-yellow"
                            } ${list?.taskTitle?.toLowerCase() === "completed" && "border-g-50"} ${
                                list?.taskTitle?.toLowerCase() === "delayed" && "border-r-40"
                            } mb-4`}
                        >
                            <div
                                onClick={() => {
                                    if (openMobileContainer === index) {
                                        setOpenMobileContainer(null);
                                    } else {
                                        setOpenMobileContainer(index);
                                    }
                                }}
                                className="flex items-center justify-between py-2.5"
                            >
                                <div className="flex items-center gap-3">
                                    {list?.taskTitle?.toLowerCase() === "on-track" && (
                                        <i className="ri-flashlight-line text-p-40"></i>
                                    )}
                                    {list?.taskTitle?.toLowerCase() === "pending" && (
                                        <i className="ri-hourglass-line text-logo-task-yellow"></i>
                                    )}
                                    {list?.taskTitle?.toLowerCase() === "completed" && (
                                        <i className="ri-checkbox-multiple-line text-g-50"></i>
                                    )}
                                    {list?.taskTitle?.toLowerCase() === "delayed" && (
                                        <i className="ri-alarm-warning-line text-r-40"></i>
                                    )}
                                    <p className="text-sm text-gm-40 font-rocGroteskMedium">
                                        {list?.taskTitle?.toLowerCase() === "on-track" &&
                                            "Active Tasks"}
                                        {list?.taskTitle?.toLowerCase() === "pending" &&
                                            "Pending Tasks"}
                                        {list?.taskTitle?.toLowerCase() === "completed" &&
                                            "Completed Tasks"}
                                        {list?.taskTitle?.toLowerCase() === "delayed" &&
                                            "Overdue Tasks"}
                                    </p>
                                    <div
                                        className={`border  border-slate-200 rounded-[16px] px-[10px] py-[4px]`}
                                    >
                                        <p className="text-[13px] text-gm-40 font-rocGroteskMedium">
                                            {list.taskTotal}
                                        </p>
                                    </div>
                                </div>
                                <i
                                    className={`ri-arrow-right-s-line text-2xl ${
                                        openMobileContainer === index && "rotate-90"
                                    }`}
                                ></i>
                            </div>

                            {openMobileContainer === index && (
                                <div
                                    onScroll={(e) => {
                                        setScrollTitle(list?.taskTitle);
                                        onScroll(
                                            e.target as HTMLElement,
                                            taskPagination?.current as number,
                                            taskPagination?.number_of_pages as number,
                                            () => {
                                                scrollTitle.toLowerCase() === "on-track"
                                                    ? setTaskActiveLimit(() => taskLimit + limit)
                                                    : scrollTitle.toLowerCase() === "pending"
                                                    ? setTaskPendingLimit(() => taskLimit + limit)
                                                    : scrollTitle.toLowerCase() === "delayed"
                                                    ? setTaskOverdueLimit(() => taskLimit + limit)
                                                    : scrollTitle.toLowerCase() === "completed"
                                                    ? setTaskCompletedLimit(() => taskLimit + limit)
                                                    : setTaskCompletedLimit(
                                                          () => taskLimit + limit
                                                      );

                                                setLoadMoreTask(true);
                                            }
                                        );
                                    }}
                                    className="flex flex-col gap-2.5 max-h-[350px] overflow-y-auto border-t-[1px] border-slate-200 pt-2.5"
                                >
                                    {list?.cardDetails?.map((card) => {
                                        return (
                                            <div
                                                key={card?._id}
                                                className="flex items-center gap-4"
                                                onClick={() => {
                                                    handleCardClick(card?._id);
                                                    setShowTaskDetails(true);
                                                }}
                                            >
                                                <VendorAvatar
                                                    size={32}
                                                    name={
                                                        card?.assignee?.fullName
                                                            ? card?.assignee?.fullName
                                                            : card?.assignee?.email
                                                    }
                                                    imageSrc={card?.assignee?.avatar}
                                                />
                                                <div>
                                                    <p className="text-xs text-slate-900 font-rocGroteskMedium leading-[24px]">
                                                        {truncate(
                                                            camelCaseToRegularCase(card?.name),
                                                            {
                                                                length: 30,
                                                            }
                                                        )}
                                                    </p>
                                                    <p className="text-sm text-slate-400 font-rocGroteskMedium">
                                                        {card?.action?.taId}
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {loading && scrollTitle === list?.taskTitle && loadMoreTask && (
                                        <Loader size={5} color="p-40" />
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>

            {showTaskDetails && (
                <TaskDetailsModal
                    id={taskId}
                    openModal={showTaskDetails}
                    closeModal={() => {
                        setShowTaskDetails(false);
                        setTaskId("");
                    }}
                />
            )}
        </div>
    );
};
export default Board;
