export const navLinks = [
    {
        title: "Dashboard",
        link: "/dashboard/home",
        childrenLinks: [""],
        iconClass: "",
    },
    {
        title: "Inventory",
        link: "/dashboard/inventory",
        childrenLinks: [""],
        iconClass: "ri-shopping-basket-line",
    },
    {
        title: "Suppliers",
        link: "/dashboard/suppliers",
        childrenLinks: [""],
        iconClass: "ri-building-line",
    },
    {
        title: "Purchase",
        link: "/dashboard/purchase",
        childrenLinks: [""],
        iconClass: "ri-file-list-3-line",
    },
    // {
    //     title: "Sales order",
    //     link: "/dashboard/order",
    //     childrenLinks: [""],
    //     iconClass: "ri-file-line",
    // },
    {
        title: "Integrations",
        link: "/dashboard/integrations",
        childrenLinks: [""],
        iconClass: "ri-link",
    },
    {
        title: "Teams",
        link: "/dashboard/teams",
        childrenLinks: [""],
        iconClass: "",
    },
    // {
    //     title: "Playground",
    //     link: "/dashboard/playground",
    //     childrenLinks: [""],
    //     iconClass: "ri-task-line",
    // },

    {
        title: "Track & Trace",
        link: "/dashboard/track-trace",
        childrenLinks: [""],
        iconClass: "",
    },
];
