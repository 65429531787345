import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { debounce } from "lodash";
import useInfiniteScroll from "hooks/useInfinteScroll";

const useGetExpirationTrackingList = (warehouseId?: string) => {
    const [search, setSearch] = useState<string>("");
    const [debounceSearch, setDebouncedSearch] = useState<string>("");
    const dispatch = useAppDispatch();
    const [expirationTrackingData, setExpirationTrackingData] = useState<{ [key: string]: any }[]>(
        []
    );
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    const { fetchingExpirationList, fetchedExpirationListSuccess, fetchedExpirationListFailure } =
        useAppSelector((state) => state.inventory);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setDebouncedSearch(e.target.value);
    }, 1000);

    useEffect(() => {
        if (Boolean(fetchedExpirationListSuccess)) {
            setExpirationTrackingData((prev) =>
                updatedPage === 1
                    ? [...fetchedExpirationListSuccess?.products]
                    : [...prev, ...fetchedExpirationListSuccess?.products]
            );
            setPagination({
                currentPage: fetchedExpirationListSuccess.pagination?.current,
                noOfPages: fetchedExpirationListSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchedExpirationListSuccess, updatedPage]);

    useEffect(() => {
        dispatch(
            inventoryActions.fetchExpirationTrackingList(
                debounceSearch,
                warehouseId,
                10,
                updatedPage
            )
        );
    }, [dispatch, debounceSearch, warehouseId, updatedPage]);

    return {
        isFetching: fetchingExpirationList,
        error: fetchedExpirationListFailure,
        data: expirationTrackingData,
        search,
        handleSearch,
        handleDebouncedChange,
        isLoadingMore,
        pagination,
        setUpdatedPage,
    };
};

export default useGetExpirationTrackingList;
