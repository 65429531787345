import React, { useEffect, useState } from "react";
import Button from "components/common/Button";
import LandingNav from "components/views/Landing/LandingNav";
import BookADemoModal from "components/views/Landing/BookADemoModal";
import LandingFooter from "components/views/Landing/LandingFooter";
import CookiePrompt from "components/views/Landing/CookiePrompt";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState(0);

    const benefits = [
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715122542/chart-bar-fill_dzatxw.svg",
            title: "Real-time cost-savings",
            content:
                "Reduce operational costs and make data-driven decisions that improve profitability.",
        },
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715122542/warehouse-fill_xydtuu.svg",
            title: "Dynamic inventory replenishment",
            content:
                "Forecast inventory needs and automate reordering to avoid stock-outs or overstocking.",
        },
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715122542/clock-fill_m3kqrg.svg",
            title: "Predictive supplier lead-time risk impact",
            content:
                "Avoid supply chain disruptions, maintain smooth operations, and ensure high customer satisfaction.",
        },
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715122542/barcode-fill_rfiyfb.svg",
            title: "Automated product track and trace",
            content: "Full traceability from purchase to delivery and streamlined product recalls.",
        },
    ];

    const integrationsOne = [
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715394621/Frame_1000003476_alrvcy.svg",
            title: "Spreadsheet",
        },
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715360125/Frame_1000003475_viyltu.svg",
            title: "Amazon",
        },
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715360125/Frame_1000003479_sfbrih.svg",
            title: "Instagram",
        },
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715360127/Frame_1000003503_wq2opv.svg",
            title: "Quickbooks",
        },
    ];

    const integrationsTwo = [
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715360125/Frame_1000003478_gcmuu3.svg",
            title: "Shopify",
        },
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715360125/Frame_1000003477_mwh0sx.svg",
            title: "X (Formerly Twitter)",
        },
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715360125/Frame_1000003474_wb5clu.svg",
            title: "Gmail",
        },
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715360126/Frame_1000003502_cmsz22.svg",
            title: "Tiktok",
        },
    ];

    const features = [
        {
            tab: "Centralize inventory in one place",
            videoSrc:
                "https://res.cloudinary.com/dvxi7qcmd/video/upload/q_auto/v1715768193/Centralize_inventory_in_one_place_xcpk2a.mp4",
            poster: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715392715/Centralize_inventory_in_one_place_d8aabn.svg",
            miniPoster:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715604735/Centralize_inventory_in_one_place_1_aj0b0r.png",
            zoomBy: 1.5,
            bottomValue: -160,
            color: "bg-[#013D28]",
        },
        {
            tab: "Demand forecasting AI agent",
            videoSrc:
                "https://res.cloudinary.com/dvxi7qcmd/video/upload/q_auto/v1715768201/286_1_shpnzr.mp4",
            poster: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715392800/Frame_1000003502_1_fhqvgd.svg",
            miniPoster:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715604735/Demand_forecasting_AI_agent_r4jdt3.png",
            zoomBy: 3,
            bottomValue: -330,
            color: "bg-[#FD700B]",
        },
        {
            tab: "Automated inventory replenishment",
            videoSrc:
                "https://res.cloudinary.com/dvxi7qcmd/video/upload/q_auto/v1715768196/Automated_inventory_replenishment_vhvipu.mp4",
            poster: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715392801/Frame_1000003503_1_ndnt9f.svg",
            miniPoster:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715604736/Inventory_forecasting_and_replenishment_agent_ykjlsi.png",
            color: "bg-[#E4281D]",
        },
        {
            tab: "Supplier lead-time risk",
            // videoSrc:
            //     "https://res.cloudinary.com/dvxi7qcmd/video/upload/v1715286715/Supplier_-_01_wr98kf.mp4",
            poster: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715392801/Frame_1000003501_lupnsj.svg",
            miniPoster:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715604737/Supplier_lead-time_risk_jnj7sz.png",
            zoomBy: 2.2,
            bottomValue: -270,
            color: "bg-[#FFB902]",
        },
        {
            tab: "Product/ingredient track and trace agent",
            videoSrc:
                "https://res.cloudinary.com/dvxi7qcmd/video/upload/q_auto/v1715768207/ingredient_track_and_trace_agent_n9nooa.mp4",
            poster: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715392802/Frame_1000003504_ohksqw.svg",
            miniPoster:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715604735/ingredient_track_and_trace_agent_o8qs0o.png",
            zoomBy: 2.2,
            bottomValue: -270,
            color: "bg-[#A8C10A]",
        },
    ];

    const whoWeHelp = [
        {
            image: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715379432/image_5_1_wibomc.png",
            title: "Finance",
        },
        {
            image: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715379556/image_3_1_kjtndy.png",
            title: "Operations",
        },
        {
            image: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715379556/image_4_1_g9odqm.png",
            title: "Supply Chain",
        },
        {
            image: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715379559/Diverse_Team_Partnership_Celebration_1_ejmlex.png",
            title: "Business owners",
        },
    ];

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const scrollToImage = (index: number) => {
        const container = document.querySelector(".featureContainer") as HTMLElement;
        const image = container.children[index] as HTMLElement;
        const containerWidth = container?.offsetWidth;
        const imageWidth = image.offsetWidth;
        const scrollLeft = image.offsetLeft - (containerWidth - imageWidth) / 2;

        const tabContainer = document.querySelector(".tabsContainer") as HTMLElement;
        const tab = tabContainer.children[index] as HTMLElement;
        const tabContainerWidth = tabContainer?.offsetWidth;
        const tabWidth = tab.offsetWidth;
        const tabScrollLeft = tab.offsetLeft - (tabContainerWidth - tabWidth) / 2;

        setSelectedFeature(index);

        tabContainer.scrollTo({
            left: tabScrollLeft,
            behavior: "smooth",
        });

        setSelectedFeature(index);

        container.scrollTo({
            left: scrollLeft - 90,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        // Check if the URL contains a hash
        const hash = window.location.hash;
        const pathname = window.location.pathname;

        if (hash) {
            // Remove the '#' symbol from the hash
            const elementId = hash.substring(1);
            // Find the element by its ID
            const targetElement = document.getElementById(elementId);
            if (targetElement) {
                // Scroll to the target element
                targetElement.scrollIntoView({ behavior: "smooth" });
            }
        }

        if (pathname === "/wait-list") {
            setOpenModal(true);
            navigate("/", { replace: true });
        }
    }, []);

    return (
        <div className={`relative bg-[#FFFBEB]`}>
            <LandingNav />

            <section className={` h-[861px] max-lg:h-[451px] w-screen flex justify-center`}>
                <div className="w-[86%]">
                    <div>
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715106662/landing_right_vector_mcj7ud.svg"
                            alt="right vector"
                            className="right-0 top-0 absolute max-lg:hidden"
                        />
                    </div>
                    <div className="mt-[158px] text-center max-lg:mt-[32px] max-lg:w-full px-6 relative z-10 flex flex-col items-center">
                        <h1 className="text-[72px] max-lg:text-[40px] text-green-2 max-lg:leading-[48px] leading-[86.4px] font-flame mb-3">
                            Your AI Supply Chain Manager
                        </h1>
                        <p className="text-xl w-[64%] text-green-2 max-lg:text-base font-interDisplay max-xl:w-full px-10 max-lg:px-2 mb-8">
                            Unify inventory data from different sources into one intelligent
                            platform and automate tasks for improved forecasting and purchase order
                            planning.
                        </p>

                        <div className="flex items-center mb-6">
                            <Button
                                btnText={"Book a Demo"}
                                btnClassname={
                                    "!w-fit !h-[48px] !text-base !rounded-md !px-6 !font-flame !shadow-[0px_-3px_0px_0px_#0000001A_inset]"
                                }
                                onClick={handleOpenModal}
                            />
                        </div>
                        <div className="flex items-center space-x-1.5 max-lg:hidden">
                            <span className="text-base font-flame">Backed by:</span>
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1710501233/techstars_logo_qfwj0t.svg"
                                alt="techstars"
                                className="w-[110.43px] h-[19.71px]"
                            />
                        </div>
                    </div>
                    <div className="mt-[90px]  max-lg:mt-[44px]">
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715106662/landing_left_vector_mnvgft.svg"
                            alt="left vector"
                            className="left-0 top-[372px] absolute max-lg:hidden"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715278569/mobile_landing_left_vector_bkhzqi.svg"
                            alt="left vector"
                            className="left-0 top-[350px] absolute hidden max-sm:block"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715278570/mobile_landing_right_vector_wwlrep.svg"
                            alt="right vector"
                            className="right-0 top-[495px] absolute hidden max-sm:block"
                        />
                        <div className="flex justify-center mt-[-40px] 2xs:mt-[-20px]  relative z-10 ">
                            <div className="bg-white cursor-pointer w-full shrink-0 md:block hidden rounded-[24px] h-[400px] lg:h-[580px] xl:h-[750px] overflow-hidden relative">
                                <video
                                    poster={features[1].poster}
                                    src={
                                        "https://res.cloudinary.com/dvxi7qcmd/video/upload/q_auto/v1715768201/286_1_shpnzr.mp4"
                                    }
                                    playsInline
                                    controls={false}
                                    autoPlay
                                    controlsList="nodownload"
                                    muted
                                    loop
                                    width={"100%"}
                                    className={`z-10 cursor-pointer  bg-white !border-none  object-cover`}
                                    style={{
                                        // zoom: feature.zoomBy ? feature.zoomBy : 1,
                                        // bottom: feature?.bottomValue,
                                        left: 0,
                                        right: 0,
                                    }}
                                />
                            </div>

                            <div className="overflow-hidden  block md:hidden ">
                                <video
                                    src={
                                        "https://res.cloudinary.com/dvxi7qcmd/video/upload/q_auto/v1715768201/286_1_shpnzr.mp4"
                                    }
                                    poster={features[1].poster}
                                    playsInline
                                    controls={false}
                                    autoPlay
                                    controlsList="nodownload"
                                    muted
                                    loop
                                    className={`z-10 cursor-pointer  object-cover !border-none overflow-hidden block md:hidden   `}
                                    style={{
                                        // zoom: 3,
                                        // position: "absolute",
                                        bottom: 5,
                                        left: 0,
                                        right: 0,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="flex justify-center mt-[50%] sm:mt-[35%] px-6 relative text-center">
                <div className="w-[86%] flex flex-col items-center text-center max-lg:w-full max-lg:px-5 mb-8 max-lg:mb-[36px] relative">
                    <h4 className="text-[32px] max-lg:leading-[38.4px] max-sm:px-3 font-flame mb-8 text-green-2">
                        We're trusted by emerging brands
                    </h4>
                    <div className="flex items-center justify-center space-x-[56px] max-md:space-x-0 max-md:space-y-12 max-md:flex-col  flex-wrap">
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715278009/Eat_Omega_3_Logo_jqjspo.svg"
                            alt="eat omega 3 logo"
                            className="w-[144px] h-[60px] lg:mb-8"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1722524194/FATCO_Logo_umkpjn.svg"
                            alt="fatco logo"
                            className="w-[131px] h-[37px] lg:mb-8"
                        />

                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715278009/Green_Regimen_Logo_incyoe.svg"
                            alt="green regimen logo"
                            className="w-[74px] h-[76.64px]"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1722524137/HoldOn_Logos_1_njqily.svg"
                            alt="holdon logo"
                            className="w-[153px] h-[37px] lg:mb-8"
                        />
                    </div>
                    <div className="flex items-center justify-center space-x-[56px] max-md:space-x-0 max-md:space-y-12 max-md:flex-col flex-wrap">
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715278012/Vitavate_Logo_caujhs.svg"
                            alt="vitavate logo"
                            className="w-[154.84px] h-[40px] lg:mb-8"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1722524138/Yellow-V2_2_bounces_1_hjnzof.svg"
                            alt="boobcube logo"
                            className="w-[183.62px] h-[67.22] lg:mb-8"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1717146303/Krack_d_Snacks_1_jznqjv.svg"
                            alt="krack'd snacks logo"
                            className="w-[155.1px] h-[74px] lg:mb-8"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1717146303/Sweet_Kiwi_Logo_1_g1sxyp.svg"
                            alt="sweetkwiki logo"
                            className="w-[171.42px] h-[29.52px] lg:mb-8"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1722524137/Wear_Oya_Logo_pwumnv.svg"
                            alt="oya logo"
                            className="w-[90px] h-[54.38px] lg:mb-8"
                        />
                    </div>
                </div>
            </section>

            <section
                id="benefits"
                className="mt-[156px] mb-[148px] max-lg:mt-[100px] relative flex justify-center"
            >
                <div className="w-[86%]">
                    <div className="mb-16">
                        <h4 className=" text-xl font-flame text-orange-1 mb-4">Benefits</h4>
                        <h5 className="text-[48px] max-lg:text-[36px] font-flame leading-[57.6px] max-lg:leading-[43.2px] text-green-2">
                            Synth helps you to improve cash <br /> flow, sell more and be centered.
                        </h5>
                    </div>

                    <div className="grid max-lg:grid-cols-2 grid-cols-4 gap-12 ">
                        {benefits.map((benefit) => (
                            <div key={benefit.title}>
                                <div className="mb-6">
                                    <img src={benefit.icon} alt="chart icon" className="w-8 h-8" />
                                </div>
                                <div className="text-green-2">
                                    <span className="block text-base md:text-[18px] md:leading-[21.6px] font-flame mb-2">
                                        {benefit.title}
                                    </span>
                                    <p className="md:text-base text-sm font-interDisplay">
                                        {benefit.content}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className=" mb-[145px] max-sm:mt-[100px] relative flex justify-center">
                <div className="md:w-[86%] md:pl-0 pl-10 w-[100%]">
                    <div className="mb-16">
                        <h4 className=" text-xl font-flame text-orange-1 mb-4">Who we help</h4>
                        <h5 className="text-[48px] font-flame leading-[57.6px] max-lg:text-[36px] max-lg:leading-[43.2px] text-green-2">
                            Designed for CPG operators
                        </h5>
                    </div>
                    <div className="md:hidden flex shrink-0 overflow-auto space-x-8">
                        {whoWeHelp.map((who, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className="w-[359px] relative shrink-0 rounded-[16px] h-[359px]"
                                >
                                    <img
                                        src={who.image}
                                        alt={who.title}
                                        className="w-full h-full rounded-[24px] object-cover"
                                        crossOrigin="anonymous"
                                    />
                                    <div className="pl-6 pb-8 flex items-end w-full h-[43.3%] absolute rounded-b-[24px] bottom-0 bg-gradient-to-t from-[#000000] from-[0.25%] via-[rgba(0, 0, 0, 0.254518)] via-[68.23%] to-[rgba(0, 0, 0, 0)] to-[92.15%]">
                                        <span className="text-[22px] font-interDisplay text-white">
                                            {who.title}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="md:grid  grid-cols-2 gap-6 hidden">
                        {whoWeHelp.map((who, idx) => {
                            return (
                                <div key={idx} className="relative rounded-[16px] h-[359px]">
                                    <img
                                        src={who.image}
                                        alt={who.title}
                                        className="w-full h-full rounded-[24px] object-cover"
                                        crossOrigin="anonymous"
                                    />
                                    <div className="pl-6 pb-8 flex items-end w-full h-[43.3%] absolute rounded-b-[24px] bottom-0 bg-gradient-to-t from-[#000000] from-[0.25%] via-[rgba(0, 0, 0, 0.254518)] via-[68.23%] to-[rgba(0, 0, 0, 0)] to-[92.15%]">
                                        <span className="text-[22px] font-interDisplay text-white">
                                            {who.title}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>

            <section className="flex justify-center mb-[128px] max-lg:mb-[100px]">
                <div className="w-[86%] max-lg:w-full max-lg:rounded-none bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715180536/be_zen_background_z7g7ue.png')] max-lg:bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715267196/Background_1_em4g2w.png')] bg-cover bg-no-repeat rounded-[32px] flex justify-center items-center py-[56px]">
                    <div className="md:w-[60%] w-[91%] text-center">
                        <h4 className=" text-xl font-flame text-orange-1 mb-4">Be Zen</h4>
                        <p className="text-[40px] leading-[48px] font-flame text-white">
                            Synth automates complex inventory tasks to give you peace of mind, so
                            you can focus on business strategy and growth.
                        </p>
                    </div>
                </div>
            </section>

            <section className="flex justify-center mb-[150px] md:mb-[170px]">
                <div className="text-center w-[75.8%]">
                    <span className="text-[48px] max-lg:text-[36px] max-lg:leading-[43.2px] block font-flame mb-2 text-green-2">
                        Save time, make money.
                    </span>
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715183262/250_msih7z.svg"
                        alt="250 dollars"
                        className="mx-auto"
                    />
                    <span className="text-[28px] max-lg:text-[24px] mt-2 max-lg:leading-[28.8px] block font-flame mb-8 text-green-2">
                        Average revenue saved per hour on Synth
                    </span>
                    <p className="text-[48px] max-lg:text-[24px] block font-flame mb-8 leading-[57.6px] max-lg:leading-[28.8px] text-green-2">
                        For every hour saved by using Synth, emerging brands generate an additional
                        $250 in revenue.
                    </p>
                    <div className="flex justify-center ">
                        <Button
                            btnText={"Book a Demo"}
                            btnClassname={
                                "!w-fit !h-[48px] !text-base !rounded-md !px-6 !font-flame !shadow-[0px_-3px_0px_0px_#0000001A_inset]"
                            }
                            onClick={handleOpenModal}
                        />
                    </div>
                </div>
            </section>

            <section id="features" className="mb-[128px] flex justify-center md:justify-end">
                <div className="md:w-[91%] w-[86%]">
                    <div className="mb-4">
                        <h4 className=" text-xl font-flame text-orange-1 mb-4">Features</h4>
                        <h5 className="text-[48px] max-lg:text-[36px] max-lg:leading-[43.2px] font-flame leading-[57.6px] text-green-2">
                            Drive company-wide operational excellence
                        </h5>
                    </div>

                    <div className="md:block hidden">
                        <div className="flex tabsContainer items-start space-x-4 mb-10 overflow-auto pr-6">
                            {features.map((feature, idx) => (
                                <span
                                    key={idx}
                                    onClick={() => {
                                        scrollToImage(idx);
                                    }}
                                    className={`text-[18px] font-flame text-green-2 pb-3 cursor-pointer shrink-0 ${
                                        idx === selectedFeature && "border-b-[3px] border-y-10"
                                    }`}
                                >
                                    {feature.tab}
                                </span>
                            ))}
                        </div>
                        <div className="flex featureContainer overflow-auto space-x-8 pb-8 pl-4 pr-10">
                            {features.map((feature, idx) => {
                                return (
                                    <div
                                        key={feature.tab}
                                        onMouseEnter={() => scrollToImage(idx)}
                                        className="bg-white cursor-pointer flex shadow-cardBoxShadow-4 items-end shrink-0 rounded-[24px] h-[550px] overflow-hidden relative"
                                    >
                                        <video
                                            poster={feature.poster}
                                            src={feature.videoSrc}
                                            playsInline
                                            controls={false}
                                            autoPlay
                                            controlsList="nodownload"
                                            muted
                                            loop
                                            className={`z-10 cursor-pointer  object-cover  w-full h-full `}
                                            style={{
                                                // zoom: feature.zoomBy ? feature.zoomBy : 1,
                                                // bottom: feature?.bottomValue,
                                                left: 0,
                                                right: 0,
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className=" featureContainer md:hidden block h-full space-y-4">
                        {features.map((feature, idx) => {
                            return (
                                <div
                                    key={feature.tab}
                                    onMouseEnter={() => scrollToImage(idx)}
                                    className=" cursor-pointer w-full  "
                                >
                                    <div
                                        className={`${feature.color} w-full px-2 pt-2 pb-2 h-fit rounded-tr-[12px] rounded-tl-[12px]`}
                                    >
                                        <video
                                            poster={feature.miniPoster}
                                            src={feature.videoSrc}
                                            playsInline
                                            controls={false}
                                            autoPlay
                                            controlsList="nodownload"
                                            muted
                                            loop
                                            className={`z-10 cursor-pointer w-[100%]  h-full !rounded-none `}
                                            style={{
                                                // zoom: feature.zoomBy ? feature.zoomBy : 1,
                                                bottom: feature?.bottomValue,
                                            }}
                                        />
                                    </div>
                                    <div className="bg-white py-5 px-3 rounded-br-[12px] shadow-cardBoxShadow-4  rounded-bl-[12px]">
                                        <p className="text-[#013D28] leading-[24px] text-[20px] font-flame ">
                                            {feature.tab}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>

            <section className="mb-[128px] flex justify-center">
                <div className="w-[86%] flex flex-col items-center text-center max-lg:w-full max-lg:px-5 mb-8 max-lg:mb-[36px] relative">
                    <h4 className="text-[32px] max-lg:text-xl max-lg:leading-[24px] font-flame mb-8 text-green-2">
                        Built and powered with the world's best modern technologies
                    </h4>
                    <div className="flex items-center space-x-[56px] max-lg:space-x-0 max-lg:gap-x-6 max-lg:gap-y-12 max-lg:grid max-lg:grid-cols-2">
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715182973/Google_Gemini_logo_1_ln4qtn.svg"
                            alt="gemini"
                            className="w-[93px] h-[38.25px]"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715108145/Netlify_logo_1_tcd5xq.svg"
                            alt="netlify"
                            className="w-[110.25px] h-[30px]"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715182973/MongoDB_SlateBlue_touo0c.svg"
                            alt="mongo db"
                            className="w-[119px] h-[30px]"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715108143/cld-main-logo_pixqoi.svg"
                            alt="cloudinary"
                            className="w-[155px] h-[30px]"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715182973/Langchain_pvqf4s.svg"
                            alt="lang chain"
                            className="w-[154.54px] h-[26.4px]"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715182973/Google_Cloud_cdc5d0.svg"
                            alt="google cloud"
                            className="w-[138.35px] h-[24px] mt-1.5"
                        />
                    </div>
                </div>
            </section>

            <section className="flex justify-center mb-[164px] max-lg:mb-[72px]">
                <div className="relative rounded-[24px] w-[86%]">
                    <div className=" h-[652px] w-full rounded-[24px] bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715262441/Happy_Redhead_Waitress_1_uscq3a.png')] max-lg:bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715268024/Happy_Redhead_Waitress_1_1_gqdgq0.png')] bg-cover bg-no-repeat"></div>
                    <div className="pl-16 pb-16 max-lg:pl-6 max-lg:pb-6 flex items-end w-full h-[43.3%] absolute rounded-b-[24px] bottom-0 bg-gradient-to-t from-[#000000] from-[0.25%] via-[rgba(0, 0, 0, 0.254518)] via-[68.23%] to-[rgba(0, 0, 0, 0)] to-[92.15%]">
                        <p className="text-[64px] leading-[76.8px] max-lg:text-[36px] max-lg:leading-[43.2px] font-flame text-white">
                            Grow your CPG <br></br> brand with{" "}
                            <span className="text-y-10">Synth</span>
                        </p>
                    </div>
                </div>
            </section>

            <section
                id="integrations"
                className="flex justify-center pb-[100px] max-lg:pb-0 max-sm:mb-[105px]"
            >
                <div className="w-[63.8%] max-sm:w-full max-sm:px-6">
                    <div className="mb-12 text-center ">
                        <h4 className=" text-xl font-flame text-orange-1 mb-4">
                            System Integration
                        </h4>
                        <h5 className="text-[48px] max-lg:text-[32px] max-lg:leading-[38.4px] font-flame leading-[57.6px] text-green-2">
                            Seamlessly integrate your inventory data via our growing family of
                            partnering platforms
                        </h5>
                    </div>

                    <div className="mb-12 md:flex hidden  justify-center  items-center">
                        <div className="flex items-center ">
                            {integrationsOne.map((integration, idx) => (
                                <div className="mr-[-12px] group cursor-pointer relative" key={idx}>
                                    <img
                                        src={integration.icon}
                                        alt={integration.title.toLowerCase()}
                                        className="w-[90px] h-[90px]"
                                    />
                                    <span className=" pointer-events-none absolute font-interDisplay text-[16px] labsolute top-full left-1/2 transform -translate-x-1/2 transition-all duration-300 opacity-0  group-focus:opacity-100 bg-[#013D28] px-3 py-2 text-white before:absolute before:left-1/2 before:bottom-full before:translate-x-1/2 before:border-4 before:border-transparent before:border-b-gm-50 rounded-[8px] before:content-[''] group-hover:opacity-100">
                                        {integration.title}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className="group cursor-pointer relative">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715360124/Frame_1000003473_hrjnfw.svg"
                                alt="synth"
                                className="w-[120px] h-[120px]"
                            />
                            <span className="whitespace-nowrap font-interDisplay pointer-events-none absolute text-[16px] bottom-full left-1/2 transform -translate-x-1/2 transition-all duration-300 opacity-0 group-focus:opacity-100 bg-[#013D28] px-3 py-2 text-white before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-gm-50 rounded-[8px] before:content-[''] group-hover:opacity-100">
                                Synth
                            </span>
                        </div>
                        <div className="flex items-center ">
                            {integrationsTwo.map((integration, idx) => (
                                <div className="-ml-[12px] group cursor-pointer relative" key={idx}>
                                    <img
                                        src={integration.icon}
                                        alt={integration.title.toLowerCase()}
                                        className="w-[90px] h-[90px]"
                                    />
                                    <span className="whitespace-nowrap font-interDisplay pointer-events-none absolute text-[16px] top-full left-1/2 transform -translate-x-1/2 transition-all duration-300 opacity-0 group-focus:opacity-100 bg-[#013D28] px-3 py-2 text-white before:absolute before:left-1/2 before:bottom-full before:translate-x-1/2 before:border-4 before:border-transparent before:border-b-gm-50 rounded-[8px] before:content-[''] group-hover:opacity-100">
                                        {integration.title}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="mb-12 md:hidden flex flex-col  justify-center  items-center">
                        <div className="group cursor-pointer flex justify-center relative">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715360124/Frame_1000003473_hrjnfw.svg"
                                alt="synth"
                                className="w-[96px] h-[96px]"
                            />
                            <span className="whitespace-nowrap font-interDisplay pointer-events-none absolute text-[16px] bottom-full left-1/2 transform -translate-x-1/2 transition-all duration-300 opacity-0 group-focus:opacity-100 bg-[#013D28] px-3 py-2 text-white before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-gm-50 rounded-[8px] before:content-[''] group-hover:opacity-100">
                                Synth
                            </span>
                        </div>
                        <div className="flex justify-center mb-1 items-center ">
                            {integrationsOne.map((integration, idx) => (
                                <div className="-mr-[12px] group cursor-pointer relative" key={idx}>
                                    <img
                                        src={integration.icon}
                                        alt={integration.title.toLowerCase()}
                                        className="w-[76px] h-[76px]"
                                    />
                                    <span className="whitespace-nowrap z-[1000] font-interDisplay pointer-events-none absolute text-[16px] top-full left-1/2 transform -translate-x-1/2 transition-all duration-300 opacity-0 group-focus:opacity-100 bg-[#013D28] px-3 py-2 text-white before:absolute before:left-1/2 before:bottom-full before:translate-x-1/2 before:border-4 before:border-transparent before:border-b-gm-50 rounded-[8px] before:content-[''] group-hover:opacity-100">
                                        {integration.title}
                                    </span>
                                </div>
                            ))}
                        </div>

                        <div className="flex justify-center  items-center ">
                            {integrationsTwo.map((integration, idx) => (
                                <div className="-mr-[12px] group cursor-pointer relative" key={idx}>
                                    <img
                                        src={integration.icon}
                                        alt={integration.title.toLowerCase()}
                                        className="w-[76px] h-[76px]"
                                    />
                                    <span className="whitespace-nowrap font-interDisplay pointer-events-none absolute text-[16px] top-full left-1/2 transform -translate-x-1/2 transition-all duration-300 opacity-0 group-focus:opacity-100 bg-[#013D28] px-3 py-2 text-white before:absolute before:left-1/2 before:bottom-full before:translate-x-1/2 before:border-4 before:border-transparent before:border-b-gm-50 rounded-[8px] before:content-[''] group-hover:opacity-100">
                                        {integration.title}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="flex justify-center ">
                        <Button
                            btnText={"Book a Demo"}
                            btnClassname={
                                "!w-fit !h-[48px] !text-base !rounded-md !px-6 !font-flame !shadow-[0px_-3px_0px_0px_#0000001A_inset]"
                            }
                            onClick={handleOpenModal}
                        />
                    </div>
                </div>
            </section>

            <LandingFooter />
            <BookADemoModal openModal={openModal} setOpenModal={setOpenModal} />
            <CookiePrompt />
        </div>
    );
};

export default Home;
