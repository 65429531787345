/* eslint-disable */
import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import CustomToast from "components/common/CustomToast";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import TabsContainer from "components/common/TabsContainer";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import {
    displayFileIcon,
    formatMoney,
    getCurrencyFromCurrencyCode,
    getFromStorage,
    onScroll,
} from "helpers";
import { downloadUrl } from "helpers/fileDownload";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import useGetUserProfile from "hooks/useGetUserProfile";
import { debounce, flattenDeep, set, truncate } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks";
import { useNavigate } from "react-router-dom";
import { orderActions } from "redux/Ldb/actions";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CustomMobileTable from "components/common/CustomMobileTable";

const PurchaseOrder = () => {
    const limit = 20;
    const [purchaseOrderList, setPurchaseOrderList] = useState([]);
    const [approvedPurchaseOrderList, setApprovedPurchaseOrderList] = useState([]);
    const [rejectedPurchaseOrderList, setRejectedPurchaseOrderList] = useState([]);
    const [selectedPO, setSelectedPO] = useState<{ [key: string]: any }>({});
    const [isScrolled, setIsScrolled] = useState(false);
    const [showMoreId, setShowMoreId] = useState("");
    const [loadMorePurchaseOrder, setLoadMorePurchaseOrder] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);
    const [userProfile, setUserProfile] = useState(() => getFromStorage("ally-user"));
    const [purchaseOrderPagination, setPurchaseOrderPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [approvedPurchaseOrderPagination, setApprovedPurchaseOrderPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [rejectedPurchaseOrderPagination, setRejectedPurchaseOrderPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [poLimit, setPoLimit] = useState(limit);
    const [approvedPoLimit, setApprovedPoLimit] = useState(limit);
    const [rejectedPoLimit, setRejectedPoLimit] = useState(limit);
    const [singlePODetails, setSinglePODetails] = useState<{ [key: string]: any }>({});

    const [activeTab, setActiveTab] = useState(0);
    const [recreatePRModal, setRecreatePRModal] = useState(false);
    const [filter, setFilter] = useState({
        search: "",
        taId: "",
    });
    const [debouncedFilter, setDebouncedFilter] = useState({
        search: "",
        taId: "",
    });
    const [profile] = useGetUserProfile();

    const [approvalStatusArr, setApprovalStatusArr] = useState([]);
    const [openApproveModal, setOpenApproveModal] = useState(false);
    const [openApprovalSuccessModal, setOpenApprovalSuccessModal] = useState(false);
    const [openRejectModal, setOpentRejectModal] = useState(false);
    const [openRejectionReasonModal, setOpentRejectionReasonModal] = useState(false);
    const [rejectionReason, setRejectionReason] = useState("");
    const [allowAction, setAllowAction] = useState(true);
    const [loadDetails, setLoadDetails] = useState(false);

    const dashboardContent = document.getElementById("dashboardContent");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {
        fetchingPurchaseOrder,
        fetchedPurchaseOrderSuccess,
        loadingPOApprovalOrRejection,
        approveOrRejectPOSuccess,
        createPurchaseOrder,
        createPurchaseOrderSuccess,
        fetchedSinglePurchaseOrderSuccess,
    } = useAppSelector((state) => state.order);

    const hasApprovals =
        singlePODetails?.approvalActions?.length > 0 || selectedPO?.tasks?.length > 0;

    const approvalPersonnelsArr = singlePODetails?.approvalActions?.map((item) => {
        return item?.assignee;
    });

    const taskPersonnelsArr = singlePODetails?.tasks?.map((item) => {
        return item?.assignee;
    });

    const approvalPersonnel: { [key: string]: any }[] = flattenDeep(approvalPersonnelsArr);
    const taskPersonnel: { [key: string]: any }[] = flattenDeep(taskPersonnelsArr);
    const shouldUserApprove =
        approvalPersonnel?.map((item) => item?._id)?.includes(profile?._id) &&
        taskPersonnel?.map((item) => item?._id)?.includes(profile?._id) &&
        !singlePODetails?.approvedBy
            ?.map((approvedUser) => approvedUser?._id)
            ?.includes(profile?._id) &&
        !singlePODetails?.rejectedBy
            ?.map((rejectedUser) => rejectedUser?._id)
            ?.includes(profile?._id) &&
        allowAction;

    const onPurchaseOrderTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                activeTab === 0
                    ? (purchaseOrderPagination?.current as number)
                    : activeTab === 1
                    ? (approvedPurchaseOrderPagination?.current as number)
                    : (rejectedPurchaseOrderPagination?.current as number),
                activeTab === 0
                    ? (purchaseOrderPagination?.number_of_pages as number)
                    : activeTab === 1
                    ? (approvedPurchaseOrderPagination?.number_of_pages as number)
                    : (rejectedPurchaseOrderPagination?.number_of_pages as number),
                () => {
                    if (activeTab === 0) {
                        setPoLimit(() => poLimit + limit);
                    }
                    if (activeTab === 1) {
                        setApprovedPoLimit(() => approvedPoLimit + limit);
                    }
                    if (activeTab === 2) {
                        setRejectedPoLimit(() => rejectedPoLimit + limit);
                    }

                    setLoadMorePurchaseOrder(true);
                }
            ),
        [dashboardContent, purchaseOrderPagination, activeTab]
    );

    const handleSearch = (e) => {
        const { name, value } = e.target;

        setFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleDebouncedChange = debounce((e) => {
        const { name, value } = e.target;

        setDebouncedFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, 1500);

    const handleRecreate = () => {
        const body: { [key: string]: any } = {
            issuedDate: moment(selectedPO.issuedDate).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
            dueDate: moment(selectedPO.dueDate).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
            itemsOrServices: selectedPO?.itemsOrServices?.map((item) => ({
                name: item?.name,
                qty: item?.qty,
                price: item?.price,
                subtotal: item?.subtotal,
            })),
            documents: selectedPO?.documents?.map((doc) => ({
                filename: doc?.filename,
                path: doc?.path,
            })),
            destination: selectedPO?.destination,
            quoteResponseId: selectedPO?.quoteResponseId,
        };

        if (Boolean(selectedPO?.additionalComment)) {
            body.additionalComment = selectedPO?.additionalComment;
        }
        if (selectedPO?.tax) {
            body.tax = selectedPO?.tax;
        }

        if (selectedPO?.shippingAndHandling) {
            body.shippingAndHandling = selectedPO?.shippingAndHandling;
        }

        dispatch(orderActions.createPurchaseOrder(body));
    };

    const tableHeader = [
        { title: "PR Number", widthClass: "w-[10.5%]" },
        { title: "Vendor (Seller)", widthClass: "w-[20.5%]" },
        { title: "Buyer", widthClass: "w-[17.6%]" },
        { title: "Issue Date", widthClass: "w-[11.3%]" },
        { title: "Due Date", widthClass: "w-[11.3%]" },
        { title: "Total Amount", widthClass: "w-[10%]" },
        { title: "Status", widthClass: "w-[12.2%]" },
        { title: "", widthClass: "w-[6.1%]" },
    ];

    const tableBody = purchaseOrderList?.map((po, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 flex font-rocGroteskRegular items-center h-full border-slate-100 text-sm text-slate-700 `}
                >
                    <p>PR {po?.taId}</p>
                </div>
            ),
            cellClickAction: () => navigate(`/dashboard/purchase-orders/${po?._id}`),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskRegular border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center gap-3">
                        <VendorAvatar
                            imageSrc={po?.vendor?.profile?.avatar}
                            size={30}
                            name={po?.vendor?.profile?.businessName}
                        />
                        <span>{truncate(po?.vendor?.profile?.businessName, { length: 15 })}</span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskRegular border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center gap-3">
                        <span>{po?.order?.customerDetails?.companyName}</span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskRegular border-slate-100 text-sm text-slate-800 `}
                >
                    <p>{moment(po?.issuedDate).format("MMMM DD, YYYY")}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskRegular border-slate-100 text-sm text-slate-800 `}
                >
                    <p>{moment(po?.dueDate).format("MMMM DD YYYY")}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p>
                        {getCurrencyFromCurrencyCode(po?.totalAmount?.currency)}
                        {po?.totalAmount?.amount}
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 space-x-1  font-rocGroteskRegular flex items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    <div
                        className={`h-2 w-2 ${
                            po?.currentStatus?.toLowerCase() === "pending" && "bg-[#F59E0B]"
                        } ${po?.currentStatus?.toLowerCase() === "accepted" && "bg-g-50"}  ${
                            po?.currentStatus?.toLowerCase() === "rejected" && "bg-[#FF3347]"
                        } rounded-full`}
                    ></div>
                    <p>{po?.currentStatus}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-100px]  ${
                            purchaseOrderList?.length >= 9 &&
                            idx >= purchaseOrderList?.length - 2 &&
                            "!top-[-150px]"
                        }`}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "Preview",
                                value: "preview",
                                action: () => {
                                    navigate(`/dashboard/purchase-orders/${po?._id}`);
                                },
                            },
                            {
                                label: "View Details",
                                value: "view details",
                                action: () => {
                                    setSelectedPO(po);
                                    setDetailsModal(true);
                                },
                            },
                        ]}
                    />
                </div>
            ),
        },
    ]);

    const mobileTableBody = purchaseOrderList?.map((po, idx) => {
        return {
            topString: po?.vendor?.profile?.businessName + po?._id,
            topContent: (
                <div className="flex items-center gap-3">
                    <VendorAvatar
                        size={32}
                        name={po?.vendor?.profile?.businessName}
                        imageSrc={po?.vendor?.profile?.avatar}
                    />
                    <p>{truncate(po?.vendor?.profile?.businessName, { length: 20 })}</p>
                </div>
            ),
            bottomContent: (
                <div className="mt-3 flex gap-3 text-slate-500 text-sm font-rocGroteskMedium">
                    <p>PO {po?.taId}</p>
                    <div className="flex items-center gap-2">
                        <div
                            className={`h-2 w-2 ${
                                po?.currentStatus?.toLowerCase() === "pending" && "bg-[#F59E0B]"
                            } ${po?.currentStatus?.toLowerCase() === "accepted" && "bg-g-50"} ${
                                po?.currentStatus?.toLowerCase() === "rejected" && "bg-[#FF3347]"
                            } rounded-full`}
                        ></div>
                        <span className="capitalize">{po?.currentStatus}</span>
                    </div>
                </div>
            ),
            rightIcon: (
                <div
                    className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-100px]  ${
                            purchaseOrderList?.length >= 9 &&
                            idx >= purchaseOrderList?.length - 2 &&
                            "!top-[-150px]"
                        }`}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "Preview",
                                value: "preview",
                                action: () => {
                                    navigate(`/dashboard/purchase-orders/${po?._id}`);
                                },
                            },
                            {
                                label: "View Details",
                                value: "view details",
                                action: () => {
                                    setSelectedPO(po);
                                    setDetailsModal(true);
                                },
                            },
                        ]}
                    />
                </div>
            ),
        };
    });

    const approvedTableHeader = [
        { title: "PO Number", widthClass: "w-[10.5%]" },
        { title: "Vendor (Seller)", widthClass: "w-[20.5%]" },
        { title: "Buyer", widthClass: "w-[17.6%]" },
        { title: "Issue Date", widthClass: "w-[11.3%]" },
        { title: "Due Date", widthClass: "w-[11.3%]" },
        { title: "Total Amount", widthClass: "w-[10%]" },
        { title: "Status", widthClass: "w-[12.2%]" },
        { title: "", widthClass: "w-[6.1%]" },
    ];

    const approvedTableBody = approvedPurchaseOrderList?.map((po, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 flex font-rocGroteskRegular items-center h-full border-slate-100 text-sm text-slate-700 `}
                >
                    <p>PO {po?.taId}</p>
                </div>
            ),
            cellClickAction: () => navigate(`/dashboard/purchase-orders/${po?._id}`),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskRegular border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center gap-3">
                        <VendorAvatar
                            imageSrc={po?.vendor?.profile?.avatar}
                            size={30}
                            name={po?.vendor?.profile?.businessName}
                        />
                        <span>
                            {po?.vendor?.firstName} {po?.vendor?.lastName}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskRegular border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center gap-3">
                        <span>{po?.order?.customerDetails?.companyName}</span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskRegular border-slate-100 text-sm text-slate-800 `}
                >
                    <p>{moment(po?.issuedDate).format("MMMM DD, YYYY")}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskRegular border-slate-100 text-sm text-slate-800 `}
                >
                    <p>{moment(po?.dueDate).format("MMMM DD YYYY")}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p>
                        {getCurrencyFromCurrencyCode(po?.totalAmount?.currency)}
                        {po?.totalAmount?.amount}
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 space-x-1  font-rocGroteskRegular flex items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    <div
                        className={`h-2 w-2 ${
                            po?.currentStatus?.toLowerCase() === "pending" && "bg-[#F59E0B]"
                        } ${po?.currentStatus?.toLowerCase() === "accepted" && "bg-g-50"}  ${
                            po?.currentStatus?.toLowerCase() === "rejected" && "bg-[#FF3347]"
                        } rounded-full`}
                    ></div>
                    <p>{po?.currentStatus}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-100px]  ${
                            purchaseOrderList?.length >= 9 &&
                            idx >= purchaseOrderList?.length - 2 &&
                            "!top-[-150px]"
                        }`}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "Preview",
                                value: "preview",
                                action: () => {
                                    navigate(`/dashboard/purchase-orders/${po?._id}`);
                                },
                            },
                            {
                                label: "View Details",
                                value: "view details",
                                action: () => {
                                    setSelectedPO(po);
                                    setDetailsModal(true);
                                },
                            },
                        ]}
                    />
                </div>
            ),
        },
    ]);

    const mobileApprovedTableBody = approvedPurchaseOrderList?.map((po, idx) => {
        return {
            topString: po?.vendor?.profile?.businessName + po?._id,
            topContent: (
                <div className="flex items-center gap-3">
                    <VendorAvatar
                        size={32}
                        name={po?.vendor?.profile?.businessName}
                        imageSrc={po?.vendor?.profile?.avatar}
                    />
                    <p>{truncate(po?.vendor?.profile?.businessName, { length: 20 })}</p>
                </div>
            ),
            bottomContent: (
                <div className="mt-3 flex gap-3 text-slate-500 text-sm font-rocGroteskMedium">
                    <p>PO {po?.taId}</p>
                    <div className="flex items-center gap-2">
                        <div
                            className={`h-2 w-2 ${
                                po?.currentStatus?.toLowerCase() === "pending" && "bg-[#F59E0B]"
                            } ${po?.currentStatus?.toLowerCase() === "accepted" && "bg-g-50"} ${
                                po?.currentStatus?.toLowerCase() === "rejected" && "bg-[#FF3347]"
                            } rounded-full`}
                        ></div>
                        <span className="capitalize">{po?.currentStatus}</span>
                    </div>
                </div>
            ),
            rightIcon: (
                <div
                    className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-100px]  ${
                            purchaseOrderList?.length >= 9 &&
                            idx >= purchaseOrderList?.length - 2 &&
                            "!top-[-150px]"
                        }`}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "Preview",
                                value: "preview",
                                action: () => {
                                    navigate(`/dashboard/purchase-orders/${po?._id}`);
                                },
                            },
                            {
                                label: "View Details",
                                value: "view details",
                                action: () => {
                                    setSelectedPO(po);
                                    setDetailsModal(true);
                                },
                            },
                        ]}
                    />
                </div>
            ),
        };
    });

    const rejectedTableHeader = [
        { title: "PO Number", widthClass: "w-[7.3%]" },
        { title: "Vendor (Seller)", widthClass: "w-[11.9%]" },
        { title: "Buyer", widthClass: "w-[14.4%]" },
        { title: "Issue Date", widthClass: "w-[9.7%]" },
        { title: "Due Date", widthClass: "w-[8.9%]" },
        { title: "Total Amount", widthClass: "w-[8.6%]" },
        { title: "Comments", widthClass: "w-[20.8%]" },
        { title: "Status", widthClass: "w-[12.2%]" },
        { title: "", widthClass: "w-[6.1%]" },
    ];

    const rejectedTableBody = rejectedPurchaseOrderList?.map((po, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 flex font-rocGroteskRegular items-center h-full border-slate-100 text-sm text-slate-700 `}
                >
                    <p>PR {po?.taId}</p>
                </div>
            ),
            cellClickAction: () => navigate(`/dashboard/purchase-orders/${po?._id}`),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskRegular border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center gap-3">
                        <VendorAvatar
                            imageSrc={po?.vendor?.profile?.avatar}
                            size={30}
                            name={po?.vendor?.profile?.businessName}
                        />
                        <span>{truncate(po?.vendor?.profile?.businessName, { length: 15 })}</span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskRegular border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center gap-3">
                        <span>
                            {truncate(po?.order?.customerDetails?.companyName, { length: 15 })}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskRegular border-slate-100 text-sm text-slate-800 `}
                >
                    <p>
                        {truncate(moment(po?.issuedDate).format("MMMM DD, YYYY"), { length: 10 })}
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskRegular border-slate-100 text-sm text-slate-800 `}
                >
                    <p>{truncate(moment(po?.dueDate).format("MMMM DD YYYY"), { length: 10 })}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <p>
                        {getCurrencyFromCurrencyCode(po?.totalAmount?.currency)}
                        {po?.totalAmount?.amount}
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full font-rocGroteskRegular border-slate-100 text-sm text-slate-800 `}
                >
                    <p>{truncate(po?.rejectReason?.[0]?.reason, { length: 25 })}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 space-x-1  font-rocGroteskRegular flex items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    <div className={`h-2 w-2 bg-[#FF3347] rounded-full`}></div>
                    <p>rejected</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-100px]  ${
                            purchaseOrderList?.length >= 9 &&
                            idx >= purchaseOrderList?.length - 2 &&
                            "!top-[-150px]"
                        }`}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "Preview PO",
                                value: "preview po",
                                action: () => {
                                    navigate(`/dashboard/purchase-orders/${po?._id}`);
                                },
                            },
                            {
                                label: "View Details",
                                value: "view details",
                                action: () => {
                                    setSelectedPO(po);
                                    setDetailsModal(true);
                                },
                            },
                            {
                                label: "Recreate PR",
                                value: "recreate pr",
                                action: () => {
                                    setSelectedPO(po);
                                    setRecreatePRModal(true);
                                },
                            },
                        ]}
                    />
                </div>
            ),
        },
    ]);

    const mobileRejectedTableBody = rejectedPurchaseOrderList?.map((po, idx) => {
        return {
            topString: po?.vendor?.profile?.businessName + po?._id,
            topContent: (
                <div className="flex items-center gap-3">
                    <VendorAvatar
                        size={32}
                        name={po?.vendor?.profile?.businessName}
                        imageSrc={po?.vendor?.profile?.avatar}
                    />
                    <p>{truncate(po?.vendor?.profile?.businessName, { length: 20 })}</p>
                </div>
            ),
            bottomContent: (
                <div className="mt-3 flex gap-3 text-slate-500 text-sm font-rocGroteskMedium">
                    <p>PO {po?.taId}</p>
                    <div className="flex items-center gap-2">
                        <div className={`h-2 w-2 bg-[#FF3347] rounded-full`}></div>
                        <span className="capitalize">Rejected</span>
                    </div>
                </div>
            ),
            rightIcon: (
                <div
                    className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-100px]  ${
                            purchaseOrderList?.length >= 9 &&
                            idx >= purchaseOrderList?.length - 2 &&
                            "!top-[-150px]"
                        }`}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "Preview PO",
                                value: "preview po",
                                action: () => {
                                    navigate(`/dashboard/purchase-orders/${po?._id}`);
                                },
                            },
                            {
                                label: "View Details",
                                value: "view details",
                                action: () => {
                                    setSelectedPO(po);
                                    setDetailsModal(true);
                                },
                            },
                            {
                                label: "Recreate PR",
                                value: "recreate pr",
                                action: () => {
                                    setSelectedPO(po);
                                    setRecreatePRModal(true);
                                },
                            },
                        ]}
                    />
                </div>
            ),
        };
    });

    const detailsItemsTableHeader = [
        {
            title: "Items",
            widthClass: "w-[85%] ",
            itemClass: "!text-slate-400 font-rocGroteskMedium",
        },
        {
            title: "Qty",
            widthClass: "w-[15%] w-full flex justify-center ",
            itemClass: "!text-slate-400 font-rocGroteskMedium",
        },
    ];

    const detailsItemsTableBody = singlePODetails?.itemsOrServices?.map((item, idx) => [
        {
            content: (
                <div className={`w-full border-slate-100 text-sm text-slate-700 py-2`}>
                    <p className="text-[10px] font-rocGroteskMedium text-slate-500">Item</p>
                    <p className="text-sm font-rocGroteskMedium">
                        <span>{item?.name}</span>
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`w-full flex justify-center h-full border-slate-100 text-sm text-slate-700 py-2 pr-3`}
                >
                    <span className="text-xs font-rocGroteskMedium">{item?.qty}</span>
                </div>
            ),
        },
    ]);

    const approvalTableHeader = [
        {
            title: "Approvals",
            widthClass: "w-[65%]",
            itemClass: "!text-slate-400 font-rocGroteskMedium",
        },
        {
            title: "Levels",
            widthClass: "w-[14.5%]",
            itemClass: "!text-slate-400 font-rocGroteskMedium justify-center",
        },
        {
            title: "Status",
            widthClass: "w-[20.5%]",
            itemClass: "!text-slate-400 font-rocGroteskMedium justify-center",
        },
    ];

    const approvalTableBody = (hasApprovals ? approvalPersonnel : [{}])?.map((item, idx) => {
        const hasBeenApproved =
            singlePODetails?.approvedBy
                ?.map((approvedUser) => approvedUser?._id)
                ?.includes(item?._id) || approvalStatusArr?.includes(item?._id);

        const hasBeenRejected =
            singlePODetails?.rejectedBy
                ?.map((rejectedUser) => rejectedUser?._id)
                ?.includes(item?._id) || approvalStatusArr?.includes(item?._id);

        return [
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 py-2 pr-3`}>
                        {item?.email ? (
                            <div>
                                <p className="text-[13px] font-rocGroteskMedium">
                                    <span>{item?.fullName || item?.email} - </span>
                                    <span className="text-p-50 ml-1">{item?.role}</span>
                                </p>
                                {item?.fullName && (
                                    <p className="text-xs font-rocGroteskMedium text-slate-500">
                                        {item?.email}
                                    </p>
                                )}
                            </div>
                        ) : (
                            <p className="">---</p>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full flex justify-center border-slate-100 text-sm text-slate-700 py-2 pr-3`}
                    >
                        <p className="text-xs font-rocGroteskMedium text-slate-500">{idx + 1}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full flex justify-center h-full border-slate-100 text-sm text-slate-700 py-2 pr-3`}
                    >
                        {item?.email ? (
                            hasBeenApproved ? (
                                <div className="flex items-center justify-center h-[25px] bg-g-25 px-2.5 py-1 rounded-[20px]">
                                    <span className="text-xs font-rocGroteskMedium">Approved</span>
                                </div>
                            ) : hasBeenRejected ? (
                                <div className="flex items-center justify-center h-[25px] bg-r-40 px-2.5 py-1 rounded-[20px]">
                                    <span className="text-xs font-rocGroteskMedium">Rejected</span>
                                </div>
                            ) : (
                                <div className="flex items-center justify-center h-[25px] bg-[#F9DFB399] px-2.5 py-1 rounded-[20px]">
                                    <span className="text-xs font-rocGroteskMedium">Pending</span>
                                </div>
                            )
                        ) : (
                            <p className="">---</p>
                        )}
                    </div>
                ),
            },
        ];
    });

    const tabs = ["Pending PR", "Approved PO", "Rejected PO"];

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return purchaseOrderList?.length > 0 ? (
                    <div className="">
                        <div className="max-lg:hidden">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isScrollable={false}
                                // isScrolled={isScrolled}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>
                        <div className="hidden max-lg:block">
                            <CustomMobileTable data={mobileTableBody} />
                        </div>
                        {loadMorePurchaseOrder && fetchingPurchaseOrder && (
                            <div className="flex my-4 justify-center">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {purchaseOrderPagination?.current ===
                            purchaseOrderPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                <p className="text-center"> End of list</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                        <div className="space-y-2  text-center">
                            <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                            <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                {filter.search
                                    ? "No purchase order found"
                                    : "No purchase order yet"}
                            </p>
                            <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                {filter.search
                                    ? "No purchase order found for this search query"
                                    : "Recent purchase order will be displayed here"}
                            </p>
                        </div>
                    </div>
                );

            case 1:
                return approvedPurchaseOrderList?.length > 0 ? (
                    <div className="">
                        <div className="max-lg:hidden">
                            <CustomTable
                                tableBody={approvedTableBody}
                                tableHeader={approvedTableHeader}
                                isScrollable={false}
                                // isScrolled={isScrolled}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>
                        <div className="hidden max-lg:block">
                            <CustomMobileTable data={mobileApprovedTableBody} />
                        </div>
                        {loadMorePurchaseOrder && fetchingPurchaseOrder && (
                            <div className="flex my-4 justify-center">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {approvedPurchaseOrderPagination?.current ===
                            approvedPurchaseOrderPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                <p className="text-center"> End of list</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                        <div className="space-y-2  text-center">
                            <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                            <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                {filter.search
                                    ? "No purchase order found"
                                    : "No purchase order yet"}
                            </p>
                            <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                {filter.search
                                    ? "No purchase order found for this search query"
                                    : "Recent purchase order will be displayed here"}
                            </p>
                        </div>
                    </div>
                );

            case 2:
                return rejectedPurchaseOrderList?.length > 0 ? (
                    <div className="">
                        <div className="max-lg:hidden">
                            <CustomTable
                                tableBody={rejectedTableBody}
                                tableHeader={rejectedTableHeader}
                                isScrollable={false}
                                // isScrolled={isScrolled}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>
                        <div className="hidden max-lg:block">
                            <CustomMobileTable data={mobileRejectedTableBody} />
                        </div>
                        {loadMorePurchaseOrder && fetchingPurchaseOrder && (
                            <div className="flex my-4 justify-center">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {rejectedPurchaseOrderPagination?.current ===
                            rejectedPurchaseOrderPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                <p className="text-center"> End of list</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                        <div className="space-y-2  text-center">
                            <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                            <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                {filter.search
                                    ? "No purchase order found"
                                    : "No purchase order yet"}
                            </p>
                            <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                {filter.search
                                    ? "No purchase order found for this search query"
                                    : "Recent purchase order will be displayed here"}
                            </p>
                        </div>
                    </div>
                );

            default:
                return purchaseOrderList?.length > 0 ? (
                    <div className="">
                        <div className="max-lg:hidden">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isScrollable={false}
                                // isScrolled={isScrolled}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>
                        <div className="hidden max-lg:block">
                            <CustomMobileTable data={mobileTableBody} />
                        </div>
                        {loadMorePurchaseOrder && fetchingPurchaseOrder && (
                            <div className="flex my-4 justify-center">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {purchaseOrderPagination?.current ===
                            purchaseOrderPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                <p className="text-center"> End of list</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                        <div className="space-y-2  text-center">
                            <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                            <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                {filter.search
                                    ? "No purchase order found"
                                    : "No purchase order yet"}
                            </p>
                            <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                {filter.search
                                    ? "No purchase order found for this search query"
                                    : "Recent purchase order will be displayed here"}
                            </p>
                        </div>
                    </div>
                );
        }
    };

    const handleApproveRejectPO = (isApprove) => {
        const specificTask = singlePODetails?.tasks?.find(
            (task) => task?.assignee?._id === profile?._id
        );
        const body: { [key: string]: any } = {
            approved: isApprove,
            taskId: specificTask?._id,
            poId: singlePODetails?._id,
        };

        if (!isApprove) {
            body.rejectReason = rejectionReason;
        }

        dispatch(orderActions.approveRejectPO(body));
    };

    // useEffect(() => {
    //     dispatch(
    //         orderActions.getPurchaseOrder(
    //             poLimit,
    //             page,
    //             debouncedFilter.search,
    //             debouncedFilter.taId,
    //             "pending"
    //         )
    //     );
        // setTimeout(() => {
        //     setLoadMorePurchaseOrder(false);
        // }, 800);
    // }, [dispatch, debouncedFilter, poLimit, createPurchaseOrderSuccess]);

    // useEffect(() => {
    //     dispatch(
    //         orderActions.getPurchaseOrder(
    //             poLimit,
    //             debouncedFilter.search,
    //             debouncedFilter.taId,
    //             "approved"
    //         )
    //     );
    // }, [dispatch, debouncedFilter, poLimit]);

    // useEffect(() => {
    //     dispatch(
    //         orderActions.getPurchaseOrder(
    //             poLimit,
    //             debouncedFilter.search,
    //             debouncedFilter.taId,
    //             "rejected"
    //         )
    //     );
    // }, [dispatch, debouncedFilter, poLimit]);

    useEffect(() => {
        if (Boolean(fetchedPurchaseOrderSuccess)) {
            if (
                fetchedPurchaseOrderSuccess?.po?.length > 0 &&
                fetchedPurchaseOrderSuccess?.po?.[0]?.approvalStatus?.toLowerCase() === "pending"
            ) {
                setPurchaseOrderList(fetchedPurchaseOrderSuccess?.po);
                setPurchaseOrderPagination({
                    current: fetchedPurchaseOrderSuccess?.pagination.current,
                    number_of_pages: fetchedPurchaseOrderSuccess?.pagination?.number_of_pages,
                });
            } else {
                setPurchaseOrderList((prev) => [...prev]);
            }
        }
    }, [fetchedPurchaseOrderSuccess]);

    useEffect(() => {
        if (Boolean(fetchedPurchaseOrderSuccess)) {
            if (
                fetchedPurchaseOrderSuccess?.po?.length > 0 &&
                fetchedPurchaseOrderSuccess?.po?.[0]?.approvalStatus?.toLowerCase() === "approved"
            ) {
                setApprovedPurchaseOrderList(fetchedPurchaseOrderSuccess?.po);
                setApprovedPurchaseOrderPagination({
                    current: fetchedPurchaseOrderSuccess?.pagination.current,
                    number_of_pages: fetchedPurchaseOrderSuccess?.pagination?.number_of_pages,
                });
            } else {
                setPurchaseOrderList((prev) => [...prev]);
            }
        }
    }, [fetchedPurchaseOrderSuccess]);

    useEffect(() => {
        if (Boolean(fetchedPurchaseOrderSuccess)) {
            if (
                fetchedPurchaseOrderSuccess?.po?.length > 0 &&
                fetchedPurchaseOrderSuccess?.po?.[0]?.approvalStatus?.toLowerCase() === "rejected"
            ) {
                setRejectedPurchaseOrderList(fetchedPurchaseOrderSuccess?.po);
                setRejectedPurchaseOrderPagination({
                    current: fetchedPurchaseOrderSuccess?.pagination.current,
                    number_of_pages: fetchedPurchaseOrderSuccess?.pagination?.number_of_pages,
                });
            } else {
                setPurchaseOrderList((prev) => [...prev]);
            }
        }
    }, [fetchedPurchaseOrderSuccess]);

    useEffect(() => {
        if (selectedPO?._id) {
            dispatch(orderActions.getSinglePurchaseOrder(selectedPO?._id));
            setLoadDetails(true);
        }
    }, [dispatch, selectedPO?._id]);

    useEffect(() => {
        if (Boolean(fetchedSinglePurchaseOrderSuccess)) {
            setSinglePODetails(fetchedSinglePurchaseOrderSuccess?.purchaseOrder);
            setLoadDetails(false);
        }
    }, [fetchedSinglePurchaseOrderSuccess]);

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onPurchaseOrderTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onPurchaseOrderTableScroll);
        };
    }, [dashboardContent, onPurchaseOrderTableScroll]);

    useEffect(() => {
        if (Boolean(approveOrRejectPOSuccess)) {
            const isApprovedAction = approveOrRejectPOSuccess?.po?.approvedById?.includes(
                profile?._id
            );

            const isRejectionAction = approveOrRejectPOSuccess?.po?.rejectedById?.includes(
                profile?._id
            );

            if (isApprovedAction) {
                setApprovalStatusArr(approveOrRejectPOSuccess?.po?.approvedById);
                setOpenApproveModal(false);
                setOpenApprovalSuccessModal(true);
            }

            if (isRejectionAction) {
                setApprovalStatusArr(approveOrRejectPOSuccess?.po?.rejectedById);
                setOpentRejectModal(false);
                setOpentRejectionReasonModal(false);
                toast.custom((t) => (
                    <CustomToast
                        t={t}
                        message={"Purchase order rejected successfully"}
                        type={"error"}
                    />
                ));
            }

            setRejectionReason("");
            setAllowAction(false);
            dispatch(orderActions.resetApproveRejectPOSuccess());
        }
    }, [approveOrRejectPOSuccess, dispatch, profile?._id]);

    return (
        <PageFrame
            containerClassName={"!h-[calc(100vh-108px)]"}
            isLoading={
                fetchingPurchaseOrder && purchaseOrderList?.length === 0 && !debouncedFilter.search
            }
        >
            <div className="md:mx-7 mx-5 max-sm:mx-0">
                <div className="mb-[25px] ">
                    <BreadCrumb
                        parentCrumb={"Purchase Order (PO)"}
                        size="large"
                        parentClassName="!text-slate-700"
                    />
                </div>
                <div className="flex md:space-y-0 space-y-3 md:flex-row flex-col items-center justify-between md:mb-[27px]">
                    <div className="flex md:w-[428px] w-full items-center gap-3">
                        <TextInput
                            name={"search"}
                            type={"text"}
                            value={filter.search}
                            inputPlaceholder={"Search by PO id (e.g 00035)"}
                            inputClassName={"!h-[34px] pl-[0px] !bg-white text-sm "}
                            leftIcon={
                                <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                            }
                            inputContainerClassname={
                                "!rounded-[4px] !h-[40px] w-full !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                            }
                            onChange={handleSearch}
                            onInput={handleDebouncedChange}
                        />

                        {/* <Dropdown
                            value={""}
                            dropdown={
                                <div className="flex items-center cursor-pointer gap-2 py-1.5 px-3 bg-white rounded shadow-boxShadow-2 border border-n-20">
                                    <i className="ri-filter-3-fill"></i>
                                    <p className="text-[13px] font-rocGroteskMedium">Filter</p>
                                </div>
                            }
                            dropdownContainerClasses={
                                " left-[-234px] !py-0 top-[49px] shadow-dropdownShadow rounded !border-0"
                            }
                            dropdownClassName={"!max-h-fit"}
                            name={"quickAction"}
                            dropdownOptions={[
                                {
                                    customChild: (
                                        <div className="w-[318px] relative  bg-white p-4 shadow-boxShadow-2 rounded border border-n-20">
                                            <div className="flex relative flex-col h-fit gap-2">
                                                <TextInput
                                                    name={"quoteId"}
                                                    type={"text"}
                                                    value={filter.taId}
                                                    placeholder={"Purchase Order ID"}
                                                    onChange={handleSearch}
                                                    onInput={handleDebouncedChange}
                                                    required={false}
                                                />
                                                <TextInput
                                                    name={"amount"}
                                                    type={"number"}
                                                    onWheel={(e) => e.target.blur()}
                                                    value={""}
                                                    placeholder={"Amount"}
                                                    onChange={handleSearch}
                                                    onInput={handleDebouncedChange}
                                                    required={false}
                                                />
                                                <TextInput
                                                    name={"vendor"}
                                                    type={"text"}
                                                    value={""}
                                                    placeholder={"Vendor"}
                                                    onChange={handleSearch}
                                                    onInput={handleDebouncedChange}
                                                    required={false}
                                                />
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        /> */}
                    </div>
                    <div className="max-sm:w-full max-sm:!mb-6">
                        <Button
                            btnText={"Create PO"}
                            type={"button"}
                            btnClassname="!py-2 !px-4 max-sm:w-full"
                            onClick={() => navigate("/dashboard/purchase-orders/create")}
                        />
                    </div>
                </div>

                <div className="">
                    <TabsContainer
                        tabs={tabs}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        component={displayActiveTab()}
                        className="!px-0"
                        itemClassName="!pb-2"
                        borderLineClase={
                            "!text-slate-100 w-[40%] max-sm:w-full max-lg:w-[50%] mb-4"
                        }
                    />
                </div>
            </div>

            <ModalContainer
                open={detailsModal}
                showCloseIcon={false}
                closeModal={() => {
                    setDetailsModal(false);
                    setSinglePODetails({});
                }}
                tailwindClassName="w-[45%] max-lg:w-[95%]"
            >
                {loadDetails ? (
                    <div className="bg-white p-8 rounded ">
                        <Loader size={7} />
                    </div>
                ) : (
                    <div className="bg-white p-8 rounded max-h-[650px] overflow-auto">
                        <div className="flex items-center justify-between mb-[28px]">
                            <span className="text-lg text-slate-500 font-rocGroteskMedium">
                                Details
                            </span>
                            <i
                                onClick={() => {
                                    setDetailsModal(false);
                                    setSinglePODetails({});
                                }}
                                className="ri-close-fill text-[30px] text-slate-500 cursor-pointer"
                            ></i>
                        </div>
                        <div className="items-center gap-4 justify-between mb-6">
                            <span className="text-base font-rocGroteskMedium">
                                Purchase{" "}
                                {singlePODetails?.approvalStatus?.toLowerCase() === "pending"
                                    ? "request"
                                    : "order"}{" "}
                                - {singlePODetails?.taId}
                            </span>
                            <div className="text-right">
                                <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                    Buyer
                                </span>
                                <div>
                                    <span className="text-sm font-rocGroteskMedium">
                                        {singlePODetails?.vendor?.profile?.businessName}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h5 className="mb-4 text-base font-rocGroteskMedium">Items</h5>
                            <div className="mb-4">
                                <CustomTable
                                    tableBody={detailsItemsTableBody}
                                    tableHeader={detailsItemsTableHeader}
                                    isScrollable={false}
                                    isCellBordered={true}
                                    isAllSelectable={false}
                                    headerContainerClass="!bg-slate-50"
                                    // tableClass="mb-12"
                                />
                            </div>
                        </div>
                        <div className="font-rocGroteskMedium text-base text-[#16C6A4] mb-6">
                            <p className="">Total amount</p>
                            <p className="">
                                {getCurrencyFromCurrencyCode(
                                    singlePODetails?.totalAmount?.currency
                                )}
                                {formatMoney()?.format(singlePODetails?.totalAmount?.amount)}
                            </p>
                        </div>
                        <hr className="w-full mb-6 bg-slate-200" />
                        <div className="mb-6">
                            <h5 className="mb-4 text-base font-rocGroteskMedium">Approval level</h5>
                            <div className="mb-4">
                                <div className="my-4">
                                    <CustomTable
                                        tableBody={approvalTableBody}
                                        tableHeader={approvalTableHeader}
                                        isAllSelectable={false}
                                        headerContainerClass="!bg-slate-50"
                                        tableClass=" w-full"
                                        // contentContainer="!pl-0"
                                        isScrollable={false}
                                        isCellBordered={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr className="w-full mb-6 bg-slate-200" />
                        {singlePODetails?.documents?.length > 0 && (
                            <div className="mb-6">
                                <h5 className="mb-4 text-base font-rocGroteskMedium">Attachment</h5>
                                {singlePODetails?.documents?.map((doc, idx) => (
                                    <div
                                        key={doc?.path + idx}
                                        className="border border-slate-100 p-4 flex items-center justify-between"
                                    >
                                        <div className="flex items-center gap-3">
                                            {displayFileIcon(getFileTypeFromUrl(doc?.path), true)}
                                            <span className="text-sm font-rocGroteskMedium">
                                                {truncate(doc?.filename, { length: 25 })}
                                            </span>
                                        </div>
                                        <i
                                            onClick={() => downloadUrl(doc?.path, doc?.filename)}
                                            className="ri-download-cloud-2-line text-lg text-p-55 cursor-pointer"
                                        ></i>
                                    </div>
                                ))}
                            </div>
                        )}
                        {activeTab === 0 && (
                            <div className="flex items-center justify-end w-full">
                                <div
                                    className={`flex items-center gap-2.5 ${
                                        !shouldUserApprove && "opacity-25"
                                    }`}
                                >
                                    <Button
                                        btnText={"Reject"}
                                        type={"button"}
                                        btnClassname="!h-[50px] !w-[140px] !bg-transparent !text-r-50 !border-r-50"
                                        onClick={() =>
                                            shouldUserApprove && setOpentRejectModal(true)
                                        }
                                    />
                                    <Button
                                        btnText={"Approve"}
                                        type={"button"}
                                        btnClassname="!h-[50px] !w-[140px]"
                                        onClick={() =>
                                            shouldUserApprove && setOpenApproveModal(true)
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </ModalContainer>

            <ModalContainer
                open={openApproveModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setOpenApproveModal(false);
                }}
            >
                <div className="bg-white !px-2 p-8  rounded-[8px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <div className="w-16 h-16 flex justify-center items-center bg-[#00DB8F] rounded-full">
                                <i className="ri-checkbox-circle-fill text-[29px] text-white"></i>
                            </div>
                            <div>
                                <h3 className="text-2xl mb-2 text-black font-rocGroteskMedium">
                                    Approved
                                </h3>
                                <p className="text-slate-500 text-center mt-1 text-sm font-rocGroteskMedium">
                                    The purchase request will be approved by you, kindly select
                                    proceed to confirm.
                                </p>
                            </div>
                        </div>

                        <div className="mt-5 flex gap-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                isLoading={false}
                                onClick={() => {
                                    setOpenApproveModal(false);
                                }}
                                disabled={loadingPOApprovalOrRejection}
                            />
                            <Button
                                onClick={() => handleApproveRejectPO(true)}
                                btnText={"Proceed"}
                                type={"button"}
                                disabled={loadingPOApprovalOrRejection}
                                isLoading={loadingPOApprovalOrRejection}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={openRejectModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setOpentRejectModal(false);
                }}
            >
                <div className="bg-white !px-2 p-8  rounded-[8px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1697712771/Caution_sign_g1u9nv.svg"
                                alt="caution"
                                className="w-[60px] h-[53px]"
                            />
                            <div>
                                <h3 className="text-2xl mb-2 text-black font-rocGroteskMedium">
                                    Rejected
                                </h3>
                                <p className="text-slate-500 text-center mt-1 text-sm font-rocGroteskMedium">
                                    This purchase order will be rejected by you, kindly add a
                                    comment to complete rejection
                                </p>
                            </div>
                        </div>

                        <div className="mt-5 flex gap-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                isLoading={false}
                                onClick={() => {
                                    setOpentRejectModal(false);
                                }}
                                disabled={loadingPOApprovalOrRejection}
                            />
                            <Button
                                onClick={() => setOpentRejectionReasonModal(true)}
                                btnText={"Add Comment"}
                                type={"button"}
                                btnClassname={"!bg-r-50 !text-white"}
                                disabled={loadingPOApprovalOrRejection}
                                isLoading={loadingPOApprovalOrRejection}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={openApprovalSuccessModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setOpenApprovalSuccessModal(false);
                }}
            >
                <div className="bg-white !px-2 p-8  rounded-[8px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <div className="w-16 h-16 flex justify-center items-center bg-[#00DB8F] rounded-full">
                                <i className="ri-checkbox-circle-fill text-[29px] text-white"></i>
                            </div>
                            <div>
                                <h3 className="text-2xl mb-2 text-black font-rocGroteskMedium">
                                    Success
                                </h3>
                                <p className="text-slate-500 text-center mt-1 text-sm font-rocGroteskMedium">
                                    Request forwarded to next approver successfully. Approver will
                                    be notified of the pending task.Your request is pending the
                                    approval of the next level and you will be notified upon
                                    completion
                                </p>
                            </div>
                        </div>

                        <div className="mt-5 flex gap-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                isLoading={false}
                                onClick={() => {
                                    setOpenApprovalSuccessModal(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={openRejectionReasonModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px]"
                closeModal={() => {
                    setOpentRejectionReasonModal(false);
                    setRejectionReason("");
                }}
            >
                <div className="bg-white !px-2 p-8  rounded-[8px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center">
                            <div className="mb-8">
                                <h3 className="text-2xl mb-2.5 text-black font-rocGroteskMedium">
                                    Provide a comment to reject this PO
                                </h3>
                                <p className="text-slate-500 text-center mt-1 text-sm font-rocGroteskMedium">
                                    To ensure transparency and compliance, please provide a brief
                                    comment explaining your decision to rejecting this PO.
                                </p>
                            </div>

                            <div className="w-full">
                                <TextareaInput
                                    name={"rejectionReason"}
                                    value={rejectionReason}
                                    onChange={(e) => setRejectionReason(e.target.value)}
                                    rows={5}
                                    placeholder={"Comment"}
                                    required={false}
                                    containerClassname=""
                                />
                            </div>
                        </div>

                        <div className="mt-5 flex flex-col items-center gap-2.5">
                            <Button
                                onClick={() => handleApproveRejectPO(false)}
                                btnText={"Send"}
                                type={"button"}
                                disabled={loadingPOApprovalOrRejection}
                                isLoading={loadingPOApprovalOrRejection}
                            />
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-transparent !text-gm-50 !w-fit"}
                                isLoading={false}
                                onClick={() => {
                                    setOpentRejectionReasonModal(false);
                                    setRejectionReason("");
                                }}
                                disabled={loadingPOApprovalOrRejection}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={recreatePRModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[500px] max-sm:w-full"
                closeModal={() => {
                    setRecreatePRModal(false);
                }}
            >
                <div className="bg-white !px-2 p-8  rounded-[8px]">
                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <div className="w-16 h-16 flex justify-center items-center bg-[#00DB8F] rounded-full">
                                <i className="ri-checkbox-circle-fill text-[29px] text-white"></i>
                            </div>
                            <div>
                                <h3 className="text-2xl mb-2 text-black font-rocGroteskMedium">
                                    Recreate PR
                                </h3>
                                <p className="text-slate-500 text-center mt-1 text-sm font-rocGroteskMedium">
                                    Are you sure you want to recreate this PR?
                                </p>
                            </div>
                        </div>

                        <div className="mt-5 flex gap-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                isLoading={false}
                                onClick={() => {
                                    setRecreatePRModal(false);
                                }}
                                disabled={createPurchaseOrder}
                            />
                            <Button
                                onClick={() => handleRecreate()}
                                btnText={"Create"}
                                type={"button"}
                                disabled={createPurchaseOrder}
                                isLoading={createPurchaseOrder}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default PurchaseOrder;
