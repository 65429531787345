/* eslint-disable */
import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import CustomCalendar from "components/common/CalendarComp.js/Calendar";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import {getCurrencyFromCurrencyCode, onScroll} from "helpers";
import {flatten} from "helpers/flattenArray";
import {debounce, flattenDeep, truncate} from "lodash";
import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../hooks/storeHooks";
import {useNavigate} from "react-router-dom";
import {orderActions} from "redux/Ldb/actions";

const Quotes = () => {
    const limit = 20;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [quoteLimit, setQuoteLimit] = useState(limit);
    const [quotesList, setQuotesList] = useState([]);
    const [loadMoreQuotes, setLoadMoreQuotes] = useState(false);
    const [quotePagination, setQuotePagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [filter, setFilter] = useState({
        search: "",
        amount: "",
        vendor: "",
        quoteId: "",
    });
    const [debouncedFilter, setDebouncedFilter] = useState({
        search: "",
        amount: "",
        vendor: "",
        quoteId: "",
    });
    const dashboardContent = document.getElementById("dashboardContent");
    const {fetchingQuotes, fetchedQuotesSuccess} = useAppSelector((state) => state.order);

    const handleSearch = (e) => {
        const {name, value} = e.target;

        setFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const onQuoteTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                quotePagination?.current as number,
                quotePagination?.number_of_pages as number,
                () => {
                    setQuoteLimit(() => quoteLimit + limit);
                    setLoadMoreQuotes(true);
                }
            ),
        [dashboardContent, quotePagination, quoteLimit]
    );
    
    const handleDebouncedChange = debounce((e) => {
        const {name, value} = e.target;

        setDebouncedFilter((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, 1500);

    useEffect(() => {
        dispatch(
            orderActions.getQuotes(
                quoteLimit,
                debouncedFilter.search,
                debouncedFilter.vendor,
                debouncedFilter.quoteId,
                debouncedFilter.amount
            )
        );
        setTimeout(() => {
            setLoadMoreQuotes(false);
        }, 800);
    }, [dispatch, debouncedFilter, quoteLimit]);

    useEffect(() => {
        if (Boolean(fetchedQuotesSuccess)) {
            setQuotesList(fetchedQuotesSuccess?.quotes);
            setQuotePagination({
                current: fetchedQuotesSuccess?.pagination.current,
                number_of_pages: fetchedQuotesSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedQuotesSuccess]);

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onQuoteTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onQuoteTableScroll);
        };
    }, [dashboardContent, onQuoteTableScroll]);

    const tableHeader = [
        {title: "Quote ID", widthClass: "w-[25%]"},
        {title: "Amount", widthClass: "w-[19%]"},
        {title: "Item price list", widthClass: "w-[28%]"},
        {title: "Vendors response", widthClass: "w-[24%]"},
    ];

    const tableBody = quotesList?.map((quote) => {
        const allVendors = quote?.rfqs?.flatMap((rfq) => rfq?.vendors || []);
        const firstSixVendors = allVendors?.slice(0, 6);
        const remainingVendors = allVendors?.slice(6);
        return [
            {
                content: (
                    <div
                        className={`py-[18px] pr-4 border-slate-100 text-sm font-rocGroteskRegular text-slate-700 `}
                    >
                        <p className="text-slate-800 ">Quote {quote?.taId}</p>
                        <p className="text-slate-500 leading-5">
                            {truncate(
                                `${quote?.rfqs?.map((rfq) => rfq?.item?.description).join(", ")} `,
                                {
                                    length: 40,
                                }
                            )}{" "}
                        </p>
                    </div>
                ),
                cellClickAction: () => {
                    navigate(`/dashboard/quotes/${quote?._id}?taId=${quote?.taId}`);
                },
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-4 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        {quote?.amount?.from?.amount === "0" &&
                        quote?.amount?.to?.amount === "0" ? (
                            <p>---</p>
                        ) : (
                            <>
                                {getCurrencyFromCurrencyCode(quote?.amount?.from?.currency)}
                                {`${quote?.amount?.from?.amount}`} -{" "}
                                {`${getCurrencyFromCurrencyCode(quote?.amount?.from?.currency)}${
                                    quote?.amount?.to?.amount
                                }`}
                            </>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-4 border-slate-100 text-sm font-rocGroteskRegular text-slate-800 `}
                    >
                        {quote?.rfqs?.length === 0 && quote?.acceptedQuoteResponses?.length === 0 ? (
                            <p>----</p>
                        ) : (
                            <>
                                {quote?.rfqs?.length > 0 &&
                                quote?.acceptedQuoteResponses?.length === 0 ? (
                                    <p className="text-slate-800">Awaiting for you to accept</p>
                                ) : (
                                    <div className="text-slate-800">
                                        <p>{quote?.rfqs?.length} items price</p>
                                        <p className="text-slate-500 leading-5 whitespace-nowrap">
                                            {quote?.acceptedQuoteResponses?.length} accepted
                                            {quote?.rfqs?.length >
                                                quote?.acceptedQuoteResponses?.length && (
                                                <span>
                                                    ,{" "}
                                                    {quote?.rfqs?.length -
                                                        quote?.acceptedQuoteResponses?.length}{" "}
                                                    pending
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                ),
            },

            {
                content: (
                    <div
                        className={`py-3 pr-3 flex items-center w-full border-slate-100 text-sm text-slate-800 `}
                    >
                        {quote?.rfqs?.length > 0 && quote?.acceptedQuoteResponses?.length === 0 ? (
                            <div className="flex space-x-1 ">
                                <div className="flex space-x-1">
                                    <div className="flex">
                                        {firstSixVendors?.map((vendor, idx) => (
                                            <div key={vendor._id + idx} className="flex space-x-1">
                                                <VendorAvatar
                                                    key={vendor._id}
                                                    imageSrc={vendor?.profile?.avatar}
                                                    size={30}
                                                    name={vendor?.profile?.businessName}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    {remainingVendors?.length > 0 && (
                                        <div className="h-[32px] px-2 flex items-center justify-center w-[32px] border border-white bg-n-20">
                                            <p className="font-rocGroteskRegular text-gm-50 text-[10px] rounded-[4px]">
                                                +{remainingVendors?.length}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="flex items-center space-x-1 text-sm font-rocGroteskRegular">
                                <div className="w-[32px] h-[32px] rounded-[4px] flex justify-center items-center bg-n-20">
                                    <i className="ri-building-2-fill text-[18px] text-slate-500"></i>
                                </div>
                                <div>
                                    <p className="text-slate-800 ">{quote?.rfqs?.length} vendors</p>
                                    <p className="text-slate-500 leading-5 whitespace-nowrap">
                                        {quote?.acceptedQuoteResponses?.length} vendors accepted
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                ),
            },
        ];
    });

    return (
        <PageFrame
            containerClassName={"!h-[calc(100vh-108px)]"}
            isLoading={fetchingQuotes && quotesList?.length === 0}
        >
            <div className="px-2 xs:px-[20px] lg:px-[26px]">
                <div className="mb-8">
                    <BreadCrumb
                        parentCrumb={"Orders"}
                        parentUrl={"/dashboard/quotes"}
                        childrenCrumb={[{title: "Quotes", path: `/dashboard/quotes`}]}
                        size="large"
                    />
                </div>

                <div className="flex md:space-y-0 space-y-3 md:flex-row flex-col items-center justify-between lg:mb-[27px]">
                    <div className="flex  md:w-[428px] w-full items-center gap-3">
                        <TextInput
                            name={"search"}
                            type={"text"}
                            value={filter.search}
                            inputPlaceholder={"Search"}
                            inputClassName={"!h-[40px] pl-[0px] !bg-white text-sm !mb-0"}
                            leftIcon={
                                <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                            }
                            inputContainerClassname={
                                "!rounded-[4px] !h-[40px]  w-full !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                            }
                            onChange={handleSearch}
                            onInput={handleDebouncedChange}
                        />

                        {/* <Dropdown
                                value={""}
                                dropdown={
                                    <div className="flex items-center cursor-pointer gap-2 py-1.5 px-3 bg-white rounded shadow-boxShadow-2 border border-n-20">
                                        <i className="ri-filter-3-fill"></i>
                                        <p className="text-[13px] lg:block hidden font-rocGroteskMedium">
                                            Filter
                                        </p>
                                    </div>
                                }
                                dropdownContainerClasses={
                                    " left-[-234px] !py-0 top-[49px] shadow-dropdownShadow rounded !border-0"
                                }
                                dropdownClassName={"!max-h-fit"}
                                name={"quickAction"}
                                dropdownOptions={[
                                    {
                                        customChild: (
                                            <div className="w-[318px] relative  bg-white p-4 shadow-boxShadow-2 rounded border border-n-20">
                                                <div className="flex relative flex-col h-fit gap-2">
                                                    <TextInput
                                                        name={"quoteId"}
                                                        type={"text"}
                                                        value={filter.quoteId}
                                                        placeholder={"Quote ID"}
                                                        onChange={handleSearch}
                                                        onInput={handleDebouncedChange}
                                                        required={false}
                                                    />
                                                    <TextInput
                                                        name={"amount"}
                                                        type={"number"}
                                                        onWheel={(e) => e.target.blur()}
                                                        value={filter.amount}
                                                        placeholder={"Amount"}
                                                        onChange={handleSearch}
                                                        onKeyDown={(evt) =>
                                                            [
                                                                "e",
                                                                "E",
                                                                "+",
                                                                "-",
                                                                "ArrowUp",
                                                                "ArrowDown",
                                                            ].includes(evt.key) &&
                                                            evt.preventDefault()
                                                        }
                                                        onInput={handleDebouncedChange}
                                                        required={false}
                                                    />
                                                    <TextInput
                                                        name={"vendor"}
                                                        type={"text"}
                                                        value={filter.vendor}
                                                        placeholder={"Vendor"}
                                                        onChange={handleSearch}
                                                        onInput={handleDebouncedChange}
                                                        required={false}
                                                    />
                                                </div>
                                            </div>
                                        ),
                                    },
                                ]}
                            /> */}
                    </div>
                    <div className="w-full md:w-fit">
                        <Button
                            btnText={"Create PO"}
                            type={"button"}
                            onClick={() => navigate("/dashboard/purchase-orders/create")}
                            btnClassname="!py-2 !whitespace-nowrap !px-4"
                        />
                    </div>
                </div>
                {quotesList?.length > 0 ? (
                    <div>
                        <div className="lg:block hidden">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isAllSelectable={true}
                                isScrollable={false}
                                isCellBordered={false}
                                headerContainerClass="!bg-slate-100"
                                headerItemClass="font-rocGroteskMedium "
                            />
                        </div>
                        <div className="py-4 lg:hidden block">
                            <p className="text-sm font-rocGroteskMedium mb-4 text-slate-900">
                                Quote ID
                            </p>
                            {quotesList?.map((quote) => (
                                <div
                                    className="border flex justify-between items-center border-slate-100 rounded mb-1 px-[17px] py-3 font-rocGroteskMedium cursor-pointer"
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/quotes/${quote?._id}?taId=${quote?.taId}`
                                        )
                                    }
                                >
                                    <div>
                                        <p className="text-sm text-slate-900 ">
                                            Quote {quote?.taId} :{" "}
                                            {truncate(
                                                `${quote?.rfqs?.map((rfq) => rfq?.item?.description)
                                                    .join(", ")} `,
                                                {
                                                    length: 40,
                                                }
                                            )}
                                        </p>
                                        <p className="text-sm text-slate-500">
                                            {" "}
                                            {quote?.amount?.from?.amount === "0" &&
                                            quote?.amount?.to?.amount === "0" ? (
                                                <p>---</p>
                                            ) : (
                                                <>
                                                    {getCurrencyFromCurrencyCode(
                                                        quote?.amount?.from?.currency
                                                    )}
                                                    {`${quote?.amount?.from?.amount}`} -{" "}
                                                    {`${getCurrencyFromCurrencyCode(
                                                        quote?.amount?.from?.currency
                                                    )}${quote?.amount?.to?.amount}`}
                                                </>
                                            )}
                                        </p>
                                    </div>
                                    <i className="ri-arrow-right-s-line text-2xl text-slate-500"></i>
                                </div>
                            ))}
                        </div>
                        {loadMoreQuotes && fetchingQuotes && (
                            <div className="flex my-4 justify-center">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {quotePagination?.current === quotePagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                <p className="text-center"> End of list</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex flex-col !h-[calc(100vh-300px)] justify-center">
                        <div className="space-y-2  text-center">
                            <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                            <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                {filter.search ? "No quote found" : "No quote yet"}
                            </p>
                            <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                {filter.search
                                    ? "No quote found for this search query"
                                    : "Recent quotes from vendor will be displayed here"}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </PageFrame>
    );
};

export default Quotes;
