import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import VendorSelectInput from "components/common/InputField/VendorSelectInput";
import ModalContainer from "components/common/ModalContainer";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import {displayFileIcon, getCurrencyFromCurrencyCode} from "helpers";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import {orderActions} from "redux/Ldb/actions";
import {currencies} from "variables";
import {useAppDispatch, useAppSelector} from "../../../../hooks/storeHooks";

const RfqDetails = () => {
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const [rfqDetails, setRfqDetails] = useState<{[key: string]: any}>({});
    const [itemData, setItemData] = useState<{
        description: string;
        qty: number | string;
        vendor?: string[];
        _id: string;
    }>({
        description: "",
        qty: "",
        vendor: [],
        _id: "",
    });

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {rfqId} = useParams();
    const {
        fetchingSingleRfq,
        fetchedSingleRfqSuccess,
        deletingRfq,
        deleteRfqSuccess,
        updatingRfq,
        updateRfqSuccess,
    } = useAppSelector((state) => state.order);

    const handleItemChange = (name, value) => {
        setItemData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleCancel = () => {
        dispatch(orderActions.deleteRfq(rfqId));
    };

    const handleUpdateRfq = () => {
        const body = {
            itemQuantity: itemData?.qty,
            vendors: itemData.vendor,
        };

        dispatch(orderActions.updateRfq(rfqId, body));
    };

    const tableHeader = [
        {title: "Item name", widthClass: "w-[45%] max-sm:w-[30%] !px-2.5"},
        {title: "QTY", widthClass: "w-[10%] max-sm:w-[15%] !px-2.5"},
        {title: "Assign vendor", widthClass: "w-[40%] max-sm:w-[35%] !px-2.5"},
    ];

    const tableBody = [
        [
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={itemData?.description}
                            name={"description"}
                            type={"text"}
                            placeholder={"Item description"}
                            containerClassname=""
                            disabled={true}
                            inputContainerClassname={"!border-0 !rounded-none"}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full h-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={String(itemData?.qty)}
                            name={"qty"}
                            type={"number"}
                            onWheel={(e) => (e.target as any).blur()}
                            onChange={(e) => handleItemChange(e.target.name, e.target.value)}
                            placeholder={"Qty"}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none"}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`w-full flex items-center h-full border-slate-100 text-sm text-slate-700`}
                    >
                        <VendorSelectInput
                            value={itemData?.vendor?.join(", ")}
                            name="vendor"
                            placeholder={"Select vendor"}
                            className="!border-0 !pl-3"
                            handleChange={(name, value, valuesArray) =>
                                handleItemChange(name, valuesArray)
                            }
                            orderType={rfqDetails?.order?.orderType}
                        />
                    </div>
                ),
                widthClass: `w-[39.3%] absolute`,
                tableDataDivClass: "!pl-0",
            },
        ],
    ];

    useEffect(() => {
        dispatch(orderActions.getSingleRfq(rfqId));
    }, [dispatch, rfqId, updateRfqSuccess]);

    useEffect(() => {
        if (Boolean(fetchedSingleRfqSuccess)) {
            setRfqDetails(fetchedSingleRfqSuccess?.rfq);
        }
    }, [fetchedSingleRfqSuccess]);

    useEffect(() => {
        if (Boolean(deleteRfqSuccess)) {
            dispatch(orderActions.resetDeleteRfqSuccess());
            setCancelModalOpen(false);
            navigate("/dashboard/rfqs");
        }
    }, [deleteRfqSuccess, dispatch, navigate]);

    useEffect(() => {
        if (Boolean(updateRfqSuccess)) {
            setEditModalOpen(false);
            dispatch(orderActions.resetUpdateRfqSuccess());
        }
    }, [updateRfqSuccess, dispatch]);

    useEffect(() => {
        const data = {
            description: rfqDetails?.item?.description,
            qty: rfqDetails?.item?.quantity,
            _id: rfqDetails?.item?._id,
        };
        setItemData(data);
    }, [rfqDetails]);

    return (
        <PageFrame isLoading={fetchingSingleRfq}>
            <div>
                <div className="w-full h-[calc(100vh-108px)] flex max-lg:flex-col rounded-[12px] bg-white border border-slate-100">
                    <div className="w-[74%] max-lg:w-full border-r h-full overflow-y-auto max-lg:h-auto max-lg:overflow-visible border-slate-100">
                        <div className="w-full">
                            <Link
                                to={"/dashboard/rfqs"}
                                className="px-6 pt-6 flex items-center gap-1 mb-4 cursor"
                            >
                                <i className="ri-arrow-left-line mt-[-2px] text-sm"></i>
                                <p className="text-sm text-gm-50 font-rocGroteskMedium">Go back</p>
                            </Link>
                            <div className="p-6 border-b border-slate-100 w-full">
                                <p className="text-xl font-rocGroteskMedium mb-1 text-slate-700">
                                    {rfqDetails?.item?.description ?? "----"}
                                </p>
                                <div className="flex gap-3 items-center max-lg:flex-col max-lg:items-start">
                                    <p className="text-sm font-rocGroteskRegular text-slate-500">
                                        Edited:{" "}
                                        {moment(rfqDetails?.updatedAt).format("DD MMM YYYY | LT")}
                                    </p>
                                    {(!rfqDetails?.quoteResponses ||
                                        rfqDetails?.quoteResponses?.length === 0) && (
                                        <div className="px-2 py-[2px] leading-[20px] text-xs text-white font-rocGroteskMedium bg-t-blue rounded">
                                            <p>Awaiting vendor responses</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="px-8 py-6 border-b border-slate-100 hidden max-lg:block">
                                <Button
                                    btnText={"Edit RFQ"}
                                    type={"button"}
                                    btnClassname="mb-3"
                                    onClick={() => setEditModalOpen(true)}
                                />
                                <Button
                                    btnClassname="!bg-n-20 !text-gm-50"
                                    btnText={"Cancel RFQ"}
                                    onClick={() => setCancelModalOpen(true)}
                                    type={"button"}
                                />
                            </div>
                            <div className="p-6 border-b border-slate-100 w-full">
                                <div className="flex items-center gap-2">
                                    <div className="py-1 px-2 bg-slate-100 rounded">
                                        <p className="text-xs text-gm-50 font-rocGroteskMedium">
                                            Quantity: {rfqDetails?.itemQuantity}
                                        </p>
                                    </div>
                                    <div className="py-1 px-2 bg-slate-100 rounded">
                                        <p className="text-xs text-gm-50 font-rocGroteskMedium">
                                            Requested {moment(rfqDetails?.createdAt).fromNow()}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="px-6 py-4 border-b border-slate-100 w-full">
                                <div className="flex items-center gap-2.5">
                                    <i className="ri-calendar-2-line text-xl"></i>
                                    <div>
                                        <p className="text-sm text-gm-50 font-rocGroteskMedium">
                                            Created on
                                        </p>
                                        <p className="text-xs text-slate-500 font-rocGroteskRegular">
                                            {moment(rfqDetails?.createdAt).format("Do MMMM YYYY")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {rfqDetails?.item && (
                                <div className="p-6 w-full">
                                    <div className="flex items-center justify-between font-rocGroteskMedium pb-[8px]">
                                        <p className="text-base text-slate-700 w-1/2">
                                            Items/Services
                                        </p>
                                        <p className="text-base text-slate-700 w-1/2 text-right">
                                            Qty
                                        </p>
                                    </div>
                                    <div className="flex items-center justify-between font-rocGroteskRegular py-4 border-t border-slate-200">
                                        <p className="text-sm text-slate-800 w-1/2">
                                            {rfqDetails?.item?.description}
                                        </p>
                                        <p className="text-sm text-slate-800 w-1/2 text-right">
                                            {rfqDetails?.item?.quantity}
                                        </p>
                                    </div>
                                </div>
                            )}
                            <div className="p-6 w-full border-b border-slate-100">
                                <p className="text-sm font-rocGroteskMedium mb-1 text-gm-50">
                                    Desired delivery date
                                </p>
                                <p className="text-sm font-rocGroteskRegular text-slate-500">
                                    {moment(rfqDetails?.order?.deliveryDate).format("Do MMM YYYY")}
                                </p>
                            </div>
                            <div>
                                <p className="text-xl text-gm-50 font-rocGroteskMedium border-b border-slate-100 py-6 px-8">
                                    List of vendors the RFQ was sent to
                                </p>
                                <div>
                                    <div className="">
                                        {rfqDetails?.vendors?.map((vendor) => {
                                            const respondedVendor =
                                                rfqDetails?.quoteResponses?.find(
                                                    (response) =>
                                                        response?.responder?._id === vendor?._id
                                                );
                                            return (
                                                <div
                                                    key={vendor?._id}
                                                    className="py-6 px-8  border-b border-slate-100"
                                                >
                                                    <div className="flex items-center gap-3 mb-4">
                                                        <VendorAvatar
                                                            imageSrc={vendor?.profile?.avatar}
                                                            size={40}
                                                            name={vendor?.profile?.businessName}
                                                        />
                                                        <div>
                                                            <div className="flex items-center gap-1">
                                                                <p className="text-base text-slate-700 font-rocGroteskMedium">
                                                                    {vendor?.profile?.businessName}
                                                                </p>
                                                            </div>

                                                            <p className="text-sm text-slate-500 font-rocGroteskRegular">
                                                                {vendor?.email}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="flex items-center gap-2">
                                                        {respondedVendor ? (
                                                            <div className="flex items-center gap-2">
                                                                <div className="py-1 px-2 bg-slate-100 rounded">
                                                                    <p className="text-xs text-gm-50 font-rocGroteskMedium">
                                                                        Amount:{" "}
                                                                        {getCurrencyFromCurrencyCode(
                                                                            respondedVendor
                                                                                ?.quoteResponse
                                                                                ?.amount?.currency
                                                                        )}
                                                                        {
                                                                            respondedVendor
                                                                                ?.quoteResponse
                                                                                ?.amount?.amount
                                                                        }
                                                                    </p>
                                                                </div>

                                                                <div className="py-1 px-2 bg-slate-100 rounded">
                                                                    <p className="text-xs text-gm-50 font-rocGroteskMedium">
                                                                        Responded:{" "}
                                                                        {moment(
                                                                            respondedVendor
                                                                                ?.quoteResponse
                                                                                ?.createdAt
                                                                        ).fromNow()}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="py-1 px-2 bg-[#F0FDF4] rounded">
                                                                <p className="text-xs text-gm-50 font-rocGroteskMedium">
                                                                    Awaiting vendor response
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[26%] max-lg:w-full h-full relative">
                        <div className="px-8 py-6 border-b border-slate-100 sticky max-lg:hidden">
                            <Button
                                btnText={"Edit RFQ"}
                                type={"button"}
                                btnClassname="mb-3"
                                onClick={() => setEditModalOpen(true)}
                            />
                            <Button
                                btnClassname="!bg-n-20 !text-gm-50"
                                btnText={"Cancel RFQ"}
                                onClick={() => setCancelModalOpen(true)}
                                type={"button"}
                            />
                        </div>
                        <div className="h-[calc(100%-150px)] overflow-y-auto max-lg:h-auto max-lg:overflow-visible">
                            <div className="px-8 py-6 border-b border-slate-100 ">
                                <p className="text-base text-gm-50 font-rocGroteskMedium mb-6">
                                    Customer details
                                </p>
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <p className="text-sm text-gm-50 font-rocGroteskMedium mb-1">
                                            Company
                                        </p>
                                        <p className="text-sm text-slate-500 font-rocGroteskRegular ">
                                            {rfqDetails?.order?.customerDetails?.companyName ||
                                                "N/A"}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gm-50 font-rocGroteskMedium mb-1">
                                            First name
                                        </p>
                                        <p className="text-sm text-slate-500 font-rocGroteskRegular ">
                                            {rfqDetails?.order?.customerDetails?.firstName || "N/A"}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gm-50 font-rocGroteskMedium mb-1">
                                            Last name
                                        </p>
                                        <p className="text-sm text-slate-500 font-rocGroteskRegular ">
                                            {rfqDetails?.order?.customerDetails?.lastName || "N/A"}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gm-50 font-rocGroteskMedium mb-1">
                                            Email address
                                        </p>
                                        <p className="text-sm text-slate-500 font-rocGroteskRegular ">
                                            {rfqDetails?.order?.customerDetails?.email || "N/A"}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gm-50 font-rocGroteskMedium mb-1">
                                            Phone
                                        </p>
                                        <p className="text-sm text-slate-500 font-rocGroteskRegular ">
                                            {rfqDetails?.order?.customerDetails?.phone?.mobile ||
                                                "N/A"}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gm-50 font-rocGroteskMedium mb-1">
                                            Country
                                        </p>
                                        <p className="text-sm text-slate-500 font-rocGroteskRegular ">
                                            {rfqDetails?.order?.customerDetails?.country || "N/A"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="px-8 py-6 ">
                                <p className="text-base text-gm-50 font-rocGroteskMedium mb-6">
                                    Documents
                                </p>
                                <div>
                                    {rfqDetails?.documents ? (
                                        <div>
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center gap-3">
                                                    {displayFileIcon("pdf", false)}
                                                    <div>
                                                        <p className="text-sm font-rocGroteskMedium">
                                                            Quotation for Electric...
                                                        </p>
                                                        <p className="text-sm text-slate-500 font-rocGroteskMedium">
                                                            4 MB{" "}
                                                        </p>
                                                    </div>
                                                </div>
                                                <i className="ri-download-line text-xl"></i>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex flex-col items-center">
                                            <i className="ri-file-line mb-4 text-3xl"></i>
                                            <p className="text-sm font-rocGroteskMedium text-center mb-1">
                                                No documents found
                                            </p>
                                            <p className="text-sm font-rocGroteskMedium text-center text-slate-500">
                                                It seems like there are no documents found in this
                                                order.
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalContainer
                    open={editModalOpen}
                    showCloseIcon={false}
                    tailwindClassName="w-[68%] max-lg:w-[95%]"
                    closeModal={() => {
                        setEditModalOpen(false);
                    }}
                >
                    <div className="bg-white shadow-cardShadow relative rounded-[6px]">
                        <div className="px-8 max-lg:px-4 py-6 flex items-center justify-between border-b border-slate-100">
                            <div className=" w-full">
                                <p className="text-base font-rocGroteskMedium text-slate-700">
                                    Edit RFQ details
                                </p>
                            </div>
                            <i
                                className="ri-close-fill text-xl cursor-pointer"
                                onClick={() => {
                                    setEditModalOpen(false);
                                }}
                            ></i>
                        </div>
                        <div className="min-h-[370px] max-h-[450px] p-8 max-lg:p-4 overflow-auto scrollbar-hide">
                            <div className="grid grid-cols-3 gap-4 mb-6 max-lg:grid-cols-1">
                                <TextInput
                                    value={rfqDetails?.taId}
                                    name={"rfqId"}
                                    type={"text"}
                                    placeholder={"RFQ ID"}
                                    disabled={true}
                                    containerClassname=""
                                />

                                <div className="w-full grid grid-cols-[115px_calc(100%-119px)] gap-1 ">
                                    <SelectInput
                                        value={rfqDetails?.order?.budget?.currency}
                                        name="currency"
                                        placeholder={"($) USD"}
                                        disabled={true}
                                        isRequired={false}
                                        dropdownOptions={currencies}
                                    />
                                    <TextInput
                                        value={rfqDetails?.order?.budget?.amount}
                                        name={"estBudget"}
                                        type={"text"}
                                        disabled={true}
                                        // onChange={(e) => onChangeEmail(e.target.value)}
                                        placeholder={"Est. Budget"}
                                        containerClassname=""
                                    />
                                </div>
                                <SelectInput
                                    value={rfqDetails?.order?.orderType}
                                    name="typeOfOrder"
                                    placeholder={"Type of Order"}
                                    disabled={true}
                                    className=""
                                    // handleChange={(_, value) => onChangeCategory(value)}
                                    dropdownOptions={[
                                        {
                                            label: "Supply",
                                            value: "supply",
                                        },
                                        {
                                            label: "Logistics",
                                            value: "logistics",
                                        },
                                    ]}
                                />
                            </div>
                            <div>
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isAllSelectable={false}
                                    headerContainerClass="!bg-slate-50"
                                    tableClass=" w-full"
                                    isScrollable={false}
                                    isCellBordered={true}
                                />
                            </div>
                        </div>
                        <div className="flex w-full justify-end gap-3 py-4 px-6 border-t border-slate-100">
                            <Button
                                btnClassname="!bg-n-20 !text-gm-50 !w-[150px]"
                                btnText={"Close"}
                                onClick={() => setEditModalOpen(false)}
                                type={"button"}
                            />
                            <Button
                                btnText={"Save changes"}
                                type={"button"}
                                btnClassname="!w-[150px]"
                                isLoading={updatingRfq}
                                onClick={handleUpdateRfq}
                            />
                        </div>
                    </div>
                </ModalContainer>

                <ModalContainer
                    open={cancelModalOpen}
                    showCloseIcon={false}
                    closeModal={() => setCancelModalOpen(false)}
                    tailwindClassName="w-[36%] max-md:w-[95%] max-lg:w-[75%]"
                >
                    <div className="bg-white flex flex-col rounded-[20px]  ">
                        <div
                            onClick={() => setCancelModalOpen(false)}
                            className="cursor-pointer mt-4 px-6 flex justify-end "
                        >
                            <i className="ri-close-fill text-gm-45 text-[30px]"></i>
                        </div>
                        <div className="px-14 my-7 max-lg:px-7">
                            <div>
                                <h6 className="text-center font-rocGroteskMedium text-[16px]">
                                    Are you sure you want to Cancel this Request For Quote (RFQ)?
                                </h6>
                            </div>
                            <div className="mt-5 space-y-4">
                                <Button
                                    btnText={"Yes, cancel RFQ"}
                                    type={"button"}
                                    btnClassname="!py-3 !bg-[#FFEFEC] border border-r-50 !text-dark-gray-2 !px-4"
                                    onClick={handleCancel}
                                    isLoading={deletingRfq}
                                />
                                <Button
                                    btnText={"No, close"}
                                    type={"button"}
                                    btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                    onClick={() => setCancelModalOpen(false)}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            </div>
        </PageFrame>
    );
};

export default RfqDetails;
