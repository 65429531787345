import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./styles.css";

const CustomCalendar = ({ showDoubleView, value, onChange }) => {
    const [activeStartDate, setActiveStartDate] = useState();
    return (
        <div>
            <Calendar
                showDoubleView={showDoubleView}
                calendarType="gregory"
                onChange={onChange}
                selectRange={showDoubleView}
                value={value}
                showNeighboringMonth={false}
                goToRangeStartOnSelect={false}
                activeStartDate={activeStartDate}
                onActiveStartDateChange={(data) => {
                    if (data.action !== "onChange") {
                        setActiveStartDate(data.activeStartDate);
                    }
                }}
            />
        </div>
    );
};

export default CustomCalendar;