import Button from "components/common/Button";
import CustomMobileTable from "components/common/CustomMobileTable";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { formatMoney } from "helpers";
import useGetExpirationTrackingList from "hooks/inventoryHooks/useGetExpirationTrackingList";
import useGetAllWarehouses from "hooks/useGetAllWarehouses";
import { truncate } from "lodash";
import moment from "moment";
import React from "react";

const ExpirationTracking = () => {
    const {
        data: allWarehouses,
        warehouseValue,
        search: warehouseSearch,
        handleDebouncedChange: debounceChange,
        handleSearch: handleWarehouseSearch,
        handleFilterChange: handleWarehouseFilterChange,
        handleClearFilter: clearWarehouseFilter,
    } = useGetAllWarehouses();

    const {
        data: expirationData,
        isFetching,
        search,
        handleSearch,
        handleDebouncedChange,
        isLoadingMore,
        pagination,
        setUpdatedPage,
    } = useGetExpirationTrackingList(warehouseValue?.value);

    const tableHeader = [
        { title: "Product name", widthClass: "w-[37.7%]" },
        { title: "Expiry date", widthClass: "w-[16.5%]" },
        { title: "Warehouse", widthClass: "w-[17.2%]" },
        { title: "Current stock levels", widthClass: "w-[11.4%]" },
        { title: "Last supplier", widthClass: "w-[17.2%]" },
    ];

    const tableBody = expirationData?.map((product, idx) => {
        const differenceInDays = moment(product?.productViabilityDetails?.expiryDate).diff(
            moment(),
            "days"
        );

        return [
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            <VendorAvatar
                                imageSrc={product?.productImageDetails?.productAvatar}
                                size={32}
                                name={product?.productName}
                                containerClassname="!rounded-md"
                            />
                            <div>
                                <span className="block text-sm">
                                    {truncate(product?.productName, {
                                        length: 40,
                                    })}
                                </span>
                                <span className="text-sm font-rocGroteskMedium text-slate-500">
                                    SKU: {product?.sku}
                                </span>
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <div>
                            <span className="block text-sm">
                                {moment(product?.productViabilityDetails?.expiryDate).format(
                                    "MMM DD, YYYY • hh:mm:ss A"
                                )}
                            </span>
                            <span
                                className={`text-sm font-rocGroteskMedium  ${
                                    differenceInDays > 10 ? "text-slate-500" : "text-[#E4281D]"
                                }`}
                            >
                                {differenceInDays} day(s) left
                            </span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>{truncate(product?.warehouse?.name, { length: 35 })}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {formatMoney().format(product?.productStockDetails?.stockLevel)}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-2">
                            <VendorAvatar
                                imageSrc={product?.vendor?.profile?.logo}
                                size={32}
                                name={product?.vendor?.profile?.businessName}
                                containerClassname="!rounded-md"
                                textClassname="!text-[10px]"
                            />
                            <span>
                                {truncate(product?.vendor?.profile?.businessName, { length: 20 })}
                            </span>
                        </div>
                    </div>
                ),
            },
        ];
    });

    const mobileTableBody = expirationData?.map((product) => {
        const differenceInDays = moment(product?.productViabilityDetails?.expiryDate).diff(
            moment(),
            "days"
        );

        return {
            topString: product?._id,
            avatar: (
                <VendorAvatar
                    imageSrc={product?.productImageDetails?.productAvatar}
                    size={32}
                    name={product?.productName}
                    containerClassname="!rounded-md"
                />
            ),
            topContent: (
                <div>
                    <div className="flex items-center text-sm font-rocGroteskMedium space-x-1">
                        <span>{truncate(product?.productName, { length: 12 })}</span>
                        <span className="text-slate-500">|</span>
                        <span className="text-slate-500">SKU: {product?.sku}</span>
                    </div>
                    <div className="flex items-center text-sm font-rocGroteskMedium space-x-1">
                        <span className="text-sm">
                            {moment(product?.productViabilityDetails?.expiryDate).format(
                                "MMM DD, YYYY"
                            )}
                        </span>
                        <span className="text-slate-500">|</span>
                        <span
                            className={`text-sm font-rocGroteskMedium  ${
                                differenceInDays > 10 ? "text-slate-500" : "text-[#E4281D]"
                            }`}
                        >
                            {differenceInDays} day(s) left
                        </span>
                    </div>
                </div>
            ),
            bottomContent: (
                <div className="flex items-center text-sm font-rocGroteskMedium space-x-1">
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>{truncate(product?.warehouse?.name, { length: 15 })}</span>
                    </div>
                    <span className="text-slate-500">|</span>
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            curr_stock:{" "}
                            {formatMoney().format(product?.productStockDetails?.stockLevel)}
                        </span>
                    </div>
                </div>
            ),
        };
    });

    const expirationAlertTableHeader = [
        { title: "Product name", widthClass: "w-[82%]" },
        { title: "Expiry date", widthClass: "w-[16%]" },
    ];

    const expirationAlertTableBody = [1, 2, 3, 4, 5]?.map((po, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center space-x-4">
                        <div className="w-12 h-12 rounded border border-slate-200 flex items-center justify-center">
                            <VendorAvatar
                                imageSrc={""}
                                size={43}
                                name={"Intercom"}
                                containerClassname="!rounded-md"
                            />
                        </div>

                        <div>
                            <span className="block text-g-75 text-sm font-rocGroteskMedium">
                                {truncate(
                                    "White Claw Hard Seltzer Variety No. 1 tropical Pineapple",
                                    {
                                        length: 45,
                                    }
                                )}
                            </span>
                            <span className="block text-slate-500 text-sm font-rocGroteskMedium">
                                Current Qty: 324
                            </span>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-right text-slate-800 `}
                >
                    <div>
                        <span className="block text-g-75 text-[13px] font-rocGroteskMedium ">
                            13 Jan, 2022
                        </span>
                        <span className="block text-r-50 text-[13px] font-rocGroteskMedium">
                            4 days left
                        </span>
                    </div>
                </div>
            ),
        },
    ]);

    return (
        <PageFrame isLoading={isFetching && !isLoadingMore}>
            <div className="flex items-center space-x-2 mb-6 max-sm:flex-col max-sm:space-x-0 max-sm:space-y-2">
                <TextInput
                    name={"search"}
                    value={search}
                    type={"text"}
                    inputPlaceholder={"Search"}
                    inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                    onChange={handleSearch}
                    onInput={handleDebouncedChange}
                    leftIcon={
                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                    }
                    inputContainerClassname={
                        "!rounded-[4px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white shadow-boxShadow-2 !border !border-slate-100"
                    }
                />

                <Dropdown
                    handleChange={(name, value) => {
                        console.log("first", name, value);
                    }}
                    dropdown={
                        <div className="flex items-center justify-between whitespace-nowrap h-[40px]  py-2 px-4 space-x-1 rounded-[5px] border border-slate-100">
                            <div className="flex items-center space-x-1">
                                {/* <i className="ri-map-pin-line text-sm text-g-75 "></i> */}
                                <span className={`text-g-75 font-rocGroteskMedium text-[13px]`}>
                                    {truncate(warehouseValue?.name, { length: 16 }) ||
                                        "All locations"}
                                </span>
                            </div>
                            <div className="flex items-center space-x-1">
                                {warehouseValue?.name && (
                                    <i
                                        onClick={clearWarehouseFilter}
                                        className="ri-close-circle-line text-slate-500"
                                    ></i>
                                )}
                                <i className="ri-arrow-down-s-line text-g-75"></i>
                            </div>
                        </div>
                    }
                    search={
                        <div>
                            <TextInput
                                value={warehouseSearch}
                                name={"search"}
                                type={"text"}
                                required={false}
                                onChange={handleWarehouseSearch}
                                onInput={debounceChange}
                                inputPlaceholder="Search warehouse"
                                containerClassname=""
                                // inputContainerClassname={"!border-none !rounded-none "}
                                inputClassName="w-full"
                            />
                        </div>
                    }
                    // dropdownClassName=""
                    wholeContainerClass="!w-[25%] max-sm:!w-full"
                    dropdownContainerClasses={`shadow-cardShadow-4 !w-full border border-slate-100 left-[-88px] rounded !max-h-fit`}
                    dropdownOptions={[
                        {
                            customChild: (
                                <div>
                                    <div className="max-h-[280px]">
                                        {allWarehouses?.map((warehouse) => {
                                            return (
                                                <div
                                                    key={warehouse?._id}
                                                    onClick={() => {
                                                        setUpdatedPage(1);
                                                        handleWarehouseFilterChange(
                                                            warehouse?.warehouseName,
                                                            warehouse?._id
                                                        );
                                                    }}
                                                >
                                                    <div
                                                        className={`flex items-center space-x-3 px-3 py-2 hover:bg-slate-100 cursor-pointer ${
                                                            warehouseValue?.value ===
                                                                warehouse?._id && "bg-slate-100"
                                                        }`}
                                                    >
                                                        <i className="ri-map-pin-line text-base text-g-75 "></i>
                                                        <div>
                                                            <span
                                                                className={`block text-g-75 text-sm font-rocGroteskMedium ${
                                                                    warehouse?.address?.address &&
                                                                    "mb-[2px]"
                                                                }`}
                                                            >
                                                                {truncate(
                                                                    warehouse?.warehouseName,
                                                                    { length: 40 }
                                                                )}
                                                            </span>
                                                            <span className="block text-g-75 text-sm font-rocGroteskMedium ">
                                                                {truncate(
                                                                    warehouse?.address?.address,
                                                                    { length: 40 }
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ),
                        },
                    ]}
                />

                {/* <Button
                    btnText="Settings"
                    btnClassname="!bg-white shadow-boxShadow-2 !border !border-slate-100 !w-fit !h-10 !text-[13px]"
                    icon={<i className="ri-settings-5-line text-base"></i>}
                /> */}
            </div>

            {expirationData?.length > 0 ? (
                <div>
                    <div className="max-lg:hidden">
                        <CustomTable
                            tableBody={tableBody}
                            tableHeader={tableHeader}
                            isScrollable={false}
                            isCellBordered={false}
                            isCellSelectable={false}
                            isAllSelectable={false}
                            headerContainerClass="!bg-slate-50"
                            headerItemClass="font-rocGroteskMedium "
                            tableClass="mb-6"
                        />
                    </div>

                    <div className="hidden max-lg:block">
                        <CustomMobileTable data={mobileTableBody} />
                    </div>

                    {isLoadingMore && (
                        <div className="flex my-4 justify-center">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}
                    {pagination?.currentPage === pagination?.noOfPages && (
                        <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                            End of list
                        </div>
                    )}
                </div>
            ) : (
                <div className="flex items-center justify-center my-10">
                    <span className="font-rocGroteskMedium text-xl text-center">
                        No data available
                    </span>
                </div>
            )}

            <ModalContainer
                open={false}
                showCloseIcon={false}
                tailwindClassName="w-[48.6%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    // setOpenSyncedModal(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative overflow-y-auto overflow-x-hidden max-h-[600px]">
                    <div className=" pt-3">
                        <div className="flex flex-col items-center text-center px-10">
                            <div className="flex w-full justify-end mr-[-12px]">
                                <i
                                    // onClick={() => setOpenSyncedModal(false)}
                                    className="ri-close-fill text-2xl text-[#3B4C58] absolute"
                                ></i>
                            </div>
                            <div className="mb-4 mt-10 relative">
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1697712771/Caution_sign_g1u9nv.svg"
                                    alt="caution"
                                    className="w-[56px] h-[49.5px] "
                                />
                            </div>
                            <div className="text-center px-8">
                                <p className="text-xl mb-1 text-center  font-rocGroteskMedium text-g-75">
                                    Urgent: Expiry Alert for Inventory Products
                                </p>
                                <p className="text-sm text-center font-rocGroteskMedium text-slate-500">
                                    We've detected that some of your products are approaching their
                                    expiration dates. To ensure optimal inventory, please review the
                                    following items:
                                </p>
                            </div>
                        </div>

                        <div className="w-full py-6">
                            <CustomTable
                                tableBody={expirationAlertTableBody}
                                tableHeader={expirationAlertTableHeader}
                                isScrollable={false}
                                // isScrolled={isScrolled}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-3"
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default ExpirationTracking;
