export const calculateCommentDate = (commentDate: string | Date) => {
    const currentDate = new Date();
    const commentDateTime = new Date(commentDate);
    const timeDifference = currentDate.valueOf() - commentDateTime.valueOf();

    const millisecondsInDay = 24 * 60 * 60 * 1000;
    const millisecondsInWeek = 7 * millisecondsInDay;
    if (timeDifference < millisecondsInDay) {
        const date = new Date(commentDate);
        const options = { hour: "numeric", minute: "numeric", hour12: true } as const;
        const formattedTime = new Intl.DateTimeFormat("en-US", options).format(date);
        return formattedTime;
    } else if (timeDifference < millisecondsInWeek) {
        const daysDifference = Math.floor(timeDifference / millisecondsInDay);
        return `${daysDifference} ${daysDifference > 1 ? "days" : "day"} ago`;
    } else {
        const weeksDifference = Math.floor(timeDifference / millisecondsInWeek);
        return `${weeksDifference} ${weeksDifference > 1 ? "weeks" : "week"} ago`;
    }
};

export function calculateRemainingTime(dueDate: string | Date) {
    const dueDateObj = new Date(dueDate);
    const today = new Date();

    const timeDiff = dueDateObj.valueOf() - today.valueOf();

    const daysRemaining = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    const minutesRemaining = Math.floor((timeDiff / (1000 * 60)) % (60 * 24));

    if (daysRemaining > 0 && daysRemaining <= 3 && minutesRemaining > 60) {
        return `${daysRemaining} day(s) remaining`;
    } 
    else if (daysRemaining === 0 && minutesRemaining > 60) {
        return `${minutesRemaining} minute(s) remaining`;
    } else {
        return "";
    }
}
