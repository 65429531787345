import Button from "components/common/Button";
import React, { useEffect, useRef, useState } from "react";
import TextInput from "../../../../../common/InputField/TextInput";
import { displayFileIcon } from "helpers";
import CustomCalendar from "components/common/CalendarComp.js/Calendar";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import ModalContainer from "components/common/ModalContainer";
import SingleProduct from "components/views/Synth2.0/Inventory/SingleProduct";
import ExcelUpload from "components/views/Ldb/Dashboard/Inventory/AddProductMethods/ExcelUpload";
import { ProductDataType } from "types/productDataType";
import { authActions, customFieldActions, inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import SelectInput from "components/common/InputField/SelectInput";
import AddressInput from "components/common/InputField/AddressInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import { isFileSizeLessThanOrEqualTo } from "helpers/compareFileSizeInMb";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import { firebaseService } from "services/firebaseService";
import useGetUserProfile from "hooks/useGetUserProfile";
import Loader from "components/common/Loader";
import { set, truncate } from "lodash";
import VendorAvatar from "components/common/VendorAvatar";
import CustomFields from "components/common/CustomFields";
import CustomFieldsImplement from "components/common/CustomFieldsImplement";
import useGetProductDetails from "hooks/inventoryHooks/useGetProductDetails";

const AddProduct = () => {
    const [profile] = useGetUserProfile();
    const [deleteImageModal, setDeleteImageModal] = useState<boolean>(false);
    const [deleteAttachmentModal, setDeleteAttachmentModal] = useState<boolean>(false);
    const [addWarehouseModal, setAddWarehouseModal] = useState<boolean>(false);
    const [uploadingFiles, setUploadingFiles] = useState<boolean>(false);
    const [uploadingAttachments, setUploadingAttachments] = useState<boolean>(false);
    const [deletingImage, setDeletingImage] = useState<boolean>(false);
    const [deletingAttachment, setDeletingAttachment] = useState<boolean>(false);
    const [skipDuplicates, setSkipDuplicates] = useState<boolean>(true);
    const [selectedRow, setSelectedRow] = useState<number | null>(null);
    const [address, setAddress] = useState<{ [key: string]: any }>({});
    const [teamList, setTeamList] = useState<{ [key: string]: any }[]>([]);
    const [addProductMethod, setAddProductMethod] = useState<string>("single");
    const [productImages, setProductImages] = useState<string[]>([]);
    const [productImage, setProductImage] = useState<string>("");
    const [imagesToRemove, setImagesToRemove] = useState<string[]>([]);
    const [attachments, setAttachments] = useState<{ filename: string; path: string }[]>([]);
    const [selectedFilePath, setSelectedFilePath] = useState<string>("");
    const [billOfMaterialIds, setBillOfMaterialIds] = useState<{ [key: string]: any }[]>([]);
    const [savedData, setSavedData] = useState<boolean>(false);
    const [addWarehouseData, setAddWarehouseData] = useState<{
        contactPerson: string;
        note: string;
        name: string;
    }>({
        contactPerson: "",
        note: "",
        name: "",
    });
    const [productData, setProductData] = useState<ProductDataType>({
        productName: "",
        inventoryCategory: "",
        vendors: [],
        unitOfMeasurement: "",
        selectAttribute: "",
        attributeValue: "",
        description: "",
        warehouse: "",
        minimumStockLevel: "",
        maximumStockLevel: "",
        bufferStockLevel: "",
        isProductPerishable: false,
        currency: "",
        price: "",
        productSource: "",
        salesPriceCurrency: "USD",
        costPriceCurrency: "USD",
        retailSalePriceCurrency: "USD",
    });
    const [productDataArr, setProductDataArr] = useState<ProductDataType[]>([]);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { productId } = useParams();

    //custom fields variables starts here
    const [customFieldArray, setCustomFieldArray] = useState<any>([]);
    const [fieldType, setFieldType] = useState<string>("text");
    const [fieldName, setFieldName] = useState<string>("");
    const [editCustomFieldIdx, setEditCustomFieldIdx] = useState<number>();
    const [recurringSetting, setRecurringSetting] = useState<boolean>(false);
    const [multipleValuesField, setMultipleValuesField] = useState<any>({
        value: [
            {
                selected: true,
                value: "",
            },
        ],
    });
    const [customAttachments, setCustomAttachments] = useState<any>({
        value: [],
    });
    const [singleValueField, setSingleValueField] = useState<any>();
    const [customModal, setCustomModal] = useState<boolean>(false);
    const [savedRecurringFieldId, setSavedRecurringFieldId] = useState<string>("");
    const [deleteCustomFieldModal, setDeleteCustomFieldModal] = useState<boolean>(false);
    const [deleteIdx, setDeleteIdx] = useState<number>();
    const [recurringFields, setRecurringFields] = useState<any>();
    const customFieldsType = "";

    //custom fields variables ends here
    const {
        updatingSingleProduct,
        updatedSingleProductSuccess,
        addingSingleProduct,
        addSingleProductSuccess,
        createWarehouse,
        createWarehouseSuccess,
        addingMultipleProducts,
        addMultipleProductsSuccess,
    } = useAppSelector((state) => state.inventory);

    const {
        fetchingCustomField,
        fetchCustomFieldSuccess,
        deletingCustomField,
        deleteCustomFieldSuccess,
    } = useAppSelector((state) => state.customField);

    const { data } = useGetProductDetails();

    const { fetchingTeamMember, fetchedTeamMemberSuccess } = useAppSelector((state) => state.auth);

    const handleChange = (name: string, value: any) => {
        setProductData((prev) => ({ ...prev, [name]: value }));
    };
    const updateProductStockSettings = (settings, data) => {
        const updatedSettings = { ...settings };
        if (data.bufferStockLevel) updatedSettings.bufferStock = data.bufferStockLevel;
        if (data.minimumStockLevel) updatedSettings.minimumStockLevel = data.minimumStockLevel;
        if (data.maximumStockLevel) updatedSettings.maximumStockLevel = data.maximumStockLevel;
        if (data.warehouse) {
            updatedSettings.warehouseId = data.warehouse;
            updatedSettings.bufferStock = data.bufferStockLevel || 0;
            updatedSettings.minimumStockLevel = data.minimumStockLevel || 0;
            updatedSettings.maximumStockLevel = data.maximumStockLevel || 0;
        }
        return updatedSettings;
    };
    const handleSaveProduct = () => {
        const body: { [key: string]: any } = {
            productName: productData.productName,
            inventoryCategory: productData.inventoryCategory,
            vendorIds: productData.vendors,
            productStockDetails: {
                unitOfMeasurement: productData.unitOfMeasurement,
                // "stockLevel": 14000
            },
            productStockSettings: [
                {
                    bufferStock: productData.bufferStockLevel || null,
                    minimumStockLevel: productData.minimumStockLevel || null,
                    maximumStockLevel: productData.maximumStockLevel || null,
                    warehouseId: productData.warehouse || null,
                },
            ],
            isProductPerishable: productData.isProductPerishable,
            productSource: productData?.productSource,
            billOfMaterialIds,
            salesPrice: {
                amount: productData?.salesPriceAmount,
                currency: productData?.salesPriceCurrency,
            },

            // retailSalePrice: {
            //     amount: productData?.retailSalePriceAmount,
            //     currency: productData?.retailSalePriceCurrency,
            // },
            upc: productData?.upc,
            attachments,
        };

        // const customObj = Object.fromEntries(
        //     customFieldArray.map((item) => [item.name, item.value])
        // );
        if (productData?.costPriceAmount) {
            body.unitCost = {
                amount: productData?.costPriceAmount,
                currency: productData?.costPriceCurrency,
            };
        }
        if (productData.description) {
            body.productDescription = productData.description;
        }
        if (productImages?.length > 0) {
            body.productImageDetails = {
                productImages: productImages?.slice(0, 5)?.map((img) => img),
                productAvatar: productImage || productImages[0],
            };
        }

        // if (customFieldArray?.length > 0) {
        //     body.customFields = { ...body.customFields, ...customObj };
        // }

        if (productData.selectAttribute && productData.attributeValue) {
            body.customFields = {
                ...body.customFields,
                [productData.selectAttribute]: productData.attributeValue,
            };
        }
        if (productData.warehouse) {
            body.productStockSettings[0] = updateProductStockSettings(
                body.productStockSettings?.[0],
                productData
            );
        } else {
            body.productStockSettings = [];
        }

        if (productId) {
            dispatch(inventoryActions.updateSingleProduct(body, productId));
        } else {
            dispatch(inventoryActions.addSingleProduct(body));
        }
    };

    const handleSaveMultipleProducts = () => {
        const data = productDataArr.map((productData) => {
            const updateProductStockSettings = (settings, data) => {};
            const body: { [key: string]: any } = {
                productName: productData.productName,
                inventoryCategory: productData.inventoryCategory,
                vendorIds: productData.vendors,
                unitOfMeasurement: productData.unitOfMeasurement,
                // productStockDetails: {
                //     unitOfMeasurement: productData.unitOfMeasurement,
                //     // "stockLevel": 14000
                // },
                productStockSettings: [
                    {
                        bufferStock: productData.bufferStockLevel || null,
                        minimumStockLevel: productData.minimumStockLevel || null,
                        maximumStockLevel: productData.maximumStockLevel || null,
                        warehouseId: productData.warehouse || null,
                    },
                ],
                isProductPerishable: productData.isProductPerishable,
                productDescription: productData.description,
            };

            if (productData?.productImages?.length > 0) {
                body.images = productData?.productImages?.slice(0, 5)?.map((img) => img);
            }

            if (productData.selectAttribute && productData.attributeValue) {
                body.customFields = {
                    [productData.selectAttribute]: productData.attributeValue,
                };
            }

            return body;
        });

        dispatch(
            inventoryActions.addMultipleProducts({ products: data, skipDuplicates: skipDuplicates })
        );
    };

    const handleUpload = (files: File[]) => {
        if (files.length > 0) {
            const filePaths = files?.map((file) => {
                return `synthally/${process.env.REACT_APP_STAGE ?? "staging"}/inventory/${
                    profile?.profile?.businessName
                }/${file?.name}`;
            });
            setUploadingFiles(true);
            firebaseService.uploadMultipleFiles(files, filePaths, (urlArr) => {
                // setUploadedUrls(urlArr);

                setProductImage(urlArr[0]);
                if (Boolean(selectedRow)) {
                    handleMultipleProductChange("productImages", urlArr, selectedRow - 1);
                }

                setProductImages((prev) => (prev?.length > 0 ? [...prev, ...urlArr] : [...urlArr]));
                setUploadingFiles(false);
            });
        }
    };

    const handleUploadAttachments = (files: File[]) => {
        if (files.length > 0) {
            const filePaths = files?.map((file) => {
                return `synthally/${process.env.REACT_APP_STAGE ?? "staging"}/inventory/${
                    profile?.profile?.businessName
                }/${file?.name}`;
            });

            setUploadingAttachments(true);
            firebaseService.uploadMultipleFiles(files, filePaths, (urlArray) => {
                // setUploadedUrls(urlArr);
                setAttachments((prev) => [
                    ...prev,
                    ...urlArray?.map((url, idx) => ({
                        filename: filePaths?.[idx]?.split("/")?.slice(-1)?.[0],
                        path: url,
                        documentType: "Add Product",
                    })),
                ]);
                setUploadingAttachments(false);
            });
        }
    };

    const handleRemoveImage = () => {
        const imagePath = decodeURIComponent(productImage?.split("/o/")?.[1]?.split("?alt")?.[0]);

        setDeletingImage(true);
        setImagesToRemove((prev) => [...prev, imagePath]);

        const remainingImages = productImages.filter((img) => img !== productImage);

        setProductImage(remainingImages?.[0] || "");
        setProductImages(remainingImages);
        setDeletingImage(false);
        setDeleteImageModal(false);
    };

    const handleRemoveMultipleImage = () => {
        setDeletingImage(true);

        firebaseService.removeMultipleFiles(imagesToRemove, () => {
            console.log("removed");
        });
    };

    const handleRemoveAttachment = (filePath: string) => {
        const imagePath = decodeURIComponent(filePath?.split("/o/")?.[1]?.split("?alt")?.[0]);

        setDeletingAttachment(true);

        firebaseService.removeFile(imagePath, () => {
            const remainingAttachments = attachments.filter(
                (attachment) => attachment?.path !== filePath
            );

            setAttachments(remainingAttachments);
            toast.custom((t) => (
                <CustomToast t={t} message={"Attachment deleted successfully"} type={"success"} />
            ));
            setDeletingAttachment(false);
            setDeleteAttachmentModal(false);
        });
    };

    const handleInputUploadAction = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = Object.values(e.target.files)?.slice(0, 5 - (productImages.length || 0));
        const filesToUpload = [];
        const namesOfFilesNotToUpload = [];

        for (const file of files) {
            const isUploadable = isFileSizeLessThanOrEqualTo(file, 5); // Convert bytes to megabytes

            if (isUploadable) {
                filesToUpload.push(file);
            } else {
                namesOfFilesNotToUpload.push(file?.name);
            }
        }

        if (namesOfFilesNotToUpload.length > 0) {
            toast.custom((t) => (
                <CustomToast
                    t={t}
                    message={`The following file(s) are too large: ${namesOfFilesNotToUpload.join(
                        ", "
                    )}`}
                    type="error"
                />
            ));
        } else {
            handleUpload(filesToUpload);
        }
    };

    const handleAddNewWarehouse = () => {
        const body = {
            address: address?.location,
            contactPersonId: addWarehouseData.contactPerson,
            warehouseName: addWarehouseData.name,
            additionalNotes: addWarehouseData.note,
        };

        dispatch(inventoryActions.createWarehouse(body));
    };

    function handleMultipleProductChange(name: string, value: any, idx: number) {
        const newArr = [...productDataArr];
        newArr[idx][name] = value;
        setProductDataArr(newArr);
    }

    const handleDeleteRow = (idx: number) => {
        const newArr = [...productDataArr];
        newArr.splice(idx, 1);
        setProductDataArr(newArr);
    };

    const handleAddNewRow = () => {
        const newArr = [...productDataArr];
        newArr.push({
            productName: "",
            inventoryCategory: "",
            vendors: [],
            unitOfMeasurement: "",
            selectAttribute: "",
            attributeValue: "",
            description: "",
            warehouse: "",
            minimumStockLevel: "",
            maximumStockLevel: "",
            bufferStockLevel: "",
            isProductPerishable: false,
        });
        setProductDataArr(newArr);
    };

    useEffect(() => {
        if (addSingleProductSuccess) {
            navigate("/dashboard/inventory");

            dispatch(inventoryActions.resetAddSingleProductSuccess());
        }
    }, [dispatch, addSingleProductSuccess]);

    useEffect(() => {
        if (updatedSingleProductSuccess) {
            navigate(-1);

            dispatch(inventoryActions.resetUpdateSingleProductSuccess());
        }
    }, [dispatch, updatedSingleProductSuccess]);

    useEffect(() => {
        if (addMultipleProductsSuccess) {
            navigate("/dashboard/inventory");
            dispatch(inventoryActions.resetAddMultipleProductsSuccess());
        }
    }, [dispatch, addMultipleProductsSuccess]);

    useEffect(() => {
        dispatch(authActions.getTeamMembers(100));
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamList(fetchedTeamMemberSuccess?.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        if (createWarehouseSuccess) {
            setAddWarehouseModal(false);
            setAddWarehouseData({
                contactPerson: "",
                note: "",
                name: "",
            });
            dispatch(inventoryActions.resetCreateWarehouseSuccess());
        }
    }, [createWarehouseSuccess]);

    //custom fields starts here
    const handleDeleteFromCustomFieldsArray = (idx) => {
        const newArr = [...customFieldArray];
        if (newArr[idx]?.recurringSetting) {
            dispatch(customFieldActions?.deleteCustomField(newArr[idx]?._id));
            newArr.splice(idx, 1);
        } else {
            newArr.splice(idx, 1);
            setCustomModal(false);
        }
        setCustomFieldArray(newArr);
        setDeleteCustomFieldModal(false);
        setDeleteIdx(-1);
    };

    useEffect(() => {
        if (!customModal) {
            setMultipleValuesField({
                value: [
                    {
                        selected: true,
                        value: "",
                    },
                ],
            });
            setSingleValueField("");
            setRecurringSetting(false);
            setCustomAttachments({
                value: [],
            });
        }
    }, [customModal]);

    const handleEditCustomField = (idx) => {
        const customArr = [...customFieldArray];
        setEditCustomFieldIdx(idx);
        setFieldType(customArr[idx]?.fieldType);
        setFieldName(customArr[idx]?.name);
        setRecurringSetting(customArr[idx]?.recurringSetting);
        if (
            customArr[idx]?.fieldType === "checkbox" ||
            customArr[idx]?.fieldType === "radio" ||
            customArr[idx]?.fieldType === "dropDown"
        ) {
            setMultipleValuesField(customArr[idx]);
        } else if (customArr[idx]?.fieldType === "file") {
            setCustomAttachments(customArr[idx]);
        } else {
            setSingleValueField(customArr[idx]);
        }
        setCustomModal(true);

        if (customArr[idx]?._id) {
            setSavedRecurringFieldId(customArr[idx]?._id);
        }
    };
    useEffect(() => {
        const scInventory = sessionStorage.getItem("scInventory");
        if (scInventory && !productId) {
            setProductData(JSON.parse(scInventory));
            sessionStorage.removeItem("scInventory");
        } else if (data?.productName && productId) {
            const filteredField = data?.customFields?.find(
                (obj) => obj && (obj?.hasOwnProperty("size") || obj?.hasOwnProperty("color"))
            );
            // const formatBillOfMaterialIds = data?.billOfMaterialIds?.map((idx) => ({
            //     ...idx?.inventory,
            //     inventoryId: idx?.inventoryId,
            //     productQuantity: idx?.quantity,
            //     scrap: idx?.scrap,
            // }));
            setProductData((prev) => ({
                ...prev,
                productName: data?.productName,
                inventoryCategory: data?.inventoryCategory,
                vendors: data?.vendorIds,
                unitOfMeasurement: data?.productStockDetails?.unitOfMeasurement,
                attributeValue: filteredField ? String(Object?.values(filteredField)?.[0]) : "",
                selectAttribute: filteredField ? String(Object?.keys(filteredField)?.[0]) : "",
                bufferStockLevel: data?.productStockSettings?.[0]?.bufferStock,
                minimumStockLevel: data?.productStockSettings?.[0]?.minimumStockLevel,
                maximumStockLevel: data?.productStockSettings?.[0]?.maximumStockLevel,
                warehouse: data?.productStockSettings?.[0]?.warehouseId,
                isProductPerishable: data?.isProductPerishable,
                description: data?.productDescription || "",
                productSource: data?.productSource,
                salesPriceAmount: data?.salesPrice?.amount,
                salesPriceCurrency: data?.salesPrice?.currency || "USD",
                costPriceAmount: data?.unitCost?.amount,
                costPriceCurrency: data?.unitCost?.currency || "USD",
                retailSalePriceAmount: data?.retailSalePrice?.amount,
                retailSalePriceCurrency: data?.retailSalePrice?.currency || "USD",

                upc: data?.upc,
            }));
            setAttachments(data?.attachments);
            // setAddSelectedProduct(formatBillOfMaterialIds);

            if (data?.productImageDetails?.productImages?.length > 0) {
                setProductImages(
                    data?.productImageDetails?.productImages?.[0] !== null &&
                        data?.productImageDetails?.productImages
                );
                setProductImage(
                    data?.productImageDetails?.productAvatar ||
                        (data?.productImageDetails?.productImages?.[0] !== null &&
                            data?.productImageDetails?.productImages?.[0])
                );
            }
            sessionStorage.removeItem("scInventory");
        } else {
            setProductData({
                productName: "",
                inventoryCategory: "",
                vendors: [],
                unitOfMeasurement: "",
                selectAttribute: "",
                attributeValue: "",
                description: "",
                warehouse: "",
                minimumStockLevel: "",
                maximumStockLevel: "",
                bufferStockLevel: "",
                isProductPerishable: false,
                currency: "",
                price: "",
                productSource: "",
                salesPriceCurrency: "USD",
                costPriceCurrency: "USD",
                retailSalePriceCurrency: "USD",
            });
        }
    }, [data]);

    useEffect(() => {
        if (Boolean(deleteCustomFieldSuccess)) {
            setCustomModal(false);
        }
    }, [deleteCustomFieldSuccess]);

    useEffect(() => {
        if (Boolean(fetchCustomFieldSuccess)) {
            setRecurringFields(fetchCustomFieldSuccess?.customFields);
        }
    }, [fetchCustomFieldSuccess]);

    useEffect(() => {
        if (customFieldsType) {
            dispatch(customFieldActions.getCustomFields(customFieldsType));
        }
    }, [dispatch, customFieldsType]);

    useEffect(() => {
        if (Boolean(deleteCustomFieldSuccess)) {
            setCustomModal(false);
        }
    }, [deleteCustomFieldSuccess]);

    useEffect(() => {
        if (recurringFields?.length > 0) {
            const formatCustomFields = () =>
                recurringFields?.map((field) => {
                    let formattedField: {
                        fieldType: string;
                        name: string;
                        recurringSetting: boolean;
                        _id: string;
                        noValue: boolean;
                        placeholder?: string;
                        placeholderValue?: string | number;
                        currency?: string;
                        value?: any;
                    } = {
                        fieldType: field?.fieldType,
                        name: field?.fieldName,
                        recurringSetting: true,
                        _id: field?._id,
                        noValue: true,
                    };

                    if (field.fieldType === "text" || field.fieldType === "multiline") {
                        formattedField = {
                            ...formattedField,
                            placeholder: field?.placeholder,
                            placeholderValue: field?.fieldName,
                        };
                    } else if (field.fieldType === "currency") {
                        formattedField = {
                            ...formattedField,
                            currency: field?.currency,
                        };
                    } else if (
                        field.fieldType === "checkbox" ||
                        field.fieldType === "radio" ||
                        field.fieldType === "select"
                    ) {
                        formattedField = {
                            ...formattedField,
                            value: field?.options?.map((item) => ({
                                value: item?.name,
                                selected: false,
                            })),
                        };
                    } else if (field.fieldType === "file") {
                        formattedField = {
                            ...formattedField,
                            value: [],
                        };
                    }

                    return formattedField;
                });

            setCustomFieldArray(formatCustomFields);
        }
    }, [recurringFields]);

    //custom fields starts here

    return (
        <div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    if (imagesToRemove?.length > 0) {
                        handleRemoveMultipleImage();
                    }

                    handleSaveProduct();
                }}
                className="bg-white rounded-lg border border-n-20"
            >
                <div className="flex items-center justify-between px-8 py-5 border-b border-n-20">
                    <div className="flex items-center space-x-1.5">
                        <i
                            onClick={() => navigate(-1)}
                            className="ri-arrow-left-line text-xl mt-[-2px] cursor-pointer max-sm:hidden"
                        ></i>
                        <span className="text-lg font-rocGroteskMedium">
                            {productId ? "Edit" : "Create"} product
                        </span>
                    </div>
                    <div className="flex items-center space-x-3">
                        {/* <p className="flex items-center space-x-3">
                            <span className="w-2 h-2 rounded-full bg-slate-300"></span>
                            <span className="text-slate-500 text-sm font-rocGroteskMedium">
                                Draft saved
                            </span>
                        </p> */}
                        <Button
                            btnText="Cancel"
                            btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                            disabled={
                                addingSingleProduct ||
                                addingMultipleProducts ||
                                updatingSingleProduct
                            }
                            onClick={() => navigate(-1)}
                        />
                        <Button
                            btnText="Save"
                            type="submit"
                            isLoading={
                                addingSingleProduct ||
                                addingMultipleProducts ||
                                updatingSingleProduct
                            }
                            disabled={
                                addingSingleProduct ||
                                addingMultipleProducts ||
                                updatingSingleProduct
                            }
                            btnClassname="!px-4 !py-2.5 !w-fit"
                        />
                    </div>
                </div>
                <div className="px-8 py-6 flex gap-[7.7%]">
                    <div className="w-full">
                        <SingleProduct
                            productData={productData}
                            handleChange={handleChange}
                            setAddWarehouseModal={setAddWarehouseModal}
                            productImages={productImages}
                            productImage={productImage}
                            setDeleteImageModal={setDeleteImageModal}
                            setProductImage={setProductImage}
                            handleInputUploadAction={handleInputUploadAction}
                            uploadingFiles={uploadingFiles}
                            handleUploadAttachments={handleUploadAttachments}
                            uploadingAttachments={uploadingAttachments}
                            attachments={attachments}
                            setSelectedFilePath={setSelectedFilePath}
                            setDeleteAttachmentModal={setDeleteAttachmentModal}
                            billOfMaterialIds={billOfMaterialIds}
                            setBillOfMaterialIds={setBillOfMaterialIds}
                        />
                    </div>
                </div>
                {customFieldArray?.length > 0 && (
                    <p className="text-base font-rocGroteskMedium mb-4 px-8">Custom Fields</p>
                )}
                <div className=" px-8 mb-10">
                    <div className="w-full">
                        {customFieldArray?.map((item, idx) => {
                            return (
                                <div className="relative w-[94%]" key={idx}>
                                    <CustomFields
                                        displayCustomField={item}
                                        fieldType={item?.fieldType}
                                        required={false}
                                        className="!mb-4"
                                    />

                                    {
                                        <div className="">
                                            {item?.fieldType === "file" && item?.noValue && (
                                                <div
                                                    className="flex items-center gap-4 py-3 px-6 rounded border border-dashed border-slate-200 cursor-pointer mb-4"
                                                    onClick={() => handleEditCustomField(idx)}
                                                >
                                                    <i className="ri-file-add-line text-2xl text-carribean-green"></i>

                                                    <p className="text-sm underline text-carribean-green font-rocGroteskMedium">
                                                        Click to add attachment
                                                    </p>
                                                </div>
                                            )}
                                            <div className="flex items-center gap-4 absolute -right-[70px] top-[9px]">
                                                <i
                                                    className="ri-edit-line text-lg text-slate-400 cursor-pointer"
                                                    onClick={() => handleEditCustomField(idx)}
                                                ></i>
                                                <i
                                                    className="ri-delete-bin-6-line cursor-pointer text-slate-400 text-xl"
                                                    onClick={() => {
                                                        setDeleteCustomFieldModal(true);
                                                        setDeleteIdx(idx);
                                                        setSavedRecurringFieldId(item?._id);
                                                    }}
                                                ></i>
                                            </div>
                                        </div>
                                    }
                                </div>
                            );
                        })}

                        {/* <div className="pb-8 !font-rocGroteskMedium mt-12">
                            <hr className="bg-slate-300" />
                            <div className="flex justify-center !-mt-5">
                                <Button
                                    btnText="Add Custom Field"
                                    btnClassname="!w-auto !bg-slate-50 !px-4 !py-[6px] !border-slate-300 !border !rounded-full"
                                    onClick={() => {
                                        console.log("clicked");
                                        setCustomModal(true);
                                        setFieldType("text");
                                        setFieldName("");
                                        setRecurringSetting(false);
                                        setEditCustomFieldIdx(-1);
                                    }}
                                    icon={<i className="ri-add-line text-base "></i>}
                                />
                            </div>
                        </div> */}
                    </div>
                </div>
            </form>

            <ModalContainer
                open={deleteImageModal}
                showCloseIcon={false}
                tailwindClassName="w-[30%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setDeleteImageModal(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="px-10 pt-3">
                        <div className="flex flex-col items-center text-center">
                            <div className="flex w-full justify-end mr-[-12px]">
                                <i
                                    onClick={() => setDeleteImageModal(false)}
                                    className="ri-close-fill text-2xl cursor-pointe"
                                ></i>
                            </div>

                            <i className="ri-delete-bin-6-line text-[32px]"></i>

                            <p className="text-xl mb-3 text-center mt-6 font-rocGroteskMedium text-g-75">
                                Delete Image?
                            </p>
                            <p className="text-sm text-center font-rocGroteskMedium text-slate-500">
                                Are you sure about deleting this image?
                            </p>
                        </div>

                        <div className="w-full flex items-center gap-2 py-6">
                            <Button
                                btnText={"No, Cancel"}
                                type={"button"}
                                btnClassname="!bg-tradeally-neutral-20"
                                onClick={() => {
                                    setDeleteImageModal(false);
                                }}
                            />
                            <Button
                                btnText={"Yes, delete"}
                                type={"button"}
                                btnClassname="!bg-r-50 !text-white"
                                isLoading={deletingImage}
                                onClick={() => {
                                    handleRemoveImage();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={deleteAttachmentModal}
                showCloseIcon={false}
                tailwindClassName="w-[30%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setDeleteAttachmentModal(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="px-10 pt-3">
                        <div className="flex flex-col items-center text-center">
                            <div className="flex w-full justify-end mr-[-12px]">
                                <i
                                    onClick={() => setDeleteAttachmentModal(false)}
                                    className="ri-close-fill text-2xl cursor-pointe"
                                ></i>
                            </div>

                            <i className="ri-delete-bin-6-line text-[32px]"></i>

                            <p className="text-xl mb-3 text-center mt-6 font-rocGroteskMedium text-g-75">
                                Delete Attachment?
                            </p>
                            <p className="text-sm text-center font-rocGroteskMedium text-slate-500">
                                Are you sure about deleting this attachment?
                            </p>
                        </div>

                        <div className="w-full flex items-center gap-2 py-6">
                            <Button
                                btnText={"No, Cancel"}
                                type={"button"}
                                btnClassname="!bg-tradeally-neutral-20"
                                onClick={() => {
                                    setDeleteAttachmentModal(false);
                                }}
                            />
                            <Button
                                btnText={"Yes, delete"}
                                type={"button"}
                                btnClassname="!bg-r-50 !text-white"
                                isLoading={deletingAttachment}
                                onClick={() => {
                                    handleRemoveAttachment(selectedFilePath);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={addWarehouseModal}
                showCloseIcon={false}
                tailwindClassName="w-[42%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setAddWarehouseModal(false);
                }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleAddNewWarehouse();
                    }}
                    className="bg-white rounded-lg shadow-cardShadow relative"
                >
                    <div className="px-10 pt-3">
                        <div className="flex flex-col items-center">
                            <div className="flex w-full justify-end mr-[-12px]">
                                <i
                                    onClick={() => setAddWarehouseModal(false)}
                                    className="ri-close-fill text-2xl cursor-pointe"
                                ></i>
                            </div>

                            <p className="text-xl mb-3 text-center  font-rocGroteskMedium text-g-75">
                                Add a new warehouse location
                            </p>
                            <div className="flex flex-col gap-6 w-full">
                                <TextInput
                                    value={addWarehouseData.name}
                                    name={"name"}
                                    type={"text"}
                                    onChange={(e) =>
                                        setAddWarehouseData((prev) => ({
                                            ...prev,
                                            [e.target.name]: e.target.value,
                                        }))
                                    }
                                    placeholder={"Warehouse name"}
                                    required={true}
                                    containerClassname=""
                                />
                                <SelectInput
                                    value={addWarehouseData.contactPerson}
                                    name="contactPerson"
                                    placeholder={"Contact Person"}
                                    handleChange={(name, value) => {
                                        setAddWarehouseData((prev) => ({ ...prev, [name]: value }));
                                    }}
                                    isRequired={true}
                                    searchLoading={fetchingTeamMember}
                                    dropdownOptions={teamList?.map((team) => ({
                                        label: team?.fullName ? team?.fullName : team?.email,
                                        value: team?._id,
                                        icon: (
                                            <VendorAvatar
                                                name={team?.fullName ? team?.fullName : team?.email}
                                                imageSrc={team?.profile?.avatar}
                                                size={20}
                                            />
                                        ),
                                    }))}
                                />
                                <AddressInput
                                    setData={setAddress}
                                    savedAddress={
                                        address?.location ? address?.location?.address : ""
                                    }
                                    placeholder="Location"
                                    required={true}
                                    name={"location"}
                                />
                                <TextareaInput
                                    name={"note"}
                                    value={addWarehouseData.note}
                                    onChange={(e) =>
                                        setAddWarehouseData((prev) => ({
                                            ...prev,
                                            [e.target.name]: e.target.value,
                                        }))
                                    }
                                    rows={5}
                                    placeholder={"Additional note"}
                                    required={true}
                                    containerClassname=""
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full items-center justify-between px-8 py-4 border-t border-slate-200 mt-10">
                        <Button
                            btnText={"View all warehouse"}
                            type={"button"}
                            btnClassname="!bg-white !border !border-n-20 !py-3 !pl-3 !pr-4 !w-fit !h-[48px]"
                            icon={<i className="ri-external-link-line text-lg"></i>}
                            btnType="textFirst"
                            onClick={() => {
                                navigate("/dashboard/inventory/warehouse");
                            }}
                        />
                        <div className="flex items-center gap-2">
                            <Button
                                btnText={"Cancel"}
                                type={"button"}
                                btnClassname="!bg-tradeally-neutral-20 !w-fit !h-[48px]"
                                disabled={createWarehouse}
                                onClick={() => {
                                    setAddWarehouseModal(false);
                                }}
                            />
                            <Button
                                btnText={"Add warehouse"}
                                type={"submit"}
                                btnClassname="!py-3 !px-6 !w-fit !h-[48px]"
                                isLoading={createWarehouse}
                                disabled={createWarehouse}
                            />
                        </div>
                    </div>
                </form>
            </ModalContainer>

            {/* <CustomFieldsImplement
                openModal={customModal}
                closeModal={() => setCustomModal(!customModal)}
                fieldType={fieldType}
                fieldName={fieldName}
                recurringSetting={recurringSetting}
                editCustomFieldIdx={editCustomFieldIdx}
                setFieldType={setFieldType}
                setFieldName={setFieldName}
                setRecurringSetting={setRecurringSetting}
                setEditCustomFieldIdx={setEditCustomFieldIdx}
                multipleValuesField={multipleValuesField}
                singleValueField={singleValueField}
                customAttachments={customAttachments}
                setMultipleValuesField={setMultipleValuesField}
                setSingleValueField={setSingleValueField}
                setCustomAttachments={setCustomAttachments}
                openDeleteModal={() => setDeleteCustomFieldModal(true)}
                setDeleteIdx={setDeleteIdx}
                customFieldArray={customFieldArray}
                setCustomFieldArray={setCustomFieldArray}
                savedRecurringFieldId={savedRecurringFieldId}
                showDeleteButton={true}
            /> */}

            {/* <ModalContainer
                open={deleteCustomFieldModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                closeModal={() => {
                    setDeleteCustomFieldModal(false);
                }}
            >
                <div className="bg-white py-7 px-6  shadow-cardShadow relative rounded-[8px]">
                    <div className="border-[2px] h-[50px] bg-[#FEF2F2] w-[50px] flex items-center justify-center rounded-full border-[#DC2626]">
                        <i className="ri-delete-bin-line text-[#DC2626] text-[26px]"></i>
                    </div>
                    <div className="mb-5 mt-4">
                        <h1 className="text-lg mb-2  font-rocGroteskMedium">
                            Delete Custom Field?
                        </h1>
                        <p className="text-slate-500 font-rocGroteskMedium text-base">
                            Are you sure you want to permanently remove this custom field? This
                            action will also delete associated content, and it cannot be undone.
                        </p>
                    </div>
                    <div className="flex justify-end items-center ">
                        <div className="flex items-center !whitespace-nowrap space-x-2 ">
                            <Button
                                btnText={"No, cancel"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !w-fit !text-gm-50"}
                                disabled={deletingCustomField}
                                onClick={() => setDeleteCustomFieldModal(false)}
                            />
                            <Button
                                btnClassname={"!py-3 !w-fit"}
                                onClick={() => {
                                    handleDeleteFromCustomFieldsArray(deleteIdx);
                                }}
                                btnText={"Delete"}
                                type={"button"}
                                isLoading={deletingCustomField}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer> */}
        </div>
    );
};

export default AddProduct;
