import React, { useEffect, useState } from "react";
import { integrationActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";

const useGetIncomeAccounts = () => {
    const dispatch = useAppDispatch();
    const [accounts, setAccounts] = useState<{ [key: string]: any }[]>([]);

    const {
        fetchingQuickbooksIncomeAccounts,
        fetchedQuickbooksIncomeAccountsSuccess,
        fetchedQuickbooksIncomeAccountsFailure,
    } = useAppSelector((state) => state.integration);

    useEffect(() => {
        dispatch(integrationActions?.getQuickbooksIncomeAccounts());
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedQuickbooksIncomeAccountsSuccess)) {
           
            setAccounts(fetchedQuickbooksIncomeAccountsSuccess?.accounts);
        }
    }, [fetchedQuickbooksIncomeAccountsSuccess]);

    return {
        data: accounts,
        isFetching: fetchingQuickbooksIncomeAccounts,
        error: fetchedQuickbooksIncomeAccountsFailure,
    };
};

export default useGetIncomeAccounts;
