import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import { formatMoney, getCurrencyFromCurrencyCode, onScroll } from "helpers";
import moment from "moment";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import Button from "components/common/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import Dropdown from "components/common/Dropdown";
import { truncate, uniqBy } from "lodash";
import CustomTable from "components/common/CustomTable";
import CustomMobileTable from "components/common/CustomMobileTable";

type StockProps = {
    debouncedSearch?: string;
    warehouse?: string;
    stockCategory?: string;
};

const StockEntry = ({ debouncedSearch, warehouse, stockCategory }: StockProps) => {
    const limit = 10;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const currTab = params.get("currTab");
    const [page, setPage] = useState<number>(1);

    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const [stockList, setStockList] = useState<{ [key: string]: any }[]>([]);
    const [loadMore, setLoadMore] = useState<boolean>(false);
    const [stockListPagination, setStockListPagination] = useState<{
        current: number | string;
        number_of_pages: number | string;
    }>({
        current: "",
        number_of_pages: "",
    });

    const { fetchingStockList, fetchedStockListSuccess } = useAppSelector(
        (state) => state.inventory
    );

    useEffect(() => {
        if (Boolean(fetchedStockListSuccess)) {
            setStockList((prev) =>
                page > 1
                    ? uniqBy([...prev, ...fetchedStockListSuccess?.stock], "_id")
                    : [...fetchedStockListSuccess?.stock]
            );
            setStockListPagination({
                current: fetchedStockListSuccess?.pagination?.current,
                number_of_pages: fetchedStockListSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedStockListSuccess]);
    useEffect(() => {
        dispatch(
            inventoryActions.fetchStockList(limit, debouncedSearch, warehouse, stockCategory, page)
        );
    }, [dispatch, page, debouncedSearch, warehouse, stockCategory]);

    useEffect(() => {
        if (warehouse || stockCategory || debouncedSearch) {
            setPage(1);
        }
    }, [debouncedSearch, warehouse, stockCategory]);

    const dashboardContent = document.getElementById("dashboardContent");

    const onTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                stockListPagination?.current as number,
                stockListPagination?.number_of_pages as number,
                () => {
                    setPage(() => page + 1);
                    setLoadMore(true);
                }
            ),
        [dashboardContent, stockListPagination]
    );
    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onTableScroll);
        };
    }, [dashboardContent, onTableScroll]);

    const handleScroll = (e) => {
        if (e.target.scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const tableHeader = [
        {
            title: "Batch ID",
            widthClass: "!border-b !border-slate-200 w-[200px]",
            itemClass: "",
        },
        {
            title: "Product",
            widthClass: "!border-b !border-slate-200 w-[220px]",
            itemClass: "",
        },
        {
            title: "Quantity",
            widthClass: "!border-b !border-slate-200 w-[130px]",
            itemClass: "",
        },
        {
            title: "Unit cost",
            widthClass: "!border-b !border-slate-200 w-[100px]",
            itemClass: "",
        },
        // {
        //     title: "Manufacture Date",
        //     widthClass: "!border-b !border-slate-200 w-[140px]",
        //     itemClass: "",
        // },
        {
            title: "Added on",
            widthClass: "!border-b !border-slate-200 w-[125px]",
            itemClass: "",
        },
        {
            title: "Expiry Date",
            widthClass: "!border-b !border-slate-200 w-[200px]",
            itemClass: "",
        },

        {
            title: "Last Supplier",
            widthClass: "!border-b !border-slate-200 w-[240px]",
            itemClass: "",
        },
        {
            title: "Warehouse",
            widthClass: "!border-b !border-slate-200 w-[240px]",
            itemClass: "",
        },
        {
            title: "Remark",
            widthClass: "!border-b !border-slate-200 w-[256px]",
            itemClass: "",
        },
        {
            title: "",
            widthClass: "!border-b !border-slate-200 w-[50px]",
            itemClass: "",
        },
    ];

    const tableBody =
        stockList?.length > 0
            ? stockList?.map((item, idx) => {
                  let expiryDate =
                      item?.productViabilityDetails?.bestBefore ||
                      item?.productViabilityDetails?.expiryDate;

                  const checkValidDate = expiryDate ? moment(expiryDate) : moment.invalid();
                  const futureDate = checkValidDate?.isAfter(moment(), "day");
                  return [
                      {
                          content: (
                              <div className="py-4" key={idx}>
                                  <p className=""> {item?.batchId}</p>
                              </div>
                          ),
                          widthClass: "!border-b !border-slate-200",
                          cellClickAction: () =>
                              navigate(
                                  `/dashboard/inventory/stock-control/stock-entry/${item?._id}?currTab=${currTab}`
                              ),
                      },

                      {
                          content: (
                              <div className="py-4 flex items-center gap-3">
                                  <div className="flex gap-3 items-center">
                                      {item?.inventory?.productImageDetails?.productAvatar ? (
                                          <div className="w-8 h-8">
                                              <img
                                                  src={
                                                      item?.inventory?.productImageDetails
                                                          ?.productAvatar
                                                  }
                                                  alt=""
                                                  className="object-contain h-full w-full"
                                              />
                                          </div>
                                      ) : (
                                          <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                              <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                          </div>
                                      )}
                                      <div className="">
                                          <p className="">
                                              {truncate(item?.inventory?.productName, {
                                                  length: 18,
                                              })}
                                          </p>
                                          <p className="text-slate-500"> {item?.inventory?.sku}</p>
                                      </div>
                                  </div>
                              </div>
                          ),
                          widthClass: "!border-b !border-slate-200",
                      },
                      {
                          content: (
                              <div className="py-4">
                                  <p className="">
                                      {`${item?.productStockDetails?.stockLevel?.toLocaleString()} ${
                                          item?.inventory?.productStockDetails?.unitOfMeasurement ??
                                          ""
                                      }`}
                                  </p>
                              </div>
                          ),
                          widthClass: "!border-b !border-slate-200",
                      },
                      {
                          content: (
                              <div className="py-4" key={idx}>
                                  <p className="">
                                      {item?.cost?.amount &&
                                          getCurrencyFromCurrencyCode(item?.cost?.currency)}

                                      {item?.cost?.amount
                                          ? formatMoney()?.format(item?.cost?.amount)
                                          : "N/A"}
                                  </p>
                              </div>
                          ),
                          widthClass: "!border-b !border-slate-200",
                      },
                      {
                          content: (
                              <div className="py-4 flex items-center gap-2">
                                  <p className="">
                                      {moment(item?.createdAt).format("MMM DD, YYYY")}
                                  </p>
                              </div>
                          ),
                          widthClass: "!border-b !border-slate-200",
                      },

                      {
                          content: (
                              <div className="py-4">
                                  <p className="">
                                      {expiryDate
                                          ? moment(expiryDate).format("MMM DD, YYYY")
                                          : "N/A"}
                                  </p>
                                  <p className="text-sm text-slate-500">
                                      {futureDate
                                          ? `${
                                                moment(expiryDate).diff(moment(), "days") + 1
                                            } days left`
                                          : !expiryDate
                                          ? "N/A"
                                          : "Expired"}
                                      {}
                                  </p>
                              </div>
                          ),
                          widthClass: "!border-b !border-slate-200",
                      },

                      {
                          content: (
                              <div className="py-4 flex items-center gap-3">
                                  <div className="flex gap-3 items-center">
                                      {item?.vendor?.profile?.businessName && (
                                          <VendorAvatar
                                              containerClassname="!rounded"
                                              imageSrc={item?.vendor?.profile?.logo}
                                              name={item?.vendor?.profile?.businessName}
                                              size={32}
                                          />
                                      )}

                                      {item?.vendor?.profile?.businessName
                                          ? truncate(item?.vendor?.profile?.businessName, {
                                                length: 35,
                                            })
                                          : "---"}
                                  </div>
                              </div>
                          ),
                          widthClass: "!border-b !border-slate-200",
                      },
                      {
                          content: (
                              <div className="py-4 flex items-center gap-2">
                                  <p className="">
                                      {truncate(item?.warehouse?.warehouseName, { length: 35 })}
                                  </p>
                              </div>
                          ),
                          widthClass: "!border-b !border-slate-200",
                      },
                      {
                          content: (
                              <div className="py-4">
                                  <p className="">
                                      {truncate(item?.notes, { length: 50 }) || "N/A"}
                                  </p>
                              </div>
                          ),
                          widthClass: "!border-b !border-slate-200",
                      },
                      {
                          content: (
                              <div onClick={(e) => e.stopPropagation()}>
                                  <Dropdown
                                      dropdown={
                                          <i className="ri-more-2-fill before:content-['\ef76'] text-gm-50"></i>
                                      }
                                      dropdownClassName={"!w-full dropdown-container  mt-4 !mb-10 "}
                                      dropdownContainerClasses={`shadow-cardShadow-2 left-[-120px]  border-0 rounded !max-h-fit`}
                                      dropdownItemsClasses={``}
                                      name={"faq-dropdown"}
                                      dropdownOptions={[
                                          {
                                              label: "Edit Stock",
                                              value: "e",
                                              action: () =>
                                                  navigate(
                                                      `/dashboard/inventory/stock-control/edit-stock/${item?._id}?currTab=${currTab}`
                                                  ),
                                              //   permissions: getEditPermissionList("inventory"),
                                          },
                                          {
                                              label: "Preview Stock",
                                              value: "d",
                                              action: () =>
                                                  navigate(
                                                      `/dashboard/inventory/stock-control/stock-entry/${item?._id}?currTab=${currTab}`
                                                  ),
                                          },
                                      ]}
                                  />
                              </div>
                          ),
                          widthClass: "!border-b !border-slate-200 ",
                      },
                  ];
              })
            : [];

    const mobileTableBody = stockList?.map((item) => {
        return {
            cellClickAction: () =>
                navigate(
                    `/dashboard/inventory/stock-control/stock-entry/${item?._id}?currTab=${currTab}`
                ),
            topString: item?._id,
            avatar: item?.inventory?.productImageDetails?.productAvatar ? (
                <div className="w-8 h-8">
                    <img
                        src={item?.inventory?.productImageDetails?.productAvatar}
                        alt=""
                        className="object-contain h-full w-full"
                    />
                </div>
            ) : (
                <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                </div>
            ),
            topContent: (
                <div>
                    <div className="">
                        <p className="text-sm font-rocGroteskMedium"> {item?.inventory?.sku}</p>
                        <p className="text-sm font-rocGroteskMedium text-slate-500">
                            {truncate(item?.inventory?.productName, {
                                length: 18,
                            })}
                        </p>
                    </div>
                </div>
            ),
            bottomContent: (
                <div className="flex items-center text-sm font-rocGroteskMedium space-x-1">
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-200 h-[21px] border rounded px-2 text-xs text-slate-500 flex items-center justify-center`}
                    >
                        <span>{moment(item?.createdAt).format("MMM DD, YYYY")}</span>
                    </div>

                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-200 h-[21px] border rounded px-2 text-xs text-slate-500 flex items-center justify-center`}
                    >
                        <span>
                            Qty:{" "}
                            {`${item?.productStockDetails?.stockLevel?.toLocaleString()} ${
                                item?.inventory?.productStockDetails?.unitOfMeasurement ?? ""
                            }`}
                        </span>
                    </div>

                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-200 h-[21px] border rounded px-2 text-xs text-slate-500 flex items-center justify-center`}
                    >
                        <span>
                            {item?.cost?.amount &&
                                getCurrencyFromCurrencyCode(item?.cost?.currency)}

                            {item?.cost?.amount ? formatMoney()?.format(item?.cost?.amount) : "N/A"}
                        </span>
                    </div>
                </div>
            ),
        };
    });

    return (
        <>
            {/* <div className="">
                <div className={`w-full min-h-[30vh]  flex items-center  justify-center`}>
                    <Loader color={"gm-50"} size={8} />
                </div>
            </div> */}

            {fetchingStockList && stockList?.length === 0 ? (
                <div className={`w-full min-h-[30vh]  flex items-center  justify-center`}>
                    <Loader color={"gm-50"} size={8} />
                </div>
            ) : (
                <div>
                    {stockList?.length > 0 ? (
                        <div>
                            <div
                                className="overflow-x-scroll max-lg:hidden"
                                onScroll={handleScroll}
                                // id="dashboardContent"
                            >
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isScrollable={true}
                                    isScrolled={isScrolled}
                                    isCellBordered={false}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs"
                                    headerContainerClass="!bg-slate-50"
                                    tableClass=" w-full !font-rocGroteskMedium cursor-pointer"
                                    tbodyClass="!text-sm !text-slate-900"
                                    bodyItemClass="hover:!bg-slate-50"
                                />
                            </div>

                            <div className="hidden max-lg:block">
                                <CustomMobileTable data={mobileTableBody} />
                            </div>

                            {loadMore && fetchingStockList && (
                                <div className="flex my-4 justify-center">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {stockListPagination?.current ===
                                stockListPagination?.number_of_pages && (
                                <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                    End of list
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className=" min-h-[30vh] my text-slate-500  flex items-center  justify-center text-sm font-rocGroteskMedium">
                            {debouncedSearch || stockCategory || warehouse ? (
                                <p className="">"No data matches your search query"</p>
                            ) : (
                                <div className="w-full flex justify-center">
                                    <div
                                        className="flex mt-[85px] p-10 rounded-lg border border-slate-100 text-slate-500
                        items-start gap-6 font-rocGroteskMedium max-w-[635px] shadow-tableNoDataShadow"
                                    >
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1705745863/box-3-line_ybcpgn.svg"
                                            alt="no data "
                                            className=""
                                        />
                                        <div className="">
                                            <p className="text-black text-base  pb-[9px]">
                                                No stock records found.
                                            </p>
                                            <p className="text-sm text-slate-500 pb-6">
                                                Currently, there are no recorded stock details for
                                                your products. Ensure accurate tracking by adding
                                                stock records, each tagged with a unique batch
                                                number for easy identification and management.
                                            </p>
                                            <div className="flex justify-start">
                                                <Dropdown
                                                    dropdown={
                                                        <div
                                                            className="!bg-y-10
                                             !py-[10px] !px-4  !text-gun-metal !text-[13px] !w-auto rounded"
                                                        >
                                                            <p
                                                                className={` font-rocGroteskMedium text-[13px]`}
                                                            >
                                                                Record your first stock
                                                            </p>
                                                        </div>
                                                    }
                                                    dropdownItemsClasses={`!px-0 !py-0`}
                                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                                    dropdownContainerClasses={`!shadow-[0px_4px_6px_-2px_rgba(16,24,40,0.03)] !border-[#F1F5F9] !right-0  rounded !max-h-fit !w-auto
                            `}
                                                    dropdownOptions={[
                                                        {
                                                            label: (
                                                                <div
                                                                    className=" text-gm-50 font-rocGroteskMedium text-sm flex justify-start
                                                 !w-[180px] px-4 py-[10px] gap-4 items-center"
                                                                >
                                                                    <i className="ri-shopping-cart-line text-[#000] text-base"></i>
                                                                    Add manually
                                                                </div>
                                                            ),
                                                            value: "",
                                                            action: () =>
                                                                navigate(
                                                                    `/dashboard/inventory/stock-control/stock-entry?channel=offline_purchase?currTab=${currTab}`
                                                                ),
                                                        },
                                                        {
                                                            label: (
                                                                <div
                                                                    className=" text-gm-50 font-rocGroteskMedium text-sm flex justify-start
                                                 !w-[180px] px-4 py-[10px] gap-4 items-center"
                                                                >
                                                                    <i className="ri-box-3-line text-[#000] text-base"></i>
                                                                    Shipments
                                                                </div>
                                                            ),
                                                            value: "",
                                                            action: () =>
                                                                navigate(
                                                                    `/dashboard/inventory/stock-control/stock-entry?channel=shipment?currTab=${currTab}`
                                                                ),
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default StockEntry;
