import React, { useEffect, useState } from "react";
import PageTitleSection from "components/common/PageTitleSection";
import { useNavigate, useParams } from "react-router-dom";
import VendorAvatar from "components/common/VendorAvatar";
import Button from "components/common/Button";
import Rating from "components/common/Rating";
import CustomTable from "components/common/CustomTable";
import { truncate } from "lodash";
import Dropdown from "components/common/Dropdown";
import TabsContainer from "components/common/TabsContainer";
import ModalContainer from "components/common/ModalContainer";
import ReactECharts from "echarts-for-react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { vendorActions } from "redux/Ldb/actions";
import { getCurrencyFromCurrencyCode } from "helpers";
import moment from "moment";
import Loader from "components/common/Loader";
import PageFrame from "components/layout/PageFrame";
import DeleteSupplierModal from "components/views/Synth2.0/Supplier/DeleteSupplierModal";

const SupplierDetails = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState(0);
    const [showQuantity, setShowQuantity] = useState(false);
    // const tabs = ["Product details", "Ongoing purchase order", "Quotes", "Returned goods"];
    const tabs = ["Product details", "Ongoing purchase order", "Quotes"];
    const [deleteSupplier, setDeleteSupplier] = useState<{ id: string; name: string }>({
        name: "",
        id: "",
    });
    const [showModals, setShowModals] = useState<boolean>(false);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false);
    const [supplierProduct, setSupplierProduct] = useState<{ [key: string]: any }>([]);
    const [supplierOngoingPo, setSupplierOngoingPo] = useState<{ [key: string]: any }>([]);
    const [supplierQuotes, setSupplierQuotes] = useState<{ [key: string]: any }>([]);
    const [supplierDetails, setSupplierDetails] = useState<{ [key: string]: any }>({});
    const [supplierProductHistory, setSupplierProductHistory] = useState<{ [key: string]: any }>(
        []
    );
    const [productInfo, setProductInfo] = useState<{ [key: string]: any }>({});
    const [inventoryId, setInventoryId] = useState<string>("");
    const {
        fetchingSupplierOngoingPo,
        fetchingSupplierProducts,
        fetchingSupplierQuotes,
        fetchingSupplierDetails,
        fetchSupplierOngoingPoSuccess,
        fetchSupplierProductsSuccess,
        fetchSupplierQuotesSuccess,
        fetchSupplierDetailsSuccess,
        fetchingSupplierProductHistory,
        fetchSupplierProductHistorySuccess,
    } = useAppSelector((state) => state?.vendor);

    useEffect(() => {
        if (Boolean(fetchSupplierOngoingPoSuccess)) {
            setSupplierOngoingPo(fetchSupplierOngoingPoSuccess);
        }
        if (Boolean(fetchSupplierProductsSuccess)) {
            // setSupplierProduct((prev) =>
            //     page > 1
            //         ? uniqBy([...prev, ...fetchSupplierProductsSuccess], "_id")
            //         : [...fetchSupplierProductsSuccess]
            // );
            setSupplierProduct(fetchSupplierProductsSuccess);
        }
        if (Boolean(fetchSupplierQuotesSuccess)) {
            setSupplierQuotes(fetchSupplierQuotesSuccess?.quotes);
        }
        if (Boolean(fetchSupplierDetailsSuccess)) {
            setSupplierDetails(fetchSupplierDetailsSuccess);
        }
        if (Boolean(fetchSupplierProductHistorySuccess)) {
            setSupplierProductHistory(fetchSupplierProductHistorySuccess);
        }
    }, [
        fetchSupplierOngoingPoSuccess,
        fetchSupplierProductsSuccess,
        fetchSupplierQuotesSuccess,
        fetchSupplierDetailsSuccess,
        fetchSupplierProductHistorySuccess,
    ]);

    useEffect(() => {
        if (id) {
            dispatch(vendorActions?.fetchSupplierOngoingPo(id, 10));
            dispatch(vendorActions?.fetchSupplierProduct(id));
            dispatch(vendorActions?.fetchSupplierQuotes(id));
            dispatch(vendorActions?.fetchSupplierDetails(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (id && inventoryId) {
            dispatch(vendorActions?.fetchSupplierProductHistory(id, inventoryId));
        }
    }, [id, inventoryId]);

    const checkRisk = (num) => {
        if (num >= 1 && num <= 4) {
            return {
                value: "Low",
                img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1712468413/Frame_2615019_1_oyxqr8.svg",
            };
        } else if (num >= 5 && num <= 7) {
            return {
                value: "Medium",
                img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1712468414/Frame_2615019_2_yz9dkz.svg",
            };
        }
        if (num >= 8 && num <= 10) {
            return {
                value: "High",
                img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1712468414/Frame_2615019_3_rj8hoz.svg",
            };
        }
    };

    const productTableHeader = [
        { title: "Product Image", widthClass: "w-[10.8%]" },
        { title: "Category", widthClass: "w-[10.6%]" },
        { title: "Product name", widthClass: "w-[9.8%]" },
        { title: "Product code", widthClass: "w-[9.8%]" },
        { title: "Price", widthClass: "w-[9.8%]" },
        { title: "Recent quantity", widthClass: "w-[9.8%]" },
        { title: "Lead time", widthClass: "w-[9.8%]" },
    ];

    const productTableBody = supplierProduct?.map((product, idx) => {
        const contractProduct = supplierDetails?.contract?.[0]?.products?.find((prod) => {
            return prod?.inventoryId === product?.inventory?._id;
        });

        return [
            {
                content: (
                    <div className={`py-2 pr-3 font-rocGroteskMedium border-slate-100`}>
                        <VendorAvatar
                            containerClassname="!rounded-none"
                            imageSrc={product?.inventory?.productImageDetails?.productAvatar}
                            name=""
                            size={40}
                        />
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                    >
                        <p>{product?.inventory?.inventoryCategory || "N/A"}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                    >
                        <p>{product?.inventory?.productName || "N/A"} </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                    >
                        <p>{product?.inventory?.sku}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                    >
                        <p>
                            {product?.inventory?.unitCost?.amount
                                ? `  ${getCurrencyFromCurrencyCode(
                                      product?.inventory?.unitCost?.currency
                                  )}
                        ${
                            Number.isInteger(product?.inventory?.unitCost?.amount)
                                ? product?.inventory?.unitCost?.amount
                                : product?.inventory?.unitCost?.amount?.toFixed(2)
                        }`
                                : "N/A"}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowQuantity(true);
                            setInventoryId(product?.inventory?._id);
                            setProductInfo({
                                name: product?.inventory?.productName,
                                category: product?.inventory?.inventoryCategory,
                            });
                        }}
                        className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-p-50 `}
                    >
                        <p className="underline">
                            {Number.isInteger(product?.quantity)
                                ? product?.quantity
                                : Math.round(Number(product?.quantity)).toLocaleString()}
                        </p>
                    </div>
                ),
            },

            {
                content: (
                    <div
                        className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                    >
                        {contractProduct &&
                        product?.avgLeadTime < contractProduct?.leadTime?.value ? (
                            <p>
                                {contractProduct?.leadTime?.value}{" "}
                                {contractProduct?.leadTime?.duration}
                            </p>
                        ) : (
                            <p>{`${product?.avgLeadTime} ${
                                product?.avgLeadTime > 1 ? "days" : "day"
                            }`}</p>
                        )}
                    </div>
                ),
            },
        ];
    });

    const tableHeader = [
        { title: "PO Number", widthClass: "w-[10.8%]" },
        { title: "Assigned to", widthClass: "w-[11%]" },
        { title: "Issue date", widthClass: "w-[8%]" },
        { title: "Due date", widthClass: "w-[8%]" },
        { title: "Total amount (USD)", widthClass: "w-[9.8%]" },
        { title: "Status", widthClass: "w-[7%]" },
        { title: "", widthClass: "w-[4.1%]" },
    ];

    const tableBody = supplierOngoingPo?.purchaseOrders?.map((po, idx) => [
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-base text-[#64748B] `}
                >
                    <div className="flex items-center space-x-3">
                        <p>PO {po?.taId}</p>
                    </div>
                </div>
            ),
            cellClickAction: () => navigate(`/dashboard/purchase`),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <div className="flex items-center space-x-2">
                        {supplierOngoingPo?.purchaseOrderSettings?.[idx]?.approvers?.[0]
                            ?.fullName && (
                            <VendorAvatar
                                imageSrc={
                                    supplierOngoingPo?.purchaseOrderSettings?.[idx]?.approvers?.[0]
                                        ?.profile?.avatar
                                }
                                size={24}
                                name={
                                    supplierOngoingPo?.purchaseOrderSettings?.[idx]?.approvers?.[0]
                                        ?.fullName
                                }
                            />
                        )}
                        <span>
                            {truncate(
                                supplierOngoingPo?.purchaseOrderSettings?.[idx]?.approvers?.[0]
                                    ?.fullName,
                                {
                                    length: 10,
                                }
                            )}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                >
                    <span className="">{moment(po?.issuedDate)?.format("DD MMM, YYYY")}</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                >
                    <span className="">{moment(po?.dueDate)?.format("DD MMM, YYYY")}</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                >
                    <span className="">
                        {getCurrencyFromCurrencyCode(po?.totalAmount?.currency)}
                        {po?.totalAmount?.amount?.toLocaleString()}
                    </span>
                </div>
            ),
        },
        {
            content: (
                <div className={`py-2 pr-3 font-rocGroteskMedium`}>
                    <div
                        className={`text-sm  space-x-1.5 flex items-center px-2.5 py-1.5 rounded-[20px] 
                     ${
                         po?.currentStatus === "accepted"
                             ? "bg-g-25 text-[#0F172A]"
                             : po?.currentStatus === "pending" || po?.currentStatus === "draft"
                             ? "bg-b-25 text-[#142837]"
                             : po?.currentStatus === "rejected"
                             ? "bg-r-25 text-[#0F172A]"
                             : po?.currentStatus === "in-transit"
                             ? "bg-[#FFEDD5] text-[#EA580C]"
                             : po?.currentStatus === "completed" && "bg-slate-200 text-slate-500"
                     }`}
                    >
                        <div
                            className={`h-2 w-2 bg-[#3668E8] rounded-full 
                        ${
                            po?.currentStatus === "accepted"
                                ? "bg-[#16C6A4]"
                                : po?.currentStatus === "pending" || po?.currentStatus === "draft"
                                ? "bg-[#3668E8]"
                                : po?.currentStatus === "rejected"
                                ? "bg-[#FF3D1D]"
                                : po?.currentStatus === "in-transit"
                                ? "bg-[#EA580C]"
                                : po?.currentStatus === "completed" && "bg-slate-500"
                        }`}
                        ></div>
                        <p className={`font-rocGroteskMedium capitalize`}>
                            {po?.currentStatus === "in-transit" ? "In transit" : po?.currentStatus}
                        </p>
                    </div>
                </div>
            ),
        },

        {
            content: (
                <div
                    className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-100px] `}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "Preview",
                                value: "preview",
                                action: () => {
                                    navigate(`/dashboard/purchase`);
                                },
                            },
                            // {
                            //     label: "View Details",
                            //     value: "view details",
                            //     action: () => {},
                            // },
                        ]}
                    />
                </div>
            ),
        },
    ]);

    const quantityTableHeader = [
        { title: "NO.", widthClass: "w-[5%]" },
        { title: "Quantity ", widthClass: "w-[7%]" },
        { title: "Order date", widthClass: "w-[9.8%]" },
        { title: "Delivery date", widthClass: "w-[9.8%]" },
    ];

    const quantityTableBody = supplierProductHistory?.inventoryDetails?.map((product, idx) => [
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-base text-[#64748B] `}
                >
                    <div className="flex items-center space-x-3">
                        <p>{idx + 1}</p>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <p>{product?.quantity}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                >
                    <p>{moment(product?.orderDate)?.format("DD, MMM, YYYY")}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                >
                    <p>{moment(product?.deliveryDate)?.format("DD, MMM, YYYY")}</p>
                </div>
            ),
        },
    ]);

    const returnGoodsTableHeader = [
        { title: "Return ID", widthClass: "w-[7%]" },
        { title: "PO ID ", widthClass: "w-[7%]" },
        { title: "Product category", widthClass: "w-[9.8%]" },
        { title: "Product name", widthClass: "w-[9%]" },
        { title: "Purchased qty.", widthClass: "w-[7%]" },
        { title: "Returned qty..", widthClass: "w-[7%]" },
        { title: "Reason", widthClass: "w-[9.8%]" },
        { title: "Return date", widthClass: "w-[9.8%]" },
        { title: "Return status", widthClass: "w-[9.8%]" },
    ];

    const returnGoodsTableBody = [1, 2, 3, 4]?.map((supply, idx) => [
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-[16px] leading-[20px] text-[#64748B] `}
                >
                    <div className="flex items-center space-x-3">
                        <p>Rtn 123</p>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <p>PO 123</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                >
                    <p>Fruits & Vegetable</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                >
                    <p>Oranges</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                >
                    <p>100</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                >
                    <p>50</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                >
                    <p>Damaged goods</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                >
                    <p>12 May, 2024</p>
                </div>
            ),
        },
        {
            content: (
                <div className={`py-3 pr-3 font-rocGroteskMedium`}>
                    <div className="text-sm space-x-1.5 flex items-center ">
                        <div className="h-2 w-2 bg-[#16C6A4] rounded-full"></div>
                        {/* <div className="h-2 w-2 bg-[#FF3D1D] rounded-full"></div>
                        <div className="h-2 w-2 bg-[#3668E8] rounded-full"></div> */}
                        <p className="font-rocGroteskMedium text-[#0F172A] ">Approved</p>
                    </div>
                </div>
            ),
        },
    ]);

    const quoteTableHeader = [
        { title: "Quote Number", widthClass: "w-[10.8%]" },
        { title: "Validity period ", widthClass: "w-[10.6%]" },
        { title: "Quote date", widthClass: "w-[9.8%]" },
        { title: "Total amount (USD)", widthClass: "w-[9.8%]" },
        { title: "Status", widthClass: "w-[9.8%]" },
        { title: "", widthClass: "w-[4.1%]" },
    ];

    const quoteTableBody = supplierQuotes?.map((quote, idx) => [
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-[16px] leading-[20px] text-[#64748B] `}
                >
                    <div className="flex items-center space-x-3">
                        <p>PO {quote?.taId}</p>
                    </div>
                </div>
            ),
            cellClickAction: () => navigate(`/dashboard/purchase`),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <p>{moment(quote?.dueDate)?.format("DD MMM, YYYY")}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                >
                    <p>{moment(quote?.createdAt)?.format("DD MMM, YYYY")}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-[#64748B] `}
                >
                    {getCurrencyFromCurrencyCode(quote?.amount?.currency)}
                    {quote?.amount?.amount?.toLocaleString()}
                </div>
            ),
        },
        {
            content: (
                <div className={`py-3 pr-3 font-rocGroteskMedium`}>
                    <div className="text-sm space-x-1.5 flex items-center ">
                        <div
                            className={`h-2 w-2  rounded-full ${
                                quote?.currentStatus === "approved" ||
                                quote?.currentStatus === "accepted"
                                    ? "bg-[#16C6A4]"
                                    : quote?.currentStatus === "pending"
                                    ? "bg-[#3668E8]"
                                    : quote?.currentStatus === "rejected" && "bg-[#FF3D1D]"
                            }`}
                        ></div>
                        <p className="font-rocGroteskMedium text-[#0F172A] capitalize">
                            {quote?.currentStatus}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-100px] `}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "Preview",
                                value: "preview",
                                action: () => {
                                    navigate(`/dashboard/purchase`);
                                },
                            },
                            // {
                            //     label: "View Details",
                            //     value: "view details",
                            //     action: () => {},
                            // },
                        ]}
                    />
                </div>
            ),
        },
    ]);

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return fetchingSupplierProducts ? (
                    <div className="my-8">
                        <Loader size={8} />
                    </div>
                ) : supplierProduct?.length >= 1 ? (
                    <div className="">
                        <div className="hidden lg:flex">
                            <CustomTable
                                tableBody={productTableBody}
                                tableHeader={productTableHeader}
                                isScrollable={false}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>
                        <div className="lg:hidden space-y-2.5">
                            {supplierProduct?.map((product, idx) => {
                                const contractProduct =
                                    supplierDetails?.contract?.[0]?.products?.find((prod) => {
                                        return prod?.inventoryId === product?.inventory?._id;
                                    });

                                return (
                                    <div
                                        className="border border-slate-200 rounded px-4 py-3.5 font-rocGroteskMedium"
                                        key={idx}
                                    >
                                        <div className="grid grid-cols-3 gap-2">
                                            <p className="text-gm-50 text-sm border-r border-slate-200 pr-4">
                                                {product?.inventory?.productName}{" "}
                                            </p>

                                            <p className="text-gm-50 text-sm">
                                                <div
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setShowQuantity(true);
                                                        setInventoryId(product?.inventory?._id);
                                                        setProductInfo({
                                                            name: product?.inventory?.productName,
                                                            category:
                                                                product?.inventory
                                                                    ?.inventoryCategory,
                                                        });
                                                    }}
                                                    className={`py-2 pr-3  border-slate-100 text-sm text-p-50 `}
                                                >
                                                    <p className="text-gm-50">
                                                        Recent qty:{" "}
                                                        {Number.isInteger(product?.quantity)
                                                            ? product?.quantity
                                                            : Math.round(Number(product?.quantity))}
                                                    </p>
                                                </div>
                                            </p>
                                        </div>
                                        <div className="grid grid-cols-3 gap-2 mt-3">
                                            <div
                                                className={`border-r border-slate-200 pr-4 text-xs text-[#64748B] `}
                                            >
                                                <p className="">
                                                    Price:
                                                    {product?.inventory?.unitCost?.amount
                                                        ? `  ${getCurrencyFromCurrencyCode(
                                                              product?.inventory?.unitCost?.currency
                                                          )}
                        ${
                            Number.isInteger(product?.inventory?.unitCost?.amount)
                                ? product?.inventory?.unitCost?.amount
                                : product?.inventory?.unitCost?.amount?.toFixed(2)
                        }`
                                                        : "N/A"}
                                                </p>
                                            </div>

                                            <div
                                                className={`border-r border-slate-200 pr-4 text-xs text-[#64748B] `}
                                            >
                                                {contractProduct &&
                                                product?.avgLeadTime <
                                                    contractProduct?.leadTime?.value ? (
                                                    <p>
                                                        Lead time:{" "}
                                                        {contractProduct?.leadTime?.value}{" "}
                                                        {contractProduct?.leadTime?.duration}
                                                    </p>
                                                ) : (
                                                    <p>
                                                        Lead time:{" "}
                                                        {`${product?.avgLeadTime} ${
                                                            product?.avgLeadTime > 1
                                                                ? "days"
                                                                : "day"
                                                        }`}
                                                    </p>
                                                )}
                                            </div>

                                            <div className={` text-xs text-[#64748B] `}>
                                                <p>Code: {product?.inventory?.sku}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center justify-center flex-col mt-20">
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1714100916/Vector_2_qctd9q.svg"
                            alt=""
                            className="mb-6"
                        />
                        <p className="text-gm-40 text-base font-rocGroteskMedium max-w-[330px] text-center mb-6">
                            Oops! looks like there are no product details available at the moment.
                            Check back later
                        </p>
                    </div>
                );

            case 1:
                return fetchingSupplierOngoingPo ? (
                    <div className="my-8">
                        <Loader size={8} />
                    </div>
                ) : supplierOngoingPo?.purchaseOrders?.length >= 1 ? (
                    <div className="">
                        <div className="hidden lg:block">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isScrollable={false}
                                // isScrolled={isScrolled}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>
                        <div className="lg:hidden space-y-2.5">
                            {supplierOngoingPo?.purchaseOrders?.map((po, idx) => {
                                return (
                                    <div
                                        className=" border border-slate-200 rounded px-4 py-3.5 font-rocGroteskMedium flex justify-between items-center"
                                        key={idx}
                                    >
                                        <div className="grid grid-cols-2 gap-2">
                                            <div
                                                className={`text-gm-50 text-sm border-r border-slate-200 pr-4 `}
                                            >
                                                <p>PO {po?.taId}</p>
                                            </div>
                                            <div className={`text-gm-50 text-sm  pr-4`}>
                                                <span className="">
                                                    Tol. Amt:{" "}
                                                    {getCurrencyFromCurrencyCode(
                                                        po?.totalAmount?.currency
                                                    )}
                                                    {po?.totalAmount?.amount?.toLocaleString()}
                                                </span>
                                            </div>
                                            <div
                                                className={`text-[#64748b] text-sm border-r border-slate-200 pr-4 `}
                                            >
                                                <p className="">
                                                    Issue Date:{" "}
                                                    {moment(po?.issuedDate)?.format("DD MMM, YYYY")}
                                                </p>
                                            </div>

                                            <div className={` text-sm text-[#64748B] `}>
                                                <p className="">
                                                    Due Date:
                                                    {moment(po?.dueDate)?.format("DD MMM, YYYY")}
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            className={`text-sm  space-x-1.5 flex items-center px-2.5 py-1.5 rounded-[20px]
                     ${
                         po?.currentStatus === "accepted"
                             ? " text-[#0F172A]"
                             : po?.currentStatus === "pending" || po?.currentStatus === "draft"
                             ? " text-[#142837]"
                             : po?.currentStatus === "rejected"
                             ? " text-[#0F172A]"
                             : po?.currentStatus === "in-transit"
                             ? "text-[#EA580C]"
                             : po?.currentStatus === "completed" && " text-slate-500"
                     }`}
                                        >
                                            <div
                                                className={`h-2 w-2 bg-[#3668E8] rounded-full
                        ${
                            po?.currentStatus === "accepted"
                                ? "bg-[#16C6A4]"
                                : po?.currentStatus === "pending" || po?.currentStatus === "draft"
                                ? "bg-[#3668E8]"
                                : po?.currentStatus === "rejected"
                                ? "bg-[#FF3D1D]"
                                : po?.currentStatus === "in-transit"
                                ? "bg-[#EA580C]"
                                : po?.currentStatus === "completed" && "bg-slate-500"
                        }`}
                                            ></div>
                                            <p className={`font-rocGroteskMedium capitalize`}>
                                                {po?.currentStatus === "in-transit"
                                                    ? "In transit"
                                                    : po?.currentStatus}
                                            </p>
                                        </div>

                                        <div className={` `} onClick={(e) => e.stopPropagation()}>
                                            <Dropdown
                                                dropdown={
                                                    <i className="ri-more-2-fill text-lg text-slate-500"></i>
                                                }
                                                dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                                dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-100px] `}
                                                dropdownItemsClasses={``}
                                                name={"faq-dropdown"}
                                                dropdownOptions={[
                                                    {
                                                        label: "Preview",
                                                        value: "preview",
                                                        action: () => {
                                                            navigate(`/dashboard/purchase`);
                                                        },
                                                    },
                                                    // {
                                                    //     label: "View Details",
                                                    //     value: "view details",
                                                    //     action: () => {},
                                                    // },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center justify-center flex-col mt-20">
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1714100916/Vector_2_qctd9q.svg"
                            alt=""
                            className="mb-6"
                        />
                        <p className="text-gm-40 text-base font-rocGroteskMedium max-w-[330px] text-center mb-6">
                            It seems there are no purchase orders available right now
                        </p>
                    </div>
                );

            case 2:
                return fetchingSupplierQuotes ? (
                    <div className="my-8">
                        <Loader size={8} />
                    </div>
                ) : supplierQuotes?.length >= 1 ? (
                    <div className="max-lg:hidden">
                        <CustomTable
                            tableBody={quoteTableBody}
                            tableHeader={quoteTableHeader}
                            isScrollable={false}
                            // isScrolled={isScrolled}
                            isCellBordered={false}
                            isCellSelectable={false}
                            isAllSelectable={false}
                            headerContainerClass="!bg-slate-50"
                            headerItemClass="font-rocGroteskMedium "
                            tableClass="mb-12"
                        />

                        <div className="lg:hidden space-y-2.5">
                            {supplierQuotes?.map((quote, idx) => {
                                 return (
                                     <div
                                         className=" border border-slate-200 rounded px-4 py-3.5 font-rocGroteskMedium flex justify-between items-center"
                                         key={idx}
                                     >
                                         <div className="grid grid-cols-2 gap-2">
                                             <div
                                                 className={`text-gm-50 text-sm border-r border-slate-200 pr-4 `}
                                             >
                                                 <p>PO {quote?.taId}</p>
                                             </div>
                                             <div className={`text-gm-50 text-sm  pr-4`}>
                                                 <span className="">
                                                     Tol. Amt:
                                                     {getCurrencyFromCurrencyCode(
                                                         quote?.amount?.currency
                                                     )}
                                                     {quote?.amount?.amount?.toLocaleString()}
                                                 </span>
                                             </div>
                                             <div
                                                 className={`text-[#64748b] text-sm border-r border-slate-200 pr-4 `}
                                             >
                                                 <p className="">
                                                     Val. period:{" "}
                                                     {moment(quote?.dueDate)?.format(
                                                         "DD MMM, YYYY"
                                                     )}
                                                 </p>
                                             </div>

                                             <div className={` text-sm text-[#64748B] `}>
                                                 <p className="">
                                                     Quote date:
                                                     {moment(quote?.createdAt)?.format(
                                                         "DD MMM, YYYY"
                                                     )}
                                                 </p>
                                             </div>
                                         </div>

                                         <div className={`py-3 pr-3 font-rocGroteskMedium`}>
                                             <div className="text-sm space-x-1.5 flex items-center ">
                                                 <div
                                                     className={`h-2 w-2  rounded-full ${
                                                         quote?.currentStatus === "approved" ||
                                                         quote?.currentStatus === "accepted"
                                                             ? "bg-[#16C6A4]"
                                                             : quote?.currentStatus === "pending"
                                                             ? "bg-[#3668E8]"
                                                             : quote?.currentStatus ===
                                                                   "rejected" && "bg-[#FF3D1D]"
                                                     }`}
                                                 ></div>
                                                 <p className="font-rocGroteskMedium text-[#0F172A] capitalize">
                                                     {quote?.currentStatus}
                                                 </p>
                                             </div>
                                         </div>

                                         <div
                                             className={` flex justify-center lg:w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                                             onClick={(e) => e.stopPropagation()}
                                         >
                                             <Dropdown
                                                 dropdown={
                                                     <i className="ri-more-2-fill text-lg text-slate-500"></i>
                                                 }
                                                 dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                                 dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-100px] `}
                                                 dropdownItemsClasses={``}
                                                 name={"faq-dropdown"}
                                                 dropdownOptions={[
                                                     {
                                                         label: "Preview",
                                                         value: "preview",
                                                         action: () => {
                                                             navigate(`/dashboard/purchase`);
                                                         },
                                                     },
                                                     // {
                                                     //     label: "View Details",
                                                     //     value: "view details",
                                                     //     action: () => {},
                                                     // },
                                                 ]}
                                             />
                                         </div>
                                     </div>
                                 );
                            






                            })}
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center justify-center flex-col mt-20">
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1714100916/Vector_2_qctd9q.svg"
                            alt=""
                            className="mb-6"
                        />
                        <p className="text-gm-40 text-base font-rocGroteskMedium max-w-[330px] text-center mb-6">
                            Looks like there are no quotes to display at the moment.
                        </p>
                    </div>
                );

            case 3:
                return (
                    <CustomTable
                        tableBody={returnGoodsTableBody}
                        tableHeader={returnGoodsTableHeader}
                        isScrollable={false}
                        // isScrolled={isScrolled}
                        isCellBordered={false}
                        isCellSelectable={false}
                        isAllSelectable={false}
                        headerContainerClass="!bg-slate-50"
                        headerItemClass="font-rocGroteskMedium "
                        tableClass="mb-12"
                    />
                );

            default:
                return (
                    <CustomTable
                        tableBody={tableBody}
                        tableHeader={tableHeader}
                        isScrollable={false}
                        // isScrolled={isScrolled}
                        isCellBordered={false}
                        isCellSelectable={false}
                        isAllSelectable={false}
                        headerContainerClass="!bg-slate-50"
                        headerItemClass="font-rocGroteskMedium "
                        tableClass="mb-12"
                    />
                );
        }
    };

    const option = {
        // title: {
        //     text: "Record count",
        //     color: "#142837",
        //     padding: [10, 0, 0, 60],
        // },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "none",
            },
            height: "auto",
        },
        legend: {},
        grid: {
            show: false,
            left: "1%",
            right: "4%",
            bottom: "1%",
            containLabel: true,
        },
        xAxis: {
            type: "value",
            boundaryGap: [0, 0.01],
            position: "top",
            axisLine: {
                show: false,
            },

            splitLine: {
                show: false,
            },
            axisLabel: {
                fontSize: 12, // Set font size for x-axis labels
                color: "#89939B", // Set font color for x-axis labels
            },
        },
        yAxis: {
            type: "category",
            data: supplierDetails?.products?.map((product) => product.productName),
            axisTick: {
                show: false,
            },
            axisLabel: {
                margin: 10,
                fontSize: 13,
                color: "#89939B",
            },
            axisLine: {
                lineStyle: {
                    color: "#E2E8F0",
                },
            },
        },
        series: [
            {
                type: "bar",
                data: supplierDetails?.products?.map((product) =>
                    Math.round(Number(product.quantity))
                ),
                label: {
                    show: true,
                    position: "insideRight",
                    formatter: (params) => {
                        return params.value;
                    },
                    color: "white",
                },
                itemStyle: {
                    color: "#16C6A4",
                    barBorderRadius: [0, 4, 4, 0],
                },
                barWidth: 30,
            },
        ],
    };

    return (
        <PageFrame isLoading={fetchingSupplierDetails}>
            <div className="bg-white lg:border lg:rounded-[8px] py-4  lg:border-[#F1F5F9]">
                <div className="pb-4 lg:border-b px-4 border-slate-100 w-full">
                    <div
                        className="flex items-center space-x-1 mb-5 cursor-pointer text-[#3B4C58] text-sm font-rocGroteskMedium relative"
                        onClick={() => navigate("/dashboard/suppliers")}
                    >
                        <i className="ri-arrow-left-line text-[#142837]"></i>
                        <p className="underline hidden lg:block">Back</p>
                        <p className="block lg:hidden text-[#0F172A] text-base">Supplier Details</p>

                        <div className="flex items-center gap-6 justify-end absolute right-0 lg:hidden">
                            <Button
                                // btnText={"Delete supplier"}
                                type={"button"}
                                btnClassname="!py-2 !text-[14px] !px-0 !bg-transparent !text-[#FF3D1D]"
                                onClick={() => {
                                    setConfirmDeleteModal(true);
                                    setShowModals(true);
                                    setDeleteSupplier({
                                        id: supplierDetails?.vendor?._id,
                                        name: supplierDetails?.vendor?.profile?.businessName,
                                    });
                                }}
                                icon={<i className="ri-delete-bin-line text-[#FF3D1D] text-xl"></i>}
                            />
                            <Button
                                // btnText={"Edit supplier details"}
                                type={"button"}
                                btnClassname="!py-2.5 !text-[14px] !px-0 !w-fit !bg-transparent"
                                onClick={() =>
                                    navigate(
                                        `/dashboard/suppliers/edit-supplier/${supplierDetails?.vendor?._id}`
                                    )
                                }
                                icon={<i className="ri-edit-box-line text-xl"></i>}
                            />
                        </div>
                    </div>
                    <div className="hidden lg:block">
                        <PageTitleSection
                            leftComp={
                                <h3 className="text-[19px] leading-[26px] font-rocGroteskMedium">
                                    Supplier Management
                                </h3>
                            }
                            child={
                                <div className="flex items-center space-x-2">
                                    <i className="ri-arrow-drop-right-line text-2xl text-[#0F172A]"></i>
                                    <h3 className="text-[19px] text-[#0F172A] leading-[26px] font-rocGroteskMedium">
                                        Supplier details
                                    </h3>
                                </div>
                            }
                        />
                    </div>
                </div>

                <div className="lg:mt-5 px-4 ">
                    <div className="">
                        <p className="text-base text-[#64748B] font-rocGroteskMedium hidden lg:block">
                            Supplier details
                        </p>
                        <div className="flex mt-3 space-x-2  justify-between items-center pb-3 lg:border-b border-dotted border-[#E0E0E0]">
                            <div className="flex space-x-2 items-center w-full">
                                {/* <div className="w-[60px] h-[60px] bg-[#F1F5F9] p-[2px] flex justify-center items-center">
                                    <VendorAvatar
                                        size={40}
                                        imageSrc={supplierDetails?.vendor?.profile?.logo}
                                        name={supplierDetails?.vendor?.profile?.businessName}
                                        containerClassname="!rounded-none"
                                    />
                                </div> */}
                                <div className="font-rocGroteskMedium">
                                    <p className="text-[#64748B] text-sm hidden lg:block">
                                        Supplier
                                    </p>
                                    <p className="text-base lg:text-xl text-gm-50">
                                        {supplierDetails?.vendor?.profile?.businessName}
                                    </p>
                                </div>
                            </div>
                            <div className="hidden lg:flex items-start gap-2.5 w-full justify-end">
                                <Button
                                    btnText={"Delete supplier"}
                                    type={"button"}
                                    btnClassname="!py-2 !text-[14px] !px-4 !bg-transparent !text-[#FF3D1D] !border-[#FF3D1D] !border
                                    !w-fit"
                                    onClick={() => {
                                        setConfirmDeleteModal(true);
                                        setShowModals(true);
                                        setDeleteSupplier({
                                            id: supplierDetails?.vendor?._id,
                                            name: supplierDetails?.vendor?.profile?.businessName,
                                        });
                                    }}
                                    icon={<i className="ri-delete-bin-line text-[#FF3D1D]"></i>}
                                />
                                <Button
                                    btnText={"Edit supplier details"}
                                    type={"button"}
                                    btnTextClassName="underline"
                                    btnClassname="!py-2.5 !text-[14px] !px-4 !w-fit"
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/suppliers/edit-supplier/${supplierDetails?.vendor?._id}`
                                        )
                                    }
                                    icon={<i className="ri-edit-box-line"></i>}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="lg:pb-4 border-b border-dotted border-[#E0E0E0]">
                        <div className="lg:p-6 bg-white lg:shadow-faintShadow mt-5 rounded grid lg:grid-cols-3 w-full lg:gap-8">
                            <div className=" w-full border-b  lg:border-b-0 flex items-center justify-between py-2.5 lg:py-0  lg:block">
                                <p className="text-[#64748B] text-sm font-rocGroteskMedium mb-1">
                                    Contact person
                                </p>
                                <div className="flex items-center space-x-1">
                                    <VendorAvatar
                                        imageSrc={supplierDetails?.vendor?.profile?.avatar}
                                        size={24}
                                        name={supplierDetails?.vendor?.firstName}
                                    />
                                    <p className="text-sm lg:text-base font-rocGroteskMedium text-gm-50">
                                        {supplierDetails?.vendor?.firstName}{" "}
                                        {supplierDetails?.vendor?.lastName}
                                    </p>
                                </div>
                            </div>
                            <div className="border-b flex items-center justify-between py-2.5 lg:py-0  lg:block  lg:border-b-0 lg:border-l lg:pl-4 lg:pl-14  w-full border-[#E2E8F0]">
                                <p className="text-[#64748B] text-sm font-rocGroteskMedium mb-1">
                                    Email
                                </p>
                                <div className="flex items-center space-x-1">
                                    <p className="text-sm lg:text-base font-rocGroteskMedium text-gm-50">
                                        {supplierDetails?.vendor?.email}
                                    </p>
                                </div>
                            </div>
                            <div className="border-b flex items-center justify-between py-2.5 lg:py-0  lg:block  lg:border-b-0 lg:border-l lg:pl-4 lg:pl-14 w-full border-[#E2E8F0]">
                                <p className="text-[#64748B] text-sm font-rocGroteskMedium mb-1">
                                    Phone number
                                </p>
                                <div className="flex items-center space-x-1">
                                    <p className="text-sm lg:text-base font-rocGroteskMedium text-gm-50">
                                        {supplierDetails?.vendor?.mobileExtension}{" "}
                                        {supplierDetails?.vendor?.mobile}
                                    </p>
                                </div>
                            </div>
                            <div className=" w-full border-b  lg:border-b-0 flex items-center justify-between py-2.5 lg:py-0  lg:block">
                                <p className="text-[#64748B] text-sm font-rocGroteskMedium mb-1">
                                    Address
                                </p>
                                <p className="text-sm lg:text-base flex items-center space-x-1 text-gm-50 font-rocGroteskMedium">
                                    {supplierDetails?.vendor?.profile?.address?.address}
                                </p>
                            </div>
                            <div className="border-b flex items-center justify-between py-2.5 lg:py-0  lg:block  lg:border-b-0 lg:border-l lg:pl-4 lg:pl-14 w-full border-[#E2E8F0]">
                                <p className="text-[#64748B] text-sm font-rocGroteskMedium mb-1">
                                    Risk status
                                </p>
                                {supplierDetails?.vendor?.riskDetails?.riskScore && (
                                    <div className="flex items-center space-x-1 text-sm lg:text-base">
                                        <p className="font-rocGroteskMedium text-[#64748B] pr-1">
                                            {
                                                checkRisk(
                                                    supplierDetails?.vendor?.riskDetails?.riskScore
                                                )?.value
                                            }{" "}
                                            risk
                                        </p>

                                        <img
                                            src={
                                                checkRisk(
                                                    supplierDetails?.vendor?.riskDetails?.riskScore
                                                )?.img
                                            }
                                            className="h-[18px] w-[24px]"
                                            alt=""
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="flex items-center justify-between py-2.5 lg:py-0  lg:block lg:border-l lg:pl-4 lg:pl-14 w-full border-[#E2E8F0]">
                                <p className="text-[#64748B] text-sm font-rocGroteskMedium mb-1">
                                    Risk score
                                </p>
                                {supplierDetails?.vendor?.riskDetails?.riskScore && (
                                    <div className="flex items-center space-x-1">
                                        <p className="text-sm lg:text-base font-rocGroteskMedium text-gm-50">
                                            {`${supplierDetails?.vendor?.riskDetails?.riskScore}/10`}
                                        </p>
                                        <Rating
                                            ratingNo={
                                                supplierDetails?.vendor?.riskDetails?.riskScore
                                            }
                                            size="xs"
                                            totalRating={10}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="text-base mt-5 mb-3 text-[#142837] font-rocGroteskMedium hidden lg:">
                            Supplier product report
                        </p>
                        <div className="lg:flex  w-full lg:space-x-5 items-stretch">
                            <div className="lg:p-6 w-full bg-white lg:shadow-faintShadow lg:rounded">
                                {/* <div className="  pb-4  border-b border-dotted border-[#E0E0E0] flex items-center justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[#64748B] text-sm font-rocGroteskMedium mb-1">
                                            Payment terms
                                        </p>
                                        <div className="flex items-center space-x-1">
                                            <p className="text-base font-rocGroteskMedium text-gm-50">
                                                {supplierDetails?.contract?.[0]?.paymentTerms
                                                    ? supplierDetails?.contract?.[0]?.paymentTerms
                                                    : "---"}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="border-b flex items-center justify-between py-2.5 lg:py-0  lg:block  lg:border-b-0 lg:border-l lg:pl-4 lg:pl-14 w-full border-[#E2E8F0]">
                                        <p className="text-[#64748B] text-sm font-rocGroteskMedium mb-1">
                                            Risk Score
                                        </p>
                                        {supplierDetails?.vendor?.riskDetails?.riskScore && (
                                            <div className="flex items-center space-x-1">
                                                <p className="text-base font-rocGroteskMedium text-gm-50">
                                                    {`${supplierDetails?.vendor?.riskDetails?.riskScore}/10`}
                                                </p>
                                                <Rating
                                                    ratingNo={
                                                        supplierDetails?.vendor?.riskDetails
                                                            ?.riskScore
                                                    }
                                                    size="xs"
                                                    totalRating={10}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="pb-4 mt-5 border-b border-dotted border-[#E0E0E0]">
                                    <p className="text-base text-[#64748B] mb-3 font-rocGroteskMedium">
                                        Contract details
                                    </p>

                                    <div className=" rounded flex items-center justify-between w-full">
                                        <div className="w-full">
                                            <p className="text-[#64748B] text-sm font-rocGroteskMedium mb-1">
                                                Type
                                            </p>
                                            <div className="flex items-center space-x-1">
                                                <p className="text-base font-rocGroteskMedium text-gm-50">
                                                    {truncate(
                                                        supplierDetails?.contract?.[0]?.document
                                                            ?.name,
                                                        { length: 20 }
                                                    ) || "---"}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="border-b flex items-center justify-between py-2.5 lg:py-0  lg:block  lg:border-b-0 lg:border-l lg:pl-4 lg:pl-14 w-full border-[#E2E8F0]">
                                            <p className="text-[#64748B] text-sm font-rocGroteskMedium mb-1">
                                                Exp. Date
                                            </p>
                                            <p className="text-base font-rocGroteskMedium text-gm-50">
                                                {supplierDetails?.contract?.[0]?.renewalDate
                                                    ? moment(
                                                          supplierDetails?.contract?.[0]
                                                              ?.renewalDate
                                                      )?.format("DD MMM, YYYY")
                                                    : "---"}
                                            </p>
                                        </div>
                                        <div className="border-b flex items-center justify-between py-2.5 lg:py-0  lg:block  lg:border-b-0 lg:border-l lg:pl-4 lg:pl-14 w-full border-[#E2E8F0]">
                                            <p className="text-[#64748B] text-sm font-rocGroteskMedium mb-1">
                                                Ren. date
                                            </p>
                                            <p className="text-base font-rocGroteskMedium text-gm-50">
                                                {supplierDetails?.contract?.[0]?.renewalDate
                                                    ? moment(
                                                          supplierDetails?.contract?.[0]
                                                              ?.renewalDate
                                                      )?.format("DD MMM, YYYY")
                                                    : "---"}
                                            </p>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="pt-6 lg:pt-0 lg:mt-5 ">
                                    <p className="text-base text-[#64748B] mb-3 font-rocGroteskMedium hidden lg:block">
                                        Supplier expenditure details
                                    </p>
                                    <div className="pb-6 lg:pb-0 flex items-center justify-between border-b border-dotted border-[#E0E0E0] lg:border-0">
                                        <div className="font-rocGroteskMedium space-y-1">
                                            <p className="text-gm-50 text-sm mb-3.5 lg:mb-0">
                                                Total expenses
                                            </p>
                                            <p className="text-p-50  font-rocGroteskBold text-2xl">
                                                {`${getCurrencyFromCurrencyCode(
                                                    supplierDetails?.totalExpenses?.currency
                                                )}
                                                ${supplierDetails?.totalExpenses?.amount?.toLocaleString()}`}
                                            </p>
                                        </div>
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1712545912/Statistic_mhljwc.svg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`py-6 lg:pt-0 lg:pb-4 lg:mt-5 border-b border-dotted border-[#E0E0E0] lg:border-0`}
                                >
                                    <p className="text-sm  text-gm-50 lg:text-[#64748B] mb-4 lg:mb-3 font-rocGroteskMedium ">
                                        Risk summary
                                    </p>
                                    {supplierDetails?.vendor?.riskDetails?.riskScore && (
                                        <div
                                            className={`flex items-center justify-between p-4 border ${
                                                checkRisk(
                                                    supplierDetails?.vendor?.riskDetails?.riskScore
                                                )?.value === "Medium" &&
                                                "border-[#6562E5] bg-light-purple"
                                            } ${
                                                checkRisk(
                                                    supplierDetails?.vendor?.riskDetails?.riskScore
                                                )?.value === "Low" &&
                                                "border-[#16C6A4] bg-light-green"
                                            } ${
                                                checkRisk(
                                                    supplierDetails?.vendor?.riskDetails?.riskScore
                                                )?.value === "High" &&
                                                "border-[#FF3D1D] bg-light-red"
                                            }   shadow-boxShadow-8 rounded`}
                                        >
                                            <div className="font-rocGroteskMedium">
                                                <p className="text-gm-50 text-sm sm:text-base ">
                                                    {`${
                                                        checkRisk(
                                                            supplierDetails?.vendor?.riskDetails
                                                                ?.riskScore
                                                        )?.value
                                                    } risk supplier`}
                                                </p>
                                                <p className="text-[#64748B] text-sm">
                                                    {
                                                        supplierDetails?.vendor?.riskDetails
                                                            ?.riskSummary
                                                    }
                                                </p>
                                            </div>

                                            <img
                                                src={
                                                    checkRisk(
                                                        supplierDetails?.vendor?.riskDetails
                                                            ?.riskScore
                                                    )?.img
                                                }
                                                className="h-[23px] w-[24px]"
                                                alt=""
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="w-full py-6 lg:p-6 lg:bg-white lg:shadow-faintShadow lg:rounded">
                                <div className="flex items-center gap-3">
                                    <p className=" text-gm-50 font-rocGroteskMedium text-sm sm:text-base">
                                        Record Count
                                    </p>
                                    {supplierDetails?.products?.length < 1 && (
                                        <div className="flex items-center gap-3">
                                            <p className="h-[13px] w-[1px] text-slate-400 border-l border-slate-400">
                                                {" "}
                                            </p>
                                            <div className="text-[#FF3D1D] font-rocGroteskMedium bg-[rgba(255,61,29,0.03)] text-sm px-3 py-1 rounded-full ">
                                                No data entry
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {supplierDetails?.products?.length >= 1 ? (
                                    <ReactECharts option={option} style={{ height: "450px" }} />
                                ) : (
                                    <div className="flex items-center justify-center flex-col mt-20">
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1713868446/supplierProduct_sa8wh1.svg"
                                            alt=""
                                            className="mb-6"
                                        />
                                        <p className="text-gm-40 text-md font-rocGroteskMedium max-w-[330px] text-center">
                                            There is no product reports available for this supplier
                                            at the moment. Stay tuned for updates or reach out to
                                            the supplier directly
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="mt-14 border-t border-dotted pt-5">
                        <TabsContainer
                            tabs={tabs}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            component={displayActiveTab()}
                            className="!px-0"
                            itemClassName="!pb-2"
                            borderLineClase={"!text-slate-100 w-full  mb-4"}
                        />
                    </div>
                </div>
                {showQuantity && (
                    <ModalContainer
                        open={showQuantity}
                        showCloseIcon={false}
                        tailwindClassName="w-[664px]"
                        closeModal={() => {
                            setShowQuantity(false);
                            setInventoryId("");
                            setProductInfo({});
                        }}
                    >
                        <div className="max-h-[70vh] overflow-y-scroll">
                            {fetchingSupplierProductHistory ? (
                                <Loader />
                            ) : (
                                <div className="bg-white p-6 space-y-1.5 rounded-lg shadow-cardShadow relative">
                                    <h3 className="text-sm text-gm-50 font-rocGroteskMedium">
                                        Product name : {productInfo?.name}
                                    </h3>
                                    <h3 className="text-sm text-gm-50 font-rocGroteskMedium">
                                        Category : {productInfo?.category}
                                    </h3>
                                    <h3 className="text-sm text-[#64748B] font-rocGroteskMedium">
                                        Total quantity : {supplierProductHistory?.totalQuantity}
                                    </h3>

                                    <div className="mt-3">
                                        <CustomTable
                                            tableBody={quantityTableBody}
                                            tableHeader={quantityTableHeader}
                                            isScrollable={false}
                                            // isScrolled={isScrolled}
                                            isCellBordered={false}
                                            isCellSelectable={false}
                                            isAllSelectable={false}
                                            headerContainerClass="!bg-slate-50"
                                            headerItemClass="font-rocGroteskMedium "
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </ModalContainer>
                )}

                {showModals && (
                    <DeleteSupplierModal
                        setConfirmDeleteModal={setConfirmDeleteModal}
                        confirmDeleteModal={confirmDeleteModal}
                        deleteSupplier={deleteSupplier}
                        detailsPage={true}
                    />
                )}
            </div>
        </PageFrame>
    );
};

export default SupplierDetails;
