import { useEffect, useMemo, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { debounce, uniqBy } from "lodash";
import useInfiniteScroll from "../useInfinteScroll";

const useGetInventoryIncidentStocking = (inventoryId?: string, scrollContent?: HTMLElement) => {
    const dispatch = useAppDispatch();
    const {
        fetchingInventoryIncidentStocking,
        fetchedInventoryIncidentStockingSuccess,
        fetchedInventoryIncidentStockingFailure,
    } = useAppSelector((state) => state.inventory);

    const [incidentList, seIncidentList] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [warehouseId, setWarehouseId] = useState<string>("");
    const [incidentType, setIncidentType] = useState<string>("");

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10,
            scrollContent
        );

    const handleIncidentTypeChange = (value: string) => {
        setUpdatedPage(1);
        setIncidentType(value);
    };

    const handleClearFilter = () => {
        setUpdatedPage(1);
        setIncidentType("");
    };

    const handleWarehouseChange = (value: string) => {
        setUpdatedPage(1);
        setWarehouseId(value);
    };

    useEffect(() => {
        dispatch(
            inventoryActions.fetchInventoryIncidentStocking(
                warehouseId,
                10,
                updatedPage,
                "overstocking",
                inventoryId
            )
        );

        return () => {
            dispatch(inventoryActions.resetFetchInventoryIncidentStocking());
        };
    }, [dispatch, warehouseId, incidentType, updatedPage, inventoryId]);

    useEffect(() => {
        if (Boolean(fetchedInventoryIncidentStockingSuccess) && incidentType === "overstocking") {
            console.log(
                "fetchedInventoryIncidentStockingSuccess",
                fetchedInventoryIncidentStockingSuccess
            );
            seIncidentList((prev) =>
                warehouseId || updatedPage === 1
                    ? uniqBy([...fetchedInventoryIncidentStockingSuccess?.incidents], "_id")
                    : uniqBy(
                          [...prev, ...fetchedInventoryIncidentStockingSuccess?.incidents],
                          "_id"
                      )
            );
            setPagination({
                currentPage: fetchedInventoryIncidentStockingSuccess.pagination?.current,
                noOfPages: fetchedInventoryIncidentStockingSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchedInventoryIncidentStockingSuccess, warehouseId, incidentType]);

    return {
        isFetching: fetchingInventoryIncidentStocking,
        error: fetchedInventoryIncidentStockingFailure,
        data: incidentList,
        seIncidentList,
        pagination,
        setPagination,
        warehouseId,
        incidentType,
        handleIncidentTypeChange,
        handleClearFilter,
        handleWarehouseChange,
        isLoadingMore,
    };
};

export default useGetInventoryIncidentStocking;
