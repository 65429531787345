import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import CustomTableLoader from "components/common/CustomTableLoader";
import CustomToast from "components/common/CustomToast";
import Dropdown from "components/common/Dropdown";
import AddressInput from "components/common/InputField/AddressInput";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import ToggleInput from "components/common/InputField/ToggleInput";
import Loader from "components/common/Loader";
import { Map } from "components/common/Map/Map";
import ModalContainer from "components/common/ModalContainer";
import PageTitleSection from "components/common/PageTitleSection";
import ProgressBar from "components/common/ProgressBar";
import TabsContainer from "components/common/TabsContainer";
import VendorAvatar from "components/common/VendorAvatar";
import QuotesHistory from "components/views/Ldb/Dashboard/Quotes/QuotesHistory";
import PurchaseOrderDataDetails from "components/views/Synth2.0/Order/PurchaseOrderDetails";
import { formatMoney, getCurrencyFromCurrencyCode, onScroll } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { debounce, divide, set, truncate, uniqBy } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { authActions, inventoryActions, orderActions } from "redux/Ldb/actions";
import { shipmentActions } from "redux/Ldb/actions/shipmentActions";
import { oceanCarriers } from "variables/oceanCarriers";
import PurchaseOrderShipmentDetails from "components/views/Synth2.0/Order/PurchaseOrderShipments"
const ShipmentPurchaseDetails = () => {
    const { purchaseId } = useParams();
    const limit = 10;
    const [response, setResponse] = useState<any>([]);
    const [productList, setProductList] = useState<any>([]);
    const [productData, setProductData] = useState<any>([]);
    const [poShipment, setPoShipment] = useState([]);
    const [totalShipment, setTotalShipment] = useState<number>(0);
    const [loadMorePoShipment, setLoadMorePoShipment] = useState(false);
    const [levels, setLevels] = useState([""]);
    const [shipmentSuccessModal, setShipmentSuccessModal] = useState<boolean>(false);
    const [country, setCountry] = useState<string>("");
    const [addShipment, setAddShipment] = useState(false);
    const [showSuccessShipmentAdded, setshowSuccessShipmentAdded] = useState(false);
    const [showShipment, setShowShipment] = useState(poShipment?.[0]?._id);
    const [mode, setMode] = useState("");
    const [address, setAddress] = useState<{ [key: string]: any }>({});
    const [activeTab, setActiveTab] = useState(0);
    const [activeTabTitle, setActiveTabTitle] = useState(0);
    const tabs = ["Product", "Quote history"];
    const smallTabs = ["Shipments", "Product", "Quote history", ];
    const tabTitle = ["Purchase order", "Shipment details"];
    const [clickedIndex, setClickedIndex] = useState(0);
    const [poShipmentPage, setPoShipmentPage] = useState<number>(1);
    const [shipmentDetails, setShipmentDetails] = useState<{ [key: string]: any }>({
        billOfLading: "",
        trackingLink: "",
        carrier: "",
        billNumber: "",
        destination: "",
        additionalInfo: "",
    });
    const [debouncedFilter, setDebouncedFilter] = useState({
        search: "",
        inventorySearch: "",
    });
    const [poShipmentPagination, setPoShipmentPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [warehouseList, setWarehouseList] = useState([]);
    const shipmentCardContent = document.getElementById("shipmentCardContent");

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { fetchingSinglePurchaseOrder, fetchedSinglePurchaseOrderSuccess } = useAppSelector(
        (state) => state.order
    );

    const { createShipment, createdShipmentSuccess, fetchPoShipmentSuccess, fetchingPoShipment } =
        useAppSelector((state) => state.shipment);

    const { fetchingWarehouseList, fetchedWarehouseListSuccess } = useAppSelector(
        (state) => state.inventory
    );

    const flattenedData = poShipment.flatMap(({ taId, status, name, productDetails }) =>
        productDetails?.map(({ category, inventory, quantity, _id }) => ({
            category,
            inventory,
            taId,
            status,
            name,
            quantity,
            _id,
        }))
    );

    const tableHeader = [
        { title: "Shipping ID", widthClass: "w-[8.6%]" },
        { title: "Product name", widthClass: "w-[13.9%]" },
        { title: "Qty shipped", widthClass: "w-[14.3%]" },
        { title: "Shipping date", widthClass: "w-[11.9%]" },
        { title: "Est. time of arrival", widthClass: "w-[10.7%]" },
        { title: "Status", widthClass: "w-[12.4%]" },
    ];
    const tableBody = flattenedData?.map((data, idx) => [
        {
            content: (
                <div
                    key={idx}
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-500 `}
                >
                    <p>SYN{data?.taId}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center space-x-3">
                        <VendorAvatar
                            imageSrc={data?.inventory?.productImageDetails?.productImages?.[0]}
                            size={32}
                            name={data?.inventory?.productName}
                        />
                        <span>
                            {truncate(data?.inventory?.productName ?? "----", { length: 10 })}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <span>{formatMoney().format(data?.quantity) ?? 0}</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <span>
                        {data?.deliveryDate
                            ? moment(data?.deliveryDate ?? "----").format("MMM DD, YYYY")
                            : "----"}
                    </span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    {data?.inventory?.deliveryTime ? (
                        <span>{`${Math.abs(data?.inventory?.deliveryTime?.time)} ${
                            data?.inventory?.deliveryTime?.unit
                        }`}</span>
                    ) : (
                        "-----"
                    )}
                </div>
            ),
        },

        {
            content: (
                <div
                    className={`py-3 pr-3 space-x-1  font-rocGroteskMedium flex items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    <div
                        className={`h-2 w-2 ${
                            data?.status?.toLowerCase() === "approved" && "bg-g-50"
                        } ${data?.status?.toLowerCase() === "delivered" && "bg-g-50"} 
                        ${data?.status?.toLowerCase() === "pending" && "bg-[#6562E5]"} 
                        ${data?.status?.toLowerCase() === "in-transit" && "bg-[#F59E0B]"} 
                        rounded-full`}
                    ></div>
                    <p>
                        {data?.status?.toLowerCase() === "in-transit"
                            ? "In transit"
                            : data?.status.charAt(0).toUpperCase() + data?.status.slice(1)}
                    </p>
                </div>
            ),
        },
    ]);

    const displayActiveTab = () => {
        switch (activeTab) {
          case 0:
                return fetchingPoShipment && flattenedData?.length === 0 ? (
                    <div>
                        <div className="md:block hidden">
                            <CustomTableLoader tableHeader={tableHeader} />
                        </div>
                        <div className="md:hidden block">
                            <Loader size={16} />
                        </div>
                    </div>
                ) : poShipment?.length > 0 ? (
                    <div className="">
                        <div className="max-lg:hidden">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isScrollable={false}
                                // isScrolled={isScrolled}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>
                        <div className="max-lg:block space-y-2 hidden">
                            {flattenedData?.map((data, idx) => (
                                <div className="border border-[#E2E8F0] py-4 sm:px-4 px-2 rounded">
                                    <div className="flex items-center justify-between">
                                        <p className="text-[#142837] font-rocGroteskMedium text-sm">
                                            SYN{data?.taId}
                                        </p>
                                        <div className="flex items-center space-x-1">
                                            <div
                                                className={`h-2 w-2 ${
                                                    data?.status?.toLowerCase() === "approved" &&
                                                    "bg-g-50"
                                                } ${
                                                    data?.status?.toLowerCase() === "delivered" &&
                                                    "bg-g-50"
                                                } 
                        ${data?.status?.toLowerCase() === "pending" && "bg-[#6562E5]"} 
                        ${data?.status?.toLowerCase() === "in-transit" && "bg-[#F59E0B]"} 
                        rounded-full`}
                                            ></div>
                                            <p className="font-rocGroteskMedium text-sm text-[#0F172A]">
                                                {data?.status?.toLowerCase() === "in-transit"
                                                    ? "In transit"
                                                    : data?.status.charAt(0).toUpperCase() +
                                                      data?.status.slice(1)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-2  flex justify-between items-center">
                                        <div className=" items-center sm:flex hidden  space-x-2 font-rocGroteskMedium text-base">
                                            <p className="border-r pr-2  text-[#142837] ">
                                                {" "}
                                                {truncate(data?.inventory?.productName ?? "----", {
                                                    length: 20,
                                                })}
                                            </p>
                                            <p className="text-slate-500 text-base">
                                                {formatMoney().format(data?.quantity) ?? 0} qty
                                            </p>
                                        </div>
                                        <div className=" xs:items-center sm:hidden flex xs:flex-row flex-col xs:space-y-0 space-y-2   xs:space-x-2 font-rocGroteskMedium text-sm">
                                            <p className="xs:border-r pr-2  text-[#142837] ">
                                                {" "}
                                                {truncate(data?.inventory?.productName ?? "----", {
                                                    length: 13,
                                                })}
                                            </p>
                                            <p className="text-slate-500 text-sm">
                                                {formatMoney().format(data?.quantity) ?? 0} qty
                                            </p>
                                        </div>
                                        <div className="flex items-center  space-x-2 font-rocGroteskMedium text-base">
                                            <p className="border-r pr-2  text-slate-500 ">
                                                {data?.deliveryDate
                                                    ? moment(data?.deliveryDate ?? "----").format(
                                                          "MMM DD, YYYY"
                                                      )
                                                    : "----"}
                                            </p>
                                            <i className="text-slate-500 ri-ship-2-line"></i>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {loadMorePoShipment && fetchingPoShipment && (
                            <div className="flex my-4 justify-center">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {poShipmentPagination?.current ===
                            poShipmentPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                <p className="text-center"> End of list</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex  h-[100%]  justify-center my-[5%]">
                        <div className="space-y-2  text-center">
                            <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                            <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                Oops! looks like there are no product details <br /> available at
                                the moment. Check back later
                            </p>
                        </div>
                    </div>
                );

            case 1:
                return <QuotesHistory purchaseId={purchaseId} />;

            default:
                return poShipment?.length > 0 ? (
                    <div className="">
                        <div className="max-lg:hidden">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isScrollable={false}
                                // isScrolled={isScrolled}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>
                        {loadMorePoShipment && fetchingPoShipment && (
                            <div className="flex my-4 justify-center">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {poShipmentPagination?.current ===
                            poShipmentPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                <p className="text-center"> End of list</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                        <div className="space-y-2  text-center">
                            <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                            <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                Oops! looks like there are no product details <br /> available at
                                the moment. Check back later
                            </p>
                        </div>
                    </div>
                );
        }
    };

    const smallDisplayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <div className="w-full pb-20 md:hidden block" >
                       <PurchaseOrderShipmentDetails setAddShipment={setAddShipment} />
                    </div>
                );
            case 1:
                return fetchingPoShipment && flattenedData?.length === 0 ? (
                    <div>
                        <div className="md:block hidden">
                            <CustomTableLoader tableHeader={tableHeader} />
                        </div>
                        <div className="md:hidden block">
                            <Loader size={16} />
                        </div>
                    </div>
                ) : poShipment?.length > 0 ? (
                    <div className="">
                        <div className="max-lg:hidden">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isScrollable={false}
                                // isScrolled={isScrolled}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>
                        <div className="max-lg:block space-y-2 hidden">
                            {flattenedData?.map((data, idx) => (
                                <div className="border border-[#E2E8F0] py-4 sm:px-4 px-2 rounded">
                                    <div className="flex items-center justify-between">
                                        <p className="text-[#142837] font-rocGroteskMedium text-sm">
                                            SYN{data?.taId}
                                        </p>
                                        <div className="flex items-center space-x-1">
                                            <div
                                                className={`h-2 w-2 ${
                                                    data?.status?.toLowerCase() === "approved" &&
                                                    "bg-g-50"
                                                } ${
                                                    data?.status?.toLowerCase() === "delivered" &&
                                                    "bg-g-50"
                                                } 
                        ${data?.status?.toLowerCase() === "pending" && "bg-[#6562E5]"} 
                        ${data?.status?.toLowerCase() === "in-transit" && "bg-[#F59E0B]"} 
                        rounded-full`}
                                            ></div>
                                            <p className="font-rocGroteskMedium text-sm text-[#0F172A]">
                                                {data?.status?.toLowerCase() === "in-transit"
                                                    ? "In transit"
                                                    : data?.status.charAt(0).toUpperCase() +
                                                      data?.status.slice(1)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-2  flex justify-between items-center">
                                        <div className=" items-center sm:flex hidden  space-x-2 font-rocGroteskMedium text-base">
                                            <p className="border-r pr-2  text-[#142837] ">
                                                {" "}
                                                {truncate(data?.inventory?.productName ?? "----", {
                                                    length: 20,
                                                })}
                                            </p>
                                            <p className="text-slate-500 text-base">
                                                {formatMoney().format(data?.quantity) ?? 0} qty
                                            </p>
                                        </div>
                                        <div className=" xs:items-center sm:hidden flex xs:flex-row flex-col xs:space-y-0 space-y-2   xs:space-x-2 font-rocGroteskMedium text-sm">
                                            <p className="xs:border-r pr-2  text-[#142837] ">
                                                {" "}
                                                {truncate(data?.inventory?.productName ?? "----", {
                                                    length: 13,
                                                })}
                                            </p>
                                            <p className="text-slate-500 text-sm">
                                                {formatMoney().format(data?.quantity) ?? 0} qty
                                            </p>
                                        </div>
                                        <div className="flex items-center  space-x-2 font-rocGroteskMedium text-base">
                                            <p className="border-r pr-2  text-slate-500 ">
                                                {data?.deliveryDate
                                                    ? moment(data?.deliveryDate ?? "----").format(
                                                          "MMM DD, YYYY"
                                                      )
                                                    : "----"}
                                            </p>
                                            <i className="text-slate-500 ri-ship-2-line"></i>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {loadMorePoShipment && fetchingPoShipment && (
                            <div className="flex my-4 justify-center">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {poShipmentPagination?.current ===
                            poShipmentPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                <p className="text-center"> End of list</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex  h-[100%]  justify-center my-[5%]">
                        <div className="space-y-2  text-center">
                            <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                            <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                Oops! looks like there are no product details <br /> available at
                                the moment. Check back later
                            </p>
                        </div>
                    </div>
                );

            case 2:
                return <QuotesHistory purchaseId={purchaseId} />;

            default:
                return poShipment?.length > 0 ? (
                    <div className="">
                        <div className="max-lg:hidden">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isScrollable={false}
                                // isScrolled={isScrolled}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                            />
                        </div>
                        {loadMorePoShipment && fetchingPoShipment && (
                            <div className="flex my-4 justify-center">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {poShipmentPagination?.current ===
                            poShipmentPagination?.number_of_pages && (
                            <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                <p className="text-center"> End of list</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="items-center flex  h-[100%]  justify-center my-[20%]">
                        <div className="space-y-2  text-center">
                            <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                            <p className="text-gm-50 font-rocGroteskMedium text-[16px]">
                                Oops! looks like there are no product details <br /> available at
                                the moment. Check back later
                            </p>
                        </div>
                    </div>
                );
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setShipmentDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleAddApproval = () => {
        setLevels((prev) => [...prev, ""]);
    };

    const handleRemoveApproval = (idx: number) => {
        const levelsCopy = [...levels];
        levelsCopy.splice(idx, 1);
        setLevels(levelsCopy);
    };
    useEffect(() => {
        setShowShipment(poShipment?.[0]?._id);
    }, [poShipment]);

    useEffect(() => {
        const countryObject = address?.origin?.details?.[0]?.address_components?.find((obj) =>
            obj.types.includes("country")
        );
        if (countryObject) {
            setCountry(countryObject.long_name);
        }
    }, [address?.origin]);

    const handleSubmit = () => {
        const body: { [key: string]: any } = {
            channel: "web",
            origin: {
                address: address?.origin?.address,
                lng: address?.origin?.lng,
                lat: address?.origin?.lat,
                details: [address?.origin?.address],
                country: country,
            },
            destinationId: shipmentDetails?.destination,
            purchaseOrderId: purchaseId,
            shipmentMode: mode,
            productDetails: productData?.map((product) => ({
                category: product?.inventory?.category ?? product?.name,
                inventoryId: product?.inventoryId,
                quantity: Number(product?.qty),
            })),
        };
        if (shipmentDetails?.additionalInfo) {
            body.additionalNotes = shipmentDetails?.additionalInfo;
        }
        if (mode.toLowerCase() === "land") {
            body.trackingLink = shipmentDetails?.trackingLink;
        }
        if (mode.toLowerCase() === "air") {
            body.airWayBillNumber = shipmentDetails?.billNumber;
            body.carrier = shipmentDetails?.carrier;
        }
        if (mode.toLowerCase() === "ocean") {
            body.trackingLink = shipmentDetails?.trackingLink;
            body.billOfLading = shipmentDetails?.billOfLading;
        }
        dispatch(shipmentActions.createShipment(body));
    };

    useEffect(() => {
        dispatch(orderActions.getSinglePurchaseOrder(purchaseId));
    }, [dispatch, purchaseId]);

    useEffect(() => {
        if (Boolean(fetchedSinglePurchaseOrderSuccess)) {
            setProductList(fetchedSinglePurchaseOrderSuccess?.purchaseOrder);
        }
    }, [fetchedSinglePurchaseOrderSuccess]);

    const productDetailsTableHeader = [
        { title: "Product name", widthClass: "w-[30.6%]" },
        { title: "SKU", widthClass: "w-[15.2%]" },
        { title: "Qty", widthClass: "w-[15.2%]" },
        { title: "", widthClass: "w-[6%]" },
    ];
    const updatedData = [...productData];

    const handleTableChange = (name, value, idx) => {
        const selectedProduct = productData?.find((product) => product.name === value);

        if (selectedProduct && !updatedData.some((product) => product.name === value)) {
            updatedData[idx] = {
                ...updatedData[idx],
                ...selectedProduct,
                qty: selectedProduct?.qty || 0,
            };

            setProductData(updatedData);
        } else {
            toast.custom((t) => (
                <CustomToast t={t} message={"Product selected already exists"} type="error" />
            ));
        }
    };

    const handleDeleteRow = (index) => {
        const updatedData = productData.filter((_, idx) => idx !== index);
        setProductData(updatedData);
    };

    const handleAddRow = () => {
        const newRow = {
            name: "",
            inventoryCategory: "",
            qty: 0,
            unitOfMeasurement: "",
            amount: 0,
        };

        setProductData([...productData, newRow]);
    };
    const handleQuantityChange = (e, idx) => {
        const { value } = e.target;
        const updatedData = [...productData];

        updatedData[idx] = {
            ...updatedData[idx],
            qty: value,
        };

        setProductData(updatedData);
    };

    const productDetailsTableBody = productData?.map((data, idx) => [
        {
            content: (
                <div
                    key={idx}
                    className={`-ml-3 pr-3  flex font-rocGroteskMedium items-center text-sm text-slate-500 `}
                >
                    <SelectInput
                        name="name"
                        value={data.name}
                        placeholder={"Enter Product"}
                        handleChange={(name, value) => handleTableChange(name, value, idx)}
                        className={"w-full !py-0 border-none"}
                        isRequired={true}
                        clearValue
                        dropdownOptions={productList?.itemsOrServices.map((res) => ({
                            label: (
                                <div className={`font-rocGroteskMedium -mt-2 flex space-x-1`}>
                                    <VendorAvatar
                                        imageSrc={
                                            res?.inventory?.productImageDetails?.productAvatar
                                        }
                                        size={24}
                                        name={res?.name}
                                    />

                                    <p className="text-slate-500 ">{res?.name}</p>
                                </div>
                            ),
                            value: res?.name,
                        }))}
                    />
                </div>
            ),
        },
        {
            content: (
                <div
                    className={` pr-4 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-900 `}
                >
                    <p className="text-slate-700">{data?.inventory?.sku || "N/A"}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`w-full -ml-3  border-r pr-1  font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <TextInput
                        value={data?.qty}
                        name={"qty"}
                        type={"number"}
                        inputContainerClassname="!border-none"
                        // placeholder={"Enter qty"}
                        inputPlaceholder="Enter qty"
                        onChange={(e) => handleQuantityChange(e, idx)}
                        required={true}
                    />
                </div>
            ),
        },
        {
            content: (
                <i
                    className={`ri-delete-bin-line text-sm ${
                        idx === 0 && "opacity-25"
                    } text-[#64748B]`}
                    onClick={() => idx !== 0 && handleDeleteRow(idx)}
                ></i>
            ),
        },
    ]);

    useEffect(() => {
        dispatch(shipmentActions.getPoShipment(purchaseId, limit, poShipmentPage));
    }, [dispatch, purchaseId, limit, poShipmentPage]);

    useEffect(() => {
        const filteredData = productList?.itemsOrServices?.filter((item) => item?.inventoryId);
        setProductData(filteredData || []);
    }, [productList.itemsOrServices]);

    useEffect(() => {
        if (Boolean(fetchPoShipmentSuccess)) {
            setPoShipment((prev) =>
                poShipmentPage > 1
                    ? uniqBy([...prev, ...fetchPoShipmentSuccess?.data], "_id")
                    : [...fetchPoShipmentSuccess?.data]
            );
            setPoShipmentPagination({
                current: fetchPoShipmentSuccess?.pagination.current,
                number_of_pages: fetchPoShipmentSuccess?.pagination?.number_of_pages,
            });
            setTotalShipment(fetchPoShipmentSuccess?.total);
        }
    }, [fetchPoShipmentSuccess]);

    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouseList(20, "", debouncedFilter.search));
    }, [dispatch, debouncedFilter.search]);

    useEffect(() => {
        if (Boolean(fetchedWarehouseListSuccess)) {
            setWarehouseList(fetchedWarehouseListSuccess.warehouses?.data);
        }
    }, [fetchedWarehouseListSuccess]);

    useEffect(() => {
        if (Boolean(createdShipmentSuccess)) {
            setResponse(createdShipmentSuccess);
            setAddShipment(false);
            setshowSuccessShipmentAdded(true);
            dispatch(shipmentActions.getPoShipment(purchaseId));
        }
    }, [createdShipmentSuccess, dispatch]);

    const onCardScroll = useCallback(
        () =>
            onScroll(
                shipmentCardContent,
                poShipmentPagination?.current as number,
                poShipmentPagination?.number_of_pages as number,
                () => {
                    setPoShipmentPage(() => poShipmentPage + 1);
                    setLoadMorePoShipment(true);
                }
            ),
        [shipmentCardContent, poShipmentPagination]
    );

    useEffect(() => {
        shipmentCardContent?.addEventListener("scroll", onCardScroll);
        return () => {
            shipmentCardContent?.removeEventListener("scroll", onCardScroll);
        };
    }, [shipmentCardContent, onCardScroll]);

    return (
        <>
            <div
                className={` ${
                    activeTabTitle === 1 && "py-4 md:border border-slate-100 rounded-[8px]"
                } ${addShipment && "md:block hidden"} bg-white`}
            >
                {activeTabTitle === 1 && (
                    <div className="pb-4 md:block hidden border-b">
                        <div
                            onClick={() => navigate(-1)}
                            className="flex px-4 items-center  space-x-3  w-fit cursor-pointer"
                        >
                            <i className="ri-arrow-left-line text-lg"></i>
                            <span className="underline text-sm font-rocGroteskMedium mt-[2px]">
                                Back
                            </span>
                        </div>
                    </div>
                )}
                {activeTabTitle === 1 && (
                    <div className="md:mt-3 px-4">
                        <div className="mb-8 md:block hidden">
                            <PageTitleSection
                                leftComp={
                                    <BreadCrumb
                                        parentCrumb={"Purchase"}
                                        parentUrl={"/dashboard/purchase"}
                                        childrenCrumb={[
                                            {
                                                title: "Purchase order details",
                                                path: `/dashboard/purchase/1`,
                                            },
                                        ]}
                                        size="large"
                                    />
                                }
                                // rightComp={
                                //     <div className="flex items-center space-x-[14px]">
                                //         <Dropdown
                                //             handleChange={(name, value) => {}}
                                //             dropdown={
                                //                 <div className="flex text-gm-50 bg-white whitespace-nowrap border border-n-20 items-center rounded-[5px] h-[32px] shadow-[0_1px_2px_0_rgba(91,104,113,0.15)] py-2 px-[15px] gap-2">
                                //                     <i className="ri-filter-3-line text-sm mt-[-2px]"></i>

                                //                     <p className="font-rocGroteskMedium text-[13px]">
                                //                         1 year
                                //                     </p>
                                //                 </div>
                                //             }
                                //             dropdownItemsClasses={`!px-0 !py-0 !w-full`}
                                //             dropdownClassName="!w-full !mb-10  !-mt-7"
                                //             dropdownContainerClasses={`shadow-cardShadow-4 !border-b !mb-0 border-slate-100 rounded !max-h-fit`}
                                //             dropdownOptions={[
                                //                 {
                                //                     value: "1 year",
                                //                     label: "1 year",
                                //                 },
                                //                 {
                                //                     value: "2 years",
                                //                     label: "2 years",
                                //                 },
                                //                 {
                                //                     value: "3 years",
                                //                     label: "3 years",
                                //                 },
                                //             ]}
                                //         />
                                //         <div className="flex bg-white text-gm-50 whitespace-nowrap border border-n-20 items-center h-[32px] rounded-[5px] shadow-[0_1px_2px_0_rgba(91,104,113,0.15)] py-2 px-[15px] gap-2">
                                //             <i className="ri-share-forward-line text-sm mt-[-2px]"></i>

                                //             <p className="font-rocGroteskMedium text-sm">Share</p>
                                //         </div>
                                //     </div>
                                // }
                            />
                        </div>

                        <div className="md:mb-0 mb-2 ">
                            <TabsContainer
                                tabs={tabTitle}
                                activeTab={activeTabTitle}
                                setActiveTab={setActiveTabTitle}
                                className="!px-0"
                                itemClassName="!pb-2"
                                borderLineClase={
                                    "!text-[#F1F5F9] w-[20%] max-sm:w-full !h-fit  mb-4"
                                }
                            />
                        </div>

                        <>
                            {fetchingPoShipment && poShipment.length === 0 ? (
                                <div>
                                    <Loader />
                                </div>
                            ) : (
                                <div>
                                    <div className="pt-4 md:flex hidden items-center pb-4 border-b border-slate-200 border-dashed justify-between mb-6">
                                        <div className="flex items-center space-x-3">
                                            <div className="w-[60px] h-[60px]  bg-slate-100 items-center justify-center p-2.5">
                                                <VendorAvatar
                                                    imageSrc={
                                                        poShipment?.[0]?.purchaseOrder?.vendor
                                                            ?.profile?.avatar
                                                    }
                                                    name={
                                                        poShipment?.[0]?.purchaseOrder?.vendor
                                                            ?.profile?.businessName
                                                    }
                                                    size={40}
                                                    containerClassname="!rounded-none"
                                                />
                                            </div>

                                            <div className="">
                                                <span className="mb-1.5   text-sm font-rocGroteskMedium text-slate-500">
                                                    Supplier
                                                </span>
                                                <span className="block text-2xl font-rocGroteskMedium">
                                                    {poShipment?.[0]?.purchaseOrder?.vendor?.profile
                                                        ?.businessName ?? "------"}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className={`flex items-center space-x-2 ${
                                                poShipment?.[0]?.status?.toLowerCase() ===
                                                    "completed" &&
                                                "bg-light-green-status  text-[#0F172A] "
                                            } ${
                                                poShipment?.[0]?.status?.toLowerCase() ===
                                                    "delivered" &&
                                                "bg-light-green-status  text-[#0F172A] "
                                            } 
                            ${
                                poShipment?.[0]?.status?.toLowerCase() === "in-transit" &&
                                "bg-light-yellow-status_2  text-[#0F172A] "
                            }
                            ${
                                poShipment?.[0]?.status?.toLowerCase() === "pending" &&
                                "bg-light-purple-status_2  text-[#0F172A] "
                            }  
                            ${
                                poShipment?.[0]?.status?.toLowerCase() === "rejected" &&
                                "bg-light-red-status  text-[#0F172A] "
                            } 
                            py-[13px] px-[18px] rounded-[7px]`}
                                        >
                                            <div
                                                className={` ${
                                                    poShipment?.[0]?.status?.toLowerCase() ===
                                                        "completed" && "bg-[#16C6A4] "
                                                } 
                                                ${
                                                    poShipment?.[0]?.status?.toLowerCase() ===
                                                        "delivered" && "bg-[#16C6A4] "
                                                } 
                            ${
                                poShipment?.[0]?.status?.toLowerCase() === "in-transit" &&
                                "bg-[#FBB14F]  text-[#0F172A] "
                            }
                            ${
                                poShipment?.[0]?.status?.toLowerCase() === "pending" &&
                                "bg-[#6562E5]  text-[#0F172A] "
                            }
                            ${
                                poShipment?.[0]?.status?.toLowerCase() === "rejected" &&
                                "bg-[#FF3D1D]  text-[#0F172A] "
                            }
                            h-2 w-2 rounded-full`}
                                            ></div>
                                            <p className={` font-rocGroteskMedium text-sm `}>
                                                {poShipment?.[0]?.status?.toLowerCase() ===
                                                    "rejected" && "Order cancelled"}
                                                {poShipment?.[0]?.status?.toLowerCase() ===
                                                    "in-transit" && "Pending fulfilment"}
                                                {poShipment?.[0]?.status?.toLowerCase() ===
                                                    "approved" && "Order fulfilled"}
                                                {poShipment?.[0]?.status?.toLowerCase() ===
                                                    "pending" && "Order pending"}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="md:hidden sm:flex-row pt-2 flex-col sm:space-y-0 space-y-3 flex sm:items-center sm:justify-between mb-4">
                                        <div className="flex items-center space-x-2">
                                            <span className="block font-rocGroteskMedium text-sm">
                                                PO {poShipment[0]?.purchaseOrder?.taId ?? "------"}
                                            </span>
                                            <div className="border-r border-[#E2E8F0] h-[10px]"></div>
                                            <div className="flex items-center space-x-2">
                                                <VendorAvatar
                                                    imageSrc={
                                                        poShipment?.[0]?.purchaseOrder?.vendor
                                                            ?.profile?.avatar
                                                    }
                                                    name={
                                                        poShipment?.[0]?.purchaseOrder?.vendor
                                                            ?.profile?.businessName
                                                    }
                                                    size={23}
                                                    containerClassname="!rounded-full"
                                                />
                                                <span className="text-sm font-rocGroteskMedium">
                                                    {poShipment?.[0]?.purchaseOrder?.vendor?.profile
                                                        ?.businessName ?? "------"}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className={`flex w-fit items-center space-x-2 ${
                                                poShipment?.[0]?.status?.toLowerCase() ===
                                                    "completed" &&
                                                "bg-light-green-status  text-[#0F172A] "
                                            } ${
                                                poShipment?.[0]?.status?.toLowerCase() ===
                                                    "delivered" &&
                                                "bg-light-green-status  text-[#0F172A] "
                                            } 
                            ${
                                poShipment?.[0]?.status?.toLowerCase() === "in-transit" &&
                                "bg-light-yellow-status_2  text-[#0F172A] "
                            }
                            ${
                                poShipment?.[0]?.status?.toLowerCase() === "pending" &&
                                "bg-light-purple-status_2  text-[#0F172A] "
                            }  
                            ${
                                poShipment?.[0]?.status?.toLowerCase() === "rejected" &&
                                "bg-light-red-status  text-[#0F172A] "
                            } 
                            py-2 px-2 rounded-[7px]`}
                                        >
                                            <div
                                                className={` ${
                                                    poShipment?.[0]?.status?.toLowerCase() ===
                                                        "completed" && "bg-[#16C6A4] "
                                                } 
                                                ${
                                                    poShipment?.[0]?.status?.toLowerCase() ===
                                                        "delivered" && "bg-[#16C6A4] "
                                                } 
                            ${
                                poShipment?.[0]?.status?.toLowerCase() === "in-transit" &&
                                "bg-[#FBB14F]  text-[#0F172A] "
                            }
                            ${
                                poShipment?.[0]?.status?.toLowerCase() === "pending" &&
                                "bg-[#6562E5]  text-[#0F172A] "
                            }
                            ${
                                poShipment?.[0]?.status?.toLowerCase() === "rejected" &&
                                "bg-[#FF3D1D]  text-[#0F172A] "
                            }
                            h-2 w-2 rounded-full`}
                                            ></div>
                                            <p className={` font-rocGroteskMedium text-sm `}>
                                                {poShipment?.[0]?.status?.toLowerCase() ===
                                                    "rejected" && "Order cancelled"}
                                                {poShipment?.[0]?.status?.toLowerCase() ===
                                                    "in-transit" && "Pending fulfilment"}
                                                {poShipment?.[0]?.status?.toLowerCase() ===
                                                    "approved" && "Order fulfilled"}
                                                {poShipment?.[0]?.status?.toLowerCase() ===
                                                    "pending" && "Order pending"}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="md:hidden block border-b py-3 border-t border-dashed">
                                        <p className="text-base font-rocGroteskMedium">
                                            {poShipment[0]?.purchaseOrder?.createdBy?.fullName ??
                                                "------"}
                                        </p>
                                        <div className="flex  items-center space-x-1">
                                            <div className="flex items-center space-x-1">
                                                <i className="ri-mail-line text-[#64748B]"></i>
                                                <p className="text-sm text-[#64748B] font-rocGroteskMedium">
                                                    {poShipment[0]?.purchaseOrder?.createdBy
                                                        ?.email ?? "------"}
                                                </p>
                                            </div>
                                            <div className="border-r border-[#E2E8F0] h-[10px]"></div>
                                            <div className="flex items-center space-x-1">
                                                <i className="ri-calendar-line text-[#64748B]"></i>
                                                <p className="text-sm text-[#64748B] font-rocGroteskMedium">
                                                    {poShipment[0]?.purchaseOrder?.createdAt
                                                        ? moment(
                                                              poShipment[0]?.purchaseOrder
                                                                  ?.createdAt
                                                          ).format("MMM DD, YYYY")
                                                        : "------"}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex items-center space-x-1">
                                            <div className="flex items-center space-x-1">
                                                <i className="ri-file-line text-[#64748B]"></i>
                                                <p className="text-sm text-[#64748B] font-rocGroteskMedium">
                                                    Payment terms :{" "}
                                                    {poShipment[0]?.purchaseOrder?.paymentTerms ??
                                                        "------"}
                                                </p>
                                            </div>
                                            <div className="border-r border-[#E2E8F0] h-[10px]"></div>
                                            <div className="flex items-center space-x-1">
                                                <i className="ri-ship-2-line text-[#64748B]"></i>
                                                <p className="text-sm text-[#64748B] font-rocGroteskMedium">
                                                    {totalShipment ?? 0}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-4 md:block hidden border-b border-slate-200 border-dashed">
                                        <div className="p-6 rounded border border-[#E2E8F0] flex items-center justify-between space-x-[59px]">
                                            <div className="w-1/5">
                                                <span className="block font-rocGroteskMedium text-slate-500 mb-2 text-sm">
                                                    Purchase order ID
                                                </span>
                                                <span className="block font-rocGroteskMedium text-base">
                                                    {poShipment[0]?.purchaseOrder?.taId ?? "------"}
                                                </span>
                                            </div>

                                            <div className="h-[42px] border-r border-slate-200"></div>

                                            <div className="w-1/5">
                                                <span className="block font-rocGroteskMedium text-slate-500 mb-2 text-sm">
                                                    Created by
                                                </span>
                                                <span className="block font-rocGroteskMedium text-base text-p-50">
                                                    {poShipment[0]?.purchaseOrder?.createdBy
                                                        ?.fullName ?? "------"}
                                                </span>
                                            </div>

                                            <div className="h-[42px] border-r border-slate-200"></div>

                                            <div className="w-1/5">
                                                <span className="block font-rocGroteskMedium text-slate-500 mb-2 text-sm">
                                                    Number of shipment
                                                </span>
                                                <span className="block font-rocGroteskMedium text-base">
                                                    {totalShipment
                                                        ? formatMoney().format(totalShipment)
                                                        : 0}
                                                </span>
                                            </div>

                                            <div className="h-[42px] border-r border-slate-200"></div>

                                            <div className="w-1/5">
                                                <span className="block font-rocGroteskMedium text-slate-500 mb-2 text-sm">
                                                    Payment terms
                                                </span>
                                                <span className="block font-rocGroteskMedium text-base">
                                                    {poShipment[0]?.purchaseOrder?.paymentTerms ??
                                                        "------"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" md:flex hidden  items-center justify-between pt-6 mb-3">
                                        <p className="text-[16px] md:block hidden leading-[20px] font-rocGroteskMedium text-[#64748B]">
                                            Map details
                                        </p>

                                        {poShipment?.length > 0 && (
                                            <Button
                                                icon={
                                                    <i className="ri-add-circle-line text-xl text-[#142837]"></i>
                                                }
                                                btnClassname={`!py-2 !bg-[#E2E8F0] ${
                                                    poShipment?.[0]?.status?.toLowerCase() ===
                                                        "rejected" && "!opacity-[0.3]"
                                                }  !border-[#E2E8F0]  !w-fit !border`}
                                                btnText="Add shipment"
                                                onClick={() => setAddShipment(true)}
                                                disabled={
                                                    poShipment?.[0]?.status?.toLowerCase() ===
                                                    "rejected"
                                                }
                                            />
                                        )}
                                    </div>
                                    <div className="flex gap-x-10   justify-between pb-6 border-b border-dashed border-slate-200">
                                        <div className=" w-full  md:flex md:flex-col hidden  pt-6 pb-3 bg-white border border-[#F1F5F9] shadow-faintShadow rounded">
                                            <h5 className="px-6 mb-6 text-sm font-rocGroteskMedium">
                                                Shipments
                                            </h5>
                                            {poShipment?.length > 0 ? (
                                                <div
                                                    className="max-h-[430px]  scroll-smooth overflow-y-scroll"
                                                    id="shipmentCardContent"
                                                >
                                                    <div className=" px-6">
                                                        {poShipment?.map((shipment, index) => (
                                                            <>
                                                                <div
                                                                    key={index}
                                                                    onClick={() => {
                                                                        setShowShipment(
                                                                            shipment?._id
                                                                        );
                                                                        setClickedIndex(index);
                                                                    }}
                                                                    className={`mb-4  px-4 w- py-6 cursor-pointer rounded ${
                                                                        showShipment ===
                                                                            shipment?._id &&
                                                                        "border-carribean-green shadow-boxShadow-5"
                                                                    } mt-3 border hover:border-carribean-green shadow-boxShadow-5`}
                                                                >
                                                                    <div className="">
                                                                        <div className="flex  justify-between items-center ">
                                                                            <div className="w-full">
                                                                                <div className="flex items-center justify-between">
                                                                                    <span className="text-sm font-rocGroteskMedium uppercase">
                                                                                        {
                                                                                            shipment?.taId
                                                                                        }
                                                                                    </span>
                                                                                    <div
                                                                                        className={`space-x-2 ${
                                                                                            shipment?.status?.toLowerCase() ===
                                                                                                "pending" &&
                                                                                            "bg-light-purple-2"
                                                                                        } ${
                                                                                            shipment?.status?.toLowerCase() ===
                                                                                                "completed" &&
                                                                                            "bg-light-green-1"
                                                                                        }
                                                                                        ${
                                                                                            shipment?.status?.toLowerCase() ===
                                                                                                "delivered" &&
                                                                                            "bg-light-green-1"
                                                                                        } 
                                                                                        ${
                                                                                            shipment?.status?.toLowerCase() ===
                                                                                                "in-transit" &&
                                                                                            "bg-[#F9DFB399]"
                                                                                        } font-rocGroteskMedium flex items-center h-full text-sm py-[5px] px-[15px]  rounded-[25px]`}
                                                                                    >
                                                                                        <div
                                                                                            className={`h-2 w-2 ${
                                                                                                shipment?.status?.toLowerCase() ===
                                                                                                    "pending" &&
                                                                                                "bg-[#6562E5]"
                                                                                            } ${
                                                                                                shipment?.status?.toLowerCase() ===
                                                                                                    "completed" &&
                                                                                                "bg-[#16C6A4]"
                                                                                            } 
                                                                                            ${
                                                                                                shipment?.status?.toLowerCase() ===
                                                                                                    "delivered" &&
                                                                                                "bg-[#16C6A4]"
                                                                                            }
                                                                                            ${
                                                                                                shipment?.status?.toLowerCase() ===
                                                                                                    "in-transit" &&
                                                                                                "bg-orange"
                                                                                            } rounded-full`}
                                                                                        ></div>
                                                                                        <span>
                                                                                            {shipment?.status
                                                                                                .charAt(
                                                                                                    0
                                                                                                )
                                                                                                .toUpperCase() +
                                                                                                shipment?.status.slice(
                                                                                                    1
                                                                                                )}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="w-full space-x-2 items-center text-sm font-rocGroteskMedium">
                                                                                    <span className="text-slate-500">
                                                                                        {truncate(
                                                                                            shipment
                                                                                                ?.origin
                                                                                                ?.address,
                                                                                            {
                                                                                                length: 20,
                                                                                            }
                                                                                        )}
                                                                                    </span>
                                                                                    <i className="ri-arrow-right-line text-[#94A3B8] text-[14px]"></i>
                                                                                    <span className="text-right text-slate-500">
                                                                                        {truncate(
                                                                                            shipment
                                                                                                ?.destination
                                                                                                ?.address
                                                                                                ?.address ??
                                                                                                shipment
                                                                                                    ?.destination
                                                                                                    ?.address
                                                                                                    ?.country,
                                                                                            {
                                                                                                length: 20,
                                                                                            }
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {index !==
                                                                    poShipment.length - 1 && (
                                                                    <hr className="border-dashed" />
                                                                )}
                                                            </>
                                                        ))}
                                                        {loadMorePoShipment &&
                                                            fetchingPoShipment && (
                                                                <div className="flex my-4 justify-center">
                                                                    <Loader
                                                                        color="gm-25"
                                                                        size={4}
                                                                    />
                                                                </div>
                                                            )}
                                                        {poShipmentPagination?.current ===
                                                            poShipmentPagination?.number_of_pages && (
                                                            <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                                                <p className="text-center">
                                                                    {" "}
                                                                    End of list
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="flex flex-col items-center justify-center h-[458px]  px-10">
                                                    <img
                                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1712243717/empty_state_cargo_k8qlnf.svg"
                                                        alt="empty state"
                                                        className="w-10 h-10 mb-6"
                                                    />
                                                    <p className="text-base font-rocGroteskMedium text-gm-40 text-center">
                                                        There are currently no shipments available
                                                        for this purchase order. Create your first
                                                        shipment by clicking on the button below
                                                    </p>
                                                    <div className="pt-3 ">
                                                        <Button
                                                            btnText="Add Shipment"
                                                            onClick={() => setAddShipment(true)}
                                                            btnClassname={`!py-2 ${
                                                                poShipment?.[0]?.status?.toLowerCase() ===
                                                                    "rejected" && "!opacity-[0.3"
                                                            }`}
                                                            disabled={
                                                                poShipment?.[0]?.status?.toLowerCase() ===
                                                                "rejected"
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className=" md:px-6  bg-transparent md:bg-white w-full  md:pt-6 pb-3  md:border border-[#F1F5F9] shadow-faintShadow rounded">
                                            <h5 className=" mb-6 md:block hidden text-sm font-rocGroteskMedium">
                                                Shipments details
                                            </h5>
                                            <div className="w-full md:block hidden">
                                                {poShipment?.length > 0 ? (
                                                    <>
                                                        {poShipment?.map((shipment, index) => (
                                                            <div className="h-full">
                                                                {clickedIndex === index && (
                                                                    <div>
                                                                        <div className=" mt-2 space-y-1.5  p-4 rounded-[10px] border">
                                                                            <div className="flex items-center pb-5 justify-between pt-2 border-b-[0.7px] border-slate-200">
                                                                                <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                                                    {shipment?.taId}
                                                                                </span>
                                                                                <div
                                                                                    className={`space-x-2 md:flex hidden ${
                                                                                        shipment?.status?.toLowerCase() ===
                                                                                            "pending" &&
                                                                                        "bg-light-purple-2"
                                                                                    } ${
                                                                                        shipment?.status?.toLowerCase() ===
                                                                                            "completed" &&
                                                                                        "bg-light-green-1"
                                                                                    } 
                                                                                    ${
                                                                                        shipment?.status?.toLowerCase() ===
                                                                                            "delivered" &&
                                                                                        "bg-light-green-1"
                                                                                    } 
                                                                                    ${
                                                                                        shipment?.status?.toLowerCase() ===
                                                                                            "in-transit" &&
                                                                                        "bg-[#F9DFB399]"
                                                                                    } font-rocGroteskMedium flex items-center h-full text-sm py-[5px] px-[15px]  rounded-[25px]`}
                                                                                >
                                                                                    <div
                                                                                        className={`h-2 w-2 ${
                                                                                            shipment?.status?.toLowerCase() ===
                                                                                                "pending" &&
                                                                                            "bg-[#6562E5]"
                                                                                        } ${
                                                                                            shipment?.status?.toLowerCase() ===
                                                                                                "completed" &&
                                                                                            "bg-[#16C6A4]"
                                                                                        }
                                                                                        ${
                                                                                            shipment?.status?.toLowerCase() ===
                                                                                                "delivered" &&
                                                                                            "bg-[#16C6A4]"
                                                                                        }
                                                                                        ${
                                                                                            shipment?.status?.toLowerCase() ===
                                                                                                "in-transit" &&
                                                                                            "bg-orange"
                                                                                        } rounded-full`}
                                                                                    ></div>
                                                                                    <span>
                                                                                        {shipment?.status
                                                                                            .charAt(
                                                                                                0
                                                                                            )
                                                                                            .toUpperCase() +
                                                                                            shipment?.status.slice(
                                                                                                1
                                                                                            )}
                                                                                    </span>
                                                                                </div>
                                                                                <div className=" items-center md:hidden flex space-x-2">
                                                                                    <div
                                                                                        className={`space-x-2 ${
                                                                                            shipment?.status?.toLowerCase() ===
                                                                                                "pending" &&
                                                                                            "bg-light-purple-2"
                                                                                        } ${
                                                                                            shipment?.status?.toLowerCase() ===
                                                                                                "completed" &&
                                                                                            "bg-light-green-1"
                                                                                        } ${
                                                                                            shipment?.status?.toLowerCase() ===
                                                                                                "in-transit" &&
                                                                                            "bg-[#F9DFB399]"
                                                                                        } font-rocGroteskMedium flex items-center h-full text-sm py-[5px] px-[15px]  rounded-[25px]`}
                                                                                    >
                                                                                        <div
                                                                                            className={`h-2 w-2 ${
                                                                                                shipment?.status?.toLowerCase() ===
                                                                                                    "pending" &&
                                                                                                "bg-[#6562E5]"
                                                                                            } ${
                                                                                                shipment?.status?.toLowerCase() ===
                                                                                                    "completed" &&
                                                                                                "bg-[#16C6A4]"
                                                                                            }  ${
                                                                                                shipment?.status?.toLowerCase() ===
                                                                                                    "delivered" &&
                                                                                                "bg-[#16C6A4]"
                                                                                            } 
                                                                                              ${
                                                                                                  shipment?.status?.toLowerCase() ===
                                                                                                      "in-transit" &&
                                                                                                  "bg-orange"
                                                                                              } rounded-full`}
                                                                                        ></div>
                                                                                        <span>
                                                                                            {shipment?.status
                                                                                                .charAt(
                                                                                                    0
                                                                                                )
                                                                                                .toUpperCase() +
                                                                                                shipment?.status.slice(
                                                                                                    1
                                                                                                )}
                                                                                        </span>
                                                                                    </div>
                                                                                    <i
                                                                                        className="ri-arrow-down-s-line cursor-pointer"
                                                                                        onClick={() => {
                                                                                            setShowShipment(
                                                                                                shipment?._id
                                                                                            );
                                                                                            setClickedIndex(
                                                                                                index
                                                                                            );
                                                                                        }}
                                                                                    ></i>
                                                                                </div>
                                                                            </div>
                                                                            <>
                                                                                {shipment?.billOfLading && (
                                                                                    <div className="flex items-center justify-between py-2 border-b-[0.7px] border-slate-200">
                                                                                        <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                                                            Bill of
                                                                                            laden
                                                                                        </span>
                                                                                        <span className="text-sm font-rocGroteskMedium text-right">
                                                                                            {
                                                                                                shipment?.billOfLading
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                )}
                                                                                {shipment?.airWayBillNumber && (
                                                                                    <div className="flex items-center justify-between py-2 border-b-[0.7px] border-slate-200">
                                                                                        <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                                                            Airway
                                                                                            Bill
                                                                                            number
                                                                                        </span>
                                                                                        <span className="text-sm font-rocGroteskMedium text-right">
                                                                                            {
                                                                                                shipment?.airWayBillNumber
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                )}
                                                                                {shipment?.trackingLink && (
                                                                                    <div className="flex items-center justify-between py-2 border-b-[0.7px] border-slate-200">
                                                                                        <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                                                            Tracking
                                                                                            link
                                                                                        </span>
                                                                                        <span className="text-sm font-rocGroteskMedium text-right">
                                                                                            {truncate(
                                                                                                shipment?.trackingLink,
                                                                                                {
                                                                                                    length: 20,
                                                                                                }
                                                                                            )}
                                                                                        </span>
                                                                                    </div>
                                                                                )}
                                                                                <div className="flex items-center justify-between py-2 border-b-[0.7px] border-slate-200">
                                                                                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                                                        Shipment
                                                                                        origin
                                                                                    </span>
                                                                                    <span className="text-sm font-rocGroteskMedium text-right">
                                                                                        {truncate(
                                                                                            shipment
                                                                                                ?.origin
                                                                                                ?.address,
                                                                                            {
                                                                                                length: 20,
                                                                                            }
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="flex items-center justify-between py-2 border-b-[0.7px] border-slate-200">
                                                                                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                                                        Shipment
                                                                                        destination
                                                                                    </span>
                                                                                    <span className="text-sm font-rocGroteskMedium text-right">
                                                                                        {truncate(
                                                                                            shipment
                                                                                                ?.destination
                                                                                                ?.address
                                                                                                ?.address ??
                                                                                                shipment
                                                                                                    ?.destination
                                                                                                    ?.address
                                                                                                    ?.country,
                                                                                            {
                                                                                                length: 20,
                                                                                            }
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="flex items-center justify-between py-2 border-b-[0.7px] border-slate-200">
                                                                                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                                                        Est.Time of
                                                                                        delivery
                                                                                    </span>
                                                                                    <span className="text-sm font-rocGroteskMedium text-right">
                                                                                        {moment(
                                                                                            shipment?.createdAt
                                                                                        ).format(
                                                                                            "DD MMM, YYYY"
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="pt-5 md:hidden block">
                                                                                    <Button
                                                                                        btnText="Add stock"
                                                                                        btnClassname={`!py-2 ${
                                                                                            shipment?.status?.toLowerCase() ===
                                                                                                "delivered" &&
                                                                                            "!opacity-50"
                                                                                        }`}
                                                                                        disabled={
                                                                                            shipment?.status?.toLowerCase() ===
                                                                                            "delivered"
                                                                                        }
                                                                                        onClick={() =>
                                                                                            navigate(
                                                                                                `/dashboard/inventory/stock-control/stock-entry?channel=shipment&shipmentId=${shipment?._id}`
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                {/* <div className="flex items-center justify-between py-2">
                                                                        <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                                            Est.Time of
                                                                            arrival
                                                                        </span>
                                                                        <span className="text-sm font-rocGroteskMedium text-right">
                                                                            Apr 17, 2023
                                                                            2:01 AM
                                                                        </span>
                                                                    </div> */}
                                                                            </>
                                                                        </div>

                                                                        <div className="pt-5 md:block hidden">
                                                                            <Button
                                                                                btnText="Add stock"
                                                                                btnClassname={`!py-2 ${
                                                                                    shipment?.status?.toLowerCase() ===
                                                                                        "delivered" &&
                                                                                    "!opacity-50"
                                                                                }`}
                                                                                disabled={
                                                                                    shipment?.status?.toLowerCase() ===
                                                                                    "delivered"
                                                                                }
                                                                                onClick={() =>
                                                                                    navigate(
                                                                                        `/dashboard/inventory/stock-control/stock-entry?channel=shipment&shipmentId=${shipment?._id}`
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <div className="flex flex-col items-center justify-center h-[458px]  px-10">
                                                        <img
                                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1712243717/empty_state_cargo_k8qlnf.svg"
                                                            alt="empty state"
                                                            className="w-10 h-10 mb-6"
                                                        />
                                                        <p className="text-base font-rocGroteskMedium text-gm-40 text-center">
                                                            We apologize, but there are no shipment
                                                            details available at the moment.
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {poShipment[0]?.purchaseOrder?.source.toLowerCase() ===
                                        "quote" && (
                                        <div className="pb-6 border-b border-dashed border-slate-200">
                                            <div className="items-start justify-between space-x-4 mt-6">
                                                <div className="w-full p-6 bg-white shadow-[0px_4px_24px_0px_#00000008] rounded">
                                                    <h5 className="block mb-6 text-base font-rocGroteskMedium">
                                                        Selected quote
                                                    </h5>
                                                    <div className="flex w-full space-x-10  mb-6 border-b pb-4">
                                                        <div className="font-rocGroteskMedium  ">
                                                            <span className="md:text-base text-sm text-slate-500 block">
                                                                Quote ID
                                                            </span>
                                                            <span
                                                                className={`md:text-base text-sm  ${
                                                                    poShipment[0]?.purchaseOrder
                                                                        ?.quote?.taId &&
                                                                    "text-p-50 underline"
                                                                } block`}
                                                            >
                                                                {poShipment[0]?.purchaseOrder?.quote
                                                                    ?.taId ?? "------"}
                                                            </span>
                                                        </div>
                                                        <div className="border-r border-slate-200"></div>
                                                        <div className="font-rocGroteskMedium ">
                                                            <span className="md:text-base text-sm text-slate-500 block">
                                                                Date reviewed
                                                            </span>
                                                            <span className="md:text-base text-sm  block">
                                                                {moment(
                                                                    poShipment[0]?.approvalTime
                                                                ).format("DD MMM, YYYY")}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* <div className="flex w-full items-center space-x-3  pb-6  border-b border-dashed border-slate-200">
<div className="w-full ">
 <h6 className="text-base text-slate-500 font-rocGroteskMedium mb-4">
     Quote summary
 </h6>
 <div className="p-4 bg-[#16C6A40A] border border-carribean-green rounded shadow-boxShadow-10">
     <span className="text-base font-rocGroteskMedium block leading-[29px]">
         Quote status: Approved
     </span>
     <p className="text-sm font-rocGroteskMedium text-slate-500">
         The quote from ABC Supplies was selected for
         the purchase requisition due to its
         competitive pricing, timely delivery, and
         past reliability. The quality of the
         products and the supplier's responsiveness
         to inquiries were also considered favorable
         factors. Overall, ABC Supplies demonstrated
         the best value proposition and alignment
         with our procurement requirements.
     </p>
 </div>
</div>
<div className="border-r border-[#94A3B8] h-[60px] flex items-center justify-center"></div>
<div className="w-full">
 <div className="">
     <h6 className="text-base font-rocGroteskMedium mb-4">
         <span>Supplier risk summary </span>
     </h6>
 </div>
 <div className="p-4 bg-[#FF3D1D0A] border border-r-50 rounded shadow-boxShadow-10 flex items-center space-x-6 ">
     <div>
         <span className="text-base font-rocGroteskMedium block leading-[29px]">
             High risk supplier
         </span>
         <p className="text-sm font-rocGroteskMedium text-slate-500">
             This supplier poses significant
             challenges due to potential quality
             issues, supply chain disruptions, or
             financial instability, necessitating
             immediate attention and proactive
             measures to address risks and minimise
             potential disruptions to operations
         </p>
     </div>
     <img
         src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1712165112/negative_trend_p7brtm.svg"
         alt="negative-trend"
         className="w-6 h-6 object-cover"
     />
 </div>
</div>
</div> */}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="mt-6 md:block  hidden">
                                        <TabsContainer
                                            tabs={tabs}
                                            activeTab={activeTab}
                                            setActiveTab={setActiveTab}
                                            component={displayActiveTab()}
                                            className="!px-0"
                                            itemClassName="!pb-2"
                                            borderLineClase={"!text-slate-100  max-sm:w-full  mb-4"}
                                        />
                                    </div>
                                    <div className="mt-6 md:hidden block">
                                        <TabsContainer
                                            tabs={smallTabs}
                                            activeTab={activeTab}
                                            setActiveTab={setActiveTab}
                                            component={smallDisplayActiveTab()}
                                            className="!px-0"
                                            itemClassName="!pb-2"
                                            borderLineClase={"!text-slate-100  max-sm:w-full  mb-4"}
                                        />
                                    </div>
                                </div>
                            )}
                        </>
                    </div>
                )}

                {activeTabTitle === 0 && (
                    <PurchaseOrderDataDetails
                        showTabs={true}
                        setActiveTabTitle={setActiveTabTitle}
                        activeTabTitle={activeTabTitle}
                    />
                )}
            </div>
            <div className="md:block hidden">
                <ModalContainer
                    open={addShipment}
                    showCloseIcon={false}
                    closeModal={() => {
                        setAddShipment(false);
                    }}
                    tailwindClassName="md:w-[700px] "
                >
                    <div className="bg-white flex flex-col rounded-[20px]  ">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                            className="bg-white shadow-cardShadow relative rounded-[20px] py-8 "
                        >
                            <div className="cursor-pointer w-full px-6">
                                <i
                                    onClick={() => {
                                        setShipmentDetails({});
                                        setAddShipment(false);
                                    }}
                                    className="ri-close-fill text-lg flex justify-end text-gm-45"
                                ></i>
                            </div>
                            <div className="font-rocGroteskMedium text-center mb-8">
                                <p className="text-xl ">Create shipment</p>
                                <p className="text-sm text-[#62707A]">
                                    Kindly add your shipment to the respective PO
                                </p>
                            </div>
                            <div className="px-6 mb-6 flex flex-col h-[400px] overflow-y-scroll gap-5">
                                <div className="border-b border-b-dotted pb-4">
                                    <p className="text-[#62707A] text-[16px] mb-2 leading-[23.04px] font-rocGroteskMedium">
                                        Shipment details
                                    </p>
                                    <TextInput
                                        value={""}
                                        disabled={true}
                                        name="shipmentId"
                                        placeholder={"Shipment ID"}
                                        onChange={(e) => {}}
                                    />
                                    <span className="mt-1 flex items-center">
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1713459494/Frame_g6qjiy.svg"
                                            className="h-2 w-2"
                                            alt=""
                                        />
                                        <p className="text-[10px] leading-[14px] text-[#64748B]">
                                            This is auto generated by the system
                                        </p>
                                    </span>
                                    <div className="flex space-x-5 mt-5">
                                        <AddressInput
                                            setData={setAddress}
                                            savedAddress={
                                                address?.streetAddress
                                                    ? address?.origin?.address
                                                    : ""
                                            }
                                            placeholder="Origin"
                                            required={true}
                                            name={"origin"}
                                        />

                                        <SelectInput
                                            value={shipmentDetails?.destination}
                                            name="destination"
                                            handleInputChange={debounce((evt) => {
                                                setDebouncedFilter({
                                                    search: evt.target.value,
                                                    inventorySearch: "",
                                                });
                                            }, 800)}
                                            placeholder={"Destination"}
                                            handleChange={(name, value) =>
                                                handleChange({ target: { name, value } })
                                            }
                                            isRequired={true}
                                            optionItemContainerClassName={"hover:bg-slate-100"}
                                            dropdownOptions={warehouseList?.map((list) => {
                                                return {
                                                    label: list?.warehouseName,
                                                    value: list?._id,
                                                };
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="border-b border-b-dotted pb-4">
                                    <p className="text-[#62707A] text-[16px]  mb-2 leading-[23.04px] font-rocGroteskMedium">
                                        Mode
                                    </p>
                                    <SelectInput
                                        value={mode}
                                        name="mode"
                                        placeholder={"Select mode"}
                                        handleChange={(name, value) => setMode(value)}
                                        isRequired={true}
                                        optionItemContainerClassName={"hover:bg-slate-100"}
                                        dropdownOptions={[
                                            {
                                                label: (
                                                    <div className="font-rocGroteskMedium items-center -mt-2 flex space-x-1">
                                                        <i className="ri-truck-line"></i>

                                                        <p className="text-gm-50 text-xs  font-rocGroteskMedium">
                                                            Ocean
                                                        </p>
                                                    </div>
                                                ),
                                                value: "ocean",
                                            },
                                            {
                                                label: (
                                                    <div className="font-rocGroteskMedium items-center -mt-2 flex space-x-1">
                                                        <i className="ri-plane-fill"></i>

                                                        <p className="text-gm-50 text-xs  font-rocGroteskMedium">
                                                            Air
                                                        </p>
                                                    </div>
                                                ),
                                                value: "air",
                                            },
                                            {
                                                label: (
                                                    <div className="font-rocGroteskMedium items-center -mt-2 flex space-x-1">
                                                        <i className="ri-truck-line"></i>

                                                        <p className="text-gm-50 text-xs  font-rocGroteskMedium">
                                                            Land
                                                        </p>
                                                    </div>
                                                ),
                                                value: "land",
                                            },
                                        ]}
                                    />
                                    {mode.toLowerCase() === "ocean" && (
                                        <div className="flex space-x-2 mt-3 items-center">
                                            <TextInput
                                                value={shipmentDetails?.trackingLink}
                                                type={"text"}
                                                name="trackingLink"
                                                placeholder={"Tracking link"}
                                                onChange={handleChange}
                                                required={true}
                                            />
                                            <TextInput
                                                value={shipmentDetails?.billOfLading}
                                                type={"text"}
                                                name="billOfLading"
                                                placeholder={"Bill of lading"}
                                                onChange={handleChange}
                                                required={true}
                                            />
                                        </div>
                                    )}
                                    {mode.toLowerCase() === "air" && (
                                        <div className="flex space-x-2 mt-3 items-center">
                                            <TextInput
                                                value={shipmentDetails?.billNumber}
                                                name="billNumber"
                                                placeholder={"Airway bill number"}
                                                onChange={handleChange}
                                                required={true}
                                            />
                                            <SelectInput
                                                value={shipmentDetails?.carrier}
                                                name="carrier"
                                                placeholder={"Carrier"}
                                                handleChange={(name, value) =>
                                                    handleChange({ target: { name, value } })
                                                }
                                                isRequired={true}
                                                optionItemContainerClassName={"hover:bg-slate-100"}
                                                dropdownOptions={oceanCarriers?.map(
                                                    (carrierData) => {
                                                        return {
                                                            label: carrierData?.["Full Name"],
                                                            value: carrierData?.SCAC,
                                                        };
                                                    }
                                                )}
                                            />
                                        </div>
                                    )}
                                    {mode.toLowerCase() === "land" && (
                                        <div className="mt-3 ">
                                            <TextInput
                                                value={shipmentDetails?.trackingLink}
                                                type={"text"}
                                                name="trackingLink"
                                                placeholder={"Tracking link"}
                                                onChange={handleChange}
                                                required={true}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="font-rocGroteskMedium">
                                    <p className="text-base  text-gm-50 mb-2">Product details</p>
                                    <p className="text-sm  text-[#64748B]  mb-2">
                                        Kindly include the products you wish to ship in your
                                        shipment
                                    </p>
                                    <div>
                                        <CustomTable
                                            tableBody={productDetailsTableBody}
                                            tableHeader={productDetailsTableHeader}
                                            isAllSelectable={false}
                                            isCellSelectable={false}
                                            headerContainerClass="!bg-[#F1F5F9] !border-[#E2E8F0] !border"
                                            isScrollable={false}
                                            isCellBordered={false}
                                            bodyItemClass={"hover:!bg-transparent"}
                                        />
                                    </div>
                                    {productData?.length > 0 &&
                                        productData?.length <
                                            productList?.itemsOrServices.length && (
                                            <div
                                                className="flex items-center mt-5 space-x-1 w-fit cursor-pointer"
                                                onClick={handleAddRow}
                                            >
                                                <i className="ri-add-circle-line text-p-50"></i>
                                                <p className="text-p-50 underline font-rocGroteskMedium text-sm">
                                                    Add product
                                                </p>
                                            </div>
                                        )}
                                </div>

                                <div className="border-b border-b-dotted pb-4">
                                    <p className="text-[#62707A] text-[16px]  mb-2 leading-[23.04px] font-rocGroteskMedium">
                                        Additional notes
                                    </p>
                                    <TextareaInput
                                        name={"additionalInfo"}
                                        value={shipmentDetails?.additionalInfo}
                                        onChange={handleChange}
                                        rows={1}
                                        placeholder={"Notes"}
                                        containerClassname=""
                                    />
                                </div>
                            </div>

                            <div className="px-6 mt-6 flex space-x-4">
                                <Button
                                    btnText={"Cancel"}
                                    btnClassname={"!bg-[#F4F5F7]"}
                                    disabled={createShipment}
                                    onClick={() => {
                                        setShipmentDetails({});
                                        setMode("");
                                        setAddShipment(false);
                                    }}
                                />
                                <Button
                                    btnText={`Add shipment`}
                                    type={"submit"}
                                    disabled={createShipment}
                                    isLoading={createShipment}
                                />
                            </div>
                        </form>
                    </div>
                </ModalContainer>
            </div>
            {addShipment && (
                <div className="md:hidden block px-4 ">
                    <div className="bg-white flex flex-col rounded-[20px]  ">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                            className="bg-white pb-28 shadow-cardShadow relative  rounded-[20px] pt-8 "
                        >
                            <div className=" mb-6 bg-white flex flex-col  gap-5">
                                <div className="border-b bg- border-b-dotted pb-4">
                                    <p className="text-[#62707A] text-[16px] mb-2 leading-[23.04px] font-rocGroteskMedium">
                                        Shipment details
                                    </p>
                                    <TextInput
                                        value={""}
                                        disabled={true}
                                        name="shipmentId"
                                        placeholder={"Shipment ID"}
                                        onChange={(e) => {}}
                                    />
                                    <span className="mt-1 flex items-center">
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1713459494/Frame_g6qjiy.svg"
                                            className="h-2 w-2"
                                            alt=""
                                        />
                                        <p className="text-[10px] leading-[14px] text-[#64748B]">
                                            This is auto generated by the system
                                        </p>
                                    </span>
                                    <div className="flex space-x-5 mt-5">
                                        <AddressInput
                                            setData={setAddress}
                                            savedAddress={
                                                address?.streetAddress
                                                    ? address?.origin?.address
                                                    : ""
                                            }
                                            placeholder="Origin"
                                            required={true}
                                            name={"origin"}
                                        />

                                        <SelectInput
                                            value={shipmentDetails?.destination}
                                            name="destination"
                                            handleInputChange={debounce((evt) => {
                                                setDebouncedFilter({
                                                    search: evt.target.value,
                                                    inventorySearch: "",
                                                });
                                            }, 800)}
                                            placeholder={"Destination"}
                                            handleChange={(name, value) =>
                                                handleChange({
                                                    target: { name, value },
                                                })
                                            }
                                            isRequired={true}
                                            optionItemContainerClassName={"hover:bg-slate-100"}
                                            dropdownOptions={warehouseList?.map((list) => {
                                                return {
                                                    label: list?.warehouseName,
                                                    value: list?._id,
                                                };
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="border-b border-b-dotted pb-4">
                                    <p className="text-[#62707A] text-[16px]  mb-2 leading-[23.04px] font-rocGroteskMedium">
                                        Mode
                                    </p>
                                    <SelectInput
                                        value={mode}
                                        name="mode"
                                        placeholder={"Select mode"}
                                        handleChange={(name, value) => setMode(value)}
                                        isRequired={true}
                                        optionItemContainerClassName={"hover:bg-slate-100"}
                                        dropdownOptions={[
                                            {
                                                label: (
                                                    <div className="font-rocGroteskMedium items-center -mt-2 flex space-x-1">
                                                        <i className="ri-truck-line"></i>

                                                        <p className="text-gm-50 text-xs  font-rocGroteskMedium">
                                                            Ocean
                                                        </p>
                                                    </div>
                                                ),
                                                value: "ocean",
                                            },
                                            {
                                                label: (
                                                    <div className="font-rocGroteskMedium items-center -mt-2 flex space-x-1">
                                                        <i className="ri-plane-fill"></i>

                                                        <p className="text-gm-50 text-xs  font-rocGroteskMedium">
                                                            Air
                                                        </p>
                                                    </div>
                                                ),
                                                value: "air",
                                            },
                                            {
                                                label: (
                                                    <div className="font-rocGroteskMedium items-center -mt-2 flex space-x-1">
                                                        <i className="ri-truck-line"></i>

                                                        <p className="text-gm-50 text-xs  font-rocGroteskMedium">
                                                            Land
                                                        </p>
                                                    </div>
                                                ),
                                                value: "land",
                                            },
                                        ]}
                                    />
                                    {mode.toLowerCase() === "ocean" && (
                                        <div className="flex space-x-2 mt-3 items-center">
                                            <TextInput
                                                value={shipmentDetails?.trackingLink}
                                                type={"text"}
                                                name="trackingLink"
                                                placeholder={"Tracking link"}
                                                onChange={handleChange}
                                                required={true}
                                            />
                                            <TextInput
                                                value={shipmentDetails?.billOfLading}
                                                type={"text"}
                                                name="billOfLading"
                                                placeholder={"Bill of lading"}
                                                onChange={handleChange}
                                                required={true}
                                            />
                                        </div>
                                    )}
                                    {mode.toLowerCase() === "air" && (
                                        <div className="flex space-x-2 mt-3 items-center">
                                            <TextInput
                                                value={shipmentDetails?.billNumber}
                                                name="billNumber"
                                                placeholder={"Airway bill number"}
                                                onChange={handleChange}
                                                required={true}
                                            />
                                            <SelectInput
                                                value={shipmentDetails?.carrier}
                                                name="carrier"
                                                placeholder={"Carrier"}
                                                handleChange={(name, value) =>
                                                    handleChange({
                                                        target: {
                                                            name,
                                                            value,
                                                        },
                                                    })
                                                }
                                                isRequired={true}
                                                optionItemContainerClassName={"hover:bg-slate-100"}
                                                dropdownOptions={oceanCarriers?.map(
                                                    (carrierData) => {
                                                        return {
                                                            label: carrierData?.["Full Name"],
                                                            value: carrierData?.SCAC,
                                                        };
                                                    }
                                                )}
                                            />
                                        </div>
                                    )}
                                    {mode.toLowerCase() === "land" && (
                                        <div className="mt-3 ">
                                            <TextInput
                                                value={shipmentDetails?.trackingLink}
                                                type={"text"}
                                                name="trackingLink"
                                                placeholder={"Tracking link"}
                                                onChange={handleChange}
                                                required={true}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="font-rocGroteskMedium">
                                    <p className="text-base  text-gm-50 mb-2">Product details</p>
                                    <p className="text-sm  text-[#64748B]  mb-2">
                                        Kindly include the products you wish to ship in your
                                        shipment
                                    </p>
                                    <div className="md:block hidden">
                                        <CustomTable
                                            tableBody={productDetailsTableBody}
                                            tableHeader={productDetailsTableHeader}
                                            isAllSelectable={false}
                                            isCellSelectable={false}
                                            headerContainerClass="!bg-[#F1F5F9] !border-[#E2E8F0] !border"
                                            isScrollable={false}
                                            isCellBordered={false}
                                            bodyItemClass={"hover:!bg-transparent"}
                                        />
                                    </div>
                                    {productData?.map((data, idx) => (
                                        <div className="mt-5 md:hidden block">
                                            <div className="flex mb-4 justify-between items-center">
                                                <p className="text-sm font-rocGroteskMedium text-[#142837] ">
                                                    Product {idx + 1}
                                                </p>
                                                <i
                                                    className={`ri-delete-bin-line ${
                                                        idx === 0 && "opacity-25"
                                                    } text-[#64748B]`}
                                                    onClick={() =>
                                                        idx !== 0 && handleDeleteRow(idx)
                                                    }
                                                ></i>
                                            </div>
                                            <div className="space-y-3">
                                                <SelectInput
                                                    name="name"
                                                    value={data.name}
                                                    placeholder={"Enter Product"}
                                                    handleChange={(name, value) =>
                                                        handleTableChange(name, value, idx)
                                                    }
                                                    className={"w-full !py-0 rounded"}
                                                    isRequired={true}
                                                    clearValue
                                                    dropdownOptions={productList?.itemsOrServices.map(
                                                        (res) => ({
                                                            label: (
                                                                <div
                                                                    className={`font-rocGroteskMedium -mt-2 flex space-x-1`}
                                                                >
                                                                    <VendorAvatar
                                                                        imageSrc={
                                                                            res?.inventory
                                                                                ?.productImageDetails
                                                                                ?.productAvatar
                                                                        }
                                                                        size={24}
                                                                        name={res?.name}
                                                                    />

                                                                    <p className="text-slate-500 ">
                                                                        {res?.name}
                                                                    </p>
                                                                </div>
                                                            ),
                                                            value: res?.name,
                                                        })
                                                    )}
                                                />
                                                <div className="flex items-center space-x-3">
                                                    <div className="border w-full py-[16px] !rounded-[4px] h-[50px] px-[13px]">
                                                        <div
                                                            className={`w-full border-slate-100 text-sm text-slate-700 `}
                                                        >
                                                            <p className="text-slate-700">
                                                                {data?.inventory?.sku || "N/A"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`w-full !border !border-[#D0D4D7] !rounded-[4px]   text-sm text-slate-700 `}
                                                    >
                                                        <TextInput
                                                            value={data?.qty}
                                                            name={"qty"}
                                                            type={"number"}
                                                            inputContainerClassname="!border-none"
                                                            // placeholder={"Enter qty"}
                                                            inputPlaceholder="Enter qty"
                                                            onChange={(e) =>
                                                                handleQuantityChange(e, idx)
                                                            }
                                                            required={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {productData?.length > 0 &&
                                        productData?.length <
                                            productList?.itemsOrServices.length && (
                                            <div
                                                className="flex items-center mt-5 space-x-1 w-fit cursor-pointer"
                                                onClick={handleAddRow}
                                            >
                                                <i className="ri-add-circle-line text-p-50"></i>
                                                <p className="text-p-50 underline font-rocGroteskMedium text-sm">
                                                    Add product
                                                </p>
                                            </div>
                                        )}
                                </div>

                                <div className="border-b border-b-dotted pb-4">
                                    <p className="text-[#62707A] text-[16px]  mb-2 leading-[23.04px] font-rocGroteskMedium">
                                        Additional notes
                                    </p>
                                    <TextareaInput
                                        name={"additionalInfo"}
                                        value={shipmentDetails?.additionalInfo}
                                        onChange={handleChange}
                                        rows={3}
                                        placeholder={"Notes"}
                                        containerClassname=""
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col space-y-3 md:hidden items-center left-0 right-0 px-5 shadow-faintShadow border-t justify-between  py-5 border-slate-100 bottom-0 w-full fixed z-[50]   bg-white">
                                <Button
                                    btnText={"Cancel"}
                                    btnClassname={"!bg-[#F4F5F7]"}
                                    disabled={createShipment}
                                    onClick={() => {
                                        setShipmentDetails({});
                                        setMode("");
                                        setAddShipment(false);
                                    }}
                                />
                                <Button
                                    btnText={`Add shipment`}
                                    type={"submit"}
                                    disabled={createShipment}
                                    isLoading={createShipment}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            )}

            <ModalContainer
                open={showSuccessShipmentAdded}
                showCloseIcon={false}
                modalClassName={"py-10 md:px-10 px-6"}
                closeModal={() => {
                    dispatch(shipmentActions.resetCreateShipmentSuccess());
                    dispatch(orderActions.getSinglePurchaseOrder(purchaseId));
                    setshowSuccessShipmentAdded(false);
                    setShipmentDetails({});
                    setMode("");
                }}
                tailwindClassName="w-[484px]"
            >
                {response?.failed?.length > 0 ? (
                    <div className="bg-white rounded-lg shadow-cardShadow relative">
                        <div className="md:px-10 px-8 pt-8 overflow-y-scroll max-h-[70vh] ">
                            <div className="flex justify-end ">
                                <i
                                    className="ri-close-fill  text-xl text-gm-45 cursor-pointer"
                                    onClick={() => {
                                        dispatch(shipmentActions.resetCreateShipmentSuccess());
                                        dispatch(orderActions.getSinglePurchaseOrder(purchaseId));
                                        setshowSuccessShipmentAdded(false);
                                        setShipmentDetails({});
                                        setMode("");
                                    }}
                                ></i>
                            </div>
                            <div className="flex flex-col items-center">
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1707140954/Software_xcvcl6.svg"
                                    alt="warn-sign"
                                />

                                <p className="text-xl mb-3 text-center mt-4 font-rocGroteskMedium text-g-75">
                                    Shipment creation failed!
                                </p>
                                <p className="text-sm text-center font-rocGroteskMedium text-slate-500 max-w-[411px]">
                                    There was an error while creating your shipment as the quantity
                                    exceeded the purchase order
                                </p>
                            </div>
                            {response?.failed?.map((item, idx) => (
                                <div className="max-h-[50vh] overflow-y-scroll ">
                                    <div className="mt-2">
                                        <p className="text-base text-slate-500 font-rocGroteskMedium">
                                            Package {idx + 1}
                                        </p>
                                    </div>
                                    <div className="bg-white space-y-3 mt-2 text-sm mb-2 shadow-cardDropdownShadow ">
                                        <div className="flex justify-between items-center font-rocGroteskMedium ">
                                            <p className="text-black">Product name</p>
                                            <p className="text-end text-slate-500">
                                                {item?.inventory?.productName}
                                            </p>
                                        </div>
                                        <div className="flex justify-between items-center font-rocGroteskMedium ">
                                            <p className="text-black">SKU</p>
                                            <p className="text-end text-slate-500">
                                                {item?.inventory?.sku}
                                            </p>
                                        </div>
                                        <div className="flex justify-between items-center font-rocGroteskMedium ">
                                            <p className="text-black">Inventory category</p>
                                            <p className="text-end text-slate-500">
                                                {item?.inventory?.inventoryCategory}
                                            </p>
                                        </div>

                                        <div className="flex justify-between text-[#FF3D1D] items-center font-rocGroteskMedium">
                                            <div className="flex items-center space-x-1 ">
                                                <i className="ri-information-line"></i>
                                                <p>Quantity</p>
                                            </div>
                                            <p className="text-end">
                                                {item?.qtyRemaining < 0
                                                    ? 0
                                                    : formatMoney().format(item?.qtyRemaining)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="w-full px-8 py-4  border-slate-200 ">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                onClick={() => {
                                    dispatch(shipmentActions.resetCreateShipmentSuccess());
                                    dispatch(orderActions.getSinglePurchaseOrder(purchaseId));
                                    setshowSuccessShipmentAdded(false);
                                    setShipmentDetails({});
                                    setMode("");
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="bg-white rounded-lg shadow-cardShadow relative">
                        <div className="md:px-10 px-4 pt-8 max-h-[70vh] overflow-y-scroll ">
                            <div className="flex justify-end ">
                                <i
                                    className="ri-close-fill text-lg text-gm-45 cursor-pointer"
                                    onClick={() => {
                                        setShipmentDetails({});
                                        setMode("");
                                        dispatch(shipmentActions.resetCreateShipmentSuccess());
                                        setshowSuccessShipmentAdded(false);
                                    }}
                                ></i>
                            </div>
                            <div className="flex flex-col items-center">
                                <div className="w-12 h-12 rounded-full bg-[#00DB8F] flex items-center justify-center">
                                    <i className="ri-checkbox-circle-fill text-xl text-white"></i>
                                </div>

                                <p className="text-xl mb-3 text-center mt-4 font-rocGroteskMedium text-g-75">
                                    Shipment created!
                                </p>
                                <p className="text-sm text-center font-rocGroteskMedium text-slate-500 max-w-[411px]">
                                    Your shipment has been successfully created
                                </p>
                            </div>
                        </div>
                        <div className="w-full px-8 pb-4 pt-2  border-slate-200 ">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname="!bg-tradeally-neutral-20 !text-gm-50"
                                onClick={() => {
                                    setShipmentDetails({});
                                    setMode("");
                                    dispatch(shipmentActions.resetCreateShipmentSuccess());
                                    setshowSuccessShipmentAdded(false);
                                }}
                            />
                        </div>
                    </div>
                )}
            </ModalContainer>
        </>
    );
};

export default ShipmentPurchaseDetails;
