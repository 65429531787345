import React from "react";
import {Droppable} from "react-beautiful-dnd";
import {BoardCard} from "./BoardCard";
import {onScroll} from "helpers";
import Loader from "components/common/Loader";

type BoardListProps = {
    listData: {[key: string]: any}[];
    setScrollTitle: React.Dispatch<React.SetStateAction<string>>;
    scrollBar: string;
    taskPagination: {[key: string]: any};
    setTaskLimit: React.Dispatch<React.SetStateAction<number>>;
    taskLimit: number;
    limit: number;
    setLoadMoreTask: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    scrollTitle: string;
    loadMoreTask: boolean;
};

export const BoardList = ({
    listData,
    setScrollTitle,
    scrollBar,
    taskPagination,
    setTaskLimit,
    taskLimit,
    limit,
    setLoadMoreTask,
    loading,
    scrollTitle,
    loadMoreTask,
}: BoardListProps) => {
    return (
        <>
            {listData?.map((list, index) => (
                <Droppable key={list?.id} droppableId={list?.taskTitle}>
                    {(provided) => (
                        <div
                            className={"w-full"}
                            key={list?.id}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {list?.taskTotal !== 0 && (
                                <div className="w-full">
                                    <div
                                        className={`flex w-full items-center space-x-2 pb-1  border-b-[3px] ${
                                            list?.taskTitle?.toLowerCase() === "on-track" &&
                                            "border-p-50"
                                        }  ${
                                            list?.taskTitle?.toLowerCase() === "pending" &&
                                            "border-[#FF8A00]"
                                        }
                                        
                               ${list?.taskTitle?.toLowerCase() === "completed" && "border-g-50"}
                               ${
                                   list?.taskTitle?.toLowerCase() === "delayed" && "border-r-50"
                               } rounded-[2px]`}
                                    >
                                        {list?.taskTitle?.toLowerCase() === "on-track" && (
                                            <i className="ri-flashlight-line text-p-40"></i>
                                        )}
                                        {list?.taskTitle?.toLowerCase() === "pending" && (
                                            <i className="ri-hourglass-line text-logo-task-yellow"></i>
                                        )}
                                        {list?.taskTitle?.toLowerCase() === "completed" && (
                                            <i className="ri-checkbox-multiple-line text-g-50"></i>
                                        )}
                                        {list?.taskTitle?.toLowerCase() === "delayed" && (
                                            <i className="ri-alarm-warning-line text-r-40"></i>
                                        )}
                                        <p className="text-[13px] text-gm-40 font-rocGroteskMedium">
                                            {list?.taskTitle?.toLowerCase() === "on-track" &&
                                                "Active Tasks"}
                                            {list?.taskTitle?.toLowerCase() === "pending" &&
                                                "Pending Tasks"}
                                            {list?.taskTitle?.toLowerCase() === "completed" &&
                                                "Completed Tasks"}
                                            {list?.taskTitle?.toLowerCase() === "delayed" &&
                                                "Overdue Tasks"}
                                        </p>
                                        <div
                                            className={`border  border-slate-200 rounded-[16px] px-[10px] py-[4px]`}
                                        >
                                            <p className="text-[13px] text-gm-40 font-rocGroteskMedium">
                                                {list.taskTotal}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="overflow-y-auto  pb-40 max-h-[calc(100vh-220px)]"
                                        onScroll={(e) => {
                                            setScrollTitle(list?.taskTitle);
                                            onScroll(
                                                e.target as HTMLElement,
                                                taskPagination?.current,
                                                taskPagination?.number_of_pages,
                                                () => {
                                                    setTaskLimit(() => taskLimit + limit);
                                                    setLoadMoreTask(true);
                                                }
                                            );
                                        }}
                                        id={scrollBar}
                                    >
                                        <BoardCard cardDetails={list?.cardDetails} />
                                        {loading &&
                                            scrollTitle === list?.taskTitle &&
                                            loadMoreTask && <Loader />}
                                    </div>
                                </div>
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            ))}
        </>
    );
};
