/* eslint-disable */
import Accordion from "components/common/Accordion";
import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import CreateRfqMethods from "components/common/CreateRfqMethods";
import CustomMobileTable from "components/common/CustomMobileTable";
import CustomTable from "components/common/CustomTable";
import CustomToast from "components/common/CustomToast";
import CustomTooltip from "components/common/CustomTooltip";
import Dropdown from "components/common/Dropdown";
import MultiselectInput from "components/common/InputField/MultiselectInput";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import { SideDrawer } from "components/common/SideDrawer";
import TabsContainer from "components/common/TabsContainer";
import { Tooltip } from "components/common/ToolTip";
import PageFrame from "components/layout/PageFrame";
import { displayFileIcon, onScroll } from "helpers";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";
import { isFileSizeLessThanOrEqualTo } from "helpers/compareFileSizeInMb";
import { convertBytes } from "helpers/displayFileSize";
import { truncate } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { authActions, workFlowActions } from "redux/Ldb/actions";
import { firebaseService } from "services/firebaseService";
import { events as eventsOpt } from "variables/workflow";
import { WorkflowTemplates, WorkflowTemplatesImages } from "variables/workflowTemplates";

const WorkFlow = () => {
    const [workFlow, setWorkFlow] = useState(false);
    const [showActivity, setShowActivity] = useState(false);
    const [deleteWorkFlows, setDeleteWorkFlows] = useState(false);
    const [duplicateWorkflowModal, setDuplicateWorkflowModal] = useState(false);
    const [renameWorkflowModal, setRenameWorkflowModal] = useState(false);
    const [shareWorkflowModal, setShareWorkflowModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedOptionModal, setSelectedOptionModal] = useState(false);
    const [workFlowName, setWorkFlowName] = useState("");
    const [workFlowCategory, setWorkFlowCategory] = useState("");
    const [newWorkflowName, setNewWorkflowName] = useState("");
    const [workFlowId, setWorkFlowId] = useState("");
    const [prompt, setPrompt] = useState("");
    const [selectedWorkflow, setSelectedWorkflow] = useState<{ [key: string]: any } | null>(null);
    const [projectDescription, setProjectDescription] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [workFlowAction, setWorkFlowAction] = useState("");
    const [docData, setDocData] = useState<{ [key: string]: any } | null>(null);
    const [uploading, setUploading] = useState(false);
    const [profile, setProfile] = useState<{ [key: string]: any }>({});
    const [analytics, setAnalytics] = useState<{ [key: string]: any }>({});
    const [showTemplates, setShowTemplates] = useState(false);
    const [playVideo, setPlayVideo] = useState(false);
    const [workflowCat, setWorkflowCat] = useState("");
    const [manualCreation, setManualCreation] = useState<{
        workflowName: string;
        event: string | { [key: string]: any };
        sources: string[];
    }>({
        workflowName: "",
        event: "",
        sources: [],
    });

    const limit = 20;
    const [workFlowList, setWorkFlowList] = useState<{ [key: string]: any }[]>([]);
    const [workflowTemplates, setWorkflowTemplates] = useState<{ [key: string]: any }[]>([]);
    const [workflowActivities, setWorkflowActivities] = useState<{ [key: string]: any }[]>([]);
    const [loadMoreWorkFlow, setLoadMoreWorkFlow] = useState(false);
    const [workFlowPagination, setWorkFlowPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [workFlowLimit, setWorkFlowLimit] = useState<number>(limit);
    const [debouncedFilter, setDebouncedFilter] = useState({
        search: "",
        taId: "",
    });
    const [activeTab, setActiveTab] = useState(0);
    const [customEventModal, setCustomEventModal] = useState(false);
    const [customEvent, setCustomEvent] = useState("");
    const [fetchedCustomEvents, setFetchedCustomEvents] = useState([]);
    const [events, setEvents] = useState([]);

    const dashboardContent = document.getElementById("dashboardContent");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [param] = useSearchParams();
    const showCreateWorkflowModal = param.get("createWorkflow");
    const {
        fetchingWorkFlowList,
        fetchedWorkFlowListSuccess,
        playOrPauseWorkFlow,
        playOrPauseWorkFlowSuccess,
        deleteWorkFlow,
        deleteWorkFlowSuccess,
        creatingWorkFlow,
        createWorkFlowSuccess,
        fetchingWorkflowActivities,
        fetchedWorkflowActivitiesSuccess,
        duplicatingWorkflow,
        duplicateWorkflowSuccess,
        updatingWorkflowName,
        updateWorkflowNameSuccess,
        fetchingWorkflowAnalytics,
        fetchedWorkflowAnalyticsSuccess,
        fetchedWorkflowTemplateSuccess,
        fetchingEventAndActionOptionList,
        fetchedEventAndActionOptionListSuccess,
    } = useAppSelector((state) => state.workFlow);
    const { fetchUserProfileSuccess } = useAppSelector((state) => state.auth);

    const onWorkFlowTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent as HTMLElement,
                Number(workFlowPagination?.current),
                Number(workFlowPagination?.number_of_pages),
                () => {
                    setWorkFlowLimit(() => workFlowLimit + limit);
                    setLoadMoreWorkFlow(true);
                }
            ),
        [dashboardContent, workFlowPagination]
    );

    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onWorkFlowTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onWorkFlowTableScroll);
        };
    }, [dashboardContent, onWorkFlowTableScroll]);

    const handleCreateWorkflow = (workFlowType: string, doc?: { [key: string]: any }) => {
        const body: { [key: string]: any } = {
            workFlowType: workFlowType,
            workFlowCategory: workflowCat,
        };

        if (workFlowType === "document") {
            body.document = {
                path: doc?.path,
                name: doc?.name,
            };
            body.name = workFlowName;
            body.workFlowCategory = workFlowCategory;
            body.triggerEventSource = manualCreation.sources;
            body.firstEventName =
                typeof manualCreation.event === "object"
                    ? manualCreation?.event?._id
                    : manualCreation.event;
            body.position = {
                x: window.innerWidth / 4 + 505 / 2,
                y: 20,
            };
        }

        if (workFlowType === "manual") {
            body.name = manualCreation.workflowName;
            body.triggerEventSource = manualCreation.sources;
            body.firstEventName =
                typeof manualCreation.event === "object"
                    ? manualCreation?.event?._id
                    : manualCreation.event;
            body.position = {
                x: window.innerWidth / 4 + 505 / 2,
                y: 20,
            };
            // body.workFlowCategory = workFlowCategory;
            // body.description = projectDescription;
            // body.customerName = customerName;
        }
        
        dispatch(workFlowActions.createWorkflow(body));
    };

    const handleManualFormChange = (name: string, value: string | string[]) => {
        setManualCreation({
            ...manualCreation,
            [name]: value,
        });
    };

    useEffect(() => {
        if (showCreateWorkflowModal?.toLowerCase() === "success") {
            setWorkFlow(true);
        } else {
            setWorkFlow(false);
        }
    }, [showCreateWorkflowModal]);

    const handleUpload = (fileName: string, file: File) => {
        if (fileName && file) {
            setUploading(true);
            firebaseService.uploadFile(
                `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/workflow/${
                    profile?.profile?.businessName
                }/createdByDocument/${fileName}`,
                file,
                (url) => {
                    handleCreateWorkflow("document", { path: url, name: fileName });
                    setUploading(false);
                }
            );
        }
    };

    useEffect(() => {
        if (!Boolean(fetchUserProfileSuccess)) {
            dispatch(authActions.getLdbProfile());
        }
    }, [dispatch, fetchUserProfileSuccess]);

    useEffect(() => {
        if (Boolean(fetchUserProfileSuccess)) {
            setProfile(fetchUserProfileSuccess);
        }
    }, [fetchUserProfileSuccess]);

    useEffect(() => {
        if (showActivity && selectedWorkflow?._id) {
            dispatch(workFlowActions.getWorkflowActivities(selectedWorkflow?._id));
        }
    }, [dispatch, showActivity, selectedWorkflow?._id]);

    useEffect(() => {
        if (Boolean(fetchedWorkflowActivitiesSuccess)) {
            setWorkflowActivities(fetchedWorkflowActivitiesSuccess?.workFlowActivities);
            dispatch(workFlowActions.resetGetWorkflowActivitiesSuccess());
        }
    }, [fetchedWorkflowActivitiesSuccess, dispatch]);

    useEffect(() => {
        if (Boolean(createWorkFlowSuccess)) {
            setWorkFlowList((prev) => [createWorkFlowSuccess?.workFlow, ...prev]);
            // setAILoading(true);
            if (selectedOption === "manual") {
                navigate(`/dashboard/workflow/${createWorkFlowSuccess?.workFlow?._id}`);
            }
            if (selectedOption === "document") {
                navigate(
                    `/dashboard/workflow/${createWorkFlowSuccess?.workFlow?._id}?createdBy=document`
                );
            }
            setSelectedOption("");
            setSelectedOptionModal(false);
            setWorkFlowName("");
            setWorkFlowCategory("");
            setCustomerName("");
            setProjectDescription("");
            setWorkflowCat("");
            setManualCreation({ workflowName: "", event: "", sources: [] });
            // toast.custom((t) => <CustomToast t={t} message={"Workflow created"} />);
            dispatch(workFlowActions.resetCreateWorkFlowSuccess());
            // dispatch(workFlowActions.getworkFlowList(workFlowLimit));
            // navigate("/dashboard/workflow");
        }
    }, [createWorkFlowSuccess]);

    useEffect(() => {
        if (Boolean(duplicateWorkflowSuccess)) {
            setDuplicateWorkflowModal(false);
            dispatch(workFlowActions.resetDuplicateWorkflowSuccess());
        }
    }, [dispatch, duplicateWorkflowSuccess]);

    useEffect(() => {
        dispatch(workFlowActions.getworkFlowList(workFlowLimit));
        setTimeout(() => {
            setLoadMoreWorkFlow(false);
        }, 800);
    }, [dispatch, debouncedFilter, workFlowLimit, duplicateWorkflowSuccess]);

    useEffect(() => {
        if (Boolean(fetchedWorkFlowListSuccess)) {
            setWorkFlowList(fetchedWorkFlowListSuccess?.workFlows);
            setWorkFlowPagination({
                current: fetchedWorkFlowListSuccess?.pagination?.current,
                number_of_pages: fetchedWorkFlowListSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedWorkFlowListSuccess]);

    useEffect(() => {
        if (Boolean(playOrPauseWorkFlowSuccess)) {
            setWorkFlowAction("");
            setWorkFlowId("");
            dispatch(workFlowActions.getworkFlowList(workFlowLimit));
            dispatch(workFlowActions.resetPauseOrPlayWorkFlowSuccess());
            navigate("/dashboard/workflow");
        }
    }, [playOrPauseWorkFlowSuccess]);

    useEffect(() => {
        if (Boolean(deleteWorkFlowSuccess)) {
            setDeleteWorkFlows(false);
            setWorkFlowId("");
            dispatch(workFlowActions.resetDeleteWorkFlowSuccess());
            dispatch(workFlowActions.getworkFlowList(workFlowLimit));
            navigate("/dashboard/workflow");
        }
    }, [deleteWorkFlowSuccess]);
    useEffect(() => {
        if (Boolean(updateWorkflowNameSuccess)) {
            const updatedName = JSON.parse(JSON.stringify(updateWorkflowNameSuccess?.update))?.name;
            const workflowListCopy = JSON.parse(JSON.stringify([...workFlowList]));
            const updatedIndex = workflowListCopy.findIndex(
                (list: { [key: string]: any }) =>
                    list?._id === updateWorkflowNameSuccess?.update?._id
            );

            if (updatedIndex !== -1) {
                workflowListCopy[updatedIndex].name = updatedName;
                setWorkFlowList(workflowListCopy);
            }
            setRenameWorkflowModal(false);
            dispatch(workFlowActions.resetUpdateWorkflowNameSuccess());
        }
    }, [updateWorkflowNameSuccess, workFlowList]);

    const onDeleteWorkFlow = () => {
        dispatch(workFlowActions.deleteWorkFlow(workFlowId));
    };

    const onPlay = () => {
        const body = {
            active: true,
        };

        dispatch(workFlowActions.pauseOrPlayWorkFlow(workFlowId, body));
    };

    const onPause = () => {
        const body = {
            active: false,
        };
        dispatch(workFlowActions.pauseOrPlayWorkFlow(workFlowId, body));
    };

    const tableHeader = [
        { title: `Workflows (${workFlowList?.length})`, widthClass: "w-[30.7%]" },
        { title: `Trigger`, widthClass: "w-[22.5%]" },
        { title: "Date created", widthClass: "w-[13.6%]" },
        { title: "Number of runs", widthClass: "w-[11%]" },
        { title: "Created by", widthClass: "w-[8.5%]" },
        { title: "", widthClass: "w-[4%]" },
        { title: "", widthClass: "w-[4%]" },
    ];

    const optionsData = [
        // {
        //     title: "Generate with AI",
        //     subText: "Prompt TradeAlly AI Co-Pilot to generate and automate your custom workflow",
        //     icon: <i className="ri-sparkling-line text-3xl text-p-50 mb-6"></i>,
        //     action: () => {
        //         setSelectedOption("AI");
        //         setWorkFlow(false);
        //          setSelectedOptionModal(true)
        //     },
        // },
        {
            title: "Generate with AI",
            subText:
                "Use SynthAlly AI to generate and automate your workflow based on your document",
            icon: <i className="ri-file-upload-line text-3xl text-p-50 mb-6"></i>,
            action: () => {
                setSelectedOption("document");
                setWorkFlow(false);
                setSelectedOptionModal(true);
            },
        },
        {
            title: "Start a blank workflow",
            subText: "Manually set up your workflow and specify what happens on each touchpoint",
            icon: <i className="ri-file-2-line text-3xl text-p-50 mb-6"></i>,
            action: () => {
                setSelectedOption("manual");
                setWorkFlow(false);
                setSelectedOptionModal(true);
            },
        },
    ];

    const tableBody = workFlowList?.map((workflow, idx) => {
        const eventName = events.find((evt) => evt.value === workflow.firstEventName)?.event;

        return [
            {
                content: (
                    <div className="flex items-center py-[18px] space-x-1">
                        <div className="h-5 w-5 flex justify-center items-center rounded-[4px] bg-n-20">
                            <i className="ri-flashlight-fill text-[13px] text-p-50"></i>
                        </div>
                        <p
                            className={`text-[14px] ${
                                workflow?.active ? "text-slate-800" : "text-slate-400"
                            } capitalize font-rocGroteskRegular whitespace-nowrap`}
                        >
                            {truncate(workflow?.name, { length: 40 })}
                        </p>
                    </div>
                ),
                cellClickAction: () => {
                    // navigate(`/dashboard/workflow/run/${workflow?._id}`);
                    navigate(`/dashboard/workflow/${workflow?._id}`);
                },
            },
            {
                content: (
                    <div className="flex items-center py-[18px] space-x-1">
                        <div className="h-5 w-5 flex justify-center items-center rounded-[4px] bg-n-20">
                            <i className="ri-flashlight-fill text-[13px] text-p-50"></i>
                        </div>
                        <p
                            className={`text-[14px] ${
                                workflow?.active ? "text-slate-800" : "text-slate-400"
                            }  font-rocGroteskRegular whitespace-nowrap`}
                        >
                            {truncate(
                                eventName ||
                                    camelCaseToRegularCase(workflow?.firstEventName) ||
                                    "----",
                                { length: 30 }
                            )}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskRegular border-slate-100 text-sm ${
                            workflow?.active ? "text-slate-800" : "text-slate-400"
                        } `}
                    >
                        <div className="flex items-center gap-3">
                            <p>{moment(workflow?.createdAt).format("MMM DD, YYYY")}</p>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskRegular border-slate-100 text-sm ${
                            workflow?.active ? "text-slate-800" : "text-slate-400"
                        }`}
                    >
                        <div className="flex items-center gap-3">
                            <p>
                                {workflow?.runs?.length}{" "}
                                {workflow?.runs?.length === 0 ? "run" : "runs"}
                            </p>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full flex items-center justify-center py-3 pr-3  whitespace-nowrap border-slate-100 font-rocGroteskRegular text-sm ${
                            workflow?.active ? "text-slate-800" : "text-slate-400"
                        } `}
                    >
                        <div className="flex h-[24px] z-0 w-[24px] rounded-full  items-center gap-3">
                            <img
                                src={workflow?.creator?.profile?.avatar}
                                alt="avatar"
                                className={`${
                                    workflow?.active ? "" : "opacity-30 "
                                } w-6 h-6 rounded-full`}
                            />
                            {/* <span> {workflow?.creator?.profile?.businessName}</span> */}
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 space-x-1  flex items-center h-full border-slate-100 text-sm text-slate-800 `}
                        onClick={(e) => {
                            e.stopPropagation();
                            setWorkFlowId(workflow?._id);
                            setWorkFlowAction(`${workflow?.active ? "pause" : "play"}`);
                            setSelectedWorkflow(workflow);
                        }}
                    >
                        {workflow?.active ? (
                            <i className="ri-pause-circle-line text-[24px] text-slate-800 "></i>
                        ) : (
                            <i className="ri-play-line text-[24px]  text-slate-400"></i>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] flex justify-center  items-center h-full border-slate-100 text-sm text-slate-800 `}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Dropdown
                            dropdownTriggerClassName={" "}
                            dropdown={<i className="ri-more-2-fill text-lg text-slate-700"></i>}
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-4 -left-[150px] ${
                                workFlowList?.length >= 7 &&
                                idx >= workFlowList?.length - 4 &&
                                "!top-[-285px]"
                            } border border-slate-100 w-[159px] rounded !max-h-fit`}
                            dropdownItemsClasses={`last:text-r-50 last:hover:bg-r-25`}
                            name={"faq-dropdown"}
                            dropdownOptions={[
                                {
                                    label: "View runs",
                                    value: "view runs",
                                    action: () => {
                                        navigate(`/dashboard/workflow/run/${workflow?._id}`);
                                    },
                                },
                                {
                                    label: "View activity log",
                                    value: "view activity log",
                                    action: () => {
                                        setShowActivity(true);
                                        setSelectedWorkflow(workflow);
                                    },
                                },
                                {
                                    label: "Edit workflow name",
                                    value: "edit workflow name",
                                    action: () => {
                                        setRenameWorkflowModal(true);
                                        setSelectedWorkflow(workflow);
                                        setNewWorkflowName(workflow?.name);
                                    },
                                },
                                {
                                    label: `${
                                        workflow?.active ? "Pause workflow" : "Play workflow"
                                    }`,
                                    value: `${
                                        workflow?.active ? "pause workflow" : "play workflow"
                                    }`,
                                    action: () => {
                                        setWorkFlowId(workflow?._id);
                                        setWorkFlowAction(`${workflow?.active ? "pause" : "play"}`);
                                        setSelectedWorkflow(workflow);
                                    },
                                },
                                {
                                    label: "Duplicate workflow",
                                    value: "duplicate workflow",
                                    action: () => {
                                        setDuplicateWorkflowModal(true);
                                        setSelectedWorkflow(workflow);
                                    },
                                },
                                {
                                    label: "Delete workflow",
                                    value: "delete workflow",
                                    action: () => {
                                        setWorkFlowId(workflow?._id);
                                        setDeleteWorkFlows(true);
                                        setSelectedWorkflow(workflow);
                                    },
                                },
                            ]}
                        />
                    </div>
                ),
            },
        ];
    });

    const mobileTableBody = workFlowList?.map((workflow, idx) => {
        return {
            cellClickAction: () => {
                navigate(`/dashboard/workflow/${workflow?._id}`);
            },
            topString: workflow?.creator?.fullName || "----",
            avatar: (
                <img
                    src={workflow?.creator?.profile?.avatar}
                    alt="avatar"
                    className={`${workflow?.active ? "" : "opacity-30 "} w-12 h-12 rounded-full`}
                />
            ),
            topContent: (
                <p
                    className={`text-sm font-rocGroteskMedium ${
                        workflow?.active ? "text-slate-800" : "text-slate-400"
                    }`}
                >
                    {truncate(workflow?.creator?.fullName, { length: 20 })}
                </p>
            ),
            bottomContent: (
                <div>
                    <div className="flex items-center gap-3 mb-2.5">
                        <p className="text-sm font-rocGroteskMedium text-slate-500">
                            {truncate(workflow?.name, { length: 15 }) || "----"}
                        </p>
                        <p className="flex items-center gap-2 text-sm font-rocGroteskMedium">
                            <span
                                className={`w-2 h-2 rounded-full ${
                                    workflow?.active ? "bg-carribean-green" : "bg-slate-500"
                                }`}
                            ></span>
                            <span>{workflow?.active ? "Active" : "Inactive"}</span>
                        </p>
                    </div>
                    <div className="flex items-center gap-3">
                        <p className="text-sm font-rocGroteskMedium ">
                            {workflow?.runs?.length} {workflow?.runs?.length === 0 ? "run" : "runs"}
                        </p>
                        <p className="flex items-center gap-2 text-sm font-rocGroteskMedium">
                            {moment(workflow?.createdAt).format("MMM DD, YYYY")}
                        </p>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                setWorkFlowId(workflow?._id);
                                setWorkFlowAction(`${workflow?.active ? "pause" : "play"}`);
                                setSelectedWorkflow(workflow);
                            }}
                        >
                            {workflow?.active ? (
                                <i className="ri-pause-circle-line text-base text-slate-800 "></i>
                            ) : (
                                <i className="ri-play-line text-base text-slate-400"></i>
                            )}
                        </div>
                    </div>
                </div>
            ),
            rightIcon: (
                <div
                    className={`py-[18px] flex justify-center  items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        dropdownTriggerClassName={" "}
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-700"></i>}
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-4 -left-[150px] ${
                            workFlowList?.length >= 7 &&
                            idx >= workFlowList?.length - 4 &&
                            "!top-[-285px]"
                        } border border-slate-100 w-[159px] rounded !max-h-fit`}
                        dropdownItemsClasses={`last:text-r-50 last:hover:bg-r-25`}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "View runs",
                                value: "view runs",
                                action: () => {
                                    navigate(`/dashboard/workflow/run/${workflow?._id}`);
                                },
                            },
                            {
                                label: "View activity log",
                                value: "view activity log",
                                action: () => {
                                    setShowActivity(true);
                                    setSelectedWorkflow(workflow);
                                },
                            },
                            {
                                label: "Edit workflow name",
                                value: "edit workflow name",
                                action: () => {
                                    setRenameWorkflowModal(true);
                                    setSelectedWorkflow(workflow);
                                    setNewWorkflowName(workflow?.name);
                                },
                            },
                            {
                                label: `${workflow?.active ? "Pause workflow" : "Play workflow"}`,
                                value: `${workflow?.active ? "pause workflow" : "play workflow"}`,
                                action: () => {
                                    setWorkFlowId(workflow?._id);
                                    setWorkFlowAction(`${workflow?.active ? "pause" : "play"}`);
                                    setSelectedWorkflow(workflow);
                                },
                            },
                            {
                                label: "Duplicate workflow",
                                value: "duplicate workflow",
                                action: () => {
                                    setDuplicateWorkflowModal(true);
                                    setSelectedWorkflow(workflow);
                                },
                            },
                            {
                                label: "Delete workflow",
                                value: "delete workflow",
                                action: () => {
                                    setWorkFlowId(workflow?._id);
                                    setDeleteWorkFlows(true);
                                    setSelectedWorkflow(workflow);
                                },
                            },
                        ]}
                    />
                </div>
            ),
        };
    });

    // useEffect(() => {
    //     dispatch(workFlowActions.getWorkflowAnalytics());
    //     dispatch(workFlowActions.getWorkflowTemplates());
    // }, [dispatch]);

    // useEffect(() => {
    //     if (Boolean(fetchedWorkflowAnalyticsSuccess)) {
    //         setAnalytics(fetchedWorkflowAnalyticsSuccess);
    //     }
    // }, [fetchedWorkflowAnalyticsSuccess]);

    // useEffect(() => {
    //     if (Boolean(fetchedWorkflowTemplateSuccess)) {
    //         setWorkflowTemplates(fetchedWorkflowTemplateSuccess?.templates);
    //     }
    // }, [fetchedWorkflowTemplateSuccess]);

    const workflowEmptyOne = () =>
        WorkflowTemplates.map((item, idx) => (
            <div
                className="cursor-pointer"
                key={item.text}
                onClick={() => {
                    if (idx === 0) {
                        setWorkFlow(true);
                    } else {
                        navigate(item?.link);
                    }
                }}
            >
                <img src={item.img} className="rounded-[6px] w-full" alt="workflow" />
                <p className="font-rocGroteskMedium text-[14px] mt-1">{item.text}</p>
            </div>
        ));

    const workflowEmpty = () => {
        const fetchTemplate = workflowTemplates?.map((item) => item?.templateName);
        const filteredTemplate = WorkflowTemplates?.filter((templ, idx) => {
            return fetchTemplate?.includes(templ?.value);
        });
        const combinedData = filteredTemplate.map((item, idx) => {
            return {
                ...item,
                img: item?.img,
            };
        });
        return (
            <>
                <div
                    className="cursor-pointer"
                    onClick={() => {
                        setWorkFlow(true);
                    }}
                >
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700553426/create_new_kwn3lc.svg"
                        className="rounded-[6px] w-full"
                        alt="workflow"
                    />
                    <p className="font-rocGroteskMedium text-[14px] mt-1">Create Workflow</p>
                </div>
                {combinedData.map((item: { [key: string]: any }, idx: number) => {
                    return (
                        <div
                            className="cursor-pointer"
                            key={item.value + idx}
                            onClick={() => navigate(item?.link)}
                        >
                            <img src={item.img} className="rounded-[6px]" alt="workflow" />
                            <p className="font-rocGroteskMedium text-[14px] mt-1">{item.text}</p>
                        </div>
                    );
                })}
            </>
        );
    };

    const tabs = ["All Templates", "Recently Used"];

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <div className="grid grid-cols-4 gap-[13px] mt-8 max-lg:grid-cols-2">
                        {workflowEmptyOne()}
                    </div>
                );

            case 1:
                return (
                    <div>
                        {workflowTemplates?.length === 0 ? (
                            <div className="flex justify-center text-center">
                                <div className=" w-[249px] my-[10%] space-y-6 items-center justify-center flex flex-col">
                                    <div>
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700426503/flow-chart_hl3vys.svg"
                                            alt="flow chart icon"
                                        />
                                    </div>
                                    <p className="text-slate-700 font-rocGroteskMedium text-[14px]">
                                        You can start by using available templates to see them
                                        listed here
                                    </p>
                                    <div>
                                        <Button
                                            btnText={"Browse existing templates"}
                                            type={"button"}
                                            btnClassname="!py-3 !px-4"
                                            onClick={() => setActiveTab(0)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="grid grid-cols-4 gap-[13px] mt-8 max-lg:grid-cols-2">
                                {workflowEmpty()}
                            </div>
                        )}
                    </div>
                );

            default:
                return (
                    <div className="grid grid-cols-4 gap-[13px] mt-8 max-lg:grid-cols-2">
                        {workflowEmptyOne()}
                    </div>
                );
        }
    };

    useEffect(() => {
        dispatch(workFlowActions.getWorkflowAnalytics());
        dispatch(workFlowActions.getWorkflowTemplates());
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedWorkflowAnalyticsSuccess)) {
            setAnalytics(fetchedWorkflowAnalyticsSuccess);
        }
    }, [fetchedWorkflowAnalyticsSuccess]);

    useEffect(() => {
        if (Boolean(fetchedWorkflowTemplateSuccess)) {
            setWorkflowTemplates(fetchedWorkflowTemplateSuccess?.templates);
        }
    }, [fetchedWorkflowTemplateSuccess]);

    useEffect(() => {
        if (Boolean(workflowCat)) {
            dispatch(workFlowActions.getEventAndActionsOptionList(workFlowCategory || workflowCat));
        }
    }, [dispatch, workflowCat, workFlowCategory]);

    useEffect(() => {
        if (Boolean(fetchedEventAndActionOptionListSuccess)) {
            setFetchedCustomEvents(fetchedEventAndActionOptionListSuccess?.workFlow?.events);
        }
    }, [fetchedEventAndActionOptionListSuccess]);

    useEffect(() => {
        const customEventsList = fetchedCustomEvents?.map((eventValue) => ({
            event:
                eventValue?._id?.replace(/([A-Z])/g, " $1")?.[0]?.toUpperCase() +
                eventValue?._id?.replace(/([A-Z])/g, " $1")?.slice(1),
            value: eventValue,
            eventType: [workflowCat],
        }));
        setEvents([
            ...customEventsList,
            ...eventsOpt?.filter((opt) =>
                opt?.eventType?.includes(workFlowCategory || workflowCat)
            ),
        ]);
    }, [fetchedCustomEvents, workflowCat, workFlowCategory]);

    return (
        <PageFrame
            isLoading={(fetchingWorkFlowList || fetchingWorkflowAnalytics) && !loadMoreWorkFlow}
            containerClassName={"h-[calc(100vh-84px)]"}
        >
            <div
                className={`${
                    showTemplates ? "mx-2 bg-[#fff] rounded px-[24px]" : "mx-7"
                } max-lg:mx-1  h-[100%]`}
            >
                {showTemplates && (
                    <Button
                        btnText={"Go back"}
                        type={"button"}
                        btnClassname="!mb-2 !bg-transparent  !text-[#6B778C] !w-auto !px-0 !text-sm"
                        onClick={() => setShowTemplates(false)}
                        icon={<i className="ri-arrow-left-line text-[#142837]"></i>}
                    />
                )}
                <div className="mb-6 flex item-center justify-between">
                    <BreadCrumb
                        parentCrumb={
                            showTemplates
                                ? "Workflow Templates"
                                : workFlowList?.length > 0
                                ? "Workflow Analytics"
                                : "Workflow"
                        }
                        size="large"
                        parentClassName="!text-slate-700 !text-lg"
                    />
                </div>

                {showTemplates ? (
                    <div>
                        <TabsContainer
                            tabs={tabs}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            component={displayActiveTab()}
                            className="!px-0"
                            itemClassName="!pb-4"
                        />
                    </div>
                ) : workFlowList?.length > 0 ? (
                    <>
                        <div className="min-h-[96%]">
                            <div className="pb-10 flex justify-between border-b max-lg:grid max-lg:grid-cols-2 max-lg:gap-4">
                                <div className="space-y-5 max-lg:bg-white max-lg:shadow-faintShadow max-lg:p-4 max-lg:rounded">
                                    <div className="flex space-x-1 items-center">
                                        <p className="text-slate-950 text-[13px] max-md:text-[10px] font-rocGroteskRegular">
                                            Av. manual run time
                                        </p>
                                        {/* <i className="ri-information-fill text-slate-300"></i> */}
                                    </div>
                                    <div>
                                        <p className="text-[36px] max-md:text-2xl  font-rocGroteskMedium text-p-70">
                                            {Boolean(analytics?.avgManualRuntime?.avgManualRuntime)
                                                ? `${
                                                      analytics?.avgManualRuntime?.avgManualRuntime?.split(
                                                          " "
                                                      )?.[0]
                                                  }`
                                                : ""}{" "}
                                            {Boolean(
                                                analytics?.avgManualRuntime?.avgManualRuntime
                                            ) ? (
                                                <span className="text-[18px] max-md:text-sm font-rocGroteskRegular">
                                                    {
                                                        analytics?.avgManualRuntime?.avgManualRuntime?.split(
                                                            " "
                                                        )?.[1]
                                                    }
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </p>
                                        <p className="flex space-x-1 text-[12px] max-md:text-[10px] font-rocGroteskMedium">
                                            {Boolean(
                                                analytics?.avgManualRuntime?.percentageIncrease
                                            ) &&
                                                (analytics?.avgManualRuntime
                                                    ?.percentageIncrease?.[0] === "-" ? (
                                                    <i className="ri-arrow-down-line text-r-50"></i>
                                                ) : (
                                                    <i className="ri-arrow-up-line text-g-50"></i>
                                                ))}
                                            {Boolean(
                                                analytics?.avgManualRuntime?.percentageIncrease
                                            ) && (
                                                <span className="text-gm-35">
                                                    {analytics?.avgManualRuntime
                                                        ?.percentageIncrease?.[0] === "-"
                                                        ? `${analytics?.avgManualRuntime?.percentageIncrease}% down this month`
                                                        : `${analytics?.avgManualRuntime?.percentageIncrease}% up this month`}
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className="space-y-5 max-lg:bg-white max-lg:shadow-faintShadow max-lg:p-4 max-lg:rounded">
                                    <div className="flex space-x-1 items-center">
                                        <p className="text-slate-950 text-[13px] max-md:text-[10px] font-rocGroteskRegular">
                                            Av. automated run time
                                        </p>
                                        {/* <i className="ri-information-fill text-slate-300"></i> */}
                                    </div>
                                    <div>
                                        <p className="text-[36px] max-md:text-2xl font-rocGroteskMedium text-p-70">
                                            {Boolean(
                                                analytics?.avgAutomatedRuntime?.avgAutomatedRuntime
                                            )
                                                ? `${
                                                      analytics?.avgAutomatedRuntime?.avgAutomatedRuntime?.split(
                                                          " "
                                                      )?.[0]
                                                  }`
                                                : ""}{" "}
                                            {Boolean(
                                                analytics?.avgAutomatedRuntime?.avgAutomatedRuntime
                                            ) ? (
                                                <span className="text-[18px] max-md:text-sm font-rocGroteskRegular">
                                                    {
                                                        analytics?.avgAutomatedRuntime?.avgAutomatedRuntime?.split(
                                                            " "
                                                        )?.[1]
                                                    }
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </p>
                                        <p className="flex space-x-1 text-[12px] max-md:text-[10px] font-rocGroteskMedium">
                                            {Boolean(
                                                analytics?.avgAutomatedRuntime?.percentageIncrease
                                            ) &&
                                                (analytics?.avgAutomatedRuntime
                                                    ?.percentageIncrease?.[0] === "-" ? (
                                                    <i className="ri-arrow-down-line text-r-50"></i>
                                                ) : (
                                                    <i className="ri-arrow-up-line text-g-50"></i>
                                                ))}
                                            {Boolean(
                                                analytics?.avgAutomatedRuntime?.percentageIncrease
                                            ) && (
                                                <span className="text-gm-35">
                                                    {analytics?.avgAutomatedRuntime
                                                        ?.percentageIncrease?.[0] === "-"
                                                        ? `${analytics?.avgAutomatedRuntime?.percentageIncrease}% down this month`
                                                        : `${analytics?.avgAutomatedRuntime?.percentageIncrease}% up this month`}
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className="space-y-5 max-lg:bg-white max-lg:shadow-faintShadow max-lg:p-4 max-lg:rounded">
                                    <div className="flex space-x-1 items-center">
                                        <p className="text-slate-950 text-[13px] max-md:text-[10px] font-rocGroteskRegular">
                                            Av. time saved
                                        </p>
                                        {/* <i className="ri-information-fill text-slate-300"></i> */}
                                    </div>
                                    <div>
                                        <p className="text-[36px] max-md:text-2xl font-rocGroteskMedium text-p-70">
                                            {Boolean(analytics?.avgTimeSaved?.avgTimeSaved)
                                                ? `${
                                                      analytics?.avgTimeSaved?.avgTimeSaved?.split(
                                                          " "
                                                      )?.[0]
                                                  }`
                                                : ""}{" "}
                                            {Boolean(analytics?.avgTimeSaved?.avgTimeSaved) ? (
                                                <span className="text-[18px] max-md:text-sm font-rocGroteskRegular">
                                                    {
                                                        analytics?.avgTimeSaved?.avgTimeSaved?.split(
                                                            " "
                                                        )?.[1]
                                                    }
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </p>
                                        <p className="flex space-x-1 text-[12px] max-md:text-[10px] font-rocGroteskMedium">
                                            {Boolean(analytics?.avgTimeSaved?.percentageIncrease) &&
                                                (analytics?.avgTimeSaved
                                                    ?.percentageIncrease?.[0] === "-" ? (
                                                    <i className="ri-arrow-down-line text-r-50"></i>
                                                ) : (
                                                    <i className="ri-arrow-up-line text-g-50"></i>
                                                ))}
                                            {Boolean(
                                                analytics?.avgTimeSaved?.percentageIncrease
                                            ) && (
                                                <span className="text-gm-35">
                                                    {analytics?.avgTimeSaved
                                                        ?.percentageIncrease?.[0] === "-"
                                                        ? `${analytics?.avgTimeSaved?.percentageIncrease}% down this month`
                                                        : `${analytics?.avgTimeSaved?.percentageIncrease}% up this month`}
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className="space-y-5 max-lg:bg-white max-lg:shadow-faintShadow max-lg:p-4 max-lg:rounded">
                                    <div className="flex space-x-1 items-center">
                                        <p className="text-slate-950 text-[13px] max-md:text-[10px] font-rocGroteskRegular">
                                            Abandoned workflow
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-[36px] max-md:text-2xl font-rocGroteskMedium text-p-70">
                                            {analytics?.abandonedWorkflows}
                                        </p>
                                        <div className="flex items-center text-b-50 text-[12px] max-md:text-[10px] font-rocGroteskMedium">
                                            {/* <div className="underline cursor-pointer">
                                                <span className="">View reasons</span>
                                                <i className="ri-arrow-right-up-line"></i>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-5 max-lg:bg-white max-lg:shadow-faintShadow max-lg:p-4 max-lg:rounded">
                                    <div className="flex space-x-1 items-center">
                                        <p className="text-slate-950 text-[13px] max-md:text-[10px] font-rocGroteskRegular">
                                            Workflow bottlenecks
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-[36px] max-md:text-2xl font-rocGroteskMedium text-p-70">
                                            {analytics?.workflowBottlenecks}{" "}
                                            {/* <span className="text-[18px]">recurring</span> */}
                                        </p>
                                        <div className="flex items-center text-b-50 text-[12px] max-md:text-[10px] font-rocGroteskMedium">
                                            {/* <div className="underline cursor-pointer">
                                                <span className="">View bottlenecks</span>
                                                <i className="ri-arrow-right-up-line"></i>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5">
                                <>
                                    <div className="flex items-center max-sm:flex-col max-sm:gap-4 max-sm:items-start justify-between mb-[27px]">
                                        <div className="flex items-center gap-3">
                                            <p className="text-lg text-slate-700 font-rocGroteskMedium">
                                                Workflow List
                                            </p>
                                        </div>
                                        <div className="flex items-center gap-4">
                                            <div>
                                                <Button
                                                    btnText={"Browse templates"}
                                                    type={"button"}
                                                    btnClassname="!bg-transparent !w-auto border-[#E2E8F0] border-[1px] !text-[#6562E5]"
                                                    onClick={() => setShowTemplates(true)}
                                                    icon={
                                                        <img src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700230667/Vector_ma1bzs.svg" />
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <Button
                                                    btnText={"Create new workflow"}
                                                    type={"button"}
                                                    btnClassname="!py-3 !px-4"
                                                    onClick={() => setWorkFlow(true)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="max-lg:hidden">
                                            <CustomTable
                                                tableBody={tableBody}
                                                tableHeader={tableHeader}
                                                isScrollable={false}
                                                isCellBordered={false}
                                                isCellSelectable={false}
                                                isAllSelectable={false}
                                                bodyItemClass="hover:border-slate-100 hover:bg-slate-50 "
                                                headerContainerClass="!bg-slate-50 !border-slate-100 "
                                                tableClass="mb-12"
                                                headerItemClass="font-rocGroteskMedium "
                                            />
                                        </div>
                                        <div className="hidden max-lg:block">
                                            <CustomMobileTable data={mobileTableBody} />
                                        </div>
                                        {fetchingWorkFlowList && loadMoreWorkFlow && (
                                            <div className="flex justify-center my-4">
                                                <Loader color="gm-25" size={4} />
                                            </div>
                                        )}
                                        {workFlowPagination?.current ===
                                            workFlowPagination?.number_of_pages && (
                                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                                End of list
                                            </div>
                                        )}
                                    </div>
                                </>

                                {showActivity && (
                                    <SideDrawer
                                        title={selectedWorkflow?.name}
                                        data={workflowActivities}
                                        isLoading={fetchingWorkflowActivities}
                                        onClick={() => setShowActivity(false)}
                                    />
                                )}
                                <ModalContainer
                                    open={deleteWorkFlows}
                                    showCloseIcon={false}
                                    modalClassName={"py-10 px-10 max-sm:px-0"}
                                    closeModal={() => setDeleteWorkFlows(false)}
                                    tailwindClassName="min-h-[371px] w-[553px] max-sm:w-[95%]"
                                >
                                    <div className="bg-white flex flex-col rounded-[20px]  ">
                                        <div className=" mt-5 px-6 flex justify-end ">
                                            <i
                                                onClick={() => setDeleteWorkFlows(false)}
                                                className="ri-close-fill cursor-pointer text-gm-45 text-2xl"
                                            ></i>
                                        </div>
                                        <div className="px-14 my-7 pb-2 max-sm:px-5">
                                            <div className="pb-7">
                                                <h6 className="text-center font-rocGroteskMedium text-[16px]">
                                                    Are you sure you want to delete the{" "}
                                                    <span className="font-rocGroteskBold capitalize">
                                                        {selectedWorkflow?.name}{" "}
                                                    </span>{" "}
                                                    workflow? Deleting this workflow means that you
                                                    will not have access to it anymore
                                                </h6>
                                            </div>
                                            <div className="my-2 space-y-4">
                                                <Button
                                                    btnText={"Yes, delete workflow"}
                                                    type={"button"}
                                                    btnClassname="!py-3 !bg-[#FFEFEC] border border-r-50 !text-dark-gray-2 !px-4"
                                                    onClick={onDeleteWorkFlow}
                                                    isLoading={deleteWorkFlow}
                                                />
                                                <Button
                                                    btnText={"No, close"}
                                                    type={"button"}
                                                    btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                                    onClick={() => setDeleteWorkFlows(false)}
                                                    disabled={deleteWorkFlow}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ModalContainer>
                                <ModalContainer
                                    open={duplicateWorkflowModal}
                                    showCloseIcon={false}
                                    modalClassName={"py-10 px-10 max-sm:px-0"}
                                    closeModal={() => setDuplicateWorkflowModal(false)}
                                    tailwindClassName="min-h-[371px] w-[553px] max-sm:w-[95%]"
                                >
                                    <div className="bg-white flex flex-col rounded-[20px] ">
                                        <div className=" mt-5 px-6 flex justify-end ">
                                            <i
                                                onClick={() => setDuplicateWorkflowModal(false)}
                                                className="ri-close-fill cursor-pointer text-gm-45 text-2xl"
                                            ></i>
                                        </div>
                                        <div className="px-14 my-7 max-sm:px-5">
                                            <div className="pb-7">
                                                <h6 className="text-center font-rocGroteskMedium text-[16px]">
                                                    Are you sure you want to duplicate the{" "}
                                                    <span className="font-rocGroteskBold">
                                                        {selectedWorkflow?.name}{" "}
                                                    </span>
                                                    workflow?{" "}
                                                </h6>
                                            </div>
                                            <div className="my-2 space-y-4">
                                                <Button
                                                    btnText={"Yes, duplicate"}
                                                    type={"button"}
                                                    btnClassname="!py-3 !px-4"
                                                    isLoading={duplicatingWorkflow}
                                                    disabled={duplicatingWorkflow}
                                                    onClick={() =>
                                                        dispatch(
                                                            workFlowActions.duplicateWorkflow(
                                                                selectedWorkflow?._id
                                                            )
                                                        )
                                                    }
                                                />
                                                <Button
                                                    btnText={"No, close"}
                                                    type={"button"}
                                                    btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                                    onClick={() => setDuplicateWorkflowModal(false)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ModalContainer>
                                <ModalContainer
                                    open={workFlowAction === "play"}
                                    showCloseIcon={false}
                                    modalClassName={"py-10 px-10 max-sm:px-0"}
                                    closeModal={() => setWorkFlowAction("")}
                                    tailwindClassName="min-h-[371px] w-[553px] max-sm:w-[95%]"
                                >
                                    <div className="bg-white flex flex-col rounded-[20px]">
                                        <div className=" mt-5 px-6 flex justify-end ">
                                            <i
                                                onClick={() => setWorkFlowAction("")}
                                                className="ri-close-fill cursor-pointer text-gm-45 text-2xl"
                                            ></i>
                                        </div>
                                        <div className="px-14 my-7 max-sm:px-5">
                                            <div className="pb-7">
                                                <h6 className="text-center font-rocGroteskMedium text-[16px]">
                                                    Are you sure you want to play the{" "}
                                                    <span className="font-rocGroteskBold">
                                                        {selectedWorkflow?.name}{" "}
                                                    </span>{" "}
                                                    workflow? You can always pause it at anytime.
                                                </h6>
                                            </div>
                                            <div className="my-2 space-y-4">
                                                <Button
                                                    btnText={"Yes, play workflow"}
                                                    type={"button"}
                                                    btnClassname="!py-3  !px-4"
                                                    onClick={onPlay}
                                                    isLoading={playOrPauseWorkFlow}
                                                />
                                                <Button
                                                    btnText={"No, close"}
                                                    type={"button"}
                                                    btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                                    onClick={() => setWorkFlowAction("")}
                                                    disabled={playOrPauseWorkFlow}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ModalContainer>
                                <ModalContainer
                                    modalClassName={"py-10 px-10 max-sm:px-0"}
                                    tailwindClassName="min-h-[371px] w-[553px] max-sm:w-[95%]"
                                    open={workFlowAction === "pause"}
                                    showCloseIcon={false}
                                    closeModal={() => setWorkFlowAction("")}
                                >
                                    <div className="bg-white flex flex-col rounded-[20px]">
                                        <div className="mt-5 px-6 flex justify-end ">
                                            <i
                                                onClick={() => setWorkFlowAction("")}
                                                className="ri-close-fill cursor-pointer  text-gm-45 text-2xl"
                                            ></i>
                                        </div>
                                        <div className="px-14 my-7 max-sm:px-5">
                                            <div className="pb-7">
                                                <h6 className="text-center font-rocGroteskMedium text-[16px]">
                                                    Are you sure you want to pause the{" "}
                                                    <span className="font-rocGroteskBold">
                                                        {selectedWorkflow?.name}{" "}
                                                    </span>
                                                    workflow? The workflow will not Keep running
                                                    until you restart it.
                                                </h6>
                                            </div>
                                            <div className="my-2 space-y-4">
                                                <Button
                                                    btnText={"Yes, pause workflow"}
                                                    type={"button"}
                                                    btnClassname="!py-3 !bg-[#FFEFEC] border border-r-50 !text-dark-gray-2 !px-4"
                                                    onClick={onPause}
                                                    isLoading={playOrPauseWorkFlow}
                                                />
                                                <Button
                                                    btnText={"No, close"}
                                                    type={"button"}
                                                    btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                                    onClick={() => setWorkFlowAction("")}
                                                    disabled={playOrPauseWorkFlow}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ModalContainer>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="">
                        <div
                            className="flex max-md:flex-col-reverse max-md:gap-4 max-md:px-6 rounded-[10px] py-[38px] pl-[29px] pr-[70px] shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)] 
                        bg-gradient-to-r from-[#e0e1ef] to-[#e3eef1] relative justify-between"
                        >
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700551795/Lines_htbkec.svg"
                                alt=""
                                className="absolute top-0 left-0"
                            />
                            <div className="">
                                <h1 className="!font-bold text-[30px] max-md:text-2xl leading-[32px] text-[#142837] font-rocGroteskBold max-w-[522px]">
                                    Automate your business operations with TradeAlly’s powerful
                                    workflow
                                </h1>
                                <Button
                                    btnType="textFirst"
                                    btnText={"Create a workflow"}
                                    type="button"
                                    btnClassname="!leading-[24px] !bg-[#042821] !text-white !w-auto mt-[70px] max-md:mt-3 !rounded-[4px] !py-[10px] !px-3"
                                    onClick={() => setWorkFlow(true)}
                                    icon={<i className="ri-arrow-right-line"></i>}
                                />
                            </div>
                            <button className="relative" onClick={() => setPlayVideo(true)}>
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700564085/Banner_image_x4lohs.svg"
                                    alt=""
                                    className="border-[#074237] border-[1px] rounded-[6px]"
                                />
                            </button>
                        </div>
                        <div className="mt-[34px] py-5 pl-5">
                            <div className="flex justify-between items-center">
                                <p className="font-rocGroteskMedium text-[20px] leading-[2rem] text-[#0F172A]">
                                    Browse workflow templates
                                </p>
                                {/* <Button
                                    type={"button"}
                                    btnClassname=" !w-auto !p-0 bg-transparent text-[#142837] font-[15px]"
                                    icon={
                                        <i className="ri-arrow-up-s-line text-2xl text-[#000]"></i>
                                    }
                                /> */}
                            </div>
                            <div className="flex justify-between items-center mt-[16px] text-xs font-rocGroteskMedium">
                                <p className="font-rocGroteskMedium text-[12px] leading-[17px] ">
                                    Recommended workflow templates
                                </p>

                                <Button
                                    btnText={"Show all"}
                                    type={"button"}
                                    onClick={() => setShowTemplates(true)}
                                    btnClassname=" !w-auto !p-0 !bg-transparent !text-[#16C6A4] underline"
                                />
                            </div>

                            <div className="grid grid-cols-4 gap-[12px] mt-2  max-lg:grid-cols-2">
                                {workflowEmptyOne()}
                            </div>
                        </div>

                        {playVideo && (
                            <ModalContainer open={playVideo} closeModal={() => setPlayVideo(false)}>
                                <div className=" h-[786px] max-lg:w-full max-lg:px-2 max-sm:h-[265px] max-lg:h-[500px] rounded-xl flex justify-center items-center ">
                                    <video
                                        poster="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto,f_auto/v1692968730/Tradeally_2.0/Landing%20page/demo_video_preview_psvkng.svg"
                                        src="https://res.cloudinary.com/dvxi7qcmd/video/upload/q_auto,f_auto/v1694536885/Tradeally_2.0/Landing%20page/YC_Demo_No_sound_f7zhtk.mp4"
                                        playsInline
                                        controls={true}
                                        autoPlay
                                        controlsList="nodownload"
                                        muted
                                        className="z-10 cursor-pointer w-[1000px] h-[598px] max-lg:w-full max-lg:h-full"
                                    />
                                </div>
                            </ModalContainer>
                        )}
                    </div>
                )}

                <ModalContainer
                    open={renameWorkflowModal}
                    showCloseIcon={false}
                    closeModal={() => setRenameWorkflowModal(false)}
                    modalClassName={"py-10 px-10 max-sm:p-0"}
                    tailwindClassName="min-h-[371px] w-[553px] max-sm:w-[95%]"
                >
                    <div className="bg-white  flex flex-col py-8 rounded-[20px]  relative">
                        <div className=" flex justify-end px-5">
                            <i
                                className="ri-close-fill cursor-pointer  text-gm-45 text-2xl"
                                onClick={() => setRenameWorkflowModal(false)}
                            ></i>
                        </div>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                dispatch(
                                    workFlowActions.updateWorkflowName({
                                        name: newWorkflowName,
                                        id: selectedWorkflow?._id,
                                    })
                                );
                            }}
                            className="px-14 max-sm:px-5"
                        >
                            <div className="mb-10 ">
                                <h6 className="text-center font-rocGroteskMedium text-2xl mb-10">
                                    Rename workflow
                                </h6>
                                <div>
                                    <TextInput
                                        value={newWorkflowName}
                                        name={"workflowName"}
                                        type={"text"}
                                        required={true}
                                        onChange={(e) => {
                                            setNewWorkflowName(e?.target?.value);
                                        }}
                                        placeholder={"Workflow name"}
                                        containerClassname="w-full "
                                        inputContainerClassname={""}
                                        inputClassName={`w-full`}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col items-center gap-2.5">
                                <Button
                                    btnText={"Save changes"}
                                    type={"submit"}
                                    btnClassname="!py-3 !px-4"
                                    isLoading={updatingWorkflowName}
                                    // onClick={() => {
                                    // }}
                                    // disabled={deletingAction}
                                />
                                <p
                                    onClick={() => {
                                        setRenameWorkflowModal(false);
                                    }}
                                    className="text-sm font-rocGroteskMedium text-center"
                                >
                                    Close
                                </p>
                            </div>
                        </form>
                    </div>
                </ModalContainer>

                <ModalContainer
                    open={workFlow}
                    showCloseIcon={false}
                    closeModal={() => setWorkFlow(false)}
                    tailwindClassName="w-[95%]  2xl:w-[60%] xl:w-[55%] lg:w-[80%]"
                >
                    <div className="bg-white flex h-full pb-10  flex-col rounded-[20px] ">
                        <div className=" mt-5 px-6 flex justify-end ">
                            <i
                                onClick={() => setWorkFlow(false)}
                                className="ri-close-fill cursor-pointer text-gm-45 text-2xl"
                            ></i>
                        </div>

                        <div className="px-14 max-sm:px-5 flex flex-col space-y-10 justify-center items-center">
                            <div className="text-center font-rocGroteskMedium space-y-4">
                                <p className="text-slate-500 text-[14px] ">Step 1</p>
                                <p className="text-[22px] text-gm-50 ">
                                    How do you want to automate your new workflow?
                                </p>
                            </div>
                            <CreateRfqMethods
                                optionsData={optionsData}
                                selectedOption={selectedOption}
                            />
                        </div>
                    </div>
                </ModalContainer>

                <ModalContainer
                    open={selectedOption === "AI" && selectedOptionModal}
                    showCloseIcon={false}
                    closeModal={() => {
                        setSelectedOption("");
                        setSelectedOptionModal(false);
                    }}
                    modalClassName={"py-10 px-10 sm:px-20"}
                    tailwindClassName="w-[553px] min-h-[568px]"
                >
                    <div className="bg-white flex flex-col rounded-[20px] ">
                        <div className=" mt-3 px-6 flex justify-end ">
                            <i
                                className="ri-close-fill cursor-pointer text-gm-45 text-2xl"
                                onClick={() => {
                                    setSelectedOption("");
                                    setSelectedOptionModal(false);
                                }}
                            ></i>
                        </div>
                        <div className="px-14 ">
                            <div className="text-center font-rocGroteskMedium space-y-4">
                                <p className="text-slate-500 text-[14px] ">Step 2</p>
                                <p className="text-[22px] text-gm-50 ">
                                    Give the SynthAlly AI Co-pilot a prompt to work with
                                </p>
                            </div>
                            <div className="mt-10">
                                <TextInput
                                    value={workFlowName}
                                    name={"workFlowName"}
                                    type={"text"}
                                    onChange={(e) => setWorkFlowName(e.target.value)}
                                    placeholder={"Enter workflow name"}
                                    required={true}
                                    containerClassname="mb-3"
                                />
                                <TextareaInput
                                    value={prompt}
                                    name={"prompt"}
                                    rows={6}
                                    onChange={(e) => setPrompt(e.target.value)}
                                    placeholder={"Enter prompt"}
                                    required={true}
                                    containerClassname="mb-3"
                                />
                            </div>
                            <div className="mt-7 space-y-4">
                                <Button
                                    btnText={"Create Workflow"}
                                    type={"button"}
                                    btnClassname="!py-3 !px-4"
                                    onClick={() => {
                                        setSelectedOption("");
                                        setSelectedOptionModal(false);
                                    }}
                                />
                                <Button
                                    btnText={"Back"}
                                    type={"button"}
                                    btnClassname="!py-3 !bg-transparent  !text-dark-gray-2 !px-4"
                                    onClick={() => {
                                        setWorkFlow(true);
                                        setSelectedOption("");
                                        setSelectedOptionModal(false);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>

                <ModalContainer
                    open={selectedOption === "manual" && selectedOptionModal}
                    showCloseIcon={false}
                    closeModal={() => {
                        setSelectedOption("");
                        setSelectedOptionModal(false);
                        // setWorkFlowName("");
                        // setWorkFlowCategory("");
                        // setProjectDescription("");
                    }}
                    tailwindClassName="w-[35.3%] max-sm:w-[95%]"
                >
                    <div className="bg-white flex flex-col rounded-[10px] px-[30px] py-8">
                        <div className="flex items-center justify-between mb-6">
                            <p className="text-slate-500 font-rocGroteskMedium text-sm ">Step 1</p>
                            <i
                                onClick={() => {
                                    setSelectedOption("");
                                    setSelectedOptionModal(false);
                                    // setWorkFlowName("");
                                    // setWorkFlowCategory("");
                                    // setProjectDescription("");
                                }}
                                className="ri-close-fill text-gm-45 text-lg cursor-pointer"
                            ></i>
                        </div>
                        <div>
                            <div className="text-left font-rocGroteskMedium mb-6">
                                <p className="text-2xl text-gm-50 mb-1">Create new Workflow</p>

                                <p className=" mb-1 flex items-center justify-between">
                                    <span className="text-sm text-slate-500">
                                        Choose from the list of workflow type
                                    </span>
                                    <CustomTooltip
                                        title={"What are workflow types?"}
                                        text={
                                            "This is a list of workflow types that you can select from. Choosing any of these helps streamline the types of actions that we show you, therefore cutting down the time it would take you to create a workflow."
                                        }
                                    >
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700483423/Tradeally_2.0/icons/help_jnbohl.svg"
                                            alt="help"
                                            className="w-6 h-6"
                                        />
                                    </CustomTooltip>
                                </p>
                            </div>
                            <div className="flex flex-col gap-4">
                                <div
                                    onClick={() => {
                                        setWorkflowCat("rfq");
                                        setSelectedOptionModal(false);
                                    }}
                                    className="p-4 h-14 rounded border border-slate-200 gap-3 cursor-pointer flex items-center group hover:border-p-50"
                                >
                                    <i className="ri-git-pull-request-line text-2xl group-hover:text-p-50"></i>
                                    <span className="font-rocGroteskMedium text-base group-hover:text-p-50">
                                        Request For Quote (RFQ)
                                    </span>
                                </div>
                                <div
                                    onClick={() => {
                                        setWorkflowCat("order");
                                        setSelectedOptionModal(false);
                                    }}
                                    className="p-4 h-14 rounded border border-slate-200 gap-3 cursor-pointer flex items-center group hover:border-p-50"
                                >
                                    <i className="ri-route-line text-2xl group-hover:text-p-50"></i>
                                    <span className="font-rocGroteskMedium text-base group-hover:text-p-50">
                                        Order processing
                                    </span>
                                </div>
                                <div
                                    onClick={() => {
                                        setWorkflowCat("po");
                                        setSelectedOptionModal(false);
                                    }}
                                    className="p-4 h-14 rounded border border-slate-200 gap-3 cursor-pointer flex items-center group hover:border-p-50"
                                >
                                    <i className="ri-file-list-3-line text-2xl group-hover:text-p-50"></i>
                                    <span className="font-rocGroteskMedium text-base group-hover:text-p-50">
                                        Purchasing order
                                    </span>
                                </div>
                                <div
                                    onClick={() => {
                                        setWorkflowCat("shipment");
                                        setSelectedOptionModal(false);
                                    }}
                                    className="p-4 h-14 rounded border border-slate-200 gap-3 cursor-pointer flex items-center group hover:border-p-50"
                                >
                                    <i className="ri-truck-line text-2xl group-hover:text-p-50"></i>
                                    <span className="font-rocGroteskMedium text-base group-hover:text-p-50">
                                        Shipment
                                    </span>
                                </div>
                                <div
                                    onClick={() => {
                                        setWorkflowCat("quotes");
                                        setSelectedOptionModal(false);
                                    }}
                                    className="p-4 h-14 rounded border border-slate-200 gap-3 cursor-pointer flex items-center group hover:border-p-50"
                                >
                                    <i className="ri-article-line text-2xl group-hover:text-p-50"></i>
                                    <span className="font-rocGroteskMedium text-base group-hover:text-p-50">
                                        Quotes
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalContainer>

                <ModalContainer
                    open={Boolean(workflowCat)}
                    showCloseIcon={false}
                    closeModal={() => {
                        setWorkflowCat("");
                        setManualCreation({ workflowName: "", event: "", sources: [] });
                    }}
                    tailwindClassName="w-[35.3%] max-sm:w-[100%]"
                >
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleCreateWorkflow("manual");
                        }}
                        className="bg-white flex flex-col rounded-[10px] px-[30px] py-8"
                    >
                        <div className="flex items-center justify-between mb-6">
                            <p className="text-slate-500 font-rocGroteskMedium text-sm ">Step 2</p>
                            <i
                                onClick={() => {
                                    setWorkflowCat("");
                                    setManualCreation({ workflowName: "", event: "", sources: [] });
                                    // setWorkFlowName("");
                                    // setWorkFlowCategory("");
                                    // setProjectDescription("");
                                }}
                                className="ri-close-fill text-gm-45 text-lg cursor-pointer"
                            ></i>
                        </div>
                        <div className="mb-6">
                            <div className="text-left font-rocGroteskMedium mb-6">
                                <p className="text-lg text-gm-50 mb-1">
                                    {workflowCat === "rfq" && "Request For Quote (RFQ) workflow"}
                                    {workflowCat === "po" && "Purchasing order workflow"}
                                    {workflowCat === "order" && "Order processing workflow"}
                                    {workflowCat === "quotes" && "Quote processing workflow"}
                                    {workflowCat === "shipment" && "Shipment processing workflow"}
                                </p>

                                <p className=" mb-1 flex items-center justify-between">
                                    <span className="text-sm text-slate-500">
                                        kindly choose from the list of events
                                    </span>
                                    <CustomTooltip
                                        title={"What are events?"}
                                        text={
                                            "Events typically start workflows. They are actions or things that happen first before any future actions are set in motion. In this case, it kickstarts your RFQ workflow."
                                        }
                                    >
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700483423/Tradeally_2.0/icons/help_jnbohl.svg"
                                            alt="help"
                                            className="w-6 h-6"
                                        />
                                    </CustomTooltip>
                                </p>
                            </div>
                            <div className="flex flex-col gap-4">
                                <TextInput
                                    value={manualCreation.workflowName}
                                    name={"workflowName"}
                                    type={"text"}
                                    onChange={(e) =>
                                        handleManualFormChange(e.target.name, e.target.value)
                                    }
                                    required={true}
                                    placeholder={"Enter workflow name"}
                                    containerClassname=""
                                    inputClassName="w-full "
                                />
                                <SelectInput
                                    value={
                                        typeof manualCreation.event === "object"
                                            ? manualCreation?.event?._id
                                            : manualCreation.event
                                    }
                                    name="event"
                                    placeholder={"Select event"}
                                    handleChange={(name, value) => {
                                        handleManualFormChange(name, value);
                                    }}
                                    isRequired={true}
                                    dropdownOptions={events
                                        ?.filter((opt) => opt?.eventType?.includes(workflowCat))
                                        ?.map((event) => ({
                                            label: event.event,
                                            value: event.value,
                                        }))}
                                    searchLoading={fetchingEventAndActionOptionList}
                                    customDropdownContainer={
                                        <div
                                            onClick={() => setCustomEventModal(true)}
                                            className="pl-4 py-1 cursor-pointer "
                                        >
                                            <p className="text-sm text-p-50 flex items-center gap-1">
                                                <i className="ri-add-fill text-p-50 text-lg mt-[-3px]"></i>
                                                <span>Add a custom event</span>
                                            </p>
                                        </div>
                                    }
                                />
                                <MultiselectInput
                                    value={manualCreation.sources}
                                    name="sources"
                                    placeholder={"Select sources"}
                                    className=""
                                    isRequired={true}
                                    handleChange={(name, value, valuesArray) =>
                                        handleManualFormChange(
                                            name as string,
                                            valuesArray as string[]
                                        )
                                    }
                                    dropdownOptions={[
                                        {
                                            label: "Whatsapp",
                                            value: "whatsapp",
                                            icon: (
                                                <img
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700554454/Tradeally_2.0/icons/whatsapp_vjsjhe.svg"
                                                    alt="whatsapp logo"
                                                    className="w-6 h-6"
                                                />
                                            ),
                                        },
                                        {
                                            label: "Gmail",
                                            value: "google",
                                            icon: (
                                                <img
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700554454/Tradeally_2.0/icons/google_wtrcmu.svg"
                                                    alt="google logo"
                                                    className="w-6 h-6"
                                                />
                                            ),
                                        },
                                        {
                                            label: "Outlook",
                                            value: "microsoft",
                                            icon: (
                                                <img
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700839953/Tradeally_2.0/icons/microsoft_logo_cwqmmo.svg"
                                                    alt="microsoft logo"
                                                    className="w-6 h-6"
                                                />
                                            ),
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-2.5">
                            <Button
                                btnText={"Back"}
                                type={"button"}
                                btnClassname={"!bg-slate-100 !text-gm-50"}
                                onClick={() => {
                                    setWorkflowCat("");
                                    setSelectedOption("manual");
                                    setSelectedOptionModal(true);
                                    setManualCreation({ workflowName: "", event: "", sources: [] });
                                }}
                                disabled={creatingWorkFlow}
                            />
                            <Button
                                btnText={"Create Workflow"}
                                type={"submit"}
                                isLoading={creatingWorkFlow}
                            />
                        </div>
                    </form>
                </ModalContainer>

                <ModalContainer
                    open={selectedOption === "document" && selectedOptionModal}
                    showCloseIcon={false}
                    closeModal={() => {
                        setDocData(null);
                        setSelectedOption("");
                        setSelectedOptionModal(false);
                        setManualCreation({ workflowName: "", event: "", sources: [] });
                        setWorkFlowName("");
                        setWorkFlowCategory("");
                    }}
                    modalClassName={" py-10 px-10 sm:px-20"}
                    tailwindClassName="w-[40%] max-sm:w-[100%]"
                >
                    <div className="bg-white flex flex-col rounded-[8px]">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleUpload(docData?.name as string, docData as File);
                            }}
                            className="bg-white shadow-cardShadow relative rounded-[20px] py-5 "
                        >
                            <div className="cursor-pointer  px-6 flex justify-end ">
                                <i
                                    onClick={() => {
                                        setDocData(null);
                                        setSelectedOption("");
                                        setSelectedOptionModal(false);
                                        setWorkFlowName("");
                                        setWorkFlowCategory("");
                                    }}
                                    className="ri-close-fill text-gm-45 text-[30px]"
                                ></i>
                            </div>
                            <p className="text-xl font-rocGroteskMedium text-center mb-6">
                                Upload File
                            </p>
                            <div className="flex flex-col gap-3 mb-3 px-10">
                                <TextInput
                                    value={workFlowName}
                                    name={"workFlowName"}
                                    type={"text"}
                                    onChange={(e) => setWorkFlowName(e.target.value)}
                                    placeholder={"Enter workflow name"}
                                    required={true}
                                    containerClassname=""
                                />
                                <SelectInput
                                    value={workFlowCategory}
                                    name="workflowCategory"
                                    handleChange={(name, value) => setWorkFlowCategory(value)}
                                    inputClassName={" "}
                                    optionItemContainerClassName={"hover:bg-slate-50"}
                                    placeholder={"Select workflow type"}
                                    isRequired={true}
                                    dropdownOptions={[
                                        {
                                            label: "RFQ",
                                            value: "rfq",
                                        },
                                        { label: "Purchase Order", value: "po" },
                                        { label: "Order", value: "order" },
                                        { label: "Shipment", value: "shipment" },
                                        { label: "Quotes", value: "quotes" },
                                    ]}
                                />
                                <SelectInput
                                    value={
                                        typeof manualCreation.event === "object"
                                            ? manualCreation?.event?._id
                                            : manualCreation.event
                                    }
                                    name="event"
                                    placeholder={"Select event"}
                                    handleChange={(name, value) => {
                                        handleManualFormChange(name, value);
                                    }}
                                    isRequired={true}
                                    disabled={!Boolean(workFlowCategory)}
                                    searchLoading={fetchingEventAndActionOptionList}
                                    dropdownOptions={events
                                        ?.filter((opt) =>
                                            opt?.eventType?.includes(workFlowCategory)
                                        )
                                        ?.map((event) => ({
                                            label: event.event,
                                            value: event.value,
                                        }))}
                                />
                                <MultiselectInput
                                    value={manualCreation.sources}
                                    name="sources"
                                    placeholder={"Select sources"}
                                    className=""
                                    isRequired={true}
                                    handleChange={(name, value, valuesArray) =>
                                        handleManualFormChange(
                                            name as string,
                                            valuesArray as string[]
                                        )
                                    }
                                    dropdownOptions={[
                                        {
                                            label: "Whatsapp",
                                            value: "whatsapp",
                                            icon: (
                                                <img
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700554454/Tradeally_2.0/icons/whatsapp_vjsjhe.svg"
                                                    alt="whatsapp logo"
                                                    className="w-6 h-6"
                                                />
                                            ),
                                        },
                                        {
                                            label: "Gmail",
                                            value: "google",
                                            icon: (
                                                <img
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700554454/Tradeally_2.0/icons/google_wtrcmu.svg"
                                                    alt="google logo"
                                                    className="w-6 h-6"
                                                />
                                            ),
                                        },
                                        {
                                            label: "Outlook",
                                            value: "microsoft",
                                            icon: (
                                                <img
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700839953/Tradeally_2.0/icons/microsoft_logo_cwqmmo.svg"
                                                    alt="microsoft logo"
                                                    className="w-6 h-6"
                                                />
                                            ),
                                        },
                                    ]}
                                />
                            </div>
                            <div className="px-10 ">
                                <div className="relative ">
                                    <div className="flex flex-col items-center cursor-pointer justify-center mb-4 h-[117px] py-2 px-6 text-center text-sm rounded border border-dashed border-slate-200">
                                        <i className="ri-upload-cloud-2-line text-slate-500 text-4xl mb-4"></i>
                                        <p className="mb-1 font-rocGroteskMedium">
                                            <span>Click to upload from your device</span>
                                        </p>
                                        <p className="text-tradeally-neutral-200  font-rocGroteskMedium">
                                            .pdf, .csv, .docx & .txt | file size limit: 25MB
                                        </p>
                                    </div>
                                    <input
                                        type="file"
                                        accept=".pdf,.doc,.docx,.txt,.csv"
                                        className="absolute top-0 w-full outline-none h-full opacity-0 cursor-pointer"
                                        id="uploadDoc"
                                        onChange={(evt) => {
                                            if (
                                                !isFileSizeLessThanOrEqualTo(
                                                    evt.target.files?.[0] as File,
                                                    25
                                                )
                                            ) {
                                                toast.custom((t) => (
                                                    <CustomToast
                                                        t={t}
                                                        message={"File to large"}
                                                        type={"error"}
                                                    />
                                                ));
                                                return;
                                            }

                                            if (evt.target.files?.[0]) {
                                                setDocData(evt.target.files?.[0]);
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            {docData && (
                                <div className="my-4 p-4 rounded border border-slate-200 mx-10 flex items-center gap-4">
                                    {displayFileIcon(
                                        docData?.type?.split("/")?.slice(-1)?.[0],
                                        undefined
                                    )}
                                    <div className="w-full flex justify-between items-start">
                                        <div>
                                            <p className="text-sm font-rocGroteskMedium ">
                                                {truncate(docData?.name, { length: 34 })}
                                            </p>
                                            <p className="text-xs font-rocGroteskMedium text-tradeally-neutral-200">
                                                {convertBytes(docData?.size)}
                                            </p>
                                        </div>
                                        <i
                                            onClick={() => {
                                                const loading = uploading || creatingWorkFlow;
                                                if (!loading) {
                                                    setDocData(null);
                                                }
                                            }}
                                            // disabled={uploading || creatingWorkFlow}
                                            className="ri-delete-bin-line text-slate-500 cursor-pointer"
                                        ></i>
                                    </div>
                                </div>
                            )}
                            <div className="px-10">
                                <Button
                                    btnText={"Generate workflow"}
                                    type={"submit"}
                                    btnClassname={" mb-3"}
                                    // onClick={() => handleUpload(docData?.name, docData)}
                                    disabled={!Boolean(docData)}
                                    isLoading={uploading || creatingWorkFlow}
                                />
                                <Button
                                    btnText={"Close"}
                                    type={"button"}
                                    btnClassname={"!bg-n-20 !text-gm-50"}
                                    onClick={() => {
                                        setDocData(null);
                                        setSelectedOption("");
                                        setSelectedOptionModal(false);
                                        setManualCreation({
                                            workflowName: "",
                                            event: "",
                                            sources: [],
                                        });
                                        setWorkFlowName("");
                                        setWorkFlowCategory("");
                                    }}
                                />
                            </div>
                        </form>
                    </div>
                </ModalContainer>

                <ModalContainer
                    open={customEventModal}
                    showCloseIcon={false}
                    closeModal={() => setCustomEventModal(false)}
                    tailwindClassName="w-[34.5%] max-lg:w-1/2 max-sm:w-[95%]"
                >
                    <div className="bg-white flex flex-col rounded-[8px] p-8 relative">
                        <div
                            onClick={() => setCustomEventModal(false)}
                            className="cursor-pointer flex justify-end absolute top-[30px] right-[32px]"
                        >
                            <i className="ri-close-fill text-gm-45 text-[20px]"></i>
                        </div>
                        <div className=" ">
                            <div className="mb-10">
                                <h6 className="text-center font-rocGroteskMedium text-2xl mb-10">
                                    Add a custom event
                                </h6>
                                <div>
                                    <TextInput
                                        value={customEvent}
                                        name={"customEvent"}
                                        type={"text"}
                                        required={true}
                                        onChange={(e) => {
                                            setCustomEvent(e?.target?.value);
                                        }}
                                        placeholder={"Enter a custom event"}
                                        containerClassname="w-full "
                                        inputContainerClassname={""}
                                        inputClassName={`w-full`}
                                        showInputComment={true}
                                        inputComment={
                                            "Type the specific event that you want to trigger a set of actions after it happens"
                                        }
                                    />
                                </div>
                            </div>
                            <div className="flex items-center gap-6">
                                <Button
                                    btnText={"Cancel"}
                                    type={"button"}
                                    btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                    onClick={() => {
                                        setCustomEventModal(false);
                                    }}
                                />
                                <Button
                                    btnText={"Save event"}
                                    type={"button"}
                                    btnClassname="!py-3 !px-4"
                                    onClick={() => {
                                        const data = customEvent?.replace(
                                            /\s+([a-zA-Z])/g,
                                            function (match, $1) {
                                                return $1.toUpperCase();
                                            }
                                        );
                                        const valueToSave =
                                            data?.[0]?.toLowerCase() + data?.slice(1);
                                        const eventToSave = customEvent?.replace(
                                            /\b(\w)/g,
                                            function (match) {
                                                return match.toUpperCase();
                                            }
                                        );

                                        setEvents((prev) => [
                                            {
                                                event: eventToSave,
                                                value: valueToSave,
                                                eventType: [workflowCat],
                                            },
                                            ...prev,
                                        ]);
                                        // setEventValue(valueToSave);
                                        handleManualFormChange("event", valueToSave);
                                        setCustomEvent("");
                                        toast.custom((t) => (
                                            <CustomToast
                                                t={t}
                                                message={"Event added to option list"}
                                                type={"success"}
                                            />
                                        ));
                                        // setNewEvent((prev) => ({
                                        //     ...prev,
                                        //     eventName: valueToSave + "-" + workflowCat,
                                        // }));
                                        setCustomEventModal(false);
                                    }}
                                    // disabled={deletingAction}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            </div>
        </PageFrame>
    );
};

export default WorkFlow;
