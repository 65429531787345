/* eslint-disable */
import ReactDOM from "react-dom";
import * as echarts from "echarts";
import ReactEcharts from "echarts-for-react";
import BreadCrumb from "components/common/BreadCrumb";
import PageFrame from "components/layout/PageFrame";
import React, { useEffect, useRef, useState } from "react";
import { truncate } from "lodash";
import Dropdown from "components/common/Dropdown";
import Button from "components/common/Button";
import { periodStartDateAndEndDate } from "helpers/periodStartDateAndEndDate";
import { businessAnalyticsActions } from "redux/Ldb/actions/businessAnalyticsActions";
import { vendorActions } from "redux/Ldb/actions";
import Loader from "components/common/Loader";
import { PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";
import { authActions } from "redux/Ldb/actions";
import * as htmlToImage from "html-to-image";
import SpendAnalysisPdf from "components/views/Ldb/Dashboard/BusinessAnalytics/SpendAnalysisPdf";
import CustomCalendar from "components/common/CalendarComp.js/Calendar";
import moment from "moment";
import pptxgen from "pptxgenjs";
import { convertPDFtoImages } from "helpers/convertPDFBlobToImages";
import { formatDateRange } from "helpers/formatDateRange";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";

const SpendAnalysis = () => {
    const dispatch = useAppDispatch();
    const chartRef = useRef(null);
    const dropDownRef = useRef(null);
    const customRef = useRef(null);
    const [profile, setProfile] = useState<any>({});
    const [chartImage, setChartImage] = useState("");
    const [shareModal, setShareModal] = useState<Boolean>(false);
    const [durationFilter, setDurationFilter] = useState("1 year");
    const [noData, setNoData] = useState(false);
    const [spendAnalytics, setSpendAnalytics] = useState<any>();
    const [category, setCategory] = useState<string>("");
    const [vendorCategory, setVendorCategory] = useState<string>();
    const [year, setYear] = useState<number | Date>();
    const [openSupplierCat, setOpenSupplierCat] = useState<Boolean>(false);
    const [openSupplier, setOpenSupplier] = useState<Boolean>(false);
    const [showCatDropDown, setShowCatDropDown] = useState(false);
    const [vendorByCategory, setVendorByCategory] = useState<any>();
    const [showCustomDate, setShowCustomDate] = useState<Boolean>(false);
    const [customValue, customOnChange] = useState(new Date());
    const [generatedBlob, setGeneratedBlob] = useState(null);
    const [imageUrls, setImageUrls] = useState<string[]>();
    const [supplier, setSupplier] = useState<string>();
    const [supplierByCategory, setSupplierByCategory] = useState<string>("");
    const [categoryAndSupplier, setCategoryAndSupplier] = useState<any>();
    const [dateRange, setDateRange] = useState<string>("");
    const [showDateRange, setShowDateRange] = useState<boolean>(false);
    const [startAndEndDate, setStartAndEndDate] = useState<any>({});
    const [insights, setInsights] = useState<string[]>();

    const [vendorId, setVendorId] = useState<string>("");

    const values = ["1 year", "6 months", "1 month", "1 week", "custom"];
    const { fetchVendorByCategorySuccess } = useAppSelector((state) => state.vendor);

    const {
        gettingSpendAnalytics,
        getSpendAnalyticsSuccess,
        fetchedSpendAnalysisCatAndSupSuccess,
    } = useAppSelector((state) => state.businessAnalytics);
    const { fetchUserProfileSuccess } = useAppSelector((state) => state.auth);

    useEffect(() => {
        const copyData = spendAnalytics?.insights;
        const formattedData = copyData?.replace(/\*\*/g, "")?.replace(/\n\n/g, "\n")?.split("\n");
        setInsights(formattedData);
    }, [spendAnalytics]);
    useEffect(() => {
        if (Boolean(fetchVendorByCategorySuccess)) {
            setVendorByCategory(fetchVendorByCategorySuccess?.vendors);
        }
    }, [fetchVendorByCategorySuccess]);

    useEffect(() => {
        if (category) {
        }
    }, [dispatch, category]);

    useEffect(() => {
        if (Boolean(fetchUserProfileSuccess)) {
            setProfile(fetchUserProfileSuccess);
        }
    }, [fetchUserProfileSuccess]);

    useEffect(() => {
        if (!Boolean(fetchUserProfileSuccess)) {
            dispatch(authActions.getLdbProfile());
        }
    }, [dispatch, fetchUserProfileSuccess]);

    const handleChangeValue = (e) => {
        const { value } = e.target;
        setDurationFilter(value);
        if (value === "custom") {
            setShowCustomDate(true);
        }
        if (value !== "custom") {
            customOnChange(new Date());
        }
    };
    const handleCategoryChange = (e) => {
        setVendorId(e.target.value);
        setVendorCategory("");
    };

    useEffect(() => {
        if (Boolean(getSpendAnalyticsSuccess)) {
            setSpendAnalytics(getSpendAnalyticsSuccess);
        }
        if (Boolean(fetchedSpendAnalysisCatAndSupSuccess)) {
            setCategoryAndSupplier(fetchedSpendAnalysisCatAndSupSuccess);
        }
    }, [getSpendAnalyticsSuccess, fetchedSpendAnalysisCatAndSupSuccess]);

    useEffect(() => {
        let period: any, numberOfMonths: number;
        setChartImage("");

        if (durationFilter !== "custom" || customValue[0] === customValue[1]) {
            if (durationFilter === "1 week") {
                period = "week";
            } else if (durationFilter === "6 months") {
                period = "month";
                numberOfMonths = 6;
            } else if (durationFilter === "1 month") {
                period = "month";
            } else if (durationFilter === "1 year") {
                period = "year";
                numberOfMonths = 12;
            } else {
                return period;
            }

            const value = periodStartDateAndEndDate(period, numberOfMonths);

            setStartAndEndDate({
                startDate: value.startDate,
                endDate: value.endDate,
            });
            setShowDateRange(false);
        }
        if (customValue[0] !== customValue[1]) {
            setStartAndEndDate({
                startDate: moment(customValue[0]).format("YYYY-MM-DD"),
                endDate: moment(customValue[1]).format("YYYY-MM-DD"),
            });
            setShowDateRange(true);
        }
    }, [customValue, durationFilter]);

    useEffect(() => {
        if (startAndEndDate?.startDate && startAndEndDate?.endDate) {
            dispatch(
                businessAnalyticsActions.spendAnalytics(
                    startAndEndDate?.startDate,
                    startAndEndDate?.endDate,
                    vendorId,
                    vendorCategory
                )
            );
            if (!Boolean(vendorId)) {
                dispatch(
                    businessAnalyticsActions.spendAnalyticsCatAndSup(
                        startAndEndDate?.startDate,
                        startAndEndDate?.endDate
                    )
                );
            }

            if (Boolean(category) && !Boolean(vendorId)) {
                dispatch(
                    vendorActions.getVendorByCategory(
                        category,
                        startAndEndDate?.startDate,
                        startAndEndDate?.endDate
                    )
                );
            }
        }
        const PoYear = new Date(startAndEndDate?.endDate).getFullYear();
        setYear(PoYear);
        const formattedRange = formatDateRange(
            startAndEndDate?.startDate,
            startAndEndDate?.endDate,
            "short"
        );
        setDateRange(formattedRange);
    }, [startAndEndDate, vendorId, category]);

    const colors = [
        "#CCF4FE",
        "#002A61",
        "#5452BF",
        "#0F846D",
        "#9896EE",
        "#FFAB00",
        "#FF7E68",
        "#E0E0FA",
        "#B1ECE1",
        "#CCDDF3",
    ];
    const pieData = spendAnalytics?.spend_analysis?.map((item, index) => {
        return {
            value: Math.round(item?.totalAmountSpent),
            name: vendorId && category ? category : item?.category,
            vendor: item?.numberOfVendors,
            color: colors[index],
        };
    });

    const option = {
        color: colors,
        tooltip: {
            trigger: "item",
            borderWidth: "4",
            borderColor: "#142837",
            padding: 0,
            axisPointer: {
                show: false,
                type: "none",
            },
            formatter: function (params) {
                const dataItem = pieData.find((item) => item.name === params.name);
                return `<div style="border: 4px solid #142837; padding: 4px 8px;  color: #fff !important;
                            font-size: 13px; font-family: 'rocGrotesk-regular'; position: relative;  background-color: #142837;">
                             Category: <span style="font-weight: 700">${params.name} </span> <br/>
                            Total Amount Spent:  <span style="font-weight: 700"> ${params.value.toLocaleString()} </span>
                            <br/>
                            Suppliers:  <span style="font-weight: 700"> ${
                                dataItem.vendor
                            } supplier(s) </span>

                             <i class="ri-arrow-down-s-fill text-[#142837]" style="position: absolute;
              z-index: 2; font-size: 20px !important; bottom: -20px; left: 40%;"></i>
                            </div>`;
            },
        },
        series: [
            {
                type: "pie",
                z: 1,
                radius: [15, 125],
                center: ["50%", "50%"],
                roseType: "radius",

                label: {
                    show: false,
                },
                emphasis: {
                    label: {
                        show: false,
                    },
                },
                data: pieData,
            },
        ],
    };

    const captureChart = async () => {
        if (!chartRef.current) {
            return;
        }
        if (!chartImage) {
            const chartElement = chartRef.current;
            const img = await htmlToImage?.toPng(chartElement);
            setChartImage(img);
        }
    };
    useEffect(() => {
        if (category && category === "") {
            setShowCatDropDown(false);
        }
    }, [category]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
                setOpenSupplierCat(false);
                setOpenSupplier(false);
            }
            if (customRef.current && !customRef.current.contains(event.target)) {
                setShowCustomDate(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropDownRef, openSupplierCat, openSupplierCat, customRef]);

    const createPPT = (imageUrls) => {
        const pres = new pptxgen();
        let slides = [];

        imageUrls?.forEach((imageUrl, index) => {
            slides[index + 1] = pres.addSlide();
            slides[index + 1].addImage({
                data: imageUrl,
                x: 0,
                y: 0,
                w: "100%",
                h: "100%",
            });
        });

        pres.writeFile({ fileName: "spend_analysis.pptx" });
    };

    useEffect(() => {
        if (imageUrls) createPPT(imageUrls);
    }, [imageUrls]);

    const getBlobImages = async (blob) => {
        const blobImages = await convertPDFtoImages(blob);
        setImageUrls(blobImages);
    };
    return (
        <PageFrame containerClassName={"h-[calc(100vh-84px)] "}>
            {/* {shareModal && (
                <ShareModal openModal={shareModal} closeModal={() => setShareModal(false)} />
            )} */}

            <div className="px-[14px]">
                <div className="mb-[33px] flex item-center justify-between">
                    {noData ? (
                        <BreadCrumb
                            parentCrumb="Business analytics"
                            size="large"
                            parentClassName="!text-slate-900 !text-xl "
                        />
                    ) : (
                        <BreadCrumb
                            parentCrumb="Business analytics"
                            size="large"
                            parentClassName="!text-slate-400 !text-xl "
                            childrenCrumb={[
                                {
                                    title: "Spend Analysis",
                                    path: "/dashboard/analytics/purchase-orders",
                                },
                            ]}
                            childClassName="!text-lg !text-slate-900"
                        />
                    )}
                </div>

                {noData ? (
                    <div className="flex items-center justify-center gap-10 mt-[150px]">
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1701768395/analytics_fnnp21.svg"
                            alt="graph"
                        />
                        <div className="max-w-[451px]">
                            <h5 className="text-xl font-rocGroteskMedium text-slate-800 mb-[14px]">
                                Business analytics
                            </h5>
                            <p className="text-slate-500 text-sm leading-6">
                                Through the business analytics page, you are able to gain quality
                                insight into how well your business operations is performing. Here,
                                TradeAlly aggregates your business performance into
                                easy-to-understand data visualization. You can also export these
                                reports for your presentations whenever you need them. The data
                                would show up here when you start transacting with your suppliers.
                            </p>
                        </div>
                    </div>
                ) : (
                    <div>
                        <p className="text-slate-900  font-rocGroteskMedium text-lg mb-4">
                            Supplier spend history
                        </p>

                        <div className="w-full flex space-x-4">
                            <div className="w-[65%] bg-white shadow-faintShadow  rounded-[12px]">
                                <div className="flex items-center justify-between m-6">
                                    <div className="flex gap-4 items-center">
                                        <div className="relative" ref={dropDownRef}>
                                            <Dropdown
                                                leaveOpen={true}
                                                dropdown={
                                                    <div className="flex items-center rounded-[5px] shadow-boxShadow-2 p-[15px] gap-2">
                                                        <p
                                                            className={`text-slate-800 font-rocGroteskMedium text-sm`}
                                                        >
                                                            {supplier
                                                                ? truncate(supplier, { length: 17 })
                                                                : "By supplier category"}
                                                        </p>
                                                        <i className="ri-arrow-down-s-line text-base text-slate-800"></i>
                                                    </div>
                                                }
                                                disabled={gettingSpendAnalytics}
                                                dropdownItemsClasses="!px-0 !py-0"
                                                dropdownClassName="!w-[203px] !mb-10 !-mt-7"
                                                dropdownContainerClasses="shadow-cardShadow-4 border border-slate-100  rounded !max-h-[448px]"
                                                name=""
                                                dropdownOptions={[
                                                    {
                                                        label: (
                                                            <div
                                                                className=" text-gm-50 font-rocGroteskMedium text-[13px] flex
                                                border-b border-b-slate-100 !w-[203px] px-[10px] py-[10px] justify-between"
                                                            >
                                                                By supplier Category
                                                                <i className="ri-arrow-right-s-line text-gm-50 text-sm"></i>
                                                            </div>
                                                        ),
                                                        value: "",
                                                        action: () => {
                                                            setOpenSupplierCat(true);
                                                            setOpenSupplier(false);
                                                        },
                                                    },
                                                    {
                                                        label: (
                                                            <div
                                                                className=" text-gm-50 font-rocGroteskMedium text-[13px] flex
                                                border-b border-b-slate-100 !w-[203px] px-[10px] py-[10px] justify-between"
                                                            >
                                                                By supplier
                                                                <i className="ri-arrow-right-s-line text-gm-50 text-sm"></i>
                                                            </div>
                                                        ),
                                                        value: "",
                                                        action: () => {
                                                            setOpenSupplierCat(false);
                                                            setOpenSupplier(true);
                                                        },
                                                    },
                                                ]}
                                            />

                                            {openSupplierCat && (
                                                <div
                                                    className="absolute z-[2] bg-[#fff] left-[210px] top-[70px] rounded border border-slate-100 shadow-cardShadow-4 
                                                max-h-[210px] overflow-scroll"
                                                >
                                                    {categoryAndSupplier?.category?.length > 0 ? (
                                                        categoryAndSupplier?.category?.map(
                                                            (item, idx) => {
                                                                return (
                                                                    <div
                                                                        key={idx}
                                                                        className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between items-center
                                                border-b border-b-slate-100 !w-[311px] px-4 py-[10px] cursor-pointer "
                                                                        onClick={() => {
                                                                            setOpenSupplierCat(
                                                                                false
                                                                            );
                                                                            setSupplierByCategory(
                                                                                ""
                                                                            );
                                                                            setVendorId("");

                                                                            if (
                                                                                item?.category ===
                                                                                category
                                                                            ) {
                                                                                setCategory("");
                                                                                setShowCatDropDown(
                                                                                    false
                                                                                );
                                                                                setSupplier("");
                                                                                setVendorCategory(
                                                                                    ""
                                                                                );
                                                                            } else {
                                                                                setCategory(
                                                                                    item?.category
                                                                                );
                                                                                setVendorCategory(
                                                                                    item?.category
                                                                                );
                                                                                setShowCatDropDown(
                                                                                    true
                                                                                );
                                                                                setSupplier(
                                                                                    item?.category
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        {item?.category}
                                                                        {category ===
                                                                        item?.category ? (
                                                                            <>
                                                                                <span className="material-icons text-xs text-slate-300">
                                                                                    check_box
                                                                                </span>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <span className="material-icons text-xs text-slate-300">
                                                                                    check_box_outline_blank
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                );
                                                            }
                                                        )
                                                    ) : (
                                                        <div
                                                            className=" text-gm-50
                                                            font-rocGroteskMedium text-xs flex
                                                            justify-between items-center border-b
                                                            border-b-slate-100 !w-[150px] px-4
                                                            py-[10px] "
                                                            onClick={() => {
                                                                setOpenSupplierCat(false);
                                                            }}
                                                        >
                                                            No Data
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            {openSupplier && (
                                                <div className="absolute z-[2] bg-[#fff] left-[210px] top-[110px]  border border-slate-100 shadow-cardShadow-4 rounded overflow-scroll max-h-[150px]">
                                                    {categoryAndSupplier?.vendors?.length > 0 ? (
                                                        categoryAndSupplier?.vendors?.map(
                                                            (item) => {
                                                                return (
                                                                    <div
                                                                        key={item?._id}
                                                                        className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between items-center
                                                border-b border-b-slate-100 !w-[311px] px-4 py-[10px] cursor-pointer"
                                                                        onClick={() => {
                                                                            setOpenSupplier(
                                                                                !openSupplier
                                                                            );
                                                                            setSupplierByCategory(
                                                                                ""
                                                                            );

                                                                            if (
                                                                                item?.vendorId ===
                                                                                vendorId
                                                                            ) {
                                                                                setVendorId("");
                                                                                setSupplier("");
                                                                            } else {
                                                                                setVendorId(
                                                                                    item?.vendorId
                                                                                );
                                                                                setCategory("");
                                                                                setVendorCategory(
                                                                                    ""
                                                                                );
                                                                                setShowCatDropDown(
                                                                                    false
                                                                                );
                                                                                setSupplier(
                                                                                    item?.vendorName ||
                                                                                        item?.vendorEmail
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        {item?.vendorName ||
                                                                            item?.vendorEmail}
                                                                        {vendorId ===
                                                                        item?.vendorId ? (
                                                                            <>
                                                                                <span className="material-icons text-xs text-slate-300">
                                                                                    check_box
                                                                                </span>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <span className="material-icons text-xs text-slate-300">
                                                                                    check_box_outline_blank
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                );
                                                            }
                                                        )
                                                    ) : (
                                                        <div
                                                            className=" text-gm-50
                                                            font-rocGroteskMedium text-xs flex
                                                            justify-between items-center border-b
                                                            border-b-slate-100 !w-[150px] px-4
                                                            py-[10px] "
                                                            onClick={() => {
                                                                setOpenSupplierCat(false);
                                                            }}
                                                        >
                                                            No Data
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        {showCatDropDown && (
                                            <Dropdown
                                                handleChange={(name, value) =>
                                                    handleCategoryChange({
                                                        target: { name, value },
                                                    })
                                                }
                                                dropdown={
                                                    <div className="flex items-center rounded-[5px] shadow-boxShadow-2 p-[15px] gap-2">
                                                        <div
                                                            className={`text-slate-800 font-rocGroteskMedium text-sm`}
                                                        >
                                                            {supplierByCategory
                                                                ? truncate(supplierByCategory, {
                                                                      length: 12,
                                                                  })
                                                                : "Supplier"}
                                                        </div>
                                                        <i className="ri-arrow-down-s-line text-base text-slate-800"></i>
                                                    </div>
                                                }
                                                disabled={gettingSpendAnalytics}
                                                dropdownItemsClasses="!px-0 !py-0"
                                                dropdownClassName="!mb-10 !-mt-7"
                                                dropdownContainerClasses="shadow-cardShadow-4 border border-slate-100  rounded  !w-[311px] !max-h-[220px]"
                                                dropdownOptions={vendorByCategory?.map((item) => ({
                                                    label: (
                                                        <div
                                                            key={item?._id}
                                                            className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between items-center
                                                border-b border-b-slate-100 !w-[311px] px-[10px] py-[10px]"
                                                        >
                                                            {item?.vendorName || item?.vendorEmail}

                                                            {vendorId === item?._id ? (
                                                                <>
                                                                    <span className="material-icons text-xs text-slate-300">
                                                                        check_box
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span className="material-icons text-xs text-slate-300">
                                                                        check_box_outline_blank
                                                                    </span>
                                                                </>
                                                            )}
                                                        </div>
                                                    ),
                                                    value: item?._id,
                                                    action: () => {
                                                        setSupplierByCategory(
                                                            item?.vendorName || item?.vendorEmail
                                                        );
                                                    },
                                                }))}
                                            />
                                        )}
                                    </div>

                                    <div className="flex gap-[12px] items-center">
                                        <div className="relative">
                                            <Dropdown
                                                handleChange={(name, value) =>
                                                    handleChangeValue({ target: { name, value } })
                                                }
                                                dropdown={
                                                    <div className="flex items-center rounded-[5px] shadow-boxShadow-2 py-2 px-[15px] gap-2 ">
                                                        <i className="ri-filter-3-line text-lg text-[#142837]"></i>

                                                        <div
                                                            className={`text-slate-800 font-rocGroteskMedium text-[13px] inline-block`}
                                                        >
                                                            {Boolean(showDateRange)
                                                                ? dateRange
                                                                : durationFilter}
                                                        </div>
                                                    </div>
                                                }
                                                disabled={gettingSpendAnalytics}
                                                dropdownItemsClasses="!px-0 !py-0"
                                                dropdownClassName="!w-full !mb-10 !-mt-7"
                                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100  rounded !max-h-fit !w-auto
                            `}
                                                name="valueRange"
                                                dropdownOptions={[
                                                    ...values?.map((item, idx) => ({
                                                        label: (
                                                            <div
                                                                key={idx}
                                                                className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                                                border-b border-b-slate-100 !w-[159px] px-4 py-[10px]"
                                                            >
                                                                {item}

                                                                {idx === values?.length - 1 && (
                                                                    <i className="ri-arrow-right-s-line text-gm-50 text-xs"></i>
                                                                )}
                                                            </div>
                                                        ),
                                                        value: item,
                                                    })),
                                                ]}
                                            />
                                            {showCustomDate && (
                                                <div
                                                    className="w-[630px]  z-[10000] absolute  -right-80 mt-5 px-[28px] pt-8 pb-8 bg-white
                                                 rounded-2xl shadow-[0px_0px_4px_rgba(0,0,0,0.04),0px_4px_32px_rgba(0,0,0,0.06)]"
                                                    ref={customRef}
                                                >
                                                    <CustomCalendar
                                                        showDoubleView={true}
                                                        value={customValue}
                                                        onChange={customOnChange}
                                                    />
                                                    <div className="flex justify-end">
                                                        <Button
                                                            btnText={"Close"}
                                                            btnClassname={"!w-fit !py-2"}
                                                            onClick={() => setShowCustomDate(false)}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <Dropdown
                                            dropdown={
                                                <div
                                                    className="flex items-center rounded-[5px] shadow-boxShadow-2 py-2 px-[15px] gap-2 "
                                                    onClick={captureChart}
                                                >
                                                    <i className="ri-expand-right-line text-lg text-[#142837]"></i>
                                                    <div
                                                        className={`text-slate-800 font-rocGroteskMedium text-[13px]`}
                                                    >
                                                        Export
                                                    </div>
                                                </div>
                                            }
                                            disabled={
                                                gettingSpendAnalytics || pieData?.length === 0
                                            }
                                            dropdownItemsClasses={`!px-0 !py-0 !opacity-100`}
                                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                            dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100  rounded !max-h-fit 
                            `}
                                            dropdownOptions={[
                                                {
                                                    label: (
                                                        <>
                                                            {!chartImage ? (
                                                                <button
                                                                    className=" text-gm-50 font-rocGroteskMedium text-xs flex gap-3
                                                border-b border-b-slate-100 !w-[200px] px-[10px] py-[10px]"
                                                                    disabled
                                                                >
                                                                    <img
                                                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702458892/ppt_qm1phs.svg"
                                                                        alt="powerpoint icon"
                                                                        className="w-3"
                                                                    />
                                                                    Export as powerpoint
                                                                    <Loader size={3} />
                                                                </button>
                                                            ) : (
                                                                <BlobProvider
                                                                    document={
                                                                        <SpendAnalysisPdf
                                                                            chartName={
                                                                                "Supplier Spend"
                                                                            }
                                                                            title={"Spend History"}
                                                                            businessLogo={
                                                                                profile?.profile
                                                                                    ?.avatar
                                                                            }
                                                                            chart={chartImage}
                                                                            year={year}
                                                                            dateRange={dateRange}
                                                                            analysis={
                                                                                spendAnalytics
                                                                            }
                                                                            insights={insights}
                                                                            chartData={pieData}
                                                                            category={
                                                                                vendorId && category
                                                                                    ? `${category} category`
                                                                                    : vendorCategory
                                                                                    ? `${vendorCategory} category`
                                                                                    : "All Supplier Categories"
                                                                            }
                                                                            showCategory={
                                                                                vendorId && category
                                                                            }
                                                                        />
                                                                    }
                                                                >
                                                                    {({ blob, loading }) => {
                                                                        setGeneratedBlob(blob);
                                                                        return loading &&
                                                                            !generatedBlob ? (
                                                                            <button
                                                                                className=" text-gm-50 font-rocGroteskMedium text-xs flex gap-3
                                                border-b border-b-slate-100 !w-[200px] px-[10px] py-[10px]"
                                                                                disabled
                                                                            >
                                                                                <img
                                                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702458892/ppt_qm1phs.svg"
                                                                                    alt="powerpoint icon"
                                                                                    className="w-3"
                                                                                />
                                                                                Export as powerpoint{" "}
                                                                                <Loader size={3} />
                                                                            </button>
                                                                        ) : (
                                                                            <button
                                                                                onClick={() =>
                                                                                    getBlobImages(
                                                                                        generatedBlob
                                                                                    )
                                                                                }
                                                                                className=" text-gm-50 font-rocGroteskMedium text-xs flex gap-3
                                                border-b border-b-slate-100 !w-[200px] px-[10px] py-[10px]"
                                                                            >
                                                                                <img
                                                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702458892/ppt_qm1phs.svg"
                                                                                    alt="powerpoint icon"
                                                                                    className="w-3"
                                                                                />
                                                                                Export as powerpoint
                                                                            </button>
                                                                        );
                                                                    }}
                                                                </BlobProvider>
                                                            )}
                                                        </>
                                                    ),
                                                    value: "",
                                                },
                                                {
                                                    label: (
                                                        <>
                                                            {!chartImage ? (
                                                                <button
                                                                    className=" text-gm-50 font-rocGroteskMedium text-xs flex gap-3
                                                border-b border-b-slate-100 !w-[200px] px-[10px] py-[10px]"
                                                                    disabled
                                                                >
                                                                    <img
                                                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700580775/pdf-document_kqjkit.svg"
                                                                        alt="pdf icon"
                                                                        className="w-3"
                                                                    />
                                                                    Export as PDF
                                                                    <Loader size={3} />
                                                                </button>
                                                            ) : (
                                                                <PDFDownloadLink
                                                                    fileName={`spend_analysis.pdf`}
                                                                    document={
                                                                        <SpendAnalysisPdf
                                                                            chartName={
                                                                                "Supplier Spend"
                                                                            }
                                                                            title={"Spend History"}
                                                                            businessLogo={
                                                                                profile?.profile
                                                                                    ?.avatar
                                                                            }
                                                                            chart={
                                                                                chartImage || null
                                                                            }
                                                                            year={year}
                                                                            dateRange={dateRange}
                                                                            analysis={
                                                                                spendAnalytics
                                                                            }
                                                                            insights={insights}
                                                                            chartData={pieData}
                                                                            category={
                                                                                vendorId && category
                                                                                    ? `${category} category`
                                                                                    : vendorCategory
                                                                                    ? `${vendorCategory} category`
                                                                                    : "All Supplier Categories"
                                                                            }
                                                                            showCategory={
                                                                                vendorId && category
                                                                            }
                                                                        />
                                                                    }
                                                                >
                                                                    {({ loading }) => (
                                                                        <>
                                                                            {loading ? (
                                                                                <button
                                                                                    className=" text-gm-50 font-rocGroteskMedium text-xs flex gap-3
                                                border-b border-b-slate-100 !w-[200px] px-[10px] py-[10px]"
                                                                                    disabled
                                                                                >
                                                                                    <img
                                                                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700580775/pdf-document_kqjkit.svg"
                                                                                        alt="pdf icon"
                                                                                        className="w-3"
                                                                                    />
                                                                                    Export as PDF
                                                                                    <Loader
                                                                                        size={3}
                                                                                    />
                                                                                </button>
                                                                            ) : (
                                                                                <button
                                                                                    className=" text-gm-50 font-rocGroteskMedium text-xs flex gap-3
                                                border-b border-b-slate-100 !w-[200px] px-[10px] py-[10px]"
                                                                                >
                                                                                    <img
                                                                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700580775/pdf-document_kqjkit.svg"
                                                                                        alt="pdf icon"
                                                                                        className="w-3"
                                                                                    />
                                                                                    Export as PDF
                                                                                </button>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </PDFDownloadLink>
                                                            )}
                                                        </>
                                                    ),
                                                    value: "",
                                                    disabled: true,
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>

                                <div className="h-full">
                                    {gettingSpendAnalytics ? (
                                        <div className="flex items-center justify-center h-[450px]">
                                            {" "}
                                            <Loader size={4} />
                                        </div>
                                    ) : pieData && pieData?.length > 0 ? (
                                        <div className="flex w-full items-center border-b border-b-[#E2E8F0] mt-20 pb-20 font-rocGrotesk pr-[30px]">
                                            <div
                                                className=" w-[60%] !font-rocGrotesk"
                                                ref={chartRef}
                                            >
                                                <ReactEcharts option={option} />
                                            </div>
                                            <div className="flex flex-col gap-[10px]">
                                                {pieData?.map((item, idx) => {
                                                    return (
                                                        <div
                                                            className="flex gap-[10px] items-center"
                                                            key={idx}
                                                        >
                                                            <div
                                                                className="w-3 h-3 rounded-[3px]"
                                                                style={{
                                                                    backgroundColor: item.color,
                                                                }}
                                                            ></div>
                                                            <p className="text-slate-500 text-[13px] font-rocGroteskMedium">
                                                                {item?.name}
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ) : (
                                        !gettingSpendAnalytics &&
                                        pieData?.length === 0 && (
                                            <div
                                                className="w-full h-[450px] flex justify-center items-center flex-col"
                                                ref={chartRef}
                                            >
                                                <img
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1701863824/graph_s30b7n.svg"
                                                    alt="no data"
                                                />
                                                <p className="text-gm-40 text-xs font-rocGroteskMedium mt-[19px]">
                                                    No data to show yet{" "}
                                                </p>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                            <div className="w-[35%] bg-white shadow-faintShadow  rounded-[12px] p-6">
                                <div className="flex mt-2 items-center pb-3 border-b justify-between ">
                                    <div className="flex items-center space-x-1">
                                        <div className="">
                                            <img
                                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1701795190/Group_55864_mbxosg.svg"
                                                alt="cycle-icon"
                                            />
                                        </div>
                                        <p className="font-rocGroteskBold text-slate-800 text-base">
                                            Supplier spend insights
                                        </p>
                                    </div>
                                </div>

                                {gettingSpendAnalytics ? (
                                    <div className="flex items-center justify-center h-[450px]">
                                        {" "}
                                        <Loader size={4} />
                                    </div>
                                ) : spendAnalytics?.spend_analysis?.length > 0 ||
                                  spendAnalytics?.top_vendor?.totalOrders ||
                                  insights?.length > 0 ? (
                                    <>
                                        <div className="mt-5">
                                            {spendAnalytics?.spend_analysis?.length > 0 && (
                                                <div className="mb-3.5">
                                                    <p className="text-slate-400 text-xs font-rocGroteskMedium mb-1.5">
                                                        Top supplier category
                                                    </p>
                                                    <p className="text-slate-600 text-sm font-rocGroteskMedium">
                                                        {vendorId && category
                                                            ? category
                                                            : spendAnalytics?.spend_analysis?.[0]
                                                                  ?.category}
                                                    </p>
                                                    <div className="flex items-center mt-1 gap-[10px] ">
                                                        <span className="w-[220px] h-[7px] bg-[#16c6a4] rounded "></span>
                                                        <p className="text-xs text-slate-400">
                                                            {`${
                                                                spendAnalytics?.spend_analysis?.[0]
                                                                    ?.numberOfVendors || 0
                                                            } supplier(s)`}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                            {spendAnalytics?.top_vendor?.totalOrders && (
                                                <div className="">
                                                    <p className="text-slate-400 text-xs font-rocGroteskMedium mb-1.5">
                                                        Top Supplier
                                                    </p>
                                                    <p className="text-slate-600 text-sm font-rocGroteskMedium">
                                                        {spendAnalytics?.top_vendor?.vendorName}
                                                    </p>
                                                    <div className="flex items-center mt-1 gap-[10px] ">
                                                        <span className="w-[109px] h-[7px] bg-[#16c6a4] rounded ">
                                                            {" "}
                                                        </span>
                                                        <p className="text-xs text-slate-400">
                                                            {`${
                                                                spendAnalytics?.top_vendor
                                                                    ?.totalOrders || 0
                                                            } purchased`}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="mt-6">
                                            {insights?.length > 0 && (
                                                <>
                                                    <div className="flex items-center space-x-2 pb-2 border-b-2 border-slate-200 w-fit">
                                                        <div className="bg-p-25 p-1 rounded-full h-[20px] w-[20px] flex justify-center items-center">
                                                            <i className="ri-lightbulb-flash-fill text-p-50 text-xs "></i>
                                                        </div>
                                                        <div>
                                                            <p className="text-slate-600 text-sm font-rocGroteskMedium">
                                                                Insight summary
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="mt-3">
                                                        <p className="text-slate-500 font-rocGroteskMedium text-xs ">
                                                            {insights?.map((item, idx) => {
                                                                return <p key={idx}> {item}</p>;
                                                            })}
                                                        </p>
                                                    </div>
                                                </>
                                            )}
                                            <div className="w-fit mt-6">
                                                {!insights ? (
                                                    <Button
                                                        btnClassname={"!bg-p-25 !text-p-70 "}
                                                        btnText={"Download full report"}
                                                        icon={
                                                            <i className="ri-download-cloud-2-line"></i>
                                                        }
                                                        disabled={true}
                                                    />
                                                ) : (
                                                    <PDFDownloadLink
                                                        fileName={`spend_insights.pdf`}
                                                        document={
                                                            <SpendAnalysisPdf
                                                                chartName={"Supplier Spend"}
                                                                title={"Spend History"}
                                                                businessLogo={
                                                                    profile?.profile?.avatar
                                                                }
                                                                year={year}
                                                                dateRange={dateRange}
                                                                chart={null}
                                                                analysis={spendAnalytics}
                                                                chartData={pieData}
                                                                category={null}
                                                                insights={insights}
                                                                showCategory={
                                                                    vendorId && category
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        }
                                                    >
                                                        <Button
                                                            btnClassname={"!bg-p-25 !text-p-70 "}
                                                            btnText={"Download full report"}
                                                            icon={
                                                                <i className="ri-download-cloud-2-line"></i>
                                                            }
                                                        />
                                                    </PDFDownloadLink>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="h-[450px] flex items-center justify-center flex-col">
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1702157493/no_report_pf7dd2.svg"
                                            alt="no data"
                                        />
                                        <p className="text-gm-40 text-xs font-rocGroteskMedium mt-[22px]">
                                            No report generated yet
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </PageFrame>
    );
};

export default SpendAnalysis;
