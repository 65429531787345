import React, { useState, useRef, useEffect } from "react";

import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import TabsContainer from "components/common/TabsContainer";
import moment from "moment/moment";
import { authActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks";
import { firebaseService } from "services/firebaseService";
import { taskActions } from "redux/Ldb/actions";
import { useNavigate } from "react-router-dom";
import VendorAvatar from "components/common/VendorAvatar";
import Loader from "components/common/Loader";
import { truncate } from "lodash";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";

type NewTasksModalProps = {
    openModal: boolean;
    closeModal: () => void;
};

const NewTasksModal = ({ openModal, closeModal }: NewTasksModalProps) => {
    const inputRef = useRef(null);
    const timelineRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState(0);
    const tabs = ["Details"];
    const [open, setOpen] = useState<boolean>(false);
    const [profile, setProfile] = useState<{ [key: string]: any }>({});
    const [teamMembers, setTeamMembers] = useState<{ [key: string]: any }[]>([]);
    const [timeline, setTimeLine] = useState<{ [key: string]: any }>({});
    const [comment, setComment] = useState<{ [key: string]: any }>({});
    const [searchString, setSearchString] = useState<string>("");
    const [duration, setDuration] = useState<string>("");
    const [selectedTeamMember, setSelectedTeamMember] = useState<
        { [key: string]: any } | null | undefined
    >(null);
    const [uploadingPdf, setUploadingPdf] = useState<boolean>(false);
    const [selectedAssignee, setSelectedAssignee] = useState<
        { [key: string]: any } | null | undefined
    >(null);
    const [isHovered, setIsHovered] = useState<string | boolean>("");
    const [documents, setDocuments] = useState([]);
    const [createTasks, setCreateTasks] = useState<{ [key: string]: any }>({
        priority: "medium",
        documents: [],
    });

    const { fetchUserProfileSuccess, fetchedTeamMemberSuccess } = useAppSelector(
        (state) => state.auth
    );
    const { createTaskSuccess, createTask } = useAppSelector((state) => state.task);

    useEffect(() => {
        if (!Boolean(fetchUserProfileSuccess)) {
            dispatch(authActions.getLdbProfile());
        }
        // if (!Boolean(fetchedTeamMemberSuccess)) {
        dispatch(authActions.getTeamMembers("", searchString));
        // }
    }, [dispatch, fetchUserProfileSuccess, searchString]);

    useEffect(() => {
        if (Boolean(fetchUserProfileSuccess)) {
            setProfile(fetchUserProfileSuccess);
        }
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamMembers(fetchedTeamMemberSuccess?.teamMembers);
        }
    }, [fetchUserProfileSuccess, fetchedTeamMemberSuccess]);

    useEffect(() => {
        if (timeline.duration && timeline.number) {
            const result = timeline.duration.replace(/\(s\)/g, "").toLowerCase();
            setCreateTasks({
                ...createTasks,
                duration: {
                    unit: `${result}s`,
                    value: timeline.number,
                },
            });

            setDuration(`${timeline.number} ${timeline.number > 1 ? `${result}s` : result}`);
        }
    }, [timeline]);

    const handleTimeline = (
        e: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } }
    ) => {
        const { name, value } = e.target;
        setTimeLine({
            ...timeline,
            [name]: value,
        });
    };

    useEffect(() => {
        if (timeline.duration && timeline.number) {
            setOpen(false);
        }
    }, [timeline]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } }
    ) => {
        const { name, value } = e.target;
        setCreateTasks({
            ...createTasks,
            [name]: value,
        });
        if (name === "priority") {
            setCreateTasks({
                ...createTasks,
                [name]: value.toLowerCase(),
            });
        }
        if (name === "lineManagerId") {
            const selectedMember = filteredData.find((member) => member._id === value);
            setSelectedTeamMember(selectedMember);
        }
        if (name === "assigneeId") {
            const selectedMember = filteredData.find((member) => member._id === value);
            setSelectedAssignee(selectedMember);
        }
    };

    useEffect(() => {
        (inputRef?.current as any)?.focus();
    }, []);

    useEffect(() => {
        if (profile && comment.data) {
            setComment({
                ...comment,
                creatorId: profile?._id,
            });
        }
    }, [profile]);

    useEffect(() => {
        if (comment.data) {
            setCreateTasks({
                ...createTasks,
                comment,
            });
        }
    }, [comment]);

    const handleUpload = (fileName: string, file: File, idx?: number) => {
        if (fileName && file) {
            setUploadingPdf(true);
            firebaseService.uploadFile(
                `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/task/${
                    profile?.profile?.businessName
                }/createdByDocument/${fileName}`,
                file,
                (url) => {
                    if (idx !== undefined) {
                        const copyDoc = [...documents];
                        copyDoc[idx] = {
                            path: url,
                            name: fileName,
                        };
                        setDocuments(copyDoc);
                    } else {
                        setDocuments((prev) => [...prev, { path: url, name: fileName }]);
                    }

                    setUploadingPdf(false);
                }
            );
        }
    };

    useEffect(() => {
        setCreateTasks({
            ...createTasks,
            documents,
        });
    }, [documents]);
    const handleSearch = (
        event: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } }
    ) => {
        const search = event.target.value.toLowerCase();
        setSearchString(search);
    };

    const filteredData = searchString
        ? teamMembers?.filter((item) => item?.email?.toLowerCase()?.includes(searchString))
        : teamMembers;

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                timelineRef.current &&
                !timelineRef.current.contains(event.target as Node) &&
                open
            ) {
                setOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [timelineRef, open]);

    const validate = () => {
        let error;
        if (!createTasks.name) {
            error = "Task title is required";
        } else if (!createTasks.lineManagerId) {
            error = "Line Manager is required";
        } else if (!createTasks.assigneeId) {
            error = "Assignee is required";
        } else if (!duration) {
            error = "Timeline is required";
        }
        return error;
    };

    const handleSubmit = () => {
        const error = validate();
        if (error) {
            toast.custom((t) => <CustomToast t={t} message={error} type="error" />);
        } else {
            dispatch(taskActions.createTask(createTasks));
        }
    };
    useEffect(() => {
        if (Boolean(createTaskSuccess)) {
            closeModal();
            dispatch(taskActions.fetchTaskList(20));
            dispatch(taskActions.fetchPendingTaskList(20, "pending"));
            dispatch(taskActions.fetchOverdueTaskList(20, "delayed"));
            dispatch(taskActions.fetchCompletedTaskList(20, "completed"));
            dispatch(taskActions.fetchActiveTaskList(20, "on-track"));
            dispatch(taskActions.resetCreateTask());
        }
    }, [dispatch, createTaskSuccess]);

    const handleMouseEnter = (idx) => {
        setIsHovered(idx);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const deleteFile = (idx) => {
        const copyDoc = [...documents];
        copyDoc?.splice(idx, 1);
        setDocuments(copyDoc);
    };

    const handleComment = (event) => {
        const { name, value } = event.target;
        event.target.style.height = "auto";
        event.target.style.height = `${event.target.scrollHeight}px`;
        setComment({
            ...comment,
            [name]: value,
            creatorId: profile?._id,
        });
    };

    const onAddTeamMember = () => {};
    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <div className="flex gap-6 flex-col mb-[50px]">
                        <div
                            className="flex items-start justify-between border-b-[1px] border-b-[#F1F5F9] pt-5
                                    pb-[10px] "
                        >
                            <div className="flex gap-2 w-full">
                                <VendorAvatar
                                    size="24"
                                    containerClassname="rounded-full"
                                    name={profile?.fullName}
                                    imageSrc={profile?.profile?.avatar}
                                />

                                <textarea
                                    id="autoGrowingTextarea"
                                    value={comment?.data}
                                    name="data"
                                    onChange={handleComment}
                                    className="box-sizing w-[100%] resize-none overflow-hidden outline-none
                                    text-[15px] leading-5 font-rocGroteskMedium 
                                     placeholder:text-[#CBD5E1] !border-none caret-[#334155] !min-h-[15px]
                                "
                                    placeholder="Drop a comment"
                                />
                            </div>
                            <div className="flex items-center gap-2 text-[#94A3B8] ml-4">
                                <input
                                    id="file"
                                    type="file"
                                    className="hidden"
                                    accept=".pdf"
                                    onChange={(e) => {
                                        if (e.target.files?.[0]) {
                                            handleUpload(
                                                e.target.files?.[0]?.name as string,
                                                e.target.files?.[0] as File
                                            );
                                        }
                                    }}
                                ></input>

                                {uploadingPdf ? (
                                    <Loader color={"inherit"} size={4} />
                                ) : (
                                    <label htmlFor="file" className="cursor-pointer">
                                        <i className="ri-attachment-2 text-xl -mt-1"></i>
                                    </label>
                                )}
                            </div>
                        </div>
                        <div className="flex justify-between gap-2 flex-wrap w-[100%]">
                            {documents?.map((doc, idx) => {
                                return (
                                    <div
                                        className="border-slate-100 border rounded flex-1 py-5 px-4 flex justify-between items-center relative !min-w-[49%] max-w-[49%]"
                                        onMouseEnter={() => handleMouseEnter(idx)}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        {isHovered === String(idx) && (
                                            <button
                                                onClick={() => deleteFile(idx)}
                                                className="rounded-full  bg-n-30 w-[20px] h-[20px] flex justify-center items-center absolute -right-[6px] -top-[10px]"
                                            >
                                                <i className="ri-close-line text-sm text-slate-500 "></i>
                                            </button>
                                        )}
                                        <div className="flex gap-x-[11px]">
                                            <img
                                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700580775/pdf-document_kqjkit.svg"
                                                alt=""
                                            />

                                            <span className="text-[#04080B] font-rocGroteskMedium text-sm ">
                                                {truncate(doc?.name, { length: 25 })}
                                            </span>
                                        </div>
                                        <div className="flex gap-2 items-center">
                                            <div>
                                                <input
                                                    type="file"
                                                    id={`changeFile-${idx}`}
                                                    className="hidden"
                                                    onChange={(e) => {
                                                        if (e.target.files?.[0]) {
                                                            handleUpload(
                                                                e.target.files?.[0]?.name,
                                                                e.target.files?.[0],
                                                                idx
                                                            );
                                                        }
                                                    }}
                                                    disabled={uploadingPdf}
                                                />
                                                <label
                                                    htmlFor={`changeFile-${idx}`}
                                                    className=" underline text-p-55 right-4 top-4 !text-xs cursor-pointer font-rocGroteskMedium"
                                                >
                                                    Change File
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );

            default:
                return;
        }
    };

    return (
        <ModalContainer
            open={openModal}
            showCloseIcon={false}
            closeModal={closeModal}
            tailwindClassName="w-[90%] md:w-[650px]"
        >
            <div className="bg-white flex h-full  flex-col rounded-[12px] max-h-[90vh] overflow-y-scroll">
                <div className=" mt-5  mb-2 px-6 pt-2 flex gap-4 items-center ">
                    <i
                        onClick={closeModal}
                        className="ri-close-fill cursor-pointer text-gm-45 text-2xl border-[#E2E8F0] border-[1px] rounded-[4px]
                        px-1 "
                    ></i>
                    <p className="text-lg !leading-[20px] text-[#0F172A] font-rocGroteskMedium">
                        Add a new task
                    </p>
                </div>
                <div className="p-6 ">
                    <div className="border-b-[1px] border-b-[#E2E8F0] pb-5">
                        <input
                            type="text"
                            placeholder="Enter task title"
                            className="!text-2xl font-rocGroteskMedium  placeholder:text-[#CBD5E1] !border-none outline-none w-full placeholder:pl-1 caret-slate-700 !leading-6"
                            ref={inputRef}
                            name="name"
                            value={createTasks?.name || ""}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="mt-[30px] mb-7 flex gap-[14px] flex-col">
                        <div className="flex items-center  text-sm">
                            <p className="text-[#64748B] font-rocGroteskMedium w-[166px]">
                                Reporter
                            </p>
                            {Boolean(profile) && (
                                <div className="flex items-center gap-2">
                                    <VendorAvatar
                                        size="24"
                                        containerClassname="rounded-full"
                                        name={profile?.fullName || profile?.email}
                                        imageSrc={profile?.profile?.avatar}
                                    />
                                    <p className="text-[#0F172A] font-rocGroteskMedium">
                                        {profile?.fullName}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="flex items-center  text-sm">
                            <p className="text-[#64748B] font-rocGroteskMedium w-[166px]">
                                Line Manager
                            </p>
                            <Dropdown
                                name="lineManagerId"
                                value={createTasks.lineManagerId}
                                dropdownTriggerClassName={" "}
                                handleChange={(name, value) =>
                                    handleChange({ target: { name, value } })
                                }
                                placeholder={"Select Line Manager"}
                                className="!bg-transparent !border-none !text-slate-300 !font-rocGroteskMedium !py-1 !px-0 !text-sm "
                                dropdown={
                                    <div className="flex space-x-2 items-center">
                                        {selectedTeamMember ? (
                                            <>
                                                <VendorAvatar
                                                    size="24"
                                                    containerClassname="rounded-full"
                                                    name={
                                                        selectedTeamMember?.fullName ||
                                                        selectedTeamMember?.email
                                                    }
                                                    imageSrc={selectedTeamMember?.avatar}
                                                />
                                                <div className=" text-slate-700 font-rocGroteskMedium py-1 text-sm">
                                                    {selectedTeamMember?.fullName ||
                                                        selectedTeamMember.email}
                                                </div>
                                            </>
                                        ) : (
                                            <div className="text-slate-300 font-rocGroteskMedium py-1 text-sm">
                                                Select Line Manager
                                            </div>
                                        )}
                                    </div>
                                }
                                dropdownClassName={"!w-[159px] !mb-10 !-mt-7"}
                                dropdownContainerClasses={`shadow-cardShadow-4 !left-[0px] !mt-0  border border-slate-100 w-[159px] rounded !max-h-fit
                                    !h-[418px] !top-[100%]
                            `}
                                search={
                                    <div
                                        className="border border-slate-200 rounded-[4px] bg-slate-50 flex py-[5px] px-3 items-center
                                        gap-2"
                                    >
                                        <i className="ri-search-line text-slate-400 text-[16px] font-rocGroteskMedium"></i>
                                        <input
                                            type="text"
                                            placeholder="Search team members"
                                            className="outline-none text-[13px] leading-[24px]"
                                            name="searchString"
                                            value={searchString}
                                            onChange={handleSearch}
                                        />
                                    </div>
                                }
                                dropdownOptions={[
                                    ...(teamMembers?.length > 0
                                        ? filteredData?.map((item, idx) => ({
                                              label: (
                                                  <div
                                                      key={idx}
                                                      className="text-gm-50 font-rocGroteskMedium py-1 text-xs flex items-center gap-2"
                                                  >
                                                      <VendorAvatar
                                                          size="24"
                                                          containerClassname="rounded-full"
                                                          name={item?.fullName || item?.email}
                                                          imageSrc={item?.avatar}
                                                      />
                                                      {item?.fullName || item?.email}
                                                  </div>
                                              ),
                                              value: item?._id,
                                          }))
                                        : [
                                              {
                                                  label: <p>No data</p>,
                                                  value: null,
                                              },
                                          ]),
                                    {
                                        label: (
                                            <button className="additional-label text-p-50 text-xs flex gap-[10px] items-center">
                                                <i className="ri-add-line text-[16px]"></i>
                                                Invite team member
                                            </button>
                                        ),
                                        value: "invite", // Adjust as needed
                                    },
                                ]}
                            />
                        </div>
                        <div className="flex items-center  text-sm">
                            <p className="text-[#64748B] font-rocGroteskMedium w-[166px]">
                                Assignee
                            </p>
                            <div>
                                <Dropdown
                                    name="assigneeId"
                                    value={createTasks.assigneeId}
                                    dropdownTriggerClassName={" "}
                                    handleChange={(name, value) =>
                                        handleChange({ target: { name, value } })
                                    }
                                    dropdown={
                                        <div className="flex space-x-2 items-center">
                                            {selectedAssignee ? (
                                                <>
                                                    <VendorAvatar
                                                        size="24"
                                                        containerClassname="rounded-full"
                                                        name={
                                                            selectedAssignee?.fullName ||
                                                            selectedAssignee?.email
                                                        }
                                                        imageSrc={selectedAssignee?.avatar}
                                                    />
                                                    <div className=" text-slate-700 font-rocGroteskMedium py-1 text-sm">
                                                        {selectedAssignee?.fullName ||
                                                            selectedAssignee?.email}
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="text-slate-300 font-rocGroteskMedium py-1 text-sm">
                                                    Select Assignee
                                                </div>
                                            )}
                                        </div>
                                    }
                                    dropdownClassName={"!w-[159px] !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 !left-[0px] !mt-0  border border-slate-100 w-[159px] rounded !max-h-fit
                                    !h-[418px] !top-[100%]
                            `}
                                    search={
                                        <div
                                            className="border border-slate-200 rounded-[4px] bg-slate-50 flex py-[5px] px-3 items-center
                                        gap-2"
                                        >
                                            <i className="ri-search-line text-slate-400 text-[16px] font-rocGroteskMedium"></i>
                                            <input
                                                type="text"
                                                placeholder="Search team members"
                                                className="outline-none text-[13px] leading-[24px]"
                                                name="searchString"
                                                value={searchString}
                                                onChange={handleSearch}
                                            />
                                        </div>
                                    }
                                    dropdownOptions={[
                                        ...filteredData?.map((item, idx) => ({
                                            label: (
                                                <div
                                                    key={idx}
                                                    className=" text-gm-50 font-rocGroteskMedium py-1 text-xs flex items-center gap-2"
                                                >
                                                    <VendorAvatar
                                                        size="24"
                                                        containerClassname="rounded-full"
                                                        name={item?.fullName || item?.email}
                                                        imageSrc={item?.avatar}
                                                    />
                                                    {item?.fullName || item.email}
                                                </div>
                                            ),
                                            value: item._id,
                                        })),
                                        {
                                            label: (
                                                <button className="additional-label text-p-50 text-xs flex gap-[10px] items-center">
                                                    <i className="ri-add-line text-[16px]"></i>
                                                    Invite team member
                                                </button>
                                            ),
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="flex items-center  text-sm">
                            <p className="text-[#64748B] font-rocGroteskMedium w-[166px]">
                                Date created
                            </p>
                            <p className="text-slate-700 font-rocGroteskMedium py-1">
                                {moment().format("MMM. Do, YYYY")}
                            </p>
                        </div>
                        <div className="flex items-center  text-sm">
                            <p className="text-[#64748B] font-rocGroteskMedium w-[166px] ">
                                Timeline
                            </p>
                            <div className="relative cursor-pointer">
                                <div
                                    className={`${
                                        duration ? "text-gm-50" : "text-slate-300"
                                    }  text-sm font-rocGroteskMedium flex gap-[6px] items-center py-1`}
                                    onClick={() => setOpen(!open)}
                                >
                                    <i className="ri-time-line text-gm-40 capitalize w-[15px]"></i>
                                    {duration ? duration : "Enter timeline"}
                                </div>
                                {open && (
                                    <div
                                        ref={timelineRef}
                                        className=" absolute z-[100] bg-[#fff] p-5 w-[338px] shadow-cardShadow-4 radius-[8px] "
                                    >
                                        <p className="text-[#142837] text-sm pb-[10px]">
                                            Set timeline
                                        </p>
                                        <div className="flex gap-[14px]">
                                            <TextInput
                                                type="number"
                                                placeholder="Enter number"
                                                inputContainerClassname="!border !bg-[#fff] !border-gm-25 "
                                                inputClassName="!hover-border-gm-25 !text-slate-400 text-base !h-[48px]"
                                                name="number"
                                                value={timeline.number}
                                                onChange={handleTimeline}
                                                min="0"
                                            />
                                            <Dropdown
                                                dropdownTriggerClassName={" "}
                                                dropdown={
                                                    <div className="space-x-2 w-[137px] border border-gm-25 h-[49px] px-4 py-4 rounded-[4px] flex items-center !justify-between">
                                                        <div className=" text-slate-400 py-1 text-center gap-[6px] ">
                                                            {timeline?.duration
                                                                ? timeline?.duration
                                                                : "Duration"}
                                                        </div>
                                                        <i className="ri-arrow-down-s-line text-lg text-slate-400"></i>
                                                    </div>
                                                }
                                                dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                                dropdownContainerClasses={`shadow-cardShadow-4 left-[0px] !w-[137px] rounded !max-h-fit !pb-0 !justify-between
                            `}
                                                handleChange={(name, value) =>
                                                    handleTimeline({ target: { name, value } })
                                                }
                                                dropdownItemsClasses="!bg-transparent "
                                                name={"duration"}
                                                value={timeline.duration}
                                                dropdownOptions={[
                                                    {
                                                        label: (
                                                            <div className=" text-slate-700 font-rocGroteskMedium py-1 text-xs text-center  ">
                                                                Minute(s)
                                                            </div>
                                                        ),
                                                        value: "Minute(s)",
                                                    },
                                                    {
                                                        label: (
                                                            <div className=" text-slate-700 font-rocGroteskMedium py-1 text-xs text-center  ">
                                                                Hour(s)
                                                            </div>
                                                        ),
                                                        value: "Hour(s)",
                                                        action: () => {
                                                            navigate("");
                                                        },
                                                    },
                                                    {
                                                        label: (
                                                            <div className=" text-slate-700 font-rocGroteskMedium py-1 text-xs text-center  ">
                                                                Day(s)
                                                            </div>
                                                        ),
                                                        value: "Day(s)",
                                                        action: () => {
                                                            navigate("");
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex items-center text-sm">
                            <p className="text-[#64748B] font-rocGroteskMedium w-[166px]">
                                Priority Level
                            </p>
                            <div>
                                <Dropdown
                                    name="priority"
                                    value={createTasks.priority}
                                    dropdownTriggerClassName={" "}
                                    handleChange={(name, value) =>
                                        handleChange({ target: { name, value } })
                                    }
                                    dropdown={
                                        <div className="flex gap-2 justify-between">
                                            <div
                                                className={`px-2 text-slate-700 font-rocGroteskMedium py-1 text-xs rounded text-center flex items-center gap-[6px] ${
                                                    createTasks.priority === "low"
                                                        ? "bg-[#E2E8F0]"
                                                        : createTasks.priority === "medium"
                                                        ? "bg-[#AAC6EB]"
                                                        : "bg-[#FBECD1]"
                                                } capitalize`}
                                            >
                                                {createTasks.priority === "low" ? (
                                                    <i className="ri-battery-low-line text-[#64748B] text-base"></i>
                                                ) : createTasks.priority === "medium" ? (
                                                    <i className="ri-scales-2-fill text-[#2B71CC] text-base"></i>
                                                ) : (
                                                    <i className="ri-fire-fill text-[#FF5D43] text-base"></i>
                                                )}
                                                {createTasks.priority
                                                    ? createTasks.priority
                                                    : "High"}
                                            </div>
                                            <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 left-[0px] !mt-0  !w-[159px] rounded !max-h-fit !p-0 !top-[105%]
                            `}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={[
                                        {
                                            label: (
                                                <div className="bg-[#FBECD1] px-2 text-slate-700 font-rocGroteskMedium py-1 text-xs rounded-[4px] text-center flex items-center gap-[6px] ">
                                                    <i className="ri-fire-fill text-[#FF5D43] text-base"></i>{" "}
                                                    High
                                                </div>
                                            ),
                                            value: "High",
                                        },
                                        {
                                            label: (
                                                <div className="bg-[#AAC6EB] px-2 text-slate-700 font-rocGroteskMedium py-1 text-xs rounded-[4px] text-center flex items-center gap-[6px] ">
                                                    <i className="ri-scales-2-fill text-[#2B71CC] text-base"></i>
                                                    Medium
                                                </div>
                                            ),
                                            value: "Medium",
                                        },
                                        {
                                            label: (
                                                <div className="bg-[#E2E8F0] px-2 text-slate-700 font-rocGroteskMedium py-1 text-xs rounded-[4px] text-center flex items-center gap-[6px] ">
                                                    <i className="ri-battery-low-line text-[#64748B] text-base"></i>
                                                    Low
                                                </div>
                                            ),
                                            value: "Low",
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="">
                        <TabsContainer
                            tabs={tabs}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            component={displayActiveTab()}
                            className="!px-0"
                            itemClassName="!pb-2"
                        />
                    </div>

                    <div className="flex justify-end gap-4 items-center border-t-[#E2E8F0] border-t-[1px] pt-6 mt-2">
                        <Button
                            onClick={closeModal}
                            btnText="Close"
                            btnClassname="!w-auto !bg-[#F4F5F7] !text-[#142837]"
                        />
                        <Button
                            isLoading={createTask}
                            onClick={handleSubmit}
                            btnText={"Save task"}
                            type={"button"}
                            btnClassname="!py-3 !pl-4 !pr-2 !w-[120px] !h-[43px]"
                            btnType="textFirst"
                        />
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
};

export default NewTasksModal;
