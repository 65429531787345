import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import VendorSelectInput from "components/common/InputField/VendorSelectInput";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";

interface FillServicesProps {
    setActiveScreen: React.Dispatch<React.SetStateAction<number>>;
    serviceData: {
        description: string;
        qty: string;
        vendor: string[];
        [key: string]: string | string[];
    }[];
    setServiceData: React.Dispatch<
        React.SetStateAction<
            {
                description: string;
                qty: string;
                vendor: string[];
            }[]
        >
    >;
    orderDetails: {
        orderType: string;
        vendorCategory: string;
        orderDescription: string;
    };
    setOrderDetails: React.Dispatch<
        React.SetStateAction<{
            orderType: string;
            vendorCategory: string;
            orderDescription: string;
        }>
    >;
    handleSendRfqs: (e: React.FormEvent<HTMLFormElement>) => void;
    sendingRfqs: boolean;
    setStep: React.Dispatch<React.SetStateAction<number>>;
}

const FillServices = ({
    setActiveScreen,
    serviceData,
    setServiceData,
    orderDetails,
    setOrderDetails,
    handleSendRfqs,
    sendingRfqs,
    setStep,
}: FillServicesProps) => {
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [allProducts, setAllProducts] = useState<{ [key: string]: any }[]>([]);
    const dispatch = useAppDispatch();

    const handleItemChange = (idx: number, name: string, value: string | string[]) => {
        const itemDataCopyArr = [...serviceData];
        const itemDataCopy = itemDataCopyArr?.[idx];
        itemDataCopy[name] = value;

        setServiceData(itemDataCopyArr);
    };

    const handleChange = (e: { target: { name: string; value: string } }) => {
        const { name, value } = e.target;
        setOrderDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const {
        fetchingSearchInventoryListing,
        fetchedSearchInventoryListingSuccess,
        fetchingWarehouseList,
        fetchedWarehouseListSuccess,
        createWarehouseSuccess,
    } = useAppSelector((state) => state.inventory);

    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e.target.value);
    }, 1500);

    const tableHeader = [
        { title: "Item name", widthClass: "w-[50%] max-sm:w-[30%] !px-2.5" },
        { title: "QTY", widthClass: "w-[10%] max-sm:w-[15%] !px-2.5" },
        { title: "Assign vendor", widthClass: "w-[40%] max-sm:w-[35%] !px-2.5" },
    ];

    const tableBody = serviceData?.map((data, idx) => {
        return [
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        {/* <TextInput
                            value={data?.description as string}
                            name={"description"}
                            type={"text"}
                            required={true}
                            onChange={(e) => handleItemChange(idx, e.target.name, e.target.value)}
                            placeholder={"Item description"}
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full "
                        /> */}
                        <SelectInput
                            value={data?.description}
                            name="description"
                            placeholder={"Item description"}
                            handleChange={(name, value) => handleItemChange(idx, name, value)}
                            handleInputChange={(e) => handleDebouncedChange(e)}
                            isRequired={true}
                            searchLoading={fetchingSearchInventoryListing}
                            className="!border-0 !rounded-none"
                            dropdownOptions={allProducts?.map((product) => ({
                                label: product?.productName,
                                value:
                                    product?._id +
                                    "-" +
                                    product?.productName +
                                    "-" +
                                    JSON.stringify(product?.vendors?.map((vendor) => vendor?._id)),
                            }))}
                        />
                    </div>
                ),
            },
            {
                content: (
                    <div className={`w-full h-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={data?.qty as string}
                            name={"qty"}
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            required={true}
                            onChange={(e) => handleItemChange(idx, e.target.name, e.target.value)}
                            placeholder={"Qty"}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            pattern={"^[0-9]+$"}
                            title={"must be digits"}
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none"}
                            inputClassName="w-full "
                        />
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full flex items-center h-full border-slate-100 text-sm text-slate-700`}
                    >
                        <VendorSelectInput
                            value={data?.vendor?.join(", ")}
                            name="vendor"
                            placeholder={"Select vendor"}
                            isRequired={true}
                            orderType={orderDetails?.orderType}
                            className="!border-0 !pl-3"
                            handleChange={(name, value, valuesArray) =>
                                handleItemChange(idx, name, valuesArray)
                            }
                        />
                        {idx !== 0 && (
                            <i
                                onClick={() => {
                                    const serviceDataCopy = [...serviceData];
                                    serviceDataCopy.splice(idx, 1);
                                    setServiceData(serviceDataCopy);
                                }}
                                className="ri-delete-bin-line cursor-pointer text-slate-500 absolute right-[-20px]"
                            ></i>
                        )}
                    </div>
                ),
                widthClass: `w-[40%] absolute `,
                tableDataDivClass: `!pl-0`,
            },
        ];
    });

    useEffect(() => {
        dispatch(inventoryActions.fetchSearchInventoryListing(30, debouncedSearch));
    }, [dispatch, debouncedSearch]);

    useEffect(() => {
        if (Boolean(fetchedSearchInventoryListingSuccess)) {
            setAllProducts(fetchedSearchInventoryListingSuccess?.inventory?.data);
        }
    }, [fetchedSearchInventoryListingSuccess]);

    return (
        <form onSubmit={handleSendRfqs} className="relative">
            <div className="mb-8 px-8 max-sm:px-3">
                <p className="text-[22px] font-rocGroteskMedium text-gm-50">
                    What items or services are you seeking a quotation for?
                </p>
            </div>
            <div className="mb-[130px]">
                <div className="px-8 max-sm:px-3 grid grid-cols-1 gap-6 mb-8">
                    <SelectInput
                        value={orderDetails.orderType}
                        name="orderType"
                        placeholder={"Order type"}
                        handleChange={(name, value) => handleChange({ target: { name, value } })}
                        isRequired={true}
                        dropdownOptions={[
                            {
                                label: "Supply",
                                value: "supply",
                            },
                            {
                                label: "Logistics",
                                value: "logistics",
                            },
                        ]}
                    />
                    {/* <SelectInput
                        value={orderDetails.vendorCategory}
                        name="vendorCategory"
                        placeholder={"Selected vendor category"}
                        handleChange={(name, value) => handleChange({ target: { name, value } })}
                        isRequired={true}
                        dropdownOptions={vendorData.map((item) => ({
                            label: item.title,
                            value: item.title,
                            icon: item.icon,
                        }))}
                    /> */}
                </div>
                <div className="max-w-full px-8 max-sm:px-3 relative mb-8">
                    <div className="w-full relative">
                        <CustomTable
                            tableBody={tableBody}
                            tableHeader={tableHeader}
                            isAllSelectable={false}
                            headerContainerClass="!bg-slate-50"
                            tableClass=" w-full"
                            isScrollable={false}
                            isCellBordered={true}
                        />
                    </div>

                    <div
                        onClick={() =>
                            setServiceData((prev) => [
                                ...prev,
                                { description: "", qty: "", vendor: [] },
                            ])
                        }
                        className="mt-4 flex items-center w-fit gap-1 cursor-pointer"
                    >
                        <i className="ri-add-fill"></i>
                        <p className="text-sm font-rocGroteskMedium text-gm-50">Add more items</p>
                    </div>
                </div>
                <div className="px-8 max-sm:px-3 w-full">
                    <TextareaInput
                        name={"orderDescription"}
                        value={orderDetails.orderDescription}
                        onChange={handleChange}
                        rows={8}
                        placeholder={"Order description"}
                        required={true}
                        containerClassname=""
                    />
                </div>
            </div>

            <div className="px-6 max-sm:px-3 py-4 border-t border-slate-100 flex justify-end">
                <div className="flex items-center gap-3">
                    <Button
                        btnClassname="!bg-n-20 !text-gm-50 !w-[150px]"
                        btnText={"Back"}
                        onClick={() => {
                            setActiveScreen((prev) => prev - 1);
                            setStep((prev) => prev - 1);
                        }}
                        type={"button"}
                    />
                    <Button
                        btnText={"Send request"}
                        type={"submit"}
                        btnClassname={"!w-[150px]"}
                        isLoading={sendingRfqs}
                        // onClick={handleSendRfqs}
                    />
                </div>
            </div>
        </form>
    );
};

export default FillServices;
