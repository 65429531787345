import CustomTable from "components/common/CustomTable";
import CustomTableLoader from "components/common/CustomTableLoader";
import Dropdown from "components/common/Dropdown";
import Loader from "components/common/Loader";
import Rating from "components/common/Rating";
import VendorAvatar from "components/common/VendorAvatar";
import { formatMoney, getCurrencyFromCurrencyCode, onScroll } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { truncate, uniqBy } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { orderActions } from "redux/Ldb/actions";

const QuotesHistory = ({ purchaseId }) => {
    const limit = 10;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [quotesHistory, setQuotesHistory] = useState([]);
    const [page, setPage] = useState<number>(1);
    const [loadMoreQuotesHistory, setLoadMoreQuotesHistory] = useState<boolean>(false);
    const [quotesHistoryPagination, setQuotesHistoryPagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });
    const { fetchingQuotesHistory, fetchedQuotesHistorySuccess } = useAppSelector(
        (state) => state.order
    );
    const dashboardContent = document.getElementById("dashboardContent");

    useEffect(() => {
        dispatch(orderActions.getQuotesHistory(purchaseId, limit, page));
    }, [dispatch, purchaseId, page]);

    useEffect(() => {
        if (Boolean(fetchedQuotesHistorySuccess)) {
            setQuotesHistory((prev) =>
                page > 1
                    ? uniqBy([...prev, ...fetchedQuotesHistorySuccess?.quotes], "_id")
                    : [...fetchedQuotesHistorySuccess?.quotes]
            );
            setQuotesHistoryPagination({
                current: fetchedQuotesHistorySuccess?.pagination.current,
                number_of_pages: fetchedQuotesHistorySuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedQuotesHistorySuccess]);

    const tableQuotesHeader = [
        { title: "Quote Number", widthClass: "w-[8.6%]" },
        { title: "Supplier", widthClass: "w-[13.9%]" },
        { title: "Date created", widthClass: "w-[11.3%]" },
        { title: "Validity period", widthClass: "w-[11.3%]" },
        { title: "Total amount(USD)", widthClass: "w-[10.7%]" },
        { title: "A.I review", widthClass: "w-[20.9%]" },
        { title: "Status", widthClass: "w-[12.4%]" },
        { title: "", widthClass: "w-[5.1%]" },
    ];

    const onQuoteTableScroll = useCallback(
        () =>
            onScroll(
                dashboardContent,
                quotesHistoryPagination?.current as number,
                quotesHistoryPagination?.number_of_pages as number,
                () => {
                    setPage(() => page + 1);
                    setLoadMoreQuotesHistory(true);
                }
            ),
        [dashboardContent, quotesHistoryPagination]
    );
    useEffect(() => {
        dashboardContent?.addEventListener("scroll", onQuoteTableScroll);
        return () => {
            dashboardContent?.removeEventListener("scroll", onQuoteTableScroll);
        };
    }, [dashboardContent, onQuoteTableScroll]);

    const tableQuotesBody = quotesHistory?.map((history, idx) => [
        {
            content: (
                <div
                    key={idx}
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-500 `}
                >
                    <p>{history?.taId}</p>
                </div>
            ),
            cellClickAction: () =>
                navigate(`/dashboard/purchase/${purchaseId}/quotes/${history?._id}`),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center space-x-3">
                        <VendorAvatar
                            imageSrc={history?.vendor?.profile?.avatar}
                            size={32}
                            name={history?.vendor?.email}
                        />
                        <span>
                            {truncate(
                                history?.vendor?.profile?.businessName ?? history?.vendor?.email,
                                { length: 18 }
                            )}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <span>{moment(history?.createdAt).format("DD MMM, YYYY")}</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <span>
                        {history?.dueDate
                            ? moment(history?.dueDate).format("DD MMM, YYYY")
                            : "----"}
                    </span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    {history?.amount ? (
                        <span>
                            {getCurrencyFromCurrencyCode(history?.amount?.currency)}
                            {formatMoney().format(history?.amount?.amount)}
                        </span>
                    ) : (
                        "----"
                    )}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-500 `}
                >
                    <span>
                        <Rating ratingNo={history?.vendor?.rating} />
                    </span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 space-x-1  font-rocGroteskMedium flex items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    <div
                        className={`h-2 w-2 ${
                            history?.currentStatus?.toLowerCase() === "approved" && "bg-g-50"
                        } 
                        ${history?.currentStatus?.toLowerCase() === "pending" && "bg-[#6562E5]"} 
                        ${history?.currentStatus?.toLowerCase() === "in-transit" && "bg-[#F59E0B]"} 
                        rounded-full`}
                    ></div>
                    <p>
                        {history?.currentStatus?.toLowerCase() === "in-transit"
                            ? "In transit"
                            : history?.currentStatus?.charAt(0).toUpperCase() +
                              history?.currentStatus?.slice(1)}
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={` flex justify-center w-[82%] items-center h-full border-slate-100 text-sm text-slate-800 `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-100px] `}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[]}
                    />
                </div>
            ),
        },
    ]);

    return fetchingQuotesHistory && quotesHistory?.length === 0 ? (
        <div>
            <div className="max-lg:hidden block">
                <CustomTableLoader tableHeader={tableQuotesHeader} />
            </div>
            <div className="max-lg:block hidden my-[5%]">
                <Loader />
            </div>
        </div>
    ) : quotesHistory?.length > 0 ? (
        <div className="">
            <div className="max-lg:hidden">
                <CustomTable
                    tableBody={tableQuotesBody}
                    tableHeader={tableQuotesHeader}
                    isScrollable={false}
                    // isScrolled={isScrolled}
                    isCellBordered={false}
                    isCellSelectable={false}
                    isAllSelectable={false}
                    headerContainerClass="!bg-slate-50"
                    headerItemClass="font-rocGroteskMedium "
                    tableClass="mb-12"
                />
            </div>
            <div className="max-lg:block space-y-2 hidden">
                {quotesHistory?.map((history: any, idx) => (
                    <div key={idx} className="border border-[#E2E8F0] py-4 sm:px-4 px-2 rounded">
                        <div className="flex items-center justify-between">
                            <div className="font-rocGroteskMedium space-y-2 text-sm">
                                <div className="flex items-center space-x-2">
                                    <p className="text-[#000000] border-r pr-2 border-r-[#E2E8F0] font-rocGroteskMedium text-sm">
                                        {history?.taId}
                                    </p>
                                    <p className="text-slate-500 text-sm">
                                        {history?.amount ? (
                                            <span>
                                                {getCurrencyFromCurrencyCode(
                                                    history?.amount?.currency
                                                )}
                                                {formatMoney().format(history?.amount?.amount)}
                                            </span>
                                        ) : (
                                            "----"
                                        )}
                                    </p>
                                </div>
                                <div className="flex items-center space-x-1">
                                    <i className="ri-calendar-line text-slate-500"></i>
                                    <p className=" text-[#142837] font-rocGroteskMedium text-xs">
                                        Date created :{" "}
                                        {history?.dueDate
                                            ? moment(history?.dueDate).format("DD MMM, YYYY")
                                            : "----"}
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-1">
                                <div
                                    className={`h-2 w-2 ${
                                        history?.currentStatus?.toLowerCase() === "approved" &&
                                        "bg-g-50"
                                    } 
                                    ${
                                        history?.currentStatus?.toLowerCase() === "pending" &&
                                        "bg-[#6562E5]"
                                    } 
                                    ${
                                        history?.currentStatus?.toLowerCase() === "in-transit" &&
                                        "bg-[#F59E0B]"
                                    } 
                            rounded-full`}
                                ></div>
                                <p className="font-rocGroteskMedium text-sm text-[#0F172A]">
                                    {" "}
                                    {history?.currentStatus?.toLowerCase() === "in-transit"
                                        ? "In transit"
                                        : history?.currentStatus?.charAt(0).toUpperCase() +
                                          history?.currentStatus?.slice(1)}
                                </p>
                            </div>
                            <div
                                onClick={() =>
                                    navigate(
                                        `/dashboard/purchase/${purchaseId}/quotes/${history?._id}`
                                    )
                                }
                            >
                                <i className="ri-more-2-fill text-lg text-slate-500"></i>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {loadMoreQuotesHistory && fetchingQuotesHistory && (
                <div className="flex my-4 justify-center">
                    <Loader color="gm-25" size={4} />
                </div>
            )}
            {quotesHistoryPagination?.current === quotesHistoryPagination?.number_of_pages && (
                <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                    <p className="text-center"> End of list</p>
                </div>
            )}
        </div>
    ) : (
        <div className="items-center flex  h-[100%]  justify-center my-[5%]">
            <div className="space-y-2  text-center">
                <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>

                <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                    Oops! looks like there are no quote history
                    <br /> available at the moment. Check back later
                </p>
            </div>
        </div>
    );
};

export default QuotesHistory;
