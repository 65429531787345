import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import Checkbox from "components/common/InputField/Checkbox";
import MultiselectInput from "components/common/InputField/MultiselectInput";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authActions, orderActions } from "redux/Ldb/actions";

const PurchaseSettings = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [debouncedSearch, setDebouncedSearch] = useState({
        search: "",
    });
    const [teamList, setTeamList] = useState([]);
    const [roles, setRoles] = useState([]);
    const [settingsDetails, setSettingsDetails] = useState<any>([]);
    const [data, setData] = useState({
        approvers: [],
    });
    const {
        fetchedPOSettingsSuccess,
        fetchingPOSettings,
        updatedPOSettingsSuccess,
        updatingPOSettings,
    } = useAppSelector((state) => state.order);
    const { fetchingRoles, fetchedRolesSuccess, fetchingTeamMember, fetchedTeamMemberSuccess } =
        useAppSelector((state) => state.auth);
    useEffect(() => {
        dispatch(orderActions.getPOSettings());
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamList(fetchedTeamMemberSuccess?.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        dispatch(authActions.getRoles(20, "", debouncedSearch.search));
    }, [dispatch, debouncedSearch.search]);

    useEffect(() => {
        if (Boolean(fetchedRolesSuccess)) {
            setRoles(fetchedRolesSuccess?.rolesList);
        }
    }, [fetchedRolesSuccess]);

    useEffect(() => {
        dispatch(authActions.getTeamMembers());
    }, [dispatch]);

    useEffect(() => {
        setData({
            approvers: settingsDetails?.approvers || [],
        });
    }, [settingsDetails]);

    useEffect(() => {
        if (Boolean(updatedPOSettingsSuccess)) {
            dispatch(orderActions.getPOSettings());
        }
    }, [updatedPOSettingsSuccess, dispatch]);

    useEffect(() => {
        if (Boolean(fetchedPOSettingsSuccess)) {
            setSettingsDetails(fetchedPOSettingsSuccess?.setings);
        }
    }, [fetchedPOSettingsSuccess]);

    const handleAddLevel = () => {
        const newApprover = {
            fullName: "",
            role: "",
            avatar: "",
            _id: "",
        };

        setData((prevData) => ({
            ...prevData,
            approvers: [...(prevData?.approvers || []), newApprover],
        }));
    };

    const handleApproverSelection = (selectedApprover) => {
        const emptyApproverIndex = data.approvers.findIndex(
            (approver) =>
                approver.fullName === "" &&
                approver.role === "" &&
                approver.avatar === "" &&
                approver._id === ""
        );
        const existingApproverIndex = data.approvers.findIndex(
            (approver) => approver._id === selectedApprover._id
        );

        if (emptyApproverIndex !== -1 && existingApproverIndex === -1) {
            const updatedApprovers = [...data.approvers];
            updatedApprovers[emptyApproverIndex] = selectedApprover;

            setData((prevData) => ({
                ...prevData,
                approvers: updatedApprovers,
            }));
        }
    };

    const handleDeleteApprover = (idx) => {
        const newApprovers = data.approvers.filter((_, index) => index !== idx);
        setData({ ...data, approvers: newApprovers });
    };

    const updateSettings = () => {
        const body = {
            approversId: data?.approvers?.map((item) => item._id?.trim())?.filter(Boolean),
        };
        dispatch(orderActions.updatePOSettings(body));
    };
 
    return (
        <div className="bg-white max-lg:border-0 border border-[#F1F5F9] md:px-0 px-4  rounded-[8px]  md:py-4">
            {fetchingPOSettings ? (
                <div className="my-[20%]">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="max-lg:hidden flex  justify-between items-center border-b px-4  md:px-8 pb-4 ">
                        <div
                            className="flex items-center w-fit cursor-pointer"
                            onClick={() => navigate(-1)}
                        >
                            <i className="ri-arrow-left-line text-gm-50"></i>
                            <p className="text-gm-50 underline text-sm font-rocGroteskMedium">
                                Back
                            </p>
                        </div>
                        <div className="flex space-x-3">
                            <Button
                                btnText="Cancel"
                                disabled={updatingPOSettings}
                                btnClassname="!bg-[#F4F5F7] !py-2 !w-fit "
                                onClick={() => navigate(-1)}
                            />
                            <Button
                                btnText="Save changes"
                                btnClassname="!w-fit !py-2 "
                                isLoading={updatingPOSettings}
                                onClick={updateSettings}
                            />
                        </div>
                    </div>
                    <div className="pb-0 max-lg:pb-24">
                        <div className="md:mt-6  max-lg:px-0 px-8">
                            <p className="md:text-[18px] text-sm md:leading-[28px] font-rocGroteskMedium text-gm-50">
                                Approval level
                            </p>
                            <p className="md:text-gm-50 w-full md:w-[50%] md:mt-3 mt-1 text-[#64748B] text-sm font-rocGroteskMedium">
                                Set up your purchase order approval level
                            </p>
                            <div className=" pb-7 ">
                                <div className="xl:w-[80%] lg:w-[70%] w-full grid grid-cols-1 md:grid-cols-2 gap-4  z-[100]  mt-5">
                                    {data?.approvers?.length > 0 && (
                                        <>
                                            {data.approvers.map((approver, idx) => (
                                                <div
                                                    key={idx}
                                                    className="rounded  border  boder-slate-200 justify-between flex items-center space-x-1 w-full"
                                                >
                                                    <div className="w-full">
                                                        <Dropdown
                                                            key={idx}
                                                            dropdown={
                                                                <div className="font-rocGroteskMedium justify-between p-2.5  border-slate-200 border-r items-center  flex space-x-2">
                                                                    <div className="flex space-x-2 items-center">
                                                                        <VendorAvatar
                                                                            imageSrc={
                                                                                approver?.profile
                                                                                    ?.avatar
                                                                            }
                                                                            size={24}
                                                                            containerClassname=""
                                                                            name={"Avatar"}
                                                                        />

                                                                        <p className="text-gm-50 text-sm  font-rocGroteskMedium">
                                                                            {approver?.fullName ||
                                                                                approver?.email}{" "}
                                                                            - {approver?.role}
                                                                        </p>
                                                                    </div>
                                                                    <div className="flex items-center space-x-4">
                                                                        <p className="text-[13px] leading-[20px] text-slate-500">
                                                                            Lev.{idx + 1}
                                                                        </p>
                                                                        <i className="ri-arrow-down-s-line text-slate-500"></i>
                                                                    </div>
                                                                </div>
                                                            }
                                                            className="w-full bg-[green]"
                                                            dropdownClassName={
                                                                "!w-full !bg-[blue] !mb-10 !-mt-7"
                                                            }
                                                            dropdownContainerClasses={`shadow-cardShadow-2 !w-full border rounded !max-h-fit  `}
                                                            dropdownItemsClasses={``}
                                                            name={"faq-dropdown"}
                                                            dropdownOptions={teamList?.map(
                                                                (res, idx) => ({
                                                                    label: (
                                                                        <div
                                                                            className="font-rocGroteskMedium items-center -mt-2 flex space-x-1"
                                                                            key={idx}
                                                                        >
                                                                            <VendorAvatar
                                                                                imageSrc={
                                                                                    res?.profile
                                                                                        ?.avatar
                                                                                }
                                                                                size={24}
                                                                                containerClassname="!rounded"
                                                                                name={res?.fullName}
                                                                            />

                                                                            <div className="text-xs  font-rocGroteskMedium">
                                                                                <p className="text-gm-50 ">
                                                                                    {res?.fullName}
                                                                                </p>
                                                                                <p className="text-slate-500 ">
                                                                                    {res?.email}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    ),
                                                                    value: res?._id,
                                                                    action: () =>
                                                                        handleApproverSelection(
                                                                            res
                                                                        ),
                                                                })
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="px-3">
                                                        <i
                                                            className="ri-delete-bin-6-line cursor-pointer text-[#64748B]"
                                                            onClick={() =>
                                                                handleDeleteApprover(idx)
                                                            }
                                                        ></i>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                                <div
                                    className="mt-3 flex items-center cursor-pointer w-fit"
                                    onClick={handleAddLevel}
                                >
                                    <i className="ri-add-fill text-g-65"></i>
                                    <p className="underline text-g-65 text-sm font-rocGroteskMedium">
                                        Add more levels
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className=" flex-col space-y-3 max-lg:block flex items-center left-0 right-0 bottom-0 px-5 shadow-faintShadow border-t py-4 justify-between fixed bg-white  border-slate-100 ">
                            <Button
                                btnText="Save changes"
                                btnClassname=" !py-2 "
                                isLoading={updatingPOSettings}
                                onClick={updateSettings}
                            />
                            <Button
                                btnText="Cancel"
                                disabled={updatingPOSettings}
                                btnClassname="!bg-[#F4F5F7] !py-2  "
                                onClick={() => navigate(-1)}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default PurchaseSettings;
