export const WorkflowTemplatesImages = [
    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700220246/Workflow_card_skqkhp.svg",
    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700220678/image_5369_y9bu2p.svg",
    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700220657/Workflow_card_1_mtz5dy.svg",
];

export const WorkflowTemplates = [
    {
        img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700553426/create_new_kwn3lc.svg",
        link: "#",
        text: "Create new workflow",
        value: "createNewWorkflow",
    },
    {
        img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700220246/Workflow_card_skqkhp.svg",
        link: "/dashboard/workflow/template?templateName=orderProcessingWorkflow",
        text: "Order processing workflow",
        value: "orderProcessingWorkflow",
        data: {
            name: "Order Processing-{current_time}",
            description: "For processing incoming orders",
            workFlowType: "manual",
            template: true,
            templateName: "Order Processing",
            firstEventName: "receiveCustomerOrder",
            workFlowCategory: "order",
            triggerEventSource: ["whatsapp", "mircosoft", "gmail"],
            position: "leave that to you",
            actions: [
                {
                    name: "Verify inventory availability to fulfill orders",
                    actionType: "checkInventory",
                    actionCat: "task_action",
                    rules: [],
                    duration: { unit: "minutes", value: 10 },
                },
                {
                    name: "Pick items from stock and pack for delivery",
                    actionType: "pickItemsForDelivery",
                    actionCat: "task_action",
                    rules: [],
                    duration: { unit: "minutes", value: 10 },
                },
            ],
        },
    },
    {
        img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700220678/image_5369_y9bu2p.svg",
        link: "/dashboard/workflow/template?templateName=purchaseOrdersWorkflow",
        text: "Purchase Orders (PO) workflow",
        value: "purchaseOrdersWorkflow",
        data: {
            name: "Purchase Orders (PO) workflow-{current_time}",
            description: "For automatically creating purchase order",
            workFlowType: "manual",
            template: true,
            templateName: "Purchase Orders (PO) workflow",
            firstEventName: "vendorSelected",
            workFlowCategory: "rfq",
            triggerEventSource: ["whatsapp", "mircosoft", "gmail"],
            position: "leave that to you",
            actions: [
                {
                    name: "Send PO request for approval",
                    actionType: "sendPOForApproval",
                    actionCat: "task_action",
                    rules: [],
                    duration: { unit: "days", value: 1 },
                },
                {
                    name: "Approve PO request",
                    actionType: "approvePo",
                    actionCat: "approval",
                    rules: [],
                    duration: { unit: "days", value: 1 },
                },
                {
                    name: "Generate PO based on approved supplier/vendor quote",
                    actionType: "createPO",
                    actionCat: "task_action",
                    rules: [],
                    duration: { unit: "days", value: 1 },
                },
            ],
        },
    },
    {
        img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700220657/Workflow_card_1_mtz5dy.svg",
        link: "/dashboard/workflow/template?templateName=rfqManagementWorkflow",
        text: "RFQ management workflow",
        value: "rfqManagementWorkflow",
        data: {
            name: "RFQ management workflow-{current_time}",
            description: "For automatically creating rfq",
            workFlowType: "manual",
            template: true,
            templateName: "RFQ management workflow",
            firstEventName: "productNotAvailable",
            workFlowCategory: "rfq",
            triggerEventSource: ["whatsapp", "mircosoft", "gmail"],
            position: "leave that to you",
            actions: [
                {
                    name: "Send RFQs to approved suppliers",
                    actionType: "createRFQ",
                    actionCat: "task_action",
                    rules: [],
                    duration: { unit: "seconds", value: 10 },
                },
                {
                    name: "Follow up with vendors",
                    actionType: "followUpWithVendors",
                    actionCat: "task_action",
                    rules: [],
                    duration: { unit: "days", value: 5 },
                },
            ],
        },
    },
    {
        img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1700220246/Workflow_card_skqkhp.svg",
        link: "/dashboard/workflow/template?templateName=purchaseRequestApprovalWorkflow",
        text: "Purchase request approval workflow",
        value: "purchaseRequestApprovalWorkflow",
        data: {
            name: "Purchase request approval workflow-{current_time}",
            description: "For automatically creating purchase request approval flow",
            workFlowType: "manual",
            template: true,
            templateName: "Purchase request approval workflow",
            firstEventName: "purchaseRequestCreated",
            workFlowCategory: "po",
            triggerEventSource: ["whatsapp", "mircosoft", "gmail"],
            position: "leave that to you",
            actions: [
                {
                    name: "Approve PO request",
                    actionType: "approvePO",
                    actionCat: "approval",
                    rules: [],
                    duration: { unit: "seconds", value: 30 },
                },
            ],
        },
    },
];
